export const paymentAggregators = [
  {
    value: 'none',
    label: 'Agregador de pago',
  },
  {
    value: 'fiserv',
    label: 'Fiserv',
  },
  {
    value: 'billPocket',
    label: 'Billpocket',
  },
];
