export const initialState = {
  fromdb: null,
  tablePages: 0,
  userDetails: null,
  deletedModal: false,
  userModal: false,
  url: null,
  id: null,
  successModal: {
    show: false,
    message: null,
    redirect: null,
  },
  errorModal: {
    show: false,
    message: null,
    redirect: null,
  },
  isLoading: false,
  error: null,
};
