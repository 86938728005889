import { closeModals } from '../modals/modalsSlice';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { logout } from '../auth/authSlice';

const kioskoSlice = createSlice({
  name: 'kiosko',
  initialState,
  reducers: {
    goToKiosko: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    goToKioskoSuccess: {
      reducer(state, action) {
        state.isLoading = false;
      },
    },
    goToKioskoFail: {
      reducer(state, action) {
        state.isLoading = false;
      },
    },
  },
  extraReducers: {
    [logout]: (state, action) => {
      return initialState;
    },
    [closeModals]: (state, action) => {
      state.isLoading = false;
      state.successModal = initialState.successModal;
      state.errorModal = initialState.errorModal;
    },
  },
});

export const {
  goToKiosko,
  goToKioskoSuccess,
  goToKioskoFail,
} = kioskoSlice.actions;

export default kioskoSlice.reducer;
