import 'react-table/react-table.css';

/* eslint-disable react/display-name */
import {
  Button,
  IconButton,
  ListSubheader,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import {
  createUser,
  deleteUser,
  getAllUsers,
  resendInvitation,
  updateUser,
} from 'app/features/users/usersSlice';
import {
  makeSelectUsersFromdb,
  selectUsers,
} from 'app/features/users/selectors';

import Add from '@material-ui/icons/Add';
import { Badge } from 'react-bootstrap';
import MUIRHFAutocompleteAgencies from 'app/components/MUIRHFAutocompleteAgencies';
import Modal from '../../../../components/Modal';
import { RHFMUISelect } from 'app/components/RHF';
import ReactTable from 'react-table';
import ShouldItRender from '../../../../components/ShouldItRender';
import { adminRoles } from '../../../../config/roles';
import { closeModals } from 'app/features/modals/modalsSlice';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { injectIntl } from 'react-intl';
import { makeSelectUserRoleFromState } from '../../../auth/selectors';
import { rolesByRole } from '../../../../utils/rolesByRole';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router';
import MUIAutocompleteAgencies from 'app/components/MUIAutocompleteAgencies';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import GetApp from '@material-ui/icons/GetApp';
import MomentUtils from '@date-io/moment';
import Close from '@material-ui/icons/Close';
import { CSVLink } from 'react-csv';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    widht: '100%',
    marginBottom: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(0, 1, 0, 1),
  },
  textField: {
    margin: theme.spacing(1),
    width: '95%',
  },
  datePicker: {
    width: '90px',
  },
}));

function UsersTable(props) {
  const classes = useStyles();
  const {
    users,
    usersFromdb,
    createUser,
    getAllUsers,
    deleteUser,
    updateUser,
    resendInvitation,
    setActionButton,
    closeModals,
    role,
    intl,
    location,
    history,
    fullUsers,
  } = props;
  const [openDelete, setOpenDelete] = useState(false);
  const [showResendEmail, setShowResendEmail] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [userID, setUserID] = useState(0);
  const [showCharactersError, setShowCharactersError] = useState(
    false,
  );
  const reactTable = useRef(null);
  const [disableDownloadCSV, setDisableDownloadCSV] = useState(false);

  const {
    handleSubmit,
    register,
    errors,
    control,
    reset,
  } = useForm();

  const [getErrorUser, setErrorUser] = React.useState(true);
  const [getErrorMail, setErrorMail] = React.useState(true);

  const [dataToCSV, setDataToCSV] = useState([]);
  const [firstDate, setFirstDate] = useState(null);
  const [secondDate, setSecondDate] = useState(null);
  const [showDateErrorModal, setShowDateErrorModal] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sorted, setSorted] = useState([]);
  const [filtered, setFiltered] = useState('');
  const [agencySelected, setAgencySelected] = useState('');

  const handleCharactersUser = (event) => {
    const regexUser = /[^a-zA-Z0-9-_ 'áéíóúÁÉÍÓÚñÑ]/;
    let characters = '';
    try {
      characters = event.target.value;
      if (characters) {
        regexUser.test(characters)
          ? setErrorUser(true)
          : setErrorUser(false);
      } else {
        setErrorUser(false);
      }
    } catch {
      characters = event;
      if (characters) {
        regexUser.test(characters)
          ? setErrorUser(true)
          : setErrorUser(false);
      } else {
        setErrorUser(false);
      }
    }
  };
  useEffect(() => {
    handleFetchData();
  }, [firstDate, secondDate]);
  useEffect(() => {
    if (users.fullUsers) {
      const CSV = [];
      let searchIn;
      !Array.isArray(users.fullUsers)
        ? (searchIn = usersFromdb)
        : (searchIn = users.fullUsers);
      searchIn.forEach((cursor) => {
        let activo;
        let agencia;
        cursor.verified ? (activo = 'Si') : (activo = 'No');
        if (cursor.adminUser.agency) {
          agencia = cursor.adminUser.agency.name;
        } else {
          agencia = '-';
        }
        const data = {
          // prettier-ignore
          'ID': cursor.id || '-',
          // prettier-ignore
          'Nombre': cursor.name || '-',
          // prettier-ignore
          'Correo': cursor.email || '-',
          // prettier-ignore
          'Agencia': agencia || '-',
          // prettier-ignore
          'Rol': cursor.role || '-',
          // eslint-disable-next-line prettier/prettier
          Activo: activo || '-',
          // prettier-ignore
          'Última actualización': cursor.updatedAt || '-',
        };
        CSV.push(data);
      });
      setDataToCSV(users.fullUsers);
    }
  }, [users.fullUsers]);
  useEffect(() => {
    if (firstDate && secondDate && firstDate > secondDate) {
      setShowDateErrorModal(true);
      setDisableDownloadCSV(true);
      renderErrorDateModal();
    } else {
      setDisableDownloadCSV(false);
    }
  }, [firstDate, secondDate]);
  useEffect(() => {
    const CSV = [];
    let searchIn;
    !Array.isArray(users.fullUsers)
      ? (searchIn = usersFromdb)
      : (searchIn = users.fullUsers);
    if (searchIn) {
      searchIn.forEach((cursor) => {
        let activo;
        let agencia;
        cursor.verified ? (activo = 'Si') : (activo = 'No');
        if (cursor.adminUser.agency) {
          agencia = cursor.adminUser.agency.name;
        } else {
          agencia = '-';
        }
        const data = {
          // prettier-ignore
          'ID': cursor.id || '-',
          // prettier-ignore
          'Nombre': cursor.name || '-',
          // prettier-ignore
          'Correo': cursor.email || '-',
          // prettier-ignore
          'Agencia': agencia || '-',
          // prettier-ignore
          'Rol': cursor.role || '-',
          // eslint-disable-next-line prettier/prettier
          Activo: activo || '-',
          // prettier-ignore
          'Última actualización': cursor.updatedAt || '-',
        };
        CSV.push(data);
      });
      setDataToCSV(CSV);
    }
  }, [users, filtered]);
  const handleCharactersMail = (event) => {
    const regexMail = /[^a-zA-Z0-9_+ñÑáéíóúÁÉÍÓÚ\.\-@]/;
    let characters = '';
    try {
      characters = event.target.value;
      if (characters) {
        regexMail.test(characters)
          ? setErrorMail(true)
          : setErrorMail(false);
      } else {
        setErrorMail(false);
      }
    } catch {
      characters = event;
      if (characters) {
        regexMail.test(characters)
          ? setErrorMail(true)
          : setErrorMail(false);
      } else {
        setErrorMail(false);
      }
    }
  };
  const renderErrorDateModal = () => {
    try {
      const title = 'Error en las fechas';
      const desc =
        'Por favor revise que la fecha inicial y la fecha final no estén invertidas ya que de lo contrario la información podría no ser correcta ni podrá descargar los reportes';
      return (
        <Modal
          open={showDateErrorModal}
          type={'error'}
          closeModal={handleCloseModals}
          dialogTitle={title}
          dialogText={desc}
          actionButtonText="Aceptar"
          onClick={handleCloseModals}
        />
      );
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };
  useEffect(() => {
    setActionButton(
      <>
        <Grid container justify="flex-start">
          <MuiPickersUtilsProvider utils={MomentUtils} locale={'es'}>
            <DatePicker
              id="mui-pickers-firstDate"
              autoOk
              disableToolbar
              disableFuture
              variant="inline"
              label="Inicial"
              format="DD/MM"
              className={classes.datePicker}
              value={firstDate}
              onChange={handleFirstDate}
              InputProps={{
                endAdornment: firstDate ? (
                  <IconButton
                    aria-label="Select locale"
                    size="small"
                    onClick={handleClearFirstDate}
                  >
                    <Close />
                  </IconButton>
                ) : null,
              }}
            />
            <span className={classes.range}>&nbsp;A&nbsp;</span>
            <DatePicker
              id="mui-pickers-secondDate"
              autoOk
              disableToolbar
              disableFuture
              variant="inline"
              label="Final"
              format="DD/MM"
              className={classes.datePicker}
              value={secondDate}
              onChange={handleSecondDate}
              InputProps={{
                endAdornment: secondDate ? (
                  <IconButton
                    aria-label="Select locale"
                    size="small"
                    onClick={handleClearSecondDate}
                  >
                    <Close />
                  </IconButton>
                ) : null,
              }}
            />
          </MuiPickersUtilsProvider>
          <div style={{ marginRight: '20px' }}>
            <CSVLink
              data={dataToCSV}
              filename={'Usuarios.csv'}
              onClick={() => {
                if (disableDownloadCSV) return false;
              }}
            >
              <Button
                variant="contained"
                className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-t-10 btnSize"
                disabled={disableDownloadCSV}
              >
                Exportar
                <GetApp className="kt-margin-l-10" />
              </Button>
            </CSVLink>
          </div>
          <ShouldItRender
            locationPage={`/${location.pathname.split('/')[1]}`}
            action="add"
          >
            <Button
              variant="contained"
              className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10 kt-margin-t-10"
              onClick={() => {
                setShowUserModal(true);
                setUserID(null);
                reset({
                  name: '',
                  role: '',
                  email: '',
                });
              }}
            >
              Crear usuario
              <Add className="kt-margin-l-10" />
            </Button>
          </ShouldItRender>
          ,
        </Grid>
      </>,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstDate, secondDate, role, disableDownloadCSV, dataToCSV]);

  const handleCloseCreate = () => {
    setShowUserModal(false);
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleDelete = () => {
    deleteUser({ userID, handleRefreshData });
    handleCloseDelete();
  };

  const handleResendInvitation = () => {
    resendInvitation({ userID });
    toggleResendInvitation();
  };

  const handleCloseModals = () => {
    closeModals();
  };

  const toggleResendInvitation = () => {
    setShowResendEmail(!showResendEmail);
  };

  const toggleCharactersError = () => {
    setShowCharactersError(!showCharactersError);
  };

  const handleRefreshData = () => {
    reactTable.current && reactTable.current.fireFetchData();
  };
  const handleFetchData = debounce(
    (state, instance) => {
      if (state) {
        const { page, pageSize, sorted, filtered } = state;
        getAllUsers({
          page,
          pageSize,
          sorted,
          filtered,
          firstDate,
          secondDate,
        });
        setPage(page);
        setPageSize(pageSize);
        setSorted(sorted);
        setFiltered(filtered);
      } else {
        getAllUsers({
          page,
          pageSize,
          sorted,
          filtered,
          firstDate,
          secondDate,
        });
      }
    },
    1000,
    { leading: false, trailing: true },
  );

  const colums = [
    {
      Header: () => <strong>Nombre</strong>,
      accessor: 'name',
      Cell: (row) => (
        // <Link to={`/edit-user/${row.original.id}`}>
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value}
        </div>
        // </Link>
      ),
    },
    {
      Header: () => <strong>Correo</strong>,
      accessor: 'email',
      Cell: (row) => (
        // <Link to={`/edit-user/${row.original.id}`}>
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value}
        </div>
        // </Link>
      ),
    },
    {
      Header: () => <strong>Agencia</strong>,
      accessor: 'adminUser.agency.name',
      Cell: (row) => (
        // <Link to={`/edit-user/${row.original.id}`}>
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value}
        </div>
        // </Link>
      ),
      show: role === 'Super Admin',
    },
    {
      Header: () => <strong>Rol</strong>,
      accessor: 'role',
      Cell: (row) => (
        // <Link to={`/edit-user/${row.original.id}`}>
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {intl.formatMessage({
            id: `AGENCY.ROLE.${row.value}`,
          })}
        </div>
        // </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Activo</strong>,
      accessor: 'verified',
      Cell: (row) => (
        // <Link to={`/edit-user/${row.original.id}`}>
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <Badge
            className={classes.badges}
            variant={row.value ? 'success' : 'danger'}
          >
            {row.value ? 'Activo' : 'No activo'}
          </Badge>
        </div>
        // </Link>
      ),
      filterable: false,
    },
    {
      Header: '',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <ShouldItRender should={role === 'Super Admin'}>
            <Tooltip title="Editar usuario">
              <IconButton
                className={classes.button}
                size="small"
                onClick={() => {
                  setShowUserModal(true);
                  reset({
                    name: row.original.name,
                    role: row.original.role,
                    email: row.original.email,
                  });
                  setUserID(row.original.id);
                  handleCharactersUser(row.original.name);
                  handleCharactersMail(row.original.email);
                }}
              >
                <i className="far fa-edit"></i>
              </IconButton>
            </Tooltip>
          </ShouldItRender>
          {!row.original.verified ? (
            <Tooltip title="Reenviar invitación">
              <IconButton
                className={classes.button}
                size="small"
                onClick={() => {
                  toggleResendInvitation();
                  setUserID(row.original.id);
                }}
              >
                <i className="far fa-envelope"></i>
              </IconButton>
            </Tooltip>
          ) : null}
          <Tooltip title="BORRAR">
            <IconButton
              className={classes.button}
              size="small"
              onClick={() => {
                handleOpenDelete();
                setUserID(row.original.id);
              }}
            >
              <i className="far fa-trash-alt"></i>
            </IconButton>
          </Tooltip>
        </div>
      ),
      filterable: false,
      show: role === 'Super Admin' || adminRoles.includes(role) || role === 'Super Admin Agencies' || role === 'Agency Manager',
    },
  ];

  const handleShowRoles = () => {
    if (role in rolesByRole)
      return rolesByRole[role].map((option, idx) => {
        if (option.subheader) {
          return (
            <ListSubheader
              key={`${option.value}-${idx}`}
              disableSticky
            >
              {option.subheader}
            </ListSubheader>
          );
        } else {
          return (
            <MenuItem
              key={`${option.value}-${idx}`}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          );
        }
      });
    else
      rolesByRole['default'].map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ));
  };

  const handleCreateOrEditUser = (formData) => {
    if (!getErrorUser && !getErrorMail) {
      if (userID) handleEditUser(formData);
      else handleCreateuser(formData);
    } else {
      setShowCharactersError(true);
    }
  };

  const handleCreateuser = (formData) => {
    createUser({
      ...formData,
      agencyID: agencySelected,
      handleRefreshData,
    });
    handleCloseCreate();
  };
  const handleFirstDate = (date) => {
    setFirstDate(date);
  };

  const handleSecondDate = (date) => {
    setSecondDate(date);
  };
  const handleClearFirstDate = () => {
    setFirstDate(null);
  };

  const handleClearSecondDate = () => {
    setSecondDate(null);
  };

  const handleEditUser = (formData) => {
    updateUser({
      ...formData,
      id: userID,
      handleRefreshData,
    });
    handleCloseCreate();
  };

  const renderAddUserDialog = () => {
    return (
      <Modal
        open={showUserModal}
        closeModal={handleCloseCreate}
        dialogTitle={userID ? 'Editar usuario' : 'Crear usuario'}
        actionButtonText={userID ? 'Editar usuario' : 'Crear usuario'}
        form={'create-user-form'}
        dialogChildren={
          <form
            id="create-user-form"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(handleCreateOrEditUser)}
          >
            <ShouldItRender
              locationPage={history.location.pathname}
              action="selectAgency"
              should={!userID}
              compounded
            >
              <MUIRHFAutocompleteAgencies
                name="agencyID"
                control={control}
                fullWidth
                onChange={(id) => setAgencySelected(id)}
                className={classes.textField}
              />
            </ShouldItRender>
            <TextField
              required
              name="name"
              type="text"
              label="Introduce el nombre de usuario"
              variant="outlined"
              onChange={handleCharactersUser}
              inputRef={register({
                required: true,
              })}
              className={classes.textField}
              error={Boolean(errors.name)}
            />
            <RHFMUISelect
              name="role"
              className={clsx(classes.textField, classes.select)}
              label="Rol"
              control={control}
              rules={{ required: true }}
              error={!!errors.role}
            >
              {handleShowRoles()}
            </RHFMUISelect>
            <TextField
              required
              name="email"
              label="Introduce un correo electrónico"
              type="email"
              variant="outlined"
              onChange={handleCharactersMail}
              inputRef={register({
                required: true,
              })}
              className={classes.textField}
              error={Boolean(errors.email)}
            />
          </form>
        }
      />
    );
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = users;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message } = users[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={handleCloseModals}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={handleCloseModals}
          />
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  const renderConfirmationDelete = () => {
    return (
      <Modal
        open={openDelete}
        type="warning"
        closeModal={handleCloseDelete}
        dialogTitle="¿Seguro que deseas eliminar el usuario?"
        dialogText="Si continúas no habrá manera de recuperar la información
            que haya sido borrada."
        actionButtonText="Eliminar usuario"
        onClick={handleDelete}
      />
    );
  };

  const renderResendInvitation = () => {
    return (
      <Modal
        open={showResendEmail}
        closeModal={toggleResendInvitation}
        dialogTitle="Reenviar invitación al usuario"
        dialogText="Estás a punto de renviar el correo de invitación a
        Karlo, ¿deseas continuar?"
        actionButtonText="Reenviar invitación"
        onClick={handleResendInvitation}
      />
    );
  };

  const renderCharactersError = () => {
    return (
      <Modal
        open={showCharactersError}
        type="warning"
        closeModal={toggleCharactersError}
        dialogTitle="Error en el nombre o el correo electrónico"
        dialogText="Por favor verifique que no haya caracteres especiales
        o números en el nombre, en el caso del correo los unicos caracteres
        que se permiten son: .-_"
        actionButtonText="Aceptar"
        onClick={toggleCharactersError}
      />
    );
  };

  return (
    <div className={classes.root}>
      {renderAddUserDialog()}
      {renderConfirmationDelete()}
      {renderResendInvitation()}
      {renderFeedBackModal()}
      {renderCharactersError()}
      <Paper className={classes.paper}>
        <ReactTable
          ref={reactTable}
          NoDataComponent={() => (
            <div className="rt-noData">Sin resultados</div>
          )}
          data={usersFromdb || []}
          manual
          onFetchData={handleFetchData}
          columns={colums}
          pages={users.tablePages}
          filterable
          className="-striped -highlight"
          previousText="Anterior"
          nextText="Siguiente"
          loading={users.isLoading}
          loadingText="Cargando datos..."
        />
      </Paper>
    </div>
  );
}

const mapStateToProps = (state) => ({
  users: selectUsers(state),
  usersFromdb: makeSelectUsersFromdb(state),
  role: makeSelectUserRoleFromState(state),
  fullUsers: state.users.fullUsers,
});

const mapDispatchToProps = {
  getAllUsers,
  updateUser,
  deleteUser,
  createUser,
  closeModals,
  resendInvitation,
};

export default withRouter(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(UsersTable),
  ),
);
