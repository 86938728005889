import { closeModals } from '../modals/modalsSlice';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { logout } from '../auth/authSlice';

const paymentOrdersSlice = createSlice({
  name: 'paymentOrders',
  initialState,
  reducers: {
    getPaymentOrders: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getPaymentOrdersSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.fromdb = action.payload.orders.rows;
        state.tablePages = action.payload.orders.pages;
        state.toDownload = null;
        state.fullOrders = action.payload.orders.fullOrders;
      },
    },
    getPaymentOrdersFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.error = action.payload.error;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    getPaymentOrderDetail: {
      reducer(state, action) {
        state.isLoading = true;
        state.orderDetails = null;
      },
    },
    getPaymentOrderDetailSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.orderDetails = action.payload.order;
      },
    },
    getPaymentOrderDetailFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    getPaymentsForOrderDetail: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    getPaymentsForOrderDetailSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.paymentsDetails = action.payload.data;
      },
    },
    getPaymentsForOrderDetailFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    createPaymentOrder: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    createPaymentOrderSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          ...action.payload,
        };
      },
    },
    createPaymentOrderFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    updatePaymentOrder: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    updatePaymentOrderSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.orderDetails = action.payload.paymentOrder;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    updatePaymentOrderFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    sendEmailLinkPaymentOrder: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    sendEmailLinkPaymentOrderSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    sendEmailLinkPaymentOrderFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    applyAdvancePaymentOrder: {
      reducer(state, action) {
        state.isLoading = true;
        state.orderDetails = null;
      },
    },
    applyAdvancePaymentOrderSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    applyAdvancePaymentOrderFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    createOrUpdateSurtaxesForPaymentOrders: {
      reducer(state, action) {
        state.isLoading = true;
        state.orderDetails = null;
      },
    },
    createOrUpdateSurtaxesForPaymentOrdersSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    createOrUpdateSurtaxesForPaymentOrdersFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    closeModals: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = initialState.successModal;
        state.errorModal = initialState.errorModal;
      },
    },
  },
  extraReducers: {
    [logout]: (state, action) => {
      return initialState;
    },
    [closeModals]: (state, action) => {
      state.isLoading = false;
      state.successModal = initialState.successModal;
      state.errorModal = initialState.errorModal;
    },
  },
});

export const {
  getPaymentOrders,
  getPaymentOrdersSuccess,
  getPaymentOrdersFail,
  getPaymentOrderDetail,
  getPaymentOrderDetailSuccess,
  getPaymentOrderDetailFail,
  getPaymentsForOrderDetail,
  getPaymentsForOrderDetailSuccess,
  getPaymentsForOrderDetailFail,
  createPaymentOrder,
  createPaymentOrderSuccess,
  createPaymentOrderFail,
  updatePaymentOrder,
  updatePaymentOrderSuccess,
  updatePaymentOrderFail,
  sendEmailLinkPaymentOrder,
  sendEmailLinkPaymentOrderSuccess,
  sendEmailLinkPaymentOrderFail,
  applyAdvancePaymentOrder,
  applyAdvancePaymentOrderSuccess,
  applyAdvancePaymentOrderFail,
  createOrUpdateSurtaxesForPaymentOrders,
  createOrUpdateSurtaxesForPaymentOrdersSuccess,
  createOrUpdateSurtaxesForPaymentOrdersFail,
} = paymentOrdersSlice.actions;

export default paymentOrdersSlice.reducer;
