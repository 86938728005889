import { call, put } from 'redux-saga/effects';
import {
  tokenizePaymentOrderFail,
  tokenizePaymentOrderSuccess,
  tokenizeServiceOrderFail,
  tokenizeServiceOrderSuccess,
} from 'app/features/tokenize/tokenizeSlice';

import { requestHandler } from '../../services/requestHandler';

export function* tokenizeServiceOrderSaga(action) {
  try {
    const data = {
      ...action.payload,
    };
    const response = yield call(requestHandler, {
      method: 'POST',
      path: '/token/generate',
      data,
    });
    yield put(tokenizeServiceOrderSuccess({ token: response }));
  } catch (e) {
    yield put(tokenizeServiceOrderFail('getPaymentSaga'));
    console.log('error in get payment saga', e);
  }
}

export function* tokenizePaymentOrderSaga(action) {
  try {
    const data = {
      ...action.payload,
    };
    const response = yield call(requestHandler, {
      method: 'POST',
      path: '/token/generate',
      data,
    });
    yield put(tokenizePaymentOrderSuccess({ token: response }));
  } catch (e) {
    yield put(tokenizePaymentOrderFail('getPaymentSaga'));
    console.log('error in get payment saga', e);
  }
}
