import { call, put } from 'redux-saga/effects';
import {
  getKarloQuotesSuccess,
  getKarloQuotesFail,
} from 'app/features/karloQuotes/karloQuotesSlice.js';

import { requestHandler } from '../../services/requestHandler';
import moment from 'moment';

export function* getKarloQuotesSaga(action) {
  try {
    const { page, pageSize, filtered } = action.payload;
    const _renderPage = pageSize * page;
    const data = {
      offset: _renderPage,
      limit: pageSize,
      field: 'id',
      ordering: 'ASC',
    };
    const karloQuotes = yield call(requestHandler, {
      method: 'POST',
      path: '/karlo-app/getUsersWhitCars',
      data,
    });
    karloQuotes.pages = yield call(
      Math.ceil,
      karloQuotes.length / pageSize,
    );
    console.log('success in karloQuotes saga ', karloQuotes);
    yield put(getKarloQuotesSuccess({ karloQuotes }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba encontrar la información.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(getKarloQuotesFail({ message }));
    console.log('error in karloQuotes saga', e);
  }
}
