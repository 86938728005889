import 'react-table/react-table.css';
import 'moment/locale/es';

/* eslint-disable react/display-name */
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  makeStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import {
  filterCaseInsensitive,
  formatDate,
  formatNumber,
  getColumnWidth,
  getNested,
} from '../../../../utils';
import {
  getPayments,
  getPaymentsOrders,
  searchInPayments,
} from 'app/features/payments/paymentsSlice';
import {
  makeSelectPaymentsSearched,
  selectPayments,
} from 'app/features/payments/selectors';

import NumberFormatCustom from 'app/components/NumberFormatCustom';
import ReactTable from 'react-table';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { makeSelectUserRoleFromState } from '../../../auth/selectors';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  paper: {
    widht: '100%',
    marginBottom: theme.spacing(0),
  },
  button: {
    margin: theme.spacing(1),
  },
  grid: {
    marginBottom: theme.spacing(1),
  },
  range: {
    paddingTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  datePicker: {
    width: 90,
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2, 0, 2, 0),
  },
  textfields: {
    marginRight: theme.spacing(1),
  },
  nameTextfield: {
    width: 280,
  },
  paymentMethodfield: {
    minWidth: 190,
  },
}));

function PaymentsTableForSearch(props) {
  const classes = useStyles();
  const {
    payments,
    paymentsSearched,
    searchInPayments,
    intl,
  } = props;
  const [nameToSearch, setNameToSearch] = useState('');
  const [paymentProcessor, setPaymentProcessor] = useState('');
  const [totalToSearch, setTotalToSearch] = useState('');
  const reactTable = React.useRef(null);

  const getOrderTypeFromPayment = (originalRow) => {
    let orderIdentificator;
    if (originalRow.serviceOrderID)
      orderIdentificator = 'serviceOrder';
    else if (originalRow.paymentOrderID)
      orderIdentificator = 'paymentOrder';
    else if (originalRow.creditOrderID)
      orderIdentificator = 'creditOrder';
    return orderIdentificator;
  };

  const getOrderIdFromPayment = (originalRow) => {
    const orderType = getOrderTypeFromPayment(originalRow);
    if (orderType === 'serviceOrder' && originalRow[orderType]) {
      return originalRow[orderType].orderID;
    } else if (
      orderType === 'paymentOrder' &&
      originalRow[orderType]
    ) {
      return originalRow[orderType].reference;
    } else if (
      orderType === 'creditOrder' &&
      originalRow[orderType]
    ) {
      return originalRow[orderType].clientID;
    }
    return '-';
  };

  const getNamePayment = (cardHolder, originalRow) => {
    if (cardHolder) return cardHolder;
    const orderType = getOrderTypeFromPayment(originalRow);
    if (orderType === 'serviceOrder' && originalRow[orderType]) {
      return getNested(
        '-',
        originalRow[orderType],
        'invoiceProfile',
        'fullName',
      );
    } else if (
      orderType === 'paymentOrder' &&
      originalRow[orderType]
    ) {
      return getNested('-', originalRow[orderType], 'nombre');
    } else if (
      orderType === 'creditOrder' &&
      originalRow[orderType]
    ) {
      return originalRow[orderType].clientName;
    }
    return '-';
  };

  const handleSearchPayments = () => {
    if (nameToSearch || totalToSearch || paymentProcessor)
      searchInPayments({
        cardHolder: nameToSearch,
        paymentProcessor,
        total: totalToSearch,
      });
  };

  const colums = [
    {
      Header: () => <strong>Agencia</strong>,
      accessor: 'agencyName',
      Cell: (row) => {
        const orderType = getOrderTypeFromPayment(row.original);
        const agencyName = getNested(
          '-',
          row.original[orderType],
          'agency',
          'name',
        );
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {agencyName}
          </div>
        );
      },
    },
    {
      Header: () => <strong>Cliente</strong>,
      accessor: 'cardHolder',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {getNamePayment(row.value, row.original)}
        </div>
      ),
      width: getColumnWidth(
        getNested([], paymentsSearched),
        'cardHolder',
        'Cliente                       ',
      ),
    },
    {
      Header: () => <strong>Monto</strong>,
      accessor: 'total',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {formatNumber(row.value)}
        </div>
      ),
      width: getColumnWidth(
        getNested([], paymentsSearched),
        'total',
        'Total    ',
      ),
    },
    {
      Header: () => <strong>Id de orden / cliente</strong>,
      accessor: 'orderID',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {getOrderIdFromPayment(row.original)}
        </div>
      ),
    },
    {
      Header: () => <strong>Fecha de pago</strong>,
      accessor: 'date',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {formatDate(row.value)}
        </div>
      ),
      width: getColumnWidth(
        getNested([], paymentsSearched),
        'date',
        'Fecha de pago     ',
      ),
    },
    {
      Header: () => <strong>Método de pago</strong>,
      accessor: 'type',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {intl.formatMessage({
            id: `PAYMENT.TYPE.${row.value}`,
          })}
        </div>
      ),
      width: getColumnWidth(
        getNested([], paymentsSearched),
        'paymentMethod',
        'Método de pago      ',
      ),
      Filter: (cell) => {
        return (
          // eslint-disable-next-line jsx-a11y/no-onchange
          <select
            onChange={(e) => cell.onChange(e.target.value)}
            value={
              cell.filter && cell.filter.value
                ? cell.filter.value
                : ''
            }
          >
            <option value="">Todos</option>
            <option value="DEBIT VISA">DÉBITO VISA</option>
            <option value="DEBIT MASTERCARD">
              DÉBITO MASTERCARD
            </option>
            <option value="CREDIT AMERICAN-EXPRESS">
              CRÉDITO AMEX
            </option>
            <option value="CREDIT VISA">CRÉDITO VISA</option>
            <option value="CREDIT MASTERCARD">
              CRÉDITO MASTERCARD
            </option>
            <option value="OXXO">OXXO</option>
            <option value="SPEI">SPEI</option>
          </select>
        );
      },
    },
  ];

  return (
    <div className={classes.root}>
      <Paper>
        <div className={classes.searchContainer}>
          <TextField
            className={clsx(
              classes.textfields,
              classes.nameTextfield,
            )}
            label="Nombre del cliente"
            type="text"
            variant="outlined"
            size="small"
            value={nameToSearch}
            onChange={({ target: { value } }) =>
              setNameToSearch(value)
            }
            InputProps={{
              startAdornment: (
                <IconButton
                  aria-label="Search"
                  size="small"
                  className={classes.calendarIcon}
                >
                  <SearchIcon />
                </IconButton>
              ),
            }}
          />
          <FormControl
            variant="outlined"
            size="small"
            className={clsx(
              classes.paymentMethodfield,
              classes.textfields,
            )}
          >
            <InputLabel id="label-payment-method">
              Método de pago
            </InputLabel>
            <Select
              labelId="label-payment-method"
              label="Método de pago"
              value={paymentProcessor}
              onChange={({ target: { value } }) =>
                setPaymentProcessor(value)
              }
            >
              <MenuItem value="billPocketID">Bill Pocket</MenuItem>
              <MenuItem value="conektaOxxoOrderID">Conekta</MenuItem>
              <MenuItem value="openPayID">Open Pay</MenuItem>
            </Select>
          </FormControl>
          <TextField
            className={classes.textfields}
            label="Total"
            type="text"
            variant="outlined"
            size="small"
            value={totalToSearch}
            onChange={({ target: { value } }) =>
              setTotalToSearch(value)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              inputComponent: NumberFormatCustom,
              inputProps: { quantity: true },
            }}
          />
          <Button
            className="btn btn-label-success btn-bold kt-margin-l-10"
            onClick={handleSearchPayments}
          >
            Buscar pagos
          </Button>
        </div>
        <ReactTable
          ref={reactTable}
          NoDataComponent={() => (
            <div className="rt-noData">Sin resultados</div>
          )}
          data={getNested([], paymentsSearched)}
          pages={payments.tablePages}
          columns={colums}
          defaultFilterMethod={filterCaseInsensitive}
          className="-striped -highlight"
          previousText="Anterior"
          nextText="Siguiente"
          loading={payments.isLoading}
          loadingText="Cargando datos..."
        />
      </Paper>
    </div>
  );
}

const mapStateToProps = (state) => ({
  payments: selectPayments(state),
  paymentsSearched: makeSelectPaymentsSearched(state),
  role: makeSelectUserRoleFromState(state),
});

const mapDispatchToProps = {
  getPayments: getPayments,
  getPaymentOrders: getPaymentsOrders,
  searchInPayments,
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PaymentsTableForSearch),
);
