import { call, put, select } from 'redux-saga/effects';
import { requestHandler } from '../../services/requestHandler';
import { formatDatesForFilter } from 'app/utils';
import {
  getChargebacksFail,
  getChargebacksSuccess,
  importChargebacksFromExcelFail,
  importChargebacksFromExcelSuccess,
  getChargebacksWTFSuccess,
  getChargebacksWTFFail,
  importChargebacksFromExcelWTFSuccess,
  importChargebacksFromExcelWTFFail,
  createChargebacksSuccess,
  createChargebacksFail,
} from './chargeBacksSlices';

export function* getChargebacksSaga(action) {
  const { page, pageSize, agencies, user, filtered } = action.payload;
  try {
    let offset = 0;
    let search = [];
    let filter = [];
    // const data = {
    //   search: null,
    //   filter: null,
    //   offset: 0,
    //   limit: 500,
    //   field: 'createdAt',
    //   ordering: 'DESC',
    //   type: 'default',
    // };
    if (filtered.length) {
      filtered.forEach((filters) => {
        if (filters.id === 'dmsId') {
          search.push('dmsId');
          filter.push(filters.value);
        }
        if (filters.id === 'transactionId') {
          search.push('transactionId');
          filter.push(filters.value);
        }
        if (filters.id === 'clientID') {
          search.push('user');
          filter.push(filters.value);
        }
        if (filters.id === 'dateDeposit') {
          search.push('dateDeposit');
          filter.push(filters.value);
        }
        if (filters.id === 'dateChargeback') {
          search.push('dateChargeback');
          filter.push(filters.value);
        }
        if (filters.id === 'datePayment') {
          search.push('datePayment');
          filter.push(filters.value);
        }
        if (filters.id === 'amountTx') {
          search.push('amountTx');
          filter.push(filters.value);
        }
        if (filters.id === 'updatedAt') {
          search.push('updatedAt');
          filter.push(filters.value);
        }
        if (filters.id === 'status') {
          search.push('status');
          filter.push(filters.value);
        }
      });
    }
    if (page > 0) {
      offset = pageSize * page;
    }
    const data = {
      search,
      filter,
      offset,
      limit: pageSize,
      field: 'createdAt',
      ordering: 'DESC',
    };
    const chargebacks = yield call(requestHandler, {
      method: 'POST',
      path: '/chargebacks/getAllChargebacks',
      data,
    });
    chargebacks.pages = yield call(
      Math.ceil,
      chargebacks.count / pageSize,
    );
    yield put(getChargebacksSuccess({ chargebacks }));
  } catch (error) {
    console.log('Error in get chargebacks saga', error);
    const { message } = error;
    yield put(getChargebacksFail({ message }));
  }
}

export function* importChargebacksFromExcelSaga(action) {
  try {
    const { data, handleRefreshData } = action.payload;
    const importExcel = yield call(requestHandler, {
      method: 'POST',
      path: '/chargebacks/importFromExcel',
      data,
    });
    const message = {
      title: 'Depósitos importados',
      desc: '¡Los depósitos han sido importados correctamente!',
    };

    yield put(importChargebacksFromExcelSuccess({ message }));
    yield call(handleRefreshData);
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaban importar los depósitos.
      Intente de nuevo, si el error persiste intente más tarde.`,
    };
    yield put(importChargebacksFromExcelFail({ message }));
    console.log('error in get detail payment saga', e);
  }
}

export function* getChargebacksWTFSaga(action) {
  const { pageSize, agencies, user } = action.payload;
  try {
    const data = {
      search: null,
      filter: null,
      offset: 0,
      limit: 500,
      field: 'createdAt',
      ordering: 'DESC',
      type: 'wtf',
    };
    const chargebacks = yield call(requestHandler, {
      method: 'POST',
      path: '/chargebacks/getAllChargebacks',
      data,
    });
    yield put(getChargebacksWTFSuccess({ chargebacks }));
  } catch (error) {
    console.log('Error in get chargebacks saga', error);
    const { message } = error;
    yield put(getChargebacksWTFFail({ message }));
  }
}

export function* importChargebacksFromExcelWTFSaga(action) {
  try {
    let { data, handleRefreshData } = action.payload;
    const importExcel = yield call(requestHandler, {
      method: 'POST',
      path: '/chargebacks/importFromExcelWTF',
      data,
    });
    const message = {
      title: 'Depósitos importados',
      desc: '¡Los depósitos han sido importados correctamente!',
    };

    yield put(importChargebacksFromExcelWTFSuccess({ message }));
    yield call(handleRefreshData);
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaban importar los depósitos.
      Intente de nuevo, si el error persiste intente más tarde.`,
    };
    yield put(importChargebacksFromExcelWTFFail({ message }));
    console.log('error in get detail payment saga', e);
  }
}

export function* createChargebackSaga(action) {
  try {
    let url;
    let data;
    let createOrupdate;
    if(!action.payload.id){
      createOrupdate = 'creado';
      url = '/chargebacks/create';
      data = {
        dmsId: action.payload.dmsId,
        transactionId: action.payload.transactionId,
        clientID: action.payload.clientID,
        dateDeposit: action.payload.dateDeposit,
        dateChargeback: action.payload.dateChargeback,
        datePayment: action.payload.datePayment,
        amountTx: action.payload.amountTx,
        status: action.payload.status,
      };
    }else{
      createOrupdate = 'actualizado';
      url = '/chargebacks/updated';
      data = {
        id: action.payload.id,
        status: action.payload.status,
      };
    }
    yield call(requestHandler, {
      method: 'POST',
      path: `${url}`,
      data,
    });
    const message = {
      title: 'Contracargo Hecho',
      desc: `¡El contracargo ha sido ${createOrupdate} correctamente!`,
    };
    yield put(createChargebacksSuccess({ message }));
  } catch (error) {
    const message = {
      title: 'Error',
      desc:
        'Ocurrio un error al mometo de crear el contracargo, Intente de nuevo, si el error persiste intente más tarde.',
    };
    yield put(createChargebacksFail({ message }));
  }
}
