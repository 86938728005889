import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';

const dispersionsSlice = createSlice({
  name: 'dispersions',
  initialState,
  reducers: {
    getDispersions: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getPaymentOrdersSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.fromdb = action.payload.orders.rows;
        state.tablePages = action.payload.orders.pages;
        state.toDownload = null;
        state.fullOrders = action.payload.orders.fullOrders;
      },
    },
    getPaymentOrdersFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.error = action.payload.error;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    getDispersionsDetails: {
      reducer(state, action) {
        state.isLoading = true;
        state.orderDetails = null;
      },
    },
    getPaymentOrderDetailSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.orderDetails = action.payload.order;
      },
    },
    getPaymentOrderDetailFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    closeModals: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = initialState.successModal;
        state.errorModal = initialState.errorModal;
      },
    },
  },
});

export const {
  getDispersions,
  getPaymentOrdersSuccess,
  getPaymentOrdersFail,
  getDispersionsDetails,
  getPaymentOrderDetailSuccess,
  getPaymentOrderDetailFail,
  closeModals,
} = dispersionsSlice.actions;

export default dispersionsSlice.reducer;
