import { call, put } from 'redux-saga/effects';
import {
  getKarloVehiclesSuccess,
  getKarloVehiclesFail,
  getVehicleDetailSuccess,
  getVehicleDetailFail,
} from 'app/features/karloVehicles/karloVehiclesSlice.js';

import { requestHandler } from '../../services/requestHandler';
import moment from 'moment';

export function* getKarloVehiclesSaga(action) {
  try {
    const { page, pageSize, filtered } = action.payload;
    const _renderPage = pageSize * page;
    const data = {
      offset: _renderPage,
      limit: pageSize,
      field: 'id',
      ordering: 'ASC',
    };
    const karloVehicles = yield call(requestHandler, {
      method: 'POST',
      path: '/karlo-app/getUsersWhitCars',
      data,
    });
    karloVehicles.pages = yield call(
      Math.ceil,
      karloVehicles.length / pageSize,
    );
    yield put(getKarloVehiclesSuccess({ karloVehicles }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba encontrar la información.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(getKarloVehiclesFail({ message }));
  }
}

export function* getVehicleDetailSaga(action) {
  try {
    const data = {
      carId: action.payload.karloID,
    };
    console.log(data);
    const vehicleDetails = yield call(requestHandler, {
      method: 'POST',
      path: '/karlo-app/getCarDetail',
      data,
    });
    yield put(getVehicleDetailSuccess({ vehicleDetails }));
    console.log('success in vehicles saga ', vehicleDetails);
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba encontrar la información del vehiculo.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(getVehicleDetailFail({ message }));
    console.log('error in vehicleDetail saga', e);
  }
}
