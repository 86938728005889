import {
  DialogTitle,
  Grid,
  Paper,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { formatNumber } from '../../../../utils';
import {
  makeSelectDepositDetailsFromState,
  selectDeposits,
} from 'app/features/deposits/selectors';

import moment from 'moment';
import ButtonExportExcel from 'app/components/ButtonExportExcel';
import Modal from '../../../../components/Modal';
import RecentActivities from '../../../../components/RecentActivities';
import ShouldItRender from 'app/components/ShouldItRender';
import SplashScreen from '../../../../partials/layout/SplashScreen';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getDepositSPEIDetail } from 'app/features/deposits/depositsSlice';
import { injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { makeSelectDepositsLogs } from '../../../logs/selectors';
import { makeSelectUserRoleFromState } from 'app/features/auth/selectors';
import { withRouter } from 'react-router';

const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  title: {
    textAlign: 'left',
    color: '#1c1c1c',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  rightContainer: {
    flexDirection: 'column',
  },
  tableTitle: {
    fontWeight: 401,
  },
  clickeableTR: {
    cursor: 'pointer',
  },
  chargebackRow: {
    backgroundColor: '#ffcccb',
  },
  table: {
    width: '100%',
    overflowX: 'auto',
  },
  clickeableTR: {
    cursor: 'pointer',
  },
}));

function DetailDepositSPEI(props) {
  const classes = useStyles();
  const {
    deposits,
    depositLogs,
    getDepositSPEIDetail,
    setLeftSideComponent,
    setActionButton,
    role,
    match,
    history,
    intl,
  } = props;
  const [hasSpeiPayments, setHasSpeiPayments] = useState(false);
  const [paymentsData, setPaymentsData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const { errorModal } = deposits;
    if (errorModal.show) {
      setShowModal(true);
    }
  }, [deposits]);

  useEffect(() => {
    setLeftSideComponent(
      <div className="kt-subheader__main">
        <div>
          <h3 className="kt-subheader__title">
            Detalle del depósito SPEI
          </h3>
        </div>
      </div>,
    );
    let data = {
      id: match.params.id,
    };
    getDepositSPEIDetail(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { details } = deposits;
    if (details) {
      const hasSpeiPayments = details.length > 0;
      setHasSpeiPayments(hasSpeiPayments);
      loadPaymentsInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deposits]);

  useEffect(() => {
    if (hasSpeiPayments)
      setActionButton(
        <ButtonExportExcel
          data={paymentsData}
          buttonText="Exportar movimientos"
        />,
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentsData, hasSpeiPayments]);

  const loadPaymentsInfo = () => {
    const { details } = deposits;
    const exportDataTemp = [];
    if (Array.isArray(details) && details.length > 0) {
      details.map((billpocketPayment) => {
        let type;
        switch (billpocketPayment.type) {
          case 'parts':
            type = 'Refacciones';
            break;
          case 'service':
            type = 'Servicio';
            break;
          case 'sales':
            type = 'Ventas';
            break;
          case 'fi':
            type = 'F&I';
            break;
          case 'pulled_apart':
            type = 'Apartado';
            break;
          case 'reservation':
            type = 'Reservación';
            break;

          default:
            type = 'Servicio';
            break;
        }
        const date = moment(billpocketPayment.depositPayment)
          .utc()
          .format('DD/MM/YYYY');
        const amount = formatNumber(billpocketPayment.totalAmount);
        exportDataTemp.push({
          // prettier-ignore
          'TIPO': type || '-',
          // prettier-ignore
          'NO. ORDEN': billpocketPayment.orderID || '-',
          // prettier-ignore
          'CLIENTE': billpocketPayment.nameCustomer || '-',
          // prettier-ignore
          'NOMBRE DE ORDENANTE': billpocketPayment.nombreOrdenante || '-',
          // prettier-ignore
          'NO. CUENTA': billpocketPayment.cuenta || '-',
          // prettier-ignore
          'RFC/CURP': billpocketPayment.rfc || '-',
          // prettier-ignore
          'CLAVE DE RASTREO': billpocketPayment.claveRastreo || '-',
          // prettier-ignore
          'FECHA DE PAGO': date || '-',
          // prettier-ignore
          'MONTO DE TRASACCIÓN': amount || '-',
        });
      });
    }
    setPaymentsData(exportDataTemp);
  };

  const handleCloseModals = () => {
    setShowModal(false);
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = deposits;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message } = deposits[modalType];
        return (
          <Modal
            open={showModal}
            type={modalType}
            closeModal={handleCloseModals}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={handleCloseModals}
          />
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  const renderDepostiDetails = () => {
    return (
      <Paper className={classes.paper}>
        <DialogTitle className={classes.title}>
          Resumen del depósito
        </DialogTitle>
        <Table bordered>
          <tbody>
            <tr>
              <td className={classes.tableTitle}>ID DEL DEPÓSITO</td>
              <td>{deposits?.deposit?.id}</td>
            </tr>
            <ShouldItRender should={role === 'Super Admin'}>
              <tr>
                <td className={classes.tableTitle}>AGENCIA</td>
                <td>{deposits?.agency}</td>
              </tr>
            </ShouldItRender>
            <tr>
              <td className={classes.tableTitle}>MONTO</td>
              <td>{formatNumber(deposits?.deposit?.totalAmount)}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>
                FECHA DE DEPÓSITO
              </td>
              <td>
                {deposits?.deposit?.depositTime
                  ? moment(deposits?.deposit?.depositTime)
                      .utc()
                      .format('DD/MM/YYYY')
                  : '-'}
              </td>
            </tr>
          </tbody>
        </Table>
      </Paper>
    );
  };

  const handleRedirectToPayments = (paymentID) => {
    history.push(`/detail-payment/${paymentID}`);
  };

  const renderBillpocketPaymentsDetails = () => {
    const { details } = deposits;
    if (Array.isArray(details) && details.length > 0) {
      const payments = details.map((billpocketPayment) => {
        let type;
        switch (billpocketPayment.type) {
          case 'parts':
            type = 'Refacciones';
            break;
          case 'service':
            type = 'Servicio';
            break;
          case 'sales':
            type = 'Ventas';
            break;
          case 'fi':
            type = 'F&I';
            break;
          case 'pulled_apart':
            type = 'Apartado';
            break;
          case 'reservation':
            type = 'Reservación';
            break;

          default:
            type = 'Servicio';
            break;
        }
        return (
          <tr key={billpocketPayment.id}>
            <td
              onClick={() =>
                handleRedirectToPayments(billpocketPayment.paymentID)
              }
              className={classes.clickeableTR}
              role="presentation"
            >
              {type}
            </td>
            <td
              onClick={() =>
                handleRedirectToPayments(billpocketPayment.paymentID)
              }
              className={classes.clickeableTR}
              role="presentation"
            >
              {billpocketPayment.orderID}
            </td>
            <td
              onClick={() =>
                handleRedirectToPayments(billpocketPayment.paymentID)
              }
              className={classes.clickeableTR}
              role="presentation"
            >
              {billpocketPayment.nameCustomer}
            </td>
            <td
              onClick={() =>
                handleRedirectToPayments(billpocketPayment.paymentID)
              }
              className={classes.clickeableTR}
              role="presentation"
            >
              {billpocketPayment.nombreOrdenante
                ? billpocketPayment.nombreOrdenante
                : '-'}
            </td>
            <td
              onClick={() =>
                handleRedirectToPayments(billpocketPayment.paymentID)
              }
              className={classes.clickeableTR}
              role="presentation"
            >
              {billpocketPayment.cuenta
                ? billpocketPayment.cuenta
                : '-'}
            </td>
            <td
              onClick={() =>
                handleRedirectToPayments(billpocketPayment.paymentID)
              }
              className={classes.clickeableTR}
              role="presentation"
            >
              {billpocketPayment.rfc ? billpocketPayment.rfc : '-'}
            </td>
            <td
              onClick={() =>
                handleRedirectToPayments(billpocketPayment.paymentID)
              }
              className={classes.clickeableTR}
              role="presentation"
            >
              {billpocketPayment.claveRastreo
                ? billpocketPayment.claveRastreo
                : '-'}
            </td>
            <td
              onClick={() =>
                handleRedirectToPayments(billpocketPayment.paymentID)
              }
              className={classes.clickeableTR}
              role="presentation"
            >
              {moment(billpocketPayment.depositPayment)
                .utc()
                .format('DD/MM/YYYY')}
            </td>
            <td
              onClick={() =>
                handleRedirectToPayments(billpocketPayment.paymentID)
              }
              className={classes.clickeableTR}
              role="presentation"
            >
              {formatNumber(billpocketPayment.totalAmount)}
            </td>
          </tr>
        );
      });
      return (
        <Paper className={classes.table}>
          <DialogTitle className={classes.title}>
            Movimientos
          </DialogTitle>
          <Table bordered size="sm">
            <thead>
              <tr>
                <th>TIPO</th>
                <th>NO. ORDEN</th>
                <th>CLIENTE</th>
                <th>NOMBRE DE ORDENANTE</th>
                <th>NO. CUENTA</th>
                <th>RFC/CURP</th>
                <th>CLAVE DE RASTREO</th>
                <th>FECHA DE PAGO</th>
                <th>MONTO DE TRANSACCIÓN</th>
              </tr>
            </thead>
            <tbody>{payments}</tbody>
          </Table>
        </Paper>
      );
    }
  };

  return isEmpty(deposits) ? (
    <SplashScreen></SplashScreen>
  ) : (
    <div>
      {renderFeedBackModal()}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          {renderDepostiDetails()}
        </Grid>
        <Grid item xs={12} md={8} className={classes.rightContainer}>
          {renderBillpocketPaymentsDetails()}
          {Array.isArray(depositLogs) && depositLogs.length > 0 && (
            <Grid item>
              <RecentActivities logs={depositLogs} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  deposits: selectDeposits(state),
  depositLogs: makeSelectDepositsLogs(state),
  role: makeSelectUserRoleFromState(state),
  depositDetails: makeSelectDepositDetailsFromState(state),
});

const mapDispatchToProps = {
  getDepositSPEIDetail: getDepositSPEIDetail,
};

export default withRouter(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(DetailDepositSPEI),
  ),
);
