import AddAdvance from 'app/features/advances/containers/AddAdvance';
import AddCreditOrder from './features/creditOrders/containers/AddCreditOrder';
import AddPaymentOrder from './features/paymentOrders/containers/AddPaymentOrder';
import AddPromotion from './features/karloPromotions/containers/AddPromotion';
import EditPromotion from './features/karloPromotions/containers/EditPromotion';
import AddAdvice from './features/karloAdvices/containers/AddAdvice';
import EditAdvice from './features/karloAdvices/containers/EditAdvice';
import AdvancesTable from 'app/features/advances/containers/AdvancesTable';
import AdvisorCalculator from './features/calculator/containers/AdvisorCalculator';
import AgenciesTable from './features/agencies/containers/AgenciesTable';
import AppointmentsTable from './features/serviceAppointments/containers/AppointmentsTable';
import VehiclesTable from './features/vehicles/containers/VehiclesTable';
import Calculator from './features/calculator/containers/Calculator';
import Calculator2023 from './features/calculator/containers/Calculator2023/container';
import CreditOrdersTable from './features/creditOrders/containers/CreditOrdersTable';
import CreditPaymentsTable from './features/payments/containers/CreditPaymentsTable';
import Dashboard from './features/dashboard/containers/DashBoard';
import DepositsTable from 'app/features/deposits/containers/DepositsTable';
import DetailAdvance from 'app/features/advances/containers/DetailAdvance';
import DetailAgency from './features/agencies/containers/DetailAgency';
import DetailCreditOrder from './features/creditOrders/containers/DetailCreditOrder';
import DetailDeposit from 'app/features/deposits/containers/DetailDeposit';
import DetailOrder from './features/serviceOrders/containers/DetailOrder';
import DetailAppointment from './features/serviceAppointments/containers/DetailAppointment';
import DetailPayment from './features/payments/containers/DetailPayment';
import DetailPaymentOrder from './features/paymentOrders/containers/DetailPaymentOrder';
import EnhancedTable from './features/serviceOrders/containers/EnhancedTable';
import TerminalsTable from './features/terminals/containers/TerminalsTable';
import KarloUsersTable from './features/karloUsers/containers/KarloUsersTable';
import KarloUserDetail from './features/karloUsers/containers/UserDetail';
import KarloOffersTable from './features/karloOffers/containers/KarloOffersTable';
import OfferDetail from './features/karloOffers/containers/OfferDetail';
import KarloAdvicesTable from './features/karloAdvices/containers/KarloAdvicesTable';
import KarloPromotionsTable from './features/karloPromotions/containers/KarloPromotionsTable';
import KarloQuotesTable from './features/karloQuotes/containers/KarloQuotesTable';
import KarloVehiclesTable from './features/karloVehicles/containers/KarloVehiclesTable';
import KarloVehicleDetail from './features/karloVehicles/containers/VehicleDetail';
import PaymentOrdersTable from './features/paymentOrders/containers/Table';
import PaymentsTable from './features/payments/containers/PaymentsTable';
import PaymentsTableClear from './features/payments/containers/PaymentsTableClear';
import PaymentsTableForSearch from 'app/features/payments/containers/PaymentsTableForSearch';
import PaymentsTableSpeiValidation from 'app/features/speiPayments/containers/PaymentsTableSpeiValidation';
import ProfileAgency from './features/setup/containers/ProfileAgency';
import PromotionsCalculator from 'app/features/calculator/containers/PromotionsCalculator';
import RedirecToLanding from './features/kiosko/components/RedirecToLandingPaymentOrders';
import downloadFile from './features/support/SupportTable/dowloadPDF';
import ReportsTable from './features/reports/containers/ReportsTable';
import UsersTable from './features/users/containers/UsersTable';
import Faqs from './features/faqs/containers/index';
import Reservations from './features/reservations/containers/ReservationsTable';
import ReservationsCruise from './features/reservationsCruise/containers/ReservationsTable';
import ReservationDetail from './features/reservations/containers/ReservationDetail';
import CreateReservation from './features/reservations/containers/CreateReservation';
import CreateReservationCruise from './features/reservationsCruise/containers/CreateReservation';
import Payments from './features/reservations/containers/Payments';
import Chargebacks from './features/chargebacks/containers/ChargebacksTable';
import Support from './features/support/SupportTable';
import CalculatorTPV from './features/calculatorTPV/container/CalculatorTPV';
import ChargebacksWTF from './features/chargebacks/containers/ChargebacksTableWTF';
import DetailDepositSPEI from './features/deposits/containers/DetailDepositSPEI';
import ServiceReviewTable from './features/serviceReview/containers/serviceReviewTable';
import DispersionTable from './features/dispersions/containers/Table';
import DetailDispersion from './features/dispersions/containers/DetailDispersion';
import Calculatorv2 from './features/calculator/containers/CalculatorV2/containers/Detail';
import DepositsFiservTable from 'app/features/depositsfiserv/containers/DepositsTable';
import DetailDepositFiserv from 'app/features/depositsfiserv/containers/DetailDeposit';
import DetailDepositStandAlone from 'app/features/standAlone/deposits/containers/DetailStandAloneDeposit';
import DepositsStandAloneTable from 'app/features/standAlone/deposits/containers/DepositsStandAloneTable';
import DetailPaymentsStandAlone from 'app/features/standAlone/payments/containers/DetailStandAloneDeposit';
import PaymentsStandAloneTable from 'app/features/standAlone/payments/containers/PaymentsStandAloneTable';

const RoutesControllers = {
  '/': Dashboard,
  '/agencies': AgenciesTable,
  '/add-advance': AddAdvance,
  '/add-external-advance': AddAdvance,
  '/add-paymentorder': AddPaymentOrder,
  '/add-creditorder': AddCreditOrder,
  '/calculator': Calculator,
  '/calculator-v2': Calculatorv2,
  '/promotions-calculator': PromotionsCalculator,
  '/advisorcal': AdvisorCalculator,
  '/calculator2023': Calculator2023,
  '/dashboard': Dashboard,
  '/detail-appointment/:id': DetailAppointment,
  '/detail-order': DetailOrder,
  '/detail-order/:id/:agencyId': DetailOrder,
  '/detail-paid-so/:id/:agencyId': DetailOrder,
  '/detail-agency/:id': DetailAgency,
  '/detail-payment-order/:id/:agencyId': DetailPaymentOrder,
  '/detail-advance/:id/:agencyId': DetailAdvance,
  '/detail-credit-order/:id': DetailCreditOrder,
  '/detail-paid-po/:id/:agencyId/:paymentID': DetailPaymentOrder,
  '/detail-payment/:paymentID': DetailPayment,
  '/paymentorders': PaymentOrdersTable,
  '/creditorders': CreditOrdersTable,
  '/advances': AdvancesTable,
  '/payments': PaymentsTable,
  '/paymentsclear': PaymentsTableClear,
  '/creditpayments': CreditPaymentsTable,
  '/paymentssearch': PaymentsTableForSearch,
  '/redirect-to-landing/:orderType': RedirecToLanding,
  '/redirect-to-landing': RedirecToLanding,
  '/reports': ReportsTable,
  '/setup': ProfileAgency,
  '/dispersions': DispersionTable,
  '/detail-dispersions/:id': DetailDispersion,
  '/depositfiserv': DepositsFiservTable,
  '/stand-alone/payments': PaymentsStandAloneTable,
  '/stand-alone/deposits': DepositsStandAloneTable,

  '/add-promotion': AddPromotion,
  '/edit-promotion/:id': EditPromotion,
  '/add-advice': AddAdvice,
  '/edit-advice/:id': EditAdvice,
  '/terminals': TerminalsTable,
  '/karlousers': KarloUsersTable,
  '/karlouser-detail/:id': KarloUserDetail,
  '/karlovehicles': KarloVehiclesTable,
  '/vehicles': VehiclesTable,
  '/serviceappointments': AppointmentsTable,
  '/karlovehicle-detail/:id': KarloVehicleDetail,
  '/offers': KarloOffersTable,
  '/offer-detail/:id': OfferDetail,
  '/advices': KarloAdvicesTable,
  '/karloPromotions': KarloPromotionsTable,
  '/karloQuotes': KarloQuotesTable,

  '/serviceorders': EnhancedTable,
  '/speipayments': PaymentsTableSpeiValidation,
  '/deposits': DepositsTable,
  '/detail-deposit/:id': DetailDeposit,
  '/detail-deposit-spei/:id': DetailDepositSPEI,
  '/users': UsersTable,
  '/guia-de-uso/:typeRole': downloadFile,
  '/reservations': Reservations,
  '/reservations-cruise': ReservationsCruise,
  '/reservation-order/:id': ReservationDetail,
  '/reservation-order-cruise/:id': ReservationDetail,
  '/create-reservation': CreateReservation,
  '/create-reservation-cruise': CreateReservationCruise,
  '/payments-reservation': Payments,
  '/chargebacks': Chargebacks,
  '/support': Support,
  '/calculator-tpv': CalculatorTPV,
  '/chargebackswtf': ChargebacksWTF,
  '/reviews': ServiceReviewTable,
  '/detail-depositfiserv/:id': DetailDepositFiserv,
  '/detail-deposit-standalone/:id/:agencyId': DetailDepositStandAlone,
  '/detail-payments-standalone/:id/:agencyId':
    DetailPaymentsStandAlone,
};

export default RoutesControllers;
