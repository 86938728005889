import { initialState } from './initialState';
import { createSlice } from '@reduxjs/toolkit';

const reservationsCruiseSlice = createSlice({
  name: 'reservations',
  initialState,
  reducers: {
    getResrvations: {
      reducer(state) {
        state.isLoading = true;
        state.error = false;
      },
    },
    getResrvationsSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.error = false;
        state.fromdb = action.payload.result;
        state.pages = action.payload.page;
        state.successModal = {
          show: false,
          // ...action.payload,
        };
        state.errorModal = {
          show: false,
        };
      },
    },
    getResrvationsFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.error = true;
        state.successModal = {
          show: false,
          // ...action.payload,
        };
        state.errorModal = {
          show: false,
        };
      },
    },
    getResrvationDeatil: {
      reducer(state) {
        state.isLoading = true;
        state.error = false;
      },
    },
    getResrvationDeatilSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.error = false;
        state.reservationDetail =
          action.payload.reservationDetail?.reservation;
      },
    },
    getResrvationDeatilFail: {
      reducer(state) {
        state.isLoading = false;
        state.error = true;
      },
    },
    getAllPayments: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getAllPaymentsSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.fromdbPayments = action.payload?.payments?.rows;
        state.pagesPayments = action.payload?.payments?.pages;
      },
    },
    getAllPaymentsFail: {
      reducer(state) {
        state.isLoading = false;
      },
    },
    createReservation: {
      reducer(state) {
        state.isLoading = true;
        state.error = false;
      },
    },
    createReservationSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.error = false;
        state.redirect = action.payload.redirect;
        state.successModal = {
          show: true,
          // ...action.payload,
        };
      },
    },
    createReservationFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          // ...action.payload,
        };
      },
    },
    updatePaymentOrderReservation: {
      reducer(state) {
        state.isLoading = true;
        state.error = false;
      },
    },
    updatePaymentOrderSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.error = false;
        state.reservationDetail = action.payload?.paymentOrder;
      },
    },
    updatePaymentOrderFail: {
      reducer(state) {
        state.isLoading = false;
        state.error = false;
      },
    },
    closeModal: {
      reducer(state) {
        state.successModal = {
          show: false,
        };
        state.errorModal = {
          show: false,
        };
      },
    },
  },
});

export const {
  getResrvations,
  getResrvationsSuccess,
  getResrvationsFail,
  getResrvationDeatil,
  getResrvationDeatilSuccess,
  getResrvationDeatilFail,
  createReservation,
  createReservationSuccess,
  createReservationFail,
  closeModal,
  getAllPayments,
  getAllPaymentsSuccess,
  getAllPaymentsFail,
  updatePaymentOrderReservation,
  updatePaymentOrderSuccess,
  updatePaymentOrderFail,
} = reservationsCruiseSlice.actions;

export default reservationsCruiseSlice.reducer;
