export const initialState = {
  fromdb: null,
  tablePages: 0,
  speiPaymentsForDownload: null,
  paymentsSearchedFromdb: null,
  speiPaymentDetails: null,
  successModal: {
    show: false,
    message: null,
  },
  errorModal: {
    show: false,
    message: null,
  },
  isLoading: false,
  error: null,
};
