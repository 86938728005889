import {
  DialogTitle,
  Grid,
  Paper,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
  formatDate,
  formatSingleDate,
  formatNumber,
  getNested,
} from '../../../../utils';
import {
  makeSelectAgencies,
  selectAgencies,
} from 'app/features/agencies/selectors';
import {
  makeSelectDepositDetailsFromState,
  selectDepositsFiserv,
} from 'app/features/depositsfiserv/selectors';

import moment from 'moment';
import ButtonExportExcel from 'app/components/ButtonExportExcel';
import Modal from '../../../../components/Modal';
import RecentActivities from '../../../../components/RecentActivities';
import ShouldItRender from 'app/components/ShouldItRender';
import SplashScreen from '../../../../partials/layout/SplashScreen';
import { Table } from 'react-bootstrap';
import { closeModals } from '../../../modals/modalsSlice';
import { connect } from 'react-redux';
import { getDepositDetailfiserv } from 'app/features/depositsfiserv/depositsSlice';
import { injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { makeSelectDepositsLogs } from '../../../logs/selectors';
import { makeSelectUserRoleFromState } from 'app/features/auth/selectors';
import { withRouter } from 'react-router';

const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  title: {
    textAlign: 'left',
    color: '#1c1c1c',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  rightContainer: {
    flexDirection: 'column',
  },
  tableTitle: {
    fontWeight: 401,
  },
  clickeableTR: {
    cursor: 'pointer',
  },
  chargebackRow: {
    backgroundColor: '#ffcccb',
  },
  table: {
    width: '100%',
    overflowX: 'auto',
  },
}));

export const DetailDeposit = (props) => {
  const classes = useStyles();
  const {
    closeModals,
    deposits,
    depositDetails,
    depositLogs,
    getDepositDetailfiserv,
    setLeftSideComponent,
    setActionButton,
    role,
    match,
    history,
    intl,
    agencies,
  } = props;
  const [totalAmount, setTotalAmount] = useState(false);
  const [totalComissions, setTotalComissions] = useState(false);
  const [totalIVA, setTotalIVA] = useState(false);
  const [totalChargebacks, settTtalChargebacks] = useState(false);
  const [hasSpeiPayments, setHasSpeiPayments] = useState(false);
  const [paymentsData, setPaymentsData] = useState([]);

  useEffect(() => {
    setLeftSideComponent(
      <div className="kt-subheader__main">
        <div>
          <h3 className="kt-subheader__title">
            Detalle del depósito fiserv
          </h3>
        </div>
      </div>,
    );
    let data = {
      id: match.params.id,
    };
    getDepositDetailfiserv(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (depositDetails) {
      const hasSpeiPayments =
        depositDetails?.SPEIPayments?.length > 0;
      setHasSpeiPayments(hasSpeiPayments);
      loadPaymentsInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depositDetails]);

  useEffect(() => {
    if (!hasSpeiPayments)
      setActionButton(
        <ButtonExportExcel
          data={paymentsData}
          buttonText="Exportar movimientos"
        />,
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentsData, hasSpeiPayments]);

  const renderPaymentForm = (months) => {
    if (months) return `${months} meses`;
    else return 'Una sola exhibición';
  };
  const loadPaymentsInfo = () => {
    const fiservPayments = depositDetails?.depositFiservDetails
      ? depositDetails?.depositFiservDetails
      : {};
    if (fiservPayments.length > 0) {
      const exportDataTemp = [];
      let totalAmountTemp = 0;
      let totalComissionsTemp = 0;
      let totalIVATemp = 0;
      let totalChargebacksTemp = 0;
      let totalDeposit = 0;

      fiservPayments.forEach((fiservPayment) => {
        if (fiservPayment?.chargeback)
          totalChargebacksTemp += fiservPayment?.total;
        else {
          totalAmountTemp += fiservPayment?.total;
          totalComissionsTemp += fiservPayment?.comision;
          totalIVATemp += fiservPayment?.iva;
        }
        if (depositDetails?.agency?.toogleZeroTaxes) {
          totalDeposit = fiservPayment?.total;
        } else {
          totalDeposit = fiservPayment?.amount;
        }

        let type = fiservPayment?.payment?.type;
        if (!type) type = 'TPV';

        let dateBpPayment = fiservPayment?.payment?.date;
        exportDataTemp.push({
          TIPO: handleGetOrderType(fiservPayment?.payment),
          AGENCIA: handleGetAgencyName(fiservPayment?.payment),
          'ID AGENCIA': handleGetOrderID(fiservPayment?.payment),
          'NOMBRE DEL BANCO': fiservPayment?.payment?.bank
            ? fiservPayment?.payment?.bank
            : '-',
          'FECHA DE PAGO': dateBpPayment
            ? formatDate(dateBpPayment)
            : '',
          'MONTO SOBRETASA': formatNumber(fiservPayment.payment.surchargeTotal),
          'IVA SOBRETASA': formatNumber(fiservPayment.payment.ivaSurcharge),
          'MONTO DE TRANSACCIÓN': formatNumber(
            fiservPayment.payment.total,
          ),
          COMISION: formatNumber(fiservPayment.commissions),
          IVA: formatNumber(fiservPayment.ivaCommissions),
          'TOTAL A DEPOSITAR': formatNumber(
            fiservPayment.payment.totalToDepositAgency,
          ),
          'MÉTODO DE PAGO': type,
          'FORMA DE PAGO': renderPaymentForm(
            fiservPayment?.payment?.months,
          ),
        });
      });
      setPaymentsData(exportDataTemp);
      setTotalAmount(totalAmountTemp);
      setTotalComissions(fiservPayments.totalComissions);
      setTotalIVA(totalIVATemp);
      settTtalChargebacks(totalChargebacksTemp);
    }
  };

  const handleGetOrderType = (payment) => {
    const serviceOrder = payment?.serviceOrder;
    const paymentOrder = payment?.paymentOrder;
    const creditOrder = payment?.creditOrder;

    if (serviceOrder) return 'Ods';
    else if (paymentOrder) return 'Odp';
    else if (creditOrder) return creditOrder?.id;
    return 'Tpv';
  };

  const handleGetOrderID = (payment) => {
    const serviceOrder = payment?.serviceOrder;
    const paymentOrder = payment?.paymentOrder;
    const creditOrder = payment?.creditOrder;
    if (serviceOrder) return serviceOrder?.orderID;
    else if (paymentOrder) return paymentOrder?.reference;
    else if (creditOrder) return creditOrder?.id;
  };

  const handleGetAgencyName = (payment) => {
    const serviceOrder = payment?.serviceOrder;
    const paymentOrder = payment?.paymentOrder;
    const creditOrder = payment?.creditOrder;
    if (serviceOrder) return serviceOrder?.agency?.name;
    else if (paymentOrder) return paymentOrder?.agency?.name;
    else if (creditOrder) return creditOrder?.agency?.name;
  };

  const handleGetOrderClientName = (payment) => {
    const serviceOrder = payment?.serviceOrder;
    const paymentOrder = payment?.paymentOrder;
    const creditOrder = payment?.creditOrder;
    if (serviceOrder) return serviceOrder?.invoiceProfile?.fullName;
    else if (paymentOrder) return paymentOrder?.nombre;
    else if (creditOrder) return creditOrder?.clientName;
  };

  const handleGetOrderBank = (payment) => {
    const serviceOrder = payment?.serviceOrder;
    const paymentOrder = payment?.paymentOrder;
    const creditOrder = payment?.creditOrder;
    if (serviceOrder) return serviceOrder?.invoiceProfile?.fullName;
    else if (paymentOrder) return paymentOrder?.nombre;
    else if (creditOrder) return creditOrder?.clientName;
  };

  const handleCloseModals = () => {
    closeModals();
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = deposits;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
            ? 'errorModal'
            : null;
        const { show, message } = deposits[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={handleCloseModals}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={handleCloseModals}
          />
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  const renderDepostiDetails = () => {
    return (
      <Paper className={classes.paper}>
        <DialogTitle className={classes.title}>
          Resumen del depósito
        </DialogTitle>
        <Table bordered>
          <tbody>
            <tr>
              <td className={classes.tableTitle}>ID DEL DEPÓSITO</td>
              <td>{getNested('-', depositDetails, 'id')}</td>
            </tr>
            <ShouldItRender should={role === 'Super Admin'}>
              <tr>
                <td className={classes.tableTitle}>AGENCIA</td>
                <td>
                  {getNested('-', depositDetails, 'agency', 'name')}
                </td>
              </tr>
            </ShouldItRender>
            <ShouldItRender should={hasSpeiPayments}>
              <tr>
                <td className={classes.tableTitle}>
                  FOLIO DE AUTORIZACIÓN BANCARIA
                </td>
                <td>
                  {getNested(
                    '-',
                    depositDetails,
                    'bankAuthorization',
                  )}
                </td>
              </tr>
            </ShouldItRender>
            <tr>
              <td className={classes.tableTitle}>MONTO</td>
              <td>
                {formatNumber(
                  getNested('-', depositDetails, 'totalToDeposit'),
                )}
              </td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>
                FECHA DE DEPÓSITO
              </td>
              <td>
                {depositDetails?.depositDate
                  ? moment(depositDetails?.depositDate)
                    .utc()
                    .format('DD/MM/YYYY')
                  : '-'}
              </td>
              {/* <td>{formatDate(depositDetails?.depositTime)}</td> */}
            </tr>
          </tbody>
        </Table>
      </Paper>
    );
  };
  //Resumen del depósito
  const renderBreakdownDetails = () => {
    let totalDeposit = 0;
    if (depositDetails?.agency?.toogleZeroTaxes) {
      totalDeposit = totalAmount;
    } else {
      totalDeposit = depositDetails?.amount;
    }
    return (
      <Paper className={classes.paper}>
        <DialogTitle className={classes.title}>Desglose</DialogTitle>
        <Table bordered>
          <tbody>
            <tr>
              <td className={classes.tableTitle}>SUMA TOTAL</td>
              <td>
                {formatNumber(
                  getNested('-', depositDetails, 'totalPayments'),
                )}
              </td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>
                SUMA DE COMISIONES
              </td>
              <td>
                {formatNumber(
                  getNested('-', depositDetails, 'totalCommissions'),
                )}
              </td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>SUMA DE IVA</td>
              <td>
                {formatNumber(
                  getNested(
                    '-',
                    depositDetails,
                    'totalIvaCommissions',
                  ),
                )}
              </td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>TOTAL DEPOSITADO</td>
              <td>
                {formatNumber(
                  getNested('-', depositDetails, 'totalToDeposit'),
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </Paper>
    );
  };
  //Desglose
  const handleRedirectToPayments = (paymentID) => {
    history.push(`/detail-payment/${paymentID}`);
  };
  //Movimientos
  const renderPaymentsDetails = () => {
    const speiPayments = depositDetails?.SPEIPayments;
    if (speiPayments.length > 0)
      return (
        <Paper>
          <DialogTitle className={classes.title}>
            Movimientos
          </DialogTitle>
          <Table bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>NÚMERO DE TRANSACCIÓN</th>
                <th>MONTO</th>
                <th>FECHA DE PAGO</th>
              </tr>
            </thead>
            <tbody>
              {speiPayments.map((speiPayment) => {
                const paymentID = speiPayment?.payment?.id;
                return (
                  <tr key={`spei-payment-${speiPayment.id}`}>
                    <td
                      className={classes.clickeableTR}
                      role="presentation"
                      onClick={() =>
                        handleRedirectToPayments(paymentID)
                      }
                    >
                      {speiPayment.id}
                    </td>
                    <td
                      className={classes.clickeableTR}
                      role="presentation"
                      onClick={() =>
                        handleRedirectToPayments(paymentID)
                      }
                    >
                      {speiPayment.transactionNumber}
                    </td>
                    <td
                      className={classes.clickeableTR}
                      role="presentation"
                      onClick={() =>
                        handleRedirectToPayments(paymentID)
                      }
                    >
                      {formatNumber(speiPayment.amount)}
                    </td>
                    <td
                      className={classes.clickeableTR}
                      role="presentation"
                      onClick={() =>
                        handleRedirectToPayments(paymentID)
                      }
                    >
                      {formatDate(speiPayment.date)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Paper>
      );
  };

  const renderFiservPaymentsDetails = () => {
    const fiservPayments = depositDetails?.depositFiservDetails
      ? depositDetails?.depositFiservDetails
      : {};
    if (fiservPayments.length > 0) {
      let totalDeposit = 0;
      const payments = fiservPayments.map((fiservPayment) => {
        const paymentID = fiservPayment?.payment?.id;
        if (depositDetails?.agency?.toogleZeroTaxes) {
          totalDeposit = fiservPayment.total;
        } else {
          totalDeposit = fiservPayment.amount;
        }

        let dateBpPayment = fiservPayment?.payment?.date;
        return (
          <tr
            key={`spei-payment-${fiservPayment.id}`}
            className={
              fiservPayment?.chargeback ? classes.chargebackRow : null
            }
          >
            <td
              className={classes.clickeableTR}
              role="presentation"
              onClick={() => handleRedirectToPayments(paymentID)}
            >
              {handleGetOrderType(fiservPayment?.payment)}
            </td>
            <td
              className={classes.clickeableTR}
              role="presentation"
              onClick={() => handleRedirectToPayments(paymentID)}
            >
              {handleGetAgencyName(fiservPayment?.payment)}
            </td>
            <td
              className={classes.clickeableTR}
              role="presentation"
              onClick={() => handleRedirectToPayments(paymentID)}
            >
              {handleGetOrderID(fiservPayment?.payment)}
            </td>
            <td
              className={classes.clickeableTR}
              role="presentation"
              onClick={() => handleRedirectToPayments(paymentID)}
            >
              {fiservPayment?.payment?.bank
                ? fiservPayment?.payment?.bank
                : '-'}
            </td>
            <td
              className={classes.clickeableTR}
              role="presentation"
              onClick={() => handleRedirectToPayments(paymentID)}
            >
              {dateBpPayment
                ? formatDate(dateBpPayment)
                : moment(fiservPayment.date)
                  .utc()
                  .format('DD/MM/YYYY h:mma')}
            </td>
            <td
              className={classes.clickeableTR}
              role="presentation"
              onClick={() => handleRedirectToPayments(paymentID)}
            >
              {formatNumber(fiservPayment.payment.surchargeTotal)}
            </td>
            <td
              className={classes.clickeableTR}
              role="presentation"
              onClick={() => handleRedirectToPayments(paymentID)}
            >
              {formatNumber(fiservPayment.payment.ivaSurcharge)}
            </td>
            <td
              className={classes.clickeableTR}
              role="presentation"
              onClick={() => handleRedirectToPayments(paymentID)}
            >
              {formatNumber(fiservPayment.payment.total)}
            </td>
            <td
              className={classes.clickeableTR}
              role="presentation"
              onClick={() => handleRedirectToPayments(paymentID)}
            >
              {formatNumber(fiservPayment.commissions)}
            </td>
            <td
              className={classes.clickeableTR}
              role="presentation"
              onClick={() => handleRedirectToPayments(paymentID)}
            >
              {formatNumber(fiservPayment.ivaCommissions)}
            </td>
            <td
              className={classes.clickeableTR}
              role="presentation"
              onClick={() => handleRedirectToPayments(paymentID)}
            >
              {formatNumber(
                fiservPayment.payment.totalToDepositAgency,
              )}
            </td>
          </tr>
        );
      });
      return (
        <Paper className={classes.table}>
          <DialogTitle className={classes.title}>
            Movimientos
          </DialogTitle>
          <Table bordered size="sm">
            <thead>
              <tr>
                <th>TIPO</th>
                <th>AGENCIA</th>
                <th>ID AGENCIA</th>
                <th>NOMBRE DEL BANCO</th>
                <th>FECHA DE PAGO</th>
                <th>MONTO SOBRETASA</th>
                <th>IVA SOBRETASA</th>
                <th>MONTO DE TRANSACCIÓN</th>
                <th>COMISION</th>
                <th>IVA</th>
                <th>TOTAL A DEPOSITAR</th>
              </tr>
            </thead>
            <tbody>{payments}</tbody>
          </Table>
        </Paper>
      );
    }
  };

  return isEmpty(depositDetails) ? (
    <SplashScreen></SplashScreen>
  ) : (
    <div>
      {renderFeedBackModal()}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          {renderDepostiDetails()}
          {!hasSpeiPayments && renderBreakdownDetails()}
        </Grid>
        <Grid item xs={12} md={8} className={classes.rightContainer}>
          {hasSpeiPayments
            ? renderPaymentsDetails()
            : renderFiservPaymentsDetails()}
          {Array.isArray(depositLogs) && depositLogs.length > 0 && (
            <Grid item>
              <RecentActivities logs={depositLogs} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  deposits: selectDepositsFiserv(state),
  depositDetails: makeSelectDepositDetailsFromState(state),
  depositLogs: makeSelectDepositsLogs(state),
  role: makeSelectUserRoleFromState(state),
  agencies: makeSelectAgencies(state),
});

const mapDispatchToProps = {
  closeModal: closeModals,
  getDepositDetailfiserv: getDepositDetailfiserv,
};

export default withRouter(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(DetailDeposit),
  ),
);
