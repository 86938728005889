import 'react-table/react-table.css';

import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
/* eslint-disable react/display-name */
import React, { useEffect } from 'react';

import {
  IconButton,
  Badge as MaterialBadge,
  Paper,
  Tooltip,
  makeStyles,
  Button,
  Typography,
} from '@material-ui/core';

import { Badge } from 'react-bootstrap';

import Add from '@material-ui/icons/Add';
import EmailIcon from '@material-ui/icons/Email';
import FileCopyTwoTone from '@material-ui/icons/FileCopyTwoTone';
import Close from '@material-ui/icons/Close';
import PaymentIcon from '@material-ui/icons/Payment';
import PublishIcon from '@material-ui/icons/Publish';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import {
  formatNumber,
  getBadgeColor,
  getColumnWidth,
  getNested,
} from '../../../../utils';
import { MenuOption } from 'app/components/MenuOption';
import MUIRHFAutocompleteAgencies from 'app/components/MUIRHFAutocompleteAgencies';
import Modal from '../../../../components/Modal';
import ShouldItRender from '../../../../components/ShouldItRender';

import {
  getAllCreditOrders,
  uploadExcelFile,
  uploadPaymentsFromExcelFile,
} from '../../creditOrdersSlice';
import { closeModals } from '../../../modals/modalsSlice';
import {
  makeSelectCreditOrdersFromdb,
  makeSelectCreditOrdersToDownload,
} from '../../selectors';
import { makeSelectUserRoleFromState } from '../../../auth/selectors';

import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(0),
  },
  wrapper: {
    position: 'relative',
  },
  notifications: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  range: {
    paddingTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  datePicker: {
    width: '90px',
  },
  icons: {
    color: '#0abb87',
    marginRight: '10px',
  },
  colorPrimary: {
    color: '#0abb87',
  },
  menuDesktop: {
    ['@media screen and (max-width: 1496px)']: {
      // eslint-disable-line no-useless-computed-key
      display: 'none',
    },
    marginTop: '13px',
  },
  menuMobile: {
    ['@media screen and (min-width: 1495px)']: {
      // eslint-disable-line no-useless-computed-key
      display: 'none',
    },
  },
}));

const CreditOrdersTable = (props) => {
  const classes = useStyles();
  const {
    creditOrders,
    creditOrdersFromdb,
    creditOrdersToDownload,
    role,
    getAllCreditOrders,
    uploadExcelFile,
    uploadPaymentsFromExcelFile,
    closeModals,
    intl,
  } = props;
  const [excelFile, setExcelFile] = React.useState(null);
  const [showExcelFileModal, setShowExcelFileModal] =
    React.useState(false);
  const [showImportPaymentsModal, setShowImportPaymentsModal] =
    React.useState(false);
  const { handleSubmit, control, errors } = useForm();
  const [firstDate, setFirstDate] = React.useState(null);
  const [secondDate, setSecondDate] = React.useState(null);
  const reactTable = React.useRef(null);

  useEffect(() => {
    if ((firstDate, secondDate)) handleRefreshData();
  }, [firstDate, secondDate]);

  useEffect(() => {
    let paymentsToCSV = [];
    if (
      Array.isArray(creditOrdersToDownload) &&
      creditOrdersToDownload.length > 0
    ) {
      paymentsToCSV = creditOrdersToDownload
        .filter((x) => x?.agency?.isActiveAgency === true)
        .map((creditOrder) => {
          let payments = 0;
          payments += creditOrder.enganche;
          if (
            Array.isArray(creditOrder.payments) &&
            creditOrder.payments.length > 0
          )
            payments = creditOrder.payments.reduce(
              (accumulator, payment) => {
                if (payment.status === 'DONE')
                  return (accumulator += payment.amount);
                return accumulator;
              },
              payments,
            );
          const balance = formatNumber(creditOrder.total - payments);
          return {
            'ID cliente': getNested('-', creditOrder, 'clientID'),
            'Num. factura': getNested(
              '-',
              creditOrder,
              'invoiceNumber',
            ),
            'Nombre del cliente': getNested(
              '-',
              creditOrder,
              'clientName',
            ),
            'Correo del cliente': getNested(
              '-',
              creditOrder,
              'clientEmail',
            ),
            'Teléfono del cliente': getNested(
              '-',
              creditOrder,
              'clientPhoneNumber',
            ),
            'Monto de crédito': formatNumber(
              getNested('-', creditOrder, 'total'),
            ),
            'Fecha próxima de pago': getNextPaymentDate(creditOrder),
            'Pago recurrente': formatNumber(
              (creditOrder.total - creditOrder.enganche) /
                creditOrder.terms,
            ),
            Términos: getNested('-', creditOrder, 'terms'),
            'Tipo de términos': getNested(
              '-',
              creditOrder,
              'termType',
            ),
            'Saldo Actual': balance,
            'Pagos vencidos': getExpiredPayments(creditOrder),
          };
        });
    }
    props.setActionButton(
      <>
        <ShouldItRender
          locationPage={props.location.pathname}
          action="exportCreditOrders"
        >
          <MuiPickersUtilsProvider utils={MomentUtils} locale={'es'}>
            <DatePicker
              autoOk
              disableToolbar
              disableFuture
              variant="inline"
              label="Inicial"
              format="DD/MM"
              className={classes.datePicker}
              value={firstDate}
              onChange={handleFirstDate}
              InputProps={{
                endAdornment: firstDate ? (
                  <IconButton
                    aria-label="Select locale"
                    size="small"
                    onClick={handleClearFirstDate}
                  >
                    <Close />
                  </IconButton>
                ) : null,
              }}
            />
            <span className={classes.range}>&nbsp;A&nbsp;</span>
            <DatePicker
              autoOk
              disableToolbar
              disableFuture
              variant="inline"
              label="Final"
              format="DD/MM"
              className={classes.datePicker}
              value={secondDate}
              onChange={handleSecondDate}
              InputProps={{
                endAdornment: secondDate ? (
                  <IconButton
                    aria-label="Select locale"
                    size="small"
                    onClick={handleClearSecondDate}
                  >
                    <Close />
                  </IconButton>
                ) : null,
              }}
            />
          </MuiPickersUtilsProvider>
        </ShouldItRender>
        <div className={classes.menuDesktop}>
          <ShouldItRender
            locationPage={props.location.pathname}
            action="exportCreditOrders"
          >
            <CSVLink
              data={paymentsToCSV}
              filename="tabla_de_creditos.csv"
              onClick={(event) => {
                return paymentsToCSV.length > 0;
              }}
            >
              <Button
                variant="contained"
                className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
                disabled={Boolean(paymentsToCSV.length <= 0)}
              >
                Exportar
                <FileCopyTwoTone className="kt-margin-l-10" />
              </Button>
            </CSVLink>
          </ShouldItRender>
          <ShouldItRender
            locationPage={props.location.pathname}
            action="importCreditOrders"
          >
            <input
              accept=".xlsx, .xls, .csv"
              hidden
              id="orders-file"
              type="file"
              onChange={(e) =>
                handleFile(e.target.files, e, 'orders')
              }
            />
            <label htmlFor="orders-file" className={classes.wrapper}>
              <Button
                variant="contained"
                className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
                component="span"
              >
                Importar órdenes
                <PublishIcon className="kt-margin-l-10" />
              </Button>
            </label>
          </ShouldItRender>
          <ShouldItRender
            locationPage={props.location.pathname}
            action="importPayments"
          >
            <input
              accept=".xlsx, .xls, .csv"
              hidden
              id="payments-file"
              type="file"
              onChange={(e) =>
                handleFile(e.target.files, e, 'payments')
              }
            />
            <label
              htmlFor="payments-file"
              className={classes.wrapper}
            >
              <Button
                variant="contained"
                className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
                component="span"
              >
                Importar pagos externos
                <PaymentIcon className="kt-margin-l-10" />
              </Button>
            </label>
          </ShouldItRender>
          <ShouldItRender
            locationPage={props.location.pathname}
            action="createCreditOrder"
          >
            <Link to={'/add-creditorder'}>
              <Button
                variant="contained"
                className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
              >
                Agregar orden de crédito
                <Add className="kt-margin-l-10" />
              </Button>
            </Link>
          </ShouldItRender>
        </div>
        <div className={classes.menuMobile}>
          <MenuOption
            Button={
              <Button
                variant="contained"
                className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-t-10"
              >
                Ver opciones
                <ArrowDropDownIcon className="kt-margin-l-10" />
              </Button>
            }
            options={[
              {
                name: (
                  <ShouldItRender
                    locationPage={props.location.pathname}
                    action="exportCreditOrders"
                  >
                    <CSVLink
                      data={paymentsToCSV}
                      filename="tabla_de_creditos.csv"
                      onClick={(event) => {
                        return paymentsToCSV.length > 0;
                      }}
                    >
                      <Typography className={classes.colorPrimary}>
                        <FileCopyTwoTone className={classes.icons} />
                        Exportar
                      </Typography>
                    </CSVLink>
                  </ShouldItRender>
                ),
                onClick: genericFuntion,
              },
              {
                name: (
                  <ShouldItRender
                    locationPage={props.location.pathname}
                    action="importCreditOrders"
                  >
                    <input
                      accept=".xlsx, .xls, .csv"
                      hidden
                      id="orders-file"
                      type="file"
                      onChange={(e) =>
                        handleFile(e.target.files, e, 'orders')
                      }
                    />
                    <label
                      htmlFor="orders-file"
                      className={classes.wrapper}
                    >
                      <Typography className={classes.colorPrimary}>
                        <PublishIcon className={classes.icons} />
                        Importar órdenes
                      </Typography>
                    </label>
                  </ShouldItRender>
                ),
                onClick: genericFuntion,
              },
              {
                name: (
                  <ShouldItRender
                    locationPage={props.location.pathname}
                    action="importPayments"
                  >
                    <input
                      accept=".xlsx, .xls, .csv"
                      hidden
                      id="payments-file"
                      type="file"
                      onChange={(e) =>
                        handleFile(e.target.files, e, 'payments')
                      }
                    />
                    <label
                      htmlFor="payments-file"
                      className={classes.wrapper}
                    >
                      <Typography className={classes.colorPrimary}>
                        <PaymentIcon className={classes.icons} />
                        Importar pagos externos
                      </Typography>
                    </label>
                  </ShouldItRender>
                ),
                onClick: genericFuntion,
              },
              {
                name: (
                  <ShouldItRender
                    locationPage={props.location.pathname}
                    action="createCreditOrder"
                  >
                    <Link to={'/add-creditorder'}>
                      <Typography className={classes.colorPrimary}>
                        <Add className={classes.icons} />
                        Agregar orden de crédito
                      </Typography>
                    </Link>
                  </ShouldItRender>
                ),
                onClick: genericFuntion,
              },
            ]}
          />
        </div>
      </>,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditOrdersToDownload, firstDate, secondDate, window]);

  const genericFuntion = () => {
    // Don't delete this funtion, is called by the top bar menu
    // buttons that do not require the component's onClick function
  };

  const handleFirstDate = (date) => {
    setFirstDate(date);
  };

  const handleSecondDate = (date) => {
    setSecondDate(date);
  };

  const handleClearFirstDate = () => {
    setFirstDate(null);
  };

  const handleClearSecondDate = () => {
    setSecondDate(null);
  };

  const handleRefreshData = () =>
    reactTable.current && reactTable.current.fireFetchData();

  const toggleExcelModal = () =>
    setShowExcelFileModal(!showExcelFileModal);

  const toggleImportPaymentsModal = () =>
    setShowImportPaymentsModal(!showImportPaymentsModal);

  const handleCloseModals = () => {
    closeModals();
  };

  const handleFetchData = debounce(
    (state, instance) => {
      let init = firstDate ? firstDate : undefined;
      let end = secondDate ? secondDate : undefined;
      const { page, pageSize, sorted, filtered } = state;
      getAllCreditOrders({
        firstDate: init,
        secondDate: end,
        page,
        pageSize,
        sorted,
        filtered,
      });
    },
    1000,
    { leading: false, trailing: true },
  );

  const handleFile = (selectedFiles, e, type) => {
    e.preventDefault();
    if (!selectedFiles || selectedFiles.length === 0) return;

    if (selectedFiles[0] !== null) {
      if (selectedFiles[0].size !== 0) {
        setExcelFile(selectedFiles[0]);
        if (type === 'orders') setShowExcelFileModal(true);
        else if (type === 'payments')
          setShowImportPaymentsModal(true);
        e.target.value = null;
        return;
      }
    }
    return;
  };

  const handleUploadFile = (values, importType) => {
    const formData = new FormData();
    if (values.agencyID) formData.append('agencyID', values.agencyID);
    formData.append(`file0`, excelFile);
    setShowExcelFileModal(false);
    setShowImportPaymentsModal(false);
    switch (importType) {
      case 'importOrders':
        return uploadExcelFile({ file: formData, handleRefreshData });

      case 'importPayments':
        return uploadPaymentsFromExcelFile({ file: formData });

      default:
        break;
    }
  };

  const calculateExpDate = (creditOrder) => {
    const terms = getNested(null, creditOrder, 'terms');
    const termType = getNested(null, creditOrder, 'termType');
    const startAt = getNested(null, creditOrder, 'startAt');
    if (terms && termType && startAt) {
      const tempStartAt = moment(startAt);
      if (termType === 'semanal') {
        tempStartAt.add(terms, 'w');
      } else if (termType === 'quincenal') {
        tempStartAt.add(terms * 15, 'd');
      } else if (termType === 'mensual') {
        tempStartAt.add(terms, 'M');
      }
      return tempStartAt.format('DD/MM/YYYY');
    }
    return '-';
  };

  const getNextPaymentDate = (creditOrder) => {
    const terms = getNested(null, creditOrder, 'terms');
    const termType = getNested(null, creditOrder, 'termType');
    const startAt = getNested(null, creditOrder, 'startAt');
    const finalDate = calculateExpDate(creditOrder);
    const actualDifference = moment().diff(
      moment(finalDate, 'DD/MM/YYYY'),
    );
    if (actualDifference >= 0) return '-';
    if (terms && termType && startAt) {
      const tempStartAt = moment(startAt);
      let termLetter = '';
      if (termType === 'semanal') {
        termLetter = 'weeks';
      } else if (termType === 'quincenal') {
        termLetter = 'days';
      } else if (termType === 'mensual') {
        termLetter = 'months';
      }
      for (let i = 0; i <= terms; i++) {
        if (termLetter === 'days') {
          if (tempStartAt.diff(moment()) >= 0) {
            return tempStartAt.format('DD/MM/YYYY');
          }
          tempStartAt.add(15, 'days');
        } else {
          if (tempStartAt.diff(moment()) >= 0) {
            return tempStartAt.format('DD/MM/YYYY');
          }
          tempStartAt.add(1, termLetter);
        }
      }
    }
    return '-';
  };

  const getExpiredPayments = (creditOrder) => {
    let expired = 0;
    if (
      Array.isArray(creditOrder.payments) &&
      creditOrder.payments.length > 0
    )
      expired = creditOrder.payments.reduce(
        (accumulator, payment) => {
          if (payment.status === 'overdue') return (accumulator += 1);
          return accumulator;
        },
        0,
      );
    return expired;
  };

  const getRemaining = (creditOrder) => {
    let payments = 0;
    payments += getNested(0, creditOrder, 'enganche');
    if (
      Array.isArray(creditOrder.payments) &&
      creditOrder.payments.length > 0
    )
      payments = creditOrder.payments.reduce(
        (accumulator, payment) => {
          if (payment.status === 'DONE')
            return (accumulator += payment.amount);
          return accumulator;
        },
        payments,
      );
    const remaining = formatNumber(creditOrder.total - payments);
    return remaining;
  };

  const columns = [
    {
      Header: () => <strong>ID cliente</strong>,
      accessor: 'clientID',
      Cell: (row) => (
        <Link to={`/detail-credit-order/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>Num. factura</strong>,
      accessor: 'invoiceNumber',
      Cell: (row) => (
        <Link to={`/detail-credit-order/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      width: getColumnWidth(
        getNested([], creditOrdersFromdb, 'fromdb'),
        'name',
        'Nombre de cliente',
      ),
    },
    {
      Header: () => <strong>Monto de crédito</strong>,
      accessor: 'total',
      Cell: (row) => (
        <Link to={`/detail-credit-order/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {formatNumber(row.value)}
          </div>
        </Link>
      ),
      width: getColumnWidth(
        getNested([], creditOrdersFromdb, 'fromdb'),
        'created',
        'Fecha creación',
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Fecha próximo pago</strong>,
      Cell: (row) => (
        <Link to={`/detail-credit-order/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {getNextPaymentDate(row.original)}
          </div>
        </Link>
      ),
      width: getColumnWidth(
        getNested([], creditOrdersFromdb, 'fromdb'),
        'updated',
        'Fecha pago',
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Pago recurrente</strong>,
      Cell: (row) => (
        <Link to={`/detail-credit-order/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {formatNumber(
              (row.original.total - row.original.enganche) /
                row.original.terms,
            )}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Saldo actual</strong>,
      Cell: (row) => (
        <Link to={`/detail-credit-order/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {formatNumber(getRemaining(row.original))}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Pagos vencidos</strong>,
      Cell: (row) => (
        <Link to={`/detail-credit-order/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {getExpiredPayments(row.original)}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Estatus</strong>,
      accessor: 'status',
      Cell: (row) => (
        <Link to={`/detail-credit-order/${row.original.orderID}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <Badge
              className={classes.badges}
              variant={getBadgeColor(
                intl.formatMessage({
                  id: `CREDIT_ORTDER.STATUS.${row.value}`,
                }),
              )}
            >
              {intl.formatMessage({
                id: `CREDIT_ORTDER.STATUS.${row.value}`,
              })}
            </Badge>
          </div>
        </Link>
      ),
      Filter: (cell) => {
        return (
          // eslint-disable-next-line jsx-a11y/no-onchange
          <select
            onChange={(e) => cell.onChange(e.target.value)}
            value={
              cell.filter && cell.filter.value
                ? cell.filter.value
                : ''
            }
          >
            <option value="">Todos</option>
            <option value="pending">Vigente</option>
            <option value="payment">Pagada</option>
            <option value="canceled">Cancelada</option>
            <option value="overdue">Expirada</option>
          </select>
        );
      },
    },
    {
      Header: () => <strong>Notificaciones</strong>,
      accessor: 'creditOrderNotifications',
      Cell: (row) => {
        let notifications = {
          whatsapp: null,
          email: null,
        };
        if (Array.isArray(row.value) && row.value.length > 0)
          notifications = row.value.reduce((acc, notification) => {
            if (
              new Date(notification.createdAt) >
              new Date(acc[notification.type])
            ) {
              acc[notification.type] = notification.createdAt;
            }
            return acc;
          }, notifications);
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <Tooltip
              disableFocusListener={!notifications.email}
              disableHoverListener={!notifications.email}
              disableTouchListener={!notifications.email}
              title={`Correo enviado ${moment(
                notifications.email,
              ).format('DD/MM/YYYY')}`}
            >
              <MaterialBadge
                className={classes.notifications}
                color="error"
              >
                <EmailIcon
                  color={notifications.email ? 'primary' : 'disabled'}
                />
              </MaterialBadge>
            </Tooltip>
            <Tooltip
              disableFocusListener={!notifications.whatsapp}
              disableHoverListener={!notifications.whatsapp}
              disableTouchListener={!notifications.whatsapp}
              title={`Whatsapp enviado ${moment(
                notifications.whatsapp,
              ).format('DD/MM/YYYY')}`}
            >
              <MaterialBadge
                className={classes.notifications}
                color="error"
              >
                <WhatsAppIcon
                  color={
                    notifications.whatsapp ? 'primary' : 'disabled'
                  }
                />
              </MaterialBadge>
            </Tooltip>
          </div>
        );
      },
      width: 127,
      filterable: false,
    },
  ];

  const renderUploadExcelFileDialog = () => {
    return (
      <Modal
        open={showExcelFileModal}
        closeModal={() => {
          toggleExcelModal();
          setExcelFile(null);
        }}
        dialogTitle="Importar órdenes"
        dialogText={`Está a punto de importar las órdenes de crédito desde el archivo ${
          excelFile ? excelFile.name : ''
        }\n desea continuar?`}
        dialogChildren={
          <form
            id="import-excel-form"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit((data, _) =>
              handleUploadFile(data, 'importOrders'),
            )}
          >
            <ShouldItRender should={role === 'Super Admin'}>
              <MUIRHFAutocompleteAgencies
                rules={{ required: true }}
                error={Boolean(errors.agencyID)}
                name="agencyID"
                control={control}
                fullWidth
                className={classes.textField}
              />
            </ShouldItRender>
          </form>
        }
        actionButtonText="Importar órdenes"
        form={'import-excel-form'}
      />
    );
  };

  const renderUploadPaymentsFromExcelFileDialog = () => {
    return (
      <Modal
        open={showImportPaymentsModal}
        closeModal={() => {
          toggleImportPaymentsModal();
          setExcelFile(null);
        }}
        dialogTitle="Importar pagos"
        dialogText={`Está a punto de importar pagos desde el archivo ${
          excelFile ? excelFile.name : ''
        }\n desea continuar?`}
        dialogChildren={
          <form
            id="import-payments-from-excel-form"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit((data, _) =>
              handleUploadFile(data, 'importPayments'),
            )}
          >
            <ShouldItRender should={role === 'Super Admin'}>
              <MUIRHFAutocompleteAgencies
                rules={{ required: true }}
                error={Boolean(errors.agencyID)}
                name="agencyID"
                control={control}
                fullWidth
                className={classes.textField}
              />
            </ShouldItRender>
          </form>
        }
        actionButtonText="Importar Pagos"
        form={'import-payments-from-excel-form'}
      />
    );
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = creditOrders;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message } = creditOrders[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={handleCloseModals}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={handleCloseModals}
          />
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  return (
    <div className={classes.root}>
      {renderUploadExcelFileDialog()}
      {renderUploadPaymentsFromExcelFileDialog()}
      {renderFeedBackModal()}
      <Paper className={classes.paper}>
        <ReactTable
          ref={reactTable}
          NoDataComponent={() => (
            <div className="rt-noData">Sin resultados</div>
          )}
          manual
          onFetchData={handleFetchData}
          data={getNested([], creditOrdersFromdb)}
          pages={creditOrders.tablePages}
          columns={columns}
          filterable
          className="-striped -highlight"
          previousText="Anterior"
          nextText="Siguiente"
          loading={creditOrders && creditOrders.isLoading}
          loadingText="Cargando datos..."
        />
      </Paper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  creditOrdersFromdb: makeSelectCreditOrdersFromdb(state),
  creditOrders: state.creditOrders,
  role: makeSelectUserRoleFromState(state),
  creditOrdersToDownload: makeSelectCreditOrdersToDownload(state),
});

const mapDispatchToProps = {
  getAllCreditOrders,
  uploadExcelFile,
  uploadPaymentsFromExcelFile,
  closeModals,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(CreditOrdersTable),
);
