export const initialState = {
  fromdb: null,
  tablePages: 0,
  isLoading: false,
  successModal: {
    show: false,
    message: null,
  },
  errorModal: {
    show: false,
    message: null,
  },
  error: null,
};
