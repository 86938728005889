import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';

const calculator2023Slice = createSlice({
  name: 'calculatorData2023',
  initialState,
  reducers: {
    getCalculatorData(state) {
      state.isLoading = true;
      state.settingAmountsPerAgency = null;
    },
    getCalculatorDataSuccess(state, action) {
      state.isLoading = false;
      state.settingAmountsPerAgency = action.payload;
    },
    getCalculatorDataFail(state, action) {
      state.isLoading = false;
      state.settingAmountsPerAgency = null;
      state.errorModal = {
        show: true,
        message: action.payload.message,
      };
    },
    getFetchCalculatorData(state) {
      state.isLoading = true;
      state.calculatorResponse = null;
    },
    getFetchCalculatorDataSuccess(state, action) {
      state.isLoading = false;
      state.calculatorResponse = action.payload?.preCalculated;
    },
    getFetchCalculatorDataFail(state, action) {
      state.isLoading = false;
      state.calculatorResponse = [];
      state.errorModal = {
        show: true,
        message: action.payload.message,
      };
    },
    getOrdersType(state) {
      state.isLoading = true;
      state.ordersType = null;
    },
    resetOrdersType(state) {
      state.isLoading = false;
      state.ordersType = null;
    },
    getOrdersTypeSuccess(state, action) {
      state.isLoading = false;
      state.ordersType = action.payload;
    },
    getOrdersTypeFail(state, action) {
      state.isLoading = false;
      state.ordersType = null;
      state.errorModal = {
        show: true,
        message: action.payload.message,
      };
    },
  },
});

export const {
  getCalculatorData,
  getCalculatorDataSuccess,
  getCalculatorDataFail,
  getFetchCalculatorData,
  getFetchCalculatorDataSuccess,
  getFetchCalculatorDataFail,
  resetOrdersType,
  getOrdersType,
  getOrdersTypeSuccess,
  getOrdersTypeFail,
} = calculator2023Slice.actions;

export default calculator2023Slice.reducer;
