import advancesSlice from '../features/advances/advancesSlice';
import agenciesSlice from '../features/agencies/agenciesSlice';
import authSlice from '../features/auth/authSlice';
import { combineReducers } from '@reduxjs/toolkit';
import creditOrdersSlice from '../features/creditOrders/creditOrdersSlice';
import dashboardSlice from '../features/dashboard/dashboardSlice';
import depositsSlice from '../features/deposits/depositsSlice';
import kioskoSlice from '../features/kiosko/kioskoSlice';
import logsSlice from '../features/logs/logsSlice';
import { metronic } from '../../_metronic';
import terminalsSlice from 'app/features/terminals/terminalsSlice.js';
import karloUsersSlice from 'app/features/karloUsers/karloUsersSlice.js';
import karloVehiclesSlice from 'app/features/karloVehicles/karloVehiclesSlice.js';
import karloAdvicesSlice from 'app/features/karloAdvices/karloAdvicesSlice.js';
import karloPromotionsSlice from 'app/features/karloPromotions/karloPromotionsSlice.js';
import karloOffersSlice from 'app/features/karloOffers/karloOffersSlice.js';
import modalsSlice from '../features/modals/modalsSlice';
import notificationsSlice from '../features/notifications/notificationsSlice';
import paymentOrdersSlice from '../features/paymentOrders/paymentOrdersSlice';
import paymentsSlice from '../features/payments/paymentsSlice';
import serviceAppointmentsSlice from '../features/serviceAppointments/serviceAppointmentsSlice';
import serviceOrdersSlice from '../features/serviceOrders/serviceOrdersSlice';
import speiPaymentsSlice from '../features/speiPayments/speiPaymentsSlice';
import tokenizeSlice from '../features/tokenize/tokenizeSlice';
import usersSlice from '../features/users/usersSlice';
import reservationsSlice from '../features/reservations/reservationsSlice';
import chargebacksSlice from '../features/chargebacks/chargeBacksSlices';
import setupLogssetupLogsSlices from '../features/setup/setupLogsSlice';
import supportSlices from '../features/support/supportSlice';
import serviceReviewSlice from '../features/serviceReview/serviceReviewSlice';
import dispersionsSlice from '../features/dispersions/dispersionsSlice';
import calculator2023Slice from '../features/calculator/containers/Calculator2023/calculator2023Slice';
import depositsFiservSlice from '../features/depositsfiserv/depositsSlice';
import depositsStandAloneSlice from '../features/standAlone/deposits/depositsSlice';
import setup from '../features/setup/setupSlice';

export const rootReducer = combineReducers({
  auth: authSlice,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer,
  serviceAppointments: serviceAppointmentsSlice,
  karloUsers: karloUsersSlice,
  karloAdvices: karloAdvicesSlice,
  karloPromotions: karloPromotionsSlice,
  karloVehicles: karloVehiclesSlice,
  karloOffers: karloOffersSlice,
  serviceOrders: serviceOrdersSlice,
  users: usersSlice,
  terminals: terminalsSlice,
  agencies: agenciesSlice,
  payments: paymentsSlice,
  speiPayments: speiPaymentsSlice,
  deposits: depositsSlice,
  notifications: notificationsSlice,
  paymentOrders: paymentOrdersSlice,
  advances: advancesSlice,
  creditOrders: creditOrdersSlice,
  dashboard: dashboardSlice,
  logs: logsSlice,
  modals: modalsSlice,
  tokenize: tokenizeSlice,
  kiosko: kioskoSlice,
  reservations: reservationsSlice,
  chargebacks: chargebacksSlice,
  setup: setupLogssetupLogsSlices,
  support: supportSlices,
  serviceReview: serviceReviewSlice,
  dispersions: dispersionsSlice,
  calculatorData: calculator2023Slice,
  depositsFiserv: depositsFiservSlice,
  depositsStandAlone: depositsStandAloneSlice,
  setup: setup,
});
