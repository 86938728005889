import { createSelector } from 'reselect';
import { initialState } from './initialState';

export const selectSupport = (state) => state.support || initialState;

export const makeSelectSupportIsloading = createSelector(
  selectSupport,
  (supportState) => supportState.isLoading,
);

export const makeSelectSupportAssociate = createSelector(
  selectSupport,
  (supportState) => supportState,
);
