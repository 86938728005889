import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';

const setupSlices = createSlice({
    name: 'setup',
    initialState,
    reducers: {
        updatePromotions:{
            reducer(state, action) {
                state.isLoading = true;
            },
        },
        updatePromotionsSuccess:{
            reducer(state, action) {
                state.isLoading = false;
                state.successModal = {
                    show: true,
                    message: action.payload.response.message || 'Promociones actualizadas',
                };
            },
        },
        updatePromotionsFail:{
            reducer(state, action) {
                state.isLoading = false;
                state.errorModal = {
                    show: true,
                    message: action.payload.error.message || 'Error al actualizar promociones',
                };
            },
        },
        closeModals: {
            reducer(state, action) {
              state.isLoading = false;
              state.successModal = false;
              state.errorModal = false;
            },
          },

    },
});

export const {
    updatePromotions,
    updatePromotionsSuccess,
    updatePromotionsFail,
    closeModals,
  } = setupSlices.actions;
  
export default setupSlices.reducer;