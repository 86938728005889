import { call, put } from 'redux-saga/effects';
import {
  getDataSuccess,
  getPaymentOrdersForDashboardFail,
  getPaymentOrdersForDashboardSuccess,
  getPaymentsForDashboardFail,
  getPaymentsForDashboardSuccess,
  getServiceOrdersForDashboardFail,
  getServiceOrdersForDashboardSuccess,
  getAgenciesForDashboardFail,
  getAgenciesForDashboardSuccess,
  getPDFReportFail,
  getPDFReportSuccess,
} from './dashboardSlice';

import moment from 'moment';
import { requestHandler } from '../../services/requestHandler';
import _ from 'lodash';

export function* getDataForDashboardSaga(action) {
  const {
    agencyId,
    firstDate,
    endDate,
    serviceOrderID,
    paymentOrderID,
  } = action.payload;
  yield call(getPaymentOrdersForDashboardSaga, {
    payload: { agencyId, firstDate, endDate },
  });
  yield call(getServiceOrdersForDashboardSaga, {
    payload: { agencyId, firstDate, endDate },
  });
  yield call(getPaymentsForDashboardSaga, {
    payload: {
      agencyId,
      firstDate,
      endDate,
      serviceOrderID,
      paymentOrderID,
    },
  });
  yield put(getDataSuccess());
}

export function* getPaymentOrdersForDashboardSaga(action) {
  try {
    const { firstDate, endDate, agencyId } = action.payload;

    if (firstDate && endDate && agencyId) {
      const data = {
        firstDate: moment(firstDate).format('YYYY-MM-DD 00:00:00.000000 -05:00'),
        endDate: moment(endDate).format('YYYY-MM-DD 23:59:59.999000 -05:00'),
        agencyID: agencyId,
      };
      const response = yield call(requestHandler, {
        method: 'POST',
        path: '/dashboard/getAllPO',
        data,
      });
      const paymentOrders = response.ODP;
      yield put(
        getPaymentOrdersForDashboardSuccess({ paymentOrders }),
      );
    }
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba obtener las órdenes de pago.
      Intente de nuevo, si el error persiste intente más tarde.`,
    };
    yield put(getPaymentOrdersForDashboardFail({ message }));
    console.log('error in login user saga', e);
  }
}

export function* getServiceOrdersForDashboardSaga(action) {
  try {
    const { firstDate, endDate, agencyId } = action.payload;
    if (firstDate && endDate && agencyId) {
      const data = {
        firstDate: moment(firstDate).format('YYYY-MM-DD 00:00:00.000000 -05:00'),
        endDate: moment(endDate).format('YYYY-MM-DD 23:59:59.999000 -05:00'),
        agencyID: agencyId,
      };
      const response = yield call(requestHandler, {
        method: 'POST',
        path: '/dashboard/getAllSO',
        data,
      });
      const serviceOrders = response.ODS;
      yield put(
        getServiceOrdersForDashboardSuccess({
          serviceOrders,
        }),
      );
    }
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba obtener las órdenes de pago.
      Intente de nuevo, si el error persiste intente más tarde.`,
    };
    yield put(getServiceOrdersForDashboardFail({ message }));
    console.log('error in login user saga', e);
  }
}

export function* getPaymentsForDashboardSaga(action) {
  try {
    const { firstDate, endDate, agencyId } = action.payload;

    if (firstDate && endDate && agencyId) {
      let response;
      const data = {
        firstDate: moment(firstDate).format('YYYY-MM-DD 00:00:00.000000 -05:00'),
        endDate: moment(endDate).format('YYYY-MM-DD 23:59:59.999000 -05:00'),
        agencyId,
      };
      response = yield call(requestHandler, {
        method: 'POST',
        path: '/dashboard/getAllpayment',
        data,
      });

      const payments = response.paymentsInfo;
      yield put(getPaymentsForDashboardSuccess({ payments }));
    }
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba obtener las órdenes de pago.
      Intente de nuevo, si el error persiste intente más tarde.`,
    };
    yield put(getPaymentsForDashboardFail({ message }));
    console.log('error in login user saga', e);
  }
}

export function* getPDFReportSaga(action) {
  try {
    const { firstDate, endDate, agency } = action.payload;
    const data = {
      firstDate: moment(firstDate).format('YYYY-MM-DD 00:00:00.000000 -05:00'),
      endDate: moment(endDate).format('YYYY-MM-DD 23:59:59.999000 -05:00'),
      agencyID: agency,
    };
    const pdf = yield call(requestHandler, {
      method: 'POST',
      path: '/dashboard/dashboardReport',
      data,
    });

    yield put(getPDFReportSuccess({}));
  } catch (error) {
    const message = `Ocurrió un error cuando se intentaba obtener las órdenes de pago.
      Intente de nuevo, si el error persiste intente más tarde.`;
    console.log('error in download pdf report ', error);
    yield put(getPDFReportFail({ message }));
  }
}

export function* getAgenciesForDashboardSaga(action) {
  try {
    const {
      firstDate: initialDay,
      endDate: finalDay,
      agency,
    } = action.payload;
    let agencies = [];
    const data = {
      firstDate: initialDay,
      endDate: finalDay,
      agency,
    };
    let message;
    if (agency !== '') {
      message = {
        title: 'Información lista',
        desc: `La información de la agencia fue obtenida satisfactoriamente`,
      };
    } else {
      data.mail = action.payload.email;
      message = {
        title: 'La información está siendo preparada',
        desc:
          'La información de las agencias está siendo procesada, en unos minutos estará lista en su correo electrónico.',
      };
    }
    agencies = yield call(requestHandler, {
      method: 'POST',
      path: '/dashboard/createDataCSV',
      data,
    });

    if (agencies === '') agencies = [];
    yield put(getAgenciesForDashboardSuccess({ agencies, message }));
  } catch (e) {
    const agencies = [];
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba obtener las Agencias.
      Intente de nuevo, si el error persiste intente más tarde.`,
    };
    yield put(getAgenciesForDashboardFail({ agencies, message }));
    console.log('error in login user saga', e);
  }
}
