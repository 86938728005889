import { closeModals } from '../modals/modalsSlice';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { logout } from '../auth/authSlice';

const agenciesSlice = createSlice({
  name: 'agencies',
  initialState,
  reducers: {
    getAgencies: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getAgenciesSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.fromdb = action.payload.agencies;
      },
    },
    getAgenciesFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },

    getAgenciesFilters: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getAgenciesFiltersSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.agenciesFilter = action.payload.agengiesFilter;
      },
    },
    getAgenciesFiltersFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },

    getAgency: {
      reducer(state) {
        state.isLoading = true;
        state.agencyDetails = null;
      },
    },
    getAgencySuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.agencyDetails = action.payload.agency;
      },
    },
    getAgencyFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },

    createAgency: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    createAgencySuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.logoUploaded = false;
        state.logoURL = null;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    createAgencyFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },

    updateCommissions: {
      reducer(state) {
        state.isLoading = true;
      },
    },

    updateAgency: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    updateAgencySuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.agencyDetails = action.payload.agency;
        state.logoURL = null;
        state.logoUploaded = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    updateAgencyFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },

    updateOption: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    updateOptionSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.agencyDetails = {
          ...action.payload.agency,
          address: state.agencyDetails.address,
        };
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    updateOptionFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },

    deleteUser: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    deleteUserSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    deleteUserFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },

    getOrUpdateSurTaxes: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getOrUpdateSurTaxesSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    getOrUpdateSurTaxesFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },

    updloadLogo: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    updloadLogoSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.logoUploaded = true;
        state.logoURL = action.payload.url;
      },
    },
    updloadLogoFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },

    upLoadAmounts: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    upLoadAmountsSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    upLoadAmountsFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },

    createAgencyEvents: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    createAgencyEventsSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    createAgencyEventsFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },

    updateAgencyEvents: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    updateAgencyEventsSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    updateAgencyEventsFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },

    updateLineConfig: {
      reducer(state) {
        state.isLoading = false;
      },
    },
    updateLineConfigSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    updateLineConfigFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },

    updateTemplateById: {
      reducer(state) {
        state.isLoading = false;
      },
    },

    updateTemplate: {
      reducer(state) {
        state.isLoading = false;
      },
    },
    updateTemplateSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    updateTemplateFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },

    getOneAgency: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getOneAgencySuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.agencyData = action.payload.agency;
      },
    },
    getOneAgencyFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },

    updateAgencyMembership: {
      reducer(state) {
        state.isLoading = false;
      },
    },
    updateAgencyMembershipSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    updateAgencyMembershipFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },

    createOrUpdateTypeOrders: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    createOrUpdateTypeOrdersSuccess: {
      reducer(state, action) {
        state.isLoading = false;
      },
    },
    createOrUpdateTypeOrdersFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
  },
  extraReducers: {
    [logout]: (state, action) => {
      return initialState;
    },
    [closeModals]: (state, action) => {
      state.isLoading = false;
      state.successModal = initialState.successModal;
      state.errorModal = initialState.errorModal;
    },
  },
});

export const {
  getAgencies,
  getAgenciesSuccess,
  getAgenciesFail,
  getAgency,
  getAgencySuccess,
  getAgencyFail,
  createAgency,
  createAgencySuccess,
  createAgencyFail,
  updateAgency,
  updateAgencySuccess,
  updateAgencyFail,
  updateCommissions,
  updateOption,
  updateOptionSuccess,
  updateOptionFail,
  getOrUpdateSurTaxes,
  getOrUpdateSurTaxesSuccess,
  getOrUpdateSurTaxesFail,
  updloadLogo,
  updloadLogoSuccess,
  updloadLogoFail,
  upLoadAmounts,
  upLoadAmountsSuccess,
  upLoadAmountsFail,
  createAgencyEvents,
  createAgencyEventsSuccess,
  createAgencyEventsFail,
  updateAgencyEvents,
  updateAgencyEventsSuccess,
  updateAgencyEventsFail,
  getAgenciesFilters,
  getAgenciesFiltersSuccess,
  getAgenciesFiltersFail,
  updateLineConfig,
  updateLineConfigSuccess,
  updateLineConfigFail,
  updateTemplate,
  updateTemplateById,
  updateTemplateSuccess,
  updateTemplateFail,
  getOneAgency,
  getOneAgencySuccess,
  getOneAgencyFail,
  updateAgencyMembership,
  updateAgencyMembershipSuccess,
  updateAgencyMembershipFail,
  createOrUpdateTypeOrders,
  createOrUpdateTypeOrdersSuccess,
  createOrUpdateTypeOrdersFail,
} = agenciesSlice.actions;

export default agenciesSlice.reducer;
