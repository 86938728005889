export const paymentsHardcoded = {
  44375450: {
    surtaxMonths: 193.06,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 279.94,
    iva: 44.79,
    toDeposit: 2892.95,
  },
  44417087: {
    surtaxMonths: 1636.17,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 2372.45,
    iva: 379.59,
    toDeposit: 24517.51,
  },
  44409621: {
    surtaxMonths: 982.57,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 1555.73,
    iva: 248.92,
    toDeposit: 14571.46,
  },
  44441646: {
    surtaxMonths: 506.62,
    surtaxComissionPercentage: '10.00%',
    comissionWithoutIVA: 643.41,
    iva: 102.94,
    toDeposit: 4319.84,
  },
  44519702: {
    surtaxMonths: 210.98,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 400.86,
    iva: 64.14,
    toDeposit: 6567.66,
  },
  44523025: {
    surtaxMonths: 110.27,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 238.91,
    iva: 38.23,
    toDeposit: 3398.4,
  },
  44514893: {
    surtaxMonths: 135.94,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 258.29,
    iva: 41.33,
    toDeposit: 4231.73,
  },
  44547920: {
    surtaxMonths: 25.19,
    comissionWithoutIVA: 47.86,
    surtaxComissionPercentage: '3.00%',
    iva: 7.66,
    toDeposit: 784.21,
  },
  44634875: {
    surtaxMonths: 31.49,
    comissionWithoutIVA: 59.83,
    surtaxComissionPercentage: '3.00%',
    iva: 9.57,
    toDeposit: 980.25,
  },
  44646496: {
    surtaxMonths: 366.5,
    comissionWithoutIVA: 531.43,
    surtaxComissionPercentage: '6.00%',
    iva: 85.03,
    toDeposit: 5491.92,
  },
  44673049: {
    surtaxMonths: 1191.1,
    surtaxComissionPercentage: '8.00%',
    comissionWithoutIVA: 1593.1,
    iva: 254.9,
    toDeposit: 13040.81,
  },
  44679312: {
    surtaxMonths: 272.64,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 590.72,
    iva: 94.52,
    toDeposit: 8402.8,
  },
  44710189: {
    surtaxMonths: 284.28,
    surtaxComissionPercentage: '10.00%',
    comissionWithoutIVA: 361.04,
    iva: 57.77,
    toDeposit: 2424.01,
  },
  44749757: {
    surtaxMonths: 1616.42,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 3502.24,
    iva: 560.36,
    toDeposit: 49818.05,
  },
  44892509: {
    surtaxMonths: 338.36,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 733.11,
    iva: 117.3,
    toDeposit: 10428.25,
  },
  44890988: {
    surtaxMonths: 785.5,
    comissionWithoutIVA: 1492.45,
    surtaxComissionPercentage: '3.00%',
    iva: 238.79,
    toDeposit: 24452.06,
  },
  44935298: {
    surtaxMonths: 1248.42,
    surtaxComissionPercentage: '10.00%',
    comissionWithoutIVA: 1585.49,
    iva: 253.68,
    toDeposit: 10644.99,
  },
  44946936: {
    surtaxMonths: 164.52,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 312.59,
    iva: 50.01,
    toDeposit: 5121.44,
  },
  44950143: {
    surtaxMonths: 100.92,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 191.76,
    iva: 30.68,
    toDeposit: 3141.69,
  },
  44739756: {
    surtaxMonths: 242.59,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 525.6,
    iva: 84.1,
    toDeposit: 7476.49,
  },
  44978370: {
    surtaxMonths: 1015.29,
    surtaxComissionPercentage: '10.00%',
    comissionWithoutIVA: 1289.42,
    iva: 206.31,
    toDeposit: 8657.17,
  },
  44372016: {
    surtaxMonths: 496.17,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 719.44,
    iva: 115.11,
    toDeposit: 7434.92,
  },
  44372334: {
    surtaxMonths: 513.48,
    surtaxComissionPercentage: '10.00%',
    comissionWithoutIVA: 652.12,
    iva: 104.34,
    toDeposit: 4378.34,
  },
  44382047: {
    surtaxMonths: 125.67,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 238.78,
    iva: 38.21,
    toDeposit: 3912.16,
  },
  44383190: {
    surtaxMonths: 305.75,
    surtaxComissionPercentage: '10.00%',
    comissionWithoutIVA: 388.31,
    iva: 62.13,
    toDeposit: 2607.1,
  },
  44382734: {
    surtaxMonths: 129.61,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 246.26,
    iva: 39.4,
    toDeposit: 4034.7,
  },
  44386913: {
    surtaxMonths: 259.45,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 492.96,
    iva: 78.87,
    toDeposit: 8076.56,
  },
  44387784: {
    surtaxMonths: 774.89,
    surtaxComissionPercentage: '10.00%',
    comissionWithoutIVA: 984.11,
    iva: 157.46,
    toDeposit: 6607.32,
  },
  44389475: {
    surtaxMonths: 108.04,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 205.28,
    iva: 32.84,
    toDeposit: 3363.25,
  },
  44391012: {
    surtaxMonths: 275.41,
    surtaxComissionPercentage: '10.00%',
    comissionWithoutIVA: 349.77,
    iva: 55.96,
    toDeposit: 2348.37,
  },
  44392001: {
    surtaxMonths: 471.96,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 896.72,
    iva: 143.48,
    toDeposit: 14691.73,
  },
  44395720: {
    surtaxMonths: 482.61,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 916.96,
    iva: 146.71,
    toDeposit: 15023.41,
  },
  44400503: {
    surtaxMonths: 86.6,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 164.53,
    iva: 26.33,
    toDeposit: 2695.68,
  },
  44400840: {
    surtaxMonths: 600.75,
    surtaxComissionPercentage: '10.00%',
    comissionWithoutIVA: 762.95,
    iva: 122.07,
    toDeposit: 5122.48,
  },
  44418826: {
    surtaxMonths: 48.99,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 106.14,
    iva: 16.98,
    toDeposit: 1509.86,
  },
  44420369: {
    surtaxMonths: 180.3,
    surtaxComissionPercentage: '10.00%',
    comissionWithoutIVA: 228.98,
    iva: 36.64,
    toDeposit: 1537.38,
  },
  44422925: {
    surtaxMonths: 257.14,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 372.85,
    iva: 59.66,
    toDeposit: 3853.15,
  },
  44426693: {
    surtaxMonths: 73.83,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 140.27,
    iva: 22.44,
    toDeposit: 2298.18,
  },
  44431573: {
    surtaxMonths: 50.88,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 96.67,
    iva: 15.47,
    toDeposit: 1583.82,
  },
  44432439: {
    surtaxMonths: 386.55,
    surtaxComissionPercentage: '8.00%',
    comissionWithoutIVA: 517.01,
    iva: 82.72,
    toDeposit: 4232.12,
  },
  44431826: {
    surtaxMonths: 194.3,
    surtaxComissionPercentage: '10.00%',
    comissionWithoutIVA: 246.77,
    iva: 39.48,
    toDeposit: 1656.79,
  },
  44433478: {
    surtaxMonths: 128.45,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 186.26,
    iva: 29.8,
    toDeposit: 1924.84,
  },
  44440716: {
    surtaxMonths: 99.51,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 189.06,
    iva: 30.25,
    toDeposit: 3097.59,
  },
  44461067: {
    surtaxMonths: 49.75,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 94.53,
    iva: 15.12,
    toDeposit: 1548.78,
  },
  44456900: {
    surtaxMonths: 109.43,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 207.91,
    iva: 33.27,
    toDeposit: 3406.36,
  },
  44464713: {
    surtaxMonths: 86.91,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 165.13,
    iva: 26.42,
    toDeposit: 2705.48,
  },
  44468208: {
    surtaxMonths: 82.38,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 178.5,
    iva: 28.56,
    toDeposit: 2539.08,
  },
  44474785: {
    surtaxMonths: 471.62,
    surtaxComissionPercentage: '10.00%',
    comissionWithoutIVA: 598.96,
    iva: 95.83,
    toDeposit: 4021.44,
  },
  44472750: {
    surtaxMonths: 1489.09,
    surtaxComissionPercentage: '10.00%',
    comissionWithoutIVA: 1891.15,
    iva: 302.58,
    toDeposit: 12697.19,
  },
  44473483: {
    surtaxMonths: 341.96,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 495.84,
    iva: 79.33,
    toDeposit: 5124.15,
  },
  44474437: {
    surtaxMonths: 363.36,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 526.87,
    iva: 84.3,
    toDeposit: 5444.84,
  },
  44476023: {
    surtaxMonths: 137.45,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 261.16,
    iva: 41.79,
    toDeposit: 4278.78,
  },
  44476795: {
    surtaxMonths: 153.78,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 222.98,
    iva: 35.68,
    toDeposit: 2304.3,
  },
  44481127: {
    surtaxMonths: 249.74,
    surtaxComissionPercentage: '10.00%',
    comissionWithoutIVA: 317.17,
    iva: 50.75,
    toDeposit: 2129.47,
  },
  44499487: {
    surtaxMonths: 137.64,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 261.52,
    iva: 41.84,
    toDeposit: 4284.66,
  },
  44501436: {
    surtaxMonths: 52.43,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 99.62,
    iva: 15.94,
    toDeposit: 1632.1,
  },
  44501662: {
    surtaxMonths: 40.91,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 77.72,
    iva: 12.44,
    toDeposit: 1273.35,
  },
  44505307: {
    surtaxMonths: 71.61,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 136.05,
    iva: 21.77,
    toDeposit: 2229.1,
  },
  44506298: {
    surtaxMonths: 292.54,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 463.19,
    iva: 74.11,
    toDeposit: 4338.41,
  },
  44503371: {
    surtaxMonths: 308.12,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 585.44,
    iva: 93.67,
    toDeposit: 9591.72,
  },
  44508539: {
    surtaxMonths: 340.49,
    surtaxComissionPercentage: '8.00%',
    comissionWithoutIVA: 455.41,
    iva: 72.87,
    toDeposit: 3727.89,
  },
  44508432: {
    surtaxMonths: 205.79,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 298.4,
    iva: 47.74,
    toDeposit: 3083.7,
  },
  44512980: {
    surtaxMonths: 189.4,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 274.63,
    iva: 43.94,
    toDeposit: 2838.14,
  },
  44520391: {
    surtaxMonths: 180.63,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 261.92,
    iva: 41.91,
    toDeposit: 2706.73,
  },
  44517491: {
    surtaxMonths: 265.18,
    surtaxComissionPercentage: '8.00%',
    comissionWithoutIVA: 354.67,
    iva: 56.75,
    toDeposit: 2903.28,
  },
  44522239: {
    surtaxMonths: 573.27,
    surtaxComissionPercentage: '10.00%',
    comissionWithoutIVA: 728.05,
    iva: 116.49,
    toDeposit: 4888.13,
  },
  44525161: {
    surtaxMonths: 155.7,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 295.82,
    iva: 47.33,
    toDeposit: 4846.73,
  },
  44547520: {
    surtaxMonths: 147.5,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 280.24,
    iva: 44.84,
    toDeposit: 4591.46,
  },
  44549727: {
    surtaxMonths: 366.44,
    surtaxComissionPercentage: '10.00%',
    comissionWithoutIVA: 465.37,
    iva: 74.46,
    toDeposit: 3124.54,
  },
  44550372: {
    surtaxMonths: 172.78,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 250.53,
    iva: 40.09,
    toDeposit: 2589.05,
  },
  44550726: {
    surtaxMonths: 162.31,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 235.35,
    iva: 37.66,
    toDeposit: 2432.14,
  },
  44551330: {
    surtaxMonths: 122.81,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 233.34,
    iva: 37.33,
    toDeposit: 3822.95,
  },
  44552302: {
    surtaxMonths: 85.97,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 163.34,
    iva: 26.13,
    toDeposit: 2676.08,
  },
  44553256: {
    surtaxMonths: 410.25,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 594.86,
    iva: 95.18,
    toDeposit: 6147.41,
  },
  44554629: {
    surtaxMonths: 249.03,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 361.09,
    iva: 57.77,
    toDeposit: 3731.57,
  },
  44554855: {
    surtaxMonths: 192.51,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 279.13,
    iva: 44.66,
    toDeposit: 2884.64,
  },
  44556488: {
    surtaxMonths: 229.9,
    surtaxComissionPercentage: '10.00%',
    comissionWithoutIVA: 291.97,
    iva: 46.72,
    toDeposit: 1960.3,
  },
  44559709: {
    surtaxMonths: 269.38,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 390.6,
    iva: 62.5,
    toDeposit: 4036.56,
  },
  44559976: {
    surtaxMonths: 288.97,
    surtaxComissionPercentage: '8.00%',
    comissionWithoutIVA: 386.5,
    iva: 61.84,
    toDeposit: 3163.79,
  },
  44559818: {
    surtaxMonths: 151.18,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 219.21,
    iva: 35.07,
    toDeposit: 2265.42,
  },
  44560877: {
    surtaxMonths: 117.03,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 222.35,
    iva: 35.58,
    toDeposit: 3642.99,
  },
  44562002: {
    surtaxMonths: 725.64,
    surtaxComissionPercentage: '8.00%',
    comissionWithoutIVA: 970.54,
    iva: 155.29,
    toDeposit: 7944.64,
  },
  44628022: {
    surtaxMonths: 50.32,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 95.61,
    iva: 15.3,
    toDeposit: 1566.43,
  },
  44625490: {
    surtaxMonths: 582.38,
    surtaxComissionPercentage: '8.00%',
    comissionWithoutIVA: 778.93,
    iva: 124.63,
    toDeposit: 6376.14,
  },
  44630682: {
    surtaxMonths: 104.45,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 151.46,
    iva: 24.23,
    toDeposit: 1565.19,
  },
  44637132: {
    surtaxMonths: 124.35,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 180.31,
    iva: 28.85,
    toDeposit: 1863.33,
  },
  44640187: {
    surtaxMonths: 342.16,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 496.13,
    iva: 79.38,
    toDeposit: 5127.1,
  },
  44643967: {
    surtaxMonths: 224.61,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 426.77,
    iva: 68.28,
    toDeposit: 6992.08,
  },
  44645865: {
    surtaxMonths: 184.14,
    surtaxComissionPercentage: '8.00%',
    comissionWithoutIVA: 246.28,
    iva: 39.4,
    toDeposit: 2016.0,
  },
  44649014: {
    surtaxMonths: 263.16,
    surtaxComissionPercentage: '10.00%',
    comissionWithoutIVA: 334.21,
    iva: 53.47,
    toDeposit: 2243.89,
  },
  44648923: {
    surtaxMonths: 760.82,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 1103.19,
    iva: 176.51,
    toDeposit: 11400.65,
  },
  44664282: {
    surtaxMonths: 44.02,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 83.64,
    iva: 13.38,
    toDeposit: 1370.38,
  },
  44664604: {
    surtaxMonths: 342.73,
    surtaxComissionPercentage: '8.00%',
    comissionWithoutIVA: 458.41,
    iva: 73.34,
    toDeposit: 3752.41,
  },
  44665682: {
    surtaxMonths: 12.6,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 23.93,
    iva: 3.83,
    toDeposit: 392.1,
  },
  44666517: {
    surtaxMonths: 48.97,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 93.04,
    iva: 14.89,
    toDeposit: 1524.28,
  },
  44667983: {
    surtaxMonths: 274.61,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 398.18,
    iva: 63.71,
    toDeposit: 4114.86,
  },
  44668256: {
    surtaxMonths: 209.76,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 304.15,
    iva: 48.66,
    toDeposit: 3143.15,
  },
  44667908: {
    surtaxMonths: 72.71,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 138.15,
    iva: 22.1,
    toDeposit: 2263.37,
  },
  44671951: {
    surtaxMonths: 71.73,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 113.57,
    iva: 18.17,
    toDeposit: 1063.69,
  },
  44677442: {
    surtaxMonths: 825.36,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 1306.81,
    iva: 209.09,
    toDeposit: 12240.03,
  },
  44671154: {
    surtaxMonths: 115.37,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 219.2,
    iva: 35.07,
    toDeposit: 3591.36,
  },
  44680603: {
    surtaxMonths: 133.67,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 253.98,
    iva: 40.64,
    toDeposit: 4161.15,
  },
  44686654: {
    surtaxMonths: 79.67,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 151.37,
    iva: 24.22,
    toDeposit: 2480.01,
  },
  44689398: {
    surtaxMonths: 82.38,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 178.5,
    iva: 28.56,
    toDeposit: 2539.09,
  },
  44703553: {
    surtaxMonths: 77.37,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 147.0,
    iva: 23.52,
    toDeposit: 2408.47,
  },
  44710186: {
    surtaxMonths: 287.08,
    surtaxComissionPercentage: '10.00%',
    comissionWithoutIVA: 364.59,
    iva: 58.33,
    toDeposit: 2447.87,
  },
  44710115: {
    surtaxMonths: 247.52,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 358.91,
    iva: 57.42,
    toDeposit: 3709.02,
  },
  44710907: {
    surtaxMonths: 100.79,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 146.14,
    iva: 23.38,
    toDeposit: 1510.28,
  },
  44715834: {
    surtaxMonths: 1099.08,
    surtaxComissionPercentage: '8.00%',
    comissionWithoutIVA: 1470.01,
    iva: 235.2,
    toDeposit: 12033.22,
  },
  44718559: {
    surtaxMonths: 394.7,
    surtaxComissionPercentage: '8.00%',
    comissionWithoutIVA: 527.92,
    iva: 84.47,
    toDeposit: 4321.41,
  },
  44720527: {
    surtaxMonths: 389.05,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 564.13,
    iva: 90.26,
    toDeposit: 5829.83,
  },
  44720637: {
    surtaxMonths: 74.3,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 141.17,
    iva: 22.59,
    toDeposit: 2312.83,
  },
  44723148: {
    surtaxMonths: 134.61,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 213.14,
    iva: 34.1,
    toDeposit: 1996.29,
  },
  44741830: {
    surtaxMonths: 160.8,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 348.4,
    iva: 55.74,
    toDeposit: 4955.85,
  },
  44745074: {
    surtaxMonths: 591.47,
    surtaxComissionPercentage: '10.00%',
    comissionWithoutIVA: 751.16,
    iva: 120.19,
    toDeposit: 5043.33,
  },
  44746574: {
    surtaxMonths: 336.1,
    surtaxComissionPercentage: '10.00%',
    comissionWithoutIVA: 426.84,
    iva: 68.29,
    toDeposit: 2865.82,
  },
  44748035: {
    surtaxMonths: 160.41,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 304.77,
    iva: 48.76,
    toDeposit: 4993.38,
  },
  44723672: {
    surtaxMonths: 198.5,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 287.83,
    iva: 46.05,
    toDeposit: 2974.48,
  },
  44754951: {
    surtaxMonths: 57.15,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 108.59,
    iva: 17.37,
    toDeposit: 1779.15,
  },
  44793790: {
    surtaxMonths: 48.12,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 91.42,
    iva: 14.63,
    toDeposit: 1497.81,
  },
  44796468: {
    surtaxMonths: 106.12,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 201.63,
    iva: 32.26,
    toDeposit: 3303.43,
  },
  44796651: {
    surtaxMonths: 154.98,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 224.72,
    iva: 35.95,
    toDeposit: 2322.3,
  },
  44797179: {
    surtaxMonths: 64.08,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 121.75,
    iva: 19.48,
    toDeposit: 1994.8,
  },
  44798919: {
    surtaxMonths: 385.38,
    surtaxComissionPercentage: '8.00%',
    comissionWithoutIVA: 515.45,
    iva: 82.47,
    toDeposit: 4219.36,
  },
  44800393: {
    surtaxMonths: 196.66,
    surtaxComissionPercentage: '10.00%',
    comissionWithoutIVA: 249.75,
    iva: 39.96,
    toDeposit: 1676.85,
  },
  44800579: {
    surtaxMonths: 76.2,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 144.79,
    iva: 23.17,
    toDeposit: 2372.2,
  },
  44800757: {
    surtaxMonths: 405.32,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 878.2,
    iva: 140.51,
    toDeposit: 12492.09,
  },
  44802466: {
    surtaxMonths: 97.62,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 185.47,
    iva: 29.68,
    toDeposit: 3038.78,
  },
  44801514: {
    surtaxMonths: 178.67,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 259.07,
    iva: 41.45,
    toDeposit: 2677.31,
  },
  44801483: {
    surtaxMonths: 285.48,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 413.95,
    iva: 66.23,
    toDeposit: 4277.82,
  },
  44811550: {
    surtaxMonths: 485.47,
    surtaxComissionPercentage: '10.00%',
    comissionWithoutIVA: 616.55,
    iva: 98.65,
    toDeposit: 4139.52,
  },
  44856846: {
    surtaxMonths: 1544.1,
    surtaxComissionPercentage: '10.00%',
    comissionWithoutIVA: 2084.54,
    iva: 333.53,
    toDeposit: 13022.96,
  },
  44857162: {
    surtaxMonths: 191.75,
    surtaxComissionPercentage: '8.00%',
    comissionWithoutIVA: 256.47,
    iva: 41.03,
    toDeposit: 2099.4,
  },
  44859757: {
    surtaxMonths: 85.59,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 162.62,
    iva: 26.02,
    toDeposit: 2664.31,
  },
  44862165: {
    surtaxMonths: 126.97,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 184.1,
    iva: 29.46,
    toDeposit: 1902.56,
  },
  44859648: {
    surtaxMonths: 96.04,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 182.48,
    iva: 29.2,
    toDeposit: 2989.74,
  },
  44863492: {
    surtaxMonths: 367.82,
    surtaxComissionPercentage: '8.00%',
    comissionWithoutIVA: 491.96,
    iva: 78.71,
    toDeposit: 4027.09,
  },
  44865198: {
    surtaxMonths: 269.38,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 390.6,
    iva: 62.5,
    toDeposit: 4036.57,
  },
  44866293: {
    surtaxMonths: 125.26,
    surtaxComissionPercentage: '8.00%',
    comissionWithoutIVA: 167.54,
    iva: 26.81,
    toDeposit: 1371.46,
  },
  44870113: {
    surtaxMonths: 79.06,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 114.64,
    iva: 18.34,
    toDeposit: 1184.69,
  },
  44872278: {
    surtaxMonths: 110.97,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 210.84,
    iva: 33.73,
    toDeposit: 3454.38,
  },
  44874228: {
    surtaxMonths: 187.51,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 271.88,
    iva: 43.5,
    toDeposit: 2809.71,
  },
  44877583: {
    surtaxMonths: 163.75,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 311.12,
    iva: 49.78,
    toDeposit: 5097.28,
  },
  44895189: {
    surtaxMonths: 48.97,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 93.04,
    iva: 14.89,
    toDeposit: 1524.29,
  },
  44897642: {
    surtaxMonths: 310.07,
    surtaxComissionPercentage: '10.00%',
    comissionWithoutIVA: 393.79,
    iva: 63.01,
    toDeposit: 2643.9,
  },
  44898600: {
    surtaxMonths: 129.61,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 246.26,
    iva: 39.4,
    toDeposit: 4034.69,
  },
  44898699: {
    surtaxMonths: 13066.39,
    surtaxComissionPercentage: '10.00%',
    comissionWithoutIVA: 16594.32,
    iva: 2655.09,
    toDeposit: 111414.49,
  },
  44899248: {
    surtaxMonths: 100.91,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 191.72,
    iva: 30.68,
    toDeposit: 3141.14,
  },
  44900180: {
    surtaxMonths: 80.7,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 153.32,
    iva: 24.53,
    toDeposit: 2512.03,
  },
  44902696: {
    surtaxMonths: 354.29,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 673.15,
    iva: 107.7,
    toDeposit: 11028.76,
  },
  44720689: {
    surtaxMonths: 92.37,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 175.51,
    iva: 28.08,
    toDeposit: 2875.48,
  },
  44903289: {
    surtaxMonths: 543.05,
    surtaxComissionPercentage: '10.00%',
    comissionWithoutIVA: 689.68,
    iva: 110.35,
    toDeposit: 4630.5,
  },
  44904669: {
    surtaxMonths: 173.83,
    surtaxComissionPercentage: '8.00%',
    comissionWithoutIVA: 232.5,
    iva: 37.2,
    toDeposit: 1903.17,
  },
  44908235: {
    surtaxMonths: 117.77,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 223.76,
    iva: 35.8,
    toDeposit: 3666.12,
  },
  44908722: {
    surtaxMonths: 598.09,
    surtaxComissionPercentage: '10.00%',
    comissionWithoutIVA: 759.57,
    iva: 121.53,
    toDeposit: 5099.77,
  },
  44909633: {
    surtaxMonths: 82.66,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 157.05,
    iva: 25.13,
    toDeposit: 2573.15,
  },
  44913861: {
    surtaxMonths: 106.43,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 202.23,
    iva: 32.36,
    toDeposit: 3313.25,
  },
  44915041: {
    surtaxMonths: 358.28,
    surtaxComissionPercentage: '8.00%',
    comissionWithoutIVA: 479.2,
    iva: 76.67,
    toDeposit: 3922.65,
  },
  44936914: {
    surtaxMonths: 137.31,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 199.1,
    iva: 31.86,
    toDeposit: 2057.51,
  },
  44941880: {
    surtaxMonths: 257.93,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 373.99,
    iva: 59.84,
    toDeposit: 3864.94,
  },
  44943910: {
    surtaxMonths: 161.38,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 306.63,
    iva: 49.06,
    toDeposit: 5023.76,
  },
  44945209: {
    surtaxMonths: 49.75,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 94.53,
    iva: 15.12,
    toDeposit: 1548.78,
  },
  44945298: {
    surtaxMonths: 50.32,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 95.61,
    iva: 15.3,
    toDeposit: 1566.43,
  },
  44951450: {
    surtaxMonths: 121.84,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 231.5,
    iva: 37.04,
    toDeposit: 3792.91,
  },
  44949708: {
    surtaxMonths: 218.1,
    surtaxComissionPercentage: '10.00%',
    comissionWithoutIVA: 276.98,
    iva: 44.32,
    toDeposit: 1859.65,
  },
  44954568: {
    surtaxMonths: 146.18,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 316.73,
    iva: 50.68,
    toDeposit: 4505.32,
  },
  44956770: {
    surtaxMonths: 295.39,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 428.32,
    iva: 68.53,
    toDeposit: 4426.35,
  },
  44970640: {
    surtaxMonths: 251.92,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 478.64,
    iva: 76.58,
    toDeposit: 7841.98,
  },
  44960175: {
    surtaxMonths: 104.73,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 151.86,
    iva: 24.3,
    toDeposit: 1569.34,
  },
  44977099: {
    surtaxMonths: 95.16,
    surtaxComissionPercentage: '3.00%',
    comissionWithoutIVA: 180.81,
    iva: 28.93,
    toDeposit: 2962.3,
  },
  44977917: {
    surtaxMonths: 388.1,
    surtaxComissionPercentage: '6.00%',
    comissionWithoutIVA: 562.74,
    iva: 90.04,
    toDeposit: 5815.54,
  },
};
