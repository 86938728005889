import { Badge, Table } from 'react-bootstrap';
import {
  Button,
  DialogContentText,
  DialogTitle,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React, { useEffect } from 'react';
import {
  applyExternalPayment,
  applyextraordinaryPayment,
  cancelCreditOrder,
  getCreditOrder,
  notificateCreditUser,
  updateCreditOrder,
} from '../../creditOrdersSlice';
import {
  cleanEmptyKeys,
  formatDate,
  formatNumber,
  getBadgeColor,
  getNested,
} from '../../../../utils';
import {
  makeSelectCreditOrderDetails,
  selectCreditOrders,
} from '../../selectors';

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';
import MailIcon from '@material-ui/icons/Mail';
import Modal from '../../../../components/Modal';
import MomentUtils from '@date-io/moment';
import NoteIcon from '@material-ui/icons/Note';
import NumberFormatCustom from '../../../../components/NumberFormatCustom';
import { RHFMUISelect } from 'app/components/RHF';
import RecentActivities from '../../../../components/RecentActivities';
import ShouldItRender from '../../../../components/ShouldItRender';
import SplashScreen from '../../../../partials/layout/SplashScreen';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { closeModals } from '../../../modals/modalsSlice';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { makeSelectCreditOrdersLogs } from '../../../logs/selectors';
import moment from 'moment';
import { sendNotification } from 'app/features/notifications/notificationsSlice';
import { withRouter } from 'react-router';

const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  paperTarjeta: {
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  divider: {
    width: '100%',
  },
  title: {
    textAlign: 'left',
    color: '#1c1c1c',
  },
  content: {
    textAlign: 'left',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  inputs: {
    marginTop: theme.spacing(1),
  },
  rightContainer: {
    flexDirection: 'column',
  },
  item: {
    textAlign: 'left',
    justifyContent: 'space-between',
  },
  paperOrden: {
    width: '100%',
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  paperCliente: {
    width: '100%',
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  paperOps: {
    width: '100%',
    margin: theme.spacing(2, 0),
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
  },
  paperUrl: {
    width: '100%',
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
  },
  item1: {
    textAlign: 'left',
  },
  item2: {
    textAlign: 'center',
  },
  tableTitle: {
    fontWeight: 401,
  },
  tables: {
    width: '100%',
  },
  margintop: {
    marginTop: theme.spacing(2),
  },
  detailButton: {
    float: 'right',
  },
}));

function DetailPaymentOrder(props) {
  const classes = useStyles();
  const {
    notifications,
    creditOrder,
    creditOrders,
    creditOrdersLogs,
    closeModals,
    notificateCreditUser,
    getCreditOrder,
    updateCreditOrder,
    applyExternalPayment,
    applyextraordinaryPayment,
    cancelCreditOrder,
    setActionButton,
    history,
    intl,
  } = props;
  const [showSMSModal, setShowSMSModal] = React.useState(false);
  const [
    showModalCancelingOrder,
    setShowModalCancelingOrder,
  ] = React.useState(false);
  const [
    showEmailPaymentLink,
    setShowEmailPaymentLink,
  ] = React.useState(false);
  const [showWhatsModal, setShowWhatsModal] = React.useState(false);
  const [showAdvanceModal, setShowAdvanceModal] = React.useState(
    false,
  );
  const [
    showExtraordinaryPaymentModal,
    setShowExtraordinaryPaymentModal,
  ] = React.useState(false);
  const [
    showUpdateCreditOrderModal,
    setShowUpdateCreditOrderModal,
  ] = React.useState(false);
  const [paymentsToRender, setPaymentsToRender] = React.useState([]);
  const [balance, setBalance] = React.useState(0);
  const [amount, setAmount] = React.useState('');
  const [selectedPayment, setSelectedPayment] = React.useState(null);
  const {
    handleSubmit,
    register,
    errors,
    formState,
    control,
    setValue,
    watch,
    reset,
  } = useForm({
    defaultValues: {
      type: '',
      date: null,
      amount: '',
      reference: '',
      clientName: '',
      clientPhoneNumber: '',
      terms: '',
      termType: '',
    },
  });

  const date = watch('date');

  useEffect(() => {
    props.setLeftSideComponent(
      <div className="kt-subheader__main">
        <div>
          <h3 className="kt-subheader__title">
            Detalle orden de crédito
          </h3>
        </div>
      </div>,
    );
    let data = {
      orderID: props.match.params.id,
    };
    getCreditOrder(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleDisplayRightButtons();
    handlerenderPaymentsDetails();

    if (creditOrder)
      reset({
        clientName: creditOrder.clientName,
        clientPhoneNumber: creditOrder.clientPhoneNumber,
        clientEmail: creditOrder.clientEmail,
        terms: creditOrder.terms,
        termType: creditOrder.termType,
        email: creditOrder.clientEmail,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditOrder]);

  const handleDisplayRightButtons = () => {
    const status = getNested(null, creditOrder, 'status');
    if (status !== 'canceled') {
      return setActionButton(
        <>
          <ShouldItRender
            locationPage={`/${props.location.pathname.split('/')[1]}`}
            action="update"
          >
            <Button
              variant="contained"
              className="btn btn-label-info btn-bold btn-sm btn-icon-h kt-margin-l-10"
              onClick={toogleUpdateCreditModal}
            >
              Editar orden
              <EditIcon className="kt-margin-l-10" />
            </Button>
          </ShouldItRender>
          <ShouldItRender
            locationPage={`/${props.location.pathname.split('/')[1]}`}
            action="extraordinaryPayment"
          >
            <Button
              variant="contained"
              className="btn btn-label-info btn-bold btn-sm btn-icon-h kt-margin-l-10"
              onClick={toogleExtraordinaryPaymentModal}
            >
              Crear ficha de pago
              <NoteIcon className="kt-margin-l-10" />
            </Button>
          </ShouldItRender>
          <ShouldItRender
            locationPage={`/${props.location.pathname.split('/')[1]}`}
            action="externalPayment"
          >
            <Button
              variant="contained"
              className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
              onClick={toogleAdvanceModal}
            >
              Aplicar pago
              <AttachMoneyIcon className="kt-margin-l-10" />
            </Button>
          </ShouldItRender>
          <ShouldItRender
            locationPage={`/${props.location.pathname.split('/')[1]}`}
            action="cancelOrder"
          >
            <Button
              variant="contained"
              className="btn btn-label-warning btn-bold btn-sm btn-icon-h kt-margin-l-10"
              onClick={toogleCancelOrderModal}
            >
              Cancelar orden
              <CancelIcon className="kt-margin-l-10" />
            </Button>
          </ShouldItRender>
        </>,
      );
    } else {
      props.setActionButton(null);
    }
  };

  const toogleUpdateCreditModal = () =>
    setShowUpdateCreditOrderModal(!showUpdateCreditOrderModal);

  const toogleAdvanceModal = () =>
    setShowAdvanceModal(!showAdvanceModal);

  const toogleExtraordinaryPaymentModal = () =>
    setShowExtraordinaryPaymentModal(!showExtraordinaryPaymentModal);

  const toogleSMSModal = () => setShowSMSModal(!showSMSModal);

  const closeModalSMS = () => setShowSMSModal(false);

  const toogleWhatsModal = () => setShowWhatsModal(!showWhatsModal);

  const closeModalWhats = () => setShowWhatsModal(false);

  const toogleCancelOrderModal = () =>
    setShowModalCancelingOrder(!showModalCancelingOrder);

  const closeModalCancelingOrder = () =>
    setShowModalCancelingOrder(false);

  const handleToogleEmailLinkModal = () => {
    setShowEmailPaymentLink(!showEmailPaymentLink);
  };

  const handleCloseModals = (redirect = null) => {
    closeModalCancelingOrder();
    closeModalWhats();
    closeModalSMS();
    setShowEmailPaymentLink(false);
    closeModals({ history, redirect });
  };

  const getOrderCreator = () => {
    let creator = '-';
    const tempCreditOrderLogs = getNested([], creditOrdersLogs);
    for (let i = 0; i < tempCreditOrderLogs.length; i++) {
      if (tempCreditOrderLogs[i].type === 'CREATE') {
        creator = tempCreditOrderLogs[i].text.slice(
          tempCreditOrderLogs[i].text.indexOf('por') +
            'por'.length +
            1,
          tempCreditOrderLogs[i].text.length,
        );
        break;
      }
    }
    return creator;
  };

  const handleCancelOrder = () => {
    const { id } = props.match.params;
    const data = {
      id,
      agencyID: creditOrder.agencyID,
    };
    cancelCreditOrder(data);
  };

  const handleUpdateCreditOrder = (formData) => {
    Object.keys(formData).forEach((key) => {
      const creditOrderKeyValue =
        typeof creditOrder[key] !== 'string'
          ? creditOrder[key].toString()
          : creditOrder[key];
      if (creditOrderKeyValue === formData[key]) delete formData[key];
    });

    const data = {
      ...formData,
      id: props.match.params.id,
    };
    updateCreditOrder(data);
    setShowUpdateCreditOrderModal(false);
  };

  const handleApplyExternPayment = (formData) => {
    const data = {
      ...formData,
      amount: parseFloat(amount.replace(/,/g, ''), 10),
      creditOrderID: props.match.params.id,
    };
    applyExternalPayment(data);
    // applyAdvancePaymentOrder(data);
    setShowAdvanceModal(false);
  };

  const handleApplyExtraordinaryPayment = (formData) => {
    const data = {
      invoiceData: {
        email: formData.email,
      },
      amount: parseFloat(amount.replace(/,/g, ''), 10),
      creditOrderID: props.match.params.id,
    };
    data.invoiceData = cleanEmptyKeys(data.invoiceData);
    applyextraordinaryPayment(data);
    // applyAdvancePaymentOrder(data);
    setShowExtraordinaryPaymentModal(false);
  };

  const onSubmit = (type, formData) => {
    const data = {
      ...formData,
      paymentID: selectedPayment,
      type,
    };
    notificateCreditUser(data);
    handleCloseModals();
  };

  const handleValidateTotal = () => {
    return amount > getNested(0, creditOrder, 'total');
  };

  const calculateExpDate = () => {
    const terms = getNested(null, creditOrder, 'terms');
    const termType = getNested(null, creditOrder, 'termType');
    const startAt = getNested(null, creditOrder, 'startAt');
    if (terms && termType && startAt) {
      const tempStartAt = moment(startAt);
      if (termType === 'semanal') {
        tempStartAt.add(terms, 'w');
      } else if (termType === 'quincenal') {
        tempStartAt.add(terms * 15, 'd');
      } else if (termType === 'mensual') {
        tempStartAt.add(terms, 'M');
      }
      return tempStartAt.format('DD/MM/YYYY');
    }
    return '-';
  };

  const getNextPaymentDate = () => {
    const terms = getNested(null, creditOrder, 'terms');
    const termType = getNested(null, creditOrder, 'termType');
    const startAt = getNested(null, creditOrder, 'startAt');
    const finalDate = calculateExpDate();
    const actualDifference = moment().diff(
      moment(finalDate, 'DD/MM/YYYY'),
    );
    if (actualDifference >= 0) return '-';
    if (terms && termType && startAt) {
      let tempStartAt = moment(startAt);
      let termLetter = '';
      if (termType === 'semanal') {
        termLetter = 'weeks';
      } else if (termType === 'quincenal') {
        termLetter = 'days';
      } else if (termType === 'mensual') {
        termLetter = 'months';
      }
      for (let i = 0; i <= terms; i++) {
        if (termLetter === 'days') {
          if (tempStartAt.diff(moment()) >= 0) {
            return tempStartAt.format('DD/MM/YYYY');
          }
          tempStartAt.add(15, 'days');
        } else {
          if (tempStartAt.diff(moment()) >= 0) {
            return tempStartAt.format('DD/MM/YYYY');
          }
          tempStartAt.add(1, termLetter);
        }
      }
    }
    return '-';
  };

  const renderUpdateCreditOrderModal = () => {
    return (
      <Modal
        open={showUpdateCreditOrderModal}
        closeModal={toogleUpdateCreditModal}
        dialogTitle="Actaulizar orden de crédito"
        dialogText="Actualice los siguientes campos de la orden de crédito."
        actionButtonText="Actualizar orden"
        dialogChildren={
          <form
            id="update-form"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(handleUpdateCreditOrder)}
          >
            <TextField
              required
              inputRef={register({
                required: true,
              })}
              error={Boolean(errors.name)}
              label="Nombre"
              name="clientName"
              margin="normal"
              variant="outlined"
            />
            <TextField
              required
              inputRef={register({
                required: true,
              })}
              error={Boolean(errors.name)}
              label="Teléfono celular"
              name="clientPhoneNumber"
              margin="normal"
              variant="outlined"
            />
            <TextField
              required
              inputRef={register({
                required: true,
                pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
              error={Boolean(errors.clientEmail)}
              label="Correo electrónico"
              name="clientEmail"
              margin="normal"
              variant="outlined"
            />
            <TextField
              required
              inputRef={register({
                required: true,
              })}
              error={Boolean(errors.name)}
              label="Plazo"
              name="terms"
              margin="normal"
              variant="outlined"
              type="number"
            />
            <RHFMUISelect
              name="termType"
              className={classes.inputs}
              label="Tipo de plazo"
              control={control}
              rules={{ required: true }}
              error={!!errors.termType}
            >
              <MenuItem disabled value="">
                Selecciona una opción
              </MenuItem>
              <MenuItem value={'semanal'}>Semanal</MenuItem>
              <MenuItem value={'quincenal'}>Quincenal</MenuItem>
              <MenuItem value={'mensual'}>Mensual</MenuItem>
            </RHFMUISelect>
          </form>
        }
        form={'update-form'}
      />
    );
  };

  const renderAdvanceModal = () => {
    return (
      <Modal
        open={showAdvanceModal}
        closeModal={toogleAdvanceModal}
        dialogTitle="Aplicar pago"
        dialogText="Ingresa los datos solicitados para aplicar un abono a la cuenta del cliente."
        actionButtonText="Aplicar pago"
        dialogChildren={
          <form
            id="advance-form"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(handleApplyExternPayment)}
          >
            <TextField
              required
              error={Boolean(errors.amount) || handleValidateTotal()}
              label="Cantidad"
              name="amount"
              value={amount}
              onChange={({ target }) => setAmount(target.value)}
              margin="normal"
              variant="outlined"
              helperText={
                handleValidateTotal()
                  ? 'El valor no puede ser mayor al total de la orden.'
                  : null
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                inputComponent: NumberFormatCustom,
                inputProps: { quantity: true },
              }}
            />
            <RHFMUISelect
              name="type"
              className={classes.inputs}
              label="Método de pago"
              control={control}
              rules={{ required: true }}
              error={!!errors.type}
            >
              <MenuItem disabled value="">
                Selecciona una opción
              </MenuItem>
              <MenuItem value={'CASH'}>Efectivo</MenuItem>
              <MenuItem value={'TRANSFER'}>Transferencia</MenuItem>
              <MenuItem value={'CARD'}>
                Tarjeta de crédito/débito
              </MenuItem>
            </RHFMUISelect>
            <TextField
              required
              name="reference"
              error={Boolean(errors.reference)}
              inputRef={register({
                required: true,
              })}
              label="Número de referencia"
              margin="normal"
              variant="outlined"
            />
            <Controller
              control={control}
              name="date"
              rules={{ required: true }}
              as={
                <MuiPickersUtilsProvider
                  utils={MomentUtils}
                  locale={'es'}
                >
                  <DatePicker
                    autoOk
                    fullWidth
                    disableFuture
                    disableToolbar
                    error={Boolean(errors.date)}
                    className={classes.inputs}
                    label="Fecha de pago"
                    variant="inline"
                    inputVariant="outlined"
                    value={date}
                    onChange={(e) => setValue('date', e, false)}
                  />
                </MuiPickersUtilsProvider>
              }
            />
            <DialogContentText className={classes.margintop}>
              Importante: Al aplicar un pago se abonará directamente
              al Saldo Remanente del cliente.
            </DialogContentText>
          </form>
        }
        form={'advance-form'}
      />
    );
  };

  const addExtraordinaryPaymentModal = () => {
    return (
      <Modal
        open={showExtraordinaryPaymentModal}
        closeModal={toogleExtraordinaryPaymentModal}
        dialogTitle="Crear ficha de pago"
        dialogText="Ingresa el monto de la ficha de pago."
        actionButtonText="Crear"
        dialogChildren={
          <form
            id="extraordinary-payment-form"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(handleApplyExtraordinaryPayment)}
          >
            <TextField
              required
              error={Boolean(errors.amount) || handleValidateTotal()}
              label="Cantidad"
              name="amount"
              value={amount}
              onChange={({ target }) => setAmount(target.value)}
              margin="normal"
              variant="outlined"
              helperText={
                handleValidateTotal()
                  ? 'El valor no puede ser mayor al total de la orden.'
                  : null
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                inputComponent: NumberFormatCustom,
                inputProps: { quantity: true },
              }}
            />
            <TextField
              inputRef={register({
                required: true,
                pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
              className={classes.inputs}
              error={Boolean(errors.email)}
              required
              name="email"
              type="email"
              autoComplete="email"
              label="Email"
              margin="normal"
              variant="outlined"
            />
            <DialogContentText className={classes.margintop}>
              Se creará una ficha de pago en OXXO por la cantidad
              ingresada y se enviará automáticamente al cliente con su
              número de referencia.
            </DialogContentText>
          </form>
        }
        form={'extraordinary-payment-form'}
      />
    );
  };

  const renderSendSmsDialog = () => {
    return (
      <Modal
        open={showSMSModal}
        closeModal={toogleSMSModal}
        dialogTitle="Enviar SMS"
        dialogText="Se enviará un SMS al número registrado previamente. En
        caso de ser incorrecto puede cambirlo en seguida."
        actionButtonText="Enviar"
        dialogChildren={
          <form
            id="sms-form"
            className={classes.container}
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit((data) => onSubmit('sms', data))}
          >
            <TextField
              required
              name="phoneNumber"
              error={Boolean(errors.phoneNumber)}
              inputRef={register({
                required: true,
              })}
              label="Teléfono celular"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              inputProps={{
                type: 'number',
              }}
            />
          </form>
        }
        form={'sms-form'}
      />
    );
  };

  const renderSendWhatsDialog = () => {
    return (
      <Modal
        open={showWhatsModal}
        closeModal={toogleWhatsModal}
        dialogTitle="Enviar mensaje por Whatsapp"
        dialogText="Se enviará un mensaje por medio de Whatsapp al número que ingrese."
        actionButtonText="Enviar"
        dialogChildren={
          <form
            id="whatsapp-form"
            className={classes.container}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit((data) =>
              onSubmit('whatsapp', data),
            )}
          >
            <TextField
              name="phoneNumber"
              label="Teléfono"
              error={Boolean(errors.phoneNumber)}
              className={classes.textField}
              inputRef={register({
                required: true,
              })}
              margin="normal"
              variant="outlined"
              inputProps={{
                type: 'number',
              }}
            />
          </form>
        }
        form={'whatsapp-form'}
      />
    );
  };

  const renderSendMailDialog = () => {
    return (
      <Modal
        open={showEmailPaymentLink}
        closeModal={handleToogleEmailLinkModal}
        dialogTitle="Enviar correo"
        dialogText="Escriba el correo al cual le desea enviar el link de pago."
        actionButtonText="Enviar"
        dialogChildren={
          <form
            className={classes.container}
            id="email-form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit((data) => onSubmit('email', data))}
          >
            <TextField
              name="email"
              label="Correo"
              className={classes.textField}
              error={errors.email && formState.touched.email}
              inputRef={register({
                required: true,
                pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
              margin="normal"
              variant="outlined"
            />
          </form>
        }
        form={'email-form'}
      />
    );
  };

  const renderCancelOrderDialog = () => {
    return (
      <Modal
        open={showModalCancelingOrder}
        closeModal={toogleCancelOrderModal}
        dialogTitle="Cancelar orden de crédito"
        dialogText="Estás a punto de cancelar esta orden de crédito, una vez
        cancelada no se puede deshacer la acción, ¿deseas
        continuar?"
        actionButtonText="Cancelar orden"
        onClick={handleCancelOrder}
      />
    );
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = creditOrders;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message, redirect } = creditOrders[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={() => handleCloseModals(redirect)}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={() => handleCloseModals(redirect)}
          />
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  const renderFeedBackModalnotifications = () => {
    try {
      const { successModal, errorModal } = notifications;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message } = notifications[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={handleCloseModals}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={handleCloseModals}
          />
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  const renderOrderDetails = () => {
    return (
      <Paper className={classes.paper}>
        <DialogTitle className={classes.title}>
          Resumen de la orden
        </DialogTitle>
        <Table bordered className={classes.tables}>
          <tbody>
            <tr>
              <td className={classes.tableTitle}>ID DE LA ORDEN</td>
              <td>{getNested('-', creditOrder, 'id')}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>ESTADO</td>
              <td>
                <Badge
                  className={classes.badges}
                  variant={getBadgeColor(
                    intl.formatMessage({
                      id: `CREDIT_ORTDER.STATUS.${getNested(
                        null,
                        creditOrder,
                        'status',
                      )}`,
                    }),
                  )}
                >
                  {intl.formatMessage({
                    id: `CREDIT_ORTDER.STATUS.${getNested(
                      null,
                      creditOrder,
                      'status',
                    )}`,
                  })}
                </Badge>
              </td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>CREADA POR</td>
              <td>{getOrderCreator()}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>
                MONTO ORIGINAL FACTURA
              </td>
              <td>
                {formatNumber(getNested(0, creditOrder, 'total'))}
              </td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>ENGANCHE</td>
              <td>
                {formatNumber(
                  getNested('-', creditOrder, 'enganche'),
                )}
              </td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>
                MONTO TOTAL DE CRÉDITO
              </td>
              <td>
                {formatNumber(getNested('-', creditOrder, 'total'))}
              </td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>PLAZO</td>
              <td>{`${getNested(
                '-',
                creditOrder,
                'terms',
              )} ${intl.formatMessage({
                id: `CREDIT_ORTDER.TERM_TYPE.DIRECT.${getNested(
                  '-',
                  creditOrder,
                  'termType',
                )}`,
              })}`}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>CARGO RECURRENTE</td>
              <td>
                {formatNumber(
                  (getNested(0, creditOrder, 'total') -
                    getNested(0, creditOrder, 'enganche')) /
                    getNested('-', creditOrder, 'terms'),
                )}
              </td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>
                FECHA DE CREACIÓN
              </td>
              <td>
                {formatDate(getNested('-', creditOrder, 'createdAt'))}
              </td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>FECHA DE INICIO</td>
              <td>
                {moment(
                  getNested('-', creditOrder, 'startAt'),
                ).format('DD/MM/YYYY')}
              </td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>
                FECHA DE VENCIMIENTO
              </td>
              <td>{calculateExpDate()}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>
                NÚMERO DE FACTURA
              </td>
              <td>{getNested('-', creditOrder, 'invoiceNumber')}</td>
            </tr>
          </tbody>
        </Table>
      </Paper>
    );
  };

  // const renderCardOrBalanceDetails = () => {
  //   if (getNested(null, creditOrder))
  //     return (
  //       <Paper className={classes.paperTarjeta}>
  //         {/* <DialogTitle className={classes.title}>Balance</DialogTitle> */}

  //         <Table bordered className={classes.tables}>
  //           <thead>
  //             <tr>
  //               <th>BALANCE</th>
  //               <th>PROXIMA FECHA DE PAGO</th>
  //             </tr>
  //           </thead>
  //           <tbody>
  //             <tr>
  //               <td>
  //                 <Grid container spacing={1}>
  //                   <Grid item xs={6} sm={6}>
  //                     TOTAL:
  //                     <br />
  //                     TOTAL ABONOS:
  //                     <br />
  //                     SALDO REMANENTE:
  //                   </Grid>
  //                   <Grid item xs={6} sm={6}>
  //                     {formatNumber(
  //                       getNested('-', creditOrder, 'total'),
  //                     )}
  //                     <br />
  //                     {formatNumber(
  //                       getNested(0, creditOrder, 'total') - balance,
  //                     )}
  //                     <br />
  //                     {formatNumber(balance)}
  //                   </Grid>
  //                 </Grid>
  //                 {/* TOTAL:&nbsp;
  //                 {formatNumber(getNested('-', creditOrder, 'total'))}
  //                 <br />
  //                 TOTAL ABONOS:{' '}
  //                 {formatNumber(
  //                   getNested(0, creditOrder, 'total') - balance,
  //                 )}
  //                 <br />
  //                 SALDO REMANENTE: {formatNumber(balance)}
  //                 <br /> */}
  //               </td>
  //               <td>{getNextPaymentDate()}</td>
  //             </tr>
  //           </tbody>
  //         </Table>
  //         {/* <Table bordered className={classes.tables}>
  //           <tbody>
  //             <tr>
  //               <td className={classes.tableTitle}>Total</td>
  //               <td>
  //                 {formatNumber(getNested('0', creditOrder, 'total'))}
  //               </td>
  //             </tr>
  //             {paymentsMiniCard}
  //             <tr>
  //               <td className={classes.tableTitle}>
  //                 Saldo remanente
  //               </td>
  //               <td>{formatNumber(balance)}</td>
  //             </tr>
  //           </tbody>
  //         </Table> */}
  //       </Paper>
  //     );
  // };

  const renderCardOrBalanceDetails = () => {
    return (
      <Paper className={classes.paperCliente}>
        <DialogTitle className={classes.title}>BALANCE</DialogTitle>
        <Table bordered className={classes.tables}>
          <tbody>
            <tr>
              <td className={classes.tableTitle}>
                TOTAL ORIGINAL FACTURA
              </td>
              <td>
                {formatNumber(
                  getNested(0, creditOrder, 'total') /*  +   */,
                  // getNested(0, creditOrder, 'enganche'),
                )}
              </td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>
                TOTAL ABONOS (ENGANCHE INCLUIDO)
              </td>
              <td>
                {formatNumber(
                  getNested(0, creditOrder, 'total') - balance,
                )}
              </td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>SALDO ACTUAL</td>
              <td>{formatNumber(balance)}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>
                PRÓXIMA FECHA DE PAGO
              </td>
              <td>{getNextPaymentDate()}</td>
            </tr>
          </tbody>
        </Table>
      </Paper>
    );
  };

  const renderUserDetails = () => {
    return (
      <Paper className={classes.paperCliente}>
        <DialogTitle className={classes.title}>Cliente</DialogTitle>
        <Table bordered className={classes.tables}>
          <tbody>
            <tr>
              <td className={classes.tableTitle}>NOMBRE COMPLETO</td>
              <td>{getNested('-', creditOrder, 'clientName')}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>CORREO</td>
              <td>{getNested('-', creditOrder, 'clientEmail')}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>TELÉFONO CELULAR</td>
              <td>
                {getNested('-', creditOrder, 'clientPhoneNumber')}
              </td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>CLIENTE ID</td>
              <td>{getNested('-', creditOrder, 'clientID')}</td>
            </tr>
          </tbody>
        </Table>
      </Paper>
    );
  };

  const handlerenderPaymentsDetails = () => {
    let paymentsToRenderTemp = [];
    let balanceTemp = (
      getNested(0, creditOrder, 'total') -
      getNested(0, creditOrder, 'enganche')
    ).toFixed(2);
    const tempPayments = getNested([], creditOrder, 'payments');
    let normalPayments = 0;
    if (tempPayments.length > 0) {
      creditOrder.payments.forEach((payment, index) => {
        if (payment.status === 'DONE') balanceTemp -= payment.amount;
        if (!payment.external) normalPayments += 1;

        const paymentToRender = (
          <Paper
            key={`payment-detail-link${payment.id}`}
            className={classes.paperCliente}
          >
            {!payment.external ? (
              <>
                <DialogTitle className={classes.title}>
                  Pago {normalPayments}
                  {payment.status === 'DONE' ? (
                    <Link
                      key={`payment-detail-link${payment.id}`}
                      to={`/detail-payment/${payment.id}`}
                    >
                      <Button
                        variant="contained"
                        className={clsx(
                          'btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10',
                          classes.detailButton,
                        )}
                      >
                        Detalle de pago
                      </Button>
                    </Link>
                  ) : (
                    <>
                      <ShouldItRender
                        should={Boolean(true)}
                        locationPage={`/${
                          props.location.pathname.split('/')[1]
                        }`}
                        action="shareLink"
                        compounded
                      >
                        <Button
                          variant="contained"
                          className={clsx(
                            'btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10',
                            classes.detailButton,
                          )}
                          onClick={() => {
                            toogleWhatsModal();
                            setSelectedPayment(payment.id);
                          }}
                        >
                          WhatsApp
                          <WhatsAppIcon className="kt-margin-l-10" />
                        </Button>
                      </ShouldItRender>
                      <ShouldItRender
                        should={Boolean(true)}
                        locationPage={`/${
                          props.location.pathname.split('/')[1]
                        }`}
                        action="shareLink"
                        compounded
                      >
                        <Button
                          variant="contained"
                          className={clsx(
                            'btn btn-label-info btn-bold btn-sm btn-icon-h kt-margin-l-10',
                            classes.detailButton,
                          )}
                          onClick={() => {
                            setSelectedPayment(payment.id);
                            setShowEmailPaymentLink(true);
                          }}
                        >
                          Correo
                          <MailIcon className="kt-margin-l-10" />
                        </Button>
                      </ShouldItRender>
                    </>
                  )}
                </DialogTitle>
                <Table bordered className={classes.tables}>
                  <thead>
                    <tr>
                      <th>FECHA DE CREACIÓN</th>
                      <th>MONTO DE PAGO</th>
                      {/* <th>VENCIMIENTO</th> */}
                      <th>Num. Ref. OXXO</th>
                      <th>ESTATUS</th>
                      <th>FECHA DE PAGO</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {moment(payment.createdAt).format(
                          'DD/MM/YYYY hh:mm a',
                        )}
                      </td>
                      <td>{formatNumber(payment.amount)}</td>
                      {/* <td>
                  {moment(payment.createdAt).format(
                    'DD/MM/YYYY',
                  )}
                </td> */}
                      <td>{payment.reference}</td>
                      <td>
                        <Badge
                          className={classes.badges}
                          variant={getBadgeColor(
                            intl.formatMessage({
                              id: `PAYMENT.STATUS.${payment.status}`,
                            }),
                          )}
                        >
                          {intl.formatMessage({
                            id: `PAYMENT.STATUS.${payment.status}`,
                          })}
                        </Badge>
                      </td>
                      <td>
                        {payment.status === 'DONE' &&
                        payment.updatedAt
                          ? moment(payment.updatedAt).format(
                              'DD/MM/YYYY hh:mm a',
                            )
                          : '-'}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </>
            ) : (
              <>
                <DialogTitle className={classes.title}>
                  Pago aplicado
                  {payment.status === 'DONE' ? (
                    <Link
                      key={`payment-detail-link${payment.id}`}
                      to={`/detail-payment/${payment.id}`}
                    >
                      <Button
                        variant="contained"
                        className={clsx(
                          'btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10',
                          classes.detailButton,
                        )}
                      >
                        Detalle de pago
                      </Button>
                    </Link>
                  ) : null}
                </DialogTitle>
                <Table bordered className={classes.tables}>
                  <thead>
                    <tr>
                      <th>FECHA DE CREACIÓN</th>
                      <th>MONTO DE PAGO</th>
                      {/* <th>VENCIMIENTO</th> */}
                      <th>MÉTODO DE PAGO</th>
                      <th>NUM. REF</th>
                      <th>ESTATUS</th>
                      <th>FECHA DE PAGO</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {moment(payment.createdAt).format(
                          'DD/MM/YYYY hh:mm a',
                        )}
                      </td>
                      <td>{formatNumber(payment.amount)}</td>
                      <td>
                        {intl.formatMessage({
                          id: `PAYMENT.TYPE.${payment.type}`,
                        })}
                      </td>
                      <td>{payment.reference}</td>
                      <td>
                        <Badge
                          className={classes.badges}
                          variant={getBadgeColor(
                            intl.formatMessage({
                              id: `PAYMENT.STATUS.${payment.status}`,
                            }),
                          )}
                        >
                          {intl.formatMessage({
                            id: `PAYMENT.STATUS.${payment.status}`,
                          })}
                        </Badge>
                      </td>
                      <td>
                        {payment.date
                          ? moment(payment.date).format(
                              'DD/MM/YYYY hh:mm a',
                            )
                          : moment(payment.updatedAt).format(
                              'DD/MM/YYYY hh:mm a',
                            )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </>
            )}
          </Paper>
        );

        paymentsToRenderTemp.push(paymentToRender);
      });
    }
    setBalance(balanceTemp);
    setPaymentsToRender(paymentsToRenderTemp);
  };

  return isEmpty(creditOrder) ? (
    <SplashScreen></SplashScreen>
  ) : (
    <div>
      {renderUpdateCreditOrderModal()}
      {addExtraordinaryPaymentModal()}
      {renderAdvanceModal()}
      {renderSendSmsDialog()}
      {renderSendWhatsDialog()}
      {renderSendMailDialog()}
      {renderCancelOrderDialog()}
      {renderFeedBackModal()}
      {renderFeedBackModalnotifications()}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          {renderOrderDetails()}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderCardOrBalanceDetails()}
          {renderUserDetails()}
        </Grid>
        <Grid item xs={12} md={12}>
          {paymentsToRender}
        </Grid>
        <Grid item xs={12} md={12}>
          <RecentActivities
            logs={creditOrdersLogs}
          ></RecentActivities>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  notifications: state.notifications,
  creditOrders: selectCreditOrders(state),
  creditOrder: makeSelectCreditOrderDetails(state),
  creditOrdersLogs: makeSelectCreditOrdersLogs(state),
});

const mapDispatchToProps = {
  closeModals: closeModals,
  SendNotification: sendNotification,
  getCreditOrder: getCreditOrder,
  updateCreditOrder: updateCreditOrder,
  applyExternalPayment,
  applyextraordinaryPayment,
  cancelCreditOrder,
  notificateCreditUser,
};

export default withRouter(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(DetailPaymentOrder),
  ),
);
