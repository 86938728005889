/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'socicon/css/socicon.css';
import './index.scss'; // Standard version
import './_metronic/_assets/plugins/flaticon/flaticon.css';
import './_metronic/_assets/plugins/flaticon2/flaticon.css';
import './_metronic/_assets/plugins/line-awesome/css/line-awesome.css';
import { persistor, store } from './app/store/store';
import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { setupAxios } from './_metronic';

// import "./sass/style.react.rtl.css"; // RTL version
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

/**
 * Inject metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios, store);

const Layout = React.lazy(() =>
  import('./_metronic/layout/Layout.js'),
);

ReactDOM.render(
  <App
    store={store}
    Layout={Layout}
    persistor={persistor}
    basename={PUBLIC_URL}
  />,
  document.getElementById('root'),
);
