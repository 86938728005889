import { closeModals } from '../modals/modalsSlice';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { logout } from '../auth/authSlice';

const serviceOrdersSlice = createSlice({
  name: 'serviceOrders',
  initialState,
  reducers: {
    getServiceOrders: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getServiceOrdersSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.fromdb = action.payload.orders.rows;
        state.tablePages = action.payload.orders.pages;
        state.fullOrders = action.payload.orders.fullOrders;
      },
    },
    getServiceOrdersFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    getOrderDetail: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getOrderDetailSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.orderDetails = action.payload.order;
        state.invoicePDF = action.payload.invoicePDF;
      },
    },
    getOrderDetailFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    invoiceOrder: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    invoiceOrderSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    invoiceOrderFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    sendEmailLinkServiceOrder: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    sendEmailLinkServiceOrderSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    sendEmailLinkServiceOrderFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    importServiceOrder: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    importServiceOrderSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    importServiceOrderFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    updateServiceOrder: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    updateServiceOrderSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    updateServiceOrderFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    setReadyToPayServiceOrder: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    setReadyToPayServiceOrderSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    setReadyToPayServiceOrderFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    createAdvanceServiceOrder: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    createAdvanceServiceOrderSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    createAdvanceServiceOrderFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    createExternalPaymentServiceOrder: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    createExternalPaymentServiceOrderSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    createExternalPaymentServiceOrderFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    createOrUpdateSurtaxes: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    createOrUpdateSurtaxesSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    createOrUpdateSurtaxesFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    searchServiceOrder: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    searchServiceOrderSucces: {
      reducer(state, action) {
        state.isLoading = false;
        state.quiterModal = {
          type: 'successModal',
          message: action.payload?.message,
          show: true,
        };
      },
    },
    searchServiceOrderFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.quiterModal = {
          type: 'errorModal',
          message: action.payload.message,
          show: true,
        };
      },
    },
    downloadfileXml: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    downloadfileXmlSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    downloadfileXmlFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
  },
  extraReducers: {
    [logout]: (state, action) => {
      return initialState;
    },
    [closeModals]: (state, action) => {
      state.isLoading = false;
      state.successModal = initialState.successModal;
      state.errorModal = initialState.errorModal;
      state.quiterModal = initialState.quiterModal;
    },
  },
});

export const {
  invoiceOrder,
  invoiceOrderSuccess,
  invoiceOrderFail,
  getServiceOrders,
  getServiceOrdersSuccess,
  getServiceOrdersFail,
  getOrderDetail,
  getOrderDetailSuccess,
  getOrderDetailFail,
  sendEmailLinkServiceOrder,
  sendEmailLinkServiceOrderSuccess,
  sendEmailLinkServiceOrderFail,
  importServiceOrder,
  importServiceOrderSuccess,
  importServiceOrderFail,
  updateServiceOrder,
  updateServiceOrderSuccess,
  updateServiceOrderFail,
  setReadyToPayServiceOrder,
  setReadyToPayServiceOrderSuccess,
  setReadyToPayServiceOrderFail,
  createAdvanceServiceOrder,
  createAdvanceServiceOrderSuccess,
  createAdvanceServiceOrderFail,
  createExternalPaymentServiceOrder,
  createExternalPaymentServiceOrderSuccess,
  createExternalPaymentServiceOrderFail,
  createOrUpdateSurtaxes,
  createOrUpdateSurtaxesSuccess,
  createOrUpdateSurtaxesFail,
  searchServiceOrder,
  searchServiceOrderSucces,
  searchServiceOrderFail,
  downloadfileXml,
  downloadfileXmlSuccess,
  downloadfileXmlFail,
} = serviceOrdersSlice.actions;

export default serviceOrdersSlice.reducer;
