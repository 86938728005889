export const initialState = {
  fromdb: null,
  agenciesFilter: null,
  agencyDetails: null,
  logoUploaded: false,
  logoURL: null,
  agencyData: null,
  successModal: {
    show: false,
    message: null,
  },
  errorModal: {
    show: false,
    message: null,
  },
  isLoading: false,
  error: null,
};
