import { call, put, select } from 'redux-saga/effects';
import {
  confirmEmailFail,
  confirmEmailSuccess,
  loginFail,
  loginSuccess,
  logoutFail,
  logoutSuccess,
  passwordResetFail,
  passwordResetSuccess,
  requestPasswordResetFail,
  requestPasswordResetSuccess,
  getModalAllAgenciesSuccess,
  getModalAllAgenciesToCloseSuccess,
  getModalAllAgenciesFail,
  confirmAgencySelectaModalSuccess,
  confirmAgencySelectaModalFail,
  confirmClose,
  getSelectOneAgencySuccess,
  succesLoginModal,
  failLoginModal,
  handleCloseModalsSuccess,
  selectOneAgencyErrorSuccess,
  selectOneAgencyErrorFail,
  handleCloseModals,
} from './authSlice';
import { makeSelectSuperAdminAgenciesId } from './selectors';
import { getAgency } from '../agencies/agenciesSlice';
import { requestHandler } from '../../services/requestHandler';

export function* loginUser(action) {
  try {
    const data = {
      email: action.payload.email,
      password: action.payload.password,
    };
    const user = yield call(requestHandler, {
      method: 'POST',
      path: '/user/login',
      data,
    });
    user.session = undefined;
    yield put(loginSuccess({ user }));
    if (user.agencyID) {
      yield put(getAgency({ agencyID: user.agencyID }));
    }
  } catch (e) {
    const message = {
      title: '',
      desc: `Error al tratar de iniciar sesión, por favor verifica tu correo y contraseña`,
    };
    yield put(loginFail({ message }));
    console.log('error in login user saga', e);
  }
}

export function* logoutUserSaga(action) {
  try {
    yield put(logoutSuccess());
  } catch (e) {
    yield put(logoutFail());
    console.log('error in login user saga', e);
  }
}

export function* requestPasswordResetSaga(action) {
  try {
    const { email, history } = action.payload;
    const data = {
      email,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/user/passwordReset/request',
      data,
    });
    const message = {
      title: '',
      desc: `Revise la bandeja de entrada de su correo electrónico. Usted debe haber recibido un correo con instrucciones.`,
    };
    yield put(requestPasswordResetSuccess({ message }));
    yield call(history.push, '/auth/login');
  } catch (e) {
    const message = {
      title: '',
      desc: `Ocurrió un error al tratar de validar su nueva contraseña, por favor inténtelo de nuevo más tarde.`,
    };
    yield put(requestPasswordResetFail({ message }));
    console.log('error in request password reset', e);
  }
}

export function* passwordResetSaga(action) {
  try {
    const { token, password, history } = action.payload;
    const data = {
      token,
      newPassword: password,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/user/passwordReset/update',
      data,
    });
    const message = {
      title: '',
      desc: `La contraseña ha sido cambiada exitosamente.`,
    };
    yield put(passwordResetSuccess({ message }));
    yield call(history.push, '/auth/login');
  } catch (e) {
    const message = {
      title: '',
      desc: `Ocurrió un error al tratar de validar su nueva contraseña, por favor inténtelo de nuevo más tarde.`,
    };
    yield put(passwordResetFail({ message }));
    console.log('error in password reset saga', e);
  }
}

export function* confirmEmailSaga(action) {
  const { history, ...rest } = action.payload;
  try {
    const data = {
      ...rest,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/user/confirmEmail',
      data,
    });
    const message = {
      title: '',
      desc: `La cuenta ha sido creada exitosamente.`,
    };
    yield put(confirmEmailSuccess({ message }));
    yield call(history.push, '/auth/login');
  } catch (e) {
    const message = {
      title: '',
      desc: `Ocurrió un error al tratar de validar su cuenta, por favor inténtelo de nuevo más tarde.`,
    };
    yield put(confirmEmailFail({ message }));
    console.log('error in confirm email saga', e);
  }
}

export function* allAgenciesModalSaga(action) {
  const { user, superAdminAgenciesId } = action.payload;
  try {
    const data = {
      where: {
        userID: superAdminAgenciesId || user.id,
      },
      agencies: true,
      users: true,
    };
    const agencies = yield call(requestHandler, {
      method: 'POST',
      path: '/superAdminByAgencies/getAll',
      data,
    });
    let registers;
    if (agencies) {
      registers = agencies.agenciesByAdmin?.map((el) => {
        return el;
      });
    }
    yield put(getModalAllAgenciesSuccess({ registers }));
  } catch (error) {
    console.log(
      'error in open modal all agencies in modal saga',
      error,
    );
  }
}

export function* allAgenciesModalToCloseSaga(action) {
  try {
    yield put(getModalAllAgenciesToCloseSuccess({}));
  } catch (error) {
    console.log(
      'error in open modal all agencies in modal saga',
      error,
    );
  }
}

export function* selectOneAgencySaga(action) {
  const { agencyID } = action.payload.el;
  try {
    const data = {
      where: { id: agencyID },
      wsUsers: true,
    };
    const agency = yield call(requestHandler, {
      method: 'POST',
      path: '/superAdminByAgencies/getOne',
      data,
    });
    yield put(getSelectOneAgencySuccess({ agency }));
  } catch (error) {
    yield put(selectOneAgencyErrorSuccess({}));
    console.log('error in select one agency in modal saga', error);
  }
}

export function* closeSelectOneAgencyFailSaga(action) {
  try {
    yield put(selectOneAgencyErrorFail({}));
  } catch (error) {
    console.log('error in select one agency in modal saga', error);
  }
}

export function* confirmAgencySelectSaga(action) {
  try {
    yield put(confirmAgencySelectaModalSuccess({}));
  } catch (error) {
    console.log('error in select one agency in modal saga', error);
  }
}

export function* confirmAgencySelectToCloseSaga(action) {
  try {
    yield put(confirmClose({}));
  } catch (error) {
    console.log(
      'error in open modal all agencies in modal saga',
      error,
    );
  }
}

export function* sendPasswordSaga(action) {
  try {
    const {
      password,
      userEmail,
      superAdminAgencies,
      history,
    } = action.payload;
    const idAdmin = superAdminAgencies?.id
      ? superAdminAgencies?.id
      : superAdminAgencies;
    const data = {
      email: userEmail,
      password: password,
    };
    const user = yield call(requestHandler, {
      method: 'POST',
      path: '/user/login',
      data,
    });
    yield put(succesLoginModal({}));
    user.session = undefined;
    yield put(loginSuccess({ user, idAdmin }));
    if (user.agencyID) {
      yield put(getAgency({ agencyID: user.agencyID }));
    }
    if (window.location.pathname === '/serviceorders') {
      yield call(history.push, '/users');
    } else {
      yield call(history.push, '/serviceorders');
    }
  } catch (error) {
    yield put(failLoginModal({}));
    console.log('error in send password correctly in saga', error);
  }
}

export function* closeModalsSaga(action) {
  try {
    yield put(handleCloseModalsSuccess({}));
  } catch (error) {
    console.log('error in close modals correctly in saga', error);
  }
}
