import { createSelector } from 'reselect';
import { initialState } from './initialState';

export const selectTerminals = (state) =>
  state.terminals || initialState;

export const makeSelectTerminals = createSelector(
  selectTerminals,
  (terminalsState) => terminalsState,
);

export const makeSelectTerminalsDetails = createSelector(
  selectTerminals,
  (terminalsState) => terminalsState.userDetail,
);

export const makeSelectTerminalsIsLoading = createSelector(
  selectTerminals,
  (terminalsState) => terminalsState.isLoading,
);
