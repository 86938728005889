import { call, put, select } from 'redux-saga/effects';
import {
  createUserFail,
  createUserSuperAdminSuccess,
  createUserSuccess,
  deleteUserFail,
  deleteUserSuccess,
  getAllUsersFail,
  getAllUsersSuccess,
  getUserFail,
  getUserSuccess,
  resendInvitationFail,
  resendInvitationSuccess,
  updateUserFail,
  updateUserSuccess,
} from 'app/features/users/usersSlice.js';

import { makeSelectAgencyId } from '../auth/selectors.js';
import { requestHandler } from '../../services/requestHandler';
import { formatDatesForFilter, getNested } from 'app/utils/index.js';

export function* getAllUsersSaga(action) {
  try {
    const {
      page,
      pageSize,
      filtered,
      agencyID,
      firstDate,
      secondDate,
    } = action.payload;
    let fullUsers = [];
    const data = {
      adminUsers: {
        where: {
          agencyID: agencyID || undefined,
        },
        agencies: {
          options: {
            attributes: ['name'],
          },
        },
      },
      options: {
        count: true,
        distinct: true,
      },
      pagination: {
        page,
        perPage: pageSize,
        order: [['updatedAt', 'DESC']],
      },
      where: {
        role: { $not: ['Userless', 'Normal User', 'Super Admin'] },
      },
    };
    if (filtered.length) {
      data.where = {};
      filtered.forEach((filter) => {
        if (filter.id === 'adminUser.agency.name')
          data.adminUsers.agencies = {
            where: {
              name: {
                $iLike: `%${filter.value}%`,
              },
            },
          };
        else data.where[filter.id] = { $iLike: `%${filter.value}%` };
      });
    }
    if (firstDate && secondDate) {
      // prettier-ignore
      data.where.createdAt = formatDatesForFilter(firstDate, secondDate);
    }
    const users = yield call(requestHandler, {
      method: 'POST',
      path: '/user/getAll',
      data,
    });
    if (
      (firstDate && secondDate) ||
      (filtered.length > 0 && filtered[0].id === 'agencyName')
    ) {
      const dataDos = {
        adminUsers: {
          where: {
            agencyID: agencyID || undefined,
          },
          agencies: {
            options: {
              attributes: ['name'],
            },
          },
        },
        options: {
          count: true,
          distinct: true,
        },
        pagination: {
          order: [['updatedAt', 'DESC']],
        },
        where: {
          role: { $not: ['Userless', 'Normal User', 'Super Admin'] },
        },
      };
      if (filtered.length) {
        dataDos.where = {};
        filtered.forEach((filter) => {
          if (filter.id === 'adminUser.agency.name')
            dataDos.adminUsers.agencies = {
              where: {
                name: {
                  $iLike: `%${filter.value}%`,
                },
              },
            };
          else
            dataDos.where[filter.id] = {
              $iLike: `%${filter.value}%`,
            };
        });
      }
      if (firstDate && secondDate) {
        // prettier-ignore
        dataDos.where.createdAt = formatDatesForFilter(firstDate, secondDate);
      }
      fullUsers = yield call(requestHandler, {
        method: 'POST',
        path: '/user/getAll',
        data: dataDos,
      });
    }
    users.fullUsers = fullUsers;
    users.pages = yield call(Math.ceil, users.count / pageSize);
    yield put(getAllUsersSuccess({ users }));
  } catch (e) {
    yield put(getAllUsersFail('getUserByAgencyAdmin'));
    console.log('error in get user ByAgencyAdmin saga', e);
  }
}

export function* deleteUserSaga(action) {
  try {
    const { userID, handleRefreshData } = action.payload;
    const data = {
      id: userID,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/user/remove',
      data,
    });
    const message = {
      title: 'Elimiado',
      desc: `El usuario se eliminó correctamente`,
    };
    yield put(deleteUserSuccess({ message }));
    yield call(handleRefreshData);
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Error al tratar de eliminar el usuario, por favor intenta más tarde`,
    };
    yield put(deleteUserFail({ message }));
    console.log('error', e);
  }
}

export function* resendInvitationSaga(action) {
  try {
    const { userID, handleRefreshData } = action.payload;
    const data = {
      id: userID,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/user/resendInvitation',
      data,
    });
    yield call;
    const message = {
      title: '¡Enviado!',
      desc: `¡Correo enviado correctamente!`,
    };
    yield put(
      resendInvitationSuccess({
        message,
      }),
    );
    yield call(handleRefreshData);
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ha ocurrido un error al tratar de enviar el correo, por favor intente más tarde`,
    };
    yield put(
      resendInvitationFail({
        message,
      }),
    );
    console.log('error: ', e);
  }
}

export function* getUserDetailSaga(action) {
  try {
    const data = {
      where: { id: action.payload.userID },
      addresses: true,
    };
    const user = yield call(requestHandler, {
      method: 'POST',
      path: '/user/getOne',
      data,
    });
    yield put(getUserSuccess(user));
  } catch (e) {
    yield put(getUserFail('detailUser'));
    console.log('error in get detail user saga', e);
  }
}

export function* updateUserSaga(action) {
  try {
    const { handleRefreshData, ...rest } = action.payload;
    const data = {
      ...rest,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/user/update',
      data,
    });
    const message = {
      title: 'Usuario actualizado',
      desc: `El usuario ha sido acutalizado correctamente`,
    };
    yield put(updateUserSuccess({ message }));
    yield call(handleRefreshData);
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ha sucedido un error al tratar de actualizar el usuario, por favor intenta nuevamente`,
    };
    yield put(updateUserFail(message));
    console.log('error in update user saga', e);
  }
}

export function* createUserSaga(action) {
  try {
    const { handleRefreshData, ...rest } = action.payload;
    const agencyId = yield select(makeSelectAgencyId());
    let tempagencyID = agencyId ? agencyId : action.payload.agencyID;
    const superAdmin = action.payload.superadmin ? true : false;
    const data = {
      ...rest,
      agencyID: tempagencyID ? tempagencyID : undefined,
    };
    if (data.superadmin) {
      delete data.superadmin;
    }
    const createAdmin = yield call(requestHandler, {
      method: 'POST',
      path: '/user/createAdmin',
      data,
    });
    const message = {
      title: 'Usuario creado',
      desc: `El usuario ha sido creado correctamente`,
    };
    if (createAdmin?.managerRoleError) {
      const message = {
        title: 'Error',
        desc: `Ya se ha creado previamente un usuario con el rol de manejador de agencia.`,
      };
      return yield put(createUserFail({ message }));
    }
    yield put(createUserSuccess({ message }));
    if (superAdmin) {
      let url = createAdmin.split(': ');
      url = url[1];
      let id = url.split('=');
      id = id[2];
      yield put(createUserSuperAdminSuccess({ url, id }));
    }
    yield call(handleRefreshData);
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ha sucedido un error al tratar de crear un usuario, por favor intente más tarde`,
    };
    yield put(createUserFail({ message }));
    console.log('error: ', e);
  }
}
