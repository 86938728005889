import { Button, Typography } from '@material-ui/core';

import React from 'react';

export default function ButtonImportFile({
  accept = '.xlsx, .xls, .csv',
  buttonName,
  file,
  setFile,
  className,
}) {
  const handleFile = (selectedFiles, e) => {
    e.preventDefault();
    if (!selectedFiles || selectedFiles.length === 0) return;

    if (selectedFiles[0] !== null) {
      if (selectedFiles[0].size !== 0) {
        setFile(selectedFiles[0]);
        e.target.value = null;
        return;
      }
    }
    return;
  };

  return (
    <div className={className}>
      <input
        accept={accept}
        hidden
        id="raised-button-file"
        type="file"
        onChange={(e) => handleFile(e.target.files, e)}
      />
      <label htmlFor="raised-button-file">
        <Button
          variant="contained"
          className="btn btn-label-success btn-bold btn-sm btn-icon-h"
          component="span"
        >
          {buttonName}
        </Button>
      </label>
      <Typography component="h6">{file?.name}</Typography>
    </div>
  );
}
