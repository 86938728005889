import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { paymentSPEI } from '../supportSlice';
import { useHistory } from 'react-router-dom';
import api from '../../../config/api';
const API_URL = api();

function downloadFile({ paymentSPEI, history }) {
  const handleRedirect = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    let history = useHistory();
    fetch(`${API_URL}/dowloadfile/download-pdf`, { method: 'POST' })
      .then((response) => response.blob())
      .then((blob) => URL.createObjectURL(blob))
      .then((href) => {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        a.href = href;
        a.download = 'MANUAL DE USUARIO KARLO.pdf';
        a.click();
        history.goBack();
      })
      .catch(function (error) {
        console.log(error);
      });

    return null;
  };

  return handleRedirect();
}

const mapDispatchToProps = {
  paymentSPEI,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  agencies: state.agencies,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(downloadFile),
);
