import { createSelector } from 'reselect';
import { initialState } from './initialState';

export const selectDeposits = (state) =>
  state.deposits || initialState;

export const makeSelectDepositsFromdbFromState = createSelector(
  selectDeposits,
  (depositsState) => depositsState.fromdb,
);
export const makeSelectDepositsFiservFromdbFromState = createSelector(
  selectDeposits,
  (depositsState) => depositsState.fromdbFiserv,
);
export const makeSelectCountAllDepositsFromState = createSelector(
  selectDeposits,
  (depositsState) => depositsState.countDeposits,
);

export const makeSelectDepositsIsLoading = createSelector(
  selectDeposits,
  (depositsState) => depositsState.isLoading,
);

export const makeSelectDepositDetailsFromState = createSelector(
  selectDeposits,
  (depositsState) => depositsState.depositDetails,
);

export const makeSelectDepositsSearchedFromState = createSelector(
  selectDeposits,
  (depositsState) => depositsState.paymentsSearchedFromdb,
);

export const makeSelectDepositsPages = createSelector(
  selectDeposits,
  (depositsState) => depositsState.tablePagesFromdb,
);
