import React, { Fragment } from 'react';

import {
  Button,
  TextField,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: theme.spacing(7, 10, 1, 10),
  },
  funtions: {
    margin: '40px 0',
    width: '100%',
  },
  textField: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  submitButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
}));

const FillDataRemoveCommision = (props) => {
  const { handleRemoveCommission, paymentData } = props;
  const classes = useStyles();
  return (
    <Fragment>
      <Grid item xs={12} md={12} className={classes.funtions}>
        <Typography variant="h4" gutterBottom>
          Quitar comisión
        </Typography>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          fullWidth
          value={paymentData?.id}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="ID."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          fullWidth
          value={
            paymentData?.serviceOrder
              ? paymentData?.serviceOrder?.agency?.name
              : paymentData?.paymentOrder?.agency?.name
          }
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="Agencia."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          fullWidth
          value={paymentData?.nombreOrdenante}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="Concepto del pago."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          fullWidth
          value={paymentData?.bank}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="Banco."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          fullWidth
          value={paymentData?.total}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="Monto total pagado."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          fullWidth
          value={paymentData?.comission}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="Comisión."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={2} className={classes.funtions}>
        <div className={classes.submitButtonWrapper}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleRemoveCommission(paymentData?.id)}
          >
            Quitar comisión
          </Button>
        </div>
      </Grid>
    </Fragment>
  );
};

export default FillDataRemoveCommision;
