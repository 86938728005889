import { call, put } from 'redux-saga/effects';
import {
  getAllSpeiPaymentsFail,
  getAllSpeiPaymentsSuccess,
  searchInSpeiPaymentsFail,
  searchInSpeiPaymentsSuccess,
} from 'app/features/speiPayments/speiPaymentsSlice';
import {
  getPaymentDetailFail,
  getPaymentDetailSuccess,
  validatePaymentFail,
  validatePaymentSuccess,
} from 'app/features/payments/paymentsSlice';

import { concat } from 'lodash';
import { getLogs } from 'app/features/logs/logsSlice';
import { requestHandler } from '../../services/requestHandler';

export function* getAllSpeiPaymentsSaga(action) {
  try {
    const { agencyID } = action.payload;
    const dataServiceOrders = {
      where: {
        type: 'SPEI',
        paymentOrderID: null,
      },
      SPEIPayments: {
        where: {
          status: 'validado',
        },
      },
      serviceOrders: {
        invoiceProfiles: true,
        where: {
          agencyID,
        },
        agencies: {
          options: {
            attributes: ['name'],
          },
        },
      },
      options: {
        order: [['updatedAt', 'DESC']],
        attributes: [
          'id',
          'amount',
          'total',
          'serviceOrderID',
          'paymentOrderID',
          'createdAt',
        ],
      },
    };

    let dataPaymentOrders = {
      ...dataServiceOrders,
      where: {
        ...dataServiceOrders.where,
        serviceOrderID: null,
        paymentOrderID: undefined,
      },

      paymentOrders: {
        where: {
          agencyID,
        },
        agencies: { ...dataServiceOrders.serviceOrders.agencies },
      },
      serviceOrders: undefined,
    };

    const paymentsServiceOrders = yield call(requestHandler, {
      method: 'POST',
      path: '/payment/getAll',
      data: dataServiceOrders,
    });
    const paymentsPaymentOrders = yield call(requestHandler, {
      method: 'POST',
      path: '/payment/getAll',
      data: dataPaymentOrders,
    });
    let payments = concat(
      paymentsServiceOrders,
      paymentsPaymentOrders,
    );
    payments = payments.sort((a, b) => a.createdAt - b.createdAt);
    yield put(getAllSpeiPaymentsSuccess({ payments }));
  } catch (e) {
    yield put(getAllSpeiPaymentsFail('getPaymentSaga'));
    console.log('error in get payment saga', e);
  }
}

export function* searchInSpeiPaymentsSaga(action) {
  try {
    const {
      agencyID,
      status,
      clientName,
      total,
      page,
      pageSize,
    } = action.payload;
    let dataServiceOrders = {
      where: {
        type: 'SPEI',
        amount: total || undefined,
        status: 'Sin Aplicar',
      },
      serviceOrders: {
        invoiceProfiles: true,
        agencies: true,
      },
      pagination: {
        order: [['createdAt', 'DESC']],
      },
    };
    if (status)
      dataServiceOrders.where = {
        ...dataServiceOrders.where,
        status,
      };
    if (agencyID) {
      dataServiceOrders.serviceOrders.where = {
        agencyID,
      };
    }

    let dataPaymentOrders = {
      ...dataServiceOrders,
      where: {
        ...dataServiceOrders.where,
        serviceOrderID: null,
        paymentOrderID: undefined,
      },

      paymentOrders: {
        agencies: true,
      },
      serviceOrders: undefined,
    };
    if (agencyID) {
      dataPaymentOrders = {
        ...dataPaymentOrders,
        paymentOrders: {
          where: {
            agencyID,
          },
        },
      };
    }
    if (clientName) {
      dataServiceOrders.where = {
        ...dataServiceOrders.where,
        cardHolder: {
          $iLike: `%${clientName}%`,
        },
      };
      dataPaymentOrders.where = {
        ...dataPaymentOrders.where,
        cardHolder: {
          $iLike: `%${clientName}%`,
        },
      };
    }

    const paymentsServiceOrders = yield call(requestHandler, {
      method: 'POST',
      path: '/payment/getAll',
      data: dataServiceOrders,
    });
    const paymentsPaymentOrders = yield call(requestHandler, {
      method: 'POST',
      path: '/payment/getAll',
      data: dataPaymentOrders,
    });

    let rows = [...paymentsPaymentOrders, ...paymentsServiceOrders];
    rows = rows.sort(
      (a, b) =>
        new Date(a.createdAt).getTime() >
        new Date(b.createdAt).getTime(),
    );

    const payments = {
      count:
        paymentsServiceOrders.length + paymentsPaymentOrders.length,
      rows,
    };
    yield put(searchInSpeiPaymentsSuccess({ payments }));
  } catch (e) {
    yield put(searchInSpeiPaymentsFail('getPaymentSaga'));
    console.log('error in get payment saga', e);
  }
}

export function* getPaymentDetailSaga(action) {
  try {
    const { paymentID } = action.payload;
    const data = {
      where: { id: paymentID },
      serviceOrders: {
        invoiceProfiles: true,
        paymentOrders: {
          options: {
            attributes: ['total'],
          },
        },
      },
      paymentOrders: true,
      creditOrders: true,
      invoiceDetails: true,
    };
    const payment = yield call(requestHandler, {
      method: 'POST',
      path: '/payment/getOne',
      data,
    });
    yield put(
      getLogs({
        object: 'payments',
        objectID: paymentID,
      }),
    );
    yield put(getPaymentDetailSuccess({ payment }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba obtener información pago.
      Intente de nuevo, si el error persiste intente más tarde.`,
    };
    yield put(getPaymentDetailFail({ message }));
    console.log('error in get detail payment saga', e);
  }
}

export function* validateSpeiPaymentSaga(action) {
  try {
    const data = {
      ...action.payload,
    };
    const payment = yield call(requestHandler, {
      method: 'POST',
      path: `/SPEIPayments/update`,
      data,
    });
    const message = {
      title: '¡Pago SPEI validado correctamente!',
      desc: `El pago ha sido validado correctamente`,
    };
    yield put(validatePaymentSuccess({ message, payment }));
    yield call(searchInSpeiPaymentsSaga, {
      payload: {
        status: 'validado',
      },
    });
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba validar el pago.
      Intente de nuevo, si el error persiste intente más tarde.`,
    };
    yield put(validatePaymentFail({ message }));
    console.log('error sending receipt payment order: ', e);
  }
}
