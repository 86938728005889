import React from 'react';
import { TextField } from '@material-ui/core';

import { paymentAggregators } from './paymentAggregators';

const Refund = (props) => {
  const { type, setPaymentAggregator } = props;

  return (
    <TextField
      fullWidth
      select
      variant="outlined"
      SelectProps={{
        native: true,
      }}
      label="Agregador"
      onChange={(event) => setPaymentAggregator(event.target.value)}
    >
      <>
        {
          paymentAggregators.map((option) => (
            <option key={option.label} value={option.value}>
              {option.label}
            </option>
          ))
        }
      </>
    </TextField>
  );
};

export default Refund;
