import { createSelector } from 'reselect';
import { initialState } from './initialState';

export const selectKarloPromotions = (state) =>
  state.karloPromotions || initialState;

export const makeSelectKarloPromotions = createSelector(
  selectKarloPromotions,
  (karloPromotionsState) => karloPromotionsState,
);

export const makeSelectKarloPromotionsIsLoading = createSelector(
  selectKarloPromotions,
  (karloPromotionsState) => karloPromotionsState.isLoading,
);

export const makeSelectPromoDetails = createSelector(
  selectKarloPromotions,
  (karloPromotionsState) => karloPromotionsState.promotionDetail,
);
