import 'react-table/react-table.css';
/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef, Fragment } from 'react';
import ReactTable from 'react-table';
import Add from '@material-ui/icons/Add';
import { Badge } from 'react-bootstrap';
import { getBadgeColor } from '../../../../utils';
import { Publish, Close } from '@material-ui/icons';
import {
  Button,
  Paper,
  makeStyles,
  IconButton,
  TextField,
  Grid,
  Tooltip,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { debounce } from 'lodash';
import {
  getChargebacks,
  closeModals,
  importChargebacksFromExcel,
  createChargebacks,
} from '../../chargeBacksSlices';
import Modal from '../../../../components/Modal';
import moment from 'moment';
import {
  selectChargebacks,
  makeSelectChargebacks,
} from '../../selectors';
import ButtonImportFile from 'app/components/ButtonImportFile';
import {
  filterCaseInsensitive,
  formatDate,
  formatNumber,
} from 'app/utils';
import {
  makeSelectUserRoleFromState,
  selectAuth,
} from '../../../auth/selectors';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useForm } from 'react-hook-form';
import InputAdornment from '@material-ui/core/InputAdornment';
import MUIDatePickerFilter from 'app/components/MUIDatePickerFilter';
import ShouldItRender from '../../../../components/ShouldItRender';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  paper: {
    widht: '100%',
    marginBottom: theme.spacing(0),
  },
  button: {
    margin: theme.spacing(1),
  },
  textField: {
    margin: theme.spacing(1),
    width: '95%',
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  datePicker: {
    width: 300,
  },
  datePickerRight: {
    marginRight: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  createCBManual: {
    width: 450,
  },
}));

const Chargebacks = (props) => {
  const {
    getChargebacks,
    chargebacks,
    fromdb,
    closeModals,
    setActionButton,
    importChargebacksFromExcel,
    role,
    auth,
    createChargebacks,
  } = props;
  const classes = useStyles();
  const reactTable = useRef(null);
  const [
    showImportDespositsModal,
    setShowImportDespositsModal,
  ] = useState(false);
  const [excelFile, setExcelFile] = useState(null);
  const [dataFromdb, setDataFromdb] = useState(null);
  const [dataManual, setDataManual] = useState({
    dmsId: '',
    transactionId: '',
    clientID: '',
    dateDeposit: moment().format(),
    dateChargeback: moment().format(),
    datePayment: moment().format(),
    amountTx: '',
    status: '',
  });
  const [dataManualEdit, setDataManualEdit] = useState({
    dmsId: '',
    id: '',
    transactionId: '',
    clientID: '',
    status: '',
  });
  const [
    showCreateCBManualModal,
    setShowCreateCBManualModal,
  ] = useState(false);
  const [
    showEditCBManualModal,
    setShowEditCBManualModal,
  ] = useState(false);
  const [showErrorStatus, setShowErrorStatus] = useState(false);

  useEffect(() => {
    if (role === 'Super Admin') {
      setActionButton(
        <>
          <Button
            variant="contained"
            className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
            onClick={toogleCreateCBManualModal}
          >
            Crear contracargo
            <Add className="kt-margin-l-10" />
          </Button>
        </>,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  useEffect(() => {
    if (role !== 'Super Admin') {
      const data = fromdb?.filter(
        (el) => el.agencyID === auth?.user?.agencyID,
      );
      setDataFromdb(data);
    } else {
      setDataFromdb(fromdb);
    }
  }, [fromdb]);

  const toggleImportExcel = () => {
    setShowImportDespositsModal(!showImportDespositsModal);
  };

  const toogleCreateCBManualModal = () => {
    setDataManual({
      dmsId: '',
      transactionId: '',
      clientID: '',
      dateDeposit: moment().format(),
      dateChargeback: moment().format(),
      datePayment: moment().format(),
      amountTx: '',
      status: '',
    });
    setShowCreateCBManualModal(!showCreateCBManualModal);
  };
  const toogleEditModal = () => {
    setDataManual({
      dmsId: '',
      transactionId: '',
      clientID: '',
      id: '',
      amountTx: '',
      status: '',
    });
    setShowEditCBManualModal(!showEditCBManualModal);
  };

  const handleImportDepositsFromExcel = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file01', excelFile);
    importChargebacksFromExcel({
      data: formData,
      handleRefreshData,
    });
    setExcelFile(null);
    toggleImportExcel();
  };

  const handleRefreshData = () =>
    reactTable.current && reactTable.current.fireFetchData();

  const handleChange = (event) => {
    if (event.target.name === 'amountTx') {
      const validation = /^[0-9.]+$/;
      const newAmount = event.target.value;
      if (validation.test(newAmount) || newAmount === '') {
        setDataManual({
          ...dataManual,
          [event.target.name]: newAmount,
        });
      }
    } else {
      setDataManual({
        ...dataManual,
        [event.target.name]: event.target.value,
      });
    }
  };
  const handleChangeEdit = (event) => {
    if (event.target.name === 'amountTx') {
      const validation = /^[0-9.]+$/;
      const newAmount = event.target.value;
      if (validation.test(newAmount) || newAmount === '') {
        setDataManualEdit({
          ...dataManualEdit,
          [event.target.name]: newAmount,
        });
      }
    } else {
      setDataManualEdit({
        ...dataManualEdit,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleCreateCBManual = () => {
    if (
      dataManual.status !== '' &&
      dataManual.dmsId !== '' &&
      dataManual.transactionId !== '' &&
      dataManual.amountTx !== ''
    ) {
      createChargebacks(dataManual);
      toogleCreateCBManualModal();
    } else {
      setShowErrorStatus(true);
    }
  };
  const handleEditCBManual = () => {
      createChargebacks(dataManualEdit);
      toogleEditModal();
  };

  const handleChangePaymentDate = (event) => {
    setDataManual({
      ...dataManual,
      datePayment: event,
    });
  };

  const handleChangeChargebackDate = (event) => {
    setDataManual({
      ...dataManual,
      dateChargeback: event,
    });
  };

  const handleChangeDepositDate = (event) => {
    setDataManual({
      ...dataManual,
      dateDeposit: event,
    });
  };

  const handleClearPaymentDate = () => {
    setDataManual(...dataManual, (dataManual.datePayment = null));
  };

  const handleClearChargebackDate = () => {
    setDataManual(...dataManual, (dataManual.dateChargeback = null));
  };

  const handleClearDepositDate = () => {
    setDataManual(...dataManual, (dataManual.dateDeposit = null));
  };

  const renderCreateDeposit = () => {
    return (
      <Modal
        open={showImportDespositsModal}
        closeModal={toggleImportExcel}
        dialogTitle="Crear depósito"
        actionButtonText="Validar"
        form={'import-deposits-from-excel-form'}
        dialogChildren={
          <form
            id="import-deposits-from-excel-form"
            autoComplete="off"
            noValidate
            onSubmit={handleImportDepositsFromExcel}
          >
            <ButtonImportFile
              className={classes.buttonImportExcel}
              buttonName="Seleccionar archivo excel"
              file={excelFile}
              setFile={setExcelFile}
            />
          </form>
        }
      />
    );
  };

  const renderCreateCBManual = () => {
    return (
      <Modal
        fullWidth
        open={showCreateCBManualModal}
        closeModal={toogleCreateCBManualModal}
        dialogTitle="Crear Nuevo Contracargo"
        actionButtonText="Crear contracargo"
        onClick={handleCreateCBManual}
        dialogChildren={
          <Fragment>
            <Grid justify="center">
              <TextField
                required
                name="dmsId"
                type="text"
                label="dmsID"
                value={dataManual.dmsId}
                variant="outlined"
                onChange={handleChange}
                className={classes.textField}
              />
              <TextField
                required
                name="transactionId"
                type="text"
                label="transactionID"
                variant="outlined"
                value={dataManual.transactionId}
                onChange={handleChange}
                className={classes.textField}
              />
              <TextField
                required
                name="amountTx"
                label="Monto total"
                value={dataManual.amountTx}
                variant="outlined"
                onChange={handleChange}
                className={classes.textField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      $
                    </InputAdornment>
                  ),
                }}
              />
              <Select
                label="Estatus"
                value={dataManual.status}
                onChange={handleChange}
                name="status"
                className={classes.textField}
              >
                <MenuItem value="Pendiente de definición">
                  <Badge
                    className={classes.badges}
                    variant={getBadgeColor('enProceso')}
                  >
                    Pendiente de definición
                  </Badge>
                </MenuItem>
                <MenuItem value="A favor del negocio">
                  <Badge
                    className={classes.badges}
                    variant={getBadgeColor('aFavor')}
                  >
                    A favor del negocio
                  </Badge>
                </MenuItem>
                <MenuItem value="A favor del tarjetahabiente ">
                  <Badge
                    className={classes.badges}
                    variant={getBadgeColor('enContra')}
                  >
                    A favor del tarjetahabiente 
                  </Badge>
                </MenuItem>
              </Select>
              <FormHelperText
                className={classes.textField}
                name="info"
              >
                Estatus del contracargo
              </FormHelperText>
            </Grid>
            <Grid container justifyContent="space-around">
              <MuiPickersUtilsProvider
                utils={MomentUtils}
                locale={'es'}
              >
                <DatePicker
                  autoOk
                  disableToolbar
                  className={classes.textField}
                  variant="inline"
                  label="Fecha de pago"
                  format="DD/MM"
                  value={dataManual.datePayment}
                  onChange={handleChangePaymentDate}
                  InputProps={{
                    endAdornment: dataManual.datePayment ? (
                      <IconButton
                        aria-label="Select locale"
                        size="small"
                        onClick={handleClearPaymentDate}
                      >
                        <Close />
                      </IconButton>
                    ) : null,
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid justify="center">
              <MuiPickersUtilsProvider
                utils={MomentUtils}
                locale={'es'}
              >
                <DatePicker
                  autoOk
                  disableToolbar
                  className={classes.textField}
                  variant="inline"
                  label="Fecha de contracargo"
                  format="DD/MM"
                  value={dataManual.dateChargeback}
                  onChange={handleChangeChargebackDate}
                  InputProps={{
                    endAdornment: dataManual.dateChargeback ? (
                      <IconButton
                        aria-label="Select locale"
                        size="small"
                        onClick={handleClearChargebackDate}
                      >
                        <Close />
                      </IconButton>
                    ) : null,
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid justify="center">
              <MuiPickersUtilsProvider
                utils={MomentUtils}
                locale={'es'}
              >
                <DatePicker
                  autoOk
                  disableToolbar
                  className={classes.textField}
                  variant="inline"
                  label="Fecha de afectación"
                  format="DD/MM"
                  value={dataManual.dateDeposit}
                  onChange={handleChangeDepositDate}
                  InputProps={{
                    endAdornment: dataManual.dateDeposit ? (
                      <IconButton
                        aria-label="Select locale"
                        size="small"
                        onClick={handleClearDepositDate}
                      >
                        <Close />
                      </IconButton>
                    ) : null,
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Fragment>
        }
      />
    );
  };

  const renderEditCBManual = () => {
    return (
      <Modal
        fullWidth
        open={showEditCBManualModal}
        closeModal={toogleEditModal}
        dialogTitle="Editar Contracargo"
        actionButtonText="Editar contracargo"
        onClick={handleEditCBManual}
        dialogChildren={
          <Fragment>
            <Grid justify="center">
              <TextField
                required
                disabled
                name="dmsId"
                type="text"
                label="dmsID"
                value={dataManualEdit.dmsId}
                variant="outlined"
                onChange={handleChangeEdit}
                className={classes.textField}
              />
              <TextField
                required
                disabled
                name="transactionId"
                type="text"
                label="transactionID"
                variant="outlined"
                value={dataManualEdit.transactionId}
                onChange={handleChangeEdit}
                className={classes.textField}
              />
              <TextField
                required
                disabled
                name="amountTx"
                label="Monto total"
                value={dataManualEdit.amountTx}
                variant="outlined"
                onChange={handleChangeEdit}
                className={classes.textField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      $
                    </InputAdornment>
                  ),
                }}
              />
              <Select
                label="Estatus"
                value={dataManualEdit.status}
                onChange={handleChangeEdit}
                name="status"
                className={classes.textField}
              >
                <MenuItem value="Pendiente de definición">
                  <Badge
                    className={classes.badges}
                    variant={getBadgeColor('enProceso')}
                  >
                    Pendiente de definición
                  </Badge>
                </MenuItem>
                <MenuItem value="A favor del negocio">
                  <Badge
                    className={classes.badges}
                    variant={getBadgeColor('aFavor')}
                  >
                    A favor del negocio
                  </Badge>
                </MenuItem>
                <MenuItem value="A favor del tarjetahabiente ">
                  <Badge
                    className={classes.badges}
                    variant={getBadgeColor('enContra')}
                  >
                    A favor del tarjetahabiente 
                  </Badge>
                </MenuItem>
              </Select>
              <FormHelperText
                className={classes.textField}
                name="info"
              >
                Estatus del contracargo
              </FormHelperText>
            </Grid>
          </Fragment>
        }
      />
    );
  };

  const statusError = () => {
    return (
      <Modal
        open={showErrorStatus}
        type="warning"
        closeModal={closeModals}
        dialogTitle="Error"
        dialogText="Asegurese que lleno todos los campos."
        actionButtonText="Aceptar"
        onClick={() => {
          setShowErrorStatus(false);
        }}
      />
    );
  };

  const columns = [
    // {
    //   Header: () => <strong>Factura</strong>,
    //   // accessor: 'orderReservation',
    //   Cell: (row) => {
    //     return (
    //       <Link to={`/reservation-order/${row.original.id}`}>
    //         <div
    //           style={{
    //             textAlign: 'center',
    //           }}
    //         >
    //           {row.value}
    //         </div>
    //       </Link>
    //     );
    //   },
    // },
    {
      Header: () => <strong>Dms ID</strong>,
      accessor: 'dmsId',
      Cell: (row) => {
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        );
      },
    },
    {
      Header: () => <strong>ID Transacción</strong>,
      accessor: 'transactionId',
      Cell: (row) => {
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        );
      },
    },
    {
      Header: () => <strong>Fecha de pago</strong>,
      accessor: 'datePayment',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value
            ? moment(row.value).utc().format('DD/MM/YYYY')
            : '-'}
        </div>
      ),
      Filter: (cell) => {
        return <MUIDatePickerFilter cell={cell} />;
      },
    },
    {
      Header: () => <strong>Fecha de contracargo</strong>,
      accessor: 'dateChargeback',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value
            ? moment(row.value).utc().format('DD/MM/YYYY')
            : '-'}
        </div>
      ),
      Filter: (cell) => {
        return <MUIDatePickerFilter cell={cell} />;
      },
    },
    {
      Header: () => <strong>Fecha de depósito</strong>,
      accessor: 'dateDeposit',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value
            ? moment(row.value).utc().format('DD/MM/YYYY')
            : '-'}
        </div>
      ),
      Filter: (cell) => {
        return <MUIDatePickerFilter cell={cell} />;
      },
    },
    {
      Header: () => <strong>Monto de TX</strong>,
      accessor: 'amountTx',
      Cell: (row) => {
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {formatNumber(row.value)}
          </div>
        );
      },
    },
    {
      Header: () => <strong>Última actualización</strong>,
      accessor: 'updatedAt',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value ? formatDate(row.value) : '-'}
        </div>
      ),
      Filter: (cell) => {
        return <MUIDatePickerFilter cell={cell} />;
      },
    },
    {
      Header: () => <strong>Estado</strong>,
      accessor: 'status',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value ? row.value : '-'}
        </div>
      ),
      Filter: (cell) => {
        return (
          // eslint-disable-next-line jsx-a11y/no-onchange
          <select
            onChange={(e) => cell.onChange(e.target.value)}
            value={
              cell.filter && cell.filter.value
                ? cell.filter.value
                : ''
            }
          >
            <option value="">Todos</option>
            <option value="Pendiente de definición">Pendiente de definición</option>
            <option value="A favor del negocio">A favor del negocio</option>
            <option value="A favor del tarjetahabiente">A favor del tarjetahabiente</option>
          </select>
        );
      },
    },
    {
      Header: '',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <ShouldItRender should={role === 'Super Admin'}>
            <Tooltip title="Editar usuario">
              <IconButton
                className={classes.button}
                size="small"
                onClick={() => {
                  setShowEditCBManualModal(true);
                  setDataManualEdit({
                    amountTx: row.original.amountTx,
                    id: row.original.id,
                    dmsId: row.original.dmsId,
                    transactionId: row.original.transactionId,
                    status: row.original.status,
                  });
                }}
              >
                <i className="far fa-edit"></i>
              </IconButton>
            </Tooltip>
          </ShouldItRender>
        </div>
      ),
      filterable: false,
      show: role === 'Super Admin',
    },
  ];

  const handleFetchData = debounce(
    (state, instance) => {
      const { page, pageSize, sorted, filtered } = state;
      getChargebacks({
        page,
        pageSize,
        sorted,
        filtered,
      });
    },
    1000,
    { leading: false, trailing: true },
  );

  const renderModal = () => {
    const { modal } = chargebacks;
    if (modal.show === true) {
      const modalType = modal.type;
      return (
        <Modal
          open={modal.show}
          type={modalType}
          closeModal={closeModals}
          dialogTitle={modal.title}
          dialogText={modal.message}
          actionButtonText="Aceptar"
          onClick={closeModals}
        />
      );
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {renderModal()}
        {renderCreateDeposit()}
        {renderCreateCBManual()}
        {renderEditCBManual()}
        {statusError()}
        <ReactTable
          NoDataComponent={() => (
            <div className="rt-noData">Sin resultados</div>
          )}
          onFetchData={handleFetchData}
          data={dataFromdb || []}
          columns={columns}
          filterable
          manual
          defaultFilterMethod={filterCaseInsensitive}
          className="-striped -highlight"
          previousText="Anterior"
          nextText="Siguiente"
          pages={chargebacks.pages}
          loading={chargebacks.isLoading}
          loadingText="Cargando datos..."
        />
      </Paper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  chargebacks: selectChargebacks(state),
  fromdb: makeSelectChargebacks(state),
  tablePagesFromdb: makeSelectChargebacks(state),
  role: makeSelectUserRoleFromState(state),
  auth: selectAuth(state),
});

const mapDispatchToProps = {
  getChargebacks,
  closeModals,
  importChargebacksFromExcel,
  createChargebacks,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(Chargebacks),
);
