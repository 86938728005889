import 'react-table/react-table.css';
import 'moment/locale/es';

/* eslint-disable react/display-name */
import {
  Button,
  Badge as MaterialBadge,
  Paper,
  IconButton,
  TextField,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import {
  getKarloAdvices,
  deleteTip,
  uploadThumbnail,
  updateTip,
} from 'app/features/karloAdvices/karloAdvicesSlice';
import Modal from '../../../../components/Modal';
import {
  formatDate,
  formatExpiricyDate,
  formatNumber,
  getBadgeColor,
  getOffersStatusName,
} from '../../../../utils';
import { Link } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { injectIntl } from 'react-intl';
import { makeSelectKarloAdvices } from 'app/features/karloAdvices/selectors';
import { selectAuth } from 'app/features/auth/selectors';
import { useForm } from 'react-hook-form';
import Dropzone from 'react-dropzone-uploader';
import Add from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(0),
  },
  notifications: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  lastUpdated: {
    marginLeft: theme.spacing(2),
    color: '#434349',
  },
  datePickerTextfield: {
    padding: '5px 5px 5px !important',
    height: '20px',
  },
  textField: {
    margin: theme.spacing(1),
    width: '95%',
  },
}));

function KarloAdvicesTable(props) {
  const classes = useStyles();
  const {
    role,
    getKarloAdvices,
    karloAdvices,
    deleteTip,
    users,
    setActionButton,
    uploadThumbnail,
    updateTip,
  } = props;
  const [karloAdvicesData, setKarloAdvicesData] = React.useState([]);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openThumbnail, setOpenThumbnail] = React.useState(false);
  const [tipId, setTipId] = React.useState(0);
  const [thumbnailImage, setThumbnailImage] = useState('');
  const reactTable = React.useRef(null);
  const {
    handleSubmit,
    register,
    errors,
    control,
    reset,
  } = useForm();

  React.useEffect(() => {
    setActionButton(
      <div>
        <Link to={'/add-advice'}>
          <Button
            variant="contained"
            className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
            onClick={() => console.log()}
          >
            Crear consejo
            <Add className="kt-margin-l-10" />
          </Button>
        </Link>
      </div>,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFetchData = debounce(
    (state, instance) => {
      const { page, pageSize, sorted, filtered } = state;
      getKarloAdvices({ page, pageSize, sorted, filtered });
    },
    1000,
    { leading: false, trailing: true },
  );

  React.useEffect(() => {
    console.log('karloAdvicesFROMDB ', karloAdvices);
    if (Array.isArray(karloAdvices.fromdb)) {
      const cleanData = karloAdvices.fromdb.map((tip) => {
        return {
          id: tip.id,
          title: tip.title,
          subtitle: tip.subtitle ? tip.subtitle : '-',
          description: tip.description,
          videoUrl: tip.videoUrl,
          createdAt: tip.createdAt,
          createdByUserId: tip.createdByUserId,
        };
      });
      setKarloAdvicesData(cleanData);
    }
  }, [karloAdvices]);

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setOpenThumbnail(false);
  };

  const handleDelete = () => {
    deleteTip({ tipId });
    handleCloseDelete();
  };

  const handleThumbnailChangeStatus = ({ meta, file }, status) => {
    console.log('meta ', meta);
    console.log('file ', file);
    console.log('status ', status);
    if (status == 'done') {
      setThumbnailImage(file);
      const formData = new FormData();
      formData.append(`file0`, file);
      formData.append(`tipId`, tipId);
      return uploadThumbnail(formData);
    }
  };

  const handleEditItem = (formData) => {
    // console.log('formData ', formData);
    console.log('users ', users?.user?.id);
    const userId = users?.user?.id;
    updateTip({
      ...formData,
      id: tipId,
      createdByUserId: userId,
    });
    handleCloseDelete();
  };

  const renderEditItem = () => {
    return (
      <Modal
        open={openThumbnail}
        closeModal={handleCloseDelete}
        dialogTitle={'Editar consejo'}
        actionButtonText={'Editar consejo'}
        form={'create-user-form'}
        dialogChildren={
          <form
            id="create-user-form"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(handleEditItem)}
          >
            <TextField
              required
              name="title"
              label="Titulo"
              type="title"
              variant="outlined"
              // onChange={handleCharactersMail}
              inputRef={register({
                required: true,
              })}
              className={classes.textField}
              error={Boolean(errors.title)}
            />
            <TextField
              required
              name="subtitle"
              label="Subtitulo"
              type="subtitle"
              variant="outlined"
              // onChange={handleCharactersMail}
              inputRef={register({
                required: true,
              })}
              className={classes.textField}
              error={Boolean(errors.subtitle)}
            />
            <TextField
              required
              name="description"
              label="Descripción"
              type="description"
              variant="outlined"
              inputRef={register({
                required: true,
              })}
              className={classes.textField}
              error={Boolean(errors.description)}
            />
            <TextField
              // required
              name="videoUrl"
              label="Video"
              type="videoUrl"
              variant="outlined"
              // onChange={handleCharactersMail}
              inputRef={register({
                required: false,
              })}
              className={classes.textField}
              error={Boolean(errors.videoUrl)}
            />
          </form>
        }
      />
    );
  };

  const renderConfirmationDelete = () => {
    return (
      <Modal
        open={openDelete}
        type="warning"
        closeModal={handleCloseDelete}
        dialogTitle="¿Seguro que deseas eliminar el consejo?"
        dialogText="Si continúas no habrá manera de recuperar la información
            que haya sido borrada."
        actionButtonText="Eliminar consejo"
        onClick={handleDelete}
      />
    );
  };

  const renderUploadThumbnail = () => {
    return (
      <Modal
        open={false}
        closeModal={handleCloseDelete}
        dialogTitle="Actualizar thumbnail"
        actionButtonText="Actualizar thumbnail"
        onClick={handleDelete}
        dialogChildren={
          <div className={classes.buttonGroup}>
            <p className="text-align-dialog">
              Cargar una imagen para el thumbnail
            </p>
            <Dropzone
              // getUploadParams={getUploadParams}
              onChangeStatus={handleThumbnailChangeStatus}
              maxFiles={1}
              submitButtonContent={null}
              styles={{
                dropzone: { minHeight: 200, maxHeight: 250 },
              }}
              // onSubmit={handleSubmitDropzone}
              accept="image/*"
            />
          </div>
        }
      />
    );
  };

  const columns = [
    {
      Header: () => <strong>createdByUserId</strong>,
      accessor: 'createdByUserId',
      Cell: (row) => (
        <Link to={`/edit-advice/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
      show: false,
    },
    {
      Header: () => <strong>ID</strong>,
      accessor: 'id',
      Cell: (row) => (
        <Link to={`/edit-advice/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Titulo</strong>,
      accessor: 'title',
      Cell: (row) => (
        <Link to={`/edit-advice/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Subtitulo</strong>,
      accessor: 'subtitle',
      Cell: (row) => (
        <Link to={`/edit-advice/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Fecha de Creación</strong>,
      accessor: 'createdAt',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {formatDate(row.value)}
        </div>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Descripción</strong>,
      accessor: 'description',
      Cell: (row) => (
        <Link to={`/edit-advice/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
      show: false,
    },
    {
      Header: () => <strong>Video</strong>,
      accessor: 'videoUrl',
      Cell: (row) => (
        <Link to={`/edit-advice/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
      show: false,
    },
    {
      Header: '',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <Tooltip title="Editar oferta">
            <IconButton
              className={classes.button}
              size="small"
              onClick={() => {
                setOpenThumbnail(true);
                reset({
                  title: row.original.title,
                  subtitle: row.original.subtitle,
                  description: row.original.description,
                  videoUrl: row.original.videoUrl,
                });
                setTipId(row.original.id);
              }}
            >
              <i className="far fa-edit"></i>
            </IconButton>
          </Tooltip>
          <Tooltip title="Borrar oferta">
            <IconButton
              className={classes.button}
              size="small"
              onClick={() => {
                handleOpenDelete();
                setTipId(row.original.id);
              }}
            >
              <i className="far fa-trash-alt"></i>
            </IconButton>
          </Tooltip>
        </div>
      ),
      filterable: false,
      show: false,
    },
  ];

  return (
    <div className={classes.root}>
      {renderConfirmationDelete()}
      {renderUploadThumbnail()}
      {renderEditItem()}
      <Paper className={classes.paper}>
        <ReactTable
          ref={reactTable}
          NoDataComponent={() => (
            <div className="rt-noData">Sin resultados</div>
          )}
          manual
          onFetchData={handleFetchData}
          data={karloAdvicesData}
          pages={karloAdvices.tablePages}
          columns={columns}
          // filterable
          className="-striped -highlight"
          previousText="Anterior"
          nextText="Siguiente"
          loading={karloAdvices.isLoading}
          loadingText="Cargando datos..."
        />
      </Paper>
    </div>
  );
}

const mapStateToProps = (state) => ({
  karloAdvices: makeSelectKarloAdvices(state),
  users: selectAuth(state),
});

const mapDispatchToProps = {
  getKarloAdvices,
  deleteTip,
  uploadThumbnail,
  updateTip,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(KarloAdvicesTable),
);
