import React, { useEffect, useState } from 'react';

import { TextField, Checkbox, makeStyles } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';

import { CheckBoxOutlineBlank, CheckBox } from '@material-ui/icons';

import { getAgencies } from 'app/features/agencies/agenciesSlice';
import { makeSelectAgenciesFromdb } from 'app/features/agencies/selectors';

import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  agenciesSelect: {
    marginTop: '8px',
    marginRight: '5px',
    backgroundColor: '#FFF',
  },
}));

const MUIAutocompleteMultipleAgencies = (props) => {
  const classes = useStyles();
  const {
    agenciesFromdb,
    getAgencies,
    agenciesList,
    agenciesNames,
    userInfo,
  } = props;

  const [agenciesOptions, setAgenciesOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [result, setResult] = useState([]);

  useEffect(() => {
    if (userInfo?.role !== 'Super Admin Agencies') {
      getAgencies();
    }
  }, []);

  useEffect(() => {
    let agencyListSource = agenciesFromdb;

    if (userInfo?.role === 'Super Admin Agencies') {
      agencyListSource = userInfo?.userName?.user?.agencies.map(
        (agency) => {
          return agency.agency;
        },
      );
    }

    if (Array.isArray(agencyListSource)) {
      const cleanAgencies = agencyListSource.map((agency) => {
        return {
          id: agency.id,
          label: agency.name,
        };
      });
      setAgenciesOptions(cleanAgencies);
    }
  }, [agenciesFromdb, userInfo?.userName?.agencies]);

  useEffect(() => {
    if (isSelectedAll && selectedOptions.length > 0) {
      setAgencies(result);
    }
  }, [isSelectedAll]);

  const setAgencies = (agencies) => {
    const agenciesId = agencies.map((agency) => {
      return agency.id;
    });

    agenciesList(agenciesId);

    const agenciesName = agencies.map((agency) => {
      return agency.label;
    });

    agenciesNames(agenciesName);
  };

  const allSelected =
    agenciesOptions.length === selectedOptions.length;

  const handleToggleOption = (selectedOptions) =>
    setSelectedOptions(selectedOptions);

  const getOptionLabel = (option) => `${option.label}`;

  const handleClearOptions = () => {
    setSelectedOptions([]);
    agenciesList([]);
    agenciesNames([]);
  };

  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      setSelectedOptions(agenciesOptions);
      setIsSelectedAll(true);
    } else {
      handleClearOptions();
      setIsSelectedAll(false);
    }
  };

  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!allSelected);
  };

  const handleChange = (event, selectedOptions, reason) => {
    if (reason === 'select-option' || reason === 'remove-option') {
      if (
        selectedOptions.find((option) => option.id === 'select-all')
      ) {
        handleToggleSelectAll();
        let resultTemp = [];
        resultTemp = agenciesOptions.filter(
          (el) => el.id !== 'select-all',
        );
        setResult(resultTemp);
      } else {
        handleToggleOption && handleToggleOption(selectedOptions);
        return setAgencies(selectedOptions);
      }
    } else if (reason === 'clear') {
      handleClearOptions && handleClearOptions();
    }
  };

  const getOptionSelected = (option, anotherOption) =>
    option.id === anotherOption.id;

  const filter = createFilterOptions();

  const optionRenderer = (option, { selected }) => {
    const selectAllProps =
      option.id === 'select-all' // To control the state of 'select-all' checkbox
        ? { checked: allSelected }
        : {};

    return (
      <>
        <Checkbox
          color="primary"
          icon={<CheckBoxOutlineBlank fontSize="small" />}
          checkedIcon={<CheckBox fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={selected}
          {...selectAllProps}
        />
        {getOptionLabel(option)}
      </>
    );
  };

  const inputRenderer = (params) => (
    <TextField {...params} label="Agencias" variant="outlined" />
  );

  return (
    <Autocomplete
      multiple
      size="small"
      limitTags={1}
      options={agenciesOptions}
      value={selectedOptions}
      disableCloseOnSelect
      getOptionLabel={(option) => option.label}
      getOptionSelected={getOptionSelected}
      className={classes.agenciesSelect}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        return [
          { label: 'Todas las agencias', id: 'select-all' },
          ...filtered,
        ];
      }}
      onChange={handleChange}
      renderOption={optionRenderer}
      renderInput={inputRenderer}
    />
  );
};

const mapStateToProps = (state) => ({
  agenciesFromdb: makeSelectAgenciesFromdb(state),
});

const mapDispatchToProps = {
  getAgencies: getAgencies,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MUIAutocompleteMultipleAgencies);
