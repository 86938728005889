import React, { Fragment, useState, useEffect } from 'react';
import {
  getBadgeColor,
} from '../../../utils';
import {
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import { Badge as BootstrapBadge } from 'react-bootstrap';
const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: theme.spacing(7, 10, 1, 10),
  },
  funtions: {
    margin: '40px 0',
    width: '100%',
  },
  textField: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  submitButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
  formControl: {
    width: '100%',
    margin: theme.spacing(1),
  },
}));
const CardDataSPEI = (props) => {
  const {
    data,
  } = props;
  const classes = useStyles();
  const [ODSOrODPUrl, setODSOrODPUrl] = useState('');
  const [orderId, setOrderId] = useState('');
  const [status, setStatus] = useState('');
  useEffect(() => {
    setOrderId(data?.serviceOrder?.orderID || data?.paymentOrder?.orderID);
    setStatus(data?.serviceOrder?.status || data?.paymentOrder?.status);
    let updatedUrl = '';
    if (data?.serviceOrder?.orderID) {
      updatedUrl = `/detail-order/${data?.serviceOrder?.orderID}/${data?.serviceOrder?.agencyID}`;
    } else if (data?.paymentOrder?.orderID) {
      updatedUrl = `/detail-payment-order/${data?.paymentOrder?.orderID}/${data?.paymentOrder?.agencyID}`;
    }
    setODSOrODPUrl(updatedUrl);
  }, [data]);

  return (
    <>
    {
      orderId &&
      <>
      <Grid item xs={12} md={12} className={classes.funtions}>
        <Typography variant="h4" gutterBottom>
          Información del pago
        </Typography>
      </Grid>
      <Grid item xs={12} md={3} className={classes.funtions}>
        <Typography variant="h6" gutterBottom>
          Id de orden
        </Typography>
        <Link to={ODSOrODPUrl} target="_blank" rel="noopener noreferrer" >
          {orderId}
        </Link>
      </Grid>
      <Grid item xs={12} md={3} className={classes.funtions}>
        <Typography variant="h6" gutterBottom>
          Nombre cliente
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {data?.cardHolder}
        </Typography>
      </Grid>
      <Grid item xs={12} md={3} className={classes.funtions}>
        <Typography variant="h6" gutterBottom>
          Referencia bancaria
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {data?.stpOrder?.reference || "Sin referencia"}
        </Typography>
      </Grid>
      <Grid item xs={12} md={3} className={classes.funtions}>
        <Typography variant="h6" gutterBottom>
          Estatus
        </Typography>
        <BootstrapBadge
          className={classes.badges}
          variant={getBadgeColor(data?.serviceOrder?.status || data?.paymentOrder?.status)}
        >
          {data?.serviceOrder?.status || data?.paymentOrder?.status}
        </BootstrapBadge>
      </Grid>
      </>
    }
    </>
  );
};

export default CardDataSPEI;
