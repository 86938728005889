import { closeModals } from '../modals/modalsSlice';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { logout } from '../auth/authSlice';

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    getData: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getDataSuccess: {
      reducer(state) {
        state.isLoading = false;
      },
    },
    getDataFail: {
      reducer(state) {
        state.isLoading = false;
      },
    },
    getPaymentOrdersForDashboardSuccess: {
      reducer(state, action) {
        state.paymentOrders = {
          ...action.payload.paymentOrders,
        };
      },
    },
    getPaymentOrdersForDashboardFail: {
      reducer(state, action) {
        state.error = action.payload.error;
        state.paymentOrders.error = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    getPDFReport: {
      reducer(state) {
        state.isLoading = true;
        state.isLoadingPDF = true;
      },
    },
    getPDFReportSuccess: {
      reducer(state) {
        state.isLoading = false;
        state.isLoadingPDF = false;
      },
    },
    getPDFReportFail: {
      reducer(state, action) {
        state.isLoadingPDF = false;
        state.isLoading = false;
        state.agencies.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    getServiceOrdersForDashboardSuccess: {
      reducer(state, action) {
        const {
          count,
          payed,
          notPayed,
          globalTotal,
        } = action.payload.serviceOrders;
        state.serviceOrders.count = count;
        state.serviceOrders.payed = payed;
        state.serviceOrders.notPayed = notPayed;
        state.serviceOrders.globalTotal = globalTotal;
      },
    },
    getServiceOrdersForDashboardFail: {
      reducer(state, action) {
        state.error = action.payload.error;
        state.serviceOrders.error = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    getPaymentsForDashboardSuccess: {
      reducer(state, action) {
        state.payments = {
          ...state.payments,
          ...action.payload.payments,
        };
      },
    },
    getPaymentsForDashboardFail: {
      reducer(state, action) {
        state.error = action.payload.error;
        state.payments.error = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    getAgenciesForDashboardSuccess: {
      reducer(state, action) {
        state.agencies = action.payload.agencies;
        state.message = action.payload.message;
        state.agencies.successModal = {
          show: true,
          message: state.message,
        };
        state.isLoading = false;
      },
    },
    getAgenciesForDashboardFail: {
      reducer(state, action) {
        state.agencies = action.payload.agencies;
        state.error = action.payload;
        state.agencies.errorModal = {
          show: true,
          message: action.payload.message,
        };
        state.isLoading = false;
      },
    },
    getAgenciesForDashboard: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    cleanDashboardInfo: {
      reducer(state, action) {
        return initialState;
      },
    },
  },
  extraReducers: {
    [logout]: (state, action) => {
      return initialState;
    },
    [closeModals]: (state, action) => {
      state.isLoading = false;
      state.successModal = initialState.successModal;
      state.errorModal = initialState.errorModal;
    },
  },
});

export const {
  getData,
  getDataSuccess,
  getDataFail,
  getPaymentOrdersForDashboardSuccess,
  getPaymentOrdersForDashboardFail,
  getServiceOrdersForDashboardSuccess,
  getServiceOrdersForDashboardFail,
  getPaymentsForDashboardSuccess,
  getPaymentsForDashboardFail,
  getAgenciesForDashboardFail,
  getAgenciesForDashboardSuccess,
  getAgenciesForDashboard,
  cleanDashboardInfo,
  getPDFReport,
  getPDFReportSuccess,
  getPDFReportFail,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
