/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import {
  Button,
  Paper,
  TextField,
  Grid,
  Typography,
  makeStyles,
  Divider,
  IconButton,
  MenuItem,
  Select,
  OutlinedInput,
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Close from '@material-ui/icons/Close';
import {
  formatNumber,
  roundValue,
  formatDate,
  getNested,
} from '../../../utils';

import MUIAutocompleteAgencies from 'app/components/MUIAutocompleteAgencies';
import Modal from 'app/components/Modal';

import { makeSelectUserRoleFromState } from '../../auth/selectors';
import ButtonImportFile from 'app/components/ButtonImportFile';

import FillDataSPEI from 'app/components/SupportForms/FillDataSPEI';
import CardDataSPEI from 'app/components/SupportForms/CardDataSPEI';
import FillDataOXXO from 'app/components/SupportForms/FillDataOXXO';
import FillDataInvoice from 'app/components/SupportForms/FillDataInvoice';
import FillDataBusinessPro from 'app/components/SupportForms/FillDataBusinessPro';
import FillDataMagik from 'app/components/SupportForms/FillDataMagik';
import FillDataRemoveCommision from 'app/components/SupportForms/FillDataRemoveCommision';
import UpdatePasswordForm from 'app/components/SupportForms/UpdatePasswordForm';
import CreateSuperAdmins from 'app/components/SupportForms/CreateSuperAdmins';
import UpdateAmountAndStatusDMS from 'app/components/SupportForms/UpdateAmountODS';
import ChangeStatus from 'app/components/SupportForms/ChangeStatus';
import Refund from 'app/components/SupportForms/Refund';
import UpdateSpei from 'app/components/SupportForms/UpdateSpei';

import { functions } from './functionsList';
import { CSVLink } from 'react-csv';
import GetApp from '@material-ui/icons/GetApp';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { useForm } from 'react-hook-form';

import { useDispatch, useSelector } from 'react-redux';
import {
  updateOrder,
  updateStatusOrder,
  billedDMS,
  closeModals,
  findSPEIorder,
  findOXXOorder,
  findInvoiceOrder,
  paymentSPEI,
  paymentOXXO,
  invoiceData,
  BPOrder,
  getQuiterOrder,
  refundODS,
  refund,
  removeCommission,
  updatePassword,
  associateAgency,
  resetState,
  getODSByOrderID,
  updateODSByOrderID,
  paymentSPEIUpdate,
  geOrdersQuiterForDownload,
  importDepositsFromExcelSupport,
  downloadUsability,
  testAffiliation,
  searchSPEI,
} from '../supportSlice';
import { closeModals as closeModalsUser } from 'app/features/modals/modalsSlice';
import { createUser, resetURL } from 'app/features/users/usersSlice';
import { getPaymentDetail } from 'app/features/payments/paymentsSlice';

import { selectSupport } from '../selectors';
import {
  makeSelectUsersURL,
  makeSelectUsersID,
  selectUsers,
} from 'app/features/users/selectors';

import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import moment from 'moment';
import MomentUtils from '@date-io/moment';
import clsx from 'clsx';

import { makeSelectPaymentDetailsFromState } from 'app/features/payments/selectors';
import {formatCardNumberAmex, formatCardNumber, validateDate, cardDate, onlyNumbers} from 'app/utils/index';
import { Autocomplete } from '@material-ui/lab';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';


const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: '20px',
  },
  funtions: {
    margin: '40px 0',
    width: '100%',
  },
  textField: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  submitButtonWrapper: {
    marginTop: '20px',
  },
  description: {
    background: '#ecedf3',
    padding: '10px',
    margin: 'auto',
    marginBottom: '10px',
    width: '80%',
  },
  descriptionText: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: '-20px',
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '-30px',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '-40px',
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: '-50px',
    },
  },
}));

const Support = (props) => {
  const dispatch = useDispatch();
  const { role } = props;
  const paymentDetails = useSelector((state) =>
    makeSelectPaymentDetailsFromState(state),
  );
  const {
    updateOrder,
    updateStatusOrder,
    billedDMS,
    support,
    urlUser,
    userID,
    users,
    closeModals,
    closeModalsUser,
    findSPEIorder,
    findOXXOorder,
    findInvoiceOrder,
    paymentSPEI,
    paymentOXXO,
    invoiceData,
    BPOrder,
    getQuiterOrder,
    refundODS,
    refund,
    removeCommission,
    updatePassword,
    createUser,
    associateAgency,
    resetState,
    resetURL,
    paymentSPEIUpdate,
    geOrdersQuiterForDownload,
    importDepositsFromExcelSupport,
    downloadUsability,
    testAffiliation,
    searchSPEI,
  } = props;
  const classes = useStyles();

  const [actionFunction, setActionFunction] = useState('');
  const [actionDescription, setActionDescription] = useState('');
  const [agencySelected, setAgencySelected] = useState('');
  const [dataDMS, setDataDMS] = useState({});
  const [orderNumber, setOrderNumber] = useState('');
  const [buttonAction, setButtonAction] = useState('Cambiar');
  const [hideFormData, setHideFormData] = useState(false);
  const [idType, setIdType] = useState('Ingresa el ID Karlo');
  const [totalSPEI, setTotalSPEI] = useState('');
  const [totalOXXO, setTotalOXXO] = useState('');
  const [hasPaymentOXXO, setHasPaymentOXXO] = useState(false);
  const [paymentOXXOId, setPaymentOXXOId] = useState(null);
  const [orderOXXOId, setOrderOXXOId] = useState(null);
  const [dateSPEI, setDateSPEI] = useState('');
  const [questionRefund, setQuestionRefund] = useState(false);
  const [questionDeposit, setQuestionDeposit] = useState(false);
  const [newDate, setNewDate] = useState(moment());
  const [newStatus, setNewStatus] = useState();
  const [paymentAggregator, setPaymentAggregator] = useState();
  const [csvReport, setCsvReport] = useState([]);
  const [csvReportODP, setCsvReportODP] = useState([]);
  const [dataOrder, setdataOrder] = useState({});
  const [excelFile, setExcelFile] = useState(null);
  const [firstDate, setFirstDate] = useState(null);
  const [secondDate, setSecondDate] = useState(null);
  const { handleSubmit, register, errors, control, watch } = useForm({
    defaultValues: {
      provisional: false,
    },
  });
  const [typeOrder, setTypeOrder] = useState('');
  const [showCardInfo, setShowCardInfo] = useState(false);

  const [labelName, setLabelName] = useState('Seleccionar forma de pago');
  const [selectedOption, setSelectedOption] = useState('');
  const [methodPayment, setMethodPayment] = useState([
    {
      label : 'Prosa',
      value : 'prosa',
    },
    {
      label : 'Prosa 3 Meses',
      value : 'prosa3',
    },
    {
      label : 'BBVA',
      value : 'bbva',
    },
    {
      label : 'Banamex',
      value : 'banamex',
    },
    {
      label : 'AMEX',
      value : 'american-express',
    },
  ]);
  const [disableButton, setDisableButton] = useState(false);
  const [paymentForm, setPaymentForm] = useState([]);
  const [cardData, setCardData] = useState({
    cardHolder: '',
    cardDC: 'credit',
    cardNumber: '',
    cardExpiration: '',
    street: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    postalCode: '',
  });
  const [cvv, setCvv] = useState();
  const [directionBusiness, setDirectionBusiness] = useState({
    country: '',
    state: '',
    municipality: '',
    colony: '',
    postalCode: '',
  });

  useEffect(() => {
    const { successModal, errorModal, fromdb } = support;
    if (
      !errorModal.show &&
      fromdb &&
      actionFunction === 'ODSBilled'
    ) {
      if (fromdb.updateOrder.Record !== undefined) {
        const data = {
          ...fromdb.updateOrder.Record,
          DMS: 'magik',
        };
        setDataDMS(data);
      } else {
        setDataDMS(fromdb.updateOrder);
      }
    } else {
      setDataDMS({});
    }
    if (
      successModal.show &&
      (actionFunction === 'ODSBilled' ||
        actionFunction === 'ODSSPEI' ||
        actionFunction === 'oxxoPaymentODS' ||
        actionFunction === 'oxxoPaymentODP' ||
        actionFunction === 'updateInvoiceData' ||
        actionFunction === 'ODPSPEI')
    ) {
      setHideFormData(true);
    }
    if (
      actionFunction === 'ODSSPEI' ||
      actionFunction === 'ODPSPEI'
    ) {
      setTotalSPEI(fromdb?.findPayment?.total);
      setDateSPEI(fromdb?.findPayment?.createdAt);
    }
    if(actionFunction === 'oxxoPaymentODS' || actionFunction === 'oxxoPaymentODP'){
      setTotalOXXO(fromdb?.findPayment?.outPut?.order?.total);
      setHasPaymentOXXO(fromdb?.findPayment?.outPut?.hasPayment);
      setOrderOXXOId(fromdb?.findPayment?.outPut?.order?.id);
      if(fromdb?.findPayment?.outPut?.hasPayment){
        setPaymentOXXOId(fromdb?.findPayment?.outPut?.order?.payments[0]?.id);
      }
    }
    if (actionFunction === 'updateInvoiceData') {
      setdataOrder(fromdb?.findPayment?.outPut?.invoiceProfile);
    }
  }, [support]);

  useEffect(() => {
    setSecondDate(null);
    setFirstDate(null);
    setCsvReportODP([]);
    setCsvReport([]);
  }, [agencySelected]);
  useEffect(() => {
    setHideFormData(false);
  }, [actionFunction]);
  useEffect(() => {
    setCsvReportODP([]);
    setCsvReport([]);
  }, []);
  useEffect(() => {
    if (firstDate && secondDate && agencySelected) {
      handleMakeAction();
    }
  }, [firstDate, secondDate]);
  useEffect(() => {
    if (support.ordersQuiterForDownload != null) {
      setCsvReport(support.ordersQuiterForDownload.ODSData);
    }
  }, [support.ordersQuiterForDownload]);
  const handleChangeCard = (name, newValue) => {
    const validation = newValue.replace(/[- A-Za-z]+$/g, '');
    const cardTypeByFirstNumber = validation.slice(0, 1);
    let result;
    if (
      (cardTypeByFirstNumber === '' || cardTypeByFirstNumber === '3') &&
      selectedOption?.value === 'american-express'
    ) {
      if (validation.length < 18) {
        result = formatCardNumberAmex(validation);
        setCardData({
          ...cardData,
          [name]: result,
        });
      }
    } else if (
      cardTypeByFirstNumber !== '3' &&
      selectedOption?.value !== 'american-express'
    ) {
      if (validation.length < 20) {
        result = formatCardNumber(validation);
        setCardData({
          ...cardData,
          [name]: result,
        });
      }
    }
  };
  const handleChangeCards = (name, newValue) => {
    if (name === 'cardExpiration') {
      if (newValue.length < 6 && (cardDate.test(newValue) || newValue === '')) {
        let date = newValue;
        if (newValue.length === 2) {
          if (cardData.cardExpiration.length !== 3) {
            const month = newValue.slice(0, 2);
            date = `${month}/`;
          }
        }
        setCardData({
          ...cardData,
          [name]: date,
        });
      }
    } else if (name === 'phone') {
      const validation = newValue.replace(/[-+ A-Za-z]+$/g, '');
      if (validation.length < 11) {
        setCardData({
          ...cardData,
          [name]: validation,
        });
      }
    } else if (
      name === 'firstName' ||
      name === 'lastName' ||
      name === 'cardHolder'
    ) {
      const validation = newValue.replace(/[^A-Z a-z]/g, '');
      setCardData({
        ...cardData,
        [name]: validation,
      });
    } else {
      setCardData({
        ...cardData,
        [name]: newValue,
      });
    }
  };
  const handleChangeCvv = (data) => {
    const firstValidation = data.replace(/[-A-Za-z]+$/g, '');
    if (onlyNumbers.test(firstValidation) || firstValidation === '') {
      const cardType = cardData.cardNumber.slice(0, 1);
      if (cardType !== '3' && firstValidation.length < 4) {
        setCvv(firstValidation);
      } else if (firstValidation.length < 5 && cardType === '3') {
        setCvv(firstValidation);
      }
    }
  };
  const searchDirectionData = (postalCode) => {
    setDirectionBusiness({
      ...directionBusiness,
      postalCode: postalCode,
      country: 'México',
    });
  };
  const handleResetInputs = () => {
    setCardData({
      ...cardData,
      cardNumber: '',
      cardHolder: '',
      cardExpiration: '',
      phone: '',
      cvv: '',
    });
    setDirectionBusiness({
      ...directionBusiness,
      postalCode: '',
      address: '',
      colony: '',
  });
  setSelectedOption('');
  setAgencySelected('');
};

  const testPayment = () => {
    const data = {
      agencyID: agencySelected,
      formatPayment: selectedOption?.value == 'prosa3' ? 'credit' : 'debit',
      bankRates: selectedOption?.value,
      methodPayment:
        selectedOption?.value == 'american-express'
          ? 'american-express'
          : 'visa',
      cardInformation: {
        number: cardData.cardNumber.replace(/\s/g, ''),
        securityCode: cvv,
        expiryDate: {
          month: cardData?.cardExpiration.slice(0, 2),
          year: cardData?.cardExpiration.slice(3, 5),
        },
      },
      customerInformation: {
        name: cardData?.firstName,
        lastName: cardData?.lastName,
      },
      customerAddress: {
        postalCode: directionBusiness?.postalCode
          ? directionBusiness?.postalCode
          : '',
        address: cardData?.street ? cardData?.street : '',
        colony: directionBusiness?.colony ? directionBusiness?.colony : '',
      },
    };
    testAffiliation(data);
  };
  useEffect(() => {
    if (support.successModal) {
      handleResetInputs();
    }
  }, [support.successModal])
  useEffect(() => {
    let ordersOdsCSV = [];
    let ordersOdpCSV = [];
    if (props.support.ordersUsabilityForDownload != null) {
      if (props.support.ordersUsabilityForDownload.report) {
        let rowsODS = props.support.ordersUsabilityForDownload.report.rowsODS;
        if (
          Array.isArray(rowsODS) &&
          rowsODS.length > 0
        ) {
          ordersOdsCSV = rowsODS.map((order) => {
            const paymentDate = order['Fecha de Pago'] ? formatDate(order['Fecha de Pago']) : '-';
            const createdDate = order['createdDate'] ? formatDate(order['createdDate']) : '-';
            const createdAt = order['createdAt'] ? formatDate(order['createdAt']) : '-';
            const total = order['total'] ? formatNumber(order['total']) : '$0.00';
            const orderOrigin = (order['DMS'] === 'karlo lite' || order['DMS'] === 'karlo') ? 'Karlo' : 'DMS';
            const paymentOrigin = (order['Origen'] === 'karlo lite' || order['DMS'] === 'karlo') ? 'Karlo' : 'DMS';
            const metodoPago = (order['Metodo de Pago'] === 'SPEI' || order['Metodo de Pago'] === 'TRANSFER') ? 'SPEI' : order['Metodo de Pago'];
            const paymentForm = order['Forma de Pago'] === 0 ||
              order['Metodo de Pago'] === 'SPEI' ||
              order['Metodo de Pago'] === 'TRANSFER' ||
              order['Metodo de Pago'] === 'OXXO' ?
              'Contado'
              : order['Forma de Pago'] + ' meses';

            var fecha1 = new Date(order['endDateApplication']);
            var fecha2 = new Date(order['startDateApplication']);
            let diferencia = fecha1 - fecha2;
            var minutos = Math.floor(diferencia / 60000);
            var segundos = Math.floor((diferencia % 60000) / 1000);
            return {
              'Id de orden': order['Id de orden'],
              'Negocio': order['Agencia'],
              'Grupo': order['Grupo'] ? order['Grupo'].toUpperCase() : "",
              'Estado': order['Estado'],
              'Ciudad': order['Ciudad'],
              'Total': total,
              'Tipo': 'Servicio',
              'Origen de Orden': orderOrigin,
              'Estatus Orden': order['Estatus'],
              'Fecha Creación de Orden en DMS': createdDate,
              'Fecha Registro de orden en Karlo': createdAt,
              'Fecha de Pago de Orden': paymentDate,
              'Estatus Pago': order['Estado de Pago'],
              'Origen de Pago': order['Origen'],
              'Método': metodoPago,
              'Marca': order['Marca'],
              'Forma de pago': paymentForm,
              'Tiempo de Transacción (MIN)': minutos,
              'Tiempo de Transacción (Segundos)': segundos,
            };
          })
        }
        let rowsODP = props.support.ordersUsabilityForDownload.report.rowsODP;
        if (
          Array.isArray(rowsODP) &&
          rowsODP.length > 0
        ) {
          ordersOdpCSV = rowsODP.map((orderODP) => {
            const paymentDate = orderODP['Fecha de Pago'] ? formatDate(orderODP['Fecha de Pago']) : '-';
            const createdDate = orderODP['createdDate'] ? formatDate(orderODP['createdDate']) : '-';
            const createdAt = orderODP['createdAt'] ? formatDate(orderODP['createdAt']) : '-';
            const total = orderODP['total'] ? formatNumber(orderODP['total']) : '$0.00';
            const orderOrigin = 'Karlo';
            const metodoPago = (orderODP['Metodo de Pago'] === 'SPEI' || orderODP['Metodo de Pago'] === 'TRANSFER') ? 'SPEI' : orderODP['Metodo de Pago'];
            const paymentForm = orderODP['Forma de Pago'] === 0 ||
              orderODP['Metodo de Pago'] === 'SPEI' ||
              orderODP['Metodo de Pago'] === 'TRANSFER' ||
              orderODP['Metodo de Pago'] === 'OXXO' ?
              'Contado'
              : orderODP['Forma de Pago'] + ' meses';


            var fecha1 = new Date(orderODP['endDateApplication']);
            var fecha2 = new Date(orderODP['startDateApplication']);
            let diferencia = fecha1 - fecha2;
            var minutos = Math.floor(diferencia / 60000);
            var segundos = Math.floor((diferencia % 60000) / 1000);
            return {
              'Id de orden': orderODP['Id de orden'],
              'Negocio': orderODP['Agencia'],
              'Grupo': orderODP['Grupo'] ? orderODP['Grupo'].toUpperCase() : "",
              'Estado': orderODP['Estado'],
              'Ciudad': orderODP['Ciudad'],
              'Total': total,
              'Tipo': orderODP['type'],
              'Origen de Orden': orderOrigin,
              'Estatus Orden': orderODP['Estatus'],
              'Fecha Creación de Orden en DMS': createdDate,
              'Fecha Registro de orden en Karlo': createdAt,
              'Fecha de Pago de Orden': paymentDate,
              'Estatus Pago': orderODP['Estado de Pago'],
              'Origen de Pago': orderODP['Origen'],
              'Método': metodoPago,
              'Marca': orderODP['Marca'],
              'Forma de pago': paymentForm,
              'Tiempo de Transacción (MIN)': minutos,
              'Tiempo de Transacción (Segundos)': segundos,
            };
          })
        }
        setCsvReport(ordersOdsCSV);
        setCsvReportODP(ordersOdpCSV);
      }
    }
  }, [support.ordersUsabilityForDownload]);
  useEffect(() => {
    const { errorModal } = support;
    if (
      errorModal.show ||
      (actionFunction !== 'ODSBilled' &&
        actionFunction !== 'ODSSPEI' &&
        actionFunction !== 'oxxoPaymentODS' &&
        actionFunction !== 'oxxoPaymentODP' &&
        actionFunction !== 'updateInvoiceData' &&
        actionFunction !== 'ODPSPEI' &&
        actionFunction !== 'updateAmountODP' &&
        actionFunction !== 'updateAmountODS')
    ) {
      setHideFormData(false);
    }

    switch (actionFunction) {
      case 'ODSBilled':
      case 'ODSSPEI':
      case 'ODPSPEI':
      case 'updateInvoiceData':
        setButtonAction('Buscar Orden');
        setIdType('Ingresa el ID Karlo');
        break;
      case 'updateAmountODS':
      case 'updateAmountODP':
        setIdType('Ingresa el DMSID');
        setButtonAction('Buscar Orden');
        break;
      case 'oxxoPaymentODS':
      case 'oxxoPaymentODP':
        setButtonAction('Buscar Orden');
        setIdType('Ingresa el DMSID');
        break;
      case 'refundODS':
        setIdType('Ingresa  NO. de orden');
        setButtonAction('Hacer devolución');
        break;
      case 'refund':
        setIdType('Ingresa el Id Karlo de la orden');
        setButtonAction('Hacer devolución');
        break;
      case 'BPGetOrder':
      case 'searchSpei':
      case 'QuiterGetOrder':
        setIdType('Ingresa DMS ID');
        setButtonAction('Buscar');
        break;
      case 'removeCommission':
        setButtonAction('Buscar');
        setIdType('Ingresa el ID del pago');
        break;
      case 'downloadReportQuiter':
        setButtonAction('Descargar');
        break;
      case 'deposits':
        setButtonAction('Importar');
        break;
      default:
        setButtonAction('Cambiar');
        setIdType('Ingresa el ID Karlo');
        break;
    }
  }, [actionFunction, support]);

  useEffect(() => {
    switch (actionFunction) {
      case 'downloadReportQuiter':
        geOrdersQuiterForDownload({});
        break;
      default:
        break;
    }
  }, [actionFunction]);

  const handleMakeAction = () => {
    const dataOrders = {
      orderNumber,
      actionFunction,
      agencySelected,
    };
    let order;
    const datas = {
      agencySelected,
      orderNumber,
    };

    switch (actionFunction) {
      case 'ODSSPEI':
        actionFunction === 'ODSSPEI'
          ? (order = 'ODS')
          : (order = 'ODP');
        dataOrders.order = order;
        setHideFormData(false);
        findSPEIorder({ dataOrders });
        break;
      case 'ODPSPEI':
        actionFunction === 'ODSSPEI'
          ? (order = 'ODS')
          : (order = 'ODP');
        dataOrders.order = order;
        setHideFormData(false);
        findSPEIorder({ dataOrders });
        break;
      case 'BPGetOrder':
        const dataGet = {
          agencySelected,
          orderNumber,
        };
        BPOrder({ dataGet });
        break;
      case 'QuiterGetOrder':
        const dataQuiterGet = {
          agencySelected,
          orderNumber,
        };
        getQuiterOrder({ dataQuiterGet });
        break;
      case 'removeCommission':
        dispatch(getPaymentDetail({ paymentID: orderNumber, type: 'SPEI' }));
        setHideFormData(true);
        break;
      case 'refundODS':
        setQuestionRefund(true);
        break;
      case 'deposits':
        setQuestionDeposit(true);
        break;
      case 'refund':
        setQuestionRefund(true);
        break;
      case 'updateAmountODP':
        const dataODP = {
          type: 'ODP',
          agencyID: agencySelected,
          orderID: orderNumber,
        };
        dispatch(getODSByOrderID(dataODP));
        setHideFormData(true);
        break;
      case 'updateAmountODS':
        const data = {
          type: 'ODS',
          agencyID: agencySelected,
          orderID: orderNumber,
        };
        dispatch(getODSByOrderID(data));
        setHideFormData(true);
        break;
      case 'UpdateDateSPEIODS':
        order = 'ODS';
        paymentSPEIUpdate({ datas, order, newDate });
        break;
      case 'UpdateDateSPEIODP':
        order = 'ODP';
        paymentSPEIUpdate({ datas, order, newDate });
        break;
      case 'updateStatusODS':
      case 'updateStatusODP':
        const updateOrderData = {
          actionFunction,
          orderNumber,
          agencySelected,
          status: newStatus,
        };
        updateStatusOrder({ updateOrderData });
        break;
      case 'ODSBilled':
        const billedData = {
          orderNumber,
          agencySelected,
        };
        billedDMS({ billedData });
        break;
      case 'downloadReportQuiter':
        geOrdersQuiterForDownload({});
        break;
      case 'updateInvoiceData':
        order = 'ODS';
        dataOrders.order = order;
        setHideFormData(false);
        findInvoiceOrder({ dataOrders });
        break;
      case 'usability':
        downloadUsability({ secondDate, firstDate, agencySelected });
        break;
      case 'oxxoPaymentODS':
        actionFunction === 'oxxoPaymentODS'
          ? (order = 'ods')
          : (order = 'odp');
        dataOrders.order = order;
        dataOrders.typeOrder = "services";
        setHideFormData(false);
        findOXXOorder({ dataOrders });
        break;
      case 'oxxoPaymentODP':
        actionFunction === 'oxxoPaymentODP'
          ? (order = 'odp')
          : (order = 'ods');
        dataOrders.order = order;
        dataOrders.typeOrder = typeOrder;
        setHideFormData(false);
        findOXXOorder({ dataOrders });
        break;
      case 'searchSpei':
        setShowCardInfo(true);
        searchSPEI({ clabe: orderNumber });
    }
  };

  const handleChange = (event) => {
    const findFunction = functions.filter(
      (funtion) => funtion.value === event.target.value,
    );

    setActionFunction(event.target.value);
    setActionDescription(findFunction[0].description);
  };

  const handleRemoveCommission = (id) => {
    removeCommission(id);
  };

  const handleUpdateODSDMS = () => {
    const data = {
      orderNumber,
    };
    updateOrder({ data });
  };

  const handlepaymentSPEI = (event) => {
    let order;
    setHideFormData(false);
    actionFunction === 'ODSSPEI' ? (order = 'ODS') : (order = 'ODP');
    paymentSPEI({ event, order });
  };

  const handlepaymentOXXO = (event) => {
    let order;
    setHideFormData(false);
    actionFunction === 'oxxoPaymentODS' ? (order = 'ods') : (order = 'odp');
    paymentOXXO({ event, order, agencySelected, orderNumber: orderOXXOId });
  }
  const handleUpdateInvoice = (event) => {
    invoiceData({
      agencySelected,
      orderNumber,
      invoiceProfile: event,
    });
  };

  const handleRefund = () => {
    const data = {
      orderNumber,
      agencySelected,
      paymentAggregator,
    };
    setQuestionRefund(false);
    if (actionFunction === 'refund') {
      refund({ data });
    } else {
      refundODS({ data });
    }
  };

  const handleImportDepositsFromExcel = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file0', excelFile);
    importDepositsFromExcelSupport({
      data: formData,
    });
    setQuestionDeposit(!questionDeposit);
    setExcelFile(null);
  };

  const handleUpdatePassword = (data) => {
    const newPasswordData = {
      agencySelected,
      email: data.email,
      password: data.newPassword,
    };
    updatePassword(newPasswordData);
  };

  const handleAssociate = (data) => {
    associateAgency(data);
  };

  const handleCreateUser = (data) => {
    createUser(data);
  };

  const handleResetCreateAdmin = () => {
    resetState();
    resetURL();
  };
  const toggleImportExcel = () => {
    setQuestionDeposit(!questionDeposit);
  };

  const handleUpdateAmountODS = (orderDataUpdate) => {
    const dataUpdate = {
      ...orderDataUpdate,
      status: 'remission',
      type: actionFunction === 'updateAmountODP' ? 'ODP' : 'ODS',
    };

    dispatch(updateODSByOrderID(dataUpdate));
  };

  const handleModalSuccess = () => {
    const { successModal } = support;
    return (
      <Modal
        open={successModal.show}
        closeModal={closeModals}
        type="successModal"
        dialogTitle="Exito"
        dialogText={successModal.message}
        actionButtonText="Cerrar"
        onClick={closeModals}
      />
    );
  };
  const handleClearFirstDate = () => {
    setFirstDate(null);
  };
  const handleFirstDate = (date) => {
    setFirstDate(date);
  };

  const handleSecondDate = (date) => {
    setSecondDate(date);
  };

  const handleClearSecondDate = () => {
    setSecondDate(null);
  };

  const handleModalFail = () => {
    const { errorModal } = support;
    return (
      <Modal
        open={errorModal.show}
        closeModal={closeModals}
        type="errorModal"
        dialogTitle="Ocurrió un Error"
        dialogText={errorModal.message}
        actionButtonText="Cerrar"
        onClick={closeModals}
      />
    );
  };

  const handleModalRefund = () => {
    return (
      <Modal
        open={questionRefund}
        closeModal={() => setQuestionRefund(false)}
        type="warning"
        dialogTitle="Realizar devolución"
        dialogText={`¿Está seguro que desea realizar la devolución de la orden ${orderNumber}?, este proceso es irreversible.`}
        onClick={handleRefund}
        actionButtonText="Aceptar"
      />
    );
  };

  const renderCreateDeposit = () => {
    return (
      <Modal
        open={questionDeposit}
        closeModal={toggleImportExcel}
        dialogTitle="Crear depósito"
        actionButtonText="Subir"
        form={'import-deposits-from-excel-form'}
        dialogChildren={
          <form
            id="import-deposits-from-excel-form"
            autoComplete="off"
            noValidate
            onSubmit={handleImportDepositsFromExcel}
          >
            <ButtonImportFile
              className={classes.buttonImportExcel}
              buttonName="Seleccionar archivo excel"
              file={excelFile}
              setFile={setExcelFile}
            />
          </form>
        }
      />
    );
  };

  return (
    <>
      {handleModalSuccess()}
      {handleModalFail()}
      {handleModalRefund()}
      {renderCreateDeposit()}
      <Paper className={classes.paper}>
        <Typography variant="h4" gutterBottom component="div">
          Modulo de soporte
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={5} className={classes.funtions}>
            <TextField
              select
              label="Listado de funciones"
              fullWidth
              value={actionFunction}
              onChange={handleChange}
              variant="outlined"
              SelectProps={{
                native: true,
              }}
            >
              {functions.map((option) => {
                if (option.value === 'refund') {
                  if (role === 'Payments Searcher') {
                    return (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    );
                  }
                } else {
                  return (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  );
                }
              })}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6} className={classes.funtions}>
            {actionFunction !== 'superadmins' &&
              actionFunction !== 'downloadReportQuiter' &&
              actionFunction !== 'searchSpei' &&
              actionFunction !== 'deposits' && (
                <MUIAutocompleteAgencies
                  bp={actionFunction === 'BPGetOrder' ? true : false}
                  quiter={
                    actionFunction === 'QuiterGetOrder' ? true : false
                  }
                  fullWidth={true}
                  value={agencySelected}
                  onChange={(_, newValue) => {
                    setAgencySelected(newValue?.id);
                  }}
                />
              )}
          </Grid>
        </Grid>

        <Divider />

        <Grid container spacing={4}>
          {actionFunction !== 'updatePassword' &&
            actionFunction !== 'downloadReportQuiter' &&
            actionFunction !== 'deposits' &&
            actionFunction !== 'usability' &&
            actionFunction !== 'searchSpei' &&
            actionFunction !== 'testAffiliation' &&
            actionFunction !== 'superadmins' ? (
            <>
              <Grid item xs={12} md={5} className={classes.funtions}>
                <TextField
                  label={idType}
                  className={classes.textField}
                  fullWidth
                  variant="outlined"
                  onChange={(event) =>
                    setOrderNumber(event.target.value)
                  }
                />
              </Grid>

              {actionFunction === 'updateStatusODS' ||
                actionFunction === 'updateStatusODP' ? (
                <Grid
                  item
                  xs={12}
                  md={5}
                  className={classes.funtions}
                >
                  <ChangeStatus
                    type={actionFunction}
                    setNewStatus={(event) => setNewStatus(event)}
                  />
                </Grid>
              ) : null}

              {actionFunction === 'refund' ? (
                <Grid
                  item
                  xs={12}
                  md={5}
                  className={classes.funtions}
                >
                  <Refund
                    type={actionFunction}
                    setPaymentAggregator={(event) =>
                      setPaymentAggregator(event)
                    }
                  />
                </Grid>
              ) : null}

              {actionFunction === 'UpdateDateSPEIODS' ||
                actionFunction === 'UpdateDateSPEIODP' ? (
                <Grid item xs={12} md={5}>
                  <UpdateSpei
                    newDate={newDate}
                    setNewDate={(event) => setNewDate(event)}
                  />
                </Grid>
              ) : null}
              {actionFunction === 'downloadReportQuiter' ? (
                <Grid item xs={12} md={5}></Grid>
              ) : null}
              {(actionFunction === 'oxxoPaymentODP') ? (
                <Grid item xs={12} md={6} className={classes.funtions}>
                  <Select
                    size={24}
                    align={'left'}
                    className={classes.borderInputFilter}
                    variant="outlined"
                    onChange={(e) => setTypeOrder(e.target.value)}
                    value={typeOrder}
                  >
                    <MenuItem key="service-option" value={'service'}>
                      Servicio
                    </MenuItem>
                    <MenuItem key="parts-option" value={'parts'}>
                      Refacciones
                    </MenuItem>
                    <MenuItem key="sales-option" value={'sales'}>
                      Ventas
                    </MenuItem>
                    <MenuItem key="fi-option" value={'fi'}>
                      {'F&I y Garantías'}
                    </MenuItem>
                  </Select>
                </Grid>
              ) : null}
            </>
          ) : null}
          { actionFunction && actionFunction === 'searchSpei' && (
            <Grid item xs={12} md={5} className={classes.funtions}>
            <TextField
              label={'CLABE interbancaria'}
              className={classes.textField}
              fullWidth
              variant="outlined"
              onChange={(event) =>
                setOrderNumber(event.target.value)
              }
            />
          </Grid>
          )}

          {actionFunction &&
            actionFunction !== 'updatePassword' &&
            actionFunction !== 'downloadReportQuiter' &&
            actionFunction !== 'superadmins' && (
              <Grid item xs={3} md={3} className={classes.funtions}>
                {actionFunction === 'usability' ? (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      className={classes.funtions}
                    >
                      <MuiPickersUtilsProvider utils={MomentUtils} locale={'es'}>
                        <DatePicker
                          autoOk
                          disableToolbar
                          disableFuture
                          variant="inline"
                          label="Inicial"
                          format="DD/MM"
                          className={classes.datePicker}
                          value={firstDate}
                          onChange={handleFirstDate}
                          InputProps={{
                            endAdornment: firstDate ? (
                              <IconButton
                                aria-label="Select locale"
                                size="small"
                                onClick={handleClearFirstDate}
                              >
                                <Close />
                              </IconButton>
                            ) : null,
                          }}
                        />
                        <span className={classes.range}>&nbsp;A&nbsp;</span>
                        <DatePicker
                          autoOk
                          disableToolbar
                          disableFuture
                          variant="inline"
                          label="Final"
                          format="DD/MM"
                          className={clsx(
                            classes.datePicker,
                            classes.datePickerRight,
                          )}
                          value={secondDate}
                          onChange={handleSecondDate}
                          InputProps={{
                            endAdornment: secondDate ? (
                              <IconButton
                                aria-label="Select locale"
                                size="small"
                                onClick={handleClearSecondDate}
                              >
                                <Close />
                              </IconButton>
                            ) : null,
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </>
                ) : null}
              </Grid>
            )}
          {actionFunction &&
            actionFunction !== 'updatePassword' &&
            actionFunction !== 'usability' &&
            actionFunction !== 'downloadReportQuiter' &&
            actionFunction !== 'testAffiliation' &&
            actionFunction !== 'superadmins' && (
              <Grid item xs={12} md={2} className={classes.funtions}>
                <div className={classes.submitButtonWrapper}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleMakeAction}
                    disabled={!actionFunction}
                  >
                    {buttonAction}
                  </Button>
                </div>
              </Grid>
            )}
          {actionFunction &&
            actionFunction === 'downloadReportQuiter' && (
              <Grid item xs={12} md={2} className={classes.funtions}>
                <div className={classes.submitButtonWrapper}>
                  <CSVLink
                    data={csvReport || []}
                    filename="Reporte-Quiter.csv"
                  >
                    <Button
                      variant="contained"
                      className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-t-5 kt-margin-l-10"
                    >
                      Exportar
                      <GetApp className="kt-margin-l-10" />
                    </Button>
                  </CSVLink>
                </div>
              </Grid>
            )}

          {/*  -------  Render forms  ------  */}
          {actionFunction === 'ODSSPEI' && hideFormData && (
            <FillDataSPEI
              handlepaymentSPEI={handlepaymentSPEI}
              orderNumber={orderNumber}
              originalDate={dateSPEI}
              originalAmount={totalSPEI}
            />
          )}
          {actionFunction === 'ODPSPEI' && hideFormData && (
            <FillDataSPEI
              handlepaymentSPEI={handlepaymentSPEI}
              orderNumber={orderNumber}
              originalDate={dateSPEI}
              originalAmount={totalSPEI}
            />
          )}
          {actionFunction === 'searchSpei' && showCardInfo && (
            <CardDataSPEI
              data={support?.fromdb?.result}
              orderNumber={orderNumber}
              originalDate={dateSPEI}
              originalAmount={totalSPEI}
            />
          )}
          {(actionFunction === 'oxxoPaymentODS' || actionFunction === 'oxxoPaymentODP') && hideFormData && (
            <FillDataOXXO
              handlepaymentOXXO={handlepaymentOXXO}
              orderNumber={orderNumber}
              originalDate={dateSPEI}
              originalAmount={totalOXXO}
              hasPaymentOXXO={hasPaymentOXXO}
              paymentOXXOId={paymentOXXOId}
            />
          )}
          {actionFunction === 'removeCommission' && hideFormData && (
            <FillDataRemoveCommision
              paymentData={paymentDetails}
              handleRemoveCommission={(id) =>
                handleRemoveCommission(id)
              }
            />
          )}
          {dataDMS?.DMS === 'businesspro' && hideFormData && (
            <FillDataBusinessPro
              handleUpdateODSDMS={handleUpdateODSDMS}
              dataDMS={dataDMS}
            />
          )}
          {actionFunction === 'usability' && (
            <Grid item xs={12} md={2} className={classes.funtions}>
              <div className={classes.submitButtonWrapper}>
                <div className={classes.submitButtonWrapper}>
                  <CSVLink
                    data={csvReport || []}
                    filename="Reporte-Usabilidad-ODS.csv"
                  >
                    <Button
                      variant="contained"
                      className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-t-5 kt-margin-l-10"
                      disabled={!(csvReport.length > 0)}
                    >
                      Usabilidad ODS
                      <GetApp className="kt-margin-l-10" />
                    </Button>
                  </CSVLink>
                </div>
                <div className={classes.submitButtonWrapper}>
                  <CSVLink
                    data={csvReportODP || []}
                    filename="Reporte-Usabilidad-ODP.csv"
                  >
                    <Button
                      variant="contained"
                      className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-t-5 kt-margin-l-10"
                      disabled={!(csvReportODP.length > 0)}
                    >
                      Usabilidad ODP
                      <GetApp className="kt-margin-l-10" />
                    </Button>
                  </CSVLink>
                </div>
              </div>
            </Grid>
          )}
          {actionFunction === 'testAffiliation' &&
          agencySelected && (
           <>
           <Grid item xs={12}>
             <Autocomplete
               value={selectedOption}
               onOpen={() => setLabelName('Forma de Pago')}
               className={classes.inputSelect}
               onChange={(event, newValue) => setSelectedOption(newValue)}
               options={methodPayment}
               getOptionLabel={(option) => option?.label?.toString()}
               getOptionValue={(option) => option?.value}
               renderInput={(params) => (
                 <TextField
                   {...params}
                   label="Selecciona una opción"
                   variant="outlined"
                 />
               )}
             />
           </Grid>
           <Grid item xs={12}>
             <FormControl
               fullWidth
               style={{ marginTop: '15px' }}
               className={classes.textField}
               variant="outlined"
             >
               <InputLabel htmlFor="outlined-adornment-amount">
                 {'Número de tarjeta'}
               </InputLabel>
               <OutlinedInput
                 id="outlined-adornment-card"
                 name="cardNumber"
                 label="Número de tarjeta"
                 inputProps={{ inputMode: 'numeric' }}
                 value={cardData.cardNumber}
                 onChange={({ target }) =>
                   handleChangeCard(target.name, target.value)
                 }
               />
             </FormControl>
           </Grid>
           <Grid item xs={12}>
             <Grid container spacing={1}>
               <Grid item xs={6}>
                 <TextField
                   autoComplete="off"
                   inputProps={{
                     inputMode: 'numeric',
                     autoComplete: 'off',
                   }}
                   required
                   fullWidth
                   label={'MM/AA'}
                   error={!validateDate(cardData.cardExpiration)}
                   margin="normal"
                   className={classes.textField}
                   variant="outlined"
                   name="cardExpiration"
                   value={cardData.cardExpiration}
                   onChange={({ target }) =>
                     handleChangeCards(target.name, target.value)
                   }
                 />
               </Grid>
               <Grid item xs={6}>
                 <TextField
                   autoComplete="off"
                   required
                   fullWidth
                   label="CVC/CVV"
                   margin="normal"
                   className={classes.textField}
                   variant="outlined"
                   name="cardPass"
                   inputProps={{
                     autoComplete: 'off',
                     type: 'password',
                     inputMode: 'numeric',
                   }}
                   onChange={({ target }) => handleChangeCvv(target.value)}
                   value={cvv}
                 />
               </Grid>
               <Grid item xs={12}>
                 <Grid container spacing={1}>
                   <Grid item xs={6}>
                     <TextField
                       autoComplete="off"
                       required
                       fullWidth
                       label={'Nombre'}
                       margin="normal"
                       className={classes.textField}
                       variant="outlined"
                       name="firstName"
                       value={cardData.firstName}
                       onChange={({ target }) =>
                         handleChangeCards(target.name, target.value)
                       }
                     />
                   </Grid>
                   <Grid item xs={6}>
                     <TextField
                       required
                       fullWidth
                       label={'Apellidos'}
                       margin="normal"
                       className={classes.textField}
                       variant="outlined"
                       name="lastName"
                       onChange={({ target }) =>
                         handleChangeCards(target.name, target.value)
                       }
                       value={cardData.lastName}
                     />
                   </Grid>
                 </Grid>
               </Grid>
               {selectedOption &&
                 selectedOption?.value === 'american-express' && (
                   <>
                     <Grid container spacing={1}>
                       <Grid item xs={12} style={{ marginTop: '20px' }}>
                         <Typography
                           variant="h5"
                           className={classes.domicileText}
                         >
                           {'Domicilio'}
                         </Typography>
                       </Grid>
                       <Grid item xs={12}>
                         <Grid item xs={6}>
                           <TextField
                             required={selectedOption?.value === 'mcVisa'}
                             fullWidth
                             inputProps={{
                               maxLength: 100,
                             }}
                             label={'Calle y número'}
                             className={classes.textField}
                             margin="normal"
                             name="street"
                             variant="outlined"
                             value={cardData.street}
                             onChange={({ target }) =>
                               handleChangeCards(target.name, target.value)
                             }
                           />
                         </Grid>
                         <Grid item xs={6}>
                           <TextField
                             required
                             label={'Código Postal'}
                             margin="normal"
                             variant="outlined"
                             fullWidth
                             value={directionBusiness.postalCode}
                             name="postalCode"
                             onChange={(event) =>
                               searchDirectionData(event.target.value)
                             }
                           />
                         </Grid>
                       </Grid>
                     </Grid>
                   </>
                 )}
               <Grid item xs={12}>
                 <Button
                   className={classes.btn}
                   disabled={
                     cardData.cardNumber === '' ||
                     cardData.cardExpiration === '' ||
                     cardData.firstName === '' ||
                     disableButton ||
                     !validateDate(cardData.cardExpiration) ||
                     cardData.lastName === '' ||
                     cvv === ''
                   }
                   type="submit"
                   variant="contained"
                   color="primary"
                   disableElevation
                   onClick={() => testPayment()}
                 >
                   {'Continuar'}
                 </Button>
               </Grid>
             </Grid>
           </Grid>
         </>
          )}
          {dataDMS?.DMS === 'magik' && hideFormData && (
            <FillDataMagik
              handleUpdateODSDMS={handleUpdateODSDMS}
              dataDMS={dataDMS}
            />
          )}
          {actionFunction === 'updatePassword' && (
            <UpdatePasswordForm
              handleUpdatePassword={(data) =>
                handleUpdatePassword(data)
              }
            />
          )}
          {actionFunction === 'superadmins' && (
            <CreateSuperAdmins
              createUser={(data) => handleCreateUser(data)}
              url={urlUser}
              id={userID}
              users={users}
              closeModalsUser={closeModalsUser}
              handleAssociate={(data) => handleAssociate(data)}
              supportData={support}
              resetState={handleResetCreateAdmin}
            />
          )}
          {(actionFunction === 'updateAmountODS' || actionFunction === 'updateAmountODP') && hideFormData && (
            <UpdateAmountAndStatusDMS
              handleUpdateAmountODS={handleUpdateAmountODS}
              orderData={support?.fromdb?.ODSData}
            />
          )}
          {actionFunction === 'updateInvoiceData' && hideFormData && (
            <FillDataInvoice
              handleUpdateInvoice={handleUpdateInvoice}
              orderNumber={orderNumber}
              originalDate={dateSPEI}
              originalAmount={totalSPEI}
              dataOrder={dataOrder}
            />
          )}
          {actionDescription && actionDescription !== '' && (
            <Grid item xs={12}>
              <Paper className={classes.description}>
                <Typography variant="h6">
                  <b>Descripción de función</b>
                </Typography>
                <Grid container alignItems="flex-start">
                  <Grid item xs={1}>
                    <InfoOutlinedIcon />
                  </Grid>
                  <Grid item xs={11}>
                    <Typography className={classes.descriptionText}>
                      {actionDescription}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )}
        </Grid>
      </Paper>
    </>
  );
};

const mapStateToProps = (state) => ({
  support: selectSupport(state),
  urlUser: makeSelectUsersURL(state),
  userID: makeSelectUsersID(state),
  users: selectUsers(state),
  role: makeSelectUserRoleFromState(state),
});
const mapDispatchToProps = {
  updateOrder,
  updateStatusOrder,
  billedDMS,
  closeModals,
  closeModalsUser,
  findSPEIorder,
  findOXXOorder,
  findInvoiceOrder,
  paymentSPEI,
  paymentOXXO,
  invoiceData,
  BPOrder,
  getQuiterOrder,
  refundODS,
  refund,
  removeCommission,
  updatePassword,
  associateAgency,
  createUser,
  resetState,
  resetURL,
  paymentSPEIUpdate,
  geOrdersQuiterForDownload: geOrdersQuiterForDownload,
  importDepositsFromExcelSupport,
  downloadUsability,
  testAffiliation,
  searchSPEI,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(Support),
);
