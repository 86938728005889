import { Col, Row } from 'react-bootstrap';
import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  defaultComissions,
  monthsByComissions,
} from '../../../../config/monthsTaxes';
import { formatNumber, getNested } from '../../../../utils';
import { getAgency } from 'app/features/agencies/agenciesSlice';

import NumberFormatCustom from '../../../../components/NumberFormatCustom';
import React, { useState, useEffect } from 'react';
import amex from '../../../../assets/img/logos/amex.svg';
import { connect, useSelector } from 'react-redux';
import { makeSelectAgencyDetailsFromState } from '../../../agencies/selectors';
import mastercard from '../../../../assets/img/logos/mastercard.svg';
import visa from '../../../../assets/img/logos/visa.svg';

const IVA = 1.16;
const BASE_EXTRA_WITH_IVA = {
  billPocket: 0,
  netpay: 2.9,
};

const useStyles = makeStyles((theme) => ({
  container: {
    flexWrap: 'wrap',
  },
  paper: {
    padding: theme.spacing(7, 10, 1, 10),
  },
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  client: {
    padding: theme.spacing(0),
    marginBottom: theme.spacing(2),
  },
  textField: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  media: {
    height: 60,
    width: '100%',
    position: 'relative',
  },
  image: {
    height: '100%',
    width: 'auto',
    position: 'absolute',
    top: '10%',
    left: '45%',
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  subtitle: {
    marginBottom: theme.spacing(6),
  },
  subTotals: {
    margin: theme.spacing(2, 0),
  },
  totals: {
    margin: theme.spacing(3, 0),
  },
  conditions: {
    margin: theme.spacing(6, 0, 0, 0),
  },
  bold: {
    fontWeight: 600,
  },
  cards: {
    margin: theme.spacing(0, 0, 0, 1),
  },
  monthsPayment: {
    marginBottom: theme.spacing(2),
  },
}));

function AdvisorCalculator(props) {
  const { agencyDetails, getAgency } = props;

  const classes = useStyles();
  const cardTypeLabel = React.useRef(null);
  const [cardTypeWidth, setCardTypeWidth] = useState(0);
  const [amount, setAmount] = useState('');
  const [cardType, setCardType] = useState(1);
  const role = useSelector((state) => state.auth.user.role);
  useEffect(() => {
    setCardTypeWidth(cardTypeLabel.current.offsetWidth);
  }, []);

  useEffect(() => {
    if (role !== 'Super Admin') {
      getAgency();
    }
  }, [getAgency, role]);

  const getBaseComission = () => {
    switch (cardType) {
      case 2:
        return getNested(
          defaultComissions['amex'],
          agencyDetails,
          'amexCardComission',
        );
      default:
        return getNested(
          defaultComissions['visa/mastercard'],
          agencyDetails,
          'cardComission',
        );
    }
  };

  const getSurchargeByCardType = (mothns) => {
    switch (cardType) {
      case 2:
        return monthsByComissions[mothns].amex;
      default:
        return monthsByComissions[mothns].normal;
    }
  };

  const renderSelectCard = () => {
    return (
      <Grid item xs>
        <FormControl
          variant="outlined"
          fullWidth
          className={classes.formControl}
        >
          <InputLabel ref={cardTypeLabel} htmlFor="card-type">
            Tipo de tarjeta
          </InputLabel>
          <Select
            value={cardType}
            onChange={({ target }) => {
              setCardType(target.value);
            }}
            input={
              <OutlinedInput
                labelWidth={cardTypeWidth}
                id="card-type"
              />
            }
          >
            <MenuItem value={0}>Seleccione una opción</MenuItem>
            <MenuItem value={1}>Visa/Mastercard</MenuItem>
            <MenuItem value={2}>Amex</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const calculateTotalCards = (month) => {
    let baseComission = 0,
      baseComissionWithIVA = 0,
      surchargeByCardType = 0,
      toDepositToAgency = 0,
      totalToBeCharged = 0;
    //CALCULATING COMISSIONS
    baseComission = getBaseComission();
    baseComissionWithIVA = baseComission * IVA;

    surchargeByCardType = getSurchargeByCardType(month);

    const comissionMWI = surchargeByCardType * IVA;

    const baseComissionCalculated =
      amount * baseComissionWithIVA +
      BASE_EXTRA_WITH_IVA['billPocket'];

    toDepositToAgency = amount - baseComissionCalculated;

    totalToBeCharged =
      (toDepositToAgency + BASE_EXTRA_WITH_IVA['billPocket']) /
      (1 - (baseComissionWithIVA + comissionMWI));

    const mensual = (totalToBeCharged / month).toFixed(2);
    const percentageComission = (
      ((totalToBeCharged - amount).toFixed(2) / amount) *
      100
    ).toFixed(3);

    if (validMonth(month)) {
      return {
        comission: `${formatNumber(totalToBeCharged - amount)}`,
        total: formatNumber(totalToBeCharged),
        mensual: formatNumber(mensual),
        percentageComission: `${percentageComission}%`,
      };
    } else {
      return {
        comission: '-',
        total: '-',
        mensual: '-',
        percentageComission: '-',
      };
    }
  };

  const validMonth = (month) => {
    return amount / month >= 100;
  };

  const renderComissions = () => {
    return Object.keys(monthsByComissions).map((key) => {
      const result = calculateTotalCards(key);
      return (
        <Grid key={`comission-${monthsByComissions[key].normal}`}>
          <Grid container spacing={2}>
            <Grid item xs>
              <Typography variant="subtitle1">{`${key} meses`}</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="subtitle1">
                {result.comission}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="subtitle1">
                {result.mensual}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="subtitle1">
                {result.total}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="subtitle1">
                {result.percentageComission}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      );
    });
  };

  return (
    <Row>
      <Col lg="12" md="12" xs="12">
        <Paper className={classes.paper}>
          <form
            className={classes.container}
            noValidate
            autoComplete="off"
          >
            <div className="input-container">
              <Grid container spacing={2}>
                <Grid item xs className={classes.subtitle}>
                  <Typography variant="h6">
                    Calculadora de pagos
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Typography variant="h6">
                    Tarjetas participantes
                  </Typography>
                  <img
                    className={classes.cards}
                    src={visa}
                    width={40}
                    height={40}
                    alt="Visa"
                  />
                  <img
                    className={classes.cards}
                    src={mastercard}
                    width={40}
                    height={40}
                    alt="Mastercard"
                  />
                  <img
                    className={classes.cards}
                    src={amex}
                    width={40}
                    height={40}
                    alt="Mastercard"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs>
                  <TextField
                    required
                    fullWidth
                    type="text"
                    label="Cantidad total"
                    variant="outlined"
                    value={amount}
                    onChange={({ target }) => setAmount(target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          $
                        </InputAdornment>
                      ),
                      inputComponent: NumberFormatCustom,
                      inputProps: { quantity: true },
                    }}
                    className={classes.textField}
                  />
                </Grid>
                <Grid item xs>
                  {renderSelectCard()}
                </Grid>
              </Grid>
              <Grid className={classes.subTotals}>
                <Grid item xs className={classes.monthsPayment}>
                  <Typography variant="h6">Pago a meses</Typography>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs></Grid>
                  <Grid item xs>
                    <Typography
                      variant="subtitle1"
                      className={classes.bold}
                    >
                      Comisión
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography
                      variant="subtitle1"
                      className={classes.bold}
                    >
                      Pago mensual
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography
                      variant="subtitle1"
                      className={classes.bold}
                    >
                      Total a pagar
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography
                      variant="subtitle1"
                      className={classes.bold}
                    >
                      Porcentaje comisión (IVA incluido)
                    </Typography>
                  </Grid>
                </Grid>
                {renderComissions()}
              </Grid>

              {/* <Typography variant="body2">
                *Pago a 18 meses solo aplica en VISA y MASTERCARD
              </Typography> */}
            </div>
          </form>
        </Paper>
      </Col>
    </Row>
  );
}

const mapStateToProps = (state) => ({
  agencyDetails: makeSelectAgencyDetailsFromState(state),
});

const mapDispatchToProps = {
  getAgency,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdvisorCalculator);
