import React, { Fragment, useState, useEffect } from 'react';

import {
  Button,
  TextField,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: theme.spacing(7, 10, 1, 10),
  },
  funtions: {
    margin: '40px 0',
    width: '100%',
  },
  textField: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  submitButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
}));

const FillDataMagik = (props) => {
  const { handleUpdateODSDMS, dataDMS } = props;
  const classes = useStyles();
  return (
    <Fragment>
      <Grid item xs={12} md={12} className={classes.funtions}>
        <Typography variant="h4" gutterBottom>
          Información del DMS: Magik
        </Typography>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="DMS"
          fullWidth
          value={dataDMS.DMS}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="DMS de la agencia."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="Caja"
          fullWidth
          value={dataDMS.CAJA}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="Caja."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="CFDI"
          fullWidth
          value={dataDMS.USO_CFDI}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="CFDI."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="Método de pago"
          fullWidth
          value={dataDMS.METODO_PAGO}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="Método de pago."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="Sucursal"
          fullWidth
          value={dataDMS.SUCURSAL}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="Sucursal."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="Serie"
          fullWidth
          value={dataDMS.SERIE}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="Serie."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="Ref. Karlo salida"
          fullWidth
          value={dataDMS.REFKARLOSALIDA}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="Ref. Karlo salida."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="Correo 1"
          fullWidth
          value={dataDMS.EMAIL1}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="Correo 1."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="Correo 2"
          fullWidth
          value={dataDMS.EMAIL2}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="Correo 2."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="ID Karlo"
          fullWidth
          value={dataDMS.ID_KARLO}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="ID Karlo."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="RFC Cliente"
          fullWidth
          value={dataDMS.RFC_CLIENTE}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="RFC Cliente."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="No. orden"
          fullWidth
          value={dataDMS.NO_ORDEN}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="No. orden."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="IVA"
          fullWidth
          value={dataDMS.IVA}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="IVA."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="Total"
          fullWidth
          value={dataDMS.TOTAL}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="Total."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="Sub total"
          fullWidth
          value={dataDMS.SUBTOTAL}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="Sub total."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="Hora"
          fullWidth
          value={dataDMS.HORA}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="Hora."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="Fecha"
          fullWidth
          value={dataDMS.FECHA}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="Fecha."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="Forma de pago"
          fullWidth
          value={dataDMS.FORMA_PAGO}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="Forma de pago."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="Últimos dígitos de la tarjeta"
          fullWidth
          value={dataDMS.REFERENCIABANCO}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="Últimos dígitos de la tarjeta."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={2} className={classes.funtions}>
        <div className={classes.submitButtonWrapper}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleUpdateODSDMS()}
          >
            Facturar
          </Button>
        </div>
      </Grid>
    </Fragment>
  );
};

export default FillDataMagik;
