import { Badge, Table, Alert } from 'react-bootstrap';
import {
  DialogTitle,
  Grid,
  Paper,
  createMuiTheme,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { getUserDetail } from 'app/features/karloUsers/karloUsersSlice';
import { makeSelectKarloUsersDetails } from 'app/features/karloUsers/selectors';

import Modal from '../../../../components/Modal';
import ActiviesProgram from '../../../../components/ActiviesProgram';
import { closeModals } from 'app/features/modals/modalsSlice';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import {
  formatDate,
  formatSingleDate,
  formatNumber,
  getNested,
} from '../../../../utils';

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '1em',
        color: 'lightblue',
        backgroundColor: 'green',
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  paperTarjeta: {
    textAlign: 'left',
    marginTop: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  divider: {
    width: '100%',
  },
  title: {
    textAlign: 'left',
    color: '#1c1c1c',
  },
  content: {
    textAlign: 'left',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    margin: theme.spacing(1, 1, 1, 1),
  },
  menu: {
    width: 200,
  },
  margin: {
    margin: theme.spacing(1),
  },
  list: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    // backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  bigContainer: {
    display: 'flex',
  },
  leftContainer: {
    flexDirection: 'column',
  },
  rightContainer: {
    flexDirection: 'column',
  },
  item: {
    textAlign: 'left',
    justifyContent: 'space-between',
  },
  paperOrden: {
    width: '100%',
    textAlign: 'left',
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
  },
  paperCliente: {
    width: '100%',
    textAlign: 'left',
    marginTop: theme.spacing(2),
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
  },
  paperOps: {
    width: '100%',
    margin: theme.spacing(2, 0),
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
  },
  paperUrl: {
    width: '100%',
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
  },
  itemBlock: {
    display: 'flex',
  },
  item1: {
    textAlign: 'left',
  },
  item2: {
    textAlign: 'center',
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  tableTitle: {
    fontWeight: 401,
  },
  tables: {
    width: '100%',
  },
  media: {
    height: 60,
    width: '100%',
    position: 'relative',
  },
  feedBackStatus: {
    width: '50px',
    height: 'auto',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '30px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  inputs: {
    marginTop: theme.spacing(1),
  },
  select: {
    margin: theme.spacing(1, 0, 1, 0),
  },
}));

function UserDetail(props) {
  const classes = useStyles();
  // const { closeModals, userDetail } = props;
  const { userDetail } = props;
  // const { userDetail } = [];
  const [userData, setUserData] = useState([]);
  const [carsData, setCarsData] = useState([]);
  const [logsData, setLogsData] = useState([]);
  const [appointmentsData, setAppointmentsData] = useState([]);

  useEffect(() => {
    if (userDetail == null) {
      console.log('es null');
    } else {
      console.log('userDetail , ', userDetail);
      if (Array.isArray(userDetail.orders)) {
        const cleanData = userDetail.orders.map((event) => {
          return {
            text: event.dmsId,
            date: event.dateCreated,
            type: event.orderType,
          };
        });
        setLogsData(cleanData);
      }
      if (Array.isArray(userDetail.serviceAppointments)) {
        const cleanDataDos = userDetail.serviceAppointments.map(
          (event) => {
            return {
              text: event.type + ' / ' + event.description,
              date: event.date,
              type: 'serviceAppt',
            };
          },
        );
        setAppointmentsData(cleanDataDos);
      }
      setCarsData(userDetail.cars);
      setUserData(userDetail);
    }
  }, [userDetail]);

  useEffect(() => {
    props.setLeftSideComponent(
      <div className="kt-subheader__main">
        <div>
          <h3 className="kt-subheader__title">Detalle Usuario</h3>
        </div>
      </div>,
    );
    let data = {
      userID: props.match.params.id,
    };
    props.getUserDetail(data);
  }, []);

  function handleCloseModals() {
    closeModals();
  }

  const renderUserCars = () => {
    const carArray = Array.isArray(carsData) ? carsData : [];
    if (carArray.length > 0) {
      const vehicles = carArray.map((vehicle) => {
        return (
          <tr key={`karloVehicles-${vehicle.id}`}>
            <td className={classes.clickeableTR} role="presentation">
              {vehicle.id}
            </td>
            <td className={classes.clickeableTR} role="presentation">
              {vehicle.brand}
            </td>
            <td className={classes.clickeableTR} role="presentation">
              {vehicle.year}
            </td>
            <td className={classes.clickeableTR} role="presentation">
              {vehicle.model}
            </td>
            <td className={classes.clickeableTR} role="presentation">
              {vehicle.version}
            </td>
            <td className={classes.clickeableTR} role="presentation">
              {vehicle.plates}
            </td>
            <td className={classes.clickeableTR} role="presentation">
              {vehicle.receiveOffers == true ? 'Si' : 'No'}
            </td>
            <td className={classes.clickeableTR} role="presentation">
              {`${vehicle.kilometrage}km`}
            </td>
            <td className={classes.clickeableTR} role="presentation">
              {formatDate(vehicle.createdAt)}
            </td>
          </tr>
        );
      });
      return (
        <Paper className={classes.table}>
          <DialogTitle className={classes.title}>Carros</DialogTitle>
          <Table bordered size="sm">
            <thead>
              <tr>
                <th>ID</th>
                <th>MARCA</th>
                <th>AÑO</th>
                <th>MODELO</th>
                <th>VERSION</th>
                <th>PLACAS</th>
                <th>RECIBE OFERTAS</th>
                <th>KM</th>
                <th>FECHA CREACIÓN</th>
              </tr>
            </thead>
            <tbody>{vehicles}</tbody>
          </Table>
        </Paper>
      );
    }
  };

  const renderAppointmentDetails = () => {
    return (
      <Paper className={classes.paper}>
        <DialogTitle className={classes.title}>
          Resumen del Usuario
        </DialogTitle>

        <Table bordered className={classes.tables}>
          <tbody>
            <tr>
              <td className={classes.tableTitle}>ID</td>
              <td>{userData.id ?? 'n/A'}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>NOMBRE</td>
              <td>{userData.name ?? 'n/A'}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>TELÉFONO</td>
              <td>{userData.phoneNumber ?? 'n/A'}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>EMAIL</td>
              <td>{userData.email ?? 'n/A'}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>GÉNERO</td>
              <td>
                {userData.gender == 'M'
                  ? 'Hombre'
                  : userData.gender == 'F'
                  ? 'Mujer'
                  : 'n/A'}
              </td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>TIPO DE LOGIN</td>
              <td>{userData.loginType}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>
                FECHA DE CREACIÓN
              </td>
              <td>{formatDate(userData.createdAt)}</td>
            </tr>
          </tbody>
        </Table>
      </Paper>
    );
  };

  const renderAgencyDetails = () => {
    return (
      <Paper className={classes.paper}>
        <DialogTitle className={classes.title}>Agencia</DialogTitle>

        <Table bordered className={classes.tables}>
          <tbody>
            <tr>
              <td className={classes.tableTitle}>ID</td>
              <td>{userData.id ?? 'n/A'}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>NOMBRE</td>
              <td>{userData.name ?? 'n/A'}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>TELÉFONO</td>
              <td>{userData.phoneNumber ?? 'n/A'}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>EMAIL</td>
              <td>{userData.email ?? 'n/A'}</td>
            </tr>
          </tbody>
        </Table>
      </Paper>
    );
  };

  const renderUserDetails = () => {
    return (
      <Paper className={classes.paper}>
        <DialogTitle className={classes.title}>Cliente</DialogTitle>

        <Table bordered className={classes.tables}>
          <tbody>
            <tr>
              <td className={classes.tableTitle}>ID</td>
              <td>{userData.id ?? 'n/A'}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>NOMBRE</td>
              <td>{userData.name ?? 'n/A'}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>TELÉFONO CELULAR</td>
              <td>{userData.phoneNumber ?? 'n/A'}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>EMAIL</td>
              <td>{userData.email ?? 'n/A'}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>GÉNERO</td>
              <td>
                {userData.gender == 'M'
                  ? 'Hombre'
                  : userData.gender == 'F'
                  ? 'Mujer'
                  : 'n/A'}
              </td>
            </tr>
          </tbody>
        </Table>
      </Paper>
    );
  };

  const renderPackageDetails = () => {
    return (
      <Paper className={classes.paper}>
        <DialogTitle className={classes.title}>Paquete</DialogTitle>
        <Table bordered className={classes.tables}>
          <tbody>
            <tr>
              <td className={classes.tableTitle}>NOMBRE</td>
              <td>{'n/A'}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>DESCRIPCIÓN</td>
              <td>{'n/A'}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>PRECIO</td>
              <td>{'n/A'}</td>
            </tr>
          </tbody>
        </Table>
      </Paper>
    );
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = userDetail;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message } = userDetail[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={handleCloseModals}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={handleCloseModals}
          />
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          {renderAppointmentDetails()}
          {renderUserDetails()}
          {renderFeedBackModal()}
        </Grid>
        <Grid item xs={12} md={8} className={classes.rightContainer}>
          {renderUserCars()}
          <Grid item>
            <ActiviesProgram
              logs={logsData.concat(appointmentsData)}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userDetail: makeSelectKarloUsersDetails(state),
});

const mapDispatchToProps = {
  getUserDetail,
  closeModals: closeModals,
};

export default withRouter(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(UserDetail),
  ),
);
