import 'react-table/react-table.css';
import 'moment/locale/es';

/* eslint-disable react/display-name */

import {
  formatDate,
  formatExpiricyDate,
  formatNumber,
  getBadgeColor,
  getOffersStatusName,
} from '../../../../utils';
import { Link } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import Add from '@material-ui/icons/Add';
import {
  IconButton,
  Button,
  Tab,
  Grid,
  TextField,
  Tabs,
  Badge as MaterialBadge,
  Paper,
  Typography,
  ListItemText,
  Avatar,
  ListItemAvatar,
  ListItem,
  Divider,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MUIRHFAutocompleteAgencies from 'app/components/MUIRHFAutocompleteAgencies';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import {
  getKarloOffers,
  getOfferVehicles,
  createOffer,
} from 'app/features/karloOffers/karloOffersSlice';
import Modal from '../../../../components/Modal';
import NumberFormat from 'react-number-format';
// import { MuiCurrencyFormat } from 'app/components/MUICurrencyFormat';
import MUIAutocompleteAgencies from 'app/components/MUIAutocompleteAgencies';
import { Badge } from 'react-bootstrap';
import React, { useEffect, useRef, useState } from 'react';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { injectIntl } from 'react-intl';
import {
  makeSelectKarloOffers,
  makeSelectKarloVehiclesOffers,
} from 'app/features/karloOffers/selectors';
import { Controller, useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(0),
  },
  notifications: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  lastUpdated: {
    marginLeft: theme.spacing(2),
    color: '#434349',
  },
  textField: {
    margin: theme.spacing(1),
    width: '95%',
  },
  datePickerTextfield: {
    padding: '5px 5px 5px !important',
    height: '20px',
  },
  agenciesSelect: {
    margin: theme.spacing(1),
    width: '95%',
  },
}));

function KarloOffersTable(props) {
  const classes = useStyles();
  const {
    getKarloOffers,
    karloOffers,
    getOfferVehicles,
    createOffer,
    vehicleOffers,
  } = props;
  const [karloOffersData, setKarloOffersData] = React.useState([]);
  const [offersVehiclesData, setOffersVehiclesData] = React.useState(
    [],
  );
  const reactTable = React.useRef(null);
  const [vehicleId, setVehicleId] = React.useState(0);
  const [tabSelected, setTabSelected] = React.useState(0);
  const [showUserModal, setShowUserModal] = useState(false);
  const [agency, setAgency] = useState('');

  const { control, watch, setValue } = useForm({
    defaultValues: {
      agencyID: '',
      total: '',
      terms: '',
      termType: '',
      agencyID: '',
      total: '',
      vigencyDate: null,
      invoiceNumber: '',
      clientName: '',
      clientEmail: '',
      clientPhoneNumber: '',
      clientID: '',
      downPayment: {
        total: '',
      },
    },
  });
  const vigencyDate = watch('vigencyDate');

  const { handleSubmit, register, errors, reset } = useForm();

  const handleFetchData = debounce(
    (state, instance) => {
      const { page, pageSize, sorted, filtered } = state;
      getKarloOffers({ page, pageSize, sorted, filtered });
      getOfferVehicles({ page, pageSize, sorted, filtered });
    },
    1000,
    { leading: false, trailing: true },
  );

  const handleChangeTab = (event, value) => setTabSelected(value);

  React.useEffect(() => {
    if (Array.isArray(karloOffers.fromdb)) {
      const cleanData = karloOffers.fromdb.map((offer) => {
        return {
          id: offer.id,
          name: offer.user.length != 0 ? offer.user.name : '-',
          agency: offer.agency.length != 0 ? offer.agency.name : '-',
          vehicle: offer.car.length != 0 ? offer.car.model : '-',
          kilometrage: offer.car?.kilometrage
            ? offer.car?.kilometrage
            : '-',
          status: getOffersStatusName(offer.status),
          offer: formatNumber(offer.total),
          expiracyDate: formatDate(offer.expiredAt),
        };
      });
      setKarloOffersData(cleanData);
    }
  }, [karloOffers.fromdb]);

  const handleExtraValidation = (formData) => {
    const data = {
      ...formData,
      agencyId: agency.id,
      carId: vehicleId,
    };
    console.log('data: ', data);
    createOffer(data);
    setShowUserModal(false);
  };

  useEffect(() => {
    console.log('vehicleOffers NULL ', vehicleOffers);
    if (vehicleOffers == null) {
      console.log('vehicleOffers NULL ', vehicleOffers);
    } else {
      const filteredCars = vehicleOffers.cars;
      if (Array.isArray(filteredCars)) {
        const cleanData = filteredCars.map((vehicle) => {
          return {
            id: vehicle.idCar,
            kilometrage: vehicle.kilometrage,
            car: vehicle.car.length != 0 ? vehicle.car.model : '-',
            agency:
              vehicle.agency.length != 0 ? vehicle.agency.name : '-',
            data: vehicle,
            // agency:
            //   offer.agency.length != 0 ? offer.agency.name : '-',
            // vehicle: offer.car.length != 0 ? offer.car.model : '-',
            // status: getOffersStatusName(offer.status),
            // offer: formatNumber(offer.total),
            // expiracyDate: formatExpiricyDate(offer.expiredAt),
          };
        });
        setOffersVehiclesData(cleanData);
        console.log('cleanData ', cleanData);
      }
    }
  }, [vehicleOffers]);

  useEffect(() => {
    props.setLeftSideComponent(
      <Tabs
        value={tabSelected}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChangeTab}
      >
        <Tab label="Vehículos" />
        <Tab label="Ofertas en curso" />
      </Tabs>,
    );
  }, [tabSelected]);

  const columnsOne = [
    {
      Header: () => <strong>ID</strong>,
      accessor: 'id',
      width: 50,
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value}
        </div>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Agencia</strong>,
      accessor: 'agency',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value}
        </div>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Vehículo</strong>,
      accessor: 'car',
      width: 250,
      Cell: (row) => (
        <div
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            alignContent: 'center',
            justifyItems: 'center',
            alignSelf: 'center',
          }}
        >
          <ListItemText
            primary={row.value}
            secondary={
              <React.Fragment>
                <Divider />
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {row.original.data.car.brand}
                </Typography>
                {'  -  ' + row.original.data.car.version}
                <div>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {row.original.data.car.year}
                  </Typography>
                </div>
              </React.Fragment>
            }
          />
        </div>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Kilometraje</strong>,
      accessor: 'kilometrage',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value}
        </div>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Precios de referencia</strong>,
      accessor: 'offer',
      width: 250,
      Cell: (row) => (
        <div>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{ height: '24px', width: '24px' }}>
                <AttachMoneyIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              style={{ marginLeft: '-100' }}
              primary="LibroAzul"
              secondary={
                row.original.data.prices.prices != null
                  ? formatNumber(
                      row.original.data.prices?.prices?.LA?.buy,
                    ) +
                    ' - ' +
                    formatNumber(
                      row.original.data.prices?.prices?.LA?.sell,
                    )
                  : null
              }
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{ height: '24px', width: '24px' }}>
                <AttachMoneyIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="MercadoLibre"
              secondary={
                row.original.data.prices.prices != null
                  ? formatNumber(
                      row.original.data.prices?.prices?.ML?.sell,
                    ) +
                    ' - ' +
                    formatNumber(
                      row.original.data.prices?.prices?.ML?.sell +
                        row.original.data.prices?.prices?.ML?.sell *
                          0.15,
                    )
                  : null
              }
            />
          </ListItem>
        </div>
      ),
      filterable: false,
    },
    {
      Header: '',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {/* <Tooltip title="Crear oferta">
            <IconButton
              className={classes.button}
              size="small"
              onClick={() => {
                setShowUserModal(true);
                setVehicleId(row.original.id);
              }}
            >
              <i className="far fa-plus-square"></i>
            </IconButton>
          </Tooltip> */}
          <div>
            <Button
              variant="contained"
              className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-5"
              onClick={() => {
                setShowUserModal(true);
                setVehicleId(row.original.id);
              }}
            >
              Ofertar
              <Add className="kt-margin-l-10" />
            </Button>
          </div>
        </div>
      ),
      filterable: false,
    },
  ];

  const columns = [
    {
      Header: () => <strong>ID</strong>,
      accessor: 'id',
      width: 50,
      Cell: (row) => (
        <Link to={`/offer-detail/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    // {
    //   Header: () => <strong>Usuario</strong>,
    //   accessor: 'name',
    //   width: 300,
    //   Cell: (row) => (
    //     <div
    //       style={{
    //         display: 'flex',
    //         justifyContent: 'center',
    //         alignItems: 'center',
    //       }}
    //     >
    //       <ListItemText
    //         primary={row.value}
    //         secondary={
    //           <React.Fragment>
    //             <Divider />
    //             <Typography
    //               sx={{ display: 'inline' }}
    //               component="span"
    //               variant="body2"
    //               color="text.primary"
    //             >
    //               {'(+52)33-122-3343'}
    //             </Typography>
    //             <div>
    //               <Typography
    //                 sx={{ display: 'inline' }}
    //                 component="span"
    //                 variant="body2"
    //                 color="text.primary"
    //               >
    //                 email@email.com
    //               </Typography>
    //             </div>
    //           </React.Fragment>
    //         }
    //       />
    //     </div>
    //   ),
    //   filterable: false,
    // },
    {
      Header: () => <strong>Vehículo</strong>,
      accessor: 'vehicle',
      width: 250,
      Cell: (row) => (
        <Link to={`/offer-detail/${row.original.id}`}>
          <div
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              alignContent: 'center',
              justifyItems: 'center',
              alignSelf: 'center',
            }}
          >
            <ListItemText
              primary={row.value}
              secondary={
                <React.Fragment>
                  <Divider />
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    Placas
                  </Typography>
                  {'  -  Versión'}
                  <div>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      VIN
                    </Typography>
                  </div>
                </React.Fragment>
              }
            />
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Agencia</strong>,
      accessor: 'agency',
      Cell: (row) => (
        <Link to={`/offer-detail/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Kilometraje</strong>,
      accessor: 'kilometrage',
      Cell: (row) => (
        <Link to={`/offer-detail/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Oferta</strong>,
      accessor: 'offer',
      Cell: (row) => (
        <Link to={`/offer-detail/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Fecha de Expiración</strong>,
      accessor: 'expiracyDate',
      width: 150,
      Cell: (row) => (
        <Link to={`/offer-detail/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Estatus</strong>,
      accessor: 'status',
      width: 100,
      Cell: (row) => (
        <Link to={`/offer-detail/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <Badge
              className={classes.badges}
              variant={getBadgeColor(row.value)}
            >
              {row.value}
            </Badge>
          </div>
        </Link>
      ),
      filterable: false,
    },
    // {
    //   Header: '',
    //   Cell: (row) => (
    //     <div
    //       style={{
    //         textAlign: 'center',
    //       }}
    //     >
    //       <Tooltip title="Editar oferta">
    //         <IconButton
    //           className={classes.button}
    //           size="small"
    //           onClick={() => {
    //             console.log();
    //           }}
    //         >
    //           <i className="far fa-edit"></i>
    //         </IconButton>
    //       </Tooltip>
    //       <Tooltip title="Borrar oferta">
    //         <IconButton
    //           className={classes.button}
    //           size="small"
    //           onClick={() => {
    //             console.log();
    //           }}
    //         >
    //           <i className="far fa-trash-alt"></i>
    //         </IconButton>
    //       </Tooltip>
    //     </div>
    //   ),
    //   filterable: false,
    // },
  ];

  const handleCloseCreate = () => {
    setShowUserModal(false);
  };

  const renderCreateOfferDialog = () => {
    return (
      <Modal
        open={showUserModal}
        closeModal={handleCloseCreate}
        dialogTitle={'Crear oferta'}
        actionButtonText={'Crear oferta'}
        form={'create-user-form'}
        dialogChildren={
          <form
            id="create-user-form"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(handleExtraValidation)}
          >
            <Grid container spacing={3}>
              <MUIAutocompleteAgencies
                size="big"
                className={classes.agenciesSelect}
                value={agency}
                onChange={(_, newValue) => {
                  setAgency(newValue);
                }}
              />
              <TextField
                required
                name="total"
                label="Precio de oferta"
                type="total"
                variant="outlined"
                // onChange={handleCharactersMail}
                inputRef={register({
                  required: true,
                })}
                className={classes.textField}
                error={Boolean(errors.name)}
              />
            </Grid>
          </form>
        }
      />
    );
  };

  return (
    <div className={classes.root}>
      {renderCreateOfferDialog()}
      {tabSelected == '0' ? (
        <Paper className={classes.paper}>
          <ReactTable
            ref={reactTable}
            NoDataComponent={() => (
              <div className="rt-noData">Sin resultados</div>
            )}
            manual
            onFetchData={handleFetchData}
            data={offersVehiclesData}
            pages={karloOffers.pages}
            columns={columnsOne}
            // filterable
            className="-striped -highlight"
            previousText="Anterior"
            nextText="Siguiente"
            loading={karloOffers.isLoading}
            loadingText="Cargando datos..."
          />
        </Paper>
      ) : (
        <Paper className={classes.paper}>
          <ReactTable
            ref={reactTable}
            NoDataComponent={() => (
              <div className="rt-noData">Sin resultados</div>
            )}
            manual
            onFetchData={handleFetchData}
            data={karloOffersData}
            pages={karloOffers.tablePages}
            columns={columns}
            // filterable
            className="-striped -highlight"
            previousText="Anterior"
            nextText="Siguiente"
            loading={karloOffers.isLoading}
            loadingText="Cargando datos..."
          />
        </Paper>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  karloOffers: makeSelectKarloOffers(state),
  vehicleOffers: makeSelectKarloVehiclesOffers(state),
});

const mapDispatchToProps = {
  getKarloOffers,
  getOfferVehicles,
  createOffer,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(KarloOffersTable),
);
