import { Col, Row } from 'react-bootstrap';
import {
  FormControl,
  Grid,
  Button,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { useState, useEffect, Fragment, useRef } from 'react';
import NumberFormatCustom from '../../../../../../components/NumberFormatCustom';
import { formatNumber, getNested } from '../../../../../../utils';
import { connect } from 'react-redux';
import { getAgency } from 'app/features/agencies/agenciesSlice';
/* import { makeSelectAgencyDetailsFromState } from '../../../../../agencies/selectors';
import { makeSelectUserRoleFromState } from 'app/features/auth/selectors'; */
import { useDispatch, useSelector } from 'react-redux';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import {
  selectDataCalc,
  makeSelectcalculatorFromState,
} from 'app/features/calculator/containers/CalculatorV2/selector';
import { getCalculatorData } from 'app/features/calculator/containers/CalculatorV2/calculatorv2Slice';
import { injectIntl } from 'react-intl';
import MUIAutocompleteAgencies from 'app/components/MUIAutocompleteAgencies';
import Checkbox from '@material-ui/core/Checkbox';
import {
  makeSelectUserRoleFromState,
  selectAuth,
} from '../../../../../auth/selectors';
const useStyles = makeStyles((theme) => ({
  container: {
    flexWrap: 'wrap',
  },
  submitButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
  paper: {
    padding: theme.spacing(7, 10, 1, 10),
  },
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  client: {
    padding: theme.spacing(0),
    marginBottom: theme.spacing(2),
  },
  textField: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  media: {
    height: 60,
    width: '100%',
    position: 'relative',
  },
  image: {
    height: '100%',
    width: 'auto',
    position: 'absolute',
    top: '10%',
    left: '45%',
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  subtitle: {
    marginBottom: theme.spacing(4.5),
  },
  subTotals: {
    margin: theme.spacing(4, 0),
  },
  totals: {
    margin: theme.spacing(3, 0),
  },
  conditions: {
    margin: theme.spacing(6, 0, 0, 0),
  },
}));

function Calculatorv2(props) {
  const { fromdb, dataCalc, role, auth } = props;
  const classes = useStyles();
  const [amount, setAmount] = useState('');
  const [promotion, setPromotion] = useState('');
  const [months, setMonths] = useState([
    {
      caso: 'one',
      etiquetaPago: 'Pago de contado',
      cardDc: 'credit',
      meses: 0,
      conPromocion: false,
      etiquetaPromocion: '',
      porcentajeComision: '-',
      porcentajeSobretasa: 0,
      comisiones: {
        comissionBase: 0,
        comissionIvaBase: 4.32,
        comissionTotalBase: 0,
      },
      sobreTasas: {
        sobreTasa: 0,
        sobreTasaIva: 0,
        sobreTasaTotal: 0,
      },
      montoOrden: '-',
      totalComisionPorUsoTerminal: 0,
      totalComisionPorDiferirAMeses: 0,
      totalComisionCliente: 0,
      totalComisionAgencia: '-',
      totalDepositoAgencia: '-',
      totalPagoPorMes: '-',
      totalPagar: '-',
    },
  ]);
  const [paymentType, setPaymentType] = useState('');
  const [cardType, setCardType] = useState('');
  const [reference, setReference] = useState('');
  const [agencySelected, setAgencySelected] = useState('');
  const [promotions, setPromotions] = useState(null);
  const [type, setType] = useState('ods');
  const [isSale, setIsSale] = useState(false);

  const placementLabel = useRef(null);
  const paymentTypeLabel = useRef(null);
  const [paymentTypeWidth, setPaymentTypeWidth] = useState(0);
  const [cardTypeWidth, setCardTypeWidth] = useState(0);
  const [placementWidth, setPlacementWidth] = useState(0);
  const [promotionWidth, setPromotionWidth] = useState(0);
  const cardTypeLabel = useRef(null);
  const promotionLabel = useRef(null);
  const [placement, setPlacement] = useState(0);
  const calculator = useSelector((state) => state.calculatorData);

  const [consultado, setConsultado] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    setPaymentTypeWidth(paymentTypeLabel.current.offsetWidth);
  }, []);

  useEffect(() => {
    if (fromdb && consultado) {
      if (fromdb?.calculatorData?.preCalculated) {
        setMonths(fromdb?.calculatorData?.preCalculated);
      }
    }
  }, [fromdb]);
  useEffect(() => {
    switch (paymentType) {
      case 1:
        setCardTypeWidth(cardTypeLabel.current.offsetWidth);
        break;
      case 2:
        setCardTypeWidth(cardTypeLabel.current.offsetWidth);
        setPromotionWidth(promotionLabel.current.offsetWidth);
        break;

      default:
        break;
    }
  }, [paymentType]);
  useEffect(() => {
    setAgencySelected(
      role !== 'Super Admin' ? auth?.user?.agencyID : agencySelected,
    );
    if (
      cardType &&
      paymentType &&
      amount &&
      reference &&
      agencySelected &&
      (promotions != null || paymentType === 1)
    ) {
      const cardDc = paymentType === 2 ? 'credit' : '';
      const isSubTypeSale = isSale ? 'sales' : '';
      let data = {
        agencyID: agencySelected,
        months: 0,
        cardType: cardType,
        cardDC: cardDc,
        amount: amount,
        type: type,
        reference: reference,
        withPromotions: promotions,
        subType: isSubTypeSale,
      };
      setConsultado(true);
      dispatch(getCalculatorData(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cardType,
    paymentType,
    amount,
    reference,
    promotions,
    agencySelected,
    isSale,
    type,
  ]);

  const renderSelectCard = () => {
    return (
      <Grid item xs>
        <FormControl
          variant="outlined"
          fullWidth
          className={classes.formControl}
        >
          <InputLabel ref={cardTypeLabel} htmlFor="card-type">
            Tipo de tarjeta
          </InputLabel>
          <Select
            value={cardType}
            onChange={({ target }) => {
              setCardType(target.value);
            }}
            input={
              <OutlinedInput
                labelWidth={cardTypeWidth}
                id="card-type"
              />
            }
          >
            <MenuItem value={0}>Seleccione una opción</MenuItem>
            <MenuItem value={'mastercard'}>Visa/Mastercard</MenuItem>
            <MenuItem value={'american-exprees'}>Amex</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const handleRenderPlacements = () => {
    switch (paymentType) {
      case 1:
        return renderSelectCard();
      case 2:
        return (
          <>
            <Grid container spacing={4}>
              {renderSelectCard()}
              <Grid item xs>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                >
                  <InputLabel
                    ref={promotionLabel}
                    htmlFor="promotion"
                  >
                    Con promoción
                  </InputLabel>
                  <Select
                    value={promotion}
                    onChange={({ target }) => {
                      setPromotion(target.value);
                      setPromotions(target.value);
                    }}
                    input={
                      <OutlinedInput
                        labelWidth={promotionWidth}
                        name="Plazo"
                        id="promotion"
                      />
                    }
                  >
                    <MenuItem value={0}>
                      Seleccione una opción
                    </MenuItem>
                    <MenuItem value={true}>Con promoción</MenuItem>
                    <MenuItem value={false}>Sin promoción</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </>
        );
      default:
        return null;
    }
  };
  const renderComissions = () => {
    return months.map((currentValue, key) => {
      return (
        <Grid key={`comission-${`${key} meses`}`}>
          <Grid container spacing={2}>
            <Grid item xs>
              <Typography variant="subtitle1">{`${currentValue.meses} meses`}</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="subtitle1">
                {formatNumber(
                  currentValue.comisiones.comissionTotalBase,
                )}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="subtitle1">
                {formatNumber(currentValue.totalPagoPorMes)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="subtitle1">
                {formatNumber(currentValue.totalPagar)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="subtitle1">
                {currentValue?.porcentajeComision * 100 || 0}%
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="subtitle1">
                {formatNumber(currentValue.totalDepositoAgencia)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      );
    });
  };
  const handleChange = (event) => {
    setIsSale(event.target.checked);
  };

  return (
    <Row>
      <Col lg="12" md="8">
        <Paper className={classes.paper}>
          <form
            className={classes.container}
            noValidate
            autoComplete="off"
          >
            <div className="input-container">
              <Grid xs={12} item className={classes.subtitle}>
                <Grid container spacing={2}>
                  <Grid item xs>
                    <Typography variant="h6">
                      Calculadora de pagos
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <FormLabel component="legend">
                      Tipo de orden
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="gender"
                      name="type"
                      value={type}
                      onChange={({ target }) => setType(target.value)}
                    >
                      <FormControlLabel
                        value="ods"
                        control={<Radio color="primary" />}
                        label="Orden de Servicio"
                      />
                      <FormControlLabel
                        value="odp"
                        control={<Radio color="primary" />}
                        label="Orden de Pago"
                      />
                    </RadioGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isSale}
                          onChange={handleChange}
                          name="checkedVenta"
                          color="primary"
                        />
                      }
                      label="Es Venta"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs>
                  <TextField
                    required
                    fullWidth
                    type="text"
                    label="Cantidad total"
                    variant="outlined"
                    value={amount}
                    onChange={({ target }) => setAmount(target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          $
                        </InputAdornment>
                      ),
                      inputComponent: NumberFormatCustom,
                      inputProps: { quantity: true },
                    }}
                    className={classes.textField}
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    fullWidth
                    type="text"
                    label="Referencia"
                    variant="outlined"
                    value={reference}
                    onChange={({ target }) =>
                      setReference(target.value)
                    }
                    className={classes.textField}
                  />
                </Grid>
                <Grid item xs>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <InputLabel
                      ref={paymentTypeLabel}
                      htmlFor="paymentType"
                    >
                      Tipo de pago
                    </InputLabel>
                    <Select
                      value={paymentType}
                      onChange={({ target }) => {
                        setPaymentType(target.value);
                      }}
                      input={
                        <OutlinedInput
                          labelWidth={paymentTypeWidth}
                          name="Tipo de pago"
                          id="paymentType"
                        />
                      }
                    >
                      <MenuItem value={0}>
                        Seleccione una opción
                      </MenuItem>
                      <MenuItem value={1}>
                        Una sola excibición
                      </MenuItem>
                      <MenuItem value={2}>Pago a meses</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {role === 'Super Admin' && (
                  <Grid item xs={6} md={3}>
                    <MUIAutocompleteAgencies
                      textLabel="Agencia default"
                      value={agencySelected}
                      onChange={(_, newValue) => {
                        setAgencySelected(newValue?.id);
                      }}
                    />
                  </Grid>
                )}
              </Grid>
              {handleRenderPlacements()}
              <Grid className={classes.subTotals}>
                <Grid item xs className={classes.monthsPayment}>
                  <Typography variant="h6">Pago a meses</Typography>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs></Grid>
                  <Grid item xs>
                    <Typography
                      variant="subtitle1"
                      className={classes.bold}
                    >
                      Comisión
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography
                      variant="subtitle1"
                      className={classes.bold}
                    >
                      Pago mensual
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography
                      variant="subtitle1"
                      className={classes.bold}
                    >
                      Total a pagar
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography
                      variant="subtitle1"
                      className={classes.bold}
                    >
                      Porcentaje comisión (IVA incluido)
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography
                      variant="subtitle1"
                      className={classes.bold}
                    >
                      Deposito total a tu cuenta
                    </Typography>
                  </Grid>
                </Grid>
                {renderComissions()}
              </Grid>
            </div>
          </form>
        </Paper>
      </Col>
    </Row>
  );
}
const mapStateToProps = (state) => ({
  dataCalc: selectDataCalc(state),
  fromdb: makeSelectcalculatorFromState(state),
  role: makeSelectUserRoleFromState(state),
  auth: selectAuth(state),
});

const mapDispatchToProps = {
  getCalculatorData,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(Calculatorv2),
);
