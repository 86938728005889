import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    height: '100%',
  },
  karloLogo: {
    width: '150px',
    height: 'auto',
    display: 'block',
    marginTop: '50px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '30px',
  },
  cardContainer: {
    alignSelf: 'center',
    backgroundColor: 'white',
    elevation: 12,
    marginTop: theme.spacing(6),
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: 12,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  imageContainer: {
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    width: '50%',
  },
  errorTitle: {
    marginTop: '20px',
  },
  errorText: {
    marginTop: '20px',
    marginButton: '20px',
  },
}));
