import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import React from 'react';

function NumberFormatCustom(props) {
  const {
    inputRef,
    onChange,
    format,
    quantity,
    cardType,
    ...other
  } = props;
  const formats = {
    mastercard: '#### #### #### ####',
    visa: '#### #### #### ####',
    'american-express': '#### ###### #####',
  };

  const handleFormat = () => {
    if (format) return format;
    else if (cardType) return formats[cardType];
  };

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      isNumericString
      thousandSeparator={quantity}
      format={handleFormat()}
    />
  );
}

NumberFormatCustom.propTypes = {
  quantity: PropTypes.bool,
  cardType: PropTypes.string,
  format: PropTypes.string,
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};
export default NumberFormatCustom;
