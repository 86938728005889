const MenuConfig = {
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    'karlo lite': {
      'Agency Admin': {
        main: 1,
        routes: [
          'dashboard',
          'users',
          'paymentorders',
          'paymentsclear',
          'setup',
          'deposits',
          'kioskoodp',
          'calculator2023',
          // 'calculator',
          // 'advisorcal',
          // 'promotionsCalculator',
          // 'calculatorTPV',
          'chargebacks',
        ],
      },
      'Super Admin Agencies': {
        main: 0,
        routes: [
          'dashboard',
          'reviews',
          'chargebacks',
          'users',
          'paymentorders',
          'paymentsclear',
          'calculator2023',
          'setup',
          'deposits',
          'kiosko',
          // 'calculator',
          // 'advisorcal',
        ],
      },
      'Agency Manager': {
        main: 1,
        routes: [
          'dashboard',
          'reviews',
          'users',
          'chargebacks',
          'paymentorders',
          'paymentsclear',
          'setup',
          'deposits',
          'kiosko',
          'calculator2023',
          // 'calculator',
          // 'advisorcal',
        ],
      },
      'Service Manager': {
        main: 0,
        routes: [
          'dashboard',
          'reviews',
          'users',
          'paymentorders',
          'paymentsclear',
          'kioskoodp',
          'calculator2023',
          // 'calculator',
          // 'advisorcal',
          // 'promotionsCalculator',
          // 'calculatorTPV',
          'chargebacks',
        ],
      },
      'Service Asesor': {
        main: 0,
        routes: [
          'paymentorders',
          'paymentsclear',
          'kioskoodp',
          'calculator2023',
          // 'advisorcal',
        ],
      },
      'Marketing Agent': {
        main: 0,
        routes: [
          'dashboard',
          'setup',
          'reviews',
          'paymentorders',
          'serviceorders',
          'users',
          'calculator2023',
          // 'advisorcal'
        ],
      },
      Accounting: {
        main: 0,
        routes: [
          'paymentorders',
          'serviceorders',
          'paymentsclear',
          'deposits',
          'kioskoodp',
          'calculator2023',
          // 'calculator',
          // 'advisorcal',
          'chargebacks',
        ],
      },
      'Sales Advisor': {
        main: 0,
        routes: [
          'paymentorders',
          'paymentsclear',
          'kioskoodp',
          // 'advisorcal',
          'calculator2023',
          // 'promotionsCalculator',
          // 'calculatorTPV',
        ],
      },
      'Sales Manager': {
        main: 0,
        routes: [
          'paymentorders',
          'paymentsclear',
          'kioskoodp',
          'calculator2023',
          // 'advisorcal',
          // 'promotionsCalculator',
          // 'calculatorTPV',
        ],
      },
      Cashier: {
        main: 0,
        routes: [
          'paymentorders',
          'serviceorders',
          'paymentsclear',
          'deposits',
          'kioskoodp',
          // 'advisorcal',
          'chargebacks',
          'calculator2023',
          // 'calculatorTPV',
          // 'standAlone',
        ],
      },
      'Parts Asesor': {
        main: 0,
        routes: [
          'paymentorders',
          'paymentsclear',
          'kioskoodp',
          'calculator2023',
          // 'advisorcal',
        ],
      },
      'Parts Manager': {
        main: 0,
        routes: [
          'paymentorders',
          'paymentsclear',
          'kioskoodp',
          'calculator2023',
          // 'calculator',
          // 'advisorcal',
        ],
      },
    },
    WFT: {
      /* prettier-ignore*/
      'Agency Admin': {
        main: 2,
        routes: [
          'dashboard',
          'reviews',
          'reservations',
          'paymentorders',
          // 'paymentsclear',
          'setup',
          'chargebacks',
          'calculator2023',
          // 'deposits',
          // 'kioskoodp',
          // 'calculator',
          // 'advisorcal',
          //  'promotionsCalculator',
        ],
      },
    },
    ABG: {
      'Agency Admin': {
        main: 1,
        routes: [
          // 'dashboard',
          'users',
          'reviews',
          'paymentorders',
          'paymentsclear',
          'setup',
          'deposits',
          'kioskoodp',
          'calculator2023',
          // 'calculator',
          // 'advisorcal',
          // 'promotionsCalculator',
          // 'calculatorTPV',
          'chargebacks',
        ],
      },
      'ABG Agency Admin': {
        main: 1,
        routes: [
          // 'dashboard',
          'users',
          'reviews',
          'paymentorders',
          'paymentsclear',
          'setup',
          'deposits',
          'kioskoodp',
          'calculator2023',
          // 'calculator',
          // 'advisorcal',
          // 'promotionsCalculator',
          // 'calculatorTPV',
          'chargebacks',
        ],
      },
      'ABG Service Manager': {
        main: 1,
        routes: [
          'reviews',
          'users',
          'paymentorders',
          'paymentsclear',
          'kioskoodp',
          'calculator2023',
          // 'calculator',
          // 'advisorcal',
          // 'promotionsCalculator',
          // 'calculatorTPV',
          'chargebacks',
        ],
      },
      'ABG Asesor': {
        main: 0,
        routes: [
          'paymentorders',
          'paymentsclear',
          'kioskoodp',
          'calculator2023',
          // 'advisorcal',
        ],
      },
      'ABG Asesor PreOwned': {
        main: 0,
        routes: [
          'paymentorders',
          'paymentsclear',
          'kioskoodp',
          'calculator2023',
          // 'advisorcal',
        ],
      },
      'ABG Sales Manager': {
        main: 0,
        routes: [
          'paymentorders',
          'paymentsclear',
          'kioskoodp',
          'calculator2023',
          // 'advisorcal',
          // 'promotionsCalculator',
          // 'calculatorTPV',
        ],
      },
      'ABG Sales Advisor': {
        main: 0,
        routes: [
          'paymentorders',
          'paymentsclear',
          'kioskoodp',
          'calculator2023',
          // 'advisorcal',
          // 'promotionsCalculator',
          // 'calculatorTPV',
        ],
      },
      'ABG Accounting': {
        main: 1,
        routes: [
          'paymentorders',
          'paymentsclear',
          'serviceorders',
          'deposits',
          'kioskoodp',
          'calculator2023',
          // 'calculator',
          // 'advisorcal',
        ],
      },
      'ABG Cashier': {
        main: 1,
        routes: [
          'paymentorders',
          'paymentsclear',
          'deposits',
          'serviceorders',
          'kioskoodp',
          'calculator2023',
          // 'advisorcal',
          // 'calculatorTPV',
          // 'standAlone',
        ],
      },
      'ABG Parts Manager': {
        main: 0,
        routes: [
          'paymentorders',
          'paymentsclear',
          'kioskoodp',
          'calculator2023',
          // 'calculator',
          // 'advisorcal',
        ],
      },
      'ABG Parts Asesor': {
        main: 1,
        routes: [
          'paymentorders',
          'paymentsclear',
          'kioskoodp',
          'calculator2023',
          // 'advisorcal',
        ],
      },
    },
    'Super Admin': {
      main: 2,
      routes: [
        'dashboard',
        'agencies',
        'reviews',
        'users',
        'chargebacks',
        'serviceorders',
        'paymentorders',
        'creditorders',
        'advances',
        'payments',
        'dispersions',
        'paymentssearch',
        'speipayments',
        'deposits',
        'support',
        'creditpayments',
        'calculator2023',
        // 'calculator',
        // 'Calculatorv2',
        // 'advisorcal',
        // 'promotionsCalculator',
        // 'calculatorTPV',
        'terminals',
        'dividerKarloApp',
        'karlousers',
        'karlovehicles',
        'advices',
        'karloappPromotions',
        'karloappQuotes',
        'offers',
        'serviceappointments',
        'dividerWTF',
        'reservations',
        'reservationsPayments',
        'faqsSuperAdmin',
      ],
    },
    'Super Admin Lite': {
      main: 2,
      routes: [
        'agencies',
        'reviews',
        // 'reservations',
        'users',
        'serviceorders',
        'paymentorders',
        'creditorders',
        // 'advances',
        'payments',
        'creditpayments',
        'calculator2023',
        // 'calculator',
        // 'advisorcal',
        'terminals',
        'dividerKarloApp',
        'karlousers',
        'karlovehicles',
        'advices',
        'karloappPromotions',
        'karloappQuotes',
        'offers',
        'serviceappointments',
      ],
    },
    'Agency Admin': {
      main: 1,
      routes: [
        'dashboard',
        'reviews',
        'users',
        'serviceorders',
        'chargebacks',
        'paymentorders',
        // 'advances',
        'payments',
        'setup',
        'deposits',
        'kiosko',
        // 'calculator',
        'calculator2023',
        // 'advisorcal',
        // 'promotionsCalculator',
        // 'calculatorTPV',
        // 'Calculatorv2',
        // 'dividerKarloApp',
        // 'karlousers',
        // 'karlovehicles',
        // 'offers',
        // 'serviceappointments',
      ],
    },
    'Super Admin Agencies': {
      main: 1,
      routes: [
        'dashboard',
        'reviews',
        // 'reservations',
        'chargebacks',
        'users',
        'serviceorders',
        'paymentorders',
        // 'advances',
        'payments',
        'setup',
        'deposits',
        'kiosko',
        'calculator2023',
        // 'calculator',
        // 'advisorcal',
      ],
    },
    'Agency Manager': {
      main: 1,
      routes: [
        'dashboard',
        'reviews',
        // 'reservations',
        'users',
        'chargebacks',
        'serviceorders',
        'paymentorders',
        // 'advances',
        'payments',
        'setup',
        'deposits',
        'kiosko',
        'calculator2023',
        // 'calculator',
        // 'advisorcal',
      ],
    },
    'Super Admin WTF': {
      main: 1,
      routes: [
        // 'dashboard',
        'reservations',
        'users',
        'deposits',
        'setup',
        // 'payments',
        'reservationsPayments',
        'chargebacks',
      ],
    },
    'WTF Asesor': {
      main: 0,
      routes: ['reservations', 'reservationsPayments'],
    },
    'Credit Administrator': {
      main: 1,
      routes: [
        'users',
        'creditorders',
        'calculator2023',
        'creditpayments',
        // 'advisorcal',
      ],
    },
    'Clear Admin': {
      main: 1,
      routes: [
        'users',
        'paymentorders',
        'paymentsclear',
        'setup',
        'kioskoodp',
        'calculator2023',
        // 'calculator',
        // 'advisorcal',
      ],
    },
    'Customer Success': {
      main: 0,
      routes: [
        'paymentorders',
        'paymentsclear',
        'calculator2023',
        // 'calculator',
        // 'advisorcal',
      ],
    },
    'Service Asesor': {
      main: 0,
      routes: [
        'serviceorders',
        'paymentorders',
        // 'advances',
        'payments',
        'calculator2023',
        'kiosko',
        // 'advisorcal',
      ],
    },
    'f&i': {
      main: 0,
      routes: [
        'serviceorders',
        'paymentorders',
        // 'advances',
        'payments',
        'kiosko',
        'calculator2023',
        // 'advisorcal',
        // 'promotionsCalculator',
        // 'calculatorTPV',
      ],
    },
    'Credit Analyst': {
      main: 0,
      routes: [
        'creditorders',
        'creditpayments',
        'calculator2023',
        // 'advisorcal'
      ],
    },
    'Marketing Agent': {
      main: 0,
      routes: [
        'dashboard',
        'setup',
        'reviews',
        'paymentorders',
        'serviceorders',
        'users',
        'calculator2023',
      ],
    },
    'Service Manager': {
      main: 0,
      routes: [
        'dashboard',
        'reviews',
        'users',
        'serviceorders',
        'paymentorders',
        // 'advances',
        'payments',
        'kiosko',
        'calculator2023',
        // 'calculator',
        // 'advisorcal',
        // 'promotionsCalculator',
        // 'calculatorTPV',
        'chargebacks',
      ],
    },
    'ABG Service Manager': {
      main: 1,
      routes: [
        'reviews',
        'users',
        'serviceorders',
        'paymentorders',
        // 'advances',
        'payments',
        'kiosko',
        'calculator2023',
        // 'calculator',
        // 'advisorcal',
        // 'promotionsCalculator',
        // 'calculatorTPV',
        'chargebacks',
      ],
    },
    Accounting: {
      main: 0,
      routes: [
        'paymentorders',
        'payments',
        'serviceorders',
        'deposits',
        'kiosko',
        'calculator2023',
        // 'calculator',
        // 'advisorcal',
        'chargebacks',
      ],
    },
    'Sales Advisor': {
      main: 0,
      routes: [
        'paymentorders',
        'paymentsclear',
        'kiosko',
        'calculator2023',
        // 'advisorcal',
        // 'promotionsCalculator',
        // 'calculatorTPV',
      ],
    },
    'Sales Manager': {
      main: 0,
      routes: [
        'paymentorders',
        'payments',
        'kiosko',
        'calculator2023',
        // 'advisorcal',
        // 'promotionsCalculator',
        // 'calculatorTPV',
      ],
    },
    'ABG Sales Manager': {
      main: 0,
      routes: [
        'paymentorders',
        'payments',
        'kiosko',
        'calculator2023',
        // 'advisorcal',
        // 'promotionsCalculator',
        // 'calculatorTPV',
      ],
    },
    Cashier: {
      main: 0,
      routes: [
        'paymentorders',
        'serviceorders',
        'payments',
        'deposits',
        'kiosko',
        // 'advisorcal',
        'chargebacks',
        'calculator2023',
        // 'calculatorTPV',
        // 'standAlone',
      ],
    },
    'Parts Asesor': {
      main: 0,
      routes: [
        'paymentorders',
        // 'advances',
        'paymentsclear',
        'kioskoodp',
        'calculator2023',
        // 'advisorcal',
      ],
    },
    'Parts Manager': {
      main: 0,
      routes: [
        'paymentorders',
        // 'advances',
        'paymentsclear',
        // 'advances',
        'kiosko',
        'calculator2023',
        // 'calculator',
        // 'advisorcal',
      ],
    },
    'ABG Parts Manager': {
      main: 0,
      routes: [
        'paymentorders',
        // 'advances',
        'paymentsclear',
        // 'advances',
        'kiosko',
        'calculator2023',
        // 'calculator',
        // 'advisorcal',
      ],
    },
    'W32 Agency Admin': {
      main: 1,
      routes: [
        // 'dashboard',
        'users',
        'reviews',
        'paymentorders',
        'paymentsclear',
        'setup',
        'deposits',
        'kioskoodp',
        'calculator2023',
        // 'calculator',
        // 'advisorcal',
        // 'promotionsCalculator',
        // 'calculatorTPV',
        'chargebacks',
      ],
    },
    'W32 Asesor': {
      main: 0,
      routes: [
        'paymentorders',
        'paymentsclear',
        'kioskoodp',
        'calculator2023',
        // 'advisorcal',
      ],
    },
    'W32 Accounting': {
      main: 0,
      routes: [
        'paymentorders',
        'paymentsclear',
        'serviceorders',
        'deposits',
        'kioskoodp',
        'calculator2023',
        // 'calculator',
        // 'advisorcal',
      ],
    },
    'W32 Parts Asesor': {
      main: 0,
      routes: [
        'paymentorders',
        'paymentsclear',
        'kioskoodp',
        'calculator2023',
        // 'advisorcal',
      ],
    },
    'Payments Searcher': {
      main: 0,
      routes: [
        'paymentssearch',
        'calculator2023',
        // 'advisorcal',
        'payments',
        'deposits',
        'support',
      ],
    },
    'ABG Asesor': {
      main: 0,
      routes: [
        'paymentorders',
        'paymentsclear',
        'kioskoodp',
        'calculator2023',
        // 'advisorcal',
      ],
    },
  },
  sections: {
    agencies: {
      title: 'Agencias',
      root: true,
      icon: 'far fa-building',
      page: 'agencies',
      bullet: 'dot',
    },
    reviews: {
      title: 'Evaluaciones',
      root: true,
      icon: 'fas fa-star-half-alt',
      page: 'reviews',
      bullet: 'dot',
    },
    dashboard: {
      title: 'Dashboard',
      root: true,
      icon: 'far fa-building',
      page: 'dashboard',
      bullet: 'dot',
    },
    users: {
      title: 'Usuarios',
      root: true,
      icon: 'flaticon2-user-outline-symbol',
      page: 'users',
      bullet: 'dot',
    },
    terminals: {
      title: 'Terminales Punto de Venta',
      root: true,
      icon: 'fas fa-cash-register',
      page: 'terminals',
      bullet: 'dot',
    },
    calculator2023: {
      title: 'Calculadora',
      root: true,
      icon: 'fas fa-calculator',
      page: 'calculator2023',
      bullet: 'dot',
    },
    karlousers: {
      title: 'Usuarios KarloApp',
      root: true,
      icon: 'flaticon2-user-outline-symbol',
      page: 'karlousers',
      bullet: 'dot',
    },
    karlovehicles: {
      title: 'Vehículos KarloApp',
      root: true,
      icon: 'fas fa-car',
      page: 'karlovehicles',
      bullet: 'dot',
    },
    chargebacks: {
      title: 'Contracargos',
      root: true,
      icon: 'fas fa-search-dollar',
      page: 'chargebacks',
      bullet: 'dot',
    },
    chargebacksWTF: {
      title: 'Contracargos WTF',
      root: true,
      icon: 'fas fa-search-dollar',
      page: 'chargebackswtf',
      bullet: 'dot',
    },
    reservations: {
      title: 'Reservaciones',
      root: true,
      icon: 'fas fa-book',
      bullet: 'dot',
      submenu: [
        {
          title: 'Reservaciones Cruceros',
          page: 'reservations-cruise',
        },
        {
          title: 'Reservaciones',
          page: 'reservations',
        },
      ],
    },
    reservationsPayments: {
      title: 'Pagos WTF',
      root: true,
      icon: 'fas fa-file-invoice-dollar',
      page: 'payments-reservation',
      bullet: 'dot',
    },
    vehicles: {
      title: 'Vehículos',
      root: true,
      icon: 'fas fa-car',
      page: 'karlovehicles',
      bullet: 'dot',
    },
    serviceappointments: {
      title: 'Citas de Servicio ',
      root: true,
      icon: 'fas fa-calendar-alt',
      page: 'serviceappointments',
      bullet: 'dot',
    },
    advices: {
      title: 'Consejos KarloApp',
      root: true,
      icon: 'fas fa-calendar-check',
      page: 'advices',
      bullet: 'dot',
    },
    karloappPromotions: {
      title: 'Promociones KarloApp',
      root: true,
      icon: 'fas fa-gifts',
      page: 'karloPromotions',
      bullet: 'dot',
    },
    karloappQuotes: {
      title: 'Polizas KarloApp',
      root: true,
      icon: 'fas fa-store-alt',
      page: 'karloQuotes',
      bullet: 'dot',
    },
    offers: {
      title: 'Ofertas KarloApp',
      root: true,
      icon: 'fas fa-car-side',
      page: 'offers',
      bullet: 'dot',
    },
    serviceorders: {
      title: 'Órdenes de Servicio',
      root: true,
      icon: 'far fa-file-alt',
      page: 'serviceorders',
      bullet: 'dot',
    },
    advances: {
      title: 'Anticipos Refacciones',
      root: true,
      icon: 'fas fa-file-invoice-dollar',
      page: 'advances',
      bullet: 'dot',
    },
    paymentorders: {
      title: 'Órdenes de Pago',
      root: true,
      icon: 'fas fa-file-invoice-dollar',
      page: 'paymentorders',
      bullet: 'dot',
    },
    creditorders: {
      title: 'Órdenes de Crédito',
      root: true,
      icon: 'far fa-file-alt',
      page: 'creditorders',
      bullet: 'dot',
    },
    creditpayments: {
      title: 'Pagos de crédito',
      root: true,
      icon: 'far fa-credit-card',
      page: 'creditpayments',
      bullet: 'dot',
    },
    paymentsclear: {
      title: 'Pagos',
      root: true,
      icon: 'far fa-credit-card',
      page: 'paymentsclear',
      bullet: 'dot',
    },
    payments: {
      title: 'Pagos',
      root: true,
      icon: 'far fa-credit-card',
      page: 'payments',
      bullet: 'dot',
    },
    paymentssearch: {
      title: 'Búsqueda de pagos',
      root: true,
      icon: 'far fa-credit-card',
      page: 'paymentssearch',
      bullet: 'dot',
    },
    speipayments: {
      title: 'Pagos SPEI',
      root: true,
      icon: 'fas fa-cash-register',
      page: 'speipayments',
      bullet: 'dot',
    },
    deposits: {
      title: 'Depósitos',
      root: true,
      icon: 'fas fa-hand-holding-usd',
      page: 'deposits',
      bullet: 'dot',
    },
    support: {
      title: 'Soporte',
      root: true,
      icon: 'fas fa-headset',
      page: 'support',
      bullet: 'dot',
    },
    calculator: {
      title: 'Calculadora',
      root: true,
      icon: 'fas fa-calculator',
      page: 'calculator',
      bullet: 'dot',
    },
    Calculatorv2: {
      title: 'Calculadora v2',
      root: true,
      icon: 'fas fa-calculator',
      page: 'calculator-v2',
      bullet: 'dot',
    },
    advisorcal: {
      title: 'Calculadora meses',
      root: true,
      icon: 'fas fa-calculator',
      page: 'advisorcal',
      bullet: 'dot',
    },
    promotionsCalculator: {
      title: 'Calculadora de venta',
      root: true,
      icon: 'fas fa-tag',
      page: 'promotions-calculator',
      bullet: 'dot',
    },
    calculatorTPV: {
      title: 'Calculadora TPV',
      root: true,
      icon: 'fas fa-calculator',
      page: 'calculator-tpv',
      bullet: 'dot',
    },
    setup: {
      title: 'Setup',
      root: true,
      icon: 'fas fa-laptop-code',
      page: 'setup',
      bullet: 'dot',
    },
    kiosko: {
      title: 'Kiosko',
      root: true,
      bullet: 'dot',
      icon: 'fas fa-store',
      submenu: [
        {
          title: 'Kiosko',
          page: 'redirect-to-landing/',
        },
      ],
    },
    kioskoodp: {
      title: 'Kiosko',
      root: true,
      bullet: 'dot',
      icon: 'fas fa-store',
      submenu: [
        {
          title: 'Órdenes de pago',
          page: 'redirect-to-landing/odp',
        },
      ],
    },
    faqsSuperAdmin: {
      title: 'Guía de uso',
      root: true,
      bullet: 'dot',
      icon: 'fas fa-book',
      submenu: [
        {
          title: 'Asesor',
          page: 'guia-de-uso/asesor',
        },
        {
          title: 'Administrador',
          page: 'guia-de-uso/administrador',
        },
      ],
    },
    faqsAsesor: {
      title: 'Guía de uso',
      root: true,
      bullet: 'dot',
      icon: 'fas fa-book',
      submenu: [
        {
          title: 'Asesor',
          page: 'guia-de-uso/asesor',
        },
      ],
    },
    faqsAdmon: {
      title: 'Guía de uso',
      root: true,
      bullet: 'dot',
      icon: 'fas fa-book',
      submenu: [
        {
          title: 'Administrador',
          page: 'guia-de-uso/administrador',
        },
      ],
    },
    notifications: {
      title: 'Notificaciones',
      root: true,
      icon: 'flaticon-paper-plane-1',
      page: 'notifications',
      bullet: 'dot',
    },
    dividerKarloApp: {
      title: 'Karlo App',
    },
    dividerWTF: {
      title: 'WTF',
    },
    dispersions: {
      title: 'Dispersiones SPEI',
      root: true,
      icon: 'fas fa-hand-holding-usd',
      page: 'dispersions',
      bullet: 'dot',
    },
    depositfiserv: {
      title: 'Depósitos Fiserv',
      root: true,
      icon: 'fas fa-hand-holding-usd',
      page: 'depositfiserv',
      bullet: 'dot',
    },
  },
};

export default MenuConfig;
