export const initialState = {
  settingAmountsPerAgency: null,
  ordersType: null,
  calculatorResponse: null,
  errorModal: {
    show: false,
    message: null,
  },
  isLoading: false,
  error: null,
};
