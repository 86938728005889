import 'react-table/react-table.css';
import 'moment/locale/es';

/* eslint-disable react/display-name */
import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Tab,
  Tabs,
  makeStyles,
  IconButton,
  Typography,
} from '@material-ui/core';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import Close from '@material-ui/icons/Close';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { formatNumber } from 'app/utils';
import ButtonImportFile from 'app/components/ButtonImportFile';
import { DepositsCreatedTable } from 'app/features/standAlone/payments/components/DepositsCreatedTable';
import Modal from 'app/components/Modal';
import ShouldItRender from 'app/components/ShouldItRender';
import ButtonExportExcel from 'app/components/ButtonExportExcel';
import { MenuOption } from 'app/components/MenuOption';

import { closeModals } from 'app/features/modals/modalsSlice';
import {
  getAllPaymentsStandAlone,
  getAllDepositsSPEI,
} from 'app/features/standAlone/payments/depositsSlice';
import {
  createDeposit,
  importDepositsFromExcel,
} from '../../depositsSlice';
import {
  makeSelectDepositsFromdbFromState,
  selectDepositsStandAlone,
  makeSelectDepositsPages,
} from 'app/features/standAlone/payments/selectors';
import { makeSelectUserRoleFromState } from 'app/features/auth/selectors';

import { Publish } from '@material-ui/icons';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import MomentUtils from '@date-io/moment';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  buttonImportExcel: {
    marginBottom: theme.spacing(2),
  },
  range: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: '30px',
  },
  datePicker: {
    '@media screen and (max-width: 1496px)': {
      // eslint-disable-line no-useless-computed-key
      width: '70px',
    },
    '@media screen and (max-width: 1024px)': {
      // eslint-disable-line no-useless-computed-key
      width: '90px',
    },
    width: '90px',
  },
  icons: {
    color: '#0abb87',
    marginRight: '10px',
  },
  colorPrimary: {
    color: '#0abb87',
  },
  menuDesktop: {
    '@media screen and (max-width: 1520px)': {
      // eslint-disable-line no-useless-computed-key
      display: 'none',
    },
  },
  menuMobile: {
    '@media screen and (min-width: 1520px)': {
      // eslint-disable-line no-useless-computed-key
      display: 'none',
    },
  },
}));

function PaymentsStandAloneTable(props) {
  const classes = useStyles();
  const {
    deposits,
    depositsFromdb,
    getAllPaymentsStandAlone,
    setLeftSideComponent,
    setActionButton,
    importDepositsFromExcel,
    closeModals,
    location,
    tablePages,
    role,
  } = props;
  const [tabSelected, setTabSelected] = useState(0);
  const [showImportDespositsModal, setShowImportDespositsModal] =
    useState(false);
  const [excelFile, setExcelFile] = useState(null);
  const [paymentsData, setPaymentsData] = useState([]);
  const [nameFile, setNameFile] = useState('Depósitos');
  const reactTable = useRef(null);
  const [firstDate, setFirstDate] = useState(null);
  const [secondDate, setSecondDate] = useState(null);

  useEffect(() => {
    setLeftSideComponent(
      <Tabs
        value={tabSelected}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Pagos creados" />
      </Tabs>,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabSelected]);

  useEffect(() => {
    let csv = [];
    const rootData =
      deposits?.exportData?.length > 0
        ? deposits?.exportData
        : depositsFromdb;
    switch (tabSelected) {
      case 0:
        if (rootData) {
          setNameFile('Depósitos_creados');
          rootData.forEach((row) => {
            const data = {
              // prettier-ignore
              'ID': row.id || '-',
              // prettier-ignore
              'Agencia': row.agencyName || row.agency?.name || '-',
              'Cuenta destino': row.finalDestinationAccount || '-',
              // prettier-ignore
              'Depositante': row.depositor || '-',
              'Fecha de depósito': row.depositDate
                ? moment(row.depositDate).utc().format('DD/MM/YYYY')
                : '-',
              'Suma de comisiones':
                formatNumber(row.totalCommissions) || '0',
              'Suma de IVA': formatNumber(
                row.totalIvaCommissions || 0,
              ),
              'Total depositado': formatNumber(
                row.totalToDeposit || 0,
              ),
              'Total Pagos': formatNumber(row.totalPayments || 0),
            };
            csv.push(data);
          });
        }
        break;
      default:
        break;
    }
    setPaymentsData(csv);
  }, [depositsFromdb]);

  const handleFirstDate = (date) => {
    setFirstDate(date);
  };

  const handleSecondDate = (date) => {
    setSecondDate(date);
  };

  const handleClearFirstDate = () => {
    setFirstDate(null);
  };

  const handleClearSecondDate = () => {
    setSecondDate(null);
  };

  useEffect(() => {
    setActionButton(
      <ShouldItRender
        locationPage={`/${location.pathname.split('/')[1]}`}
        action="importDepositsFiserv"
      >
        <MuiPickersUtilsProvider utils={MomentUtils} locale={'es'}>
          <DatePicker
            id="mui-pickers-firstDate"
            autoOk
            disableToolbar
            disableFuture
            variant="inline"
            label="Inicial"
            format="DD/MM"
            className={classes.datePicker}
            value={firstDate}
            onChange={handleFirstDate}
            InputProps={{
              endAdornment: firstDate ? (
                <IconButton
                  aria-label="Select locale"
                  size="small"
                  onClick={handleClearFirstDate}
                >
                  <Close />
                </IconButton>
              ) : null,
            }}
          />
          <span className={classes.range}>&nbsp;A&nbsp;</span>
          <DatePicker
            id="mui-pickers-secondDate"
            autoOk
            disableToolbar
            disableFuture
            variant="inline"
            label="Final"
            format="DD/MM"
            className={classes.datePicker}
            value={secondDate}
            onChange={handleSecondDate}
            InputProps={{
              endAdornment: secondDate ? (
                <IconButton
                  aria-label="Select locale"
                  size="small"
                  onClick={handleClearSecondDate}
                >
                  <Close />
                </IconButton>
              ) : null,
            }}
          />
        </MuiPickersUtilsProvider>
        <div className={classes.menuDesktop}>
          <ButtonExportExcel
            data={paymentsData}
            buttonText="Exportar Depósitos"
            fileName={nameFile}
          />
        </div>
        <div className={classes.menuMobile}>
          <MenuOption
            Button={
              <Button
                variant="contained"
                className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-t-10"
              >
                Ver opciones
                <ArrowDropDownIcon className="kt-margin-l-10" />
              </Button>
            }
            options={[
              {
                name: (
                  <ButtonExportExcel
                    data={paymentsData}
                    buttonText="Exportar Depósitos"
                    fileName={nameFile}
                  />
                ),
                onClick: genericFuntion,
              },
            ]}
          />
        </div>
      </ShouldItRender>,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentsData, firstDate, secondDate]);

  const genericFuntion = () => {
    // Don't delete this funtion, is called by the top bar menu
    // buttons that do not require the component's onClick function
  };

  const handleCloseModals = () => {
    closeModals();
  };

  const toggleImportExcel = () => {
    setShowImportDespositsModal(!showImportDespositsModal);
  };

  const handleRefreshData = () =>
    reactTable.current && reactTable.current.fireFetchData();

  const handleImportDepositsFromExcel = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file0', excelFile);
    importDepositsFromExcel({
      data: formData,
      handleRefreshData,
    });
    setExcelFile(null);
    toggleImportExcel();
  };

  const renderCreateDeposit = () => {
    return (
      <Modal
        open={showImportDespositsModal}
        closeModal={toggleImportExcel}
        dialogTitle="Crear depósito"
        actionButtonText="Validar"
        form={'import-deposits-from-excel-form'}
        dialogChildren={
          <form
            id="import-deposits-from-excel-form"
            autoComplete="off"
            noValidate
            onSubmit={handleImportDepositsFromExcel}
          >
            <ButtonImportFile
              className={classes.buttonImportExcel}
              buttonName="Seleccionar archivo excel"
              file={excelFile}
              setFile={setExcelFile}
            />
          </form>
        }
      />
    );
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = deposits;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message, redirect } = deposits[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={() => handleCloseModals(redirect)}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={() => handleCloseModals(redirect)}
          />
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  return (
    <div className={classes.root}>
      {renderCreateDeposit()}
      {renderFeedBackModal()}
      <DepositsCreatedTable
        firstDate={firstDate}
        secondDate={secondDate}
        reactTableRef={reactTable}
        deposits={deposits}
        depositsFromdb={depositsFromdb}
        getAllDeposits={getAllPaymentsStandAlone}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  deposits: selectDepositsStandAlone(state),
  depositsFromdb: makeSelectDepositsFromdbFromState(state),
  tablePages: makeSelectDepositsPages(state),
  role: makeSelectUserRoleFromState(state),
});

const mapDispatchToProps = {
  getAllPaymentsStandAlone,
  getAllDepositsSPEI,
  createDeposit,
  importDepositsFromExcel,
  closeModals,
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PaymentsStandAloneTable),
);
