export const initialState = {
  fromdb: null,
  tablePages: 0,
  isLoading: false,
  agencyWithManager: null,
  agencyWithOutManager: null,
  successModal: {
    show: false,
    message: null,
  },
  errorModal: {
    show: false,
    message: null,
  },
  error: null,
  ordersQuiterForDownload: null,
  ordersUsabilityForDownload: null,
};
