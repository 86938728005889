import {
  Button,
  DialogContent,
  CircularProgress,
  Backdrop,
  Checkbox,
  CardMedia,
  Grid,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';

import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Input from 'app/components/Input';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import { selectAuth } from 'app/features/auth/selectors';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router';
import Clear from '@material-ui/icons/Clear';

import Modal from '../../../../components/Modal';
import {
  getPromotionDetail,
  uploadPromoThumbnail,
  uploadPromoDescription,
  updatePromotion,
  deletePromotion,
  handleClosePromoModals,
} from 'app/features/karloPromotions/karloPromotionsSlice';
import { selectKarloPromotions } from 'app/features/karloPromotions/selectors';
import { makeSelectPromoDetails } from 'app/features/karloPromotions/selectors';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(5, 5, 3, 5),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5, 5, 3, 5),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(5, 10, 3, 10),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(5, 10, 3, 10),
    },
  },
  title: {
    textAlign: 'left',
    color: '#1c1c1c',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.5) !important',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  switch: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textFieldAddProducts: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    width: '50%',
  },
  margin: {
    margin: theme.spacing(1),
  },
  client: {
    padding: theme.spacing(0),
    marginTop: theme.spacing(1),
  },
  productsContainer: {
    marginBottom: theme.spacing(3),
  },
  submitButtonWrapper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  formGroupSwitch: {
    alignItems: 'center',
  },
  image: {
    height: 200,
    width: 'auto',
    alignItems: 'center',
    marginTop: 30,
    marginBottom: 30,
  },
  media: {
    width: '100%',
    alignItems: 'center',
  },
}));

function EditPromotion(props) {
  const classes = useStyles();
  const {
    updatePromotion,
    promoDetail,
    users,
    deletePromotion,
    uploadPromoThumbnail,
    uploadPromoDescription,
    setActionButton,
  } = props;
  const [thumbnailImage, setThumbnailImage] = useState('');
  const [openDelete, setOpenDelete] = React.useState(false);
  const [descriptionImage, setDescriptionImage] = useState('');
  const [promoId, setPromoId] = React.useState(0);
  const [hasExpiracy, setExpiracy] = useState(false);
  const [vigencyDate, setVigency] = useState(new Date());
  const [expirationDate, setExpiration] = useState(null);
  const [openThumbnail, setOpenThumbnail] = React.useState(false);
  const [openDescription, setOpenDescription] = React.useState(false);
  const { handleSubmit, register, errors, control, reset } = useForm({
    defaultValues: {
      provisional: false,
    },
  });

  function handleClosePromoModals() {
    props.handleClosePromoModals();
  }

  function handleCloseDeleteModal() {
    props.handleClosePromoModals();
    props.history.push('/karloPromotions');
  }

  useEffect(() => {
    console.log('data full ', promoDetail);
    setActionButton(
      <div>
        <Button
          variant="contained"
          className="btn btn-label-danger btn-bold btn-sm btn-icon-h kt-margin-l-10"
          onClick={() => {
            handleOpenDelete();
            setPromoId(promoDetail.id);
          }}
        >
          Eliminar promoción
          <Clear className="kt-margin-l-10" />
        </Button>
      </div>,
    );
    if (promoDetail == null) {
      console.log('es nill');
    } else {
      reset({
        title: promoDetail.title,
        subtitle: promoDetail.subtitle,
        description: promoDetail.description,
        videoUrl: promoDetail.videoUrl,
      });
      setExpiration(promoDetail.expirationDate);
      setVigency(promoDetail.startDate);
      setExpiracy(promoDetail.expirationDate == null ? false : true);
    }
  }, [promoDetail]);

  useEffect(() => {
    let data = {
      id: props.match.params.id,
    };
    props.getPromotionDetail(data);
  }, []);

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = props.promotions;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message } = props.promotions[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={
              message.title == 'Eliminado'
                ? handleCloseDeleteModal
                : handleClosePromoModals
            }
            onClick={
              message.title == 'Eliminado'
                ? handleCloseDeleteModal
                : handleClosePromoModals
            }
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
          />
        );
      }
      return null;
    } catch (e) {
      handleClosePromoModals();
      return null;
    }
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setOpenThumbnail(false);
    setOpenDescription(false);
  };

  const handleThumbnailChangeStatus = ({ meta, file }, status) => {
    if (status == 'done') {
      setThumbnailImage(file);
    }
  };

  const handleThumbnail = () => {
    uploadPromoThumbnail({
      file: thumbnailImage,
      id: promoId,
    });
    handleCloseDelete();
  };

  const handleDescription = () => {
    uploadPromoDescription({
      file: descriptionImage,
      id: promoId,
    });
    handleCloseDelete();
  };

  const handleDescriptionChangeStatus = ({ meta, file }, status) => {
    if (status == 'done') {
      setDescriptionImage(file);
    }
  };

  const handleExtraValidation = (formData) => {
    const userId = users?.user?.id;
    updatePromotion({
      ...formData,
      id: promoDetail.id,
      createdByUserId: userId,
      startDate: vigencyDate,
      expirationDate: expirationDate,
    });
  };

  const handleDelete = () => {
    deletePromotion({ promoId });
    handleCloseDelete();
  };

  const renderConfirmationDelete = () => {
    return (
      <Modal
        open={openDelete}
        type="warning"
        closeModal={handleCloseDelete}
        dialogTitle="¿Seguro que deseas eliminar el promoción?"
        dialogText="Si continúas no habrá manera de recuperar la información
            que haya sido borrada."
        actionButtonText="Eliminar promoción"
        onClick={handleDelete}
      />
    );
  };

  const renderuploadPromoThumbnail = () => {
    return (
      <Modal
        open={openThumbnail}
        closeModal={handleCloseDelete}
        dialogTitle="Actualizar thumbnail"
        actionButtonText="Actualizar thumbnail"
        onClick={handleThumbnail}
        dialogChildren={
          <div className={classes.buttonGroup}>
            <p className="text-align-dialog">
              Cargar una imagen para el thumbnail
            </p>
            <Dropzone
              // getUploadParams={getUploadParams}
              onChangeStatus={handleThumbnailChangeStatus}
              maxFiles={1}
              submitButtonContent={null}
              styles={{
                dropzone: { minHeight: 200, maxHeight: 250 },
              }}
              // onSubmit={handleSubmitDropzone}
              accept="image/*"
            />
          </div>
        }
      />
    );
  };

  const renderuploadPromoDescription = () => {
    return (
      <Modal
        open={openDescription}
        closeModal={handleCloseDelete}
        dialogTitle="Actualizar promoción"
        actionButtonText="Actualizar promoción"
        onClick={handleDescription}
        dialogChildren={
          <div className={classes.buttonGroup}>
            <p className="text-align-dialog">
              Cargar una imagen para el promoción
            </p>
            <Dropzone
              // getUploadParams={getUploadParams}
              onChangeStatus={handleDescriptionChangeStatus}
              maxFiles={1}
              submitButtonContent={null}
              styles={{
                dropzone: { minHeight: 200, maxHeight: 250 },
              }}
              // onSubmit={handleSubmitDropzone}
              accept="image/*"
            />
          </div>
        }
      />
    );
  };

  return (
    <div>
      {renderuploadPromoThumbnail()}
      {renderuploadPromoDescription()}
      {renderConfirmationDelete()}
      {renderFeedBackModal()}
      {props.promotions.isLoading && (
        <Backdrop
          className={classes.backdrop}
          open={props.promotions.isLoading}
        >
          <CircularProgress />
        </Backdrop>
      )}
      <Paper className={classes.paper}>
        <form
          onSubmit={handleSubmit(handleExtraValidation)}
          noValidate
          autoComplete="off"
        >
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.client}>
              <Typography variant="h6">
                Información de la promoción
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                required
                ref={register({
                  required: true,
                })}
                name="title"
                label={'Titulo de promoción'}
                className={classes.textField}
                error={Boolean(errors.reference)}
                // helperText="Introduce el número de referencia"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                required
                ref={register({
                  required: true,
                })}
                name="subtitle"
                label={'Subtitulo de promoción'}
                className={classes.textField}
                error={Boolean(errors.reference)}
                // helperText="Introduce el número de referencia"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Input
                required
                ref={register({
                  required: true,
                })}
                name="description"
                label={'Descripción de promoción'}
                className={classes.textField}
                error={Boolean(errors.reference)}
                // helperText="Introduce el número de referencia"
              />
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={6}>
              <Checkbox
                className={classes.checkbox}
                checked={hasExpiracy}
                onChange={() => setExpiracy(!hasExpiracy)}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
              <Typography>Tiene vigencia</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MuiPickersUtilsProvider
                utils={MomentUtils}
                locale={'es'}
              >
                <DatePicker
                  autoOk
                  fullWidth
                  disablePast
                  disableToolbar
                  name="startDate"
                  // error={Boolean(errors.vigencyDate)}
                  className={classes.textField}
                  format="YYYY/MM/DD"
                  label="Fecha de inicio"
                  variant="inline"
                  inputVariant="outlined"
                  value={vigencyDate}
                  onChange={(date) =>
                    setVigency(moment(date).format('YYYY/MM/DD'))
                  }
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MuiPickersUtilsProvider
                utils={MomentUtils}
                locale={'es'}
              >
                <DatePicker
                  autoOk
                  fullWidth
                  disablePast
                  disableToolbar
                  name="expirationDate"
                  disabled={hasExpiracy == false ? true : false}
                  // error={Boolean(errors.vigencyDate)}
                  className={classes.textField}
                  format="YYYY/MM/DD"
                  label="Fecha de vigencia"
                  variant="inline"
                  inputVariant="outlined"
                  value={expirationDate}
                  onChange={(date) =>
                    setExpiration(moment(date).format('YYYY/MM/DD'))
                  }
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Input
                ref={register({
                  required: false,
                })}
                name="videoUrl"
                label={'Video'}
                className={classes.textField}
                error={Boolean(errors.reference)}
              />
            </Grid>
          </Grid>
          <div className={classes.submitButtonWrapper}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={props.promotions.isLoading}
            >
              Actualizar promoción
            </Button>
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.client}>
              <Typography variant="h6" gutterBottom>
                {'Archivos adjuntos'}
              </Typography>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <div className={classes.buttonGroup}>
                  <p className="text-align-dialog">
                    Imagen para el thumbnail
                  </p>
                  <DialogContent>
                    {promoDetail?.thumbnailImage == null ? (
                      <div>
                        <Typography variant="h4" gutterBottom>
                          No hay imagen para el thumbnail
                        </Typography>
                        <div className={classes.submitButtonWrapper}>
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={props.promotions.isLoading}
                            onClick={() => {
                              setOpenThumbnail(true);
                              setPromoId(promoDetail.id);
                            }}
                          >
                            Agregar imagen thumbnail
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <CardMedia
                          className={classes.media}
                          title="imagen thumbnail"
                        >
                          <img
                            src={promoDetail.thumbnailImage}
                            alt="logo"
                            className={classes.image}
                          />
                        </CardMedia>
                        <div className={classes.submitButtonWrapper}>
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={props.promotions.isLoading}
                            onClick={() => {
                              setOpenThumbnail(true);
                              setPromoId(promoDetail.id);
                            }}
                          >
                            Actualizar imagen thumbnail
                          </Button>
                        </div>
                      </div>
                    )}
                  </DialogContent>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={classes.buttonGroup}>
                  <p className="text-align-dialog">
                    Imagen para el promoción
                  </p>
                  <DialogContent>
                    {promoDetail?.descriptionImage == null ? (
                      <div>
                        <Typography variant="h4" gutterBottom>
                          No hay imagen para el promoción
                        </Typography>
                        <div className={classes.submitButtonWrapper}>
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={props.promotions.isLoading}
                            onClick={() => {
                              setOpenDescription(true);
                              setPromoId(promoDetail.id);
                            }}
                          >
                            Agregar imagen promoción
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <CardMedia
                          className={classes.media}
                          title="imagen promocion"
                        >
                          <img
                            src={promoDetail.descriptionImage}
                            alt="logo"
                            className={classes.image}
                          />
                        </CardMedia>
                        <div className={classes.submitButtonWrapper}>
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={props.promotions.isLoading}
                            onClick={() => {
                              setOpenDescription(true);
                              setPromoId(promoDetail.id);
                            }}
                          >
                            Actualizar imagen promoción
                          </Button>
                        </div>
                      </div>
                    )}
                  </DialogContent>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  promotions: selectKarloPromotions,
  users: selectAuth,
  promoDetail: makeSelectPromoDetails,
});

const mapDispatchToProps = {
  handleClosePromoModals,
  deletePromotion,
  uploadPromoThumbnail,
  uploadPromoDescription,
  getPromotionDetail,
  updatePromotion,
};

export default withRouter(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(EditPromotion),
  ),
);
