/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import Divider from '@material-ui/core/Divider';
import api from '../../../../config/api';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
// Data
import { faqsAsesor } from '../../dataFaqs';
import { Button } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
const API_URL = api();
const useStyles = makeStyles({
  primary: {
    fontSize: '1.1em',
    '&:hover': {
      color: '#066dbf',
    },
  },
});

const dowloadfile = () => {
  fetch(`${API_URL}/dowloadfile/download-pdf`, { method: 'POST' })
    .then((response) => response.blob())
    .then((blob) => URL.createObjectURL(blob))
    .then((href) => {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      a.href = href;
      a.download = 'MANUAL DE USUARIO KARLO.pdf';
      a.click();
    })
    .catch(function (error) {
      console.log(error);
    });
};

const normalFaqs = (props) => {
  const classes = useStyles();
  const { typeRole } = props;
  useEffect(() => {
    dowloadfile();
  }, []);

  return (
    <Container style={{ width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <Typography variant="h5" gutterBottom>
            Preguntas Frecuentes
          </Typography>
          <Divider variant="fullWidth" />
        </Grid>
        {faqsAsesor.map((item, idx) => {
          const {
            subject,
            data: dataOriginal,
            permalinkParent,
          } = item;
          let { data } = item;
          const total = dataOriginal.length;
          if (total > 5) data.splice(4);
          return (
            <Grid item xs={12} md={6} key={`${subject}-${idx}`}>
              <Typography variant="h6">
                {`${subject} (${total})`}
              </Typography>
              <List dense={true}>
                {data.map((item) => {
                  const { id, title } = item;
                  return (
                    <ListItem
                      component="a"
                      href="/dashboard"
                      key={id}
                    >
                      <ListItemIcon style={{ minWidth: 30 }}>
                        <MenuBookIcon />
                      </ListItemIcon>
                      <ListItemText
                        classes={{
                          primary: classes.primary,
                        }}
                        primary={title}
                      />
                    </ListItem>
                  );
                })}
                {total > 5 && (
                  <ListItem component="a" href={permalinkParent}>
                    <ListItemIcon style={{ minWidth: 30 }} />
                    <ListItemText
                      classes={{
                        primary: classes.primary,
                      }}
                      primary="Ver más"
                    />
                  </ListItem>
                )}
              </List>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default normalFaqs;
