import { createSelector } from 'reselect';
import { getNested } from '../../utils';
import { initialState } from './initialState';

export const selectPaymentOrders = (state) =>
  state.paymentOrders || initialState;

export const makeSelectPaymentOrdersIsLoading = createSelector(
  selectPaymentOrders,
  (paymentOrdersState) => paymentOrdersState.isLoading,
);

export const makeSelectPaymentOrders = createSelector(
  selectPaymentOrders,
  (paymentOrdersState) => getNested([], paymentOrdersState, 'fromdb'),
);

export const makeSelectPaymentOrderDetailsFromState = createSelector(
  selectPaymentOrders,
  (paymentOrdersState) =>
    getNested([], paymentOrdersState, 'orderDetails'),
);

export const makeSelectPaymentOrdersNotCanceled = createSelector(
  selectPaymentOrders,
  (paymentOrdersState) =>
    getNested([], paymentOrdersState, 'fromdb').filter(
      (paymentOrder) => paymentOrder.status !== 'cancelada',
    ),
);

export const makeSelectPaymentOrdersCanceled = createSelector(
  selectPaymentOrders,
  (paymentOrdersState) =>
    getNested([], paymentOrdersState, 'fromdb').filter(
      (paymentOrder) => paymentOrder.status === 'cancelada',
    ),
);
