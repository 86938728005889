import { createSelector } from 'reselect';
import { initialState } from './initialState';

export const selectSpeiPayments = (state) =>
  state.speiPayments || initialState;

export const makeSelectSpeiPaymentsIsLoading = createSelector(
  selectSpeiPayments,
  (speiPaymentsState) => speiPaymentsState.isLoading,
);

export const makeSelectPaymentDetailsFromState = createSelector(
  selectSpeiPayments,
  (speiPaymentsState) => speiPaymentsState.paymentDetails,
);

export const makeSelectSpeiPaymentsFromdbFromState = createSelector(
  selectSpeiPayments,
  (speiPaymentsState) => speiPaymentsState.fromdb,
);
export const makeSelectSpeiPaymentsSearchedFromState = createSelector(
  selectSpeiPayments,
  (speiPaymentsState) => speiPaymentsState.paymentsSearchedFromdb,
);
