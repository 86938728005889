import React, { useEffect, useState } from 'react';

import {
  Button,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Switch,
  TextField,
  Typography,
  makeStyles,
  Tooltip,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { green } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  cleanEmptyKeys,
  dmsValues,
  onlyNumbers,
  enumEventInvoice,
  institutionDispersion,
  roundValue,
  enumPaymentAggregators,
  formatDate,
  typeOfPromotionMap,
  formatMoney,
} from '../../../../utils';
import AutocompleteBrands from 'app/components/AutocompleteBrands';
import { MuiCurrencyFormat } from 'app/components/MUICurrencyFormat';
import Modal from '../../../../components/Modal';
import ShouldItRender from '../../../../components/ShouldItRender';

import {
  createOrUpdateTypeOrders,
  getAgency,
  updateCommissions,
  updateOption,
  updloadLogo,
  updateAgency,
  upLoadAmounts,
  updateLineConfig,
  updateTemplate,
  updateAgencyMembership,
  updateTemplateById,
} from '../../agenciesSlice';
import { closeModals } from 'app/features/modals/modalsSlice';
import { makeSelectUserRoleFromState } from '../../../auth/selectors';

import SplashScreen from '../../../../partials/layout/SplashScreen';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { getDMSName } from '../../../../../_metronic';
import { injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { stubTrue } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  divider: {
    width: '100%',
  },
  title: {
    textAlign: 'left',
    color: '#1c1c1c',
    position: 'relative',
  },
  content: {
    textAlign: 'left',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
  marginMinimunBtn: {
    marginTop: theme.spacing(1),
  },
  marginLineBtn: {
    marginTop: theme.spacing(3),
  },
  saveButton: {
    margin: theme.spacing(1),
    width: '145px',
  },
  saveDispersalsButton: {
    marginTop: theme.spacing(3),
    width: '145px',
  },
  iconRight: {
    margin: theme.spacing(0),
    padding: theme.spacing(1),
    position: 'absolute',
    top: '40%',
    right: '5%',
    marginTop: -12,
  },
  media: {
    width: '100%',
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  image: {
    height: 100,
    width: 'auto',
    marginTop: 30,
    marginBottom: 30,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  minimumTotalAllInput: {
    marginBottom: '50px',
  },
  minimumTotalInput: {
    marginBottom: '15px',
  },
  toptemplates: {
    color: 'black',
    align: 'left',
    margin: theme.spacing(1),
  },
  chipInput: {
    marginTop: theme.spacing(3),
  },
  submitButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
  padding: {
    padding: theme.spacing(1),
  },
  configSelect: {
    maxWidth: '60%',
    textAlign: 'left',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightMedium,
  },
  GridAccording: {
    padding: '20px',
  },
  headerColumns: {
    backgroundColor: '#D9DDDC',
  },
  titleTable: {
    fontWeight: 'bold',
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  itemRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  top: {
    marginTop: '10px',
  },
}));

function DetailAgency(props) {
  const {
    agencies,
    createOrUpdateTypeOrders,
    closeModals,
    getAgency,
    match,
    role,
    updateAgency,
    updateAgencyMembership,
    updateCommissions,
    updateLineConfig,
    updateOption,
    updateTemplate,
    updateTemplateById,
    upLoadAmounts,
    updloadLogo,
  } = props;

  const classes = useStyles();
  const buttonClassname = clsx({
    [classes.buttonSuccess]: agencies.logoUploaded,
  });
  const { register, errors, control } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(),
  });

  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [openCommissions, setOpenCommissions] = useState(false);
  const [showButton, setButton] = useState(true);
  const [changeAmounst, setChangeAmounst] = useState(true);
  const [changeLineConfig, setChangeLineConfig] = useState(true);
  const [changeCommissionConfig, setChangeCommissionConfig] =
    useState(false);
  const [changeAmounstForAlls, setChangeAmounstForAlls] =
    useState(false);
  const [wsUser, setWsUser] = useState();
  const [agencyData, setAgency] = useState({});
  const [agencyLineConfig, setAgencyLineConfig] = useState({});
  const [templateSurvey, setTemplateSurvey] = useState({});
  const [agencyOrdersTypeConfig, setAgencyOrdersTypeConfig] =
    useState({});
  const [minimumTotal, setMinimumTotal] = useState({
    fi: '',
    service: '',
    sales: '',
    parts: '',
    reservation: '',
    pulled_apart: '',
    preowned: '',
    insurance: '',
    accessories: '',
    'h&p': '',
  });
  const [minimumTotalOriginal, setMinimumTotalOriginal] = useState({
    fi: '',
    service: '',
    sales: '',
    parts: '',
    reservation: '',
    pulled_apart: '',
    preowned: '',
    insurance: '',
    accessories: '',
    'h&p': '',
  });
  const [configTypeOrder, setConfigTypeOrder] = useState({
    sales: {
      isActive: false,
      type: 'odp',
      id: null,
      typeOrder: 'sales',
    },
    preowned: {
      isActive: false,
      type: 'odp',
      id: null,
      typeOrder: 'preowned',
    },
    service: {
      isActive: false,
      type: 'ods',
      id: null,
      typeOrder: 'service',
    },
    fi: {
      isActive: false,
      type: 'odp',
      id: null,
      typeOrder: 'fi',
    },
    parts: {
      isActive: false,
      type: 'odp',
      id: null,
      typeOrder: 'parts',
    },
    maintenance: {
      isActive: false,
      type: 'odp',
      id: null,
      typeOrder: 'maintenance',
    },
    pulled_apart: {
      isActive: false,
      type: 'odp',
      id: null,
      typeOrder: 'pulled_apart',
    },
  });
  const [agencyCommissionsConfig, setAgencyCommissionsConfig] =
    useState([]);
  const [agencyByMembership, setAgencyByMembership] = useState([]);
  const [brandsPrint, setBrandsPrint] = useState([]);
  const [configTypeOrderUpdated, setConfigTypeOrderUpdated] =
    useState([]);
  const [AllagencyByMembership, setAllAgencyByMembership] = useState([
    {
      membershipType: 'banamex',
      threeMonths: '',
      sixMonths: '',
      nineMonths: '',
      twelveMonths: '',
      eighteenMonths: '',
    },
    {
      membershipType: 'american-express',
      threeMonths: '',
      sixMonths: '',
      nineMonths: '',
      twelveMonths: '',
      eighteenMonths: '',
    },
    {
      membershipType: 'prosa',
      threeMonths: '',
      sixMonths: '',
      nineMonths: '',
      twelveMonths: '',
      eighteenMonths: '',
    },
    {
      membershipType: 'bbva',
      threeMonths: '',
      sixMonths: '',
      nineMonths: '',
      twelveMonths: '',
      eighteenMonths: '',
    },
  ]);
  const [
    agencyByMembershipPercentage,
    setAgencyByMembershipPercentage,
  ] = useState([]);
  const [
    cleanAgencyByMembershipToSee,
    setCleanAgencyByMembershipToSee,
  ] = useState([]);
  const [agencyTemplatesConfig, setAgencyTemplatesConfig] = useState(
    [],
  );

  useEffect(() => {
    getAgency({ agencyID: match.params.id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (agencyTemplatesConfig && agencyTemplatesConfig.length > 0) {
      const surveyTemplate = agencyTemplatesConfig.find(
        (template) => template.isSurveyTemplate === true,
      );
      setTemplateSurvey(surveyTemplate);
    }
  }, [agencyTemplatesConfig]);

  useEffect(() => {
    if (agencies.agencyDetails) {
      const agencyData = agencies.agencyDetails;

      let consolidatedPayment =
        agencyData?.isConsolidatedPayment === null
          ? 'null'
          : agencyData?.isConsolidatedPayment;

      const cleanAgency = {
        id: agencyData.id,
        name: agencyData.name,
        DMS: agencyData.DMS,
        is3DSecureActive: agencyData.is3DSecureActive,
        eventInvoice: agencyData.eventInvoice,
        businessProID: agencyData.businessProID,
        email: agencyData.email,
        phoneNumber: agencyData.phoneNumber
          ? agencyData.phoneNumber
          : '-',
        rfc: agencyData.rfc,
        nickname: agencyData.nickname,
        logo: agencyData.logo ? agencyData.logo : '-',
        oxxoPayments: agencyData.oxxoPayments,
        SPEIPayments: agencyData.SPEIPayments,
        amexPayments: agencyData.amexPayments,
        provisionalPayments: agencyData.provisionalPayments,
        smsNotifications: agencyData.smsNotifications,
        emailNotifications: agencyData.emailNotifications,
        whatsNotifications: agencyData.whatsNotifications,
        whatsNotificationsODP: agencyData.whatsNotificationsODP,
        sendMessagePDFInvoiceWS: agencyData.sendMessagePDFInvoiceWS,
        smsUnitNotifications: agencyData.smsUnitNotifications,
        emailUnitNotifications: agencyData.emailUnitNotifications,
        whatsUnitNotifications: agencyData.whatsUnitNotifications,
        bpocketApiKey: agencyData.bpocketApiKey || '',
        businessName: agencyData.businessName || '',
        bpAgencyName: agencyData.bpAgencyName || '',
        afiliationNumber: agencyData.afiliationNumber || '',
        address: agencyData.address || {},
        invoiceConfirmation: agencyData.invoiceConfirmation,
        cardComission: agencyData.cardComission,
        amexCardComission: agencyData.amexCardComission,
        CIEReference: agencyData.CIEReference,
        clabe: agencyData.clabe,
        speiCustom: agencyData.speiCustom,
        bpUser: agencyData.bpUser,
        whatsPhoneID: agencyData.whatsPhoneID,
        stpID: agencyData.stpID,
        toogleZeroTaxes: agencyData.toogleZeroTaxes,
        hasKiosko: agencyData.hasKiosko,
        isActiveOnlineKiosko: agencyData.isActiveOnlineKiosko,
        odpPartsPromotions: agencyData.odpPartsPromotions,
        odpSalesPromotions: agencyData.odpSalesPromotions,
        odpServicePromotions: agencyData.odpServicePromotions,
        odsServicePromotions: agencyData.odsServicePromotions,
        threeMonthPayment: agencyData.threeMonthPayment,
        sixMonthPayment: agencyData.sixMonthPayment,
        nineMonthPayment: agencyData.nineMonthPayment,
        twelveMonthPayment: agencyData.twelveMonthPayment,
        brands: agencyData.brands,
        outputEmail: agencyData.outputEmail,
        principalBrand: agencyData.principalBrand,
        isActiveInternationalCards:
          agencyData.isActiveInternationalCards,
        minimunTotal: agencyData.minimunTotal,
        clearMechanicsID: agencyData.clearMechanicsID,
        amountLetter: agencyData.amountLetter,
        commissionTPVAmex: agencyData.commissionTPVAmex,
        commissionTPVVisaMc: agencyData.commissionTPVVisaMc,
        cardCreditTPVCommission: agencyData.cardCreditTPVCommission,
        internationalTPVCommission:
          agencyData.internationalTPVCommission,
        monetaryTPVCommission: agencyData.monetaryTPVCommission,
        cardCreditCommission: agencyData.cardCreditCommission,
        nombreBeneficiario: agencyData.nombreBeneficiario,
        cuentaBeneficiario: agencyData.cuentaBeneficiario,
        rfcCurpBeneficiario: agencyData.rfcCurpBeneficiario,
        institucionContraparte: agencyData.institucionContraparte,
        tipoCuentaBeneficiario: agencyData.tipoCuentaBeneficiario,
        dispersalClabe: agencyData.dispersalClabe,
        groupName: agencyData.groupName,
        centroCosto: agencyData.centroCosto,
        nombreOrdenante: agencyData.nombreOrdenante,
        cuentaOrdenante: agencyData.cuentaOrdenante,
        rfcCurpOrdenante: agencyData.rfcCurpOrdenante,
        isConsolidatedPayment: consolidatedPayment,
        internationalCommission: agencyData.internationalCommission,
        monetaryCommission: agencyData.monetaryCommission,
        cuentaBeneficiarioOXXO: agencyData.cuentaBeneficiarioOXXO,
        cuentaBeneficiarioAmericanExpress:
          agencyData.cuentaBeneficiarioAmericanExpress,
        cuentaBeneficiarioBBVA: agencyData.cuentaBeneficiarioBBVA,
        cuentaBeneficiarioVisaMcCarnet:
          agencyData.cuentaBeneficiarioVisaMcCarnet,
        nombreBancoAmericanExpress:
          agencyData.nombreBancoAmericanExpress,
        cuentaBeneficiarioAmericanExpress:
          agencyData.cuentaBeneficiarioAmericanExpress,
        nombreBancoBBVA: agencyData.nombreBancoBBVA,
        cuentaBeneficiarioBBVA: agencyData.cuentaBeneficiarioBBVA,
        nombreBancoVisaMcCarnet: agencyData.nombreBancoVisaMcCarnet,
        cuentaBeneficiarioVisaMcCarnet:
          agencyData.cuentaBeneficiarioVisaMcCarnet,
        nombreBancoOXXO: agencyData.nombreBancoOXXO,
        cuentaBeneficiarioOXXO: agencyData.cuentaBeneficiarioOXXO,
        upgradeProcessInvoice: agencyData.upgradeProcessInvoice,
        whatsNotifications: agencyData.whatsNotifications,
        whatsNotificationsODP: agencyData.whatsNotificationsODP,
        sendMessagePDFInvoiceWS: agencyData.sendMessagePDFInvoiceWS,
        isActiveGupshup: agencyData.isActiveGupshup,
        isWhatsAppCustom: agencyData.isWhatsAppCustom,
        deferPaymentsPulledApart: agencyData.deferPaymentsPulledApart,
        isActiveAgency: agencyData.isActiveAgency,
        createManuallyOdp: agencyData.createManuallyOdp,
        membershipTpv: agencyData.membershipTpv,
        membershipPinpad: agencyData.membershipPinpad,
        membershipEcommerce: agencyData.membershipEcommerce,
        fStoreId: agencyData.fStoreId,
        fStoreIdBBVA: agencyData.fStoreIdBBVA,
        paymentAggregators: agencyData.paymentAggregators,
        agencyGeneralAddress: agencyData.agencyGeneralAddress || {},
        institucionContraparte:
          agencyData.institucionContraparte || '',
        sendInvoiceError: agencyData.sendInvoiceError,
      };

      let cleanLineConfig;
      let cleanAgencyByMembership = [
        ...agencyData.agencyByMembership,
      ];
      let arrCleanAgencyByMembershipToSee = [];

      let cleanAgencyByMembershipSet = new Set(
        cleanAgencyByMembership.map((item) => item.membershipType),
      );

      for (let i = 0; i < AllagencyByMembership.length; i++) {
        const membershipType =
          AllagencyByMembership[i].membershipType;
        if (!cleanAgencyByMembershipSet.has(membershipType)) {
          cleanAgencyByMembershipSet.add(membershipType);
          cleanAgencyByMembership.push(AllagencyByMembership[i]);
          arrCleanAgencyByMembershipToSee.push(
            AllagencyByMembership[i],
          );
        } else {
          const existingMembership = cleanAgencyByMembership.find(
            (item) =>
              item.membershipType ===
              AllagencyByMembership[i].membershipType,
          );
          const updatedMembership = {
            ...existingMembership,
            threeMonths: roundValue(
              existingMembership.threeMonths * 100,
              4,
            ),
            sixMonths: roundValue(
              existingMembership.sixMonths * 100,
              4,
            ),
            nineMonths: roundValue(
              existingMembership.nineMonths * 100,
              4,
            ),
            twelveMonths: roundValue(
              existingMembership.twelveMonths * 100,
              4,
            ),
            eighteenMonths: roundValue(
              existingMembership.eighteenMonths * 100,
              4,
            ),
          };
          arrCleanAgencyByMembershipToSee.push(updatedMembership);
        }
      }

      setCleanAgencyByMembershipToSee(
        arrCleanAgencyByMembershipToSee,
      );

      setAgencyByMembership(cleanAgencyByMembership);

      if (agencyData.gupshupLine) {
        cleanLineConfig = {
          agencyID: agencyData.id,
          source: agencyData.gupshupLine.source
            ? agencyData.gupshupLine.source
            : '',
          apikey: agencyData.gupshupLine.apikey
            ? agencyData.gupshupLine.apikey
            : '',
          appId: agencyData.gupshupLine.appId
            ? agencyData.gupshupLine.appId
            : '',
          partnerAppToken: agencyData.gupshupLine.partnerAppToken
            ? agencyData.gupshupLine.partnerAppToken
            : '',
          appName: agencyData.gupshupLine.appName
            ? agencyData.gupshupLine.appName
            : '',
        };

        if (agencyData.gupshupLine.gupshupTemplates) {
          setAgencyTemplatesConfig(
            agencyData.gupshupLine.gupshupTemplates,
          );
        }
      }

      if (agencyData.typeOrdersByAgencies) {
        setAgencyOrdersTypeConfig(agencyData.typeOrdersByAgencies);
      }

      try {
        setWsUser(agencies.agencyDetails.wsUsers[0].user);
      } catch {}

      let brandsTemp = [];

      agencyData?.brands?.map((cursor) => {
        brandsTemp.push(cursor.name);
        setBrandsPrint(brandsTemp);
      });

      setAgency(cleanAgency);
      setAgencyLineConfig(cleanLineConfig);

      if (agencyData.commissions) {
        const updatedData = agencyData.commissions.map((item) => ({
          ...item,
          transactionFeePercentage: roundValue(
            item.transactionFeePercentage * 100,
          ),
        }));
        setAgencyCommissionsConfig(updatedData);
      }

      const settingAmounts = agencies.agencyDetails.settingAmounts;

      let tempAmounts = {
        fi: '',
        service: '',
        sales: '',
        parts: '',
        reservation: '',
        pulled_apart: '',
        preowned: '',
        insurance: '',
        accessories: '',
        'h&p': '',
      };

      if (settingAmounts && settingAmounts.length > 0) {
        settingAmounts.forEach((amount) => {
          switch (amount.typeOrder) {
            case 'fi':
              tempAmounts = {
                ...tempAmounts,
                fi: {
                  maximumAmountCardPayment:
                    amount.maximumAmountCardPayment
                      ? amount.maximumAmountCardPayment
                      : 0,
                  id: amount.id,
                  agencyID: cleanAgency.id,
                  applyAll: false,
                },
              };
              break;
            case 'service':
              tempAmounts = {
                ...tempAmounts,
                service: {
                  maximumAmountCardPayment:
                    amount.maximumAmountCardPayment
                      ? amount.maximumAmountCardPayment
                      : 0,
                  id: amount.id,
                  agencyID: cleanAgency.id,
                  applyAll: false,
                },
              };
              break;
            case 'sales':
              tempAmounts = {
                ...tempAmounts,
                sales: {
                  maximumAmountCardPayment:
                    amount.maximumAmountCardPayment
                      ? amount.maximumAmountCardPayment
                      : 0,
                  id: amount.id,
                  agencyID: cleanAgency.id,
                  applyAll: false,
                },
              };
              break;
            case 'parts':
              tempAmounts = {
                ...tempAmounts,
                parts: {
                  maximumAmountCardPayment:
                    amount.maximumAmountCardPayment
                      ? amount.maximumAmountCardPayment
                      : 0,
                  id: amount.id,
                  agencyID: cleanAgency.id,
                  applyAll: false,
                },
              };
              break;
            case 'reservation':
              tempAmounts = {
                ...tempAmounts,
                reservation: {
                  maximumAmountCardPayment:
                    amount.maximumAmountCardPayment
                      ? amount.maximumAmountCardPayment
                      : 0,
                  id: amount.id,
                  agencyID: cleanAgency.id,
                  applyAll: false,
                },
              };
              break;
            case 'pulled_apart':
              tempAmounts = {
                ...tempAmounts,
                pulled_apart: {
                  maximumAmountCardPayment:
                    amount.maximumAmountCardPayment
                      ? amount.maximumAmountCardPayment
                      : 0,
                  id: amount.id,
                  agencyID: cleanAgency.id,
                  applyAll: false,
                },
              };
              break;
            case 'preowned':
              tempAmounts = {
                ...tempAmounts,
                preowned: {
                  maximumAmountCardPayment:
                    amount.maximumAmountCardPayment
                      ? amount.maximumAmountCardPayment
                      : 0,
                  id: amount.id,
                  agencyID: cleanAgency.id,
                  applyAll: false,
                },
              };
              break;
            case 'insurance':
              tempAmounts = {
                ...tempAmounts,
                insurance: {
                  maximumAmountCardPayment:
                    amount.maximumAmountCardPayment
                      ? amount.maximumAmountCardPayment
                      : 0,
                  id: amount.id,
                  agencyID: cleanAgency.id,
                  applyAll: false,
                },
              };
              break;

            case 'h&p':
              tempAmounts = {
                ...tempAmounts,
                'h&p': {
                  maximumAmountCardPayment:
                    amount.maximumAmountCardPayment
                      ? amount.maximumAmountCardPayment
                      : 0,
                  id: amount.id,
                  agencyID: cleanAgency.id,
                  applyAll: false,
                },
              };
              break;
            case 'accessories':
              tempAmounts = {
                ...tempAmounts,
                accessories: {
                  maximumAmountCardPayment:
                    amount.maximumAmountCardPayment
                      ? amount.maximumAmountCardPayment
                      : 0,
                  id: amount.id,
                  agencyID: cleanAgency.id,
                  applyAll: false,
                },
              };
              break;
          }
        });
        setMinimumTotal({
          ...tempAmounts,
        });
        setMinimumTotalOriginal({
          ...tempAmounts,
        });
      }

      let typeOrders = configTypeOrder;

      if (agencies.agencyDetails?.typeOrders?.length > 0) {
        agencies.agencyDetails?.typeOrders?.forEach((orderType) => {
          switch (orderType.typeOrder) {
            case 'sales':
              typeOrders = {
                ...typeOrders,
                sales: {
                  isActive: orderType.isActive,
                  type: 'odp',
                  id: orderType.id,
                  typeOrder: 'sales',
                },
              };
              break;
            case 'preowned':
              typeOrders = {
                ...typeOrders,
                preowned: {
                  isActive: orderType.isActive,
                  type: 'odp',
                  id: orderType.id,
                  typeOrder: 'preowned',
                },
              };
              break;
            case 'service':
              typeOrders = {
                ...typeOrders,
                service: {
                  isActive: orderType.isActive,
                  type: 'ods',
                  id: orderType.id,
                  typeOrder: 'service',
                },
              };
              break;
            case 'fi':
              typeOrders = {
                ...typeOrders,
                fi: {
                  isActive: orderType.isActive,
                  type: 'odp',
                  id: orderType.id,
                  typeOrder: 'fi',
                },
              };
              break;
            case 'parts':
              typeOrders = {
                ...typeOrders,
                parts: {
                  isActive: orderType.isActive,
                  type: 'odp',
                  id: orderType.id,
                  typeOrder: 'parts',
                },
              };
              break;
            case 'maintenance':
              typeOrders = {
                ...typeOrders,
                maintenance: {
                  isActive: orderType.isActive,
                  type: 'odp',
                  id: orderType.id,
                  typeOrder: 'maintenance',
                },
              };
              break;
            case 'pulled_apart':
              typeOrders = {
                ...typeOrders,
                pulled_apart: {
                  isActive: orderType.isActive,
                  type: 'odp',
                  id: orderType.id,
                  typeOrder: 'pulled_apart',
                },
              };
              break;
          }
        });
      }
      setConfigTypeOrder(typeOrders);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencies.agencyDetails]);

  useEffect(() => {
    if (agencies.logoUploaded) {
      setButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencies.logoUploaded]);

  const handleChangeBrands = (data) => {
    let originalBrands = Object.values(brandsPrint);
    let formatBrands = data.map((brand) => brand.id);
    if (originalBrands.length < formatBrands.length) {
      originalBrands.map((brand) => {
        if (!formatBrands.includes(brand)) {
          formatBrands.push(brand);
          setBrandsPrint(formatBrands);
        }
      });
    }
    setBrandsPrint(formatBrands);
    setAgency({
      ...agencyData,
      brands: formatBrands,
    });
    setButton(false);
  };

  const handleChange = (name) => (event) => {
    const newValue = event.target.value;
    if (name.includes('.')) {
      const splitedObjKey = name.split('.');
      if (splitedObjKey[1] !== 'postalCode') {
        setAgency({
          ...agencyData,
          [splitedObjKey[0]]: {
            ...agencyData[splitedObjKey[0]],
            [splitedObjKey[1]]: newValue,
          },
        });
      } else {
        const validation = /^[0-9]+$/;
        if (
          newValue.length <= 5 &&
          (validation.test(newValue) || newValue === '')
        ) {
          setAgency({
            ...agencyData,
            [splitedObjKey[0]]: {
              ...agencyData[splitedObjKey[0]],
              [splitedObjKey[1]]: newValue,
            },
          });
        }
      }
    } else if (name === 'monetaryCommission') {
      if (newValue >= 0) {
        setAgency({
          ...agencyData,
          [name]: newValue,
        });
        setButton(false);
      }
    } else if (name === 'eventInvoice') {
      setAgency({
        ...agencyData,
        [name]: newValue,
      });
      const optionTemp = {
        id: agencyData.id,
      };
      optionTemp[name] = newValue;
      updateOption(optionTemp);
    } else {
      setAgency({
        ...agencyData,
        [name]: newValue,
      });
      setButton(false);
    }
    setButton(false);
  };

  const handleChangeLineConfig = (name) => (event) => {
    const newValue = event.target.value;
    setAgencyLineConfig({
      ...agencyLineConfig,
      [name]: newValue,
    });
    setChangeLineConfig(false);
  };

  const handleSubmitConfig = () => {
    updateLineConfig(agencyLineConfig);
  };

  ///TODO: Mandar los datos para actualizar el tamplate
  const handleSubmitTemplate = () => {
    updateTemplate(agencyTemplatesConfig);
  };

  const handleSubmitTemplateById = () => {
    updateTemplateById(templateSurvey);
  };

  const handleChangeMinimumAmount = (name, value) => {
    if (onlyNumbers.test(value) || value === undefined) {
      setChangeAmounst(false);
      if (name === 'all') {
        if (value !== '' && value !== undefined) {
          setChangeAmounstForAlls(true);
          setMinimumTotal({
            ...minimumTotal,
            all: {
              id: minimumTotal.fi.id,
              agencyID: agencyData.id,
              applyAll: true,
              maximumAmountCardPayment: value,
            },
          });
        } else {
          setChangeAmounstForAlls(false);
          setMinimumTotal({
            ...minimumTotal,
            all: {
              id: minimumTotal.fi.id,
              agencyID: agencyData.id,
              applyAll: false,
              maximumAmountCardPayment: value,
            },
          });
        }
      } else {
        setMinimumTotal({
          ...minimumTotal,
          [name]: {
            ...minimumTotal[name],
            maximumAmountCardPayment: value === undefined ? 0 : value,
          },
        });
      }
    }
  };

  const handleSubmitNewAmounts = () => {
    if (changeAmounstForAlls) {
      upLoadAmounts(minimumTotal);
    } else {
      let changeAmount = [];
      for (const type in minimumTotalOriginal) {
        if (minimumTotalOriginal[type] !== minimumTotal[type]) {
          changeAmount.push({
            [type]: minimumTotal[type],
          });
        }
      }
      if (changeAmount.length > 0) {
        upLoadAmounts(changeAmount);
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenCommissions = () => {
    setOpenCommissions(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenCommissions(false);
  };

  const handleSubmitCommissions = () => {
    const updatedData = agencyCommissionsConfig.map((item) => ({
      ...item,
      transactionFeePercentage: item.transactionFeePercentage / 100,
    }));
    let toSend = [...updatedData];
    updateCommissions(toSend);
    handleClose();
  };

  const handleSubmitSend = () => {
    let toSendData = agencies.logoUploaded
      ? {
          ...agencyData,
          logo: agencies.logoURL,
          nickname: agencyData.nickname.replace(/\s/g, ''),
        }
      : {
          ...agencyData,
          nickname: agencyData.nickname.replace(/\s/g, ''),
        };
    toSendData = cleanEmptyKeys(toSendData, agencies.agencyDetails);
    Boolean(toSendData.amexCardComission) &&
      (toSendData.amexCardComission = parseFloat(
        toSendData.amexCardComission,
      ));
    Boolean(toSendData.cardComission) &&
      (toSendData.cardComission = parseFloat(
        toSendData.cardComission,
      ));

    delete toSendData.provisionalPayments;
    delete toSendData.smsNotifications;
    delete toSendData.emailNotifications;
    delete toSendData.whatsNotifications;
    delete toSendData.whatsNotificationsODP;
    delete toSendData.sendMessagePDFInvoiceWS;
    delete toSendData.smsUnitNotifications;
    delete toSendData.emailUnitNotifications;
    delete toSendData.whatsUnitNotifications;
    delete toSendData.invoiceConfirmation;
    delete toSendData.SPEIPayments;
    delete toSendData.oxxoPayments;
    delete toSendData.amexPayments;
    updateAgency(toSendData);
    handleClose();
  };

  const handleCloseModals = () => {
    closeModals();
  };

  const handleEdit = () => {
    setEdit(!edit);
  };

  const handleFile = (selectedFiles) => {
    if (!selectedFiles || selectedFiles.length === 0) return;

    if (selectedFiles[0] != null) {
      if (selectedFiles[0].size !== 0) {
        const formData = new FormData();
        formData.append(`file0`, selectedFiles[0]);
        return updloadLogo(formData);
      }
    }
    return;
  };

  const handleUpdateOption = (option, value) => {
    if (role === 'Super Admin') {
      const optionTemp = {
        id: agencyData.id,
      };
      optionTemp[option] = value;
      updateOption(optionTemp);
    }
  };

  const handleUpdateTemplateId = (newTemplateId, id) => {
    if (newTemplateId) {
      setAgencyTemplatesConfig((current) =>
        current.map((obj) => {
          if (obj.id === id) {
            return { ...obj, templateId: newTemplateId };
          }
          return obj;
        }),
      );
    }
  };

  const handleUpdateTemplateById = (newTemplateId, id) => {
    if (newTemplateId) {
      setTemplateSurvey({
        ...templateSurvey,
        templateId: newTemplateId,
      });
    }
  };

  const handleChangeOrdersTypeStatus = (data) => {
    let configOrderType = data;
    const alreadyUpdated = configTypeOrderUpdated.filter(
      (config) => config.typeOrder === configOrderType.typeOrder,
    );

    configOrderType.isActive = !configOrderType.isActive;
    if (alreadyUpdated.length === 0) {
      setConfigTypeOrderUpdated([
        ...configTypeOrderUpdated,
        configOrderType,
      ]);
    } else {
      const cleanOrders = configTypeOrderUpdated.filter(
        (config) => config.typeOrder !== configOrderType.typeOrder,
      );
      setConfigTypeOrderUpdated([...cleanOrders]);
    }

    setConfigTypeOrder({
      ...configTypeOrder,
      [configOrderType.typeOrder]: {
        ...configOrderType,
      },
    });
  };

  const handleUpdateTypeOrder = () => {
    configTypeOrderUpdated.forEach((orderType) => {
      createOrUpdateTypeOrders({
        ...orderType,
        agencyID: agencies.agencyDetails.id,
      });
    });

    setConfigTypeOrderUpdated([]);
  };

  const handleUpdateItem = (item) => {
    const data = {
      agencyID: agencyData.id,
      id: item.id === '' ? null : item.id,
      membershipType:
        item.membershipType === '' ? null : item.membershipType,
      membership: 'null',
      rate: 0,
      bankMonetaryCommission: 0,
      karloPayMonetaryCommission: 0,
      threeMonths:
        item.threeMonths === ''
          ? 0
          : roundValue(item.threeMonths / 100, 4),
      sixMonths:
        item.sixMonths === ''
          ? 0
          : roundValue(item.sixMonths / 100, 4),
      nineMonths:
        item.nineMonths === ''
          ? 0
          : roundValue(item.nineMonths / 100, 4),
      twelveMonths:
        item.twelveMonths === ''
          ? 0
          : roundValue(item.twelveMonths / 100, 4),
      eighteenMonths:
        item.eighteenMonths === ''
          ? 0
          : roundValue(item.eighteenMonths / 100, 4),
    };
    updateAgencyMembership(data);
  };

  const handleInputChange = (index, field, value) => {
    setChangeCommissionConfig(stubTrue);
    const updatedPromotion = {
      ...agencyCommissionsConfig[index],
      [field]: value,
    };

    const updatedPromotions = [...agencyCommissionsConfig];
    updatedPromotions[index] = updatedPromotion;
    setAgencyCommissionsConfig(updatedPromotions);
  };

  const renderConfirmation = () => {
    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth="xs"
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            ¿Seguro que deseas editar la agencia?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Se actualizará la agencia con la nueva información que
              proporciones
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleSubmitSend} color="primary">
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const renderConfirmationCommissions = () => {
    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth="xs"
          open={openCommissions}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            ¿Seguro que deseas editar la agencia?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Se actualizarán las comisiones de la agencia con la
              nueva información
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleSubmitCommissions} color="primary">
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = agencies;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message } = agencies[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={handleCloseModals}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={handleCloseModals}
          />
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  const renderTextAgencyDetails = (title, value, tooltip) => {
    return (
      <Grid item xs={12} sm={4} lg={3} className={classes.margin}>
        {title}
        {tooltip && (
          <>
            &nbsp;
            <Tooltip title={tooltip}>
              <InfoOutlinedIcon />
            </Tooltip>
          </>
        )}
        <br />
        {value}
      </Grid>
    );
  };

  const renderAgencyDetails = () => {
    return (
      <Grid container>
        {renderTextAgencyDetails(
          'Nombre de agencia',
          agencyData.name,
        )}
        {renderTextAgencyDetails('Teléfono', agencyData.phoneNumber)}
        {renderTextAgencyDetails(
          'Razón social',
          agencyData.businessName,
        )}
        {agencyData.DMS === 'businesspro'
          ? renderTextAgencyDetails(
              'business Pro ID',
              getDMSName(agencyData.businessProID),
            )
          : null}
        {renderTextAgencyDetails(
          'Dirección de la agencia',
          `${agencyData.address.street}, ${agencyData.address.ext}, ${agencyData.address.colony}, ${agencyData.address.postalCode}, ${agencyData.address.city}, ${agencyData.address.state}`,
        )}
        {renderTextAgencyDetails('RFC', agencyData.rfc)}
        {renderTextAgencyDetails('DMS', agencyData.DMS)}
        {renderTextAgencyDetails(
          'Credencial de BillPocket',
          agencyData.bpocketApiKey,
        )}
        {renderTextAgencyDetails(
          'Nombre de BP',
          agencyData.bpAgencyName,
        )}
        {renderTextAgencyDetails(
          'Afiliación de BillPocket',
          agencyData.afiliationNumber,
        )}
        {renderTextAgencyDetails(
          '# de usuario Billpocket',
          agencyData.bpUser,
        )}
        {renderTextAgencyDetails('stp ID', agencyData.stpID)}
        {renderTextAgencyDetails(
          'Correo de salida',
          agencyData.outputEmail,
          'Correo del cual se envían las notificaciones de pago de la agencia',
        )}
        {renderTextAgencyDetails('Usuario del WS', wsUser)}
        {renderTextAgencyDetails(
          'Cuenta OXXO del beneficiario',
          agencyData.cuentaBeneficiarioOXXO,
        )}
        {renderTextAgencyDetails(
          'Cuenta Amex del beneficiario',
          agencyData.cuentaBeneficiarioAmericanExpress,
        )}
        {renderTextAgencyDetails(
          'Cuenta BBVA del beneficiario',
          agencyData.cuentaBeneficiarioBBVA,
        )}
        {renderTextAgencyDetails(
          'Cuenta Visa/Mc/Carnet del beneficiario',
          agencyData.cuentaBeneficiarioVisaMcCarnet,
        )}
        {renderTextAgencyDetails(
          'Clabe de la agencia',
          agencyData.dispersalClabe,
        )}
        {renderTextAgencyDetails(
          'Nombre de Grupo',
          agencyData.groupName,
        )}
        {renderTextAgencyDetails(
          'Centro Costo',
          agencyData.centroCosto,
        )}
        {renderTextAgencyDetails('Store Id', agencyData.fStoreId)}
        {renderTextAgencyDetails(
          'Store Id BBVA',
          agencyData.fStoreIdBBVA,
        )}
        {renderTextAgencyDetails(
          'Banco Visa/Master Card/Carnet',
          agencyData.nombreBancoVisaMcCarnet,
        )}
        {renderTextAgencyDetails(
          'Nombre del beneficiario',
          agencyData.nombreBeneficiario,
        )}
        {renderTextAgencyDetails(
          'Cuenta del beneficiario',
          agencyData.cuentaBeneficiario,
        )}
      </Grid>
    );
  };

  const renderSpecialInputAgencyDetails = (formInput) => {
    return (
      <Grid item xs={12} sm={4} lg={3} className={classes.margin}>
        {formInput}
      </Grid>
    );
  };

  const renderInputAgencyDetails = (
    label,
    value,
    onChange,
    placeholder,
  ) => {
    return (
      <Grid item xs={12} sm={4} lg={3} className={classes.margin}>
        <TextField
          label={label}
          InputLabelProps={{ shrink: true }}
          value={value}
          placeholder={placeholder}
          onChange={handleChange(onChange)}
          margin="normal"
          variant="outlined"
        />
      </Grid>
    );
  };

  const renderAgencyEdit = () => {
    return (
      <Grid container mb={5}>
        <form
          className={classes.container}
          noValidate
          autoComplete="off"
        >
          {
            <Typography variant="h6" className={classes.top}>
              Datos Fiscales de la agencia:
            </Typography>
          }
          <FormGroup row>
            {renderSpecialInputAgencyDetails(
              <TextField
                error={agencyData.name === '' ? true : false}
                label="Nombre"
                InputLabelProps={{ shrink: true }}
                value={agencyData.name}
                onChange={handleChange('name')}
                margin="normal"
                variant="outlined"
                helperText={
                  agencyData.name === '' ? 'Campo faltante' : ''
                }
              />,
            )}
            {renderSpecialInputAgencyDetails(
              <TextField
                error={agencyData.phoneNumber === '' ? true : false}
                helperText={
                  agencyData.phoneNumber === ''
                    ? 'Campo faltante'
                    : ''
                }
                label="Teléfono"
                InputLabelProps={{ shrink: true }}
                value={agencyData.phoneNumber}
                onChange={handleChange('phoneNumber')}
                margin="normal"
                variant="outlined"
              />,
            )}
            {renderSpecialInputAgencyDetails(
              <TextField
                error={agencyData.businessName === '' ? true : false}
                helperText={
                  agencyData.businessName === ''
                    ? 'Campo faltante'
                    : ''
                }
                label="Razón social"
                InputLabelProps={{ shrink: true }}
                value={agencyData.businessName}
                onChange={handleChange('businessName')}
                margin="normal"
                variant="outlined"
              />,
            )}
            {renderSpecialInputAgencyDetails(
              <TextField
                required
                error={
                  agencyData.address.street === '' ? true : false
                }
                helperText={
                  agencyData.address.street === ''
                    ? 'Campo faltante'
                    : ''
                }
                label="Calle"
                value={agencyData.address.street}
                onChange={handleChange('address.street')}
                margin="normal"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />,
            )}
            {renderSpecialInputAgencyDetails(
              <TextField
                label="Ext"
                error={agencyData.address.ext === '' ? true : false}
                helperText={
                  agencyData.address.ext === ''
                    ? 'Campo faltante'
                    : ''
                }
                value={agencyData.address.ext}
                onChange={handleChange('address.ext')}
                margin="normal"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />,
            )}
            {renderSpecialInputAgencyDetails(
              <TextField
                error={
                  agencyData.address.colony === '' ? true : false
                }
                helperText={
                  agencyData.address.colony === ''
                    ? 'Campo faltante'
                    : ''
                }
                label="Colonia"
                InputLabelProps={{ shrink: true }}
                value={agencyData.address.colony}
                onChange={handleChange('address.colony')}
                margin="normal"
                variant="outlined"
              />,
            )}
            {renderSpecialInputAgencyDetails(
              <TextField
                error={
                  agencyData.address.postalCode === '' ? true : false
                }
                helperText={
                  agencyData.address.postalCode === ''
                    ? 'Campo faltante'
                    : ''
                }
                label="Código postal"
                value={agencyData.address.postalCode}
                onChange={handleChange('address.postalCode')}
                margin="normal"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />,
            )}
            {renderSpecialInputAgencyDetails(
              <TextField
                error={agencyData.address.city === '' ? true : false}
                helperText={
                  agencyData.address.city === ''
                    ? 'Campo faltante'
                    : ''
                }
                label="Ciudad"
                value={agencyData.address.city}
                onChange={handleChange('address.city')}
                margin="normal"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />,
            )}
            {renderSpecialInputAgencyDetails(
              <TextField
                error={agencyData.address.state === '' ? true : false}
                helperText={
                  agencyData.address.state === ''
                    ? 'Campo faltante'
                    : ''
                }
                label="Estado"
                value={agencyData.address.state}
                onChange={handleChange('address.state')}
                margin="normal"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />,
            )}
            {renderSpecialInputAgencyDetails(
              <TextField
                error={agencyData.rfc === '' ? true : false}
                helperText={
                  agencyData.rfc === '' ? 'Campo faltante' : ''
                }
                label="RFC"
                InputLabelProps={{ shrink: true }}
                value={agencyData.rfc}
                onChange={handleChange('rfc')}
                margin="normal"
                variant="outlined"
              />,
            )}
            {renderInputAgencyDetails(
              'Nombre de Grupo',
              agencyData.groupName,
              'groupName',
            )}
          </FormGroup>
          <Divider className={classes.divider} />
          <Grid item md={12}>
            <Typography variant="h6" className={classes.top}>
              Datos generales:
            </Typography>
          </Grid>
          {renderSpecialInputAgencyDetails(
            <TextField
              required
              error={
                agencyData.agencyGeneralAddress.street === ''
                  ? true
                  : false
              }
              helperText={
                agencyData.agencyGeneralAddress.street === ''
                  ? 'Campo faltante'
                  : ''
              }
              label="Calle"
              value={agencyData.agencyGeneralAddress.street}
              onChange={handleChange('agencyGeneralAddress.street')}
              margin="normal"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />,
          )}
          {renderSpecialInputAgencyDetails(
            <TextField
              label="Ext"
              error={
                agencyData.agencyGeneralAddress.ext === ''
                  ? true
                  : false
              }
              helperText={
                agencyData.agencyGeneralAddress.ext === ''
                  ? 'Campo faltante'
                  : ''
              }
              value={agencyData.agencyGeneralAddress.ext}
              onChange={handleChange('agencyGeneralAddress.ext')}
              margin="normal"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />,
          )}
          {renderSpecialInputAgencyDetails(
            <TextField
              error={
                agencyData.agencyGeneralAddress.colony === ''
                  ? true
                  : false
              }
              helperText={
                agencyData.agencyGeneralAddress.colony === ''
                  ? 'Campo faltante'
                  : ''
              }
              label="Colonia"
              InputLabelProps={{ shrink: true }}
              value={agencyData.agencyGeneralAddress.colony}
              onChange={handleChange('agencyGeneralAddress.colony')}
              margin="normal"
              variant="outlined"
            />,
          )}
          {renderSpecialInputAgencyDetails(
            <TextField
              error={
                agencyData.agencyGeneralAddress.postalCode === ''
                  ? true
                  : false
              }
              helperText={
                agencyData.agencyGeneralAddress.postalCode === ''
                  ? 'Campo faltante'
                  : ''
              }
              label="Código postal"
              value={agencyData.agencyGeneralAddress.postalCode}
              onChange={handleChange(
                'agencyGeneralAddress.postalCode',
              )}
              margin="normal"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />,
          )}
          {renderSpecialInputAgencyDetails(
            <TextField
              error={
                agencyData.agencyGeneralAddress.city === ''
                  ? true
                  : false
              }
              helperText={
                agencyData.agencyGeneralAddress.city === ''
                  ? 'Campo faltante'
                  : ''
              }
              label="Ciudad"
              value={agencyData.agencyGeneralAddress.city}
              onChange={handleChange('agencyGeneralAddress.city')}
              margin="normal"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />,
          )}
          {renderSpecialInputAgencyDetails(
            <TextField
              error={
                agencyData.agencyGeneralAddress.state === ''
                  ? true
                  : false
              }
              helperText={
                agencyData.agencyGeneralAddress.state === ''
                  ? 'Campo faltante'
                  : ''
              }
              label="Estado"
              value={agencyData.agencyGeneralAddress.state}
              onChange={handleChange('agencyGeneralAddress.state')}
              margin="normal"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />,
          )}
          {agencyData.DMS === 'businesspro'
            ? renderInputAgencyDetails(
                'business Pro ID',
                agencyData.businessProID,
                'businessProID',
              )
            : null}
          {renderSpecialInputAgencyDetails(
            <TextField
              required
              select
              error={agencyData.DMS === '' ? true : false}
              name="DMS"
              label="Selecciona el DMS al que pertenece la agencia"
              type="text"
              margin="normal"
              variant="outlined"
              value={agencyData.DMS}
              onChange={handleChange('DMS')}
            >
              {dmsValues.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>,
          )}
          {renderSpecialInputAgencyDetails(
            <TextField
              required
              select
              error={
                agencyData.paymentAggregators === '' ? true : false
              }
              name="DMS"
              label="Selecciona el agregador al que pertenece la agencia"
              type="text"
              margin="normal"
              variant="outlined"
              value={agencyData.paymentAggregators}
              onChange={handleChange('paymentAggregators')}
            >
              {enumPaymentAggregators.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>,
          )}
          {renderSpecialInputAgencyDetails(
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                label="Correo de salida"
                InputLabelProps={{ shrink: true }}
                value={agencyData.outputEmail}
                onChange={handleChange('outputEmail')}
                margin="normal"
                variant="outlined"
              />
              &nbsp;
              <Tooltip title="Correo del cual se envían las notificaciones de pago de la agencia">
                <InfoOutlinedIcon />
              </Tooltip>
            </div>,
          )}
          {renderInputAgencyDetails(
            'Marca',
            agencyData.principalBrand,
            'principalBrand',
          )}
          <Divider className={classes.divider} />
          <Grid item md={5}>
            <Typography variant="h6" className={classes.top}>
              Configuración de agregador:
            </Typography>
          </Grid>
          <FormGroup row>
            {renderInputAgencyDetails(
              'BpocketApiKey',
              agencyData.bpocketApiKey,
              'bpocketApiKey',
            )}
            {renderInputAgencyDetails(
              'Nombre agencia BP',
              agencyData.bpAgencyName,
              'bpAgencyName',
            )}
            {renderInputAgencyDetails(
              'Afiliación BP',
              agencyData.afiliationNumber,
              'afiliationNumber',
            )}
            {renderInputAgencyDetails(
              '# de usuario Billpocket',
              agencyData.bpUser,
              'bpUser',
            )}

            {renderInputAgencyDetails(
              'stp ID',
              agencyData.stpID,
              'stpID',
            )}
            {renderInputAgencyDetails(
              'Cuenta de OXXO del beneficiario',
              agencyData.cuentaBeneficiarioOXXO,
              'cuentaBeneficiarioOXXO',
            )}
            {renderInputAgencyDetails(
              'Cuenta AMEX del beneficiario',
              agencyData.cuentaBeneficiarioAmericanExpress,
              'cuentaBeneficiarioAmericanExpress',
            )}
            {renderInputAgencyDetails(
              'Cuenta BBVA del beneficiario',
              agencyData.cuentaBeneficiarioBBVA,
              'cuentaBeneficiarioBBVA',
            )}
            {renderInputAgencyDetails(
              'Cuenta Visa/Mc/Carnet del beneficiario',
              agencyData.cuentaBeneficiarioVisaMcCarnet,
              'cuentaBeneficiarioVisaMcCarnet',
            )}
            {renderInputAgencyDetails(
              'Clabe de la agencia',
              agencyData.dispersalClabe,
              'dispersalClabe',
            )}
            {renderInputAgencyDetails(
              'Afiliación PinPad',
              agencyData.membershipPinpad,
              'membershipPinpad',
            )}
            {renderInputAgencyDetails(
              'Afiliación Ecommerce',
              agencyData.membershipEcommerce,
              'membershipEcommerce',
            )}
            {renderInputAgencyDetails(
              'Store Id',
              agencyData.fStoreId,
              'fStoreId',
            )}
            {renderInputAgencyDetails(
              'Store Id BBVA',
              agencyData.fStoreIdBBVA,
              'fStoreIdBBVA',
            )}
          </FormGroup>
          <div className={classes.buttonGroup}>
            <input
              accept="image/*"
              hidden
              id="raised-button-file"
              type="file"
              onChange={(e) => handleFile(e.target.files)}
            />
            <label
              htmlFor="raised-button-file"
              className={classes.wrapper}
            >
              <Button
                variant="contained"
                component="span"
                color="primary"
                className={buttonClassname}
                disabled={agencies.isLoading}
              >
                {agencies.logoUploaded
                  ? 'Logo Cargado'
                  : 'Cambiar logo'}
                {agencies.logoUploaded ? (
                  <Check className={classes.rightIcon} />
                ) : (
                  <CloudUploadIcon className={classes.rightIcon} />
                )}
              </Button>
              {agencies.isLoading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </label>
          </div>
          <Button
            disabled={showButton}
            variant="contained"
            size="medium"
            color="primary"
            className={classes.saveButton}
            onClick={handleClickOpen}
          >
            Guardar
          </Button>
        </form>
      </Grid>
    );
  };

  const renderDataAgency = () => {
    return (
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item sm={12} md={10} lg={10} xl={9}>
            <DialogTitle className={classes.title}>
              {edit ? 'Agencia - (editar)' : 'Agencia'}
              <ShouldItRender
                locationPage={`/${
                  props.location.pathname.split('/')[1]
                }`}
                action="edit"
              >
                <IconButton
                  aria-label="Delete"
                  className={classes.iconRight}
                  onClick={handleEdit}
                >
                  <i className="far fa-edit"></i>
                </IconButton>
              </ShouldItRender>
            </DialogTitle>
            <Divider className={classes.divider} />
            <DialogContent className={classes.content}>
              {edit ? renderAgencyEdit() : renderAgencyDetails()}
              {renderConfirmation()}
              {renderConfirmationCommissions()}
              {renderFeedBackModal()}
            </DialogContent>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2} xl={3}>
            <DialogTitle className={classes.title}>Logo</DialogTitle>
            <Divider className={classes.divider} />
            <DialogContent>
              {agencyData.logo === '-' ? (
                <Typography variant="h4" gutterBottom>
                  No hay logo de agencia
                </Typography>
              ) : (
                <CardMedia
                  className={classes.media}
                  title="Logo de agencia"
                >
                  <img
                    src={agencyData.logo}
                    alt="logo"
                    className={classes.image}
                  />
                </CardMedia>
              )}
            </DialogContent>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  const renderConfigSwitch = (checked, onChange, label) => {
    return (
      <FormControlLabel
        control={
          <Switch
            className={classes.switch}
            checked={checked}
            onChange={onChange}
            color="primary"
          />
        }
        label={label}
      />
    );
  };

  const renderConfig = () => {
    return (
      <Paper className={classes.paper}>
        <Grid container justifyContent="space-evenly">
          <DialogTitle className={classes.title}>
            Configuraciones
          </DialogTitle>

          <Divider className={classes.divider} />

          <Grid item xs={12} sm={6} xl={3}>
            <Typography variant="h6" className={classes.top}>
              Pagos
            </Typography>
            <FormGroup row>
              {renderConfigSwitch(
                agencyData.oxxoPayments,
                () =>
                  handleUpdateOption(
                    'oxxoPayments',
                    !agencyData.oxxoPayments,
                  ),
                'Pagos con Oxxo',
              )}
              {renderConfigSwitch(
                agencyData.SPEIPayments,
                () =>
                  handleUpdateOption(
                    'SPEIPayments',
                    !agencyData.SPEIPayments,
                  ),
                'Pagos con SPEI',
              )}
              {renderConfigSwitch(
                agencyData.amexPayments,
                () =>
                  handleUpdateOption(
                    'amexPayments',
                    !agencyData.amexPayments,
                  ),
                'Pagos con AMEX',
              )}
              {renderConfigSwitch(
                agencyData.isActiveInternationalCards,
                () =>
                  handleUpdateOption(
                    'isActiveInternationalCards',
                    !agencyData.isActiveInternationalCards,
                  ),
                'Pagos internacionales',
              )}
              {renderConfigSwitch(
                agencyData.provisionalPayments,
                () =>
                  handleUpdateOption(
                    'provisionalPayments',
                    !agencyData.provisionalPayments,
                  ),
                'Órdenes con pago provisional',
              )}
              {renderConfigSwitch(
                agencyData.is3DSecureActive,
                () =>
                  handleUpdateOption(
                    'is3DSecureActive',
                    !agencyData.is3DSecureActive,
                  ),
                'Pagos con seguridad 3DS',
              )}
              {renderConfigSwitch(
                agencyData.invoiceConfirmation,
                () =>
                  handleUpdateOption(
                    'invoiceConfirmation',
                    !agencyData.invoiceConfirmation,
                  ),
                'Facturación',
              )}
              {agencyData.invoiceConfirmation ? (
                <Grid item style={{ textAlign: 'left' }}>
                  {
                    <TextField
                      required
                      className={classes.configSelect}
                      select
                      error={
                        agencyData.eventInvoice === '' ? true : false
                      }
                      name="eventInvoice"
                      label="Configura datos de facturación requeridos"
                      type="text"
                      margin="normal"
                      variant="outlined"
                      value={agencyData.eventInvoice}
                      onChange={handleChange('eventInvoice')}
                    >
                      {enumEventInvoice.map((option) => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  }
                </Grid>
              ) : null}
              {renderConfigSwitch(
                agencyData.deferPaymentsPulledApart,
                () =>
                  handleUpdateOption(
                    'deferPaymentsPulledApart',
                    !agencyData.deferPaymentsPulledApart,
                  ),
                'Diferir pagos de ODP tipo apartado',
              )}

              {renderConfigSwitch(
                agencyData.threeMonthPayment,
                () =>
                  handleUpdateOption(
                    'threeMonthPayment',
                    !agencyData.threeMonthPayment,
                  ),
                'Pago a 3 meses',
              )}
              {renderConfigSwitch(
                agencyData.sixMonthPayment,
                () =>
                  handleUpdateOption(
                    'sixMonthPayment',
                    !agencyData.sixMonthPayment,
                  ),
                'Pago a 6 meses',
              )}
              {renderConfigSwitch(
                agencyData.nineMonthPayment,
                () =>
                  handleUpdateOption(
                    'nineMonthPayment',
                    !agencyData.nineMonthPayment,
                  ),
                'Pago a 9 meses',
              )}
              {renderConfigSwitch(
                agencyData.twelveMonthPayment,
                () =>
                  handleUpdateOption(
                    'twelveMonthPayment',
                    !agencyData.twelveMonthPayment,
                  ),
                'Pago a 12 meses',
              )}
            </FormGroup>
          </Grid>

          <Divider className={classes.dividerHeight} />

          <Grid item xs={12} sm={6} xl={3}>
            <FormGroup>
              <Typography variant="h6" className={classes.top}>
                Notificaciones
              </Typography>
              <FormGroup row>
                {renderConfigSwitch(
                  agencyData.smsNotifications,
                  () =>
                    handleUpdateOption(
                      'smsNotifications',
                      !agencyData.smsNotifications,
                    ),
                  'Mensajes SMS',
                )}
                {renderConfigSwitch(
                  agencyData.emailNotifications,
                  () =>
                    handleUpdateOption(
                      'emailNotifications',
                      !agencyData.emailNotifications,
                    ),
                  'Correos',
                )}
                {renderConfigSwitch(
                  agencyData.whatsNotifications,
                  () =>
                    handleUpdateOption(
                      'whatsNotifications',
                      !agencyData.whatsNotifications,
                    ),
                  'Mensajes con WhatsApp ODS',
                )}
                {renderConfigSwitch(
                  agencyData.whatsNotificationsODP,
                  () =>
                    handleUpdateOption(
                      'whatsNotificationsODP',
                      !agencyData.whatsNotificationsODP,
                    ),
                  'Mensajes con WhatsApp ODP',
                )}
                {renderConfigSwitch(
                  agencyData.sendMessagePDFInvoiceWS,
                  () =>
                    handleUpdateOption(
                      'sendMessagePDFInvoiceWS',
                      !agencyData.sendMessagePDFInvoiceWS,
                    ),
                  'Mensajes de Factura',
                )}
                <Divider className={classes.divider} />
                {renderConfigSwitch(
                  agencyData.isSurveyActive,
                  () =>
                    handleUpdateOption(
                      'isSurveyActive',
                      !agencyData.isSurveyActive,
                    ),
                  'Mensaje de encuesta',
                )}
                <Grid
                  item
                  button
                  xs={12}
                  className={classes.margin}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'left',
                    marginTop: '1px',
                  }}
                >
                  <Grid item xs={8} className={classes.margin}>
                    <TextField
                      label="templateId"
                      value={templateSurvey?.templateId}
                      onChange={(event) => {
                        handleUpdateTemplateById(
                          event.target.value,
                          templateSurvey?.id,
                        );
                      }}
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    className={classes.marginMinimunBtn}
                    onClick={handleSubmitTemplateById}
                  >
                    Guardar
                  </Button>
                </Grid>
              </FormGroup>
              <Typography variant="h6" className={classes.top}>
                Habilitar botones
              </Typography>
              <FormGroup row>
                {renderConfigSwitch(
                  agencyData.smsUnitNotifications,
                  () =>
                    handleUpdateOption(
                      'smsUnitNotifications',
                      !agencyData.smsUnitNotifications,
                    ),
                  'Mensajes SMS',
                )}
                {renderConfigSwitch(
                  agencyData.emailUnitNotifications,
                  () =>
                    handleUpdateOption(
                      'emailUnitNotifications',
                      !agencyData.emailUnitNotifications,
                    ),
                  'Correos',
                )}
                {renderConfigSwitch(
                  agencyData.whatsUnitNotifications,
                  () =>
                    handleUpdateOption(
                      'whatsUnitNotifications',
                      !agencyData.whatsUnitNotifications,
                    ),
                  'Mensajes con Whats App',
                )}
              </FormGroup>
              {role === 'Super Admin' && (
                <>
                  <Typography variant="h6" className={classes.top}>
                    Agencia
                  </Typography>
                  <FormGroup row>
                    {renderConfigSwitch(
                      agencyData.isActiveAgency,
                      () =>
                        handleUpdateOption(
                          'isActiveAgency',
                          !agencyData.isActiveAgency,
                        ),
                      'Activar Agencia',
                    )}
                  </FormGroup>
                </>
              )}
              {role === 'Super Admin' && (
                <>
                  <Typography variant="h6" className={classes.top}>
                    Ordenes
                  </Typography>
                  <FormGroup row>
                    {renderConfigSwitch(
                      agencyData.createManuallyOdp,
                      () =>
                        handleUpdateOption(
                          'createManuallyOdp',
                          !agencyData.createManuallyOdp,
                        ),
                      'Crear ODP de manera manual',
                    )}
                  </FormGroup>
                </>
              )}
            </FormGroup>
          </Grid>

          <Divider className={classes.dividerHeight} />

          <Grid item xs={12} sm={6} xl={3}>
            <FormGroup>
              <Typography variant="h6" className={classes.top}>
                Promociones
              </Typography>
              <FormGroup row>
                {renderConfigSwitch(
                  agencyData.odsServicePromotions,
                  () =>
                    handleUpdateOption(
                      'odsServicePromotions',
                      !agencyData.odsServicePromotions,
                    ),
                  'Órdenes de servicio gerente de servicio',
                )}
                {renderConfigSwitch(
                  agencyData.odpSalesPromotions,
                  () =>
                    handleUpdateOption(
                      'odpSalesPromotions',
                      !agencyData.odpSalesPromotions,
                    ),
                  'Órdenes de pago gerente de ventas',
                )}
                {renderConfigSwitch(
                  agencyData.odpPartsPromotions,
                  () =>
                    handleUpdateOption(
                      'odpPartsPromotions',
                      !agencyData.odpPartsPromotions,
                    ),
                  'Órdenes de pago gerente de refacciones',
                )}
                {renderConfigSwitch(
                  agencyData.odpServicePromotions,
                  () =>
                    handleUpdateOption(
                      'odpServicePromotions',
                      !agencyData.odpServicePromotions,
                    ),
                  'Órdenes de pago gerente de servicio',
                )}
              </FormGroup>
              <Typography variant="h6" className={classes.top}>
                Comisiones
              </Typography>
              <FormGroup row>
                {renderConfigSwitch(
                  agencyData.toogleZeroTaxes,
                  () =>
                    handleUpdateOption(
                      'toogleZeroTaxes',
                      !agencyData.toogleZeroTaxes,
                    ),
                  'Tasa cero',
                )}
              </FormGroup>
              <Typography variant="h6" className={classes.top}>
                Kiosko
              </Typography>
              <FormGroup row>
                {renderConfigSwitch(
                  agencyData.hasKiosko,
                  () =>
                    handleUpdateOption(
                      'hasKiosko',
                      !agencyData.hasKiosko,
                    ),
                  'Activar kiosko',
                )}
              </FormGroup>
              <FormGroup row>
                {renderConfigSwitch(
                  agencyData.isActiveOnlineKiosko,
                  () =>
                    handleUpdateOption(
                      'isActiveOnlineKiosko',
                      !agencyData.isActiveOnlineKiosko,
                    ),
                  'Kiosko en línea',
                )}
              </FormGroup>
              <Typography variant="h6" className={classes.top}>
                Facturación
              </Typography>
              <FormGroup row>
                {renderConfigSwitch(
                  agencyData.upgradeProcessInvoice,
                  () =>
                    handleUpdateOption(
                      'upgradeProcessInvoice',
                      !agencyData.upgradeProcessInvoice,
                    ),
                  'Activar nuevo flujo de facturación',
                )}
                {renderConfigSwitch(
                  agencyData.sendInvoiceError,
                  () =>
                    handleUpdateOption(
                      'sendInvoiceError',
                      !agencyData.sendInvoiceError,
                    ),
                  'Enviar mensaje orden ya facturada',
                )}
              </FormGroup>
              <Typography variant="h6" className={classes.top}>
                Ordenes
              </Typography>
              <FormGroup row>
                {renderConfigSwitch(
                  configTypeOrder?.sales?.isActive,
                  () =>
                    handleChangeOrdersTypeStatus(
                      configTypeOrder?.sales,
                    ),
                  'Ventas',
                )}
                {renderConfigSwitch(
                  configTypeOrder?.preowned?.isActive,
                  () =>
                    handleChangeOrdersTypeStatus(
                      configTypeOrder?.preowned,
                    ),
                  'Semi nuevos',
                )}
                {renderConfigSwitch(
                  configTypeOrder?.service?.isActive,
                  () =>
                    handleChangeOrdersTypeStatus(
                      configTypeOrder?.service,
                    ),
                  'Servicio',
                )}
                {renderConfigSwitch(
                  configTypeOrder?.fi?.isActive,
                  () =>
                    handleChangeOrdersTypeStatus(configTypeOrder?.fi),
                  'F&I',
                )}
                {renderConfigSwitch(
                  configTypeOrder?.parts?.isActive,
                  () =>
                    handleChangeOrdersTypeStatus(
                      configTypeOrder?.parts,
                    ),
                  'Refacciones',
                )}
                {renderConfigSwitch(
                  configTypeOrder?.maintenance?.isActive,
                  () =>
                    handleChangeOrdersTypeStatus(
                      configTypeOrder?.maintenance,
                    ),
                  'Matenimiento',
                )}
                {renderConfigSwitch(
                  configTypeOrder?.['pulled_apart']?.isActive,
                  () =>
                    handleChangeOrdersTypeStatus(
                      configTypeOrder?.['pulled_apart'],
                    ),
                  'Apartados',
                )}
              </FormGroup>

              <FormGroup row>
                <Button
                  disabled={configTypeOrderUpdated.length === 0}
                  variant="contained"
                  size="medium"
                  color="primary"
                  className={classes.marginMinimunBtn}
                  onClick={handleUpdateTypeOrder}
                >
                  Guardar cambios
                </Button>
              </FormGroup>
            </FormGroup>
          </Grid>

          <Grid item xs={12} sm={6} xl={3}>
            <FormGroup>
              <Typography variant="h6" className={classes.top}>
                Montos mínimos
              </Typography>
              <FormGroup row>
                <MuiCurrencyFormat
                  label="Aplicar para todos"
                  onChange={(event) =>
                    handleChangeMinimumAmount('all', event)
                  }
                  className={classes.minimumTotalAllInput}
                />
              </FormGroup>
              <Grid container spacing={1}>
                <Grid container item xs={12} md={6}>
                  <MuiCurrencyFormat
                    label="F&I"
                    value={
                      minimumTotal?.all &&
                      minimumTotal?.all?.maximumAmountCardPayment
                        ? minimumTotal?.all?.maximumAmountCardPayment
                        : minimumTotal?.fi
                            ?.maximumAmountCardPayment &&
                          minimumTotal?.fi
                            ?.maximumAmountCardPayment !== 0
                        ? minimumTotal?.fi?.maximumAmountCardPayment
                        : ''
                    }
                    onChange={(event) =>
                      handleChangeMinimumAmount('fi', event)
                    }
                    className={classes.minimumTotalInput}
                    disabled={changeAmounstForAlls}
                  />
                </Grid>

                <Grid container item xs={12} md={6}>
                  <MuiCurrencyFormat
                    label="Servicios"
                    value={
                      minimumTotal?.all &&
                      minimumTotal?.all?.maximumAmountCardPayment
                        ? minimumTotal?.all?.maximumAmountCardPayment
                        : minimumTotal?.service
                            ?.maximumAmountCardPayment &&
                          minimumTotal?.service
                            ?.maximumAmountCardPayment !== 0
                        ? minimumTotal?.service
                            ?.maximumAmountCardPayment
                        : ''
                    }
                    onChange={(event) =>
                      handleChangeMinimumAmount('service', event)
                    }
                    className={classes.minimumTotalInput}
                    disabled={changeAmounstForAlls}
                  />
                </Grid>

                <Grid container item xs={12} md={6}>
                  <MuiCurrencyFormat
                    name="sales"
                    label="Ventas"
                    value={
                      minimumTotal?.all &&
                      minimumTotal?.all?.maximumAmountCardPayment
                        ? minimumTotal?.all?.maximumAmountCardPayment
                        : minimumTotal?.sales
                            ?.maximumAmountCardPayment &&
                          minimumTotal?.sales
                            ?.maximumAmountCardPayment !== 0
                        ? minimumTotal?.sales
                            ?.maximumAmountCardPayment
                        : ''
                    }
                    onChange={(event) =>
                      handleChangeMinimumAmount('sales', event)
                    }
                    className={classes.minimumTotalInput}
                    disabled={changeAmounstForAlls}
                  />
                </Grid>

                <Grid container item xs={12} md={6}>
                  <MuiCurrencyFormat
                    label="Refacciones"
                    value={
                      minimumTotal?.all &&
                      minimumTotal?.all?.maximumAmountCardPayment
                        ? minimumTotal?.all?.maximumAmountCardPayment
                        : minimumTotal?.parts
                            ?.maximumAmountCardPayment &&
                          minimumTotal?.parts
                            ?.maximumAmountCardPayment !== 0
                        ? minimumTotal?.parts
                            ?.maximumAmountCardPayment
                        : ''
                    }
                    onChange={(event) =>
                      handleChangeMinimumAmount('parts', event)
                    }
                    className={classes.minimumTotalInput}
                    disabled={changeAmounstForAlls}
                  />
                </Grid>

                <Grid container item xs={12} md={6}>
                  <MuiCurrencyFormat
                    label="Reservaciones"
                    value={
                      minimumTotal?.all &&
                      minimumTotal?.all?.maximumAmountCardPayment
                        ? minimumTotal?.all?.maximumAmountCardPayment
                        : minimumTotal?.reservation
                            ?.maximumAmountCardPayment &&
                          minimumTotal?.reservation
                            ?.maximumAmountCardPayment !== 0
                        ? minimumTotal?.reservation
                            ?.maximumAmountCardPayment
                        : ''
                    }
                    onChange={(event) =>
                      handleChangeMinimumAmount('reservation', event)
                    }
                    className={classes.minimumTotalInput}
                    disabled={changeAmounstForAlls}
                  />
                </Grid>

                <Grid container item xs={12} md={6}>
                  <MuiCurrencyFormat
                    label="Apartados"
                    value={
                      minimumTotal?.all &&
                      minimumTotal?.all?.maximumAmountCardPayment
                        ? minimumTotal?.all?.maximumAmountCardPayment
                        : minimumTotal?.pulled_apart
                            ?.maximumAmountCardPayment &&
                          minimumTotal?.pulled_apart
                            ?.maximumAmountCardPayment !== 0
                        ? minimumTotal?.pulled_apart
                            ?.maximumAmountCardPayment
                        : ''
                    }
                    onChange={(event) =>
                      handleChangeMinimumAmount('pulled_apart', event)
                    }
                    className={classes.minimumTotalInput}
                    disabled={changeAmounstForAlls}
                  />
                </Grid>

                <Grid container item xs={12} md={6}>
                  <MuiCurrencyFormat
                    label="Seminuevos"
                    value={
                      minimumTotal?.all &&
                      minimumTotal?.all?.maximumAmountCardPayment
                        ? minimumTotal?.all?.maximumAmountCardPayment
                        : minimumTotal?.preowned
                            ?.maximumAmountCardPayment &&
                          minimumTotal?.preowned
                            ?.maximumAmountCardPayment !== 0
                        ? minimumTotal?.preowned
                            ?.maximumAmountCardPayment
                        : ''
                    }
                    onChange={(event) =>
                      handleChangeMinimumAmount('preowned', event)
                    }
                    className={classes.minimumTotalInput}
                    disabled={changeAmounstForAlls}
                  />
                </Grid>

                <Grid container item xs={12} md={6}>
                  <MuiCurrencyFormat
                    label="Seguros"
                    value={
                      minimumTotal?.all &&
                      minimumTotal?.all?.maximumAmountCardPayment
                        ? minimumTotal?.all?.maximumAmountCardPayment
                        : minimumTotal?.insurance
                            ?.maximumAmountCardPayment &&
                          minimumTotal?.insurance
                            ?.maximumAmountCardPayment !== 0
                        ? minimumTotal?.insurance
                            ?.maximumAmountCardPayment
                        : ''
                    }
                    onChange={(event) =>
                      handleChangeMinimumAmount('insurance', event)
                    }
                    className={classes.minimumTotalInput}
                    disabled={changeAmounstForAlls}
                  />
                </Grid>

                <Grid container item xs={12} md={6}>
                  <MuiCurrencyFormat
                    label="Accesorios"
                    value={
                      minimumTotal?.all &&
                      minimumTotal?.all?.maximumAmountCardPayment
                        ? minimumTotal?.all?.maximumAmountCardPayment
                        : minimumTotal?.accessories
                            ?.maximumAmountCardPayment &&
                          minimumTotal?.accessories
                            ?.maximumAmountCardPayment !== 0
                        ? minimumTotal?.accessories
                            ?.maximumAmountCardPayment
                        : ''
                    }
                    onChange={(event) =>
                      handleChangeMinimumAmount('accessories', event)
                    }
                    className={classes.minimumTotalInput}
                    disabled={changeAmounstForAlls}
                  />
                </Grid>

                <Grid container item xs={12} md={6}>
                  <MuiCurrencyFormat
                    label="Hojalateria y pintura"
                    value={
                      minimumTotal?.all &&
                      minimumTotal?.all?.maximumAmountCardPayment
                        ? minimumTotal?.all?.maximumAmountCardPayment
                        : minimumTotal?.['h&p']
                            ?.maximumAmountCardPayment &&
                          minimumTotal?.['h&p']
                            ?.maximumAmountCardPayment !== 0
                        ? minimumTotal?.['h&p']
                            ?.maximumAmountCardPayment
                        : ''
                    }
                    onChange={(event) =>
                      handleChangeMinimumAmount('h&p', event)
                    }
                    className={classes.minimumTotalInput}
                    disabled={changeAmounstForAlls}
                  />
                </Grid>
              </Grid>

              <FormGroup row>
                <Button
                  disabled={changeAmounst}
                  variant="contained"
                  size="medium"
                  color="primary"
                  className={classes.marginMinimunBtn}
                  onClick={handleSubmitNewAmounts}
                >
                  Guardar montos mínimos
                </Button>
              </FormGroup>
            </FormGroup>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  const renderLineConfig = () => {
    return (
      <Paper className={classes.paper}>
        <Grid container justify="space-evenly">
          <DialogTitle className={classes.title}>
            Configuraciones de la linea
          </DialogTitle>
          <Divider className={classes.divider} />
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.top}>
              Envios de WhatsApp
            </Typography>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    className={classes.switch}
                    checked={agencyData.isActiveGupshup}
                    disabled={!agencyData.whatsNotifications}
                    onChange={() =>
                      handleUpdateOption(
                        'isActiveGupshup',
                        !agencyData.isActiveGupshup,
                      )
                    }
                    color="primary"
                  />
                }
                label="Activar Gupshup"
              />
            </FormGroup>
            <FormGroup row>
              {/* <FormControlLabel
                control={
                  <Switch
                    className={classes.switch}
                    disabled={
                      !agencyData.isActiveGupshup ||
                      !agencyData.whatsNotifications
                    }
                    checked={agencyData.isWhatsAppCustom}
                    onChange={() =>
                      handleUpdateOption(
                        'isWhatsAppCustom',
                        !agencyData.isWhatsAppCustom,
                      )
                    }
                    color="primary"
                  />
                }
                label="Activar template personalizado"
              /> */}
            </FormGroup>
            <Typography variant="h6" className={classes.top}>
              Linea
            </Typography>
            <FormGroup row>
              <Grid
                item
                button
                xs={12}
                lg={5}
                className={classes.margin}
              >
                <TextField
                  label="source"
                  disabled={!agencyData.isActiveGupshup}
                  value={agencyLineConfig?.source}
                  onChange={handleChangeLineConfig('source')}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid
                item
                button
                xs={12}
                lg={5}
                className={classes.margin}
              >
                <TextField
                  label="apikey"
                  disabled={!agencyData.isActiveGupshup}
                  value={agencyLineConfig?.apikey}
                  onChange={handleChangeLineConfig('apikey')}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid
                item
                button
                xs={12}
                lg={5}
                className={classes.margin}
              >
                <TextField
                  label="appId"
                  disabled={!agencyData.isActiveGupshup}
                  value={agencyLineConfig?.appId}
                  onChange={handleChangeLineConfig('appId')}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid
                item
                button
                xs={12}
                lg={5}
                className={classes.margin}
              >
                <TextField
                  label="partnerAppToken"
                  disabled={!agencyData.isActiveGupshup}
                  value={agencyLineConfig?.partnerAppToken}
                  onChange={handleChangeLineConfig('partnerAppToken')}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid
                item
                button
                xs={12}
                lg={5}
                className={classes.margin}
              >
                <TextField
                  label="appName"
                  disabled={!agencyData.isActiveGupshup}
                  value={agencyLineConfig?.appName}
                  onChange={handleChangeLineConfig('appName')}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid
                item
                button
                xs={12}
                lg={5}
                className={classes.margin}
              >
                <FormGroup row>
                  <Button
                    disabled={changeLineConfig}
                    variant="contained"
                    size="medium"
                    color="primary"
                    className={classes?.marginLineBtn}
                    onClick={handleSubmitConfig}
                  >
                    Guardar Linea
                  </Button>
                </FormGroup>
              </Grid>
            </FormGroup>
            {/* {agencyData.isWhatsAppCustom ? (
              <>
                <Typography variant="h6" className={classes.top}>
                  Templates
                </Typography>
                <div>
                  <FormGroup row>
                    {agencyTemplatesConfig.map((item, index) => {
                      return (
                        <>
                          <Grid
                            item
                            button
                            xs={12}
                            lg={6}
                            className={classes.padding}
                          >
                            <Typography
                              variant="body1"
                              align="left"
                              className={classes.toptemplates}
                            >
                              {item.type}{' '}
                              {item.isKioskoTemplate
                                ? 'Kiosko'
                                : null}
                              {item.isInvoiceTemplate
                                ? 'nuevo flujo de facturacion'
                                : null}
                            </Typography>
                            <TextField
                              label="templateId"
                              value={item?.templateId}
                              onChange={(event) => {
                                handleUpdateTemplateId(
                                  event.target.value,
                                  item?.id,
                                );
                              }}
                              margin="normal"
                              variant="outlined"
                            />
                          </Grid>
                        </>
                      );
                    })}
                  </FormGroup>
                  <FormGroup row>
                    <Button
                      variant="contained"
                      size="medium"
                      color="primary"
                      className={classes.marginMinimunBtn}
                      onClick={handleSubmitTemplate}
                    >
                      Guardar Templates
                    </Button>
                  </FormGroup>
                </div>
              </>
            ) : null} */}
          </Grid>
        </Grid>
      </Paper>
    );
  };

  const renderConfigOrderType = () => {
    return (
      <Paper className={classes.paper}>
        <Grid container justifyContent="space-evenly">
          <DialogTitle className={classes.title}>
            Promociones por tipo de orden
          </DialogTitle>
          <Divider className={classes.divider} />
          <br />
          <Card container justify="flex-end">
            <CardContent>
              <div className={classes.inner}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell size={'small'} align={'left'}>
                        <Typography className={classes.titleTable}>
                          ID
                        </Typography>
                      </TableCell>
                      <TableCell size={'small'} align={'left'}>
                        <Typography className={classes.titleTable}>
                          Tipo
                        </Typography>
                      </TableCell>
                      <TableCell size={'small'} align={'left'}>
                        <Typography className={classes.titleTable}>
                          Tipo de orden
                        </Typography>
                      </TableCell>
                      <TableCell size={'small'} align={'left'}>
                        <Typography className={classes.titleTable}>
                          Estado orden
                        </Typography>
                      </TableCell>
                      <TableCell size={'small'} align={'left'}>
                        <Typography className={classes.titleTable}>
                          3 Meses
                        </Typography>
                      </TableCell>
                      <TableCell size={'small'} align={'left'}>
                        <Typography className={classes.titleTable}>
                          6 Meses
                        </Typography>
                      </TableCell>
                      <TableCell size={'small'} align={'left'}>
                        <Typography className={classes.titleTable}>
                          9 Meses
                        </Typography>
                      </TableCell>
                      <TableCell size={'small'} align={'left'}>
                        <Typography className={classes.titleTable}>
                          12 Meses
                        </Typography>
                      </TableCell>
                      <TableCell size={'small'} align={'left'}>
                        <Typography className={classes.titleTable}>
                          Fecha de creación
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {agencyOrdersTypeConfig?.length > 0 ? (
                      agencyOrdersTypeConfig.map((item) => (
                        <>
                          <TableRow key={item.id}>
                            <TableCell size={'small'}>
                              <Typography className={classes.itemRow}>
                                {item.id || '-'}
                              </Typography>
                            </TableCell>
                            <TableCell size={'small'}>
                              <Typography align={'left'}>
                                {item?.type?.toUpperCase() || '-'}
                              </Typography>
                            </TableCell>
                            <TableCell size={'small'}>
                              <Typography className={classes.itemRow}>
                                {typeOfPromotionMap(item.typeOrder) ||
                                  '-'}
                              </Typography>
                            </TableCell>
                            <TableCell size={'small'}>
                              <Typography className={classes.itemRow}>
                                {item.isActive
                                  ? 'Activo'
                                  : 'Inactivo'}
                              </Typography>
                            </TableCell>
                            <TableCell size={'small'}>
                              <Typography className={classes.itemRow}>
                                {item.promotionsByOrderType
                                  ? formatMoney(
                                      item.promotionsByOrderType[
                                        '3Promotion'
                                      ],
                                    )
                                  : '-'}
                              </Typography>
                            </TableCell>
                            <TableCell size={'small'}>
                              <Typography className={classes.itemRow}>
                                {item.promotionsByOrderType
                                  ? formatMoney(
                                      item.promotionsByOrderType[
                                        '6Promotion'
                                      ],
                                    )
                                  : '-'}
                              </Typography>
                            </TableCell>
                            <TableCell size={'small'}>
                              <Typography className={classes.itemRow}>
                                {item.promotionsByOrderType
                                  ? formatMoney(
                                      item.promotionsByOrderType[
                                        '9Promotion'
                                      ],
                                    )
                                  : '-'}
                              </Typography>
                            </TableCell>
                            <TableCell size={'small'}>
                              <Typography className={classes.itemRow}>
                                {item.promotionsByOrderType
                                  ? formatMoney(
                                      item.promotionsByOrderType[
                                        '12Promotion'
                                      ],
                                    )
                                  : '-'}
                              </Typography>
                            </TableCell>
                            <TableCell size={'small'}>
                              <Typography className={classes.itemRow}>
                                {item?.createdAt
                                  ? formatDate(item?.createdAt)
                                  : '-'}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell
                          style={{ padding: 0, borderWidth: 0 }}
                          colSpan={12}
                        >
                          <div className={classes.notFound}>
                            <Typography className={classes.itemRow}>
                              Sin resultados para mostrar
                            </Typography>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Paper>
    );
  };

  const renderDispersalsConfig = () => {
    return (
      <Paper className={classes.paper}>
        <Grid container justify="space-evenly">
          <DialogTitle className={classes.title}>
            Configuración de Dispersiones
          </DialogTitle>
          <Divider className={classes.divider} />
          <FormGroup row>
            {renderInputAgencyDetails(
              'CURP/RFC del beneficiario',
              agencyData.rfcCurpBeneficiario,
              'rfcCurpBeneficiario',
              'RFC del cliente',
            )}
            {renderInputAgencyDetails(
              'Nombre del ordenante',
              agencyData.nombreOrdenante,
              'nombreOrdenante',
            )}
            {renderInputAgencyDetails(
              'Cuenta del ordenante',
              agencyData.cuentaOrdenante,
              'cuentaOrdenante',
              'Agregar cuenta de Karlo',
            )}
            {renderInputAgencyDetails(
              'CURP/RFC del ordenante',
              agencyData.rfcCurpOrdenante,
              'rfcCurpOrdenante',
              'Cuenta de Karlo',
            )}
            {renderSpecialInputAgencyDetails(
              <TextField
                required
                select
                name="dispersions"
                label="Selecciona el tipo de dispersión"
                type="text"
                margin="normal"
                variant="outlined"
                value={agencyData.isConsolidatedPayment}
                onChange={handleChange('isConsolidatedPayment')}
              >
                <MenuItem key={'null'} value={'null'}>
                  {'Dispersión apagada'}
                </MenuItem>
                <MenuItem key={true} value={true}>
                  {'Dispersión por dia'}
                </MenuItem>
                <MenuItem key={false} value={false}>
                  {'Dispersión por operacion'}
                </MenuItem>
              </TextField>,
            )}
            {renderInputAgencyDetails(
              'Institución contraparte',
              agencyData.institucionContraparte,
              'institucionContraparte',
            )}
            {renderInputAgencyDetails(
              'Tipo de cuenta del beneficiario',
              agencyData.tipoCuentaBeneficiario,
              'tipoCuentaBeneficiario',
            )}
            {renderInputAgencyDetails(
              'Centro Costo',
              agencyData.centroCosto,
              'centroCosto',
              'Es el creado en STP (nombre)',
            )}
            {renderInputAgencyDetails(
              'Afiliacion TPV',
              agencyData.membershipTpv,
              'membershipTpv',
            )}
            {renderInputAgencyDetails(
              'Nombre del beneficiario',
              agencyData.nombreBeneficiario,
              'nombreBeneficiario',
            )}
            {renderInputAgencyDetails(
              'Cuenta del beneficiario',
              agencyData.cuentaBeneficiario,
              'cuentaBeneficiario',
            )}
            <Grid
              item
              xs={12}
              sm={4}
              lg={3}
              className={classes.margin}
            >
              {
                <TextField
                  required
                  select
                  name="institucionContraparte"
                  label="Institución de dispersión "
                  type="text"
                  margin="normal"
                  variant="outlined"
                  value={agencyData.institucionContraparte}
                  onChange={handleChange('institucionContraparte')}
                >
                  {institutionDispersion.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              }
            </Grid>
            <Grid
              item
              button
              xs={12}
              lg={5}
              className={classes.margin}
            >
              <FormGroup row>
                <Button
                  disabled={showButton}
                  variant="contained"
                  size="medium"
                  color="primary"
                  className={classes.saveDispersalsButton}
                  onClick={handleClickOpen}
                >
                  Guardar
                </Button>
              </FormGroup>
            </Grid>
          </FormGroup>
        </Grid>
      </Paper>
    );
  };

  const renderSurchargeConfigFiserv = (promotion, index) => {
    return (
      <>
        <Grid container justifyContent="space-evenly">
          <Accordion
            style={{ width: '100%', mminWidth: '60%' }}
            elevation={0}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              style={{
                padding: '0px 10px 10px 10px',
              }}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                {promotion.membershipType.toUpperCase()}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    fullWidth
                    label="3 meses"
                    InputLabelProps={{ shrink: true }}
                    name="threeMonths"
                    onChange={(event) => {
                      const { value } = event.target;
                      const newState =
                        cleanAgencyByMembershipToSee.map((obj) => {
                          if (
                            obj.membershipType ===
                            promotion.membershipType
                          ) {
                            return { ...obj, threeMonths: value };
                          }
                          return obj;
                        });
                      setCleanAgencyByMembershipToSee(newState);
                    }}
                    value={promotion?.threeMonths}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <TextField
                    fullWidth
                    label="6 meses"
                    InputLabelProps={{ shrink: true }}
                    name="sixMonths"
                    onChange={(event) => {
                      const { value } = event.target;
                      const newState =
                        cleanAgencyByMembershipToSee.map((obj) => {
                          if (
                            obj.membershipType ===
                            promotion.membershipType
                          ) {
                            return { ...obj, sixMonths: value };
                          }
                          return obj;
                        });
                      setCleanAgencyByMembershipToSee(newState);
                    }}
                    value={promotion?.sixMonths}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <TextField
                    fullWidth
                    label="9 meses"
                    InputLabelProps={{ shrink: true }}
                    name="nineMonths"
                    onChange={(event) => {
                      const { value } = event.target;
                      const newState =
                        cleanAgencyByMembershipToSee.map((obj) => {
                          if (
                            obj.membershipType ===
                            promotion.membershipType
                          ) {
                            return { ...obj, nineMonths: value };
                          }
                          return obj;
                        });
                      setCleanAgencyByMembershipToSee(newState);
                    }}
                    value={promotion?.nineMonths}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <TextField
                    fullWidth
                    label="12 meses"
                    InputLabelProps={{ shrink: true }}
                    name="twelveMonths"
                    onChange={(event) => {
                      const { value } = event.target;
                      const newState =
                        cleanAgencyByMembershipToSee.map((obj) => {
                          if (
                            obj.membershipType ===
                            promotion.membershipType
                          ) {
                            return { ...obj, twelveMonths: value };
                          }
                          return obj;
                        });
                      setCleanAgencyByMembershipToSee(newState);
                    }}
                    value={promotion?.twelveMonths}
                  />
                </Grid>
                <Grid
                  item
                  button
                  xs={12}
                  lg={5}
                  className={classes.marginMinimunBtn}
                >
                  <FormGroup row>
                    <Button
                      variant="contained"
                      size="medium"
                      color="primary"
                      className={classes.marginMinimunBtn}
                      onClick={() =>
                        handleUpdateItem(
                          cleanAgencyByMembershipToSee[index],
                        )
                      }
                    >
                      Guardar
                    </Button>
                  </FormGroup>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Divider className={classes.divider} />
      </>
    );
  };

  const renderPromotionsCard = () => {
    return (
      <div>
        <Paper className={classes.paper}>
          <Grid container justify="space-evenly">
            <DialogTitle className={classes.title}>
              Configuración de Sobretasas Fiserv
            </DialogTitle>
            <Divider className={classes.divider} />
            {cleanAgencyByMembershipToSee.length > 0 &&
              cleanAgencyByMembershipToSee.map((x, index) => {
                // Paso 3
                return (
                  <React.Fragment key={index}>
                    {renderSurchargeConfigFiserv(x, index)}
                  </React.Fragment>
                );
              })}
          </Grid>
        </Paper>
      </div>
    );
  };

  const renderEditablePromotionsCard = () => {
    return (
      <div>
        <Paper className={classes.paper}>
          <Grid container spacing={2} justify="space-evenly">
            <DialogTitle className={classes.title}>
              Configuración de Comisiones
            </DialogTitle>
            <Divider className={classes.divider} />
            {agencyCommissionsConfig.map((promotion, index) => (
              <Grid item xs={3} key={promotion.id}>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>
                      {promotion.origin.toUpperCase()} -{' '}
                      {promotion.formatPayment.toUpperCase()}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          label="Comision por transaccion"
                          margin="normal"
                          variant="outlined"
                          placeholder="Default 0.00 (0%)"
                          value={promotion.transactionFeePercentage}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              'transactionFeePercentage',
                              e.target.value,
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Comision Bancaria"
                          margin="normal"
                          variant="outlined"
                          placeholder="Default 0 ($0)"
                          value={promotion.monetaryBankCommission}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              'monetaryBankCommission',
                              e.target.value,
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Comision Karlo"
                          margin="normal"
                          variant="outlined"
                          placeholder="Default 0 ($0)"
                          value={promotion.monetaryKarloCommission}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              'monetaryKarloCommission',
                              e.target.value,
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: 'left' }}>
                        <FormControlLabel
                          control={
                            <Switch
                              className={classes.switch}
                              checked={promotion.isActive}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  'isActive',
                                  e.target.checked,
                                )
                              }
                              color="primary"
                            />
                          }
                          label="Activo"
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ))}
          </Grid>
          <Grid item button xs={12} lg={5} className={classes.margin}>
            <FormGroup row>
              <Button
                disabled={!changeCommissionConfig}
                variant="contained"
                size="medium"
                color="primary"
                className={classes?.marginLineBtn}
                onClick={handleClickOpenCommissions}
              >
                Guardar Comisiones
              </Button>
            </FormGroup>
          </Grid>
        </Paper>
      </div>
    );
  };

  return isEmpty(agencyData) ? (
    <SplashScreen></SplashScreen>
  ) : (
    <div>
      {renderDataAgency()}
      <br />
      {renderConfig()}
      <br />
      {renderLineConfig()}
      <br />
      {renderDispersalsConfig()}
      <br />
      {renderConfigOrderType()}
      <br />
      {renderEditablePromotionsCard()}
      <br />
      {agencyData.paymentAggregators === 'fiserv'
        ? renderPromotionsCard()
        : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  agencies: state.agencies,
  role: makeSelectUserRoleFromState(state),
});

const mapDispatchToProps = {
  closeModals,
  createOrUpdateTypeOrders,
  getAgency,
  updateAgency,
  updateAgencyMembership,
  upLoadAmounts,
  updateCommissions,
  updateLineConfig,
  updloadLogo,
  updateOption,
  updateTemplate,
  updateTemplateById,
};

export default withRouter(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(DetailAgency),
  ),
);
