import React, { Fragment, useState, useEffect } from 'react';

import {
  Button,
  TextField,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: theme.spacing(7, 10, 1, 10),
  },
  funtions: {
    margin: '40px 0',
    width: '100%',
  },
  textField: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  submitButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
}));

const FillDataBusinessPro = (props) => {
  const { handleUpdateODSDMS, dataDMS } = props;
  const classes = useStyles();
  return (
    <Fragment>
      <Grid item xs={12} md={12} className={classes.funtions}>
        <Typography variant="h4" gutterBottom>
          Información del DMS: Business Pro
        </Typography>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="DMS"
          fullWidth
          value={dataDMS.DMS}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="DMS de la agencia."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="Últimos dígitos de la tarjeta"
          fullWidth
          value={dataDMS.UltimosDigitosTarjeta}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="Últimos dígitos de la tarjeta."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="Tarjetahabiente"
          fullWidth
          value={dataDMS.TarjetaHabiente}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="Tarjetahabiente."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="Referencia"
          fullWidth
          value={dataDMS.Referencia}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="Referencia."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="Fecha de operación"
          fullWidth
          value={dataDMS.FechaHoraOperacion}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="Fecha de operación."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="Forma de pago"
          fullWidth
          value={dataDMS.FormaPago}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="Forma de pago."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="Id tipo de tarjeta"
          fullWidth
          value={dataDMS.IdTipoTarjeta}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="Id tipo de tarjeta."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="Banco"
          fullWidth
          value={dataDMS.Banco}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="Banco."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="$ Monto de operación"
          fullWidth
          value={dataDMS.MontoOperacion}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="Monto de operación."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="$ Total"
          fullWidth
          value={dataDMS.Total}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="Total."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="Id Banco"
          fullWidth
          value={dataDMS.IdBanco}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="Id Banco."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="Forma de pago SAT"
          fullWidth
          value={dataDMS.FormaPagoSAT}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="Forma de pago SAT."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="Tipo de pago"
          fullWidth
          value={dataDMS.TipoPago}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="Tipo de pago."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="Concepto del pago"
          fullWidth
          value={dataDMS.ConceptoPago}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="Concepto del pago."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="Agregador"
          fullWidth
          value={dataDMS.Agregador}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="Agregador."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="Concepto de anticipo"
          fullWidth
          value={dataDMS.ConceptoAnticipo}
          disabled={true}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          helperText="Concepto de anticipo."
        ></TextField>
      </Grid>
      <Grid item xs={12} md={2} className={classes.funtions}>
        <div className={classes.submitButtonWrapper}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleUpdateODSDMS()}
          >
            Facturar
          </Button>
        </div>
      </Grid>
    </Fragment>
  );
};

export default FillDataBusinessPro;
