import 'react-table/react-table.css';
import 'moment/locale/es';

/* eslint-disable react/display-name */
import {
  Button,
  Badge as MaterialBadge,
  Paper,
  IconButton,
  TextField,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import { getKarloQuotes } from 'app/features/karloQuotes/karloQuotesSlice';

import { Link } from 'react-router-dom';
import React from 'react';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { injectIntl } from 'react-intl';
import { makeSelectKarloQuotes } from 'app/features/karloQuotes/selectors';
import { useForm } from 'react-hook-form';
import Add from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(0),
  },
  notifications: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  lastUpdated: {
    marginLeft: theme.spacing(2),
    color: '#434349',
  },
  datePickerTextfield: {
    padding: '5px 5px 5px !important',
    height: '20px',
  },
}));

function KarloQuotesTable(props) {
  const classes = useStyles();
  const {
    role,
    getKarloQuotes,
    karloQuotes,
    setActionButton,
  } = props;
  const [karloQuotesData, setKarloQuotesData] = React.useState([]);
  const reactTable = React.useRef(null);

  const handleFetchData = debounce(
    (state, instance) => {
      const { page, pageSize, sorted, filtered } = state;
      getKarloQuotes({ page, pageSize, sorted, filtered });
    },
    1000,
    { leading: false, trailing: true },
  );

  React.useEffect(() => {
    console.log('karloQuotesFROMDB ', karloQuotes);
    if (Array.isArray(karloQuotes.fromdb)) {
      const cleanData = karloQuotes.fromdb.map((user) => {
        return {
          id: user.id,
          plates: user.plates,
          brand: user.brand,
          model: user.model,
          year: user.year,
          version: user.version,
          vin: user.vin ? user.vin : 'n/a',
          client: user.user.length != 0 ? user.user.name : 'n/a',
          agency: user.agency.length != 0 ? user.agency.name : 'n/a',
        };
      });
      setKarloQuotesData(cleanData);
    }
  }, [karloQuotes]);

  const columns = [
    {
      Header: () => <strong>ID</strong>,
      accessor: 'id',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value}
        </div>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Titulo</strong>,
      accessor: 'title',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value}
        </div>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Descripión</strong>,
      accessor: 'desciption',
      Cell: (row) => (
        <Link to={`/karlovehicle-detail/`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Fecha</strong>,
      accessor: 'createdAt',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value}
        </div>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Vigencia</strong>,
      accessor: 'vigency',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value}
        </div>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Agencia</strong>,
      accessor: 'agency',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value}
        </div>
      ),
      filterable: false,
    },
    {
      Header: '',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <Tooltip title="Editar oferta">
            <IconButton
              className={classes.button}
              size="small"
              onClick={() => {
                console.log();
              }}
            >
              <i className="far fa-edit"></i>
            </IconButton>
          </Tooltip>
          <Tooltip title="Borrar oferta">
            <IconButton
              className={classes.button}
              size="small"
              onClick={() => {
                console.log();
              }}
            >
              <i className="far fa-trash-alt"></i>
            </IconButton>
          </Tooltip>
        </div>
      ),
      filterable: false,
    },
  ];

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <ReactTable
          ref={reactTable}
          NoDataComponent={() => (
            <div className="rt-noData">Sin resultados</div>
          )}
          manual
          onFetchData={handleFetchData}
          data={karloQuotesData}
          pages={karloQuotes.tablePages}
          columns={columns}
          // filterable
          className="-striped -highlight"
          previousText="Anterior"
          nextText="Siguiente"
          loading={karloQuotes.isLoading}
          loadingText="Cargando datos..."
        />
      </Paper>
    </div>
  );
}

const mapStateToProps = (state) => ({
  karloQuotes: makeSelectKarloQuotes(state),
});

const mapDispatchToProps = {
  getKarloQuotes,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(KarloQuotesTable),
);
