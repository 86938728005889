import { createSelector } from 'reselect';
import { getNested } from '../../utils';
import { initialState } from './initialState';

export const selectAdvances = (state) =>
  state.advances || initialState;

export const makeSelectAdvancesIsLoading = createSelector(
  selectAdvances,
  (AdvancesState) => AdvancesState.isLoading,
);

export const makeSelectAdvances = createSelector(
  selectAdvances,
  (AdvancesState) => getNested([], AdvancesState, 'fromdb'),
);

export const makeSelectAdvanceDetails = createSelector(
  selectAdvances,
  (AdvancesState) => AdvancesState.advanceDetails,
);

export const makeSelectCancelAdvances = createSelector(
  selectAdvances,
  (AdvancesState) => getNested([], AdvancesState, 'cancelAdvances'),
);
