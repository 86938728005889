import { call, put } from 'redux-saga/effects';
import {
  createNotificationFail,
  createNotificationSuccess,
  sendNotificationFail,
  sendNotificationSuccess,
} from 'app/features/notifications/notificationsSlice';

import { requestHandler } from '../../services/requestHandler';

export function* createNotificationSaga(action) {
  try {
    const { message, date, fetchData } = action.payload;
    const data = {
      message,
      date,
      fetchData,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/notification/schedule',
      data,
    });
    yield put(createNotificationSuccess());
  } catch (e) {
    yield put(createNotificationFail());
    console.log('error in get user BySuperAdmin saga', e);
  }
}

export function* sendNotificationSaga(action) {
  const {
    phoneNumber,
    email,
    id,
    isOdp,
    type,
    isReservation,
    lada,
  } = action.payload;
  let path;
  try {
    const data = {
      email,
      phoneNumber,
      id,
      isOdp,
      isReservation,
      lada,
    };

    switch (type) {
      case 'sms':
        path = '/notification/create';
        break;
      case 'whatsapp':
        path = '/notification/whatsapp';
        break;
      case 'notify':
        path = '/notification/notify';
        break;
      default:
        path = '/notification/create';
        break;
    }
    const response = yield call(requestHandler, {
      method: 'POST',
      path,
      data,
    });
    const message = {
      title: 'Exito!',
      desc: `El ${type} se envió correctamente.`,
    };
    yield put(sendNotificationSuccess({ message }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba enviar el correo.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(sendNotificationFail({ message }));
    console.log('error in send notification saga');
  }
}
