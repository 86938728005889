import { closeModals } from '../modals/modalsSlice';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { logout } from '../auth/authSlice';

const speiPaymentsSlice = createSlice({
  name: 'speiPayments',
  initialState,
  reducers: {
    getAllSpeiPayments: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getAllSpeiPaymentsSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.fromdb = action.payload.payments;
        state.tablePages = action.payload.payments.pages;
      },
    },
    getAllSpeiPaymentsFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    getSpeiPaymentDetail: {
      reducer(state) {
        state.isLoading = true;
        state.paymentDetails = null;
      },
    },
    getSpeiPaymentDetailSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.paymentDetails = action.payload.payment;
      },
    },
    getSpeiPaymentDetailFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    searchInSpeiPayments: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    searchInSpeiPaymentsSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.paymentsSearchedFromdb = action.payload.payments;
      },
    },
    searchInSpeiPaymentsFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    validateSpeiPayment: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    validateSpeiPaymentSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.paymentDetails = action.payload.payment;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    validateSpeiPaymentFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    cleanSpeiPaymentsLoaded: {
      reducer(state, action) {
        state.fromdb = null;
      },
    },
  },
  extraReducers: {
    [logout]: (state, action) => {
      return initialState;
    },
    [closeModals]: (state, action) => {
      state.isLoading = false;
      state.successModal = initialState.successModal;
      state.errorModal = initialState.errorModal;
    },
  },
});

export const {
  getAllSpeiPayments,
  getAllSpeiPaymentsSuccess,
  getAllSpeiPaymentsFail,
  getSpeiPaymentDetail,
  getSpeiPaymentDetailSuccess,
  getSpeiPaymentDetailFail,
  searchInSpeiPayments,
  searchInSpeiPaymentsSuccess,
  searchInSpeiPaymentsFail,
  validateSpeiPayment,
  validateSpeiPaymentSuccess,
  validateSpeiPaymentFail,
  cleanSpeiPaymentsLoaded,
} = speiPaymentsSlice.actions;

export default speiPaymentsSlice.reducer;
