import { call, put, select } from 'redux-saga/effects';
import { requestHandler } from '../../services/requestHandler';
import { formatDatesForFilter } from 'app/utils';
import {
  getResrvationsSuccess,
  getResrvationsFail,
  getResrvationDeatilSuccess,
  getResrvationDeatilFail,
  createReservationFail,
  createReservationSuccess,
  getAllPaymentsSuccess,
  updatePaymentOrderSuccess,
  updatePaymentOrderFail,
} from './reservationsSlice';
import {
  makeSelectAgencyId,
  makeSelectUserId,
  makeSelectUserRole,
} from '../auth/selectors';

export function* getReservationsSaga(action) {
  const { pageSize, agencies, user, isMSC = null } = action.payload;
  try {
    const data = {
      search: null,
      filter: null,
      isMSC,
      offset: 0,
      limit: 500,
      field: 'createdAt',
      ordering: 'DESC',
    };
    let result;
    let page;
    if (
      user.user.role === 'Super Admin' ||
      agencies?.agencyDetails?.nickname == 'WorldTravelFun' ||
      agencies?.agencyDetails?.nickname == 'WORLDTRAVELANDFUN'
    ) {
      const reservations = yield call(requestHandler, {
        method: 'POST',
        path: '/reservations/getAll',
        data,
      });
      result = reservations?.reservations
        ? reservations?.reservations
        : [];
      page = yield call(Math.ceil, result.length / pageSize);
    } else {
      result = [];
      page = 1;
    }
    yield put(getResrvationsSuccess({ result, page }));
  } catch (error) {
    console.log('Error in reservation saga', error);
    yield put(getResrvationsFail({}));
  }
}

export function* getAllPaymentsSaga(action) {
  try {
    const {
      firstDate,
      secondDate,
      page,
      pageSize,
      filtered,
    } = action.payload;
    const userRole = yield select(makeSelectUserRole());
    const adminID = yield select(makeSelectUserId());
    const agencyID = yield select(makeSelectAgencyId());
    const data = {
      paymentOrders: {
        agencies: {
          where: {},
        },
        where: {
          type: 'reservation',
        },
      },
      options: {
        count: true,
        distinct: true,
      },
      pagination: {
        page,
        perPage: pageSize,
        order: [['date', 'DESC']],
      },
      where: {
        status: 'DONE',
        paymentOrderID: { $not: null },
      },
    };

    if (firstDate && secondDate) {
      data.where = {
        ...data.where,
        date: formatDatesForFilter(firstDate, secondDate),
      };
    }
    const payments = yield call(requestHandler, {
      method: 'POST',
      path: '/payment/getAll',
      data,
    });
    payments.pages = yield call(Math.ceil, payments.count / pageSize);
    yield put(getAllPaymentsSuccess({ payments }));
  } catch (error) {
    console.log('Error in get all payments saga', error);
  }
}

export function* getDeatilReservationSaga(action) {
  const { id } = action.payload;
  try {
    const data = {
      id,
    };
    const reservationDetail = yield call(requestHandler, {
      method: 'POST',
      path: '/reservations/getById',
      data,
    });
    yield put(getResrvationDeatilSuccess({ reservationDetail }));
  } catch (error) {
    console.log('Error in detail reservation saga', error);
    yield put(getResrvationDeatilFail({}));
  }
}

export function* updatePaymentOrderReservationSaga(action) {
  try {
    const { status, id, reference } = action.payload;
    const data = {
      id,
      status,
    };
    const paymentOrder = yield call(requestHandler, {
      method: 'POST',
      path: '/paymentOrder/update',
      data,
    });
    const message = {
      title: '¡Éxito!',
      desc: `La orden de pago ${reference} ha sido cancelada.`,
    };
    yield put(
      updatePaymentOrderSuccess({
        message,
        paymentOrder,
      }),
    );
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba cancelar la orden de pago.
      Intente de nuevo, si el error persiste intente más tarde.`,
    };
    yield put(updatePaymentOrderFail({ message }));
    console.log('error canceling payment order: ', e);
  }
}

export function* createReservationSaga(action) {
  const payload = action.payload.formData;
  const agencyId = yield select(makeSelectAgencyId());
  try {
    let idAgency;
    if (agencyId) {
      idAgency = agencyId;
    } else {
      const data = {
        pagination: { order: [['updatedAt', 'DESC']] },
      };
      const agenciesList = yield call(requestHandler, {
        method: 'POST',
        path: '/agency/getAll',
        data,
      });
      const getAgency = agenciesList?.filter(
        (el) =>
          el.nickname === 'WORLDTRAVELANDFUN' ||
          el.nickname === 'WorldTravelFun',
      );
      idAgency = getAgency[0]?.id;
    }
    const data = {
      agencyId: idAgency,
      orderId: payload?.data?.orderNum,
      numberReservation: payload?.data?.reservationNum,
      total: payload?.total,
      customerName: payload?.data?.clientName,
      customerPhone: payload?.data?.telephone,
      customerEmail: payload?.data?.email,
      numberNights: payload?.data?.numberNights,
      numberPax: payload?.data?.pax,
      address: payload?.data?.address,
      typeOfUnit: payload?.data?.unitType,
      checkIn: payload?.checkInn,
      checkOut: payload?.checkout,
      resort: payload?.resort === 'otro' ? payload?.data?.otherPort : payload?.resort,
      typeOrder: payload?.typeOrder,
      comments: payload?.data?.coments,
    };
    const createReservation = yield call(requestHandler, {
      method: 'POST',
      path: '/reservations/create',
      data,
    });
    let redirect = '';
    if(payload?.data?.unitType === 'MSC'){
      redirect = '/reservations-cruise';
      
    }else{
      redirect = '/reservations';
    }
    const mesaage = {
      title: '¡Éxito!',
      desc: `La reservación ha sido creada correctamente.`,
    };
    yield put(createReservationSuccess({ redirect, mesaage }));
  } catch (error) {
    console.log('Error in create reservation SAGA -----', error);
    const redirect = '/reservations';
    const mesaage = {
      title: 'Error al crear la orden!',
      desc: `Ya se encuentra registrada esta orden.`,
    };
    yield put(createReservationFail({ redirect, mesaage }));
  }
}
