import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { logout } from '../auth/authSlice';

const logsSlice = createSlice({
  name: 'logs',
  initialState,
  reducers: {
    getLogs: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getLogsSuccess: {
      reducer(state, action) {
        const { logs, object } = action.payload;
        if (object)
          return {
            ...state,
            isLoading: false,
            [object]: logs,
          };
        else
          return {
            ...state,
            isLoading: false,
            fromdb: logs,
          };
      },
    },
    getLogsFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.error = action.payload.error;
      },
    },
    resetStore: {
      reducer(state) {
        return initialState;
      },
    },
  },
  extraReducers: {
    [logout]: (state, action) => {
      return initialState;
    },
  },
});

export const {
  getLogs,
  getLogsSuccess,
  getLogsFail,
} = logsSlice.actions;

export default logsSlice.reducer;
