import {
  Button,
  Grid,
  MenuItem,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React, { useEffect } from 'react';
import {
  getAgencies,
  getAgency,
} from '../../../agencies/agenciesSlice';
import {
  makeSelectAgencyIdFromState,
  makeSelectUserRoleFromState,
} from '../../../auth/selectors';

import Input from 'app/components/Input';
import MUIRHFAutocompleteAgencies from 'app/components/MUIRHFAutocompleteAgencies';
import Modal from '../../../../components/Modal';
import MomentUtils from '@date-io/moment';
import { MuiCurrencyFormat } from 'app/components/MUICurrencyFormat';
import { RHFMUISelect } from 'app/components/RHF';
import ShouldItRender from '../../../../components/ShouldItRender';
import { cleanEmptyKeys } from '../../../../utils';
import { closeModals } from '../../../modals/modalsSlice';
import { connect } from 'react-redux';
import { createCreditOrder } from '../../creditOrdersSlice';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import { withRouter } from 'react-router';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(5, 5, 3, 5),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5, 5, 3, 5),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(5, 10, 3, 10),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(5, 10, 3, 10),
    },
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  client: {
    padding: theme.spacing(0),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

function AddCreditOrder(props) {
  const classes = useStyles();
  const {
    creditOrders,
    closeModals,
    role,
    agencyID,
    createCreditOrder,
    history,
    intl,
  } = props;
  const [showWarningTotal, setShowWarningTotal] = React.useState(
    false,
  );

  const {
    handleSubmit,
    register,
    errors,
    control,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      agencyID: '',
      total: '',
      terms: '',
      termType: '',
      startAt: null,
      invoiceNumber: '',
      clientName: '',
      clientEmail: '',
      clientPhoneNumber: '',
      clientID: '',
      downPayment: {
        total: '',
      },
    },
  });

  const startAt = watch('startAt');
  const terms = watch('terms');
  const termType = watch('termType');
  const total = watch('total');
  const downPayment = watch('downPayment');

  useEffect(() => {
    props.setLeftSideComponent(
      <h3 className="kt-subheader__title">
        Añadir orden de crédito
      </h3>,
    );
    if (role === 'Super Admin') {
      props.getAgencies();
    } else {
      props.getAgency(agencyID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseModals = (redirect) => {
    closeModals({ history, redirect });
  };

  const toggleWarningTotal = () => {
    setShowWarningTotal(!showWarningTotal);
  };

  const renderLimitMinAmountDialog = () => {
    return (
      <Modal
        open={showWarningTotal}
        type="warning"
        closeModal={toggleWarningTotal}
        dialogText="El precio total de la órden debe de ser mayor o igual a $11.00 pesos"
        actionButtonText="Aceptar"
        onClick={toggleWarningTotal}
      />
    );
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = creditOrders;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message, redirect } = creditOrders[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={() => handleCloseModals(redirect)}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={() => handleCloseModals(redirect)}
          />
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  const handleGetCreditTotal = () => {
    if (total && terms)
      return (parseFloat(total) / parseInt(terms)).toFixed(2);
    return '';
  };

  const calculateExpDate = () => {
    if (terms && termType && startAt) {
      const tempStartAt = moment(startAt);
      if (termType === 'semanal') {
        tempStartAt.add(terms, 'w');
      } else if (termType === 'quincenal') {
        tempStartAt.add(terms * 15, 'd');
      } else if (termType === 'mensual') {
        tempStartAt.add(terms, 'M');
      }
      return tempStartAt;
    }
    return null;
  };

  const handleLabelName = (termType) => {
    if (termType)
      return intl
        .formatMessage({
          id: `CREDIT_ORTDER.TERM_TYPE.${termType}`,
        })
        .toLowerCase();
    return '';
  };

  const handleCreateCreditOrder = (formData) => {
    let cleanValues = cleanEmptyKeys(formData);
    if (cleanValues.downPayment.total === '')
      delete cleanValues.downPayment;
    createCreditOrder(cleanValues);
  };

  return (
    <Paper className={classes.paper}>
      <form
        className={classes.container}
        onSubmit={handleSubmit(handleCreateCreditOrder)}
        noValidate
        autoComplete="off"
      >
        <ShouldItRender
          locationPage={props.history.location.pathname}
          action="selectAgency"
        >
          <Grid item xs={12} className={classes.client}>
            <Typography variant="h6">Agencia</Typography>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <MUIRHFAutocompleteAgencies
                rules={{ required: true }}
                error={Boolean(errors.agencyID)}
                name="agencyID"
                control={control}
                fullWidth
                className={classes.textField}
              />
            </Grid>
          </Grid>
        </ShouldItRender>
        <Grid item xs={12} className={classes.client}>
          <Typography variant="h6">
            Información de la orden
          </Typography>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Controller
              name="total"
              control={control}
              className={classes.textField}
              render={(props) => (
                <MuiCurrencyFormat
                  label="Monto total"
                  helperText="Ingrese el monto total de la orden de crédito"
                  error={Boolean(errors.total)}
                  {...props}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              required
              ref={register({
                required: true,
              })}
              name="terms"
              type="number"
              label="Plazo"
              className={classes.textField}
              error={Boolean(errors.terms)}
              helperText="Ingrese a cuantos plazos será pagada la orden"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <RHFMUISelect
              name="termType"
              className={classes.textField}
              label="Tipo plazo"
              control={control}
              rules={{ required: true }}
              error={!!errors.termType}
              helperText="Selecciona un tipo de plazo"
            >
              <MenuItem value="semanal">Semanal</MenuItem>
              <MenuItem value="quincenal">Quincenal</MenuItem>
              <MenuItem value="mensual">Mensual</MenuItem>
            </RHFMUISelect>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiCurrencyFormat
              disabled
              value={handleGetCreditTotal()}
              label={`Cargo ${handleLabelName(termType)}`}
              className={classes.textField}
              error={Boolean(errors.total)}
              helperText="Ingrese el monto total de la orden de crédito"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="startAt"
              rules={{ required: true }}
              as={
                <MuiPickersUtilsProvider
                  utils={MomentUtils}
                  locale={'es'}
                >
                  <DatePicker
                    autoOk
                    fullWidth
                    disableFuture
                    disableToolbar
                    error={Boolean(errors.startAt)}
                    className={classes.textField}
                    format="DD/MM/YYYY"
                    label="Fecha inicio de crédito"
                    variant="inline"
                    inputVariant="outlined"
                    value={startAt}
                    onChange={(e) => setValue('startAt', e, false)}
                    helperText="Seleccione el día del primer pago"
                  />
                </MuiPickersUtilsProvider>
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider
              utils={MomentUtils}
              locale={'es'}
            >
              <DatePicker
                autoOk
                fullWidth
                disableToolbar
                disabled
                format="DD/MM/YYYY"
                className={classes.textField}
                label="Fecha fin de crédito"
                variant="inline"
                inputVariant="outlined"
                value={calculateExpDate()}
                onChange={(e) => {}}
                helperText="Se calcula automáticamente ingresando la Fecha Inicio"
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              inputRef={register}
              name="invoiceNumber"
              label="Número factura"
              error={Boolean(errors.invoiceNumber)}
              helperText="Ingrese el número de factura"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="downPayment.total"
              control={control}
              render={(props) => (
                <MuiCurrencyFormat
                  className={classes.textField}
                  label="Total del enganche"
                  error={Boolean(downPayment.total > total)}
                  helperText={
                    downPayment.total > total
                      ? 'El monto debe de ser menor a la cantidad total de la orden de crédito'
                      : 'Ingrese el monto total del engache'
                  }
                  {...props}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.client}>
          <Typography variant="h6">
            Información del cliente
          </Typography>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Input
              required
              ref={register({
                required: true,
              })}
              name="clientID"
              label="ID cliente"
              className={classes.textField}
              error={Boolean(errors.clientID)}
              helperText="Ingrese el ID del usuario"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              required
              ref={register({
                required: true,
              })}
              name="clientName"
              label="Nombre del cliente"
              className={classes.textField}
              error={Boolean(errors.clientName)}
              helperText="Ingrese nombre completo del cliente"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              required
              ref={register({
                required: true,
              })}
              name="clientEmail"
              label="Correo electrónico"
              className={classes.textField}
              error={Boolean(errors.clientEmail)}
              helperText="Ingrese la dirección de correo electrónico"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              required
              ref={register({
                required: true,
              })}
              name="clientPhoneNumber"
              label="Teléfono celular"
              className={classes.textField}
              error={Boolean(errors.clientPhoneNumber)}
              helperText="Ingrese el teléfono del usuario"
            />
          </Grid>
        </Grid>
        <Grid container className={classes.submitButton}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            // disabled={creditOrders.isLoading}
          >
            Crear orden de crédito
          </Button>
        </Grid>
      </form>
      {renderLimitMinAmountDialog()}
      {renderFeedBackModal()}
    </Paper>
  );
}

const mapStateToProps = (state) => ({
  creditOrders: state.creditOrders,
  agencies: state.agencies,
  role: makeSelectUserRoleFromState(state),
  agencyID: makeSelectAgencyIdFromState(state),
});

const mapDispatchToProps = {
  createCreditOrder,
  getAgencies,
  getAgency: getAgency,
  closeModals,
};

export default withRouter(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(AddCreditOrder),
  ),
);
