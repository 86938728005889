import { closeModals } from '../modals/modalsSlice';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { logout } from '../auth/authSlice';

const karloPromotionsSlice = createSlice({
  name: 'karloPromotions',
  initialState,
  reducers: {
    getKarloPromotions: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getKarloPromotionsSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.fromdb = action.payload.karloPromotions.promotions;
        state.tablePages = action.payload.karloPromotions.pages;
      },
    },
    getKarloPromotionsFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    deletePromotion: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    deletePromotionSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          ...action.payload,
        };
      },
    },
    deletePromotionFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    createPromotion: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    createPromotionSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          ...action.payload,
        };
      },
    },
    createPromotionFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    updatePromotion: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    updatePromotionSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          ...action.payload,
        };
      },
    },
    updatePromotionFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    getPromotionDetail: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getPromotionDetailSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.promotionDetail = action.payload.details.promotion;
      },
    },
    getPromotionDetailFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    uploadPromoThumbnail: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    uploadPromoThumbnailSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          ...action.payload,
        };
      },
    },
    uploadPromoThumbnailFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    uploadPromoDescription: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    uploadPromoDescriptionSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          ...action.payload,
        };
      },
    },
    uploadPromoDescriptionFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    handleClosePromoModals: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    handleClosePromoModalsSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = initialState.successModal;
        state.errorModal = initialState.errorModal;
      },
    },
  },
  extraReducers: {
    [logout]: (state, action) => {
      return initialState;
    },
  },
});

export const {
  getKarloPromotions,
  getKarloPromotionsSuccess,
  getKarloPromotionsFail,
  deletePromotion,
  deletePromotionSuccess,
  deletePromotionFail,
  createPromotion,
  createPromotionSuccess,
  createPromotionFail,
  updatePromotion,
  updatePromotionSuccess,
  updatePromotionFail,
  getPromotionDetail,
  getPromotionDetailSuccess,
  getPromotionDetailFail,
  uploadPromoThumbnail,
  uploadPromoThumbnailSuccess,
  uploadPromoThumbnailFail,
  uploadPromoDescription,
  uploadPromoDescriptionSuccess,
  uploadPromoDescriptionFail,
  handleClosePromoModals,
  handleClosePromoModalsSuccess,
} = karloPromotionsSlice.actions;

export default karloPromotionsSlice.reducer;
