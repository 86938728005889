/* eslint-disable react/display-name */
import {
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
  getAgency,
  getOrUpdateSurTaxes,
  updateAgency,
  updloadLogo,
} from '../../../agencies/agenciesSlice';
import { getSetuplogs } from '../../setupLogsSlice';
import {
  makeSelectAgencyDetailsFromState,
  selectAgencies,
} from 'app/features/agencies/selectors';

import Advertisements from 'app/features/setup/components/Advertisements';
import Comissions from '../../components/Comissions';
import GeneralConfig from '../../components/GeneralConfig';
import Modal from '../../../../components/Modal';
import Promotions from '../../components/Promotions';
import NewPromotions from '../../components/newPromotions';
import Activities from '../../components/Activities';
import SplashScreen from '../../../../partials/layout/SplashScreen';
import { TabPanel } from 'app/components/TabPanel';
import { closeModals } from 'app/features/modals/modalsSlice';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getNested } from '../../../../utils';
import { injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  agencyLogo: {
    margin: 'auto',
    marginTop: 20,
    height: 50,
    color: '#fff',
    overflow: 'visible',
  },
  title: {
    marginTop: 25,
  },
  subtitle: {
    marginTop: 10,
  },
  agency: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    margin: theme.spacing(0),
  },
}));

function ProfileAgency(props) {
  const classes = useStyles();
  const {
    agencies,
    agencyDetails,
    getAgency,
    updateAgency,
    updloadLogo,
    getSetuplogs,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState(null);
  const [tab, setTab] = React.useState(0);

  useEffect(() => {
    getAgency();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    handleSubmit,
    register,
    errors,
    control,
    reset,
  } = useForm();

  useEffect(() => {
    if (agencyDetails) {
      reset({
        minimunTotal: agencyDetails?.minimunTotal,
        amountLetter: agencyDetails?.amountLetter,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencyDetails]);

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const handleCloseModals = () => {
    handleClose();
    props.closeModals();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = agencies;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message } = agencies[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={handleCloseModals}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={handleCloseModals}
          />
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  return isEmpty(agencyDetails) ? (
    <SplashScreen></SplashScreen>
  ) : (
    <div className={classes.root}>
      {renderFeedBackModal()}
      <Grid container spacing={4}>
        <Grid item xs={12} md={3}>
          <Paper className={classes.paper}>
            <img
              alt="logo"
              src={getNested(null, agencyDetails, 'logo')}
              className={classes.agencyLogo}
            />
            <Typography
              variant="h5"
              gutterBottom
              className={classes.title}
            >
              {getNested(null, agencyDetails, 'name')}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={9}>
          <Paper className={classes.paperInfo}>
            <Grid container>
              <Grid item xs={12}>
                <Tabs
                  value={tab}
                  onChange={handleChangeTab}
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab label="Datos de agencia" />
                  <Tab label="comisiones" />
                  <Tab label="Promociones" />
                  <Tab label="Avisos" />
                  <Tab label="Registro de actividades" />
                </Tabs>
              </Grid>
            </Grid>
            <TabPanel value={tab} index={0}>
              <GeneralConfig
                agencyDetails={agencyDetails}
                getSetuplogs={getSetuplogs}
                data={data}
                agencies={agencies}
                setData={setData}
                handleClickOpen={handleClickOpen}
                open={open}
                updloadLogo={updloadLogo}
                updateAgency={updateAgency}
                handleClose={handleClose}
                control={control}
                register={register}
                errors={errors}
                handleSubmit={handleSubmit}
              />
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <Comissions agency={agencyDetails} />
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <NewPromotions />
            </TabPanel>
            <TabPanel value={tab} index={3}>
              <Advertisements
                agencyEvents={getNested(
                  [],
                  agencyDetails,
                  'agencyEvents',
                )}
                getSetuplogs={getSetuplogs}
                agencyDetails={agencyDetails}
              />
            </TabPanel>
            <TabPanel value={tab} index={4}>
              <Activities />
            </TabPanel>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  agencies: selectAgencies,
  agencyDetails: makeSelectAgencyDetailsFromState,
});

const mapDispatchToProps = {
  getAgency,
  updateAgency,
  updloadLogo,
  closeModals,
  getOrUpdateSurTaxes,
  getSetuplogs,
};

export default withRouter(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(ProfileAgency),
  ),
);
