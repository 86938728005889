import { call, put, select } from 'redux-saga/effects';
import {
  getServiceReviewsSuccess,
  getServiceReviewsFail,
  getServiceReviewsFiltersSuccess,
  getServiceReviewsFiltersFail,
} from './serviceReviewSlice';
import moment from 'moment';

import { requestHandler } from '../../services/requestHandler';

const filteredData = (filtered, dates) => {
  let where = {};
  if (dates !== '') {
    where = {
      ...where,
      date: {
        firstDate: moment(dates.firstDate).format(
          'YYYY-MM-DDT00:00:00',
        ),
        secondDate: moment(dates.secondDate).format(
          'YYYY-MM-DDT23:59:59',
        ),
      },
    };
  }

  filtered.forEach((filters) => {
    if (filters.value) {
      where = {
        ...where,
        [filters.id]: filters.value,
      };
    }
  });

  return where;
};

export function* getServiceReviewsSaga(action) {
  try {
    const {
      filtered,
      page,
      pageSize,
      firstDate,
      secondDate,
      tabSelected,
    } = action.payload;
    let data = {
      offset: page > 0 ? pageSize * page : 0,
      limit: pageSize,
      field: 'id',
      ordering: 'DESC',
    };

    if (filtered.length || (firstDate && secondDate)) {
      let dates = '';
      if (firstDate && secondDate) {
        dates = { firstDate, secondDate };
      }
      let where = filteredData(filtered, dates);
      where = {
        ...where,
        ['type']: tabSelected,
      };
      data = {
        ...data,
        where,
      };
    }else{
      let where = {
        ...where,
        ['type']: tabSelected,
      };
      data = {
        ...data,
        where,
      };
    }

    const reviews = yield call(requestHandler, {
      method: 'POST',
      path: '/serviceReview/getAll',
      data,
    });

    reviews.pages = yield call(Math.ceil, reviews.count / pageSize);
    yield put(getServiceReviewsSuccess({ reviews }));
    const { outPutFilter } = reviews;
    yield put(getServiceReviewsFiltersSuccess({ outPutFilter }));
  } catch (error) {
    console.log('Error in get service review saga', error);
    const { message } = error;
    yield put(getServiceReviewsFail({ message }));
  }
}
