import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';

import PropTypes from 'prop-types';
import React from 'react';
import error from '../../assets/img/assets/error.jpeg';
import success from '../../assets/img/assets/good.jpeg';
import warning from '../../assets/img/assets/warning.png';

const useStyles = makeStyles((theme) => ({
  feedBackStatus: {
    width: '60px',
    height: 'auto',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '30px',
  },
}));

const Modal = (props) => {
  const classes = useStyles();
  const {
    open,
    type,
    closeModal,
    dialogTitle,
    dialogText,
    dialogChildren,
    actionButtonText,
    onClick,
    form,
    ...rest
  } = props;

  const handleRenderIcon = () => {
    switch (type) {
      case 'success':
      case 'successModal':
        return (
          <img
            src={success}
            alt="logo"
            className={classes.feedBackStatus}
          />
        );
      case 'warning':
        return (
          <img
            src={warning}
            alt="logo"
            className={classes.feedBackStatus}
          />
        );

      case 'error':
      case 'errorModal':
        return (
          <img
            src={error}
            alt="logo"
            className={classes.feedBackStatus}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={open}
        onClose={closeModal}
        {...rest}
      >
        <DialogTitle onClose={closeModal}>{dialogTitle}</DialogTitle>
        <DialogContent>
          {handleRenderIcon()}
          <DialogContentText>{dialogText}</DialogContentText>
          {dialogChildren}
        </DialogContent>
        <DialogActions>
          {!type ? (
            <Button onClick={closeModal} color="primary">
              Cancelar
            </Button>
          ) : null}
          <Button
            type="submit"
            color="primary"
            form={form}
            onClick={onClick}
          >
            {actionButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

Modal.propTypes = {
  dialogTitle: PropTypes.string,
  dialogText: PropTypes.string,
  actionButtonText: PropTypes.string,
  open: PropTypes.bool,
  type: PropTypes.string,
  onClick: PropTypes.func,
  closeModal: PropTypes.func,
  dialogChildren: PropTypes.element,
  form: PropTypes.string,
};

export default Modal;
