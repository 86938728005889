import { Grid, Typography, makeStyles } from '@material-ui/core';
import {
  defaultComissions,
  monthsByComissions,
  defaultComissionsByAgregador,
  monthsByComissionsByAgregador,
} from '../../../../config/monthsTaxes';
import { getNested, roundValue, formatMoney, getFormatPaymentName } from '../../../../utils';

import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import amex from '../../../../assets/img/logos/amex.svg';
import mastercard from '../../../../assets/img/logos/mastercard.svg';
import oxxo from '../../../../assets/img/logos/oxxopay_brand.png';
import spei from '../../../../assets/img/logos/spei_brand.png';
import visa from '../../../../assets/img/logos/visa.svg';
import internacionales from '../../../../assets/img/logos/International.svg';

const useStyles = makeStyles((theme) => ({
  commission: {
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: 'black',
  },
  tables: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  leyendsContainer: {
    padding: 15,
  },
  visaMaster: {
    marginLeft: 10,
  },
  tdVertical: {
    verticalAlign: 'middle',
  },
}));

const Comissions = ({ agency }) => {
  const classes = useStyles();
  const commissions = agency.commissions;
  const agencyByMembership = agency.agencyByMembership;
  const [agencyByMembershipFormatted, setAgencyByMembershipFormatted] = useState({
    3: { months: 3 },
    6: { months: 6 },
    9: { months: 9 },
    12: { months: 12 },
  });
  useEffect(() => {
    if (agencyByMembership?.length > 0) {
      const updatedFormatted = { ...agencyByMembershipFormatted };

      agencyByMembership.forEach(item => {
        const { membershipType, threeMonths, sixMonths, nineMonths, twelveMonths } = item;

        updatedFormatted[3][membershipType] = threeMonths;
        updatedFormatted[6][membershipType] = sixMonths;
        updatedFormatted[9][membershipType] = nineMonths;
        updatedFormatted[12][membershipType] = twelveMonths;
      });

      setAgencyByMembershipFormatted(updatedFormatted);
    }
  }, [agencyByMembership]);

  const renderOxxo = () => {
    if (agency && agency.oxxoPayments)
      return (
        <tr>
          <td>
            <img src={oxxo} width="auto" height={28} alt="Oxxo" />
          </td>
          <td className={classes.tdVertical}>3.9% + IVA</td>
        </tr>
      );
    else return null;
  };

  const renderSpei = () => {
    if (agency && agency.SPEIPayments)
      return (
        <tr>
          <td>
            <img src={spei} width="auto" height={20} alt="Spei" />
          </td>
          <td className={classes.tdVertical}> $0 pesos </td>
        </tr>
      );
    else return null;
  };

  const renderCommissionsByCards = () => (
    <>
      <Grid item xs={12} md={12} className={classes.tables}>
        <Table responsive="xl" className={classes.tdVertical}>
          <thead>
            <tr>
              <th>Método de pago</th>
              <th>Comisión por transacción</th>
            </tr>
          </thead>
          <tbody>
            {commissions.map((commission, index) => {
              if (commission.origin === "ecommerce" && commission.isActive) {
                return (
                  <>
                    {
                      commission.formatPayment === "credit" || commission.formatPayment === "debit" ? (
                        <tr>
                          <td colSpan={2}>
                            {<Typography style={{ textAlign: 'center' }}>{getFormatPaymentName(commission.formatPayment)}</Typography>}
                          </td>
                        </tr>
                      )
                        :
                        null
                    }
                    <tr key={index}>
                      <td>
                        {
                          commission.formatPayment === "credit" ? (
                            <img src={visa} width={40} height={40} alt="Visa" />
                          )
                            : commission.formatPayment === "debit" ? (
                              <img src={visa} width={40} height={40} alt="visa" />
                            )
                              : null
                        }
                        {
                          commission.formatPayment === "credit" || commission.formatPayment === "credit-amex" || commission.formatPayment === "debit" ? (
                            <img
                              className={commission.formatPayment === "credit" || commission.formatPayment === "debit" ? classes.visaMaster : null}
                              src={commission.formatPayment === "credit" || commission.formatPayment === "debit" ? mastercard : amex}
                              width={40}
                              height={40}
                              alt="Mastercard"
                            />
                          )
                            : commission.formatPayment === "international" ? (
                              <img src={internacionales} width={40} height={40} alt="International" />
                            )
                              : null
                        }
                      </td>
                      <td className={classes.tdVertical}>
                        {roundValue(commission.transactionFeePercentage * 100)}%
                        {commission.monetaryBankCommission > 0
                          ? " + " + formatMoney(commission.monetaryBankCommission)
                          : ""}
                        {commission.monetaryKarloCommission > 0
                          ? " + " + formatMoney(commission.monetaryKarloCommission)
                          : ""}{" "}
                        + IVA
                      </td>
                    </tr>
                  </>);
              }
              return null;
            })}
            {renderOxxo()}
            {renderSpei()}
          </tbody>
        </Table>
      </Grid>
    </>
  );
  const renderCommissionsByCardsTpv = () => (
    <>
      <Grid item xs={12} md={12} className={classes.tables}>
        <Table responsive="xl" className={classes.tdVertical}>
          <thead>
            <tr>
              <th>Método de pago</th>
              <th>Comisión por transacción</th>
            </tr>
          </thead>
          <tbody>
            {commissions.map((commission, index) => {
              if (commission.origin === "tpv" && commission.isActive) {
                return (
                  <>
                    {
                      commission.formatPayment === "credit" || commission.formatPayment === "debit" ? (
                        <tr>
                          <td colSpan={2}>
                            {<Typography style={{ textAlign: 'center' }}>{getFormatPaymentName(commission.formatPayment)}</Typography>}
                          </td>
                        </tr>
                      )
                        :
                        null
                    }
                    <tr key={index}>
                      <td>
                        {
                          commission.formatPayment === "credit" ? (
                            <img src={visa} width={40} height={40} alt="Visa" />
                          )
                            : commission.formatPayment === "debit" ? (
                              <img src={visa} width={40} height={40} alt="visa" />
                            )
                              : null
                        }
                        {
                          commission.formatPayment === "credit" || commission.formatPayment === "credit-amex" || commission.formatPayment === "debit" ? (
                            <img
                              className={commission.formatPayment === "credit" || commission.formatPayment === "debit" ? classes.visaMaster : null}
                              src={commission.formatPayment === "credit" || commission.formatPayment === "debit" ? mastercard : amex}
                              width={40}
                              height={40}
                              alt="Mastercard"
                            />
                          )
                            : commission.formatPayment === "international" ? (
                              <img src={internacionales} width={40} height={40} alt="International" />
                            )
                              : null
                        }
                      </td>
                      <td className={classes.tdVertical}>
                        {roundValue(commission.transactionFeePercentage * 100)}%
                        {commission.monetaryBankCommission > 0
                          ? " + " + formatMoney(commission.monetaryBankCommission)
                          : ""}
                        {commission.monetaryKarloCommission > 0
                          ? " + " + formatMoney(commission.monetaryKarloCommission)
                          : ""}{" "}
                        + IVA
                      </td>
                    </tr>
                  </>);
              }
              return null;
            })}
          </tbody>
        </Table>
      </Grid>
    </>
  );
  const renderCommissionsByCardsKiosko = () => (
    <>
      <Grid item xs={12} md={12} className={classes.tables}>
        <Table responsive="xl" className={classes.tdVertical}>
          <thead>
            <tr>
              <th>Método de pago</th>
              <th>Comisión por transacción</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <img src={visa} width={40} height={40} alt="Visa" />
                <img
                  className={classes.visaMaster}
                  src={mastercard}
                  width={40}
                  height={40}
                  alt="Mastercard"
                />
              </td>
              <td className={classes.tdVertical}>
                {roundValue(
                  getNested(
                    defaultComissionsByAgregador['fiservKiosko']['visa/mastercard'],
                    agency,
                    'cardComission',
                  ) * 100,
                )}
                % + IVA
              </td>
            </tr>
            <tr>
              <td>
                <img
                  src={amex}
                  width={40}
                  height={40}
                  alt="American Express"
                />
              </td>
              <td className={classes.tdVertical}>
                {roundValue(
                  getNested(
                    defaultComissionsByAgregador['fiservKiosko']['amex'],
                    agency,
                    'amexCardComission',
                  ) * 100,
                )}
                % + IVA
              </td>
            </tr>
          </tbody>
        </Table>
      </Grid>
    </>
  );

  const renderCommissionsKiosko = () => (
    <>
      <Grid item xs={12} md={12} className={classes.tables}>
        <Table responsive="xl" className={classes.tdVertical}>
          <thead>
            <tr>
              <th>Meses</th>
              <th>Otros</th>
              <th>Citibanamex</th>
              <th>BBVA</th>
              <th>AMEX</th>
            </tr>
          </thead>
          <tbody>
            {
              Object.keys(monthsByComissionsByAgregador['fiserv']).map((key) => (
                <tr key={key}>
                  <td>{`${key} meses`}</td>
                  <td>{`${(
                    monthsByComissionsByAgregador['fiserv'][key]['prosa'] * 100
                  ).toFixed(2)}% + IVA`}</td>
                  <td>{`${(
                    monthsByComissionsByAgregador['fiserv'][key]['citibanamex'] * 100
                  ).toFixed(2)}% + IVA`}</td>
                  <td>{`${(
                    monthsByComissionsByAgregador['fiserv'][key]['BBVA'] * 100
                  ).toFixed(2)}% + IVA`}</td>
                  <td>{`${(
                    monthsByComissionsByAgregador['fiserv'][key]['amex'] * 100
                  ).toFixed(2)}% + IVA`}</td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </Grid>
    </>
  );

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={9}>
          <Typography variant="h5" className={classes.commission}>
            Comisiones fijas Ecommerce
          </Typography>
        </Grid>
        {renderCommissionsByCards()}
        <Grid item xs={12} md={9}>
          <Typography variant="h5" className={classes.commission}>
            Comisiones fijas TPV
          </Typography>
        </Grid>
        {renderCommissionsByCardsTpv()}
        <Grid item xs={12} md={9}>
          <Typography variant="h5" className={classes.commission}>
            Comisiones a meses
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} className={classes.tables}>
          <Table responsive="xl">
            <thead>
              {agency.paymentAggregators === 'billpocket' ? (
                <tr>
                  <th>Meses</th>
                  <th>Visa/Mastercard</th>
                  <th>AMEX</th>
                </tr>
              ) : agency.paymentAggregators === 'fiserv' ? (
                <tr>
                  <th>Meses</th>
                  <th>Otros</th>
                  <th>Citibanamex</th>
                  <th>BBVA</th>
                  <th>AMEX</th>
                </tr>
              ) : null}
            </thead>
            <tbody>
              {agency.paymentAggregators === 'billpocket' ?
                Object.keys(monthsByComissionsByAgregador['billpocket']).map((key) => (
                  <tr key={key}>
                    <td>{`${key} meses`}</td>
                    <td>{`${(
                      monthsByComissionsByAgregador['billpocket'][key]['normal'] * 100
                    ).toFixed(2)}% + IVA`}</td>
                    <td>{`${(
                      monthsByComissionsByAgregador['billpocket'][key]['amex'] * 100
                    ).toFixed(2)}% + IVA`}</td>
                  </tr>
                ))
                : agency.paymentAggregators === 'fiserv' ?
                  (
                    Object.keys(agencyByMembershipFormatted).map((key) => (
                      <tr key={key}>
                        <td>{`${key} meses`}</td>
                        <td>{`${(
                          agencyByMembershipFormatted[key]['prosa'] * 100
                        ).toFixed(2)}% + IVA`}</td>
                        <td>{`${(
                          agencyByMembershipFormatted[key]['banamex'] * 100
                        ).toFixed(2)}% + IVA`}</td>
                        <td>{`${(
                          agencyByMembershipFormatted[key]['bbva'] * 100
                        ).toFixed(2)}% + IVA`}</td>
                        <td>{`${(
                          agencyByMembershipFormatted[key]['american-express'] * 100
                        ).toFixed(2)}% + IVA`}</td>
                      </tr>
                    ))
                  )
                  :
                  null
              }
            </tbody>
          </Table>
        </Grid>
        {
          agency.hasKiosko ?
            <>
              <Grid item xs={12} md={9}>
                <Typography variant="h5" className={classes.commission}>
                  Comisiones fijas kiosko
                </Typography>
              </Grid>
              {renderCommissionsByCardsKiosko()}
            </>
            : null
        }
        {agency.paymentAggregators === 'billpocket' && agency.hasKiosko ? (
          <>
            <Grid container>
              <Grid item xs={12} md={9}>
                <Typography variant="h5" className={classes.commission}>
                  Comisiones a meses kiosko
                </Typography>
              </Grid>
              {renderCommissionsKiosko()}
            </Grid>
          </>
        ) : null}
      </Grid>
    </>
  );
};

export default Comissions;
