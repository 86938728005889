import { call, put } from 'redux-saga/effects';
import {
  getAllDepositsFail,
  getAllDepositsSuccess,
  getDepositDetailFail,
  getDepositDetailSuccess,
  importDepositsFromExcelFail,
  importDepositsFromExcelSuccess,
} from 'app/features/standAlone/payments/depositsSlice';

import { formatDatesForFilter } from 'app/utils';
import { getLogs } from 'app/features/logs/logsSlice';
import { requestHandler } from '../../../services/requestHandler';
import moment from 'moment';

export function* getAllPaymentsStandAloneSaga(action) {
  const { page, pageSize, sorted, filtered } = action.payload;
  try {
    let offset = 0;
    let firstDate = action.payload.firstDate
      ? moment(action.payload.firstDate).format('YYYY-MM-DDT00:00:00')
      : '';
    let secondDate = action.payload.secondDate
      ? moment(action.payload.secondDate).format(
          'YYYY-MM-DDT23:59:59',
        )
      : '';

    let where = {};
    if (filtered.length) {
      filtered.forEach((filters) => {
        where = {
          ...where,
          [filters.id]: filters.value,
        };
      });
    }

    let ordering = 'DESC';
    if (sorted.length > 0 && sorted[0].id === 'updatedAt') {
      ordering = sorted[0].desc ? 'DESC' : 'ASC';
    }

    if (page > 0) {
      offset = pageSize * page;
    }

    const data = {
      where,
      pagination: {
        limit: pageSize,
        offset,
      },
      ordering: {
        field: 'updatedAt',
        direction: ordering,
      },
      firstDate: firstDate,
      secondDate: secondDate,
    };

    const deposits = yield call(requestHandler, {
      method: 'POST',
      path: '/payments-standalone/getAll',
      data,
    });
    
    yield put(getAllDepositsSuccess({ deposits }));
  } catch (e) {
    yield put(getAllDepositsFail('getPaymentSaga'));
    console.log('error in get payment saga', e);
  }
}

export function* getAllSpeiPaymentsValidatedSaga(action) {
  try {
    const { page, pageSize, filtered } = action.payload;
    const data = {
      where: {},
      agencies: {
        options: {
          attributes: ['name'],
        },
      },
      pagination: {
        page,
        perPage: pageSize,
      },
      options: {
        order: [['updatedAt', 'DESC']],
      },
    };
    if (filtered.length) {
      filtered.forEach((filter) => {
        if (filter.id === 'id') data.where['id'] = filter.value;
        else if (filter.id === 'agency.name')
          data.agencies = {
            where: {
              name: {
                $iLike: `%${filter.value}%`,
              },
            },
          };
        else if (filter.id === 'name')
          data.where['name'] = {
            $iLike: `%${filter.value}%`,
          };
        else if (filter.id === 'amount')
          data.where['amount'] = filter.value;
        else if (filter.id === 'createdAt')
          data.where['createdAt'] = formatDatesForFilter(
            filter.value,
          );
        else data.where[filter.id] = { $iLike: `%${filter.value}%` };
      });
    }

    const deposits = yield call(requestHandler, {
      method: 'POST',
      path: '/deposits/getAll',
      data,
    });
    yield put(getAllDepositsSuccess({ deposits }));
  } catch (e) {
    yield put(getAllDepositsFail('getPaymentSaga'));
    console.log('error in get payment saga', e);
  }
}

export function* getPaymentsStandAloneDetailSaga(action) {
  try {
    const { id, agencyID } = action.payload;
    let data = {
      id,
      agencyID,
    };
    /* data = { id: id }; */
    const deposit = yield call(requestHandler, {
      method: 'POST',
      path: '/payments-standalone/getOne',
      data,
    });
    yield put(
      getLogs({
        object: 'deposits',
        objectID: id,
      }),
    );
    yield put(getDepositDetailSuccess({ deposit }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba obtener información pago.
      Intente de nuevo, si el error persiste intente más tarde.`,
    };
    yield put(getDepositDetailFail({ message }));
    console.log('error in get detail payment saga', e);
  }
}

export function* importDepositsFromExcelSaga(action) {
  try {
    const { data, handleRefreshData } = action.payload;
    yield call(requestHandler, {
      method: 'POST',
      path: '/deposits/importFromExcel',
      data,
    });
    const message = {
      title: 'Depósitos importados',
      desc: '¡Los depósitos han sido importados correctamente!',
    };

    yield put(importDepositsFromExcelSuccess({ message }));
    yield call(handleRefreshData);
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaban importar los depósitos.
      Intente de nuevo, si el error persiste intente más tarde.`,
    };
    yield put(importDepositsFromExcelFail({ message }));
    console.log('error in get detail payment saga', e);
  }
}
