import 'react-table/react-table.css';
import 'moment/locale/es';

/* eslint-disable react/display-name */
import {
  Button,
  Badge as MaterialBadge,
  Paper,
  Checkbox,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  getKarloPromotions,
  updatePromotion,
  deletePromotion,
} from 'app/features/karloPromotions/karloPromotionsSlice';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {
  formatDate,
  formatExpiricyDate,
  formatNumber,
  getBadgeColor,
  getOffersStatusName,
} from '../../../../utils';
import Modal from '../../../../components/Modal';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { injectIntl } from 'react-intl';
import { selectAuth } from 'app/features/auth/selectors';
import { makeSelectKarloPromotions } from 'app/features/karloPromotions/selectors';
import { useForm } from 'react-hook-form';
import Add from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(0),
  },
  notifications: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  lastUpdated: {
    marginLeft: theme.spacing(2),
    color: '#434349',
  },
  datePickerTextfield: {
    padding: '5px 5px 5px !important',
    height: '20px',
  },
  textField: {
    margin: theme.spacing(1),
    width: '95%',
  },
}));

function KarloPromotionsTable(props) {
  const classes = useStyles();
  const {
    role,
    getKarloPromotions,
    deletePromotion,
    updatePromotion,
    users,
    karloPromotions,
    setActionButton,
  } = props;
  const [
    karloPromotionsData,
    setKarloPromotionsData,
  ] = React.useState([]);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [promotionId, setPromotionId] = React.useState(0);
  const [hasExpiracy, setExpiracy] = useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [vigencyDate, setVigency] = useState(new Date());
  const [expirationDate, setExpiration] = useState(null);
  const reactTable = React.useRef(null);
  const {
    handleSubmit,
    register,
    errors,
    control,
    reset,
  } = useForm();

  const handleEditItem = (formData) => {
    console.log('users ', users?.user?.id);
    const userId = users?.user?.id;
    const data = {
      ...formData,
      startDate: vigencyDate,
      expirationDate: expirationDate,
      id: promotionId,
      createdByUserId: userId,
    };
    console.log('data ', data);
    updatePromotion({
      ...formData,
      startDate: vigencyDate,
      expirationDate: expirationDate,
      id: promotionId,
      createdByUserId: userId,
    });
    handleCloseDelete();
  };

  React.useEffect(() => {
    setActionButton(
      <div>
        <Link to={'/add-promotion'}>
          <Button
            variant="contained"
            className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
            onClick={() => console.log()}
          >
            Crear promoción
            <Add className="kt-margin-l-10" />
          </Button>
        </Link>
      </div>,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFetchData = debounce(
    (state, instance) => {
      const { page, pageSize, sorted, filtered } = state;
      getKarloPromotions({ page, pageSize, sorted, filtered });
    },
    1000,
    { leading: false, trailing: true },
  );

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setOpenEdit(false);
  };

  const handleDelete = () => {
    deletePromotion({ promotionId });
    handleCloseDelete();
  };

  const renderConfirmationDelete = () => {
    return (
      <Modal
        open={openDelete}
        type="warning"
        closeModal={handleCloseDelete}
        dialogTitle="¿Seguro que deseas eliminar la promoción?"
        dialogText="Si continúas no habrá manera de recuperar la información
            que haya sido borrada."
        actionButtonText="Eliminar promoción"
        onClick={handleDelete}
      />
    );
  };

  const renderEditItem = () => {
    return (
      <Modal
        open={openEdit}
        closeModal={handleCloseDelete}
        dialogTitle={'Editar promoción'}
        actionButtonText={'Editar promoción'}
        form={'create-user-form'}
        dialogChildren={
          <form
            id="create-user-form"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(handleEditItem)}
          >
            <TextField
              required
              name="title"
              label="Titulo"
              type="title"
              variant="outlined"
              // onChange={handleCharactersMail}
              inputRef={register({
                required: true,
              })}
              className={classes.textField}
              error={Boolean(errors.title)}
            />
            <TextField
              required
              name="subtitle"
              label="Subtitulo"
              type="subtitle"
              variant="outlined"
              // onChange={handleCharactersMail}
              inputRef={register({
                required: true,
              })}
              className={classes.textField}
              error={Boolean(errors.subtitle)}
            />
            <TextField
              required
              name="description"
              label="Descripción"
              type="description"
              variant="outlined"
              inputRef={register({
                required: true,
              })}
              className={classes.textField}
              error={Boolean(errors.description)}
            />
            <MuiPickersUtilsProvider
              utils={MomentUtils}
              locale={'es'}
            >
              <DatePicker
                autoOk
                fullWidth
                disablePast
                disableToolbar
                name="startDate"
                // error={Boolean(errors.vigencyDate)}
                className={classes.textField}
                format="YYYY/MM/DD"
                label="Fecha de inicio"
                variant="inline"
                inputVariant="outlined"
                value={vigencyDate}
                onChange={(date) =>
                  setVigency(moment(date).format('YYYY/MM/DD'))
                }
              />
            </MuiPickersUtilsProvider>
            <Grid item xs={12} sm={6}>
              <Checkbox
                className={classes.checkbox}
                checked={hasExpiracy}
                onChange={() => setExpiracy(!hasExpiracy)}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
              <Typography>Tiene vigencia</Typography>
            </Grid>
            <MuiPickersUtilsProvider
              utils={MomentUtils}
              locale={'es'}
            >
              <DatePicker
                autoOk
                fullWidth
                disablePast
                disableToolbar
                name="expirationDate"
                disabled={hasExpiracy == false ? true : false}
                // error={Boolean(errors.vigencyDate)}
                className={classes.textField}
                format="YYYY/MM/DD"
                label="Fecha de vigencia"
                variant="inline"
                inputVariant="outlined"
                value={expirationDate}
                onChange={(date) =>
                  setExpiration(moment(date).format('YYYY/MM/DD'))
                }
              />
            </MuiPickersUtilsProvider>
            <TextField
              // required
              name="videoUrl"
              label="Video"
              type="videoUrl"
              variant="outlined"
              // onChange={handleCharactersMail}
              inputRef={register({
                required: false,
              })}
              className={classes.textField}
              error={Boolean(errors.videoUrl)}
            />
          </form>
        }
      />
    );
  };

  React.useEffect(() => {
    if (Array.isArray(karloPromotions.fromdb)) {
      const cleanData = karloPromotions.fromdb.map((promotion) => {
        return {
          id: promotion.id,
          title: promotion.title,
          description: promotion.description,
          videoUrl: promotion.videoUrl,
          startDate: promotion.startDate,
          expirationDate:
            promotion.expirationDate == null
              ? '-'
              : formatDate(promotion.expirationDate),
          subtitle: promotion.subtitle ? promotion.subtitle : '-',
          createdAt: promotion.createdAt,
        };
      });
      setKarloPromotionsData(cleanData);
    }
  }, [karloPromotions]);

  const columns = [
    {
      Header: () => <strong>description</strong>,
      accessor: 'description',
      Cell: (row) => (
        <Link to={`/edit-promotion/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
      show: false,
    },
    {
      Header: () => <strong>videoUrl</strong>,
      accessor: 'videoUrl',
      Cell: (row) => (
        <Link to={`/edit-promotion/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
      show: false,
    },
    {
      Header: () => <strong>ID</strong>,
      accessor: 'id',
      Cell: (row) => (
        <Link to={`/edit-promotion/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Titulo</strong>,
      accessor: 'title',
      Cell: (row) => (
        <Link to={`/edit-promotion/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Subtitulo</strong>,
      accessor: 'subtitle',
      Cell: (row) => (
        <Link to={`/edit-promotion/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>startDate</strong>,
      accessor: 'startDate',
      Cell: (row) => (
        <Link to={`/edit-promotion/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {formatDate(row.value)}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>expirationDate</strong>,
      accessor: 'expirationDate',
      Cell: (row) => (
        <Link to={`/edit-promotion/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Fecha de Creación</strong>,
      accessor: 'createdAt',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {formatDate(row.value)}
        </div>
      ),
      filterable: false,
      show: false,
    },
    {
      Header: '',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <Tooltip title="Editar promoción">
            <IconButton
              className={classes.button}
              size="small"
              onClick={() => {
                setOpenEdit(true);
                reset({
                  title: row.original.title,
                  subtitle: row.original.subtitle,
                  description: row.original.description,
                  videoUrl: row.original.videoUrl,
                });
                setVigency(row.original.startDate);
                setExpiration(row.original.expirationDate);
                setExpiracy(
                  row.original.expirationDate == null ? false : true,
                );
                setPromotionId(row.original.id);
              }}
            >
              <i className="far fa-edit"></i>
            </IconButton>
          </Tooltip>
          <Tooltip title="Borrar promoción">
            <IconButton
              className={classes.button}
              size="small"
              onClick={() => {
                handleOpenDelete();
                setPromotionId(row.original.id);
              }}
            >
              <i className="far fa-trash-alt"></i>
            </IconButton>
          </Tooltip>
        </div>
      ),
      filterable: false,
      show: false,
    },
  ];

  return (
    <div className={classes.root}>
      {renderConfirmationDelete()}
      {renderEditItem()}
      <Paper className={classes.paper}>
        <ReactTable
          ref={reactTable}
          NoDataComponent={() => (
            <div className="rt-noData">Sin resultados</div>
          )}
          manual
          onFetchData={handleFetchData}
          data={karloPromotionsData}
          pages={karloPromotions.tablePages}
          columns={columns}
          // filterable
          className="-striped -highlight"
          previousText="Anterior"
          nextText="Siguiente"
          loading={karloPromotions.isLoading}
          loadingText="Cargando datos..."
        />
      </Paper>
    </div>
  );
}

const mapStateToProps = (state) => ({
  karloPromotions: makeSelectKarloPromotions(state),
  users: selectAuth(state),
});

const mapDispatchToProps = {
  getKarloPromotions,
  deletePromotion,
  updatePromotion,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(KarloPromotionsTable),
);
