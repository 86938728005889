import React, { Fragment, useState, useEffect } from 'react';

import {
  Button,
  TextField,
  Grid,
  Typography,
  makeStyles,
  InputAdornment,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: theme.spacing(7, 10, 1, 10),
  },
  funtions: {
    margin: '40px 0',
    width: '100%',
  },
  textField: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  submitButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
  formControl: {
    width: '100%',
    margin: theme.spacing(1),
  },
}));

const FillDataInvoice = (props) => {
  const { handleUpdateInvoice, dataOrder } = props;
  const classes = useStyles();
  const [dataOrderInvoice, setdataOrderInvoice] = useState(dataOrder);

  const handleChange = (event) => {
    const {
      target: { name: targetName, value: targetValue },
    } = event;
    if (targetName === 'phoneNumbers') {
      const validation = /^[0-9]{0,10}$/;
      if (validation.test(targetValue) || targetValue === '') {
        setdataOrderInvoice({
          ...dataOrderInvoice,
          [targetName]: [targetValue],
        });
      }
    } else if (targetName === 'rfc') {
      const validation = /^[a-zA-Z0-9]{0,13}$/;
      if (validation.test(targetValue) || targetValue === '') {
        setdataOrderInvoice({
          ...dataOrderInvoice,
          [targetName]: targetValue,
        });
      }
    } else if (targetName === 'postalCode') {
      const validation = /^[0-9]{0,5}$/;
      if (validation.test(targetValue) || targetValue === '') {
        setdataOrderInvoice({
          ...dataOrderInvoice,
          [targetName]: targetValue,
        });
      }
    } else {
      setdataOrderInvoice({
        ...dataOrderInvoice,
        [targetName]: targetValue,
      });
    }
  };

  return (
    <>
      <Grid item xs={12} md={12} className={classes.funtions}>
        <Typography variant="h4" gutterBottom>
          Información del pago
        </Typography>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="Nombre"
          fullWidth
          onChange={handleChange}
          name="fullName"
          value={dataOrderInvoice.fullName}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="Telefono"
          fullWidth
          onChange={handleChange}
          name="phoneNumbers"
          value={dataOrderInvoice.phoneNumbers[0]}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="RFC"
          fullWidth
          onChange={handleChange}
          name="rfc"
          value={dataOrderInvoice.rfc}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="Email"
          fullWidth
          onChange={handleChange}
          name="email"
          value={dataOrderInvoice.email}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="CFDI"
          fullWidth
          onChange={handleChange}
          name="cfdi"
          value={dataOrderInvoice.cfdi}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="Regimen"
          fullWidth
          onChange={handleChange}
          name="taxRegime"
          value={dataOrderInvoice.taxRegime}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="Código Postal"
          fullWidth
          onChange={handleChange}
          name="postalCode"
          value={dataOrderInvoice.postalCode}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={2} className={classes.funtions}>
        <div className={classes.submitButtonWrapper}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleUpdateInvoice(dataOrderInvoice)}
            //disabled={lockButton()}
          >
            Actualizar
          </Button>
        </div>
      </Grid>
    </>
  );
};

export default FillDataInvoice;
