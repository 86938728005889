import React, { useState, useEffect } from 'react';
import {
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Divider,
  TextField,
  Typography,
  makeStyles,
  CircularProgress,
  Backdrop,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import Input from 'app/components/Input';
import {
  createReservation,
  closeModal,
} from '../../reservationsSlice';
import { selectReservations } from '../../selectors';
import Modal from '../../../../components/Modal';
import { MuiCurrencyFormat } from 'app/components/MUICurrencyFormat';
import moment from 'moment';
import {
  typeOrders,
  ports,
  resorts,
  units,
} from '../../../../utils';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(5, 5, 3, 5),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5, 5, 3, 5),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(5, 10, 3, 10),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(5, 10, 3, 10),
    },
  },
  title: {
    textAlign: 'left',
    color: '#1c1c1c',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  switch: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textFieldAddProducts: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    width: '50%',
  },
  margin: {
    margin: theme.spacing(1),
  },
  client: {
    padding: theme.spacing(0),
    marginTop: theme.spacing(1),
  },
  productsContainer: {
    marginBottom: theme.spacing(3),
  },
  submitButtonWrapper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  formGroupSwitch: {
    alignItems: 'center',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));




const CreateReservation = (props) => {
  const { createReservation, reservationState, closeModal } = props;
  const classes = useStyles();
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    watch,
  } = useForm();
  const [checkInn, setCheckInn] = useState('');
  const [checkout, setCheckout] = useState('');
  const [resort, setResort] = useState('');
  const [unit, setUnit] = useState('');
  const [port, setPort] = useState('');
  const [typeOrder, setTypeOrder] = useState('');
  const [total, setTotal] = useState();
  const [errorDate, setErrorDate] = useState(false);

  const handleChange = (event) => {
    setResort(event.target.value);
  };
  const handleChangeOrderType = (event) => {
    setTypeOrder(event.target.value);
  };
  const handleChangePort = (event) => {
    setPort(event.target.value);
    setResort(event.target.value);
  };
  const handleChangeCheckIn = (event) => {
    setCheckInn(event.target.value);
    setCheckout(event.target.value);
  };

  const handleChangeUnit = (event) => {
    const unitValue = event.target.value;
    setResort('PuertoVallarta');
    setUnit(unitValue);
  };

  const onSubmit = (data) => {
    data["address"] = '';
    data["unitType"] = unit;
    const formData = {
      total,
      data,
      resort,
      checkInn,
      checkout,
      typeOrder,
    };
    const dateIn = moment(checkInn, 'YYYY/MM/DD');
    const dateOut = moment(checkout, 'YYYY/MM/DD');

    if (dateOut < dateIn) {
      setErrorDate(true);
      return null;
    }
    setErrorDate(false);
    createReservation({ formData });
  };

  const redirectModal = () => {
    try {
      const { successModal, redirect } = reservationState;
      const modalType = 'successModal';
      if (successModal.show === true) {
        return (
          <Modal
            open={successModal.show}
            type={modalType}
            closeModal={() => handleCloseModals(redirect)}
            dialogTitle={'Creada correctamente'}
            dialogText={
              'Se ha creado correctamente una nueva reservación.'
            }
            actionButtonText="Aceptar"
            onClick={() => handleCloseModals(redirect)}
          />
        );
      }
    } catch (error) {
      return null;
    }
  };

  const rejectModal = () => {
    try {
      const { errorModal, redirect } = reservationState;
      const modalType = 'errorModal';
      if (errorModal.show === true) {
        return (
          <Modal
            open={errorModal.show}
            type={modalType}
            closeModal={() => handleCloseModals('/reservations')}
            dialogTitle={'Error al crear la orden'}
            dialogText={'Ya se encuentra registrada esta orden.'}
            actionButtonText="Aceptar"
            onClick={() => handleCloseModals('/reservations')}
          />
        );
      }
    } catch (error) {
      return null;
    }
  };

  const handleCloseModals = (redirect) => {
    return window.location.replace(redirect);
  };

  return (
    <Paper className={classes.paper}>
      {reservationState.isLoading && (
        <>
          <Backdrop
            className={classes.backdrop}
            open={reservationState.isLoading}
          >
            <CircularProgress />
          </Backdrop>
        </>
      )}
      {redirectModal()}
      {rejectModal()}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.client}>
            <Typography variant="h6">
              Información de la orden
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name="orderNum"
              ref={register({
                required: true,
              })}
              label={'Número de orden'}
              className={classes.textField}
              helperText="Introduce el número de orden."
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiCurrencyFormat
              label={'Monto total'}
              helperText="Precio con IVA"
              // value={total}
              onChange={(e) => setTotal(e)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              ref={register({
                required: true,
              })}
              name="reservationNum"
              label={'Número de reservación'}
              className={classes.textField}
              helperText="Introduce el número de reservación."
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.client}>
            <Typography variant="h6">
              Información del cliente
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              ref={register({
                required: true,
              })}
              name="clientName"
              label={'Nombre del cliente'}
              className={classes.textField}
              helperText="Introduce el nombre del cliente."
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              ref={register({
                required: true,
              })}
              name="telephone"
              label={'Télefono celular'}
              className={classes.textField}
              helperText="Introduce el número de télefono."
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              ref={register({
                required: true,
              })}
              name="email"
              label={'Email'}
              className={classes.textField}
              helperText="Introduce el correo electrónico."
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              select
              fullWidth
              name="unitType"
              variant="outlined"
              label={'Tipo de unidad'}
              value={unit}
              onChange={handleChangeUnit}
            >
              {units.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        {
          unit !== 'MSC'
            ?
            <Grid container spacing={3}>
              <Grid item xs={12} className={classes.client}>
                <Typography variant="h6">Resort/Destino</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  fullWidth
                  label="Resort/Destino"
                  value={resort}
                  variant="outlined"
                  onChange={handleChange}
                >
                  {resorts.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  onChange={(e) => setCheckInn(e.target.value)}
                  label="Check In"
                  type="date"
                  variant="outlined"
                  defaultValue={new Date().getDate()}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  onChange={(e) => setCheckout(e.target.value)}
                  label="Check Out"
                  type="date"
                  variant="outlined"
                  defaultValue={new Date().getDate()}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {errorDate && (
                  <span
                    style={{
                      color: 'red',
                      fontSize: '.9em',
                    }}
                  >
                    Fecha invalida, favor de revisar.
                  </span>
                )}
              </Grid>
              <Grid item xs={12} sm={12}>
                <Input
                  // fullWidth
                  ref={register({
                    required: false,
                  })}
                  name="coments"
                  label="Comentarios"
                  helperText="Comentarios adicionales."
                />
              </Grid>
            </Grid>
            :
            <Grid container spacing={3}>
              <Grid item xs={12} className={classes.client}>
                <Typography variant="h6">Información Embarcación</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input
                  name="pax"
                  ref={register({
                    required: true,
                  })}
                  label={'Número de PAX'}
                  className={classes.textField}
                  helperText="Introduce el número de PAX."
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  fullWidth
                  label="Embarcación"
                  value={typeOrder}
                  variant="outlined"
                  onChange={handleChangeOrderType}
                >
                  {typeOrders.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  onChange={handleChangeCheckIn}
                  label="Fecha de Salida"
                  type="date"
                  variant="outlined"
                  defaultValue={new Date().getDate()}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Input
                  name="numberNights"
                  type='number'
                  ref={register({
                    required: true,
                  })}
                  label={'Número de Noches'}
                  className={classes.textField}
                  helperText="Introduce el número de Noches."
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField
                  id="standard-select-currency"
                  select
                  fullWidth
                  label="Puerto de Embarque"
                  value={port}
                  variant="outlined"
                  onChange={handleChangePort}
                >
                  {ports.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {
                port === 'otro'
                  ?
                  <Grid item xs={6} sm={3}>
                    <Input
                      name="otherPort"
                      ref={register({
                        required: true,
                      })}
                      label={'Otro Puerto de Embarque'}
                      className={classes.textField}
                      helperText="Introduce el puerto."
                    />
                  </Grid>
                  : null

              }
              <Grid item xs={12} sm={12}>
                <Input
                  // fullWidth
                  ref={register({
                    required: false,
                  })}
                  name="coments"
                  label="Comentarios"
                  helperText="Comentarios adicionales."
                />
              </Grid>
            </Grid>
        }
        <div className={classes.submitButtonWrapper}>
          <Button variant="contained" color="primary" type="submit">
            Guardar Reservación
          </Button>
        </div>
      </form>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  reservationState: selectReservations(state),
});

const mapDispatchToProps = {
  createReservation,
  closeModal,
};

export default withRouter(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(CreateReservation),
  ),
);
