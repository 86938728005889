import { call, put } from 'redux-saga/effects';
import { requestHandler } from '../../services/requestHandler';
import {
  updateOrderFail,
  updateOrderSuccess,
  findSPEIorderSuccess,
  findSPEIorderFail,
  findInvoiceOrderSuccess,
  findInvoiceOrderFail,
  paymentSPEISuccess,
  paymentSPEIFail,
  invoiceDataFail,
  invoiceDataSuccess,
  BPOrderFail,
  BPOrderSuccess,
  getQuiterOrderSucces,
  getQuiterOrderFail,
  refundODSSuccess,
  refundODSFail,
  refundSuccess,
  refundFail,
  removeCommissionSuccess,
  removeCommissionFail,
  updatePasswordSuccess,
  updatePasswordFail,
  associateAgencySuccess,
  associateAgencyFail,
  getODSByOrderIDSuccess,
  getODSByOrderIDFail,
  updateODSByOrderIDSuccess,
  updateODSByOrderIDFail,
  getOrdersQuiterForDownloadSuccess,
  getOrdersQuiterForDownloadSuccessFail,
  importDepositsFromExcelFail,
  importDepositsFromExcelSuccess,
  downloadUsabilitySuccess,
  downloadUsabilityFail,
  testAffiliationSuccess,
  testAffiliationFail,
  searchSPEISuccess,
  searchSPEIFail,
} from './supportSlice';

export function* getBPOrderSaga(action) {
  const { orderNumber, agencySelected } = action.payload.dataGet;
  try {
    const data = {
      agencyID: agencySelected,
      orderID: orderNumber,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: `/serviceOrder/getOrderBp`,
      data,
    });
    yield put(BPOrderSuccess({}));
  } catch (error) {
    // prettier-ignore
    console.log('(SUPPORT MODULE) ERROR IN GET BP ORDER SAGA -> ', error);
    yield put(BPOrderFail(error));
  }
}

export function* getQuiterOrderSaga(action) {
  try {
    const {
      orderNumber,
      agencySelected,
    } = action.payload.dataQuiterGet;
    const data = {
      agencyID: agencySelected,
      dmsID: orderNumber,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/serviceOrder/getOneODS',
      data,
    });
    const message = `La orden ${orderNumber} ha sido agregada correctamente.`;
    yield put(getQuiterOrderSucces({ message }));
  } catch (error) {
    // prettier-ignore
    console.log('(SUPPORT MODULE) ERROR IN GET QUITER ORDER SAGA -> ', error);
    const message = 'Hubo un error al buscar la orden';
    yield put(getQuiterOrderFail({ message }));
  }
}

export function* updateStatusOrderSaga(action) {
  try {
    const {
      actionFunction,
      orderNumber,
      status,
    } = action.payload.updateOrderData;

    let payment;
    try {
      payment = yield call(requestHandler, {
        method: 'POST',
        path: `/payment/getOne`,
        data: {
          where: {
            [`${actionFunction === 'updateStatusODS'
              ? 'serviceOrderID'
              : 'paymentOrderID'
              }`]: +orderNumber,
          },
        },
      });
    } catch (error) {
      payment = 'withOutPay';
    }

    if (payment === 'withOutPay') {
      const url =
        actionFunction === 'updateStatusODS'
          ? 'serviceOrder'
          : 'paymentOrder';

      const data = {
        id: orderNumber,
        status,
      };

      const updateOrder = yield call(requestHandler, {
        method: 'POST',
        path: `/${url}/update`,
        data,
      });

      updateOrder.message =
        'La orden se ha actualizado correctamente.';
      yield put(updateOrderSuccess({ updateOrder }));
    } else {
      const message =
        'Las ordenes con pagos registrados no pueden tener cambios en el status por este medio.';
      yield put(updateOrderFail({ error: message }));
    }
  } catch (error) {
    // prettier-ignore
    console.log('(SUPPORT MODULE) ERROR IN UPDATE STATUS ORDER SAGA -> ', error);
    yield put(updateOrderFail({}));
  }
}
export function* updateInvoiceData(action) {
  try {
    const {
      agencySelected,
      orderNumber,
      invoiceProfile,
    } = action.payload;

    let data = {
      agencyID: agencySelected,
      orderID: orderNumber,
      invoiceProfile,
    };
    const updateOrder = yield call(requestHandler, {
      method: 'POST',
      path: `/serviceOrder/updateInformationInvoiceOrder`,
      data,
    });
    yield put(invoiceDataSuccess({ message: updateOrder }));
  } catch (error) {
    // prettier-ignore
    console.log('(SUPPORT MODULE) ERROR IN UPDATE INVOICE DATA SAGA -> ', error);
    yield put(invoiceDataFail({}));
  }
}

export function* billedDMSSaga(action) {
  try {
    const { orderNumber, agencySelected } = action.payload.billedData;

    const data = {
      orderNumber,
      agencySelected,
    };
    const updateOrder = yield call(requestHandler, {
      method: 'POST',
      path: `/serviceOrder/dataBilledDMS`,
      data,
    });
    updateOrder.message = 'La orden fue encontrada exitosamente.';
    yield put(updateOrderSuccess({ updateOrder }));
  } catch (error) {
    // prettier-ignore
    console.log('(SUPPORT MODULE) ERROR IN BILLED DMS SAGA -> ', error);
    yield put(updateOrderFail({ error: error.message }));
  }
}

export function* updateOrderSaga(action) {
  const {
    status,
    orderNumber,
    actionFunction,
    agencySelected,
  } = action.payload.data;
  if (
    status === undefined &&
    actionFunction === undefined &&
    agencySelected === undefined
  ) {
    try {
      const data = {
        serviceOrderID: orderNumber,
      };
      const updateOrder = yield call(requestHandler, {
        method: 'POST',
        path: `/serviceOrder/notifyDMS`,
        data,
      });
      if (updateOrder?.status === 'DMS Errored') {
        updateOrder.message =
          'Error en el servidor, intente más tarde o contacte con el administrador.';
        yield put(updateOrderFail({ updateOrder }));
      } else {
        updateOrder.message = 'La orden ha sido facturada en el DMS.';
        yield put(updateOrderSuccess({ updateOrder }));
      }
    } catch (error) {
      console.log('ERROR in support saga: ', error);
      yield put(updateOrderFail({ error }));
    }
  }
}

export function* downloadUsabilitySaga(action) {
  const {
    secondDate,
    agencySelected,
    firstDate,
  } = action.payload;
  let message;
  try {
    let agencyId = agencySelected === 1000000 ? null : agencySelected;
    const data = {
      firstDate,
      secondDate,
      agencyID: agencyId,
    };
    const report = yield call(requestHandler, {
      method: 'POST',
      path: `/payment/export-report-usability`,
      data,
    });
    yield put(downloadUsabilitySuccess({ report }));
  } catch (error) {
    // prettier-ignore
    console.log('(SUPPORT MODULE) ERROR IN FIND SPEI ORDER SAGA -> ', error);
    error.code === 404
      ? (message =
        'No se encontro la orden, por favor revise que el No. de orden y la agencia correspondan')
      : (message =
        'Error en el servidor, por favor verifique que el número de orden corresponda a la agencia o intente más tarde.');
    yield put(downloadUsabilityFail({ message }));
  }
}
export function* findSPEIorderSaga(action) {
  const {
    orderNumber,
    agencySelected,
    order,
  } = action.payload.dataOrders;
  let message;
  try {
    let data = {};
    if (order === 'ODS') {
      data = {
        where: {
          serviceOrderID: orderNumber,
        }
      }
    } else {
      data = {
        where: {
          paymentOrderID: orderNumber,
        }
      }
    }
    const findPayment = yield call(requestHandler, {
      method: 'POST',
      path: `/payment/getOrderForStp`,
      data,
    });
    yield put(findSPEIorderSuccess({ findPayment }));
  } catch (error) {
    // prettier-ignore
    console.log('(SUPPORT MODULE) ERROR IN FIND SPEI ORDER SAGA -> ', error);
    error.code === 404
      ? (message =
        'Lo sentimos, el cliente aun no realiza el pago, por lo que no se encontró un registro pendiente por aplicar de la orden.')
      : (message =
        'Lo sentimos, el cliente aun no realiza el pago, por lo que no se encontró un registro pendiente por aplicar de la orden.');
    yield put(findSPEIorderFail({ message }));
  }
}
export function* findOXXOorderSaga(action) {
  const {
    orderNumber,
    agencySelected,
    order,
    typeOrder,
  } = action.payload.dataOrders;
  let message;
  try {
    const data = {
      orderID: orderNumber,
      orderType: order,
      agencyID: agencySelected,
      orderSubType: typeOrder,
    };
    const findPayment = yield call(requestHandler, {
      method: 'POST',
      path: `/supports/getOrder`,
      data,
    });
    yield put(findSPEIorderSuccess({ findPayment }));
  } catch (error) {
    // prettier-ignore
    console.log('(SUPPORT MODULE) ERROR IN FIND OXXO ORDER SAGA -> ', error);
    error.code === 404
      ? (message =
        'No se encontro la orden, por favor revise que el No. de orden y la agencia correspondan')
      : (message =
        'Error en el servidor, por favor verifique que el número de orden corresponda a la agencia o intente más tarde.');
    yield put(findSPEIorderFail({ message }));
  }
}
export function* findInvoiceOrderSaga(action) {
  const {
    orderNumber,
    agencySelected,
    order,
  } = action.payload.dataOrders;
  let message;
  try {
    let url;
    const data = {
      orderID: orderNumber,
      agencyID: agencySelected,
    };
    order === 'ODS' ? (url = 'serviceOrder') : (url = 'paymentOrder');
    const findPayment = yield call(requestHandler, {
      method: 'POST',
      path: `/serviceOrder/getOrderData`,
      data,
    });
    yield put(findInvoiceOrderSuccess({ findPayment }));
  } catch (error) {
    // prettier-ignore
    console.log('(SUPPORT MODULE) ERROR IN FIND INVOICE ORDER SAGA -> ', error);
    error.code === 404
      ? (message =
        'No se encontro la orden, por favor revise que el No. de orden y la agencia correspondan')
      : (message =
        'Error en el servidor, por favor verifique que el número de orden corresponda a la agencia o intente más tarde.');
    yield put(findInvoiceOrderFail({ message }));
  }
}

export function* paymentSPEIUpdateSaga(action) {
  let message;
  const id = action.payload.datas.orderNumber;
  const agencySelected = action.payload.datas.agencySelected;
  let dataPayment;
  let typeOrder;
  let order = action.payload.order;
  try {
    let url;
    const data = {
      where: {
        id: id,
        agencyID: agencySelected,
      },
    };
    order === 'ODS' ? (url = 'serviceOrder') : (url = 'paymentOrder');
    const findPayment = yield call(requestHandler, {
      method: 'POST',
      path: `/${url}/getOne`,
      data,
    });
    if (findPayment) {
      try {
        if (action.payload.order === 'ODS') {
          typeOrder = 'serviceOrder';
        } else {
          typeOrder = 'paymentOrder';
        }
        dataPayment = {
          where: {
            status: 'DONE',
            type: 'SPEI',
            [`${action.payload.order === 'ODS'
              ? 'serviceOrderID'
              : 'paymentOrderID'
              }`]: +id,
          },
        };
        const payment = yield call(requestHandler, {
          method: 'POST',
          path: `/payment/getOne`,
          data: dataPayment,
        });
        if (!payment) {
          const message =
            'Error al optener la información del pago, por favor verfique que esta orden si tiene un pago asociado actualmente.';
          yield put(paymentSPEIFail({ message }));
        } else {
          const data = {
            id: payment.id,
            date: action.payload.newDate,
            paymentDate: action.payload.newDate,
          };
          yield call(requestHandler, {
            method: 'POST',
            path: `/payment/update`,
            data,
          });
          const message = `El SPEI fue actualizada de manera correcta.`;
          yield put(paymentSPEISuccess({ message }));
        }
      } catch (e) {
        let error = e.details.slice(7);
        error = error.split('.');
        error = error[0];
        yield put(updateOrderFail({ error }));
      }
    }
  } catch (error) {
    // prettier-ignore
    console.log('(SUPPORT MODULE) ERROR IN PAYMENT SPEI UPDATE SAGA -> ', error);
    error.code === 404
      ? (message =
        'No se encontró la orden, por favor revise que el No. de orden la agencia y el tipo de orden correspondan')
      : (message =
        'Error en el servidor, por favor verifique que el número de orden corresponda a la agencia o intente más tarde.');
    yield put(findSPEIorderFail({ message }));
  }
}

export function* paymentSPEISaga(action) {
  try {
    const id = action.payload.event.id;
    const order = action.payload.order;
    const wasDispersed = action.payload.event.wasDispersed;
    let typeOrder;
    let dataOrder;
    let dataMail;
    let dataPayment;
    if (order === 'ODS') {
      typeOrder = 'serviceOrder';
      dataPayment = {
        where: {
          serviceOrderID: id,
        },
      };
    } else {
      typeOrder = 'paymentOrder';
      dataPayment = {
        where: {
          paymentOrderID: id,
        },
      };
    }
    const payment = yield call(requestHandler, {
      method: 'POST',
      path: `/payment/getOne`,
      data: dataPayment,
    });
    if (!payment) {
      const message =
        'Error al optener la información del pago, por favor verfique que esta orden si tiene un pago asociado actualmente.';
      yield put(paymentSPEIFail({ message }));
    } else {
      const data = {
        id: payment.id,
        amount: action.payload.event.amount,
        status: action.payload.event.status,
        paymentMethod: action.payload.event.paymentMethod,
        type: action.payload.event.type,
        reference: action.payload.event.reference,
        invoiceNumber: action.payload.event.invoiceNumber,
        total: action.payload.event.amount,
        authNumber: action.payload.event.authNumber,
        date: action.payload.event.date,
        bank: action.payload.event.bank,
        nombreOrdenante: action.payload.event.nameOrder,
        cuenta: action.payload.event.accountNumber,
        rfc: action.payload.event.rfcCurp,
        claveRastreo: action.payload.event.tracking,
        wasDispersed: wasDispersed,
      };
      yield call(requestHandler, {
        method: 'POST',
        path: `/payment/update`,
        data,
      });
      if (order === 'ODS') {
        dataOrder = {
          id,
          status: 'payment',
        };
      } else {
        dataOrder = {
          id,
          status: 'pagado',
        };
      }
      const infoODS = yield call(requestHandler, {
        method: 'POST',
        path: `/${typeOrder}/update`,
        data: dataOrder,
      });
      if (order === 'ODS') {
        dataMail = {
          agencyID: infoODS.agency.id,
          type: 'pay:ods',
          orderID: infoODS.orderID,
        };
      } else {
        dataMail = {
          agencyID: infoODS.agency.id,
          type: 'pay:odp',
          orderID: infoODS.orderID,
        };
      }
      const mail = yield call(requestHandler, {
        method: 'POST',
        path: `/agencyEvents/notify`,
        data: dataMail,
      });
      const message = `La orden fue actualizada de manera correcta. ${mail.message}`;
      yield put(paymentSPEISuccess({ message }));
    }
  } catch (error) {
    // prettier-ignore
    console.log('(SUPPORT MODULE) ERROR IN PAYMENT SPEI SAGA -> ', error);
    const message =
      'Error en el servidor, intente de nuevo más tarde.';
    yield put(paymentSPEIFail({ message }));
  }
}

export function* paymentOXXOSaga(action) {
  try {
    const { event, agencySelected, orderNumber, } = action.payload;
    const data = {
      agencyID: agencySelected,
      orderID: orderNumber,
      orderType: action.payload.order,
      hasPayment: event.hasPaymentOXXO,
      paymentId: event.paymentOXXOId,
      payment: {
        cardHolder: event.cardHolder,
        conektaReferenceNumber: event.conektaReferenceNumber,
        conektaOxxoOrderID: event.conektaOxxoOrderID,
        total: event.total,
      }
    };
    const payment = yield call(requestHandler, {
      method: 'POST',
      path: `/supports/createOrUpdatePaymentOxxo`,
      data,
    });
    const message = payment ? payment : 'La orden fue actualizada de manera correcta.';
    yield put(paymentSPEISuccess({ message }));
  } catch (error) {
    // prettier-ignore
    console.log('(SUPPORT MODULE) ERROR IN PAYMENT OXXO SAGA -> ', error);
    const message = error.message;
    yield put(paymentSPEIFail({ message }));
  }
}

export function* refundSaga(action) {
  const { orderNumber, agencySelected, paymentAggregator } = action.payload.data;
  try {
    const data = {
      agencyID: agencySelected,
      orderID: orderNumber,
      paymentAggregator: paymentAggregator,
    };
    const refund = yield call(requestHandler, {
      method: 'POST',
      path: `/payment/payment-refund`,
      data,
    });
    const message = refund.message;
    yield put(refundSuccess({ message }));
  } catch (error) {
    // prettier-ignore
    console.log('(SUPPORT MODULE) ERROR IN REFOUND SAGA -> ', error);
    const message = error.message;
    yield put(refundFail({ message }));
  }
}

export function* refundODSSaga(action) {
  const { orderNumber, agencySelected } = action.payload.data;
  try {
    const data = {
      agencyID: agencySelected,
      orderID: orderNumber,
    };
    const refund = yield call(requestHandler, {
      method: 'POST',
      path: `/payment/refund`,
      data,
    });
    const message = refund.message;
    yield put(refundODSSuccess({ message }));
  } catch (error) {
    // prettier-ignore
    console.log('(SUPPORT MODULE) ERROR IN REFOUND ODS SAGA -> ', error);
    const message = error.message;
    yield put(refundODSFail({ message }));
  }
}

export function* removeCommissionSaga(action) {
  try {
    const data = { id: action.payload };
    const removeCommission = yield call(requestHandler, {
      method: 'POST',
      path: '/payment/updateComission',
      data,
    });
    yield put(removeCommissionSuccess(removeCommission));
  } catch (error) {
    // prettier-ignore
    console.log('(SUPPORT MODULE) ERROR IN REMOVE COMISSION SAGA -> ', error);
    const message = error.message;
    yield put(removeCommissionFail({ message }));
  }
}

export function* updatePasswordSaga(action) {
  try {
    const { email, password, agencySelected } = action.payload;
    const data = {
      where: { email },
    };
    const userType = yield call(requestHandler, {
      method: 'POST',
      path: '/user/getOne',
      data,
    });
    let update;
    const dataPassword = {
      agencyID: agencySelected,
      email,
      password,
    };
    update = yield call(requestHandler, {
      method: 'POST',
      path: '/user/update-password',
      data: dataPassword,
    });
    if (userType.role === 'Super Admin Agencies') {
      // get the list of agencies this user manages
      const userId = {
        where: { userID: userType.id },
      };
      const { agenciesByAdmin } = yield call(requestHandler, {
        method: 'POST',
        path: '/superAdminByAgencies/getAll',
        data: userId,
      });
      yield* agenciesByAdmin.map(function* (agency) {
        // get the user "Manejador de agencia"
        try {
          const agencyUser = {
            where: { id: agency.agencyID },
          };
          const agencyUserMail = yield call(requestHandler, {
            method: 'POST',
            path: '/superAdminByAgencies/getOne',
            data: agencyUser,
          });
          const dataPassword = {
            agencyID: agency.agencyID,
            email: agencyUserMail.adminUsers[0].email,
            password,
          };
          const userUpdate = yield call(requestHandler, {
            method: 'POST',
            path: '/user/update-password',
            data: dataPassword,
          });
        } catch (error) { }
      });
    }
    yield put(updatePasswordSuccess({ message: update.message }));
  } catch (error) {
    // prettier-ignore
    console.log('(SUPPORT MODULE) ERROR IN UPDATE PASSWORD SAGA -> ', error);
    const message = error.message;
    yield put(updatePasswordFail({ message }));
  }
}

export function* associateAgencySaga(action) {
  try {
    const data = action.payload;
    const associateAgencies = yield call(requestHandler, {
      method: 'POST',
      path: '/user/createAgencyManager',
      data,
    });

    yield put(associateAgencySuccess({ associateAgencies }));
  } catch (error) {
    // prettier-ignore
    console.log('(SUPPORT MODULE) ERROR IN ASSOCIATE AGENCY SAGA -> ', error);
    const message = error.message;
    yield put(associateAgencyFail({ message }));
  }
}

export function* getODSByOrderIDSaga(action) {
  try {
    const data = action.payload;
    let path = '/serviceOrder/getOneByOrderID';
    if(data && data.type === 'ODP') {
      path = '/paymentOrder/getOneByOrderID';
    }
    const ODSData = yield call(requestHandler, {
      method: 'POST',
      path: path,
      data,
    });

    yield put(getODSByOrderIDSuccess({ ODSData }));
  } catch (error) {
    console.log('ERROR IN GET ODS BY ORDER ID SAGA ->', error);
    yield put(getODSByOrderIDFail({ message: error.message }));
  }
}

export function* updateODSByOrderIDSaga(action) {
  try {
    const data = action.payload;
    let path = '/serviceOrder/update';
    if(data && data.type === 'ODP') {
      path = '/paymentOrder/update';
      delete data.type;
      delete data.orderID;
    }
    const ODSData = yield call(requestHandler, {
      method: 'POST',
      path: path,
      data,
    });
    yield put(updateODSByOrderIDSuccess({ ODSData }));
  } catch (error) {
    console.log('ERROR IN UPDATE ODS BY ORDER ID SAGA ->', error);
    yield put(updateODSByOrderIDFail({ message: error.message }));
  }
}
export function* getOrdersQuiterToRepost(action) {
  try {
    const data = { groupName: 'AUTOSUR' };
    const ODSData = yield call(requestHandler, {
      method: 'POST',
      path: '/serviceOrder/getAllQuiter',
      data,
    });
    yield put(getOrdersQuiterForDownloadSuccess({ ODSData }));
  } catch (error) {
    console.log('ERROR IN UPDATE ODS BY ORDER ID SAGA ->', error);
    yield put(
      getOrdersQuiterForDownloadSuccessFail({
        message: error.message,
      }),
    );
  }
}
export function* importDepositsFromExcelSupportSaga(action) {
  try {
    const { data, handleRefreshData } = action.payload;
    yield call(requestHandler, {
      method: 'POST',
      path: '/payments-standalone/uploadFile',
      data,
    });
    const message = {
      title: 'Depósitos importados',
      desc: '¡Los depósitos han sido importados correctamente!',
    };

    yield put(importDepositsFromExcelSuccess({ message }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaban importar los depósitos.
      Intente de nuevo, si el error persiste intente más tarde.`,
    };
    yield put(importDepositsFromExcelFail({ message }));
    console.log('error in get detail payment saga', e);
  }
}

export function* testAffiliationSaga(action){
  try{
    const data = action.payload;
    const result = yield call(requestHandler, {
      method: 'POST',
      path: '/supports/payAffiliationValidator',
      data
    });
    yield put(testAffiliationSuccess({ result }));
  }catch(e){
    const message = e.message;
    yield put(testAffiliationFail({ message }));
    console.log('error in get detail payment saga', e);
  }
}

export function* searchSpeiSaga(action) {
  try {
    const data = action.payload;
    const result = yield call(requestHandler, {
      method: 'POST',
      path: '/payment/getOneByClabe',
      data,
    });
    yield put(searchSPEISuccess({ result }));
  } catch (error) {
    console.log('(SUPPORT MODULE) ERROR IN SEARCH SPEI SAGA -> ', error);
    const message = error.message;
    yield put(searchSPEIFail({ message }));
  }
}