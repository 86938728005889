import { createSelector } from 'reselect';
import { initialState } from './initialState';

export const selectUsers = (state) => state.users || initialState;

export const makeSelectUsersIsLoading = createSelector(
  selectUsers,
  (usersState) => usersState.isLoading,
);

export const makeSelectUsersFromdb = createSelector(
  selectUsers,
  (usersState) => usersState.fromdb,
);

export const makeSelectUsersURL = createSelector(
  selectUsers,
  (usersState) => usersState.url,
);

export const makeSelectUsersID = createSelector(
  selectUsers,
  (usersState) => usersState.id,
);
