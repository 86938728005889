import { createSelector } from 'reselect';
import { initialState } from './initialState';

export const selectCreditOrders = (state) =>
  state.creditOrders || initialState;

export const makeSelectCreditOrdersFromdb = createSelector(
  selectCreditOrders,
  (creditOrdersState) => creditOrdersState.fromdb,
);

export const makeSelectCreditOrderDetails = createSelector(
  selectCreditOrders,
  (creditOrdersState) => creditOrdersState.creditOrderDetails,
);

export const makeSelectIsLoadingCreditOrder = createSelector(
  selectCreditOrders,
  (creditOrdersState) => creditOrdersState.isLoading,
);

export const makeSelectCreditOrdersToDownload = createSelector(
  selectCreditOrders,
  (creditOrdersState) => creditOrdersState.toDownload,
);
