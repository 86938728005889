import { call, put } from 'redux-saga/effects';
import { goToKioskoFail, goToKioskoSuccess } from './kioskoSlice';

import { handleUrlLanding } from 'app/config/landing';
import { requestHandler } from '../../services/requestHandler';

export function* goToKioskoSaga(action) {
  try {
    const { history } = action.payload;
    const landingUrl = yield call(handleUrlLanding);
    const response = yield call(requestHandler, {
      method: 'GET',
      path: '/kiosko/go',
    });
    yield call(
      window.open,
      `${landingUrl}kiosko?loginToken=${response.token}`,
      '_blank',
    );
    yield put(goToKioskoSuccess());
    yield call(history.goBack);
  } catch (e) {
    yield put(goToKioskoFail());
    console.log('error in get payment saga', e);
  }
}
