import 'react-table/react-table.css';
import 'moment/locale/es';

/* eslint-disable react/display-name */
import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Tab,
  Tabs,
  makeStyles,
  IconButton,
  Typography,
} from '@material-ui/core';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import Close from '@material-ui/icons/Close';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { formatNumber } from 'app/utils';
import ButtonImportFile from 'app/components/ButtonImportFile';
import { DepositsCreatedTable } from 'app/features/deposits/components/DepositsCreatedTable';
import { DepositsFiservCreatedTable } from 'app/features/deposits/components/DepositsFiserv';
import { DepositsTPVCreatedTable } from 'app/features/standAlone/deposits/components/DepositsCreatedTable';
import { DepositsSPEI } from 'app/features/deposits/components/DepositsSPEI/index.js';
import Modal from 'app/components/Modal';
import ShouldItRender from 'app/components/ShouldItRender';
import { SpeiValidatedTable } from 'app/features/deposits/components/SpeiValidatedTable';
import ButtonExportExcel from 'app/components/ButtonExportExcel';
import { MenuOption } from 'app/components/MenuOption';

import { closeModals } from 'app/features/modals/modalsSlice';
import {
  cleanSpeiPaymentsLoaded,
  getAllSpeiPayments,
} from 'app/features/speiPayments/speiPaymentsSlice';
import {
  getAllDeposits,
  getAllDepositsSPEI,
  getAllDepositsfiserv,
  countAllDeposits,
} from 'app/features/deposits/depositsSlice';
import { getAllDepositsStandAlone } from 'app/features/standAlone/deposits/depositsSlice';
import {
  createDeposit,
  importDepositsFromExcel,
} from '../../depositsSlice';
import {
  makeSelectDepositsFromdbFromState,
  selectDeposits,
  makeSelectDepositsPages,
  makeSelectDepositsFiservFromdbFromState,
  makeSelectCountAllDepositsFromState,
} from 'app/features/deposits/selectors';
import {
  makeSelectDepositsTPVFromdbFromState,
  makeSelectDepositsTPVDownloadFromState,
} from 'app/features/standAlone/deposits/selectors';
import {
  makeSelectSpeiPaymentsFromdbFromState,
  selectSpeiPayments,
} from 'app/features/speiPayments/selectors';
import { makeSelectUserRoleFromState } from 'app/features/auth/selectors';

import { Publish } from '@material-ui/icons';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import {formatSingleDate,formatSingleDateBillpocket} from 'app/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  buttonImportExcel: {
    marginBottom: theme.spacing(2),
  },
  range: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: '30px',
  },
  datePicker: {
    ['@media screen and (max-width: 1496px)']: {
      // eslint-disable-line no-useless-computed-key
      width: '70px',
    },
    ['@media screen and (max-width: 1024px)']: {
      // eslint-disable-line no-useless-computed-key
      width: '90px',
    },
    width: '90px',
  },
  icons: {
    color: '#0abb87',
    marginRight: '10px',
  },
  colorPrimary: {
    color: '#0abb87',
  },
  menuDesktop: {
    ['@media screen and (max-width: 1520px)']: {
      // eslint-disable-line no-useless-computed-key
      display: 'none',
    },
  },
  menuMobile: {
    ['@media screen and (min-width: 1520px)']: {
      // eslint-disable-line no-useless-computed-key
      display: 'none',
    },
  },
}));

function DepositsTable(props) {
  const classes = useStyles();
  const {
    deposits,
    depositsFromdb,
    depositsFiserv,
    countAllDeposits,
    countAllDepositsFromdb,
    depositsTPV,
    depositsTPVDownload,
    getAllDepositsfiserv,
    getAllDepositsStandAlone,
    speiPayments,
    speiPaymentsFromdb,
    getAllDeposits,
    getAllDepositsSPEI,
    getAllSpeiPayments,
    createDeposit,
    cleanSpeiPaymentsLoaded,
    setLeftSideComponent,
    setActionButton,
    importDepositsFromExcel,
    closeModals,
    location,
    tablePages,
    role,
  } = props;
  const [tabSelected, setTabSelected] = useState('validados');
  const [showImportDespositsModal, setShowImportDespositsModal] =
    useState(false);
  const [excelFile, setExcelFile] = useState(null);
  const [paymentsData, setPaymentsData] = useState([]);
  const [nameFile, setNameFile] = useState('Depósitos');
  const reactTable = useRef(null);
  const [firstDate, setFirstDate] = useState(null);
  const [secondDate, setSecondDate] = useState(null);

  useEffect(() => {
    setLeftSideComponent(
      <>
        {role !== 'Super Admin' ? (
          <Tabs
            value={tabSelected}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChangeTab}
          >
            {countAllDepositsFromdb?.countDeposits > 0 && (
              <Tab value="billPocket" label="Depósitos Billpocket" />
            )}
            {/* <Tab value="validados" label="Pagos validados" /> */}
            <Tab value="SPEI" label="Depósitos SPEI" />
            {countAllDepositsFromdb?.countDepositsFiservs > 0 && (
              <Tab value="Fiserv" label="Depósitos Fiserv" />
            )}
            {countAllDepositsFromdb?.countDepositsStandAlones > 0 && (
              <Tab value="TPV" label="Depósitos TPV" />
            )}
          </Tabs>
        ) : (
          <Tabs
            value={tabSelected}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChangeTab}
          >
            <Tab value="billPocket" label="Depósitos Billpocket" />
            {/* <Tab value="validados" label="Pagos validados" /> */}
            <Tab value="SPEI" label="Depósitos SPEI" />
            <Tab value="Fiserv" label="Depósitos Fiserv" />
            <Tab value="TPV" label="Depósitos TPV" />
          </Tabs>
        )}
      </>,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabSelected, countAllDepositsFromdb]);

  useEffect(() => {
    countAllDeposits({});
  }, []);

  useEffect(() => {
    if (countAllDepositsFromdb?.countDeposits > 0) {
      setTabSelected('billPocket');
    } else if (countAllDepositsFromdb?.countDepositsFiservs > 0) {
      setTabSelected('Fiserv');
    } else if (countAllDepositsFromdb?.countDepositsStandAlones > 0) {
      setTabSelected('TPV');
    }
  }, [countAllDepositsFromdb]);

  useEffect(() => {
    let csv = [];
    const rootData =
      deposits?.exportData?.length > 0
        ? deposits?.exportData
        : depositsFromdb;
    switch (tabSelected) {
      case 'billPocket':
        if (rootData) {
          setNameFile('Depósitos_creados');
          rootData
            .filter((x) => x?.agency?.isActiveAgency === true)
            .forEach((row) => {
              const data = {
                // prettier-ignore
                'ID': row.id || '-',
                // prettier-ignore
                'Agencia': row.agencyName || row.agency?.name || '-',
                'Clave rastreo': row.billPocketID || '-',
                // prettier-ignore
                'Monto': formatNumber(row.amount || 0),
                'Fecha de depósito': row.depositTime
                  ? formatSingleDateBillpocket(row.depositTime)
                  : '-',
                'Suma total': formatNumber(row.totalAmount) || '0',
                'Suma de comisión': formatNumber(
                  row.totalCommission || 0,
                ),
                'Suma de IVA': formatNumber(row.VATSum || 0),
                'Total depositado': formatNumber(
                  row.totalDeposited || 0,
                ),
                'Total contracargos': formatNumber(
                  row.fullChargeback || 0,
                ),
              };
              csv.push(data);
            });
        }
        break;
      case 'SPEI':
        setNameFile('Depósitos_SPEI');
        rootData
          .filter((x) => x['isActiveAgency'] === true)
          .forEach((row) => {
            const data = {
              'Fecha de depósito': row.depositTime
                ? formatSingleDate(row.depositTime)
                : '-',
              // prettier-ignore
              'Total': formatNumber(row?.totalAmount || 0),
              // prettier-ignore
              'Agencia': row.agencyName || '-',
            };
            csv.push(data);
          });
        break;
      case 'Fiserv':
        const rootDataFiserv =
          deposits?.depositsForDownload?.length > 0
            ? deposits?.depositsForDownload
            : depositsFiserv;
        if (rootDataFiserv) {
          setNameFile('Depósitos_Fiserv');
          rootDataFiserv
            .filter((x) => x['isActiveAgency'] === true)
            .forEach((row) => {
              const data = {
                // prettier-ignore
                'ID': row.id || '-',
                // prettier-ignore
                'Agencia': row.agencyName || row.agency?.name || '-',
                'Cuenta destino': row.finalDestinationAccount || '-',
                // prettier-ignore
                'Depositante': row.depositor || '-',
                'Fecha de depósito': row.depositDate
                  ? formatSingleDate(row.depositTime)
                  : '-',
                'Suma de comisiones':
                  formatNumber(row.totalCommissions) || '0',
                'Suma de IVA': formatNumber(
                  row.totalIvaCommissions || 0,
                ),
                'Total depositado': formatNumber(
                  row.totalToDeposit || 0,
                ),
                'Total Pagos': formatNumber(row.totalPayments || 0),
              };
              csv.push(data);
            });
        }
        break;
      case 'TPV':
        const rootDataTPV =
          depositsTPVDownload.length > 0
            ? depositsTPVDownload
            : rootData;
        if (rootDataTPV) {
          setNameFile('Depósitos_Fiserv');
          rootDataTPV
            .filter((x) => x?.agency?.isActiveAgency === true)
            .forEach((row) => {
              const data = {
                // prettier-ignore
                'ID': row.id || '-',
                // prettier-ignore
                'Agencia': row.agency?.name || '-',
                // prettier-ignore
                'Depositante': row.depositor || '-',
                'Fecha de depósito': row.depositDate
                  ? formatSingleDate(row.depositTime)
                  : '-',
                'Total depositado': formatNumber(
                  row.totalToDeposit || 0,
                ),
              };
              csv.push(data);
            });
        }
        break;
      default:
        break;
    }
    setPaymentsData(csv);
  }, [
    tabSelected,
    depositsFromdb,
    depositsFiserv,
    deposits?.depositsForDownload,
    depositsTPVDownload,
  ]);

  const handleFirstDate = (date) => {
    setFirstDate(date);
  };

  const handleSecondDate = (date) => {
    setSecondDate(date);
  };

  const handleClearFirstDate = () => {
    setFirstDate(null);
  };

  const handleClearSecondDate = () => {
    setSecondDate(null);
  };

  useEffect(() => {
    setActionButton(
      <ShouldItRender
        locationPage={`/${location.pathname.split('/')[1]}`}
        action="importDeposits"
      >
        <MuiPickersUtilsProvider utils={MomentUtils} locale={'es'}>
          <DatePicker
            id="mui-pickers-firstDate"
            autoOk
            disableToolbar
            disableFuture
            variant="inline"
            label="Inicial"
            format="DD/MM"
            className={classes.datePicker}
            value={firstDate}
            onChange={handleFirstDate}
            InputProps={{
              endAdornment: firstDate ? (
                <IconButton
                  aria-label="Select locale"
                  size="small"
                  onClick={handleClearFirstDate}
                >
                  <Close />
                </IconButton>
              ) : null,
            }}
          />
          <span className={classes.range}>&nbsp;A&nbsp;</span>
          <DatePicker
            id="mui-pickers-secondDate"
            autoOk
            disableToolbar
            disableFuture
            variant="inline"
            label="Final"
            format="DD/MM"
            className={classes.datePicker}
            value={secondDate}
            onChange={handleSecondDate}
            InputProps={{
              endAdornment: secondDate ? (
                <IconButton
                  aria-label="Select locale"
                  size="small"
                  onClick={handleClearSecondDate}
                >
                  <Close />
                </IconButton>
              ) : null,
            }}
          />
        </MuiPickersUtilsProvider>
        <div className={classes.menuDesktop}>
          {role === 'Super Admin' &&
            tabSelected !== 'TPV' &&
            tabSelected !== 'Fiserv' && (
              <Button
                variant="contained"
                className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
                onClick={toggleImportExcel}
              >
                Importar depósitos
                <Publish className="kt-margin-l-10" />
              </Button>
            )}
          <ButtonExportExcel
            data={paymentsData}
            buttonText="Exportar Depósitos"
            fileName={nameFile}
          />
        </div>
        <div className={classes.menuMobile}>
          <MenuOption
            Button={
              <Button
                variant="contained"
                className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-t-10"
              >
                Ver opciones
                <ArrowDropDownIcon className="kt-margin-l-10" />
              </Button>
            }
            options={[
              {
                name: role === 'Super Admin' &&
                  tabSelected !== 'TPV' &&
                  tabSelected !== 'Fiserv' && (
                    <Typography className={classes.colorPrimary}>
                      <Publish className={classes.icons} />
                      Importar depósitos
                    </Typography>
                  ),
                onClick: toggleImportExcel,
              },
              {
                name: (
                  <ButtonExportExcel
                    data={paymentsData}
                    buttonText="Exportar Depósitos"
                    fileName={nameFile}
                  />
                ),
                onClick: genericFuntion,
              },
            ]}
          />
        </div>
      </ShouldItRender>,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentsData, firstDate, secondDate]);

  const genericFuntion = () => {
    // Don't delete this funtion, is called by the top bar menu
    // buttons that do not require the component's onClick function
  };

  const handleChangeTab = (event, value) => {
    setTabSelected(value);
  };

  const handleCloseModals = () => {
    closeModals();
  };

  const toggleImportExcel = () => {
    setShowImportDespositsModal(!showImportDespositsModal);
  };

  const handleRefreshData = () =>
    reactTable.current && reactTable.current.fireFetchData();

  const handleImportDepositsFromExcel = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file0', excelFile);
    importDepositsFromExcel({
      data: formData,
      handleRefreshData,
    });
    setExcelFile(null);
    toggleImportExcel();
  };

  const renderCreateDeposit = () => {
    return (
      <Modal
        open={showImportDespositsModal}
        closeModal={toggleImportExcel}
        dialogTitle="Crear depósito"
        actionButtonText="Validar"
        form={'import-deposits-from-excel-form'}
        dialogChildren={
          <form
            id="import-deposits-from-excel-form"
            autoComplete="off"
            noValidate
            onSubmit={handleImportDepositsFromExcel}
          >
            <ButtonImportFile
              className={classes.buttonImportExcel}
              buttonName="Seleccionar archivo excel"
              file={excelFile}
              setFile={setExcelFile}
            />
          </form>
        }
      />
    );
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = deposits;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message, redirect } = deposits[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={() => handleCloseModals(redirect)}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={() => handleCloseModals(redirect)}
          />
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  return (
    <div className={classes.root}>
      {renderCreateDeposit()}
      {renderFeedBackModal()}
      {tabSelected === 'billPocket' ? (
        <DepositsCreatedTable
          firstDate={firstDate}
          secondDate={secondDate}
          reactTableRef={reactTable}
          deposits={deposits}
          depositsFromdb={depositsFromdb}
          getAllDeposits={getAllDeposits}
        />
      ) : tabSelected === 'validados' ? (
        <SpeiValidatedTable
          speiPayments={speiPayments}
          speiPaymentsFromdb={speiPaymentsFromdb}
          getAllSpeiPayments={getAllSpeiPayments}
          createDeposit={createDeposit}
          changeToDepositsCreated={() => setTabSelected(0)}
          cleanSpeiPaymentsLoaded={cleanSpeiPaymentsLoaded}
        />
      ) : tabSelected === 'Fiserv' ? (
        <DepositsFiservCreatedTable
          firstDate={firstDate}
          secondDate={secondDate}
          reactTableRef={reactTable}
          deposits={deposits}
          depositsFromdb={depositsFiserv}
          getAllDepositsfiserv={getAllDepositsfiserv}
        />
      ) : tabSelected === 'TPV' ? (
        <DepositsTPVCreatedTable
          firstDate={firstDate}
          secondDate={secondDate}
          reactTableRef={reactTable}
          deposits={deposits}
          depositsFromdb={depositsTPV}
          getAllDepositsStandAlone={getAllDepositsStandAlone}
        />
      ) : (
        <DepositsSPEI
          firstDate={firstDate}
          secondDate={secondDate}
          reactTableRef={reactTable}
          deposits={deposits}
          depositsFromdb={depositsFromdb}
          getAllDeposits={getAllDepositsSPEI}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  deposits: selectDeposits(state),
  depositsFromdb: makeSelectDepositsFromdbFromState(state),
  depositsFiserv: makeSelectDepositsFiservFromdbFromState(state),
  depositsTPV: makeSelectDepositsTPVFromdbFromState(state),
  depositsTPVDownload: makeSelectDepositsTPVDownloadFromState(state),
  speiPayments: selectSpeiPayments(state),
  speiPaymentsFromdb: makeSelectSpeiPaymentsFromdbFromState(state),
  tablePages: makeSelectDepositsPages(state),
  role: makeSelectUserRoleFromState(state),
  countAllDepositsFromdb: makeSelectCountAllDepositsFromState(state),
});

const mapDispatchToProps = {
  getAllDeposits,
  countAllDeposits,
  getAllDepositsSPEI,
  getAllDepositsfiserv,
  getAllDepositsStandAlone,
  getAllSpeiPayments,
  createDeposit,
  cleanSpeiPaymentsLoaded,
  importDepositsFromExcel,
  closeModals,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(DepositsTable),
);
