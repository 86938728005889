import { call, put } from 'redux-saga/effects';
import {
  getServiceAppointmentsSuccess,
  getServiceAppointmentsFail,
  getAppointmentDetailSuccess,
  getAppointmentDetailFail,
  confirmAppointmentSuccess,
  confirmAppointmentFail,
  rejectAppointmentSuccess,
  rejectAppointmentFail,
  handleCloseAppointmentModalsSuccess,
} from 'app/features/serviceAppointments/serviceAppointmentsSlice.js';

import { requestHandler } from '../../services/requestHandler';
import moment from 'moment';
import { errors } from './errors.js';

export function* getAppointmentsSaga(action) {
  try {
    const { page, pageSize, filtered } = action.payload;
    const _renderPage = pageSize * page;
    const data = {
      offset: _renderPage,
      limit: pageSize,
      field: 'createdAt',
      ordering: 'ASC',
    };
    if (filtered.length) {
      filtered.forEach((filter) => {
        console.log('type = ', filter.id, filter.value);
        if (filter.id === 'type') {
          data.filter = filter.value;
        } else
          data.where[filter.id] = { $iLike: `%${filter.value}%` };
      });
    }
    const appointments = yield call(requestHandler, {
      method: 'POST',
      path: '/karlo-app/getServiceAppointmentsAll',
      data,
    });
    appointments.pages = yield call(
      Math.ceil,
      appointments.length / pageSize,
    );
    yield put(getServiceAppointmentsSuccess({ appointments }));
    console.log('success in appointments saga ', appointments);
  } catch (e) {
    yield put(getServiceAppointmentsFail('Not valid auth'));
    console.log('error in appointments saga', e);
  }
}

export function* getAppointmentDetailSaga(action) {
  try {
    const { karloID } = action.payload;
    console.log(action.payload);
    const data = {
      id: karloID,
    };
    const appointmentDetails = yield call(requestHandler, {
      method: 'POST',
      path: '/karlo-app/getServiceAppointmentsById',
      data,
    });
    console.log(appointmentDetails);
    yield put(getAppointmentDetailSuccess({ appointmentDetails }));
    console.log('success in appointments saga ', appointmentDetails);
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba encontrar la información de la cita.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(getAppointmentDetailFail({ message }));
    console.log('error in appointmentDetail saga', e);
  }
}

export function* updateConfirmedAppointmentSaga(action) {
  const { description, date, time, id } = action.payload;
  console.log(action.payload);
  try {
    const data = {
      id: id,
      date: moment(date).format('YYYY/MM/DD'),
      hour: time,
      description: description,
    };
    const appointment = yield call(requestHandler, {
      method: 'POST',
      path: '/karlo-app/updateServiceConfirmed',
      data,
    });
    const message = {
      title: '¡Éxito!',
      desc: `La cita ha sido confirmada correctamente.`,
    };
    yield put(confirmAppointmentSuccess({ message }));
    yield call(getAppointmentDetailSaga, {
      payload: { karloID: action.payload.id },
    });
  } catch (e) {
    let message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba confirmar la cita.
      Intente de nuevo, si el error persiste intente más tarde.`,
    };
    yield put(confirmAppointmentFail({ message }));
    console.log('error in get order detail user saga', e);
  }
}

export function* updateRejectedAppointmentSaga(action) {
  const { id } = action.payload;
  try {
    const data = {
      id: id,
    };
    const appointment = yield call(requestHandler, {
      method: 'POST',
      path: '/karlo-app/updateServiceCanceled',
      data,
    });
    const message = {
      title: '¡Éxito!',
      desc: `La cita ha sido rechaza correctamente.`,
    };
    yield put(rejectAppointmentSuccess({ message }));
    yield call(getAppointmentDetailSaga, {
      payload: { karloID: action.payload.id },
    });
  } catch (e) {
    let message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba cancelar la cita.
      Intente de nuevo, si el error persiste intente más tarde.`,
    };
    yield put(rejectAppointmentFail({ message }));
    console.log('error in get order detail user saga', e);
  }
}

export function* closeModalsAppointmentSaga(action) {
  try {
    yield put(handleCloseAppointmentModalsSuccess({}));
  } catch (error) {
    console.log('error in close modals correctly in saga', error);
  }
}
