import { closeModals } from '../modals/modalsSlice';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { logout } from '../auth/authSlice';

const karloQuotesSlice = createSlice({
  name: 'karloQuotes',
  initialState,
  reducers: {
    getKarloQuotes: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getKarloQuotesSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.fromdb = action.payload.karloQuotes;
        state.tablePages = action.payload.karloQuotes.pages;
      },
    },
    getKarloQuotesFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
  },
  extraReducers: {
    [logout]: (state, action) => {
      return initialState;
    },
  },
});

export const {
  getKarloQuotes,
  getKarloQuotesSuccess,
  getKarloQuotesFail,
} = karloQuotesSlice.actions;

export default karloQuotesSlice.reducer;
