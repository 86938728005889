export const initialState = {
  paymentOrders: {
    count: 0,
    payed: 0,
    notPayed: 0,
    sales: 0,
    service: 0,
    parts: 0,
    globalTotal: 0,
    error: false,
  },
  serviceAppointments: {
    count: 0,
  },
  serviceOrders: {
    count: 0,
    payed: 0,
    notPayed: 0,
    globalTotal: 0,
    error: false,
  },
  payments: {
    'DEBIT VISA': 0,
    'CREDIT VISA': 0,
    'CREDIT MASTERCARD': 0,
    'DEBIT MASTERCARD': 0,
    'CREDIT AMERICAN-EXPRESS': 0,
    OXXO: 0,
    SPEI: 0,
    fromdb: null,
    count: 0,
    total: 0,
    cash: 0,
    months: 0,
    invoicedPayments: 0,
    error: false,
  },
  successModal: {
    show: false,
    message: null,
  },
  errorModal: {
    show: false,
    message: null,
  },
  isLoading: false,
  isLoadingCSV: false,
  error: null,
  agencies: null,
  isLoadingPDF: false,
};
