import { closeModals } from '../modals/modalsSlice';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { logout } from '../auth/authSlice';

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    getAllUsers: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getAllUsersSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.fromdb = action.payload.users.rows;
        state.fullUsers = action.payload.users.fullUsers.rows;
        state.tablePages = action.payload.users.pages;
      },
    },
    getAllUsersFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    getUser: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getUserSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.userDetails = action.payload.user;
      },
    },
    getUserFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    createUser: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    createUserSuperAdminSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.url = action.payload.url;
        state.id = action.payload.id;
      },
    },
    resetURL: {
      reducer(state, action) {
        state.url = null;
        state.id = null;
      },
    },
    createUserSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    createUserFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    updateUser: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    updateUserSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    updateUserFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    deleteUser: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    deleteUserSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    deleteUserFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    resendInvitation: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    resendInvitationSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    resendInvitationFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
  },
  extraReducers: {
    [logout]: (state, action) => {
      return initialState;
    },
    [closeModals]: (state, action) => {
      state.isLoading = false;
      state.successModal = initialState.successModal;
      state.errorModal = initialState.errorModal;
    },
  },
});

export const {
  getAllUsers,
  getAllUsersSuccess,
  getAllUsersFail,
  getUser,
  getUserSuccess,
  getUserFail,
  createUser,
  createUserSuperAdminSuccess,
  resetURL,
  createUserSuccess,
  createUserFail,
  updateUser,
  updateUserSuccess,
  updateUserFail,
  deleteUser,
  deleteUserSuccess,
  deleteUserFail,
  resendInvitation,
  resendInvitationSuccess,
  resendInvitationFail,
} = usersSlice.actions;

export default usersSlice.reducer;
