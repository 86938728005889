import { closeModals } from '../modals/modalsSlice';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { logout } from '../auth/authSlice';

const tokenizeSlice = createSlice({
  name: 'tokenize',
  initialState,
  reducers: {
    tokenizeServiceOrder: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    tokenizeServiceOrderSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.serviceOrderToken = action.payload.token;
      },
    },
    tokenizeServiceOrderFail: {
      reducer(state, action) {
        state.isLoading = false;
      },
    },
    tokenizePaymentOrder: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    tokenizePaymentOrderSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.paymentOrderToken = action.payload.token;
      },
    },
    tokenizePaymentOrderFail: {
      reducer(state, action) {
        state.isLoading = false;
      },
    },
  },
  extraReducers: {
    [logout]: (state, action) => {
      return initialState;
    },
    [closeModals]: (state, action) => {
      state.isLoading = false;
      state.successModal = initialState.successModal;
      state.errorModal = initialState.errorModal;
    },
  },
});

export const {
  tokenizeServiceOrder,
  tokenizeServiceOrderSuccess,
  tokenizeServiceOrderFail,
  tokenizePaymentOrder,
  tokenizePaymentOrderSuccess,
  tokenizePaymentOrderFail,
} = tokenizeSlice.actions;

export default tokenizeSlice.reducer;
