import { closeModals } from '../modals/modalsSlice';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { logout } from '../auth/authSlice';

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    createNotification: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    createNotificationSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    createNotificationFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    sendNotification: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    sendNotificationSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    sendNotificationFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
  },
  extraReducers: {
    [logout]: (state, action) => {
      return initialState;
    },
    [closeModals]: (state, action) => {
      state.isLoading = false;
      state.successModal = initialState.successModal;
      state.errorModal = initialState.errorModal;
    },
  },
});

export const {
  createNotification,
  createNotificationSuccess,
  createNotificationFail,
  sendNotification,
  sendNotificationSuccess,
  sendNotificationFail,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
