import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';

const setupLogsSlices = createSlice({
  name: 'setupLogs',
  initialState,
  reducers: {
    getSetuplogs: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getSetuplogsSuccess: {
      reducer(state) {
        state.isLoading = false;
      },
    },
    getSetuplogsFail: {
      reducer(state) {
        state.isLoading = false;
      },
    },
  },
});

export const {
  getSetuplogs,
  getSetuplogsFail,
  getSetuplogsSuccess,
} = setupLogsSlices.actions;

export default setupLogsSlices.reducer;
