import { call, put } from 'redux-saga/effects';
import { getLogsFail, getLogsSuccess } from './logsSlice';

import { orderBy } from 'lodash';
import { requestHandler } from '../../services/requestHandler';

export function* getLogsSaga(action) {
  try {
    const { object, objectID, agencyID } = action.payload;
    const data = {
      where: {
        object: object || undefined,
        objectID: parseInt(objectID) || undefined,
        agency: parseInt(agencyID) || undefined,
      },
      options: { order: [['createdAt', 'DESC']] },
    };
    let logs = yield call(requestHandler, {
      method: 'POST',
      path: '/logs/getAll',
      data,
    });
    if (Array.isArray(logs) && logs.length > 0) {
      logs = orderBy(logs, ['date'], ['desc']);
    }
    yield put(getLogsSuccess({ logs, object }));
  } catch (e) {
    yield put(getLogsFail('getPaymentOrder'));
    console.log('error in login user saga', e);
  }
}
