import 'react-table/react-table.css';
import 'moment/locale/es';

/* eslint-disable react/display-name */
import {
  Paper,
  makeStyles,
  Button,
  TextField,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import {
  getTerminals,
  createTerminal,
  deleteTerminal,
  updateTerminal,
} from 'app/features/terminals/terminalsSlice';

import { closeModals } from 'app/features/modals/modalsSlice';
import Modal from '../../../../components/Modal';
import Add from '@material-ui/icons/Add';
import React from 'react';
import ReactTable from 'react-table';
import ShouldItRender from '../../../../components/ShouldItRender';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import MUIRHFAutocompleteAgencies from 'app/components/MUIRHFAutocompleteAgencies';
import { injectIntl } from 'react-intl';
import { makeSelectTerminals } from 'app/features/terminals/selectors';
import { useForm } from 'react-hook-form';
import { formatSingleDate, formatHour } from '../../../../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(0),
  },
  notifications: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textField: {
    margin: theme.spacing(1),
    width: '95%',
  },
  lastUpdated: {
    marginLeft: theme.spacing(2),
    color: '#434349',
  },
  datePickerTextfield: {
    padding: '5px 5px 5px !important',
    height: '20px',
  },
}));

function TerminalsTable(props) {
  const classes = useStyles();
  const {
    history,
    getTerminals,
    terminals,
    setActionButton,
    createTerminal,
    deleteTerminal,
    updateTerminal,
    closeModals,
  } = props;
  const [openDelete, setOpenDelete] = React.useState(false);
  const [terminalID, seTerminalID] = React.useState(0);
  const [terminalsData, setTerminalsData] = React.useState([]);
  const reactTable = React.useRef(null);
  const [showAddAgencyModal, setShowAddAgencyModal] = React.useState(
    false,
  );
  const {
    handleSubmit,
    register,
    errors,
    control,
    reset,
  } = useForm();

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = terminals;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message } = terminals[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={handleCloseModals}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={handleCloseModals}
          />
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  const handleFetchData = debounce(
    (state, instance) => {
      const { page, pageSize, sorted, filtered } = state;
      getTerminals({ page, pageSize, sorted, filtered });
    },
    1000,
    { leading: false, trailing: true },
  );

  let toogleAddAgencyModal = () => {
    setShowAddAgencyModal(!showAddAgencyModal);
    seTerminalID(null);
    reset({
      agencyID: '',
      serialNumber: '',
      model: '',
      name: '',
    });
  };

  const handleCloseModals = () => {
    setShowAddAgencyModal(false);
    setOpenDelete(false);
    closeModals();
  };

  const handleExtraValidation = (formData) => {
    console.log('formData: ', formData);
    createTerminal(formData);
    handleCloseModals();
  };

  const handleEditValidation = (formData) => {
    console.log('formData: ', formData);
    updateTerminal({
      ...formData,
      id: terminalID,
    });
    handleCloseModals();
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleDelete = () => {
    deleteTerminal({ terminalID });
    handleCloseDelete();
  };

  const handleCreateOrEditTerminal = (formData) => {
    if (terminalID) {
      handleEditValidation(formData);
    } else {
      handleExtraValidation(formData);
    }
  };

  const renderAddTerminal = () => {
    return (
      <Modal
        open={showAddAgencyModal}
        closeModal={handleCloseModals}
        dialogTitle={
          terminalID ? 'Editar terminal' : 'Crear terminal'
        }
        actionButtonText={
          terminalID ? 'Editar terminal' : 'Crear terminal'
        }
        form={'create-user-form'}
        dialogChildren={
          <form
            id="create-user-form"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(handleCreateOrEditTerminal)}
            // onSubmit={handleSubmit(handleExtraValidation)}
          >
            <MUIRHFAutocompleteAgencies
              rules={{ required: true }}
              error={Boolean(errors.agencyID)}
              name="agencyID"
              type="agencyID"
              label="agencyID"
              control={control}
              fullWidth
              className={classes.textField}
            />
            <TextField
              required
              name="serialNumber"
              label="Número de serie"
              type="serialNumber"
              variant="outlined"
              // onChange={handleCharactersMail}
              inputRef={register({
                required: true,
              })}
              className={classes.textField}
              error={Boolean(errors.serialNumber)}
            />
            <TextField
              required
              name="model"
              label="Modelo"
              type="model"
              variant="outlined"
              // onChange={handleCharactersMail}
              inputRef={register({
                required: true,
              })}
              className={classes.textField}
              error={Boolean(errors.model)}
            />
            <TextField
              required
              name="name"
              label="Nombre"
              type="name"
              variant="outlined"
              // onChange={handleCharactersMail}
              inputRef={register({
                required: true,
              })}
              className={classes.textField}
              error={Boolean(errors.name)}
            />
          </form>
        }
      />
    );
  };

  React.useEffect(() => {
    setActionButton(
      <div>
        <Button
          variant="contained"
          className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
          onClick={toogleAddAgencyModal}
        >
          Crear Terminal
          <Add className="kt-margin-l-10" />
        </Button>
      </div>,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    console.log('terminalsFROMDB ', terminals);
    if (Array.isArray(terminals.fromdb)) {
      const cleanData = terminals.fromdb.map((terminal) => {
        return {
          id: terminal.id,
          name: terminal.name ? terminal.name : '-',
          serialNumber: terminal.serialNumber
            ? terminal.serialNumber
            : '-',
          model: terminal.model ? terminal.model : '-',
          agencyID: terminal.agencyID ? terminal.agencyID : '-',
          createdAt: formatSingleDate(terminal.createdAt),
        };
      });
      setTerminalsData(cleanData);
    }
  }, [terminals]);

  const columns = [
    {
      Header: () => <strong>ID</strong>,
      accessor: 'id',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value}
        </div>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Nombre</strong>,
      accessor: 'name',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value}
        </div>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Número de Serie</strong>,
      accessor: 'serialNumber',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value}
        </div>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Modelo</strong>,
      accessor: 'model',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value}
        </div>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Agencia</strong>,
      accessor: 'agencyID',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value}
        </div>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Fecha de Creación</strong>,
      accessor: 'createdAt',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value}
        </div>
      ),
      filterable: false,
    },
    {
      Header: '',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <Tooltip title="Editar terminal">
            <IconButton
              className={classes.button}
              size="small"
              onClick={() => {
                setShowAddAgencyModal(true);
                reset({
                  name: row.original.name,
                  serialNumber: row.original.serialNumber,
                  model: row.original.model,
                  agencyID: row.original.agencyID,
                });
                seTerminalID(row.original.id);
                // handleCharactersUser(row.original.name);
                // handleCharactersMail(row.original.email);
              }}
            >
              <i className="far fa-edit"></i>
            </IconButton>
          </Tooltip>
          <Tooltip title="BORRAR">
            <IconButton
              className={classes.button}
              size="small"
              onClick={() => {
                handleOpenDelete();
                seTerminalID(row.original.id);
              }}
            >
              <i className="far fa-trash-alt"></i>
            </IconButton>
          </Tooltip>
        </div>
      ),
      filterable: false,
    },
  ];

  const renderConfirmationDelete = () => {
    return (
      <Modal
        open={openDelete}
        type="warning"
        closeModal={handleCloseDelete}
        dialogTitle="¿Seguro que deseas eliminar la terminal?"
        dialogText="Si continúas no habrá manera de recuperar la información
            que haya sido borrada."
        actionButtonText="Eliminar terminal"
        onClick={handleDelete}
      />
    );
  };

  return (
    <div className={classes.root}>
      {renderAddTerminal()}
      {renderConfirmationDelete()}
      {renderFeedBackModal()}
      <Paper className={classes.paper}>
        <ReactTable
          ref={reactTable}
          NoDataComponent={() => (
            <div className="rt-noData">Sin resultados</div>
          )}
          manual
          onFetchData={handleFetchData}
          data={terminalsData}
          pages={terminals.tablePages}
          columns={columns}
          // filterable
          className="-striped -highlight"
          previousText="Anterior"
          nextText="Siguiente"
          loading={terminals.isLoading}
          loadingText="Cargando datos..."
        />
      </Paper>
    </div>
  );
}

const mapStateToProps = (state) => ({
  terminals: makeSelectTerminals(state),
});

const mapDispatchToProps = {
  getTerminals,
  createTerminal: createTerminal,
  deleteTerminal: deleteTerminal,
  updateTerminal: updateTerminal,
  closeModals,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(TerminalsTable),
);
