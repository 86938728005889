import MenuConfig from '_metronic/layout/MenuConfig';
import { get } from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import { toNumber } from 'lodash';

export function getSOStatusName(status, agencyIsMercedes) {
  if (agencyIsMercedes) {
    const formated = {
      open: 'Abierta',
      remission: 'Pendiente',
      payment: 'Pagada',
      billed: 'Facturada',
    };
    return formated[status];
  } else {
    const formated = {
      open: 'Abierta',
      remission: 'Por pagar',
      payment: 'Pagada',
      billed: 'Facturada',
      canceled: 'Cancelada',
      REFUND: 'Devuelto',
    };
    return formated[status];
  }
}

export function getOffersStatusName(status) {
  const formated = {
    sent: 'Enviada',
    accepted: 'Aceptada',
    rejected: 'Rechazada',
    canceled: 'Cancelada',
    expired: 'Expirada',
  };
  return formated[status];
}

export function getFormatPaymentName(status) {
  const formated = {
    'credit': 'Crédito',
    debit: 'Débito',
    'credit-amex': 'Amex',
    international: 'Internacional',
  };
  return formated[status];
}
export function getPOStatusName(status) {
  const formated = {
    'por pagar': 'Por pagar',
    pagado: 'Pagada',
    cancelada: 'Cancelada',
    cancelado: 'Cancelado',
    'pago provisional': 'Pago provisional',
    facturada: 'Facturada',
    APPLIED: 'Aplicado',
    expirado: 'Expirado',
    REFUND: 'Devuelto',
    
  };
  return formated[status];
}
export function getDISPERSIONStatusName(status) {
  const formated = {
    LIQUIDADO: 'Liquidado',
    FALLO: 'Fallo',
    pendiente: 'Pendiente',
    CANCELADO: 'Cancelado',
  };
  return formated[status];
}

export function getBadgeColor(status) {
  const colored = {
    Abierta: 'info',
    Enviada: 'info',
    'Por pagar': 'warning',
    'por pagar': 'warning',
    Pendiente: 'warning',
    Fallo: 'warning',
    Aceptada: 'success',
    Pagada: 'success',
    pagado: 'success',
    Pagado: 'success',
    Liquidado: 'success',
    Facturada: 'success',
    Cancelada: 'danger',
    cancelada: 'danger',
    Rechazada: 'danger',
    Cancelado: 'danger',
    Vencida: 'danger',
    Vencido: 'danger',
    'Pago provisional': 'info',
    'Url Enviado': 'warning',
    Expirada: 'secondary',
    Expirado: 'secondary',
    Vigente: 'info',
    Aplicado: 'info',
    enProceso: 'warning',
    aFavor: 'success',
    enContra: 'danger',
    Devuelto: 'info',
    Dispersada: 'success',
    billed: 'success',
    facturada: 'success',
  };
  return colored[status];
}

export function getAppointmentColor(status) {
  const colored = {
    pending: 'info',
    confirmed: 'success',
    canceled: 'danger',
  };
  return colored[status];
}

export function getDispersionStatus(status) {
  status = status ? 'Dispersada' : 'Pendiente';
  return status;
}

export function getAppointmentName(status) {
  const name = {
    pending: 'Creada',
    confirmed: 'Confirmada',
    canceled: 'Cancelada',
  };
  return name[status];
}

export function getCOStatusName(status, agencyIsMercedes) {
  const formated = {
    open: 'Abierta',
    remission: 'Por pagar',
    payment: 'Pagada',
    billed: 'Facturada',
  };
  return formated[status];
}

export const filterCaseInsensitive = (filter, row) => {
  const id = filter.pivotId || filter.id;
  if (row[id] !== null && typeof row[id] === 'string') {
    return row[id] !== undefined
      ? String(row[id].toLowerCase()).includes(
          filter.value.toLowerCase(),
        )
      : true;
  } else {
    return String(row[filter.id]).includes(String(filter.value));
  }
};

export function formatNumber(number) {
  return numeral(number).format('$0,0.00');
}

export const formatMoney = (number) => {
  return numeral(number).format('$0,0.00');
};

export const formatPercent = (number) => {
  const value = +number * 100;
  return `${numeral(value).format('0.00')}%`;
};

export const getColumnWidth = (data, accessor, headerText) => {
  const cellLength = Math.max(
    ...data.map((row) => {
      let value = '';

      if (typeof accessor === 'string') {
        value = get(row, accessor);
      } else {
        value = accessor(row);
      }

      if (typeof value === 'number') return value.toString().length;
      return (value || '').length;
    }),
    headerText.length,
  );

  const magicSpacing = 8;
  return cellLength * magicSpacing;
};

export const getMainRoute = (role) => {
  let mainRoute = '/';
  if (role in MenuConfig.aside) {
    mainRoute = `/${
      MenuConfig.aside[role]?.routes[MenuConfig.aside[role]?.main]
    }`;
  }
  return mainRoute;
};

export const cleanEmptyKeys = (object, originalObject = {}) => {
  const tempObject = { ...object };
  Object.keys(tempObject).forEach((key) => {
    if (originalObject[key] && tempObject[key] === '') {
      tempObject[key] = null;
    } else if (!originalObject[key] && tempObject[key] === '') {
      tempObject[key] = undefined;
    }
  });
  return tempObject;
};

export const getNested = (isUndefined, obj, ...args) => {
  return (
    args.reduce((obj, level) => obj && obj[level], obj) || isUndefined
  );
};

export const dmsValues = [
  {
    value: '',
    label: '',
  },
  {
    value: 'magikv2',
    label: 'Magik v2 ',
  },
  {
    value: 'magik',
    label: 'Magik',
  },
  {
    value: 'WTF',
    label: 'WTF',
  },
  {
    value: 'businesspro',
    label: 'Bussiness PRO',
  },
  {
    value: 'clearmechanics',
    label: 'Clear Mechanics',
  },
  {
    value: 'solomon(camcar)',
    label: 'Solomon (Camcar)',
  },
  {
    value: 'solomon(camcar) leon',
    label: 'Solomon (Camcar) León',
  },
  {
    value: 'GDS',
    label: 'GDS',
  },
  {
    value: 'gds-vw-lindavista',
    label: 'GDS VW LindaVista',
  },
  {
    value: 'ABG',
    label: 'ABG',
  },
  {
    value: 'karlo lite',
    label: 'Karlo Lite',
  },
  {
    value: 'credits',
    label: 'Créditos',
  },
  {
    value: 'karlo',
    label: 'Karlo',
  },
  {
    value: 'ftp-autoline',
    label: 'Ftp Autoline',
  },
  {
    value: 'sia',
    label: 'SIA',
  },
  {
    value: 'w32',
    label: 'W32 PHP',
  },
  {
    value: 'sad',
    label: 'SAD',
  },
  {
    value: 'quiter',
    label: 'Quiter',
  },
  {
    value: 'vamsa',
    label: 'Vamsa',
  },
  {
    value: 'sao',
    label: 'SAO',
  },
  {
    value: 'boox',
    label: 'Boox',
  },
  {
    value: 'alden',
    label: 'Alden',
  },
  {
    value: 'intelisis',
    label: 'Intelisis',
  },
  {
    value: 'Total Dealer',
    label: 'Total Dealer',
  },
  {
    value: 'edega',
    label: 'EDEGA',
  },
  {
    value: 'dynamics',
    label: 'Dynamics',
  },
];
export const enumEventInvoice = [
  {
    value: '',
    label: '',
  },
  {
    value: 'all fields disabled',
    label: 'Si mostrar los inputs pero que ninguno sea editable',
  },
  {
    value: 'fiscal fields enabled',
    label:
      'Si mostrar los inputs y que solo ‘usoCFDI’ y ‘regimenFiscal’ son editables',
  },
  {
    value: 'all fields enabled',
    label: 'Si mostrar los inputs y todos los campos son editables',
  },
  {
    value: 'rfc and social reason disabled',
    label: 'Si mostrar los inputs y que el RFC y Razón  Social no sean editables',
  },
];
export const institutionDispersion = [
  {
    value: null,
    label: '',
  },
  {
    value: '2001',
    label: 'BANXICO',
  },
  {
    value: '37006',
    label: 'BANCOMEXT',
  },
  {
    value: '37009',
    label: 'BANOBRAS',
  },
  {
    value: '37019',
    label: 'BANJERCITO',
  },
  {
    value: '37135',
    label: 'NAFIN',
  },
  {
    value: '37166',
    label: 'BaBien',
  },
  {
    value: '37168',
    label: 'HIPOTECARIA FED',
  },
  {
    value: '40002',
    label: 'BANAMEX',
  },
  {
    value: '40012',
    label: 'BBVA MEXICO',
  },
  {
    value: '40014',
    label: 'SANTANDER',
  },
  {
    value: '40021',
    label: 'HSBC',
  },
  {
    value: '40030',
    label: 'BAJIO',
  },
  {
    value: '40036',
    label: 'INBURSA',
  },
  {
    value: '40042',
    label: 'MIFEL',
  },
  {
    value: '40044',
    label: 'SCOTIABANK',
  },
  {
    value: '40058',
    label: 'BANREGIO',
  },
  {
    value: '40059',
    label: 'INVEX',
  },
  {
    value: '40060',
    label: 'BANSI',
  },
  {
    value: '40062',
    label: 'AFIRME',
  },
  {
    value: '40072',
    label: 'BANORTE',
  },
  {
    value: '40106',
    label: 'BANK OF AMERICA',
  },
  {
    value: '40108',
    label: 'MUFG',
  },
  {
    value: '40110',
    label: 'JP MORGAN',
  },
  {
    value: '40112',
    label: 'BMONEX',
  },
  {
    value: '40113',
    label: 'VE POR MAS',
  },
  {
    value: '40126',
    label: 'CREDIT SUISSE',
  },
  {
    value: '40127',
    label: 'AZTECA',
  },
  {
    value: '40128',
    label: 'AUTOFIN',
  },
  {
    value: '40129',
    label: 'BARCLAYS',
  },
  {
    value: '40130',
    label: 'COMPARTAMOS',
  },
  {
    value: '40132',
    label: 'MULTIVA BANCO',
  },
  {
    value: '40133',
    label: 'ACTINVER',
  },
  {
    value: '40136',
    label: 'INTERCAM BANCO',
  },
  {
    value: '40137',
    label: 'BANCOPPEL',
  },
  {
    value: '40138',
    label: 'ABC CAPITAL',
  },
  {
    value: '40140',
    label: 'CONSUBANCO',
  },
  {
    value: '40141',
    label: 'VOLKSWAGEN',
  },
  {
    value: '40143',
    label: 'CIBANCO',
  },
  {
    value: '40145',
    label: 'BBASE',
  },
  {
    value: '40147',
    label: 'BANKAOOL',
  },
  {
    value: '40148',
    label: 'PAGATODO',
  },
  {
    value: '40150',
    label: 'INMOBILIARIO',
  },
  {
    value: '40151',
    label: 'DONDE',
  },
  {
    value: '40152',
    label: 'BANCREA',
  },
  {
    value: '40154',
    label: 'BANCO COVALTO',
  },
  {
    value: '40155',
    label: 'ICBC',
  },
  {
    value: '40156',
    label: 'SABADELL',
  },
  {
    value: '40157',
    label: 'SHINHAN',
  },
  {
    value: '40158',
    label: 'MIZUHO BANK',
  },
  {
    value: '40159',
    label: 'BANK OF CHINA',
  },
  {
    value: '40160',
    label: 'BANCO S3',
  },
  {
    value: '90600',
    label: 'MONEXCB',
  },
  {
    value: '90601',
    label: 'GBM',
  },
  {
    value: '90602',
    label: 'MASARI',
  },
  {
    value: '90605',
    label: 'VALUE',
  },
  {
    value: '90608',
    label: 'VECTOR',
  },
  {
    value: '90613',
    label: 'MULTIVA CBOLSA',
  },
  {
    value: '90616',
    label: 'FINAMEX',
  },
  {
    value: '90617',
    label: 'VALMEX',
  },
  {
    value: '90620',
    label: 'PROFUTURO',
  },
  {
    value: '90630',
    label: 'CB INTERCAM',
  },
  {
    value: '90631',
    label: 'CI BOLSA',
  },
  {
    value: '90634',
    label: 'FINCOMUN',
  },
  {
    value: '90638',
    label: 'NU MEXICO',
  },
  {
    value: '90642',
    label: 'REFORMA',
  },
  {
    value: '90646',
    label: 'STP',
  },
  {
    value: '90648',
    label: 'EVERCORE',
  },
  {
    value: '90652',
    label: 'CREDICAPITAL',
  },
  {
    value: '90653',
    label: 'KUSPIT',
  },
  {
    value: '90656',
    label: 'UNAGRA',
  },
  {
    value: '90659',
    label: 'ASP INTEGRA OPC',
  },
  {
    value: '90661',
    label: 'ALTERNATIVOS',
  },
  {
    value: '90670',
    label: 'LIBERTAD',
  },
  {
    value: '90677',
    label: 'CAJA POP MEXICA',
  },
  {
    value: '90680',
    label: 'CRISTOBAL COLON',
  },
  {
    value: '90683',
    label: 'CAJA TELEFONIST',
  },
  {
    value: '90684',
    label: 'OPM',
  },
  {
    value: '90685',
    label: 'FONDO (FIRA)',
  },
  {
    value: '90686',
    label: 'INVERCAP',
  },
  {
    value: '90689',
    label: 'FOMPED',
  },
  {
    value: '90703',
    label: 'TESORED',
  },
  {
    value: '90710',
    label: 'NVIO',
  },
  {
    value: '90902',
    label: 'INDEVAL',
  },
  {
    value: '90903',
    label: 'CoDi Valida',
  },
  {
    value: '91812',
    label: 'BBVA BANCOMER2*',
  },
  {
    value: '91814',
    label: 'SANTANDER2*',
  },
  {
    value: '91821',
    label: 'HSBC2*',
  },
  {
    value: '91927',
    label: 'AZTECA2*',
  },
  {
    value: '91872',
    label: 'BANORTE2*',
  },
  {
    value: '90706',
    label: 'ARCUS',
  },
  {
    value: '90710',
    label: 'NVIO',
  },
  {
    value: '91802',
    label: 'BANAMEX2',
  },
];
export const enumPaymentAggregators = [
  {
    value: 'fiserv',
    label: 'fiserv',
  },
  {
    value: 'billpocket',
    label: 'billpocket',
  },
];

export const brandsArray = [
  'acura',
  'alfa romeo',
  'audi',
  'baic',
  'bmw',
  'buick',
  'cadillac',
  'cbo',
  'chevrolet',
  'chrysler',
  'faw',
  'fiat',
  'ford',
  'general motors',
  'giant',
  'gmc',
  'hino',
  'honda',
  'hyundai',
  'infiniti',
  'isuzu',
  'jac',
  'jaguar',
  'kia',
  'land rover',
  'lincoln',
  'mazda',
  'mercedes benz',
  'mg',
  'mini cooper',
  'mitsubishi',
  'nissan',
  'peugeot',
  'porsche',
  'renault',
  'seat',
  'smart',
  'subaru',
  'suzuki',
  'toyota',
  'volkswagen',
  'volvo',
];

export const brands = [
  {
    label: 'Acura',
    value: 'acura',
  },
  {
    label: 'Alfa Romeo',
    value: 'alfa romeo',
  },
  {
    label: 'Audi',
    value: 'audi',
  },
  {
    label: 'Baic',
    value: 'baic',
  },
  {
    label: 'BMW',
    value: 'bmw',
  },
  {
    label: 'Buick',
    value: 'buick',
  },
  {
    label: 'Cadillac',
    value: 'cadillac',
  },
  {
    label: 'Cbo',
    value: 'cbo',
  },
  {
    label: 'Chevrolet',
    value: 'chevrolet',
  },
  {
    label: 'Chrysler',
    value: 'chrysler',
  },
  {
    label: 'FAW',
    value: 'faw',
  },
  {
    label: 'Fiat',
    value: 'fiat',
  },
  {
    label: 'Ford',
    value: 'ford',
  },
  {
    label: 'General Motors',
    value: 'general motors',
  },
  {
    label: 'Giant',
    value: 'giant',
  },
  {
    label: 'GMC',
    value: 'gmc',
  },
  {
    label: 'HINO',
    value: 'hino',
  },
  {
    label: 'Honda',
    value: 'honda',
  },
  {
    label: 'Hyundai',
    value: 'hyundai',
  },
  {
    label: 'Infiniti',
    value: 'infiniti',
  },
  {
    label: 'Isuzu',
    value: 'isuzu',
  },
  {
    label: 'JAC',
    value: 'jac',
  },
  {
    label: 'Jaguar',
    value: 'jaguar',
  },
  {
    label: 'KIA',
    value: 'kia',
  },
  {
    label: 'Land Rover',
    value: 'land rover',
  },
  {
    label: 'Lincoln',
    value: 'lincoln',
  },
  {
    label: 'Mazda',
    value: 'mazda',
  },
  {
    label: 'Mercedes Benz',
    value: 'mercedes benz',
  },
  {
    label: 'MG',
    value: 'mg',
  },
  {
    label: 'Mini Cooper',
    value: 'mini cooper',
  },
  {
    label: 'Mitsubishi',
    value: 'mitsubishi',
  },
  {
    label: 'Nissan',
    value: 'nissan',
  },
  {
    label: 'Peugeot',
    value: 'peugeot',
  },
  {
    label: 'Porsche',
    value: 'porsche',
  },
  {
    label: 'Renault',
    value: 'renault',
  },
  {
    label: 'Seat',
    value: 'seat',
  },
  {
    label: 'Smart',
    value: 'smart',
  },
  {
    label: 'Subaru',
    value: 'subaru',
  },
  {
    label: 'Suzuki',
    value: 'suzuki',
  },
  {
    label: 'Toyota',
    value: 'toyota',
  },
  {
    label: 'Volkswagen',
    value: 'volkswagen',
  },
  {
    label: 'Volvo',
    value: 'volvo',
  },
];

export const mappedAssesors = (advisorName) => {
  const advisorsNames = {
    '#1001': 'Mario Padilla asesor de servicio',
    '#1002': 'Israel Hernandez asesor de servicio',
    '#1003': 'Carlos Salgado asesor de servicio',
  };
  if (advisorName in advisorsNames) return advisorsNames[advisorName];
  else return advisorName;
};

export const cloneObjectLevels = (objectToClone) => {
  return JSON.parse(JSON.stringify(objectToClone));
};

export const isNumeric = (value) =>
  !isNaN(parseFloat(value)) && isFinite(value);

export const formatDate = (date) => {
  return moment(date).format('DD/MM/YYYY hh:mm a');
};

export const formatExpiricyDate = (date) => {
  return moment(date).endOf('day').fromNow();
};

export const formatSingleDate = (date) => {
  return moment(date).format('DD/MM/YYYY');
};

export const formatSingleDateBillpocket = (date) => {
  const tempDate = moment(date).utc().format('DD/MM/YYYY');
  return tempDate
};

export const formatHour = (date) => {
  return moment(date).format('LT');
};

export const daysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate();
};

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const onlyNumbers = new RegExp('^[0-9.]+$');

export const formatDatesForFilter = (
  startDate,
  endDate = moment(startDate),
) => {
  if (startDate)
    return {
      $gt: startDate.startOf('day'),
      $lt: endDate.endOf('day'),
    };
  return undefined;
};

export const formatDatesForFilterBp = (
  startDate,
  endDate = moment(startDate),
) => {
  if (startDate) {
    const tempDate = startDate.subtract(1, 'days').startOf('day');

    return {
      $gt: tempDate,
      $lt: endDate.endOf('day'),
    };
  }

  return undefined;
};

export const formatDatesForFilterTimeZone = (
  startDate,
  endDate = moment(startDate),
) => {
  if (startDate)
    return {
      $gt: startDate
        .startOf('day')
        .format('YYYY-MM-DD 00:00:00.000000 -05:00'),
      $lt: endDate
        .endOf('day')
        .format('YYYY-MM-DD 23:59:59.999000 -05:00'),
    };
  return undefined;
};

export const roundValue = (num, decimals = 2) => {
  let scaling = 10 ** decimals;
  return Math.round((num + Number.EPSILON) * scaling) / scaling;
};

export const typeOfPromotion = [
  {
    value: 'service',
    label: 'Servicios',
  },
  {
    value: 'parts',
    label: 'Refacciones',
  },
  {
    value: 'sales',
    label: 'Ventas',
  },
  {
    value: 'fi',
    label: 'F&I y Garantías',
  },
  {
    value: 'preowned',
    label: 'Apartado',
  },
  {
    value: 'insurance',
    label: 'Seguro',
  },
  {
    value: 'h&p',
    label: 'H&P',
  },
  {
    value: 'reservation',
    label: 'Reservación',
  },
];

export const typeOfPromotionMap = (typeOfPromotion) => {
  switch (typeOfPromotion) {
    case 'service':
      return 'Servicios';
    case 'parts':
      return 'Refacciones';
    case 'sales':
      return 'Ventas';
    case 'fi':
      return 'F&I y Garantías';
    case 'preowned':
      return 'Seminuevo';
    case 'insurance':
      return 'Seguro';
    case 'h&p':
      return 'H&P';
    case 'reservation':
      return 'Reservación';
    case 'maintenance':
      return 'Mantenimiento';
    case 'pulled_apart':
      return 'Apartados';
    case 'deducible':
      return 'Deducibles';
    case 'plates':
      return 'Placas';
    case 'accessories':
      return 'Accesorios';
    default:
      return typeOfPromotion;
  }
};

export const paymentsInfoBase = {
  cash: {
    name: 'Pagos de contado',
    desc: '',
    count: 0,
  },
  3: {
    name: 'Pagos a 3 meses',
    desc: '',
    count: 0,
  },
  6: {
    name: 'Pagos a 6 meses',
    desc: '',
    count: 0,
  },
  9: {
    name: 'Pagos a 9 meses',
    desc: '',
    count: 0,
  },
  12: {
    name: 'Pagos a 12 meses',
    desc: '',
    count: 0,
  },
};

export const paymentsTypeInfoBase = {
  visa: {
    name: 'Visa',
    desc: '',
    count: 0,
  },
  master: {
    name: 'Master Card',
    desc: '',
    count: 0,
  },
  amex: {
    name: 'American Express',
    desc: '',
    count: 0,
  },
  oxxo: {
    name: 'Oxxo',
    desc: '',
    count: 0,
  },
  spei: {
    name: 'Spei',
    desc: '',
    count: 0,
  },
};

export const typeOrders = [
  {
    value: 'MSCArmonia',
    label: 'MSC Armonia',
  },
  {
    value: 'MSCBellisima',
    label: 'MSC Bellisima',
  },
  {
    value: 'MSCDivina',
    label: 'MSC Divina',
  },
  {
    value: 'MSCEuribia',
    label: 'MSC Euribia',
  },
  {
    value: 'MSCFantasia',
    label: 'MSC Fantasia',
  },
  {
    value: 'MSCGrandiosa',
    label: 'MSC Grandiosa',
  },
  {
    value: 'MSCLirica',
    label: 'MSC Lirica',
  },
  {
    value: 'MSCMagnifica',
    label: 'MSC Magnifica',
  },
  {
    value: 'MSCMeraviglia',
    label: 'MSC Meraviglia',
  },
  {
    value: 'MSCMusica',
    label: 'MSC Musica',
  },
  {
    value: 'MSCOpera',
    label: 'MSC Opera',
  },
  {
    value: 'MSCOrchestra',
    label: 'MSC Orchestra',
  },
  {
    value: 'MSCPoesia',
    label: 'MSC Poesia',
  },
  {
    value: 'MSCPreziosa',
    label: 'MSC Preziosa',
  },
  {
    value: 'MSCSeascape',
    label: 'MSC Seascape',
  },
  {
    value: 'MSCSeashore',
    label: 'MSC Seashore',
  },
  {
    value: 'MSCSeaside',
    label: 'MSC Seaside',
  },
  {
    value: 'MSCSeaview',
    label: 'MSC Seaview',
  },
  {
    value: 'MSCSinfonia',
    label: 'MSC Sinfonia',
  },
  {
    value: 'MSCSplendida',
    label: 'MSC Splendida',
  },
  {
    value: 'MSCVirtuosa',
    label: 'MSC Virtuosa',
  },
  {
    value: 'MSCWorldAmerica',
    label: 'MSC World America',
  },
  {
    value: 'MSCWorldEuropa',
    label: 'MSC World Europa',
  },
];

export const ports = [
  {
    value: 'Miami',
    label: 'Miami',
  },
  {
    value: 'NewYork',
    label: 'New York',
  },
  {
    value: 'Barcelona',
    label: 'Barcelona',
  },
  {
    value: 'Roma',
    label: 'Roma',
  },
  {
    value: 'Venecia',
    label: 'Venecia',
  },
  {
    value: 'otro',
    label: 'Otro',
  },
];

export const resorts = [
  {
    value: 'LosCabos',
    label: 'Los Cabos',
  },
  {
    value: 'Cancun',
    label: 'Cancún',
  },
  {
    value: 'PuertoVallarta',
    label: 'Puerto Vallarta',
  },
];

export const units = [
  {
    value: 'Club Regina',
    label: 'Club Regina',
  },
  {
    value: 'Las Palmas',
    label: 'Las Palmas',
  },
];

export const formatCardNumber = (value) => {
  const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g;
  const onlyNumbers = value.replace(/[^\d]/g, '');

  return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
    [$1, $2, $3, $4].filter((group) => !!group).join(' ')
  );
};

export const formatCardNumberAmex = (value) => {
  const regex = /^(\d{0,4})(\d{0,6})(\d{0,5})$/g;
  const onlyNumbers = value.replace(/[^\d]/g, '');

  return onlyNumbers.replace(regex, (regex, $1, $2, $3) =>
    [$1, $2, $3].filter((group) => !!group).join(' ')
  );
};

export const validateDate = (monthYear) => {
  if (cardDate.test(monthYear)) {
    const cardDate = monthYear.split('/');
    const exMonth = toNumber(cardDate[0]);
    const exYear = toNumber(cardDate[1]);
    if (exMonth > 12) return false;
    if (isNaN(exMonth) || isNaN(exYear)) return false;
    const today = new Date();
    const someday = new Date();
    someday.setFullYear(`20${exYear}`, exMonth, 1);
    if (someday < today) {
      return false;
    } else {
      return true;
    }
  }
  return true;
};
export const cardDate = /^[0-9/]+$/;

