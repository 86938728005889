import React, { useEffect, useState } from 'react';

import { Autocomplete } from '@material-ui/lab';
import { Controller } from 'react-hook-form';
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import { getAgencies } from 'app/features/agencies/agenciesSlice';
import { makeSelectAgenciesFromdb } from 'app/features/agencies/selectors';

function MUIRHFAutocompleteAgencies(props) {
  const {
    agenciesFromdb,
    getAgencies,
    className,
    error,
    onChange,
    getAllInfo,
    ...rest
  } = props;

  const [agenciesOptions, setAgenciesOptions] = useState([]);

  useEffect(() => {
    getAgencies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Array.isArray(agenciesFromdb)) {
      const cleanAgencies = agenciesFromdb.map((agency) => {
        return {
          id: agency.id,
          label: agency.name,
          provisionalPayments: agency.provisionalPayments,
        };
      });
      setAgenciesOptions(cleanAgencies);
    }
  }, [agenciesFromdb]);

  const getOpObj = (option) => {
    if (!option.id)
      option = agenciesOptions.find((op) => op.id === option);
    return option;
  };

  const getOpLabel = (option) => {
    const optionSelected = getOpObj(option);
    if (optionSelected) return optionSelected.label;
    return '';
  };

  const handleOnChange = (data) => {
    if (data) {
      if (onChange) {
        onChange(data.id);
      }
      if (getAllInfo) {
        getAllInfo(data);
      }
    } else {
      onChange('');
    }
  };

  return (
    <Controller
      {...rest}
      render={(props) => (
        <Autocomplete
          {...props}
          className={className}
          getOptionLabel={getOpLabel}
          getOptionSelected={(option, value) =>
            option.id === value.id
          }
          options={agenciesOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              label={'Agencia'}
              variant="outlined"
              error={error}
            />
          )}
          onChange={(_, data) => handleOnChange(data)}
        />
      )}
    />
  );
}

const mapStateToProps = (state) => ({
  agenciesFromdb: makeSelectAgenciesFromdb(state),
});

const mapDispatchToProps = {
  getAgencies: getAgencies,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MUIRHFAutocompleteAgencies);
