import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  ListItem,
  ListItemText,
  Switch,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { getNested, isNumeric, typeOfPromotion } from 'app/utils';

import NumberFormatCustom from '../../../../components/NumberFormatCustom';
import React, { Fragment } from 'react';
import amex from '../../../../assets/img/logos/amex.svg';
import { closeModals } from 'app/features/modals/modalsSlice';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { getOrUpdateSurTaxes } from '../../../agencies/agenciesSlice';
import { injectIntl } from 'react-intl';
import mastercard from '../../../../assets/img/logos/mastercard.svg';
import visa from '../../../../assets/img/logos/visa.svg';
import { withRouter } from 'react-router-dom';
import { identity } from 'lodash-es';
import RecentActivities from '../../../../components/RecentActivities';
import {
  makeSelectUserRoleFromState,
} from '../../../auth/selectors';

const useStyles = makeStyles((theme) => ({
  commission: {
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: 'black',
  },
  tables: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  leyendsContainer: {
    padding: theme.spacing(1, 3, 4, 5),
  },
  card: {
    minWidth: 275,
    marginBottom: theme.spacing(2),
  },
  CardContent: {
    padding: theme.spacing(0, 3),
    '&:last-child': {
      paddingBottom: '0px',
    },
  },
  cardInnerContent: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  flex: {
    flex: '1',
  },
  flexToogle: {
    alignItems: 'flex-start',
  },
  items: {
    padding: theme.spacing(0),
  },
  textField: {
    width: '250px',
    marginBottom: '0px',
    marginTop: '0px',
    marginRight: theme.spacing(1),
  },
  imageBrands: {
    margin: theme.spacing(0, 0.2),
  },
  updateButton: {},
  intputButtonContainer: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
  },
  conditions: {
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
}));

const Promotions = (props) => {
  const classes = useStyles();
  const { agencies, getOrUpdateSurTaxes, role } = props;
  const comissions = [
    {
      months: 3,
      text: '3 meses',
      cards: '3.95%',
      imageCards: () => getAllImagaCards(),
    },
    {
      months: 6,
      text: '6 meses',
      cards: '6.95%',
      imageCards: () => getAllImagaCards(),
    },
    {
      months: 9,
      text: '9 meses',
      cards: '8.95%',
      imageCards: () => getAllImagaCards(),
    },
    {
      months: 12,
      text: '12 meses',
      cards: '11.95%',
      imageCards: () => getAllImagaCards(),
    },
  ];
  const [checked, setChecked] = React.useState({
    amex: [
      {
        id: 0,
        3: 'false',
        6: 'false',
        9: 'false',
        12: 'false',
        18: 'false',
        typeOfPromotion: 'service',
      },
      {
        id: 0,
        3: 'false',
        6: 'false',
        9: 'false',
        12: 'false',
        18: 'false',
        typeOfPromotion: 'parts',
      },
      {
        id: 0,
        3: 'false',
        6: 'false',
        9: 'false',
        12: 'false',
        18: 'false',
        typeOfPromotion: 'sales',
      },
      {
        id: 0,
        3: 'false',
        6: 'false',
        9: 'false',
        12: 'false',
        18: 'false',
        typeOfPromotion: 'fi',
      },
      {
        id: 0,
        3: 'false',
        6: 'false',
        9: 'false',
        12: 'false',
        18: 'false',
        typeOfPromotion: 'preowned',
      },
      {
        id: 0,
        3: 'false',
        6: 'false',
        9: 'false',
        12: 'false',
        18: 'false',
        typeOfPromotion: 'insurance',
      },
      {
        id: 0,
        3: 'false',
        6: 'false',
        9: 'false',
        12: 'false',
        18: 'false',
        typeOfPromotion: 'h&p',
      },
      {
        id: 0,
        3: 'false',
        6: 'false',
        9: 'false',
        12: 'false',
        18: 'false',
        typeOfPromotion: 'plates',
      },
      {
        id: 0,
        3: 'false',
        6: 'false',
        9: 'false',
        12: 'false',
        18: 'false',
        typeOfPromotion: 'accessories',
      },
      {
        id: 0,
        3: 'false',
        6: 'false',
        9: 'false',
        12: 'false',
        18: 'false',
        typeOfPromotion: 'reservation',
      },
      {
        id: 0,
        3: 'false',
        6: 'false',
        9: 'false',
        12: 'false',
        18: 'false',
        typeOfPromotion: 'deducible',
      },
    ],
    visa: [
      {
        id: 0,
        3: 'false',
        6: 'false',
        9: 'false',
        12: 'false',
        18: 'false',
        typeOfPromotion: 'service',
      },
      {
        id: 0,
        3: 'false',
        6: 'false',
        9: 'false',
        12: 'false',
        18: 'false',
        typeOfPromotion: 'parts',
      },
      {
        id: 0,
        3: 'false',
        6: 'false',
        9: 'false',
        12: 'false',
        18: 'false',
        typeOfPromotion: 'sales',
      },
      {
        id: 0,
        3: 'false',
        6: 'false',
        9: 'false',
        12: 'false',
        18: 'false',
        typeOfPromotion: 'fi',
      },
      {
        id: 0,
        3: 'false',
        6: 'false',
        9: 'false',
        12: 'false',
        18: 'false',
        typeOfPromotion: 'preowned',
      },
      {
        id: 0,
        3: 'false',
        6: 'false',
        9: 'false',
        12: 'false',
        18: 'false',
        typeOfPromotion: 'insurance',
      },
      {
        id: 0,
        3: 'false',
        6: 'false',
        9: 'false',
        12: 'false',
        18: 'false',
        typeOfPromotion: 'h&p',
      },
      {
        id: 0,
        3: 'false',
        6: 'false',
        9: 'false',
        12: 'false',
        18: 'false',
        typeOfPromotion: 'plates',
      },
      {
        id: 0,
        3: 'false',
        6: 'false',
        9: 'false',
        12: 'false',
        18: 'false',
        typeOfPromotion: 'accessories',
      },
      {
        id: 0,
        3: 'false',
        6: 'false',
        9: 'false',
        12: 'false',
        18: 'false',
        typeOfPromotion: 'reservation',
      },
      {
        id: 0,
        3: 'false',
        6: 'false',
        9: 'false',
        12: 'false',
        18: 'false',
        typeOfPromotion: 'deducible',
      },
    ],
    temp: [
      {
        id: 0,
        3: 'false',
        6: 'false',
        9: 'false',
        12: 'false',
        18: 'false',
        typeOfPromotion: 'service',
      },
      {
        id: 0,
        3: 'false',
        6: 'false',
        9: 'false',
        12: 'false',
        18: 'false',
        typeOfPromotion: 'parts',
      },
      {
        id: 0,
        3: 'false',
        6: 'false',
        9: 'false',
        12: 'false',
        18: 'false',
        typeOfPromotion: 'sales',
      },
      {
        id: 0,
        3: 'false',
        6: 'false',
        9: 'false',
        12: 'false',
        18: 'false',
        typeOfPromotion: 'fi',
      },
      {
        id: 0,
        3: 'false',
        6: 'false',
        9: 'false',
        12: 'false',
        18: 'false',
        typeOfPromotion: 'preowned',
      },
      {
        id: 0,
        3: 'false',
        6: 'false',
        9: 'false',
        12: 'false',
        18: 'false',
        typeOfPromotion: 'insurance',
      },
      {
        id: 0,
        3: 'false',
        6: 'false',
        9: 'false',
        12: 'false',
        18: 'false',
        typeOfPromotion: 'h&p',
      },
      {
        id: 0,
        3: 'false',
        6: 'false',
        9: 'false',
        12: 'false',
        18: 'false',
        typeOfPromotion: 'plates',
      },
      {
        id: 0,
        3: 'false',
        6: 'false',
        9: 'false',
        12: 'false',
        18: 'false',
        typeOfPromotion: 'accessories',
      },
      {
        id: 0,
        3: 'false',
        6: 'false',
        9: 'false',
        12: 'false',
        18: 'false',
        typeOfPromotion: 'reservation',
      },
      {
        id: 0,
        3: 'false',
        6: 'false',
        9: 'false',
        12: 'false',
        18: 'false',
        typeOfPromotion: 'deducible',
      },
    ],
  });
  const [selectedMSI, setSelectedMSI] = React.useState({});
  const [
    showConfirmMSIModal,
    setShowConfirmMSIModal,
  ] = React.useState(false);
  const [promotion, setPromotions] = React.useState(null);

  React.useEffect(() => {
    const tempChecked = { ...checked };
    let tempObject;

    if (agencies.agencyDetails.surTax.length === 0) {
      tempObject = tempChecked.temp;
    } else {
      tempObject = agencies.agencyDetails.surTax;
    }

    if (agencies.agencyDetails && agencies.agencyDetails.surTax) {
      tempChecked.visa = tempObject;
      tempChecked.temp = tempObject;
    }

    if (agencies.agencyDetails && agencies.agencyDetails.AMEXSurTax)
      tempChecked.amex = tempObject;

    updateMinimalQuantity(tempChecked);
    setLogsPromotions(agencies.agencyDetails.logs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setLogsPromotions = (logs) => {
    const newLogs = logs.map((log) => {
      let { date, typeOfPromotion, user } = log.data;
      let type = log.type;
      let text;

      if (type === 'CREATEPROMOTION') {
        text =
          `Se han agregado las configuraciones de las promociones para ${typeOfPromotion} ` +
          `de manera automatica. El cambio se producio a las ${date} por el usuario ${user}.`;
      } else {
        text =
          `Se han actualizado las configuraciones de las promociones para ${typeOfPromotion}, ` +
          `el cambio se producio a las ${date} por el usuario ${user}.`;
      }

      return {
        date,
        text,
        type,
      };
    });
    setPromotions(newLogs);
  };

  const updateMinimalQuantity = (asliasChecked) => {
    const tempChecked = { ...asliasChecked };
    const tempAccessVisa = tempChecked.visa;
    let tempValues = [];
    let final = [];

    tempAccessVisa.forEach((value) => {
      comissions.forEach((comission) => {
        const valueMonths =
          value[comission.months] === null
            ? 'false'
            : value[comission.months];

        tempValues.push(valueMonths);
      });

      let obj = {
        ['3']: tempValues[0],
        ['6']: tempValues[1],
        ['9']: tempValues[2],
        ['12']: tempValues[3],
        ['18']: 'false',
        ['id']: value.id,
        ['agencyID']: value.agencyID,
        ['typeOfPromotion']: value.typeOfPromotion,
      };

      final.push(obj);
      tempValues = [];
    });

    tempChecked.visa = final;
    tempChecked.temp = final;
    tempChecked.amex = final;

    setChecked(tempChecked);
  };

  const handleToggle = (
    id = null,
    typeOfPromotion = null,
    value = null,
  ) => {
    const tempChecked = { ...checked };
    const tempObject = tempChecked.visa;
    const tempValue = value ? value : selectedMSI.value;
    const tempId = id ? id : selectedMSI.id;
    const tempPromotion = typeOfPromotion
      ? typeOfPromotion
      : selectedMSI.typeOfPromotion;
    const filtered = tempObject.filter(
      (result) =>
        result.id === tempId &&
        result.typeOfPromotion === tempPromotion,
    );

    const position = tempObject.findIndex(
      (x) => x.id === tempId && x.typeOfPromotion === tempPromotion,
    );
    const result = filtered[0];

    if (isNumeric(tempChecked.temp[position][tempValue])) {
      tempChecked.temp[position][tempValue] = 'false';
    } else {
      tempChecked.temp[position][tempValue] = '0';
    }

    if (result !== undefined) {
      tempChecked.temp[position]['id'] = result.id;
      tempChecked.temp[position]['typeOfPromotion'] =
        result.typeOfPromotion;
    }

    setChecked(tempChecked);
    setShowConfirmMSIModal(false);
  };

  const handleShowConfirmMSI = (value, id, typeOfPromotion) => {
    setSelectedMSI(value, id, typeOfPromotion);
    setShowConfirmMSIModal(true);
  };

  const handleSetMinimumQuanity = (
    target,
    months,
    id,
    typeOfPromotion,
  ) => {
    if (target.value) {
      const tempChecked = { ...checked };
      let position = tempChecked.temp.findIndex(
        (x) => x.id === id && x.typeOfPromotion === typeOfPromotion,
      );

      if (position === -1) position = 0;

      tempChecked.temp[position][months] = target.value;
      setChecked(tempChecked);
    }
  };

  const renderCard = (card) => {
    let src;
    switch (card) {
      case 'visa':
        src = visa;
        break;
      case 'mastercard':
        src = mastercard;
        break;
      case 'amex':
        src = amex;
        break;
      default:
        src = visa;
    }
    return (
      <img
        key={card}
        className={classes.imageBrands}
        src={src}
        width={25}
        height={25}
        alt={card}
      />
    );
  };

  const getAllImagaCards = () => {
    return [
      renderCard('visa'),
      renderCard('mastercard'),
      renderCard('amex'),
    ];
  };

  const renderMSIConfirmation = () => {
    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth="xs"
          open={showConfirmMSIModal}
          onClose={() => setShowConfirmMSIModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Absorber comisión
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Al activar esta opción la empresa absorberá la comisión
              de pago a {selectedMSI.value} meses de las tarjetas
              Visa/Mastercard/AMEX. Podrá desactivar esta opción en
              cualquier momento.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setShowConfirmMSIModal(false)}
              color="primary"
            >
              Cancelar
            </Button>
            <Button onClick={() => handleToggle()} color="primary">
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const handleUpdateComissions = (values) => {
    const { months, id, typeOfPromotion } = values;

    const agencyID = getNested(
      undefined,
      agencies,
      'agencyDetails',
      'id',
    );

    const tempObject = { ...checked };

    const filtered = tempObject.temp.filter(
      (result) =>
        result.id === id &&
        result.typeOfPromotion === typeOfPromotion,
    );

    const position = tempObject.temp.findIndex(
      (x) => x.id === id && x.typeOfPromotion === typeOfPromotion,
    );

    const cleanTempChecked = filtered[0][months] || '0';

    checked.temp[position][months] = cleanTempChecked;

    setChecked({
      ...checked,
      visa: [...checked.temp],
      amex: [...checked.temp],
      temp: [...checked.temp],
    });

    const tempChecked = {
      visa: {
        agencyID,
        [months]: cleanTempChecked,
        id,
        months,
      },
      amex: {
        agencyID,
        [months]: cleanTempChecked,
        id,
        months,
      },
    };

    getOrUpdateSurTaxes(tempChecked);
  };

  const renderTypeOfService = (promotion) => {
    const tempChecked = { ...checked };
    let detailPromotion = agencies.agencyDetails.surTax;
    let position;

    if (tempChecked.temp.length !== undefined) {
      // detailPromotion = tempChecked.temp;
      position = detailPromotion.findIndex(
        (x) => x.typeOfPromotion === promotion.value,
      );
    }

    if (position === -1) {
      position = 0;
    }

    let filtered = detailPromotion.filter(
      (result) => result.typeOfPromotion === promotion.value,
    );

    if (filtered.length === 0) {
      filtered = [
        {
          ['id']: 0,
          ['3']: null,
          ['6']: null,
          ['9']: null,
          ['12']: null,
          ['18']: null,
          ['typeOfPromotion']: promotion.value,
        },
      ];
    }

    const details = {
      id: filtered[0]['id'],
      3: filtered[0]['3'] === null ? 'false' : filtered[0]['3'],
      6: filtered[0]['6'] === null ? 'false' : filtered[0]['6'],
      9: filtered[0]['9'] === null ? 'false' : filtered[0]['9'],
      12: filtered[0]['12'] === null ? 'false' : filtered[0]['12'],
      18: filtered[0]['18'] === null ? 'false' : filtered[0]['18'],
      typeOfPromotion: filtered[0]['typeOfPromotion'],
      position: position === undefined ? 0 : position,
    };

    return (
      <Grid item xs={12} md={12} className={classes.tables}>
        <h6>Promociones para {promotion.label}</h6>
        {renderCommissionsByCards(details)}
      </Grid>
    );
  };

  const renderCommissionsByCards = (details) => {
    return (
      <Fragment>
        <Grid item xs={12} md={12} className={classes.tables}>
          {comissions.map((comission) => (
            <Card
              key={`comission-${comission.months}`}
              className={classes.card}
            >
              <CardContent className={classes.CardContent}>
                <div className={classes.cardInnerContent}>
                  <ListItem className={classes.items}>
                    <ListItemText
                      className={classes.flex}
                      primary={comission.text}
                      secondary={comission.cards}
                    />
                    <FormControlLabel
                      className={clsx(
                        classes.flex,
                        classes.flexToogle,
                      )}
                      labelPlacement="top"
                      control={
                        <Switch
                          edge="end"
                          onChange={() =>
                            isNumeric(
                              checked.temp[details.position][
                                comission.months
                              ],
                            )
                              ? handleToggle(
                                  details.id,
                                  details.typeOfPromotion,
                                  comission.months,
                                )
                              : handleShowConfirmMSI({
                                  value: comission.months,
                                  id: details.id,
                                  typeOfPromotion:
                                    details.typeOfPromotion,
                                })
                          }
                          checked={isNumeric(
                            checked.temp[details.position][
                              comission.months
                            ],
                          )}
                        />
                      }
                    />
                    <div
                      className={clsx(
                        classes.flex,
                        classes.intputButtonContainer,
                      )}
                    >
                      <TextField
                        label="Cantidad de pago mínima"
                        className={classes.textField}
                        value={
                          isNumeric(
                            checked.temp[details.position][
                              comission.months
                            ],
                          )
                            ? checked.temp[details.position][
                                comission.months
                              ]
                            : ''
                        }
                        onChange={({ target }) =>
                          handleSetMinimumQuanity(
                            target,
                            comission.months,
                            details.id,
                            details.typeOfPromotion,
                          )
                        }
                        margin="normal"
                        variant="outlined"
                        disabled={
                          !isNumeric(
                            checked.temp[details.position][
                              comission.months
                            ],
                          )
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              $
                            </InputAdornment>
                          ),
                          inputComponent: NumberFormatCustom,
                          inputProps: { quantity: true },
                        }}
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.updateButton}
                        disabled={
                          checked.temp[details.position][
                            comission.months
                          ] !==
                          checked.visa[details.position][
                            comission.months
                          ]
                        }
                        onClick={() =>
                          handleUpdateComissions({
                            months: comission.months,
                            id: details.id,
                            typeOfPromotion: details.typeOfPromotion,
                          })
                        }
                      >
                        Actualizar
                      </Button>
                    </div>
                  </ListItem>
                </div>
              </CardContent>
            </Card>
          ))}
        </Grid>
      </Fragment>
    );
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={9}>
          <Typography variant="h5" className={classes.commission}>
            Promociones meses sin intereses
          </Typography>
        </Grid>
        {typeOfPromotion.map((promotion) => {
            if(promotion.value === 'reservation'){
                  if(role.includes("WTF") || agencies.agencyDetails.nickname === 'WORLDTRAVELANDFUN' ){
                    return (renderTypeOfService(promotion))
                  }
            }else{
              return (renderTypeOfService(promotion))
            }
        }
        )}
        <Grid item xs={12} md={12}>
          <div className={classes.conditions}>
            *La promoción de 18 meses solamente se aplica a tarjetas
            VISA/MASTERCARD
          </div>
          <FormGroup className={classes.leyendsContainer}>
            <FormControlLabel
              control={<Switch checked={true} />}
              label="La empresa pagará la comisión fija más la comisión a
              meses de la opción seleccionada. El usuario recibe
              beneficio de meses sin intereses."
            />
            <FormControlLabel
              control={<Switch checked={false} />}
              label="La comisión a meses la paga el cliente final. Ésta
              comisión se calcula e incrementa el monto total a pagar
              de la orden de servicio o orden de pago según sea el
              caso de manera que no afecte el ingreso de la empresa."
            />
          </FormGroup>
          {/* <Grid item>
            <RecentActivities logs={promotion} />
          </Grid> */}
        </Grid>
      </Grid>
      {renderMSIConfirmation()}
    </>
  );
};

const mapStateToProps = (state) => ({
  agencies: state.agencies,
  role: makeSelectUserRoleFromState(state),
});

const mapDispatchToProps = {
  closeModals,
  getOrUpdateSurTaxes,
};

export default withRouter(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(Promotions),
  ),
);
