import { createSelector } from 'reselect';
import { initialState } from './initialState';

export const selectDataCalc = (state) =>
  state.calculatorData || initialState;

export const makeSelectcalculatorDataIsLoading = createSelector(
  selectDataCalc,
  (calculatorState) => calculatorState.isLoading,
);

export const makeSelectcalculatorFromState = createSelector(
  selectDataCalc,
  (calculatorState) => calculatorState.fromdb,
);
