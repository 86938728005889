import { call, put } from 'redux-saga/effects';
import {
  getKarloPromotionsSuccess,
  getKarloPromotionsFail,
  deletePromotionSuccess,
  deletePromotionFail,
  createPromotionSuccess,
  createPromotionFail,
  updatePromotionSuccess,
  updatePromotionFail,
  getPromotionDetailSuccess,
  getPromotionDetailFail,
  uploadPromoThumbnailSuccess,
  uploadPromoThumbnailFail,
  uploadPromoDescriptionSuccess,
  uploadPromoDescriptionFail,
  handleClosePromoModalsSuccess,
} from 'app/features/karloPromotions/karloPromotionsSlice.js';
import { errors } from './errors.js';
import { requestHandler } from '../../services/requestHandler';
import moment from 'moment';

export function* getKarloPromotionsSaga(action) {
  try {
    let page = 0;
    let pageSize = 20;
    let agencyId = '';
    let filtered;
    if (action) {
      page = action.payload.page;
      pageSize = action.payload.pageSize;
      filtered = action.payload.filtered;
    }
    const _renderPage = pageSize * page;
    const data = {
      offset: _renderPage,
      limit: pageSize,
      field: 'id',
      ordering: 'ASC',
    };
    const karloPromotions = yield call(requestHandler, {
      method: 'POST',
      path: '/karlo-app/getPromotions',
      data,
    });
    karloPromotions.pages = yield call(
      Math.ceil,
      karloPromotions.promotions.length / pageSize,
    );
    console.log('success in karloPromotions saga ', karloPromotions);
    yield put(getKarloPromotionsSuccess({ karloPromotions }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba encontrar la información.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(getKarloPromotionsFail({ message }));
    console.log('error in karloPromotions saga', e);
  }
}

export function* deletePromotionSaga(action) {
  console.log(action.payload);
  try {
    const { promoId } = action.payload;
    const data = {
      id: promoId,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/karlo-app/deletePromotion',
      data,
    });
    const message = {
      title: 'Eliminado',
      desc: `La promoción se eliminó correctamente`,
    };
    yield put(deletePromotionSuccess({ message }));
    yield call(getKarloPromotionsSaga);
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Error al tratar de eliminar la promoción, por favor intenta más tarde`,
    };
    yield put(deletePromotionFail({ message }));
    console.log('error', e);
  }
}

export function* createPromotionSaga(action) {
  const {
    title,
    subtitle,
    description,
    videoUrl,
    vigencyDate,
    expirationDate,
    descriptionImg,
    thumbnailImg,
    createdByUserId,
  } = action.payload;
  try {
    const data = {
      title: title,
      subtitle: subtitle,
      description: description,
      startDate: vigencyDate,
      expirationDate: expirationDate,
      videoUrl: videoUrl,
      createdByUserId: createdByUserId,
    };
    const promotion = yield call(requestHandler, {
      method: 'POST',
      path: '/karlo-app/createPromotion',
      data,
    });
    const promotionId = promotion.promotionId;
    if (promotion.promotionId) {
      try {
        const formData = new FormData();
        formData.append(`file0`, thumbnailImg);
        formData.append('promotionId', promotionId);
        const promotionThumbnail = yield call(requestHandler, {
          method: 'POST',
          path: '/karlo-app/uploadPromotionThumbnailImage',
          contenType: 'multipart/form-data',
          data: formData,
        });
        console.log('promotionThumbnail ', promotionThumbnail);
        if (promotionThumbnail.code == '200') {
          try {
            const formDataD = new FormData();
            formDataD.append(`file1`, descriptionImg);
            formDataD.append('promotionId', promotionId);
            const promotionDescription = yield call(requestHandler, {
              method: 'POST',
              path: '/karlo-app/uploadPromotionDescriptionImage',
              contenType: 'multipart/form-data',
              data: formDataD,
            });
            console.log(
              'promotionDescription ',
              promotionDescription,
            );
            const redirect = '/advices';
            const message = {
              title: '¡Exito!',
              desc: `La promoción ha sido creado con exito.`,
            };
            yield put(
              createPromotionSuccess({
                message,
                redirect,
              }),
            );
          } catch (e) {
            console.log('e ', e);
          }
        }
      } catch (e) {
        console.log('e ', e);
      }
    }
  } catch (e) {
    let message = '';
    if (e.code in errors)
      message = {
        title: 'Error',
        desc: errors[e.code],
      };
    else
      message = {
        title: 'Error',
        desc: `Ocurrió un error cuando se intentaba crear la promoción.
        Intente de nuevo, si el error persiste intente más tarde.`,
      };
    yield put(createPromotionFail({ message }));
    console.log('error in create tip saga', e);
  }
}

export function* updatePromotionDataSaga(action) {
  const {
    title,
    subtitle,
    description,
    videoUrl,
    startDate,
    expirationDate,
    id,
    createdByUserId,
  } = action.payload;
  try {
    const data = {
      id: id,
      title: title,
      subtitle: subtitle,
      description: description,
      startDate: startDate,
      expirationDate: expirationDate,
      videoUrl: videoUrl.length != 0 ? videoUrl : null,
      createdByUserId: createdByUserId,
    };
    const url = yield call(requestHandler, {
      method: 'POST',
      path: '/karlo-app/updatePromotion',
      data: data,
    });
    const message = {
      title: 'Exito',
      desc: `El consejo se ha editado exitosamente.`,
    };
    yield put(updatePromotionSuccess({ message }));
    yield call(getPromotionDetailSaga, {
      payload: { id: action.payload.id },
    });
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se trataba de actualizar.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(updatePromotionFail({ message }));
    console.log(e);
  }
}

export function* getPromotionDetailSaga(action) {
  try {
    const data = {
      id: action.payload.id,
    };
    console.log('data promo, ', data);
    const details = yield call(requestHandler, {
      method: 'POST',
      path: '/karlo-app/getPromotionById',
      data,
    });
    console.log('details promo, ', details);
    yield put(getPromotionDetailSuccess({ details }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba encontrar la información del consejo.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(getPromotionDetailFail({ message }));
  }
}

export function* uploadThumbnailPromoSaga(action) {
  const { id, file } = action.payload;
  console.log(id, ' image ', file);
  try {
    const formData = new FormData();
    formData.append(`file0`, file);
    formData.append(`promotionId`, id);
    const url = yield call(requestHandler, {
      method: 'POST',
      path: '/karlo-app/uploadPromotionThumbnailImage',
      contenType: 'multipart/form-data',
      data: formData,
    });
    const message = {
      title: 'Exito',
      desc: `Se ha subido con exito la imagen del thumbnail.`,
    };
    yield put(uploadPromoThumbnailSuccess({ message }));
    yield call(getPromotionDetailSaga, {
      payload: { id: action.payload.id },
    });
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se trataba subir el thumbnail.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(uploadPromoThumbnailFail({ message }));
    // console.log('error in upload logo saga', e);
  }
}

export function* uploadDescriptionPromoSaga(action) {
  const { id, file } = action.payload;
  console.log(id, ' image ', file);
  try {
    const formData = new FormData();
    formData.append(`file0`, file);
    formData.append(`promotionId`, id);
    const url = yield call(requestHandler, {
      method: 'POST',
      path: '/karlo-app/uploadPromotionDescriptionImage',
      contenType: 'multipart/form-data',
      data: formData,
    });
    const message = {
      title: 'Exito',
      desc: `Se ha subido con exito la imagen de la descripción.`,
    };
    console.log('response in upload description saga', url);
    yield put(uploadPromoDescriptionSuccess({ message }));
    yield call(getPromotionDetailSaga, {
      payload: { id: action.payload.id },
    });
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se trataba subir la imagen de la descripción.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(uploadPromoDescriptionFail({ message }));
    console.log('error in upload logo saga', e);
  }
}

export function* closeModalsPromoSaga(action) {
  try {
    yield put(handleClosePromoModalsSuccess({}));
  } catch (error) {
    console.log('error in close modals correctly in saga', error);
  }
}
