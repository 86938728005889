import 'react-table/react-table.css';
import 'moment/locale/es';

/* eslint-disable react/display-name */
import {
  Button,
  Badge as MaterialBadge,
  Paper,
  TextField,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import { getKarloVehicles } from 'app/features/karloVehicles/karloVehiclesSlice';

import { Link } from 'react-router-dom';
import React from 'react';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { injectIntl } from 'react-intl';
import { makeSelectKarloVehicles } from 'app/features/karloVehicles/selectors';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(0),
  },
  notifications: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  lastUpdated: {
    marginLeft: theme.spacing(2),
    color: '#434349',
  },
  datePickerTextfield: {
    padding: '5px 5px 5px !important',
    height: '20px',
  },
}));

function KarloVehiclesTable(props) {
  const classes = useStyles();
  const { role, getKarloVehicles, karloVehicles } = props;
  const [karloVehiclesData, setKarloVehiclesData] = React.useState(
    [],
  );
  const reactTable = React.useRef(null);

  const handleFetchData = debounce(
    (state, instance) => {
      const { page, pageSize, sorted, filtered } = state;
      getKarloVehicles({ page, pageSize, sorted, filtered });
    },
    1000,
    { leading: false, trailing: true },
  );

  React.useEffect(() => {
    // console.log('karloVehiclesFROMDB ', karloVehicles);
    if (Array.isArray(karloVehicles.fromdb)) {
      const cleanData = karloVehicles.fromdb.map((user) => {
        return {
          id: user.id,
          plates: user.plates,
          brand: user.brand,
          model: user.model,
          year: user.year,
          version: user.version,
          vin: user.vin ? user.vin : '-',
          state: user.state ? user.state : '-',
          client: user.user.length != 0 ? user.user.name : '-',
          agency: user.agency.length != 0 ? user.agency.name : '-',
        };
      });
      setKarloVehiclesData(cleanData);
    }
  }, [karloVehicles]);

  const columns = [
    {
      Header: () => <strong>ID</strong>,
      accessor: 'id',
      Cell: (row) => (
        <Link to={`/karlovehicle-detail/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Placas</strong>,
      accessor: 'plates',
      Cell: (row) => (
        <Link to={`/karlovehicle-detail/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Marca</strong>,
      accessor: 'brand',
      Cell: (row) => (
        <Link to={`/karlovehicle-detail/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Modelo</strong>,
      accessor: 'model',
      Cell: (row) => (
        <Link to={`/karlovehicle-detail/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Año</strong>,
      accessor: 'year',
      Cell: (row) => (
        <Link to={`/karlovehicle-detail/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Versión</strong>,
      accessor: 'version',
      Cell: (row) => (
        <Link to={`/karlovehicle-detail/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>VIN</strong>,
      accessor: 'vin',
      Cell: (row) => (
        <Link to={`/karlovehicle-detail/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Estado</strong>,
      accessor: 'state',
      Cell: (row) => (
        <Link to={`/karlovehicle-detail/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Cliente</strong>,
      accessor: 'client',
      Cell: (row) => (
        <Link to={`/karlovehicle-detail/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Agencia</strong>,
      accessor: 'agency',
      Cell: (row) => (
        <Link to={`/karlovehicle-detail/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
  ];

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <ReactTable
          ref={reactTable}
          NoDataComponent={() => (
            <div className="rt-noData">Sin resultados</div>
          )}
          manual
          onFetchData={handleFetchData}
          data={karloVehiclesData}
          pages={karloVehicles.tablePages}
          columns={columns}
          // filterable
          className="-striped -highlight"
          previousText="Anterior"
          nextText="Siguiente"
          loading={karloVehicles.isLoading}
          loadingText="Cargando datos..."
        />
      </Paper>
    </div>
  );
}

const mapStateToProps = (state) => ({
  karloVehicles: makeSelectKarloVehicles(state),
});

const mapDispatchToProps = {
  getKarloVehicles,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(KarloVehiclesTable),
);
