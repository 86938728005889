export const handleUrlLanding = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'development':
      return 'http://localhost:3001/';
    case 'staging':
      return 'http://landing-staging.karlo.io/';
    case 'production':
      return 'https://pagos.karlo.io/';
    default:
      return 'http://localhost:3001/';
  }
};
