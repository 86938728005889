import '../../../../../_metronic/_assets/sass/pages/login/login-1.scss';

import { Link, Redirect, Route, Switch } from 'react-router-dom';

import ConfirmEmail from '../../components/ConfirmEmail';
import ForgotPassword from '../../components/ForgotPassword';
import Login from '../../components/Login';
import NewPassword from '../../components/NewPassword';
import React from 'react';
import { toAbsoluteUrl } from '../../../../../_metronic';

export default function AuthPage(props) {
  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div
          id="kt_login"
          className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
        >
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <div
              className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
              style={{
                background:
                  'linear-gradient(to bottom, #000926 0%, #0f243f 100%)',
              }}
            >
              <div className="kt-grid__item">
                <Link to="/" className="kt-login__logo">
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl(
                      '/media/logos/Karlo_Logos_White.png',
                    )}
                    style={{ width: 100, height: 100 }}
                  />
                </Link>
              </div>
              <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
                <div className="kt-grid__item kt-grid__item--middle">
                  <h3 className="kt-login__title">Karlo Admin</h3>
                  {/* <h4 className="kt-login__subtitle">
                    The ultimate Bootstrap & Angular 6 admin theme framework for
                    next generation web apps.
                  </h4> */}
                </div>
              </div>
              <div className="kt-grid__item">
                <div className="kt-login__info">
                  <div className="kt-login__copyright">
                    Copyright &copy; {new Date().getFullYear()} Karlo
                    Solutions S.A. de C.V.
                  </div>
                </div>
              </div>
            </div>

            <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
              <Switch>
                <Redirect
                  from="/auth"
                  exact={true}
                  to="/auth/login"
                />

                <Route path="/auth/login" component={Login} />
                <Route
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <Route
                  path="/auth/reset-password"
                  component={NewPassword}
                />
                <Route
                  path="/auth/confirm-email"
                  component={ConfirmEmail}
                />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
