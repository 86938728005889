import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
} from '@material-ui/core';
import {
  IVA,
  defaultComissions,
  monthsByComissions,
} from 'app/config/monthsTaxes';
import {
  cloneObjectLevels,
  formatNumber,
  getNested,
  isNumeric,
  roundValue,
} from 'app/utils';

import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

const BASE_EXTRA_WITH_IVA = {
  billPocket: 0,
  netpay: 2.9,
};

const useStyles = makeStyles((theme) => ({
  container: {
    flexWrap: 'wrap',
  },
  paper: {
    padding: theme.spacing(7, 10, 1, 10),
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fromToInputs: {
    width: '50%',
    margin: theme.spacing(0, 1, 1, 0),
  },
  card: {
    width: '100%',
    marginBottom: '10px',
  },
  hourInputsContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  CardContent: {
    padding: '10px !important',
  },
}));

export const PromotionMonthsCard = (props) => {
  const classes = useStyles();
  const {
    amount,
    monthsChecked,
    setMonthsChecked,
    cardComission,
    amexCardComission,
    ODS,
    payments,
    serviceOrderDetails,
  } = props;

  const [promotionsByOrderType, setPromotionsByOrderType] = useState(payments?.promotionsByOrderType || serviceOrderDetails?.promotionsByOrderType || []);

  const [months, setMonths] = useState({
    visa: {
      id: 0,
      3: false,
      6: false,
      9: false,
      12: false,
      18: false,
      typeOfPromotion: promotionsByOrderType?.length > 0 && promotionsByOrderType[0]['typeOrdersByAgency']?.typeOrder,
    },
    amex: {
      id: 0,
      3: false,
      6: false,
      9: false,
      12: false,
      18: false,
      typeOfPromotion: promotionsByOrderType?.length > 0 && promotionsByOrderType[0]['typeOrdersByAgency']?.typeOrder,
    },
  });
  // const [temp, setTemp] = useState(monthsChecked);

  useEffect(() => {
    const tempChecked = { ...months };
    if (payments) {
      if (payments?.detailPromotionByAgency?.length > 0) {
        payments?.detailPromotionByAgency?.forEach((promotion) => {
          promotion.isThree
            ? (tempChecked.visa[3] = true)
            : (tempChecked.visa[3] = false);
          promotion.isSix
            ? (tempChecked.visa[6] = true)
            : (tempChecked.visa[6] = false);
          promotion.isNine
            ? (tempChecked.visa[9] = true)
            : (tempChecked.visa[9] = false);
          promotion.isTwelve
            ? (tempChecked.visa[12] = true)
            : (tempChecked.visa[12] = false);
          promotion.isEighteen
            ? (tempChecked.visa[18] = true)
            : (tempChecked.visa[18] = false);
        });
      }
    }
    if (serviceOrderDetails) {
      if (serviceOrderDetails?.detailPromotionByAgency?.length > 0) {
        serviceOrderDetails?.detailPromotionByAgency?.forEach((promotion) => {
          promotion.isThree
            ? (tempChecked.visa[3] = true)
            : (tempChecked.visa[3] = false);
          promotion.isSix
            ? (tempChecked.visa[6] = true)
            : (tempChecked.visa[6] = false);
          promotion.isNine
            ? (tempChecked.visa[9] = true)
            : (tempChecked.visa[9] = false);
          promotion.isTwelve
            ? (tempChecked.visa[12] = true)
            : (tempChecked.visa[12] = false);
          promotion.isEighteen
            ? (tempChecked.visa[18] = true)
            : (tempChecked.visa[18] = false);
        });
      }
    }
    setMonths(tempChecked);
  }, []);

  const getBaseComission = (cardType) => {
    switch (cardType) {
      case 2:
        return getNested(
          defaultComissions['amex'],
          amexCardComission,
        );
      default:
        return getNested(
          defaultComissions['visa/mastercard'],
          cardComission,
        );
    }
  };

  const getSurchargeByCardType = (placement, cardType) => {
    switch (cardType) {
      case 2:
        return monthsByComissions[placement].amex;
      default:
        return monthsByComissions[placement].normal;
    }
  };

  const handleCheckMonth = (month, value) => {
    const tempChecked = cloneObjectLevels(months);
    if (ODS) {
      if (value) {
        tempChecked.visa[month] = '1';
        tempChecked.amex[month] = '1';
      } else {
        tempChecked.visa[month] = 'false';
        tempChecked.amex[month] = 'false';
      }
    } else {
      if (value) {
        tempChecked.visa[month] = '1';
        tempChecked.amex[month] = '1';
      } else {
        tempChecked.visa[month] = 'false';
        tempChecked.amex[month] = 'false';
      }
    }
    setMonthsChecked(tempChecked);
  };

  const changeValue = (month, value) => {
    const monthChecked = cloneObjectLevels(months);
    if (value) {
      monthChecked.visa[month] = value;
      monthChecked.amex[month] = value;
    } else {
      monthChecked.visa[month] = false;
      monthChecked.amex[month] = false;
    }
    setMonths(monthChecked);
  };

  const calculateNumbers = (placement, cardType) => {
    let baseComission = 0,
      baseComissionWithOutIVA = 0,
      baseComissionWithIVA = 0,
      surchargeByCardType = 0,
      completeSurcharge = 0,
      toDepositToAgency = 0,
      tempIva = 0;
    baseComission = getBaseComission(cardType);
    baseComissionWithOutIVA = baseComission * parseFloat(amount);
    baseComissionWithIVA = baseComission * IVA;
    tempIva += baseComissionWithOutIVA * 0.16;
    surchargeByCardType = getSurchargeByCardType(placement, cardType);
    tempIva += surchargeByCardType * parseFloat(amount) * 0.16;
    completeSurcharge = surchargeByCardType * parseFloat(amount);
    const baseComissionCalculated =
      amount * baseComissionWithIVA +
      BASE_EXTRA_WITH_IVA['billPocket'];
    toDepositToAgency =
      amount -
      baseComissionCalculated -
      amount * (getSurchargeByCardType(placement, cardType) * IVA);
    const totalComission =
      tempIva + completeSurcharge + baseComissionWithOutIVA;
    return { totalComission, toDeposit: toDepositToAgency };
  };

  const handleCheckAmountWithMount = (key) => {
    if (key === "3") {
      return roundValue(amount / key) > 100.0 &&  promotionsByOrderType?.length > 0 ;
    }else if(key === "6"){
      return roundValue(amount / key) > 100.0 &&  promotionsByOrderType?.length > 0;
    }else if(key === "9"){
      return roundValue(amount / key) > 100.0 &&  promotionsByOrderType?.length > 0 ;
    }else if(key === "12"){
      return roundValue(amount / key) > 100.0 &&  promotionsByOrderType?.length > 0 ;
    } else if(key === "18"){
      return roundValue(amount / key) > 100.0 &&  promotionsByOrderType?.length > 0 ;
    }
  };

  return Object.keys(monthsByComissions)
    .filter(handleCheckAmountWithMount)
    .map((key) => {
      const visaMaster = calculateNumbers(key, 1);
      const amex = calculateNumbers(key, 2);
      return (
        <Card key={`day-${key}`} className={classes.card}>
          <CardContent className={classes.CardContent}>
            <Grid container>
              <Grid item xs={3} className={classes.flex}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        // ODS
                        //   ? isNumeric(monthsChecked?.visa[0][key])
                        //   : isNumeric(monthsChecked?.visa[key])
                        months.visa[key]
                      }
                      onChange={({ target: { checked } }) => {
                        handleCheckMonth(key, checked);
                        changeValue(key, checked);
                      }}
                    />
                  }
                  label={`${key} meses`}
                />
              </Grid>
              <Grid item xs={2} className={classes.flex}>
                <div
                  className={clsx(
                    classes.flex,
                    classes.hourInputsContainer,
                  )}
                >
                  Comisión Visa/MC: <br />
                  <b>{formatNumber(visaMaster.totalComission)}</b>
                </div>
              </Grid>
              <Grid item xs={2} className={classes.flex}>
                <div
                  className={clsx(
                    classes.flex,
                    classes.hourInputsContainer,
                  )}
                >
                  A depositar: <br />
                  <b>{formatNumber(visaMaster.toDeposit)}</b>
                </div>
              </Grid>
              <Grid item xs={2} className={classes.flex}>
                <div
                  className={clsx(
                    classes.flex,
                    classes.hourInputsContainer,
                  )}
                >
                  Comisión AMEX: <br />
                  <b>{formatNumber(amex.totalComission)}</b>
                </div>
              </Grid>
              <Grid item xs={2} className={classes.flex}>
                <div
                  className={clsx(
                    classes.flex,
                    classes.hourInputsContainer,
                  )}
                >
                  A depositar: <br />
                  <b>{formatNumber(amex.toDeposit)}</b>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      );
    });
};
