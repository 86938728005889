import { createSelector } from 'reselect';
import { initialState } from './initialState';

export const selectReservations = (state) =>
  state.reservations || initialState;

export const makeSelectReservations = createSelector(
  selectReservations,
  (reservationsState) => reservationsState.fromdb,
);

export const makeSelectReservationDeatil = createSelector(
  selectReservations,
  (reservationsState) => reservationsState.reservationDetail,
);

export const makeSelectRedirect = createSelector(
  selectReservations,
  (reservationsState) => reservationsState.redirect,
);

export const makeSelectPages = createSelector(
  selectReservations,
  (reservationsState) => reservationsState.pages,
);
