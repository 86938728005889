import api from '../../config/api';
/* eslint-disable new-cap */
import axios from 'axios';

export const requestHandler = (axiosOptions = {}) => {
  let url;
  const API_URL = api();
  const {
    baseUrl,
    method = 'GET',
    contenType,
    headers = {
      'Content-Type': contenType || 'application/json',
      Accept: 'application/json',
    },
    data,
  } = axiosOptions;

  let { path } = axiosOptions;

  if (baseUrl && !path) {
    url = baseUrl;
  } else if (baseUrl && path) {
    url = baseUrl + path;
    headers.type = 'calculator';
  } else if (path) {
    url = API_URL + path;
  } else {
    url = API_URL;
  }
  return axios({
    baseURL: url,
    headers,
    method,
    data,
  })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return Promise.reject(err.response.data);
    });
};
