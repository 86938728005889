import { createSelector } from 'reselect';
import { getNested } from '../../utils';
import { initialState } from './initialState';

export const selectAgencies = (state) =>
  state.agencies || initialState;

export const makeSelectAgencies = createSelector(
  selectAgencies,
  (agenciesState) => agenciesState,
);

export const makeSelectAgenciesFromdb = createSelector(
  selectAgencies,
  (agenciesState) => agenciesState.fromdb,
);

export const makeSelectAgenciesFilters = createSelector(
  selectAgencies,
  (agenciesState) => {
    const agenciesFiltered = agenciesState.agenciesFilter;
    return agenciesFiltered;
  },
);

export const makeSelectAgenciesFromdbDMSBusinessPro = createSelector(
  selectAgencies,
  (agenciesState) => {
    const agenciesFromdb = getNested([], agenciesState, 'fromdb');
    return agenciesFromdb.filter(
      (agency) => agency.DMS === 'businesspro',
    );
  },
);

export const makeSelectAgencyDetailsFromState = createSelector(
  selectAgencies,
  (agenciesState) => agenciesState.agencyDetails,
);

export const makeSelectAgenciesIsloading = createSelector(
  selectAgencies,
  (agenciesState) => agenciesState.isLoading,
);

export const makeSelectGetOneAgency = createSelector(
  selectAgencies,
  (agenciesState) => agenciesState.agencyData,
);
