import React, { Component } from 'react';

import { LayoutSplashScreen } from '../../../../../_metronic';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../../authSlice';

class Logout extends Component {
  componentDidMount() {
    this.props.logout();
  }

  render() {
    const { hasAuthToken } = this.props;

    return hasAuthToken ? (
      <LayoutSplashScreen />
    ) : (
      <Redirect to="/auth" />
    );
  }
}

const mapDispatchToProps = {
  logout: logout,
};

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  mapDispatchToProps,
)(Logout);
