import { createSelector } from 'reselect';
import { initialState } from './initialState';

export const selectServiceAppointments = (state) =>
  state.serviceAppointments || initialState;

export const makeSelectServiceAppointmentDetails = createSelector(
  selectServiceAppointments,
  (appointmentsState) => appointmentsState,
);

export const makeSelectServiceAppointment = createSelector(
  selectServiceAppointments,
  (appointmentsState) => appointmentsState.appointmentDetails,
);

export const makeSelectServiceAppointmentsIsLoading = createSelector(
  selectServiceAppointments,
  (serviceAppointmentsState) => serviceAppointmentsState.isLoading,
);
