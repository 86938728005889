import { initialState } from './initialState';
import { createSlice } from '@reduxjs/toolkit';

const chargebacksSlice = createSlice({
  name: 'charbacks',
  initialState,
  reducers: {
    getChargebacks(state) {
      state.isLoading = true;
      state.error = false;
    },
    getChargebacksSuccess(state, action) {
      state.isLoading = false;
      state.fromdb = action.payload.chargebacks.response;
      state.pages = action.payload.chargebacks.pages;
    },
    getChargebacksFail(state, action) {
      state.isLoading = false;
      state.modal = {
        show: true,
        title: 'Error!',
        message: action.payload?.message,
        type: 'errorModal',
      };
    },
    importChargebacksFromExcel: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    importChargebacksFromExcelSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.modal = {
          title: action.payload.message?.title,
          show: true,
          message: action.payload.message?.desc,
          type: 'successModal',
        };
      },
    },
    importChargebacksFromExcelFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.modal = {
          title: action.payload.message?.title,
          show: true,
          message: action.payload.message?.desc,
          type: 'errorModal',
        };
      },
    },
    getChargebacksWTF(state) {
      state.isLoading = true;
      state.error = false;
    },
    getChargebacksWTFSuccess(state, action) {
      state.isLoading = false;
      state.fromdb = action.payload.chargebacks;
    },
    getChargebacksWTFFail(state, action) {
      state.isLoading = false;
      state.modal = {
        show: true,
        title: 'Error!',
        message: action.payload?.message,
        type: 'errorModal',
      };
    },
    importChargebacksFromExcelWTF: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    importChargebacksFromExcelWTFSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.modal = {
          title: action.payload.message?.title,
          show: true,
          message: action.payload.message?.desc,
          type: 'successModal',
        };
      },
    },
    importChargebacksFromExcelWTFFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.modal = {
          title: action.payload.message?.title,
          show: true,
          message: action.payload.message?.desc,
          type: 'errorModal',
        };
      },
    },
    createChargebacks: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    createChargebacksSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.modal = {
          title: action.payload.message?.title,
          show: true,
          message: action.payload.message?.desc,
          type: 'successModal',
        };
      },
    },
    createChargebacksFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.modal = {
          title: action.payload.message?.title,
          show: true,
          message: action.payload.message?.desc,
          type: 'errorModal',
        };
      },
    },
    closeModals(state) {
      // state.isLoading = true;
      state.modal = {
        show: false,
        message: null,
        type: null,
      };
    },
  },
});

export const {
  getChargebacks,
  getChargebacksSuccess,
  getChargebacksFail,
  closeModals,
  importChargebacksFromExcel,
  importChargebacksFromExcelSuccess,
  importChargebacksFromExcelFail,
  getChargebacksWTF,
  getChargebacksWTFSuccess,
  getChargebacksWTFFail,
  importChargebacksFromExcelWTF,
  importChargebacksFromExcelWTFSuccess,
  importChargebacksFromExcelWTFFail,
  createChargebacks,
  createChargebacksSuccess,
  createChargebacksFail,
} = chargebacksSlice.actions;

export default chargebacksSlice.reducer;
