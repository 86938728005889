import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import { Close } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  datePicker: {
    width: 120,
  },
  datePickerTextfield: {
    padding: '5px 5px 5px !important',
    height: '20px',
  },
}));

export default function MUIDatePickerFilter(props) {
  const classes = useStyles();
  const { cell } = props;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale={'es'}>
      <DatePicker
        autoOk
        disableFuture
        disableToolbar
        className={classes.datePicker}
        value={
          cell.filter && cell.filter.value ? cell.filter.value : null
        }
        onChange={(e) => cell.onChange(e)}
        inputProps={{
          className: classes.datePickerTextfield,
        }}
        InputProps={{
          disableUnderline: true,
          endAdornment:
            cell.filter && cell.filter.value ? (
              <IconButton
                aria-label="Select locale"
                size="small"
                onClick={() => cell.onChange(null)}
              >
                <Close />
              </IconButton>
            ) : null,
        }}
      />
    </MuiPickersUtilsProvider>
  );
}
