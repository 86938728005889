import { closeModals } from '../../modals/modalsSlice';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { logout } from '../../auth/authSlice';

const depositsSlice = createSlice({
  name: 'depositsStandAlone',
  initialState,
  reducers: {
    getAllPaymentsStandAlone: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getAllDepositsSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.fromdb = action.payload.deposits.rows;
        state.exportData = action.payload.deposits.filteredData;
        state.tablePagesFromdb = action.payload.deposits.pages;
      },
    },
    getAllDepositsFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    getPaymentsDetailStandAlone: {
      reducer(state) {
        state.isLoading = true;
        state.depositDetails = null;
      },
    },
    getDepositDetailSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.depositDetails = action.payload.deposit;
      },
    },
    getDepositDetailFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    createDeposit: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    createDepositSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    createDepositFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    importDepositsFromExcel: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    importDepositsFromExcelSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    importDepositsFromExcelFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    getAllDepositsSPEI: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getAllDepositsSPEISuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.fromdb = action.payload.deposits.output;
        state.exportData = action.payload.deposits.filteredData;
        state.tablePagesFromdb = action.payload.deposits.pages;
      },
    },
    getAllDepositsSPEIFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    getDepositSPEIDetail: {
      reducer(state) {
        state.isLoading = true;
        state.depositDetails = null;
      },
    },
    getDepositSPEIDetailSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.deposit = action.payload.deposit.output.deposit;
        state.agency = action.payload.deposit.output.agency.name;
        state.details = action.payload.deposit.output.details;
      },
    },
    getDepositSPEIDetailFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
  },
  closeModals: {
    reducer(state, action) {
      state.isLoading = false;
      state.successModal = initialState.successModal;
      state.errorModal = initialState.errorModal;
    },
  },
  extraReducers: {
    [logout]: (state, action) => {
      return initialState;
    },
    [closeModals]: (state, action) => {
      state.isLoading = false;
      state.successModal = initialState.successModal;
      state.errorModal = initialState.errorModal;
    },
  },
});

export const {
  getAllPaymentsStandAlone,
  getAllDepositsSuccess,
  getAllDepositsFail,
  getPaymentsDetailStandAlone,
  getDepositDetailSuccess,
  getDepositDetailFail,
  createDeposit,
  createDepositSuccess,
  createDepositFail,
  importDepositsFromExcel,
  importDepositsFromExcelSuccess,
  importDepositsFromExcelFail,
  getAllDepositsSPEI,
  getAllDepositsSPEISuccess,
  getAllDepositsSPEIFail,
  getDepositSPEIDetail,
  getDepositSPEIDetailSuccess,
  getDepositSPEIDetailFail,
} = depositsSlice.actions;

export default depositsSlice.reducer;
