import React, { useEffect, useState } from 'react';
import { brands } from '../../utils/index';
import { Autocomplete } from '@material-ui/lab';
import { Controller } from 'react-hook-form';
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';

function AutocompleteBrands(props) {
  const { className, onChange, value, ...rest } = props;
  const [brandsOptions, setBrandsOptions] = useState([]);
  const [valueBrands, setValueBrands] = useState([]);

  useEffect(() => {
    if (Array.isArray(brands)) {
      const formatBrands = brands.map((brand) => {
        return {
          id: brand.value,
          label: brand.label,
        };
      });
      setBrandsOptions(formatBrands);
    }
  }, []);

  useEffect(() => {
    if (value) {
      const formatValueBrands = value.map((brand) => {
        const capFirstLetter =
          brand.charAt(0).toUpperCase() + brand.slice(1);
        return {
          id: brand,
          label: capFirstLetter,
        };
      });
      setValueBrands(formatValueBrands);
    }
  }, [value]);

  const getOpObj = (option) => {
    if (!option.id)
      option = brandsOptions.find((op) => op.id === option);
    return option;
  };

  if (props.control) {
    return (
      <Controller
        {...rest}
        render={(props) => (
          <Autocomplete
            {...props}
            multiple
            className={className}
            getOptionLabel={(option) => getOpObj(option).label}
            getOptionSelected={(option, value) =>
              option.id === value.id
            }
            options={brandsOptions}
            value={valueBrands}
            renderInput={(params) => (
              <TextField
                {...params}
                label={'Marca'}
                variant="outlined"
              />
            )}
            onChange={(_, data) => {
              if (data) {
                onChange(data);
                setValueBrands(data);
              } else onChange('');
            }}
          />
        )}
      />
    );
  } else {
    return (
      <Autocomplete
        {...props}
        multiple
        getOptionLabel={(option) => getOpObj(option).label}
        getOptionSelected={(option, value) => option.id === value.id}
        options={brandsOptions}
        value={valueBrands}
        renderInput={(params) => (
          <TextField {...params} label={'Marca'} variant="outlined" />
        )}
        onChange={(_, data) => {
          if (data) {
            onChange(data);
            setValueBrands(data);
          } else onChange('');
        }}
      />
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AutocompleteBrands);
