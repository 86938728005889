import {
  DialogTitle,
  Grid,
  Paper,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
  formatDate,
  formatSingleDateBillpocket,
  formatNumber,
  getNested,
} from '../../../../utils';
import {
  makeSelectAgencies,
  selectAgencies,
} from 'app/features/agencies/selectors';
import {
  makeSelectDepositDetailsFromState,
  selectDeposits,
} from 'app/features/deposits/selectors';

import moment from 'moment';
import ButtonExportExcel from 'app/components/ButtonExportExcel';
import Modal from '../../../../components/Modal';
import RecentActivities from '../../../../components/RecentActivities';
import ShouldItRender from 'app/components/ShouldItRender';
import SplashScreen from '../../../../partials/layout/SplashScreen';
import { Table } from 'react-bootstrap';
import { closeModals } from '../../../modals/modalsSlice';
import { connect } from 'react-redux';
import { getDepositDetail } from 'app/features/deposits/depositsSlice';
import { injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { makeSelectDepositsLogs } from '../../../logs/selectors';
import { makeSelectUserRoleFromState } from 'app/features/auth/selectors';
import { withRouter } from 'react-router';

const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  title: {
    textAlign: 'left',
    color: '#1c1c1c',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  rightContainer: {
    flexDirection: 'column',
  },
  tableTitle: {
    fontWeight: 401,
  },
  clickeableTR: {
    cursor: 'pointer',
  },
  chargebackRow: {
    backgroundColor: '#ffcccb',
  },
  table: {
    width: '100%',
    overflowX: 'auto',
  },
}));

function DetailDeposit(props) {
  const classes = useStyles();
  const {
    closeModals,
    deposits,
    depositDetails,
    depositLogs,
    getDepositDetail,
    setLeftSideComponent,
    setActionButton,
    role,
    match,
    history,
    intl,
    agencies,
  } = props;
  const [totalAmount, setTotalAmount] = useState(false);
  const [totalComissions, setTotalComissions] = useState(false);
  const [totalIVA, setTotalIVA] = useState(false);
  const [totalChargebacks, settTtalChargebacks] = useState(false);
  const [hasSpeiPayments, setHasSpeiPayments] = useState(false);
  const [paymentsData, setPaymentsData] = useState([]);

  useEffect(() => {
    setLeftSideComponent(
      <div className="kt-subheader__main">
        <div>
          <h3 className="kt-subheader__title">
            Detalle del depósito
          </h3>
        </div>
      </div>,
    );
    let data = {
      id: match.params.id,
    };
    getDepositDetail(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (depositDetails) {
      const hasSpeiPayments =
        depositDetails?.SPEIPayments?.length > 0;
      setHasSpeiPayments(hasSpeiPayments);
      loadPaymentsInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depositDetails]);

  useEffect(() => {
    if (!hasSpeiPayments)
      setActionButton(
        <ButtonExportExcel
          data={paymentsData}
          buttonText="Exportar movimientos"
        />,
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentsData, hasSpeiPayments]);

  const renderPaymentForm = (months) => {
    if (months) return `${months} meses`;
    else return 'Una sola exhibición';
  };
  const loadPaymentsInfo = () => {
    const billpocketPayments = depositDetails?.depositPayments;
    if (billpocketPayments.length > 0) {
      const exportDataTemp = [];
      let totalAmountTemp = 0;
      let totalComissionsTemp = 0;
      let totalIVATemp = 0;
      let totalChargebacksTemp = 0;
      let totalDeposit = 0;

      billpocketPayments.forEach((billpocketPayment) => {
        if (billpocketPayment?.chargeback)
          totalChargebacksTemp += billpocketPayment?.total;
        else {
          totalAmountTemp += billpocketPayment?.total;
          totalComissionsTemp += billpocketPayment?.comision;
          totalIVATemp += billpocketPayment?.iva;
        }
        if (depositDetails?.agency?.toogleZeroTaxes) {
          totalDeposit = billpocketPayment?.total;
        } else {
          totalDeposit = billpocketPayment?.amount;
        }

        let type = billpocketPayment?.payment?.type;
        if (!type) type = 'TPV';

        let dateBpPayment = billpocketPayment?.payment?.date;
        exportDataTemp.push({
          TIPO: handleGetOrderType(billpocketPayment?.payment),
          'BILL POCKET ID': billpocketPayment?.payment?.billPocketID
            ? billpocketPayment?.payment?.billPocketID
            : billpocketPayment?.transactionID,
          'REF. DE TRANSACCIÓN': billpocketPayment?.tranReference,
          'NOMBRE TPV': billpocketPayment?.dispTag,
          AGENCIA: billpocketPayment?.payment?.billPocketID,
          'ID AGENCIA': handleGetAgencyName(
            billpocketPayment?.payment,
          ),
          'NOMBRE DEL CLIENTE': handleGetOrderClientName(
            billpocketPayment?.payment,
          ),
          'FECHA DE PAGO': dateBpPayment
            ? formatDate(dateBpPayment)
            : '',
          'MONTO DE TRANSACCIÓN': formatNumber(
            billpocketPayment.total,
          ),
          COMISION: formatNumber(billpocketPayment.comision),
          IVA: formatNumber(billpocketPayment.iva),
          'TOTAL A DEPOSITAR': formatNumber(totalDeposit),
          'MÉTODO DE PAGO': type,
          'FORMA DE PAGO': renderPaymentForm(
            billpocketPayment?.payment?.months,
          ),
          'MONTO SOBRETASA': formatNumber(
            billpocketPayment?.montoSobretasa,
          ),
          'IVA SOBRETASA': formatNumber(
            billpocketPayment?.ivaSobretasa,
          ),
          'MARCA TARJETA': billpocketPayment?.cardBrand,
          'TIPO TARJETA': billpocketPayment?.cardType,
          BANCO: billpocketPayment?.bank,
          'CONTRACARGO?': billpocketPayment?.chargeback ? 'SI' : 'NO',
        });
      });
      setPaymentsData(exportDataTemp);
      setTotalAmount(totalAmountTemp);
      setTotalComissions(totalComissionsTemp);
      setTotalIVA(totalIVATemp);
      settTtalChargebacks(totalChargebacksTemp);
    }
  };

  const handleGetOrderType = (payment) => {
    const serviceOrder = payment?.serviceOrder;
    const paymentOrder = payment?.paymentOrder;
    const creditOrder = payment?.creditOrder;

    if (serviceOrder) return 'Ods';
    else if (paymentOrder) return 'Odp';
    else if (creditOrder) return creditOrder?.id;
    return 'Tpv';
  };

  const handleGetOrderID = (payment) => {
    const serviceOrder = payment?.serviceOrder;
    const paymentOrder = payment?.paymentOrder;
    const creditOrder = payment?.creditOrder;
    if (serviceOrder) return serviceOrder?.orderID;
    else if (paymentOrder) return paymentOrder?.reference;
    else if (creditOrder) return creditOrder?.id;
  };

  const handleGetAgencyName = (payment) => {
    const serviceOrder = payment?.serviceOrder;
    const paymentOrder = payment?.paymentOrder;
    const creditOrder = payment?.creditOrder;
    if (serviceOrder) return serviceOrder?.agency?.name;
    else if (paymentOrder) return paymentOrder?.agency?.name;
    else if (creditOrder) return creditOrder?.agency?.name;
  };

  const handleGetOrderClientName = (payment) => {
    const serviceOrder = payment?.serviceOrder;
    const paymentOrder = payment?.paymentOrder;
    const creditOrder = payment?.creditOrder;
    if (serviceOrder) return serviceOrder?.invoiceProfile?.fullName;
    else if (paymentOrder) return paymentOrder?.nombre;
    else if (creditOrder) return creditOrder?.clientName;
  };

  const handleCloseModals = () => {
    closeModals();
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = deposits;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message } = deposits[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={handleCloseModals}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={handleCloseModals}
          />
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  const renderDepostiDetails = () => {
    return (
      <Paper className={classes.paper}>
        <DialogTitle className={classes.title}>
          Resumen del depósito
        </DialogTitle>
        <Table bordered>
          <tbody>
            <tr>
              <td className={classes.tableTitle}>ID DEL DEPÓSITO</td>
              <td>{getNested('-', depositDetails, 'id')}</td>
            </tr>
            <ShouldItRender should={role === 'Super Admin'}>
              <tr>
                <td className={classes.tableTitle}>AGENCIA</td>
                <td>
                  {getNested('-', depositDetails, 'agency', 'name')}
                </td>
              </tr>
            </ShouldItRender>
            <ShouldItRender should={hasSpeiPayments}>
              <tr>
                <td className={classes.tableTitle}>
                  FOLIO DE AUTORIZACIÓN BANCARIA
                </td>
                <td>
                  {getNested(
                    '-',
                    depositDetails,
                    'bankAuthorization',
                  )}
                </td>
              </tr>
            </ShouldItRender>
            <tr>
              <td className={classes.tableTitle}>CONCEPTO</td>
              <td>{getNested('-', depositDetails, 'name')}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>MONTO</td>
              <td>
                {formatNumber(
                  getNested('-', depositDetails, 'amount'),
                )}
              </td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>
                FECHA DE DEPÓSITO
              </td>
              <td>
                {depositDetails?.depositTime
                  ? formatSingleDateBillpocket(depositDetails?.depositTime)
                  : '-'}
              </td>
              {/* <td>{formatDate(depositDetails?.depositTime)}</td> */}
            </tr>
          </tbody>
        </Table>
      </Paper>
    );
  };

  const renderBreakdownDetails = () => {
    let totalDeposit = 0;
    if (depositDetails?.agency?.toogleZeroTaxes) {
      totalDeposit = totalAmount;
    } else {
      totalDeposit = depositDetails?.amount;
    }
    return (
      <Paper className={classes.paper}>
        <DialogTitle className={classes.title}>Desglose</DialogTitle>
        <Table bordered>
          <tbody>
            <tr>
              <td className={classes.tableTitle}>SUMA TOTAL</td>
              <td>{formatNumber(totalAmount)}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>
                SUMA DE COMISIONES
              </td>
              <td>{formatNumber(totalComissions)}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>SUMA DE IVA</td>
              <td>{formatNumber(totalIVA)}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>
                TOTAL CONTRACARGOS
              </td>
              <td>{formatNumber(formatNumber(totalChargebacks))}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>TOTAL DEPOSITADO</td>
              <td>{formatNumber(totalDeposit)}</td>
            </tr>
          </tbody>
        </Table>
      </Paper>
    );
  };
  const handleRedirectToPayments = (paymentID) => {
    history.push(`/detail-payment/${paymentID}`);
  };

  const renderPaymentsDetails = () => {
    const speiPayments = depositDetails?.SPEIPayments;
    if (speiPayments.length > 0)
      return (
        <Paper>
          <DialogTitle className={classes.title}>
            Movimientos
          </DialogTitle>
          <Table bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>NÚMERO DE TRANSACCIÓN</th>
                <th>MONTO</th>
                <th>FECHA DE PAGO</th>
              </tr>
            </thead>
            <tbody>
              {speiPayments.map((speiPayment) => {
                const paymentID = speiPayment?.payment?.id;
                return (
                  <tr key={`spei-payment-${speiPayment.id}`}>
                    <td
                      className={classes.clickeableTR}
                      role="presentation"
                      onClick={() =>
                        handleRedirectToPayments(paymentID)
                      }
                    >
                      {speiPayment.id}
                    </td>
                    <td
                      className={classes.clickeableTR}
                      role="presentation"
                      onClick={() =>
                        handleRedirectToPayments(paymentID)
                      }
                    >
                      {speiPayment.transactionNumber}
                    </td>
                    <td
                      className={classes.clickeableTR}
                      role="presentation"
                      onClick={() =>
                        handleRedirectToPayments(paymentID)
                      }
                    >
                      {formatNumber(speiPayment.amount)}
                    </td>
                    <td
                      className={classes.clickeableTR}
                      role="presentation"
                      onClick={() =>
                        handleRedirectToPayments(paymentID)
                      }
                    >
                      {formatDate(speiPayment.date)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Paper>
      );
  };

  const renderBillpocketPaymentsDetails = () => {
    const billpocketPayments = depositDetails?.depositPayments;
    if (billpocketPayments.length > 0) {
      let totalDeposit = 0;
      const payments = billpocketPayments.map((billpocketPayment) => {
        const paymentID = billpocketPayment?.payment?.id;
        if (depositDetails?.agency?.toogleZeroTaxes) {
          totalDeposit = billpocketPayment.total;
        } else {
          totalDeposit = billpocketPayment.amount;
        }

        let dateBpPayment = billpocketPayment?.payment?.date;
        return (
          <tr
            key={`spei-payment-${billpocketPayment.id}`}
            className={
              billpocketPayment?.chargeback
                ? classes.chargebackRow
                : null
            }
          >
            <td
              className={classes.clickeableTR}
              role="presentation"
              onClick={() => handleRedirectToPayments(paymentID)}
            >
               {handleGetOrderType(billpocketPayment?.payment)}
            </td>
            <td
              className={classes.clickeableTR}
              role="presentation"
              onClick={() => handleRedirectToPayments(paymentID)}
            >
              {billpocketPayment?.payment?.billPocketID
                ? billpocketPayment?.payment?.billPocketID
                : billpocketPayment?.transactionID}
            </td>
            <td
              className={classes.clickeableTR}
              role="presentation"
              onClick={() => handleRedirectToPayments(paymentID)}
            >
              {billpocketPayment.tranReference}
            </td>
            <td
              className={classes.clickeableTR}
              role="presentation"
              onClick={() => handleRedirectToPayments(paymentID)}
            >
              {billpocketPayment?.dispTag}
            </td>
            <td
              className={classes.clickeableTR}
              role="presentation"
              onClick={() => handleRedirectToPayments(paymentID)}
            >
              {handleGetAgencyName(billpocketPayment?.payment)}
            </td>
            <td
              className={classes.clickeableTR}
              role="presentation"
              onClick={() => handleRedirectToPayments(paymentID)}
            >
              {handleGetOrderID(billpocketPayment?.payment)}
            </td>
            <td
              className={classes.clickeableTR}
              role="presentation"
              onClick={() => handleRedirectToPayments(paymentID)}
            >
              {handleGetOrderClientName(billpocketPayment?.payment)}
            </td>
            <td
              className={classes.clickeableTR}
              role="presentation"
              onClick={() => handleRedirectToPayments(paymentID)}
            >
              {dateBpPayment
                ? formatDate(dateBpPayment)
                : moment(billpocketPayment.date)
                    .utc()
                    .format('DD/MM/YYYY h:mma')}
            </td>
            <td
              className={classes.clickeableTR}
              role="presentation"
              onClick={() => handleRedirectToPayments(paymentID)}
            >
              {formatNumber(billpocketPayment.total)}
            </td>
            <td
              className={classes.clickeableTR}
              role="presentation"
              onClick={() => handleRedirectToPayments(paymentID)}
            >
              {formatNumber(billpocketPayment.comision)}
            </td>
            <td
              className={classes.clickeableTR}
              role="presentation"
              onClick={() => handleRedirectToPayments(paymentID)}
            >
              {formatNumber(billpocketPayment.iva)}
            </td>
            <td
              className={classes.clickeableTR}
              role="presentation"
              onClick={() => handleRedirectToPayments(paymentID)}
            >
              {formatNumber(billpocketPayment?.montoSobretasa)}
            </td>
            <td
              className={classes.clickeableTR}
              role="presentation"
              onClick={() => handleRedirectToPayments(paymentID)}
            >
              {formatNumber(billpocketPayment?.ivaSobretasa)}
            </td>
            <td
              className={classes.clickeableTR}
              role="presentation"
              onClick={() => handleRedirectToPayments(paymentID)}
            >
              {billpocketPayment?.cardBrand}
            </td>
            <td
              className={classes.clickeableTR}
              role="presentation"
              onClick={() => handleRedirectToPayments(paymentID)}
            >
              {billpocketPayment?.cardType}
            </td>
            <td
              className={classes.clickeableTR}
              role="presentation"
              onClick={() => handleRedirectToPayments(paymentID)}
            >
              {billpocketPayment?.bank}
            </td>
            <td
              className={classes.clickeableTR}
              role="presentation"
              onClick={() => handleRedirectToPayments(paymentID)}
            >
              {formatNumber(totalDeposit)}
            </td>
          </tr>
        );
      });
      return (
        <Paper className={classes.table}>
          <DialogTitle className={classes.title}>
            Movimientos
          </DialogTitle>
          <Table bordered size="sm">
            <thead>
              <tr>
                <th>TIPO</th>
                <th>BILL POCKET ID</th>
                <th>REF. DE TRANSACCIÓN</th>
                <th>NOMBRE TPV</th>
                <th>AGENCIA</th>
                <th>ID AGENCIA</th>
                <th>NOMBRE DEL CLIENTE</th>
                <th>FECHA DE PAGO</th>
                <th>MONTO DE TRANSACCIÓN</th>
                <th>COMISION</th>
                <th>IVA</th>
                <th>MONTO SOBRETASA</th>
                <th>IVA SOBRETASA</th>
                <th>MARCA TARJETA</th>
                <th>TIPO TARJETA</th>
                <th>BANCO</th>
                <th>TOTAL A DEPOSITAR</th>
              </tr>
            </thead>
            <tbody>{payments}</tbody>
          </Table>
        </Paper>
      );
    }
  };

  return isEmpty(depositDetails) ? (
    <SplashScreen></SplashScreen>
  ) : (
    <div>
      {renderFeedBackModal()}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          {renderDepostiDetails()}
          {!hasSpeiPayments && renderBreakdownDetails()}
        </Grid>
        <Grid item xs={12} md={8} className={classes.rightContainer}>
          {hasSpeiPayments
            ? renderPaymentsDetails()
            : renderBillpocketPaymentsDetails()}
          {Array.isArray(depositLogs) && depositLogs.length > 0 && (
            <Grid item>
              <RecentActivities logs={depositLogs} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  deposits: selectDeposits(state),
  depositDetails: makeSelectDepositDetailsFromState(state),
  depositLogs: makeSelectDepositsLogs(state),
  role: makeSelectUserRoleFromState(state),
  agencies: makeSelectAgencies(state),
});

const mapDispatchToProps = {
  closeModal: closeModals,
  getDepositDetail: getDepositDetail,
};

export default withRouter(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(DetailDeposit),
  ),
);
