import { call, put } from 'redux-saga/effects';
import { requestHandler } from '../../services/requestHandler';
import {
  getSetuplogsFail,
  getSetuplogsSuccess,
} from './setupLogsSlice';

import {
  updatePromotionsSuccess,
  updatePromotionsFail,
} from './setupSlice';

export function* getSetupLogsSaga(action) {
  try {
    const data = action.payload;
    const log = yield call(requestHandler, {
      method: 'POST',
      path: '/setupLogs/update',
      data,
    });
    yield put(getSetuplogsSuccess({ log }));
  } catch (error) {
    console.log('Error in setup logs saga ', error);
    yield put(getSetuplogsFail({}));
  }
}

export function* updatePromotionsSaga(action){
  const data = action.payload;
  try {
    const response = yield call(requestHandler, {
      method: 'POST',
      path: '/promotionsByOrderType/update-all',
      data,
    });
    yield put(updatePromotionsSuccess({ response }));
  } catch (error) {
    console.log('ERROR IN updatePromotionsSaga ->', error);
    yield put(updatePromotionsFail({ error }));
  }
}
