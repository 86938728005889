import {
  getPaymentOrderDetailFail,
  getPaymentOrderDetailSuccess,
  getPaymentOrdersFail,
  getPaymentOrdersSuccess,
} from './dispersionsSlice';
import { call, put } from 'redux-saga/effects';

import { formatDatesForFilter } from 'app/utils';
import { requestHandler } from '../../services/requestHandler';

export function* getDispersionsSaga(action) {
  try {
    const { page, pageSize, firstDate, secondDate, filtered } = action.payload;
    let offset = 0;
    let fullOrders = [];
    let data = {};
    //harcode limit and offset
    if (page > 0) {
      offset = pageSize * page;
    }
    let where;
    if (filtered.length) {
      filtered.forEach((filters) => {
        where = {
          ...where,
          [filters.id]: filters.value,
        };
      });
    }
    data = {
      where,
      offset: offset,
      limit: pageSize,
      field: 'createdAt',
      ordering: 'DESC',
    };
    if (firstDate && secondDate)
    // prettier-ignore
    data.where.createdAt = formatDatesForFilter(firstDate, secondDate);
    const orders = yield call(requestHandler, {
      method: 'POST',
      path: '/dispersions/get-all-dispersions',
      data,
    });
    orders.fullOrders = fullOrders;
    orders.pages = yield call(Math.ceil, orders.count / pageSize);
    yield put(getPaymentOrdersSuccess({ orders }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba obtener las dispersiónes.
      Intente de nuevo, si el error persiste intente más tarde.`,
    };
    yield put(getPaymentOrdersFail({ message }));
    console.log('error in login user saga', e);
  }
}

export function* getDispersionDetailSaga(action) {
  try {
    const { dispersionID } = action.payload;
    ///Para sin filtros, solo trae el detalle de la dispersion
    const data = { id: dispersionID };
    const order = yield call(requestHandler, {
      method: 'POST',
      path: '/dispersions/get-dispersion',
      data,
    });
    yield put(getPaymentOrderDetailSuccess({ order }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba obtener información de las dispersiónes.
      Intente de nuevo, si el error persiste intente más tarde.`,
    };
    yield put(getPaymentOrderDetailFail({ message }));
    console.log('error in get order detail user saga', e);
  }
}
