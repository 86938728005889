import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, colors } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 0,
    flexShrink: 0,
    borderRadius: theme.shape.borderRadius,
    lineHeight: '10px',
    fontSize: '10px',
    height: 20,
    minWidth: 20,
    whiteSpace: 'nowrap',
    padding: theme.spacing(0.5, 1),
  },
  rounded: {
    borderRadius: 10,
    padding: theme.spacing(0.5)
  }
}));

const Label = props => {
  const { className, variant, color, shape, children, style, fullWidth, ...rest } = props;
  const classes = useStyles();

  const rootClassName = clsx(
    {
      [classes.root]: true,
      [classes.rounded]: shape === 'rounded'
    },
    className
  );

  const finalStyle = { ...style };

  if (fullWidth) {
    finalStyle.width = '100%';
  }

  if (variant === 'contained') {
    finalStyle.backgroundColor = color.background;
    finalStyle.color = color.text ? color.text : '#FFF';
    finalStyle.fontWeight = 'bold';
    finalStyle.fontSize = '10px';
    finalStyle.textTransform = 'none';
  } else {
    finalStyle.border = `1px solid ${color.background}`;
    finalStyle.color = color.background;
    finalStyle.textTransform = 'none';
  }

  return (
    <Typography
      {...rest}
      className={rootClassName}
      style={finalStyle}
      variant="overline"
    >
      {children}
    </Typography>
  );
};

export default Label;
