import {
  Button,
  Grid,
  MenuItem,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import {
  createAdvance,
  createExternalAdvance,
} from '../../advancesSlice';
import { emailRegex, getNested } from 'app/utils';
import {
  makeSelectAgencyIdFromState,
  makeSelectUserRoleFromState,
} from '../../../auth/selectors';

import Input from 'app/components/Input';
import MUIRHFAutocompleteAgencies from 'app/components/MUIRHFAutocompleteAgencies';
import Modal from '../../../../components/Modal';
import MomentUtils from '@date-io/moment';
import { MuiCurrencyFormat } from 'app/components/MUICurrencyFormat';
import { RHFMUISelect } from 'app/components/RHF';
import ShouldItRender from '../../../../components/ShouldItRender';
import { closeModals } from '../../../modals/modalsSlice';
import { connect } from 'react-redux';
import { getAgency } from '../../../agencies/agenciesSlice';
import { injectIntl } from 'react-intl';
import { selectAdvances } from 'app/features/advances/selectors';
import { withRouter } from 'react-router';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(5, 5, 3, 5),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5, 5, 3, 5),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(5, 10, 3, 10),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(5, 10, 3, 10),
    },
  },
  title: {
    textAlign: 'left',
    color: '#1c1c1c',
  },
  textField: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  submitButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  client: {
    padding: theme.spacing(0),
    marginTop: theme.spacing(1),
  },
}));

function AddAdvance(props) {
  const classes = useStyles();
  const {
    advances,
    createAdvance,
    createExternalAdvance,
    role,
    agencyID,
    history,
    location,
  } = props;
  const [isExternal, setIsExternal] = useState(false);

  const {
    handleSubmit,
    register,
    errors,
    control,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      date: null,
      paymentType: '',
    },
  });

  const date = watch('date');

  useEffect(() => {
    props.setLeftSideComponent(
      <h3 className="kt-subheader__title">
        Crear órden de anticipo
      </h3>,
    );
    if (role !== 'Super Admin') {
      props.getAgency(agencyID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.pathname.includes('external')) setIsExternal(true);
  }, [location]);

  const handleCloseModals = (redirect) => {
    props.closeModals({ history, redirect });
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = advances;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message, redirect } = advances[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={() => handleCloseModals(redirect)}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={() => handleCloseModals(redirect)}
          />
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  const handleCreateAdvance = (formData) => {
    let cleanValues = {};
    const {
      serviceOrderID,
      amount,
      advanceName,
      clientAccount,
      type,
      date,
      paymentType,
      ...rest
    } = formData;
    if (isExternal) {
      cleanValues = {
        payment: {
          total: amount,
          amount,
          type: paymentType,
          reference: advanceName,
          external: true,
          comission: 0,
          surcharge: 0,
          date,
        },
        advance: {
          clientAccount,
          agencyID: getNested(undefined, formData, 'agencyID'),
          vin: getNested(undefined, formData, 'vin'),
          email: getNested(undefined, formData, 'email'),
          reference: serviceOrderID || undefined,
          nombre: getNested(undefined, formData, 'nombre'),
          phoneNumber: getNested(undefined, formData, 'phoneNumber'),
          products: [
            {
              concepto: advanceName,
              total: amount,
            },
          ],
          advance: true,
          advanceName,
          type: 'service',
        },
      };
      createExternalAdvance(cleanValues);
    } else {
      cleanValues = {
        ...rest,
        clientAccount,
        products: [
          {
            concepto: advanceName,
            total: amount,
          },
        ],
        advanceName,
        type: 'service',
        advance: true,
        provisional: false,
        reference: serviceOrderID || undefined,
      };
      createAdvance(cleanValues);
    }
  };

  return (
    <Paper className={classes.paper}>
      <form
        onSubmit={handleSubmit(handleCreateAdvance)}
        noValidate
        autoComplete="off"
      >
        <ShouldItRender
          locationPage={props.history.location.pathname}
          action="selectAgency"
        >
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.client}>
              <Typography variant="h6">AGENCIA</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MUIRHFAutocompleteAgencies
                rules={{ required: true }}
                error={Boolean(errors.agencyID)}
                name="agencyID"
                control={control}
                fullWidth
                className={classes.textField}
              />
            </Grid>
          </Grid>
        </ShouldItRender>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.client}>
            <Typography variant="h6">
              INGRESA LA INFORMACION DEL ANTICIPO
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Input
              required
              ref={register({
                required: true,
              })}
              name="clientAccount"
              label="Ingresa el no de cliente"
              className={classes.textField}
              error={Boolean(errors.clientAccount)}
              helperText="ID CLIENTE"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              ref={register}
              name="serviceOrderID"
              label="Si ya cuenta con número de órden de servicio ingresalo aquí (opcional)"
              className={classes.textField}
              error={Boolean(errors.serviceOrderID)}
              helperText="No. De ODS (opcional)"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              required
              multiline
              ref={register({
                required: true,
              })}
              name="advanceName"
              label="Escribe el concepto del anticpo"
              className={classes.textField}
              error={Boolean(errors.advanceName)}
              helperText="CONCEPTO"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="amount"
              control={control}
              className={classes.textField}
              render={(props) => (
                <MuiCurrencyFormat
                  label="Ingresa el monto del anticipo"
                  helperText="MONTO"
                  error={Boolean(errors.amount)}
                  {...props}
                />
              )}
            />
          </Grid>
          {isExternal && (
            <>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="date"
                  rules={{ required: true }}
                  as={
                    <MuiPickersUtilsProvider
                      utils={MomentUtils}
                      locale={'es'}
                    >
                      <DatePicker
                        autoOk
                        fullWidth
                        disableFuture
                        disableToolbar
                        error={Boolean(errors.date)}
                        className={classes.textField}
                        format="DD/MM/YYYY"
                        label="Introduce la fecha del anticipo del cliente"
                        variant="inline"
                        inputVariant="outlined"
                        value={date}
                        onChange={(e) => setValue('date', e, false)}
                        helperText="Fecha"
                      />
                    </MuiPickersUtilsProvider>
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <RHFMUISelect
                  name="paymentType"
                  className={classes.textField}
                  label="Método de pago"
                  control={control}
                  rules={{ required: true }}
                  error={!!errors.paymentType}
                  helperText="Efectivo, transferencia, tarjeta, Otro"
                >
                  <MenuItem disabled value="">
                    Selecciona una opción
                  </MenuItem>
                  <MenuItem value={'CASH'}>Efectivo</MenuItem>
                  <MenuItem value={'TRANSFER'}>
                    Transferencia
                  </MenuItem>
                  <MenuItem value={'CARD'}>
                    Tarjeta de crédito/débito
                  </MenuItem>
                  <MenuItem value={'OTHER'}>Otro</MenuItem>
                </RHFMUISelect>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input
                  ref={register}
                  name="reference"
                  label="Introduce un número de referencia adicional ( ej. No. De folio de anticipo, no de pedido)"
                  className={classes.textField}
                  error={Boolean(errors.reference)}
                  helperText={
                    'No. De referencia adicional (opcional)'
                  }
                />
              </Grid>
            </>
          )}
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.client}>
            <Typography variant="h6">
              INGRESA LA INFORMACION DEL CLIENTE
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              required
              ref={register({
                required: true,
                pattern: /(\w.+\s).+/,
              })}
              name="nombre"
              label="Introduce el nombre y apellido del cliente"
              className={classes.textField}
              error={Boolean(errors.nombre)}
              helperText={'Nombre y Apellido'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              required
              ref={register({
                required: true,
              })}
              name="phoneNumber"
              label="Introduce el celular del cliente"
              className={classes.textField}
              error={Boolean(errors.phoneNumber)}
              helperText={'Teléfono 10 digitos'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              required
              ref={register({
                required: true,
                pattern: emailRegex,
              })}
              name="email"
              label="Introduce el correo del cliente"
              className={classes.textField}
              error={Boolean(errors.email)}
              helperText={'Correo'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              required
              ref={register({
                required: true,
              })}
              name="vin"
              label="Ingresa no. De serie auto"
              className={classes.textField}
              error={Boolean(errors.vin)}
              helperText={'VIN'}
              inputProps={{
                maxLength: 17,
              }}
            />
          </Grid>
        </Grid>
        <div className={classes.submitButtonWrapper}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={advances.isLoading}
          >
            Guardar anticipo
          </Button>
        </div>
      </form>
      {renderFeedBackModal()}
    </Paper>
  );
}

const mapStateToProps = (state) => ({
  advances: selectAdvances(state),
  role: makeSelectUserRoleFromState(state),
  agencyID: makeSelectAgencyIdFromState(state),
});

const mapDispatchToProps = {
  createAdvance: createAdvance,
  closeModals: closeModals,
  getAgency: getAgency,
  createExternalAdvance,
};

export default withRouter(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(AddAdvance),
  ),
);
