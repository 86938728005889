import 'react-table/react-table.css';
import 'moment/locale/es';

/* eslint-disable react/display-name */
import {
  Button,
  IconButton,
  Paper,
  makeStyles,
} from '@material-ui/core';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React, { useEffect } from 'react';
import {
  filterCaseInsensitive,
  formatNumber,
  getColumnWidth,
} from '../../../../utils';

import { CSVLink } from 'react-csv';
import Close from '@material-ui/icons/Close';
import GetApp from '@material-ui/icons/GetApp';
import { Link } from 'react-router-dom';
import MUIDatePickerFilter from 'app/components/MUIDatePickerFilter';
import MomentUtils from '@date-io/moment';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { getPaymentsCreditOrders } from 'app/features/payments/paymentsSlice';
import { injectIntl } from 'react-intl';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  paper: {
    widht: '100%',
    marginBottom: theme.spacing(0),
  },
  button: {
    margin: theme.spacing(1),
  },
  grid: {
    width: '45%',
    marginBottom: theme.spacing(1),
  },
  range: {
    paddingTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  datePicker: {
    width: 90,
  },
}));

function CreditPaymentsTable(props) {
  const classes = useStyles();
  const { getPaymentsCreditOrders, intl } = props;
  const [payments, setPayments] = React.useState([]);
  const [firstDate, setFirstDate] = React.useState(null);
  const [secondDate, setSecondDate] = React.useState(null);

  const handleFirstDate = (date) => {
    setFirstDate(date);
  };

  const handleSecondDate = (date) => {
    setSecondDate(date);
  };

  const handleClearFirstDate = () => {
    setFirstDate(null);
  };

  const handleClearSecondDate = () => {
    setSecondDate(null);
  };

  useEffect(() => {
    let init = firstDate ? firstDate : undefined;
    let end = secondDate ? secondDate : undefined;
    getPaymentsCreditOrders({
      firstDate: init,
      secondDate: end,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstDate, secondDate]);

  useEffect(
    () => {
      let paymentsToCSV = [];
      if (
        Array.isArray(props.payments.creditOrdersFromdb) &&
        props.payments.creditOrdersFromdb.length > 0
      ) {
        const cleanPayments = props.payments.creditOrdersFromdb
          .filter((payment) => payment && payment.creditOrder)
          .map((payment) => {
            const toDeposit = payment.paymentMethod
              ? payment.promotionWI
                ? payment.amount - payment.surcharge
                : payment.amount - payment.comission
              : 0;

            const comissionWithOutIva = payment.paymentMethod
              ? (payment.total - toDeposit) / 1.16
              : 0;

            const iva = payment.paymentMethod
              ? comissionWithOutIva
                ? (comissionWithOutIva * 0.16).toFixed(2)
                : '-'
              : 0;
            const clientFullName = payment.creditOrder.clientName
              ? payment.creditOrder.clientName
              : '-';
            const orderId = payment.creditOrder.id;
            const total = payment ? formatNumber(payment.total) : '-';
            const date = moment(payment.updatedAt).format(
              'DD/MM/YYYY hh:mm a',
            );
            const paymentMethod = payment.type || '-';

            const clientID = payment.creditOrder.clientID;

            paymentsToCSV.push({
              'Id de orden': orderId || '-',
              Cliente: clientFullName || '-',
              'Nombre tarjeta habiente': payment.cardHolder || '-',
              'No. Transacción':
                payment.authNumber || payment.reference || '-',
              'Num. Tarjeta': payment.last4 || '-',
              'Metodo de Pago': payment.type || '-',
              'Forma de pago':
                payment.paymentMethod === 'complete'
                  ? 'Una sola exhibicion'
                  : 'A meses',
              'Fecha de pago': date || '-',
              Monto: total || '-',
              'Comision sin IVA':
                formatNumber(comissionWithOutIva) || '-',
              IVA: formatNumber(iva) || '-',
              'A depositar': formatNumber(toDeposit) || '-',
            });
            return {
              karloID: payment.id,
              clientID,
              agencyID: payment.creditOrder.agencyID,
              orderId: payment.creditOrder.id,
              odsId: payment.creditOrder.id,
              date,
              total: payment ? formatNumber(payment.total) : '-',
              comission: comissionWithOutIva,
              iva,
              toDeposit: formatNumber(toDeposit),
              refNumber: payment
                ? payment.transactionNetPayToken
                : '-',
              clientFullName,
              paymentMethod,
            };
          });
        setPayments(cleanPayments);
      } else {
        setPayments([]);
      }
      props.setActionButton(
        <>
          <MuiPickersUtilsProvider utils={MomentUtils} locale={'es'}>
            <DatePicker
              id="mui-pickers-date"
              disableToolbar
              variant="inline"
              label="Inicial"
              className={classes.datePicker}
              value={firstDate}
              onChange={handleFirstDate}
              InputProps={{
                endAdornment: firstDate ? (
                  <IconButton
                    aria-label="Select locale"
                    size="small"
                    onClick={handleClearFirstDate}
                  >
                    <Close />
                  </IconButton>
                ) : null,
              }}
            />
            <span className={classes.range}>&nbsp;A&nbsp;</span>
            <DatePicker
              id="mui-pickers-date"
              disableToolbar
              variant="inline"
              label="Final"
              className={classes.datePicker}
              value={secondDate}
              onChange={handleSecondDate}
              InputProps={{
                endAdornment: secondDate ? (
                  <IconButton
                    aria-label="Select locale"
                    size="small"
                    onClick={handleClearSecondDate}
                  >
                    <Close />
                  </IconButton>
                ) : null,
              }}
            />
          </MuiPickersUtilsProvider>
          <CSVLink
            data={paymentsToCSV}
            filename="tablaDePagos.csv"
            onClick={(event) => {
              return paymentsToCSV.length > 0;
            }}
          >
            <Button
              variant="contained"
              className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
              disabled={Boolean(paymentsToCSV.length <= 0)}
            >
              Exportar
              <GetApp className="kt-margin-l-10" />
            </Button>
          </CSVLink>
        </>,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.payments.creditOrdersFromdb, firstDate, secondDate],
  );

  const handleTable = (row) => {
    return `/detail-payment/${row.original.karloID}`;
  };

  const colums = [
    {
      Header: () => <strong>ID cliente</strong>,
      accessor: 'clientID',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>Id de orden</strong>,
      accessor: 'orderId',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>Cliente</strong>,
      accessor: 'clientFullName',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      width: getColumnWidth(payments, 'clientFullName', 'Cliente'),
    },
    {
      Header: () => <strong>Método de pago</strong>,
      accessor: 'paymentMethod',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {intl.formatMessage({
              id: `PAYMENT.TYPE.${row.value}`,
            })}
          </div>
        </Link>
      ),
      width: getColumnWidth(
        payments,
        'paymentMethod',
        'Método de pago',
      ),
    },
    {
      Header: () => <strong>Fecha de pago</strong>,
      accessor: 'date',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      Filter: (cell) => {
        return <MUIDatePickerFilter cell={cell} />;
      },
      width: getColumnWidth(payments, 'date', 'Fecha de pago'),
    },
    {
      Header: () => <strong>Monto</strong>,
      accessor: 'total',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      width: getColumnWidth(payments, 'total', 'Total'),
    },
    {
      Header: () => <strong>Comisión</strong>,
      accessor: 'comission',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {formatNumber(row.value)}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>IVA</strong>,
      accessor: 'iva',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {formatNumber(row.value)}
          </div>
        </Link>
      ),
      width: getColumnWidth(payments, 'iva', 'IVA'),
    },
    {
      Header: () => <strong>A depositar</strong>,
      accessor: 'toDeposit',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
  ];

  return (
    <div className={classes.root}>
      <Paper>
        <ReactTable
          NoDataComponent={() => (
            <div className="rt-noData">Sin resultados</div>
          )}
          data={payments}
          columns={colums}
          filterable
          defaultFilterMethod={filterCaseInsensitive}
          className="-striped -highlight"
          previousText="Anterior"
          nextText="Siguiente"
          loading={props.payments.isLoading}
          loadingText="Cargando datos..."
        />
      </Paper>
    </div>
  );
}

const mapStateToProps = (state) => ({
  payments: state.payments,
});

const mapDispatchToProps = {
  getPaymentsCreditOrders,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(CreditPaymentsTable),
);
