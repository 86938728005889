import * as yup from 'yup';

// const stringNotRequired = yup.string().nullable();
const stringValid = yup.string().required('Este campo es requerido');
// const numberValid = yup
//   .number()
//   .required("Este campo es requerido")
//   .positive("El valor no puede ser negativo");

export const agencySchema = yup.object().shape({
  name: stringValid,
  phoneNumber: stringValid,
  email: stringValid.email(),
  DMS: stringValid,
  rfc: stringValid,
  nickname: stringValid,
  address: yup.object().shape({
    street: stringValid,
    ext: stringValid,
    colony: stringValid,
    postalCode: stringValid,
    city: stringValid,
    state: stringValid,
  }),
  businessName: stringValid,
  bpocketApiKey: yup.string(),
  afiliationNumber: yup.string(),
  bpAgencyName: yup.string(),
  bpUser: yup.string(),
  whatsPhoneID: yup.string(),
  stpID: yup.string(),
});
