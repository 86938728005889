import { Controller } from 'react-hook-form';
import FormControl from '@material-ui/core/FormControl';
import { FormHelperText } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import React from 'react';
import Select from '@material-ui/core/Select';

const MUISelect = ({
  name,
  label,
  control,
  defaultValue,
  children,
  helperText,
  ...props
}) => {
  const labelId = `${name}-label`;
  return (
    <FormControl variant="outlined" fullWidth {...props}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        as={
          <Select labelId={labelId} label={label}>
            {children}
          </Select>
        }
        name={name}
        control={control}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};
export default MUISelect;
