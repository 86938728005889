import { createSelector } from 'reselect';
import { initialState } from './initialState';

export const selectServiceOrders = (state) =>
  state.serviceOrders || initialState;

export const makeSelectServiceOrderDetails = createSelector(
  selectServiceOrders,
  (serviceOrdersState) => serviceOrdersState.orderDetails,
);

export const makeSelectServiceOrdersIsLoading = createSelector(
  selectServiceOrders,
  (serviceOrdersState) => serviceOrdersState.isLoading,
);
