import React from 'react';
import { makeStyles } from '@material-ui/core';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import MomentUtils from '@date-io/moment';

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: '40px',
  },
}));

const UpdateSpei = (props) => {
  const { newDate, setNewDate } = props;
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale={'es'}>
      <DatePicker
        className={classes.marginTop}
        SelectProps={{
          native: true,
          inputProps: {
            name: 'newDate',
          },
        }}
        autoOk
        fullWidth
        disableFuture
        disableToolbar
        format="DD/MM/YYYY"
        label="Fecha de SPEI"
        variant="inline"
        inputVariant="outlined"
        value={newDate}
        onChange={(e) => setNewDate(e)}
        helperText="Seleccione la nueva fecha"
      />
    </MuiPickersUtilsProvider>
  );
};

export default UpdateSpei;
