import { createSelector } from 'reselect';
import { initialState } from './initialState';

export const selectKarloQuotes = (state) =>
  state.karloQuotes || initialState;

export const makeSelectKarloQuotes = createSelector(
  selectKarloQuotes,
  (karloQuotesState) => karloQuotesState,
);

export const makeSelectKarloQuotesIsLoading = createSelector(
  selectKarloQuotes,
  (karloQuotesState) => karloQuotesState.isLoading,
);
