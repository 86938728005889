import 'react-table/react-table.css';
import 'moment/locale/es';

/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';

import {
  Button,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import SearchIcon from '@material-ui/icons/Search';

import spei_transaction_number from '../../../../assets/img/assets/spei_transaction_number.png';

import {
  filterCaseInsensitive,
  formatDate,
  formatNumber,
  getNested,
} from '../../../../utils';
import MUIAutocompleteAgencies from 'app/components/MUIAutocompleteAgencies';
import Modal from 'app/components/Modal';
import NumberFormatCustom from 'app/components/NumberFormatCustom';

import {
  searchInSpeiPayments,
  validateSpeiPayment,
} from 'app/features/speiPayments/speiPaymentsSlice';
import { closeModals } from 'app/features/modals/modalsSlice';
import {
  makeSelectSpeiPaymentsSearchedFromState,
  selectSpeiPayments,
} from 'app/features/speiPayments/selectors';

import { connect } from 'react-redux';
import MomentUtils from '@date-io/moment';
import { Controller, useForm } from 'react-hook-form';
import ReactTable from 'react-table';
import clsx from 'clsx';
import { injectIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  paper: {
    widht: '100%',
    marginBottom: theme.spacing(0),
  },
  button: {},
  grid: {
    marginBottom: theme.spacing(1),
  },
  range: {
    paddingTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  datePicker: {
    width: 90,
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2, 2, 2, 2),
  },
  textField: {
    margin: theme.spacing(1, 0, 1, 0),
  },
  totalTextfield: {
    width: 110,
  },
  referenceTextfield: {
    width: 210,
    marginRight: theme.spacing(1),
  },
  nameTextfield: {
    width: 250,
    marginTop: theme.spacing(1),
  },
  paymentMethodfield: {
    minWidth: 130,
  },
  imgSpeiTransactionNumber: {
    height: 'auto',
    width: '500px',
  },
  tooltipImgSpeiTransactionNumber: {
    maxWidth: 500,
    backgroundColor: '#f5f5f9',
  },
}));

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: 550,
    fontSize: theme.typography.pxToRem(12),
  },
}))(Tooltip);

function PaymentsTableSpeiValidation(props) {
  const classes = useStyles();
  const {
    speiPayments,
    speiPaymentsSearched,
    searchInSpeiPayments,
    validateSpeiPayment,
    closeModals,
  } = props;
  const [clientNameToSearch, setClientNameToSearch] = useState('');
  const [totalToSearch, setTotalToSearch] = useState('');
  const [speiStatusToSearch, setSpeiStatusToSearch] = useState('');
  const reactTable = React.useRef(null);
  const [showValidateModal, setShowValidateModal] = useState(false);
  const [selectedSpeiPayment, setSelectedSpeiPayment] = useState(
    null,
  );
  const [agencyID, setAgencyID] = useState('');
  const {
    handleSubmit,
    register,
    errors,
    control,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      date: null,
    },
  });
  const [speiOrders, setSpeiOrders] = useState([]);
  const [speiOrdersPages, setSpeiOrdersPages] = useState(0);

  useEffect(() => {
    searchInSpeiPayments({});
  }, []);

  useEffect(() => {
    if (
      speiPaymentsSearched &&
      speiPaymentsSearched.hasOwnProperty('rows')
    ) {
      setSpeiOrders(speiPaymentsSearched.rows);
      setSpeiOrdersPages(speiPaymentsSearched.pages);
    }
  }, [speiPaymentsSearched]);
  const date = watch('date');

  const getOrderTypeFromPayment = (originalRow) => {
    let orderIdentificator;
    if (originalRow.serviceOrderID)
      orderIdentificator = 'serviceOrder';
    else if (originalRow.paymentOrderID)
      orderIdentificator = 'paymentOrder';
    else if (originalRow.creditOrderID)
      orderIdentificator = 'creditOrder';
    return orderIdentificator;
  };

  const getOrderIdFromPayment = (originalRow) => {
    const orderType = getOrderTypeFromPayment(originalRow);
    if (orderType === 'serviceOrder' && originalRow[orderType]) {
      return originalRow[orderType].orderID;
    } else if (
      orderType === 'paymentOrder' &&
      originalRow[orderType]
    ) {
      return originalRow[orderType].reference;
    } else if (
      orderType === 'creditOrder' &&
      originalRow[orderType]
    ) {
      return originalRow[orderType].clientID;
    }
    return '-';
  };

  const getKarloIDFromPayment = (originalRow) => {
    const orderType = getOrderTypeFromPayment(originalRow);
    if (orderType === 'serviceOrder' && originalRow[orderType]) {
      return originalRow[orderType].id;
    } else if (
      orderType === 'paymentOrder' &&
      originalRow[orderType]
    ) {
      return originalRow[orderType].id;
    } else if (
      orderType === 'creditOrder' &&
      originalRow[orderType]
    ) {
      return originalRow[orderType].id;
    }
    return '-';
  };

  const getMontoPagadoPayment = (originalRow) => {
    const orderType = getOrderTypeFromPayment(originalRow);
    if (orderType === 'serviceOrder' && originalRow[orderType]) {
      return originalRow[orderType].total;
    } else if (
      orderType === 'paymentOrder' &&
      originalRow[orderType]
    ) {
      return originalRow[orderType].total;
    }

    return '-';
  };

  const getNamePayment = (cardHolder, originalRow) => {
    if (cardHolder) return cardHolder;
    const orderType = getOrderTypeFromPayment(originalRow);
    if (orderType === 'serviceOrder' && originalRow[orderType]) {
      return getNested(
        '-',
        originalRow[orderType],
        'invoiceProfile',
        'fullName',
      );
    } else if (
      orderType === 'paymentOrder' &&
      originalRow[orderType]
    ) {
      return getNested('-', originalRow[orderType], 'nombre');
    } else if (
      orderType === 'creditOrder' &&
      originalRow[orderType]
    ) {
      return originalRow[orderType].clientName;
    }
    return '-';
  };

  const handleRenderValidateButton = (status, id, amount) => {
    if (status === 'pendiente')
      return (
        <Tooltip title="Validar pago">
          <IconButton
            className={classes.button}
            size="small"
            onClick={() => {
              setShowValidateModal(true);
              setSelectedSpeiPayment({ id, amount });
            }}
          >
            <i className="fas fa-cash-register"></i>
          </IconButton>
        </Tooltip>
      );

    return null;
  };

  const handleValidateSpeiPayment = (formData) => {
    const { amount, ...rest } = formData;
    validateSpeiPayment({
      ...rest,
      amount: parseFloat(amount.replace(/,/g, ''), 10),
      status: 'validado',
      id: selectedSpeiPayment.id,
      speiStatusToSearch,
      agencyID,
      clientNameToSearch,
      totalToSearch,
    });
    setShowValidateModal(false);
  };

  const handleSearchPayments = () => {
    if (
      agencyID ||
      speiStatusToSearch ||
      clientNameToSearch ||
      totalToSearch
    )
      searchInSpeiPayments({
        agencyID,
        status: speiStatusToSearch,
        clientName: clientNameToSearch,
        total: totalToSearch,
      });
  };

  const handleCloseModals = () => {
    closeModals();
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = speiPayments;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message, redirect } = speiPayments[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={() => handleCloseModals(redirect)}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={() => handleCloseModals(redirect)}
          />
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  const colums = [
    {
      Header: () => <strong>Karlo ID</strong>,
      accessor: 'reference',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {getKarloIDFromPayment(row.original)}
        </div>
      ),
    },
    {
      Header: () => <strong>Agencia</strong>,
      accessor: 'agencyName',
      Cell: (row) => {
        const orderType = getOrderTypeFromPayment(row.original);
        const agencyName = getNested(
          '-',
          row.original[orderType],
          'agency',
          'name',
        );
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {agencyName}
          </div>
        );
      },
    },
    {
      Header: () => <strong>Cliente</strong>,
      accessor: 'cardHolder',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {getNamePayment(row.value, row.original)}
        </div>
      ),
    },
    {
      Header: () => <strong>ID Orden</strong>,
      accessor: 'orderID',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {getOrderIdFromPayment(row.original)}
        </div>
      ),
    },
    {
      Header: () => <strong>Monto Original</strong>,
      accessor: 'amount',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {formatNumber(getMontoPagadoPayment(row.original))}
        </div>
      ),
    },
    {
      Header: () => <strong>Monto Pagado</strong>,
      accessor: 'amount',
      Cell: (row) => {
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {formatNumber(row.value)}
          </div>
        );
      },
    },
    {
      Header: () => <strong>Tipo</strong>,
      accessor: 'serviceOrderID',
      Cell: (row) => {
        const { serviceOrderID } = row.original;
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {serviceOrderID ? 'ODS' : 'ODP'}
          </div>
        );
      },
    },
    {
      Header: () => <strong>Status</strong>,
      accessor: 'status',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value}
        </div>
      ),
    },
    {
      Header: () => <strong>Fecha de pago</strong>,
      accessor: 'date',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value ? formatDate(row.value) : '-'}
        </div>
      ),
    },
    {
      Header: () => <strong>Referencia numerica</strong>,
      accessor: 'reference',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value}
        </div>
      ),
    },
    {
      Header: () => <strong>Banco</strong>,
      accessor: 'bank',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value}
        </div>
      ),
    },
    {
      Header: () => <strong>Nombre ordenante</strong>,
      accessor: 'nombreOrdenante',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value}
        </div>
      ),
    },
    {
      Header: () => <strong>Número de cuenta</strong>,
      accessor: 'cuenta',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value}
        </div>
      ),
    },
    {
      Header: () => <strong>RFC/CURP</strong>,
      accessor: 'rfc',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value}
        </div>
      ),
    },
    {
      Header: () => <strong>Clave de rastreo</strong>,
      accessor: 'claveRastreo',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value}
        </div>
      ),
    },
    {
      Header: () => <strong></strong>,
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {handleRenderValidateButton(
            getNested(null, row.original, 'SPEIPayment', 'status'),
            getNested(null, row.original, 'SPEIPayment', 'id'),
            row.original.amount,
          )}
        </div>
      ),
      width: 70,
      filterable: false,
    },
  ];

  const renderValidateSpeiPayment = () => {
    return (
      <Modal
        open={showValidateModal}
        closeModal={() => setShowValidateModal(false)}
        dialogTitle="Validar pago SPEI"
        dialogText="Ingrese los siguientes datos para validar el pago por SPEI."
        actionButtonText="Validar"
        dialogChildren={
          <form
            id="validation-form"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(handleValidateSpeiPayment)}
          >
            <TextField
              name="amount"
              label="Monto de la orden"
              error={Boolean(errors.amount)}
              className={classes.textField}
              inputRef={register({
                required: true,
                validate: (value) => {
                  if (value)
                    return (
                      parseFloat(value.replace(/,/g, ''), 10) ===
                      selectedSpeiPayment.amount
                    );
                },
              })}
              margin="normal"
              variant="outlined"
              inputProps={{
                type: 'number',
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                inputComponent: NumberFormatCustom,
                inputProps: { quantity: true },
              }}
              helperText={
                Boolean(errors.amount)
                  ? 'Por favor ingrese un monto válido y que sea igual al monto de la orden'
                  : ''
              }
            />
            <TextField
              name="transactionNumber"
              label="Número de transacción"
              error={Boolean(errors.transactionNumber)}
              className={classes.textField}
              inputRef={register({
                required: true,
              })}
              margin="normal"
              variant="outlined"
              inputProps={{
                type: 'number',
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CustomTooltip
                      title={
                        <>
                          <img
                            src={spei_transaction_number}
                            alt="logo"
                            className={
                              classes.imgSpeiTransactionNumber
                            }
                          />
                        </>
                      }
                    >
                      <HelpOutlineIcon />
                    </CustomTooltip>
                  </InputAdornment>
                ),
              }}
            />
            <Controller
              control={control}
              name="date"
              rules={{ required: true }}
              as={
                <MuiPickersUtilsProvider
                  utils={MomentUtils}
                  locale={'es'}
                >
                  <DatePicker
                    autoOk
                    fullWidth
                    disableFuture
                    disableToolbar
                    error={Boolean(errors.date)}
                    className={classes.textField}
                    format="DD/MM/YYYY"
                    label="Fecha de pago"
                    variant="inline"
                    inputVariant="outlined"
                    value={date}
                    onChange={(e) => setValue('date', e, false)}
                  />
                </MuiPickersUtilsProvider>
              }
            />
          </form>
        }
        form={'validation-form'}
      />
    );
  };

  return (
    <div className={classes.root}>
      {renderValidateSpeiPayment()}
      {renderFeedBackModal()}
      <Paper>
        <div className={classes.searchContainer}>
          <MUIAutocompleteAgencies
            size="small"
            style={{ width: 250 }}
            className={classes.referenceTextfield}
            value={agencyID}
            onChange={(event, newValue) => {
              setAgencyID(newValue.id);
            }}
          />
          <TextField
            className={classes.nameTextfield}
            label="Nombre del cliente"
            type="text"
            variant="outlined"
            size="small"
            value={clientNameToSearch}
            onChange={({ target: { value } }) =>
              setClientNameToSearch(value)
            }
            InputProps={{
              startAdornment: (
                <IconButton
                  aria-label="Search"
                  size="small"
                  className={classes.calendarIcon}
                >
                  <SearchIcon />
                </IconButton>
              ),
            }}
          />
          <Button
            className="btn btn-label-success btn-bold kt-margin-l-10"
            onClick={handleSearchPayments}
          >
            Buscar pagos
          </Button>
        </div>
        <ReactTable
          ref={reactTable}
          NoDataComponent={() => (
            <div className="rt-noData">Sin resultados</div>
          )}
          data={speiOrders}
          columns={colums}
          defaultFilterMethod={filterCaseInsensitive}
          className="-striped -highlight"
          previousText="Anterior"
          nextText="Siguiente"
          loading={speiPayments.isLoading}
          loadingText="Cargando datos..."
        />
      </Paper>
    </div>
  );
}

const mapStateToProps = (state) => ({
  speiPaymentsSearched: makeSelectSpeiPaymentsSearchedFromState(
    state,
  ),
  speiPayments: selectSpeiPayments(state),
});

const mapDispatchToProps = {
  searchInSpeiPayments: searchInSpeiPayments,
  validateSpeiPayment,
  closeModals,
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PaymentsTableSpeiValidation),
);
