import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';

const supportSlices = createSlice({
  name: 'support',
  initialState,
  reducers: {
    updateOrder: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    updateStatusOrder: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    billedDMS: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    updateOrderSuccess: {
      reducer(state, action) {
        state.fromdb = action.payload;
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: state.fromdb.updateOrder.message,
        };
      },
    },
    updateOrderFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.error
            ? action.payload.error
            : state.fromdb.updateOrder.message === {}
            ? 'Error al tratar de obtener la información'
            : state.fromdb.updateOrder.message,
        };
      },
    },
    findSPEIorder: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    findOXXOorder: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    findSPEIorderSuccess: {
      reducer(state, action) {
        state.fromdb = action.payload;
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: 'La orden solicitada fue encontrada',
        };
      },
    },
    findSPEIorderFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    downloadUsability: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    downloadUsabilitySuccess: {
      reducer(state, action) {
        state.ordersUsabilityForDownload = action.payload;
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: 'Se encontró la información',
        };
      },
    },
    downloadUsabilityFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    findInvoiceOrder: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    findInvoiceOrderSuccess: {
      reducer(state, action) {
        state.fromdb = action.payload;
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: 'La orden solicitada fue encontrada',
        };
      },
    },
    findInvoiceOrderFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    BPOrder: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    BPOrderSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: 'Se ha concredado correctamente',
        };
      },
    },
    BPOrderFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: JSON.stringify(action.payload.message) || 'Error en el servidor, intentar más tarde',
        };
      },
    },
    getOrdersQuiterForDownloadSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.ordersQuiterForDownload = action.payload;
      },
    },
    getOrdersQuiterForDownloadSuccessFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    geOrdersQuiterForDownload: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getQuiterOrder: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getQuiterOrderSucces: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    getQuiterOrderFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    paymentSPEI: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    paymentOXXO: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    paymentSPEIUpdate: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    paymentSPEISuccess: {
      reducer(state, action) {
        // state.fromdb = action.payload;
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    paymentSPEIFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    invoiceData: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    invoiceDataUpdate: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    invoiceDataSuccess: {
      reducer(state, action) {
        // state.fromdb = action.payload;
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    invoiceDataFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    refundODS: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    refundODSSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    refundODSFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    refund: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    refundSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    refundFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    removeCommission: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    removeCommissionSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    removeCommissionFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    updatePassword: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    updatePasswordSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    updatePasswordFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    associateAgency: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    associateAgencySuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.associateAgencies.message,
        };
        state.agencyWithManager =
          action.payload.associateAgencies.agencyWithManager;
        state.agencyWithOutManager =
          action.payload.associateAgencies.agencyWithOutManager;
      },
    },
    associateAgencyFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    getODSByOrderID: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getODSByOrderIDSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.fromdb = action.payload;
      },
    },
    getODSByOrderIDFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },

    updateODSByOrderID: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    updateODSByOrderIDSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: 'Se actualizó el monto de la orden',
        };
      },
    },
    updateODSByOrderIDFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    importDepositsFromExcelSupport: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    importDepositsFromExcelSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message:
            '¡Los depósitos han sido importados correctamente!',
        };
      },
    },
    importDepositsFromExcelFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: `Ocurrió un error cuando se intentaban importar los depósitos.
          Intente de nuevo, si el error persiste intente más tarde.`,
        };
      },
    },
    testAffiliation: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    testAffiliationSuccess:{
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.result.output.message ? action.payload.result.output.message : 'Prueba exitosa de afiliación',
        };
      },
    },
    testAffiliationFail:{
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },

    searchSPEI: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    searchSPEISuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.fromdb = action.payload;
      },
    },
    searchSPEIFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.fromdb = null;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    resetState: {
      reducer(state, action) {
        state.agencyWithManager = null;
        state.agencyWithOutManager = null;
      },
    },
    closeModals: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = initialState.successModal;
        state.errorModal = initialState.errorModal;
      },
    },
  },
});

export const {
  updateOrder,
  updateStatusOrder,
  billedDMS,
  updateOrderFail,
  updateOrderSuccess,
  findSPEIorder,
  findOXXOorder,
  findSPEIorderSuccess,
  findSPEIorderFail,
  downloadUsability,
  downloadUsabilityFail,
  downloadUsabilitySuccess,
  findInvoiceOrder,
  findInvoiceOrderFail,
  findInvoiceOrderSuccess,
  paymentSPEI,
  paymentOXXO,
  paymentSPEIUpdate,
  paymentSPEISuccess,
  paymentSPEIFail,
  closeModals,
  BPOrder,
  BPOrderFail,
  BPOrderSuccess,
  getQuiterOrder,
  getQuiterOrderSucces,
  getQuiterOrderFail,
  refundODS,
  refundODSSuccess,
  refundODSFail,
  refund,
  refundSuccess,
  refundFail,
  removeCommission,
  removeCommissionSuccess,
  removeCommissionFail,
  updatePassword,
  updatePasswordSuccess,
  updatePasswordFail,
  associateAgency,
  associateAgencySuccess,
  associateAgencyFail,
  resetState,
  getODSByOrderID,
  getODSByOrderIDSuccess,
  getODSByOrderIDFail,
  updateODSByOrderID,
  updateODSByOrderIDSuccess,
  updateODSByOrderIDFail,
  geOrdersQuiterForDownload,
  getOrdersQuiterForDownloadSuccess,
  getOrdersQuiterForDownloadSuccessFail,
  invoiceDataFail,
  invoiceDataSuccess,
  invoiceDataUpdate,
  invoiceData,
  importDepositsFromExcelSupport,
  importDepositsFromExcelSuccess,
  importDepositsFromExcelFail,
  testAffiliation,
  testAffiliationFail,
  testAffiliationSuccess,
  searchSPEI,
  searchSPEISuccess,
  searchSPEIFail,
} = supportSlices.actions;

export default supportSlices.reducer;
