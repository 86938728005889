export const initialState = {
  fromdb: null,
  reservationDetail: null,
  successModal: {
    show: false,
    message: null,
  },
  errorModal: {
    show: false,
    message: null,
  },
  isLoading: false,
  error: null,
  pages: 1,
  redirect: null,
  pagesPayments: 1,
  fromdbPayments: null,
};
