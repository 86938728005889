import { Alert, Table } from 'react-bootstrap';
import {
  Button,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
  cloneObjectLevels,
  formatNumber,
  getNested,
} from '../../../../utils';
import {
  defaultComissions,
  monthsByComissions,
  monthsByComissionsBuenFin,
} from '../../../../config/monthsTaxes';
import {
  getPaymentDetail,
  sendPaymentReceipt,
  sendInvoiceReceipt,
  validatePayment,
  getPdfData,
  statusPayment,
} from 'app/features/payments/paymentsSlice';
import { useDispatch, useSelector } from 'react-redux';

import CheckIcon from '@material-ui/icons/Check';
import SyncIcon from '@material-ui/icons/Sync';
import CreateIcon from '@material-ui/icons/Create';
import GetAppIcon from '@material-ui/icons/GetApp';
import Modal from '../../../../components/Modal';
import Products from 'app/components/Products';
import ReceiptIcon from '@material-ui/icons/Receipt';
import RecentActivities from '../../../../components/RecentActivities';
import ShouldItRender from '../../../../components/ShouldItRender';
import SplashScreen from '../../../../partials/layout/SplashScreen';
import api from '../../../../config/api';
import { closeModals } from '../../../modals/modalsSlice';
import clsx from 'clsx';
import { injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import {
  makeSelectPaymentDetailsFromState,
  makeSelectPaymentPdfData,
} from 'app/features/payments/selectors';
import { makeSelectPaymentsLogs } from '../../../logs/selectors';
import moment from 'moment';
import { paymentsHardcoded } from 'app/config/paymentsHardcoded';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router';
import GridItem from 'app/components/Grid/GridItem';
import {
  makeSelectUserRoleFromState,
  selectAuth,
} from '../../../auth/selectors';
import { connect } from 'react-redux';
import { MenuOption } from 'app/components/MenuOption';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const order = {
  0: 'serviceOrder',
  1: 'paymentOrder',
  2: 'creditOrder',
};

const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  paperTarjeta: {
    textAlign: 'left',
    marginTop: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  divider: {
    width: '100%',
  },
  title: {
    textAlign: 'left',
    color: '#1c1c1c',
  },
  content: {
    textAlign: 'left',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  rightContainer: {
    flexDirection: 'column',
  },
  item: {
    textAlign: 'left',
    justifyContent: 'space-between',
  },
  paperOrden: {
    width: '100%',
    textAlign: 'left',
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
  },
  paperCliente: {
    width: '100%',
    textAlign: 'left',
    marginTop: theme.spacing(2),
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
  },
  paperOps: {
    width: '100%',
    marginTop: theme.spacing(2),
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
  },
  paperUrl: {
    width: '100%',
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
  },
  item1: {
    textAlign: 'left',
  },
  item2: {
    textAlign: 'center',
  },
  tableTitle: {
    fontWeight: 401,
  },
  tables: {
    width: '100%',
  },
  iconImportant: {
    display: 'inline',
  },
  yellowWarning: {
    backgroundColor: '#ffb82270',
    border: '1px solid #ffb82270',
    color: '#111111',
  },
  menuDesktop: {
    '@media screen and (max-width: 1139px)': {
      // eslint-disable-line no-useless-computed-key
      display: 'none',
    },
    marginTop: '13px',
  },
  menuMobile: {
    '@media screen and (min-width: 1140px)': {
      // eslint-disable-line no-useless-computed-key
      display: 'none',
    },
  },
}));

function DetailPayment(props) {
  const dispatch = useDispatch();

  const { role, user } = props;

  const payments = useSelector((state) => state.payments);
  const paymentDetails = useSelector((state) =>
    makeSelectPaymentDetailsFromState(state),
  );

  const paymentsLogs = useSelector((state) =>
    makeSelectPaymentsLogs(state),
  );

  const classes = useStyles();
  const API_URL = api();
  const { intl } = props;
  const [orderData, setOrder] = useState({});
  const [
    showSendReceiptEmailModal,
    setShowSendReceiptEmailModal,
  ] = useState(false);
  const [showSendInvoiceModal, setShowSendInvoiceModal] = useState(
    false,
  );
  const [
    showSendRefundEmailModal,
    setShowSendRefundEmailModal,
  ] = useState(false);
  const [
    showValidatePaymentModal,
    setValidatePaymentModal,
  ] = useState(false);
  const [
    showChangePaymentModal,
    setShowChangePaymentModal,
  ] = useState(false);
  // prettier-ignore
  const [showNoInvoiceModal, setShowNoInvoiceModal] = useState(false);
  const [orderType, setOrderType] = useState('');
  const { handleSubmit, register, errors, formState } = useForm();
  const [payStatus, setPayStatus] = useState('Cambiar a devuelto');

  useEffect(() => {
    props.setLeftSideComponent(
      <div className="kt-subheader__main">
        <div>
          <h3 className="kt-subheader__title">Detalle de pago</h3>
        </div>
      </div>,
    );
    let data = {
      paymentID: parseInt(props.match.params.paymentID),
    };
    dispatch(getPaymentDetail(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleDisplayRightButtons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentDetails, orderType, orderData]);

  useEffect(() => {
    if (paymentDetails) {
      const paymentData = paymentDetails;
      const orderTypeTemp = getOrderType(paymentDetails);
      const orderData = cloneObjectLevels(
        getNested({}, paymentDetails, [order[orderTypeTemp]]),
      );
      let orderID = '';
      let clientFullName = '';
      let clientEmail = '';
      let clientPhoneNumber = '';
      let clientID = '';
      let invoiceNumberCreditOrder = '';
      let orderName = '';
      let category = '';
      let clientAccount = '';
      let products = '';
      let type = '';
      let surcharge = '';
      let surchargeTotal = '';
      let totalCommissionForDeferringToMonths = '';
      let baseSurcharge = '';
      let comission = '';
      let baseComission = '';
      let ivaComision = '';
      let ivaSurcharge = '';
      let totalCommissionCustomer = '';
      let totalCommisionToAgency = '';
      let totalToDepositAgency = '';
      let totalCommissionForTerminalUse = '';
      let monetaryCommission = '';
      let advancesToShow = [];
      switch (orderTypeTemp) {
        //SERVICE ORDER
        case 0:
          const advances = orderData.paymentOrders;
          orderID = orderData.orderID;
          clientFullName = orderData.invoiceProfile.fullName;
          clientEmail = orderData.invoiceProfile.email;
          clientPhoneNumber =
            orderData.invoiceProfile.phoneNumbers[0];
          orderName = 'SERVICIO';
          if (Array.isArray(advances) && advances.length > 0) {
            advancesToShow = advances;
            const totalAldavanced = advances.reduce(
              (acc, advance) => (acc += advance.total),
              0,
            );
            orderData.total = orderData.total - totalAldavanced;
          }
          surcharge = paymentData.surcharge;
          comission = paymentData.comission;
          baseComission = paymentData.baseComission;
          surchargeTotal =
            paymentData.surcharge &&
            paymentData.totalCommissionForTerminalUse > 0
              ? paymentData.surcharge
              : paymentData.totalCommissionForDeferringToMonths;
          totalCommissionForDeferringToMonths =
            paymentData.totalCommissionForDeferringToMonths;
          baseSurcharge = paymentData.baseSurcharge;
          ivaComision = paymentData.ivaComision;
          ivaSurcharge = paymentData.ivaSurcharge;
          totalCommisionToAgency =
            paymentData.totalCommissionToAgency;
          totalToDepositAgency = paymentData.totalToDepositAgency;
          totalCommissionForTerminalUse =
            paymentData.totalCommissionForTerminalUse;
          break;
        //PAYMENT ORDER
        case 1:
          orderID = orderData.reference;
          clientFullName = orderData.nombre;
          clientEmail = orderData.email;
          clientPhoneNumber = orderData.phoneNumber;
          orderName = 'PAGO';
          category = orderData.type;
          clientAccount = orderData.clientAccount;
          products = orderData.products;
          type = orderData.type;
          surcharge = paymentData.surcharge;
          comission = paymentData.comission;
          baseComission = paymentData.baseComission;
          surchargeTotal =
            paymentData.surcharge &&
            paymentData.totalCommissionForTerminalUse > 0
              ? paymentData.surcharge
              : paymentData.totalCommissionForDeferringToMonths;
          totalCommissionForDeferringToMonths =
            paymentData.totalCommissionForDeferringToMonths;
          baseSurcharge = paymentData.baseSurcharge;
          ivaComision = paymentData.ivaComision;
          ivaSurcharge = paymentData.ivaSurcharge;
          totalCommisionToAgency =
            paymentData.totalCommissionToAgency;
          totalToDepositAgency = paymentData.totalToDepositAgency;
          totalCommissionForTerminalUse =
            paymentData.totalCommissionForTerminalUse;
          monetaryCommission = paymentData.monetaryCommission;
          break;
        //CREDIT ORDER
        case 2:
          orderID = orderData.id;
          clientFullName = orderData.clientName;
          clientEmail = orderData.clientEmail;
          clientPhoneNumber = orderData.clientPhoneNumber;
          clientID = orderData.clientID;
          invoiceNumberCreditOrder = orderData.invoiceNumber;
          orderName = 'CRÉDITO';
          surcharge = paymentData.surcharge;
          comission = paymentData.comission;
          baseComission = paymentData.baseComission;
          surchargeTotal =
            paymentData.surcharge &&
            paymentData.totalCommissionForTerminalUse > 0
              ? paymentData.surcharge
              : paymentData.totalCommissionForDeferringToMonths;
          totalCommissionForDeferringToMonths =
            paymentData.totalCommissionForDeferringToMonths;
          baseSurcharge = paymentData.baseSurcharge;
          ivaComision = paymentData.ivaComision;
          ivaSurcharge = paymentData.ivaSurcharge;
          totalCommisionToAgency =
            paymentData.totalCommissionToAgency;
          totalToDepositAgency = paymentData.totalToDepositAgency;
          totalCommissionForTerminalUse =
            paymentData.totalCommissionForTerminalUse;
          break;

        default:
          break;
      }

      const toDeposit = paymentData.paymentMethod
        ? paymentData.promotionWI
          ? paymentData.amount - paymentData.surcharge
          : paymentData.amount - paymentData.comission
        : 0;

      paymentData.status === 'DONE'
        ? setPayStatus('Cambiar a devuelto')
        : setPayStatus('Cambiar a pagado');
      const cleanPayment = {
        id: orderData.id || '-',
        total: getNested('-', orderData, 'total'),
        originalTotal: getNested(
          0,
          paymentDetails,
          [order[orderTypeTemp]],
          'total',
        ),
        orderID,
        category,
        clientAccount,
        advancesToShow,
        type,
        clientFullName,
        clientEmail,
        clientPhoneNumber,
        surcharge,
        comission,
        baseComission,
        surchargeTotal,
        baseSurcharge,
        ivaComision,
        ivaSurcharge,
        totalCommisionToAgency,
        totalToDepositAgency,
        totalCommissionForTerminalUse,
        totalCommissionForDeferringToMonths,
        bank: paymentData.bank,
        clientRFC: orderData.rfc || '-',
        cardHolder: paymentData ? paymentData.cardHolder : '-',
        lasDigits: paymentData ? paymentData.last4 : '-',
        authNumber: paymentData ? paymentData.authNumber : '-',
        paymentMethod: paymentData ? paymentData.paymentMethod : '-',
        paymentType: paymentData ? paymentData.type : '-',
        external: paymentData.external,
        orderPrice: paymentData ? paymentData.total : '-',
        totalComission: paymentData.paymentMethod
          ? paymentData.total - toDeposit
          : 0,
        toDeposit,
        months: paymentData ? paymentData.months : 0,
        promotionWI: paymentData && paymentData.promotionWI,
        datePayed: moment(paymentData.paymentDate).format(
          'DD/MM/YYYY hh:mm a',
        ),
        products,
        orderType: orderTypeTemp,
        invoiceDetail: getNested(
          undefined,
          paymentData,
          'invoiceDetail',
        ),
        advisorName: getNested('-', orderData, 'adviser'),
        advisorPhone: getNested('-', orderData, 'adviserPhoneNumber'),
        advisorEmail: getNested('-', orderData, 'adviserEmail'),
        invoiceNumber: getNested(null, paymentData, 'invoiceNumber'),
        clientID,
        invoiceNumberCreditOrder,
        orderName,
        billPocketID: getNested('-', paymentData, 'billPocketID'),
        baseComission: getNested(null, paymentData, 'baseComission'),
        nombreOrdenante: paymentData
          ? paymentData.nombreOrdenante
          : '-',
        cuenta: paymentData ? paymentData.cuenta : '-',
        rfc: paymentData ? paymentData.rfc : '-',
        claveRastreo: paymentData ? paymentData.claveRastreo : '-',
        monetaryCommission,
        origin: paymentData ? paymentData.origin : '-',
      };
      setOrder(cleanPayment);
      setOrderType(orderTypeTemp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentDetails]);

  const getOrderType = (paymentDetails) => {
    if (
      'serviceOrder' in paymentDetails &&
      paymentDetails.serviceOrder
    )
      return 0;
    else if (
      'paymentOrder' in paymentDetails &&
      paymentDetails.paymentOrder
    )
      return 1;
    else if (
      'creditOrder' in paymentDetails &&
      paymentDetails.creditOrder
    )
      return 2;
  };

  const updateInvoice = () => {
    if (orderData.invoiceNumber) {
      return (
        <Grid container justify="space-between">
          <Grid item>{orderData.invoiceNumber}</Grid>
          <Grid item>
            <CreateIcon
              style={{ cursor: 'pointer' }}
              onClick={toogleShowNoInvoiceModal}
            />
          </Grid>
        </Grid>
      );
    }
    return '-';
  };
  const genericFunction = () => {
    // Don't delete this funtion, is called by the top bar menu
    // buttons that do not require the component's onClick function
  };

  const handleDisplayRightButtons = () => {
    return props.setActionButton(
      <>
        <div className={classes.menuDesktop}>
          <ShouldItRender
            should={orderData.invoiceNumber ? false : true}
            locationPage={`/${props.location.pathname.split('/')[1]}`}
            action="validateInvoice"
            compounded
          >
            <Button
              variant="contained"
              className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
              onClick={toogleShowValidatePaymentModal}
            >
              Validar
              <CheckIcon className="kt-margin-l-10" />
            </Button>
          </ShouldItRender>
          <Button
            variant="contained"
            className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
            onClick={toogleShowSendReceiptEmailModal}
          >
            Enviar recibo
            <ReceiptIcon className="kt-margin-l-10" />
          </Button>
          {(((paymentDetails?.serviceOrder?.agency
            ?.upgradeProcessInvoice &&
            paymentDetails?.serviceOrder?.urlPayment !== '') ||
          (!paymentDetails?.serviceOrder?.agency
            ?.upgradeProcessInvoice &&
            paymentDetails?.serviceOrder?.status === 'billed')) &&
            (paymentDetails?.serviceOrder?.agency
              ?.sendMessagePDFInvoiceWS)) ? (
            <Button
              variant="contained"
              className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
              onClick={toogleShowSendInvoiceModal}
            >
              Reenviar factura
              <ReceiptIcon className="kt-margin-l-10" />
            </Button>
          ) : null}
          <ShouldItRender
            should={paymentDetails?.status === 'REFUND'}
            locationPage={`/${props.location.pathname.split('/')[1]}`}
            action="validateInvoice"
            compounded
          >
            <Button
              variant="contained"
              className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
              onClick={toogleShowSendRefundEmailModal}
            >
              Enviar Devolución
              <ReceiptIcon className="kt-margin-l-10" />
            </Button>
          </ShouldItRender>
          <a
            href={`${API_URL}/${order[orderType]}/getPDFReceipt?paymentID=${props.match.params.paymentID}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              variant="contained"
              className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
            >
              Descargar recibo
              <GetAppIcon className="kt-margin-l-10" />
            </Button>
          </a>
          <ShouldItRender
            should={paymentDetails?.status === 'REFUND' || paymentDetails?.status === 'CANCELED'}
            locationPage={`/${props.location.pathname.split('/')[1]}`}
            action="validateInvoice"
            compounded
          >
            <a
              href={`${API_URL}/payment/export-pdf-refund?agencyID=${paymentDetails?.serviceOrder ? paymentDetails?.serviceOrder?.agencyID : paymentDetails?.paymentOrder?.agencyID}&paymentID=${props.match.params.paymentID}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                variant="contained"
                className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
              >
                Descargar devolución
                <GetAppIcon className="kt-margin-l-10" />
              </Button>
            </a>
          </ShouldItRender>
          <ShouldItRender
            should={user?.user?.name === 'Development Account'}
            locationPage={`/${props.location.pathname.split('/')[1]}`}
            action="validateInvoice"
            compounded
          >
            <Button
              variant="contained"
              className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
              onClick={toogleShowChangePayment}
            >
              {payStatus}
              <SyncIcon className="kt-margin-l-10" />
            </Button>
          </ShouldItRender>
        </div>
        <div className={classes.menuMobile}>
          <MenuOption
            Button={
              <Button
                variant="contained"
                className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-t-10"
              >
                Ver opciones
                <ArrowDropDownIcon className="kt-margin-l-10" />
              </Button>
            }
            options={[
              {
                name: (
                  <ShouldItRender
                    should={orderData.invoiceNumber ? false : true}
                    locationPage={`/${props.location.pathname.split('/')[1]
                      }`}
                    action="validateInvoice"
                    compounded
                  >
                    <Button
                      variant="contained"
                      className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
                      onClick={toogleShowValidatePaymentModal}
                    >
                      Validar
                      <CheckIcon className="kt-margin-l-10" />
                    </Button>
                  </ShouldItRender>
                ),
                onClick: genericFunction,
              },
              {
                name: (
                  <Button
                    variant="contained"
                    className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
                    onClick={toogleShowSendReceiptEmailModal}
                  >
                    Enviar recibo
                    <ReceiptIcon className="kt-margin-l-10" />
                  </Button>
                ),
                onClick: genericFunction,
              },
              {
                name: (
                  <ShouldItRender
                    should={paymentDetails?.status === 'REFUND'}
                    locationPage={`/${props.location.pathname.split('/')[1]}`}
                    action="validateInvoice"
                    compounded
                  >
                    <Button
                      variant="contained"
                      className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
                      onClick={toogleShowSendRefundEmailModal}
                    >
                      Enviar devolución
                      <ReceiptIcon className="kt-margin-l-10" />
                    </Button>
                  </ShouldItRender>
                ),
                onClick: genericFunction,
              },
              {
                name: (
                  <a
                    href={`${API_URL}/${order[orderType]}/getPDFReceipt?paymentID=${props.match.params.paymentID}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      variant="contained"
                      className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
                    >
                      Descargar recibo
                      <GetAppIcon className="kt-margin-l-10" />
                    </Button>
                  </a>
                ),
                onClick: genericFunction,
              },
              {
                name: (
                  <ShouldItRender
                    should={paymentDetails?.status === 'REFUND' || paymentDetails?.status === 'CANCELED'}
                    locationPage={`/${props.location.pathname.split('/')[1]}`}
                    action="validateInvoice"
                    compounded
                  >
                    <a
                      href={`${API_URL}/payment/export-pdf-refund?agencyID=52&paymentID=${props.match.params.paymentID}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button
                        variant="contained"
                        className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
                      >
                        Descargar devolución
                        <GetAppIcon className="kt-margin-l-10" />
                      </Button>
                    </a>
                  </ShouldItRender>
                ),
                onClick: genericFunction,
              },
              {
                name: (
                  <ShouldItRender
                    should={
                      user?.user?.name === 'Development Account'
                    }
                    locationPage={`/${props.location.pathname.split('/')[1]
                      }`}
                    action="validateInvoice"
                    compounded
                  >
                    <Button
                      variant="contained"
                      className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
                      onClick={toogleShowChangePayment}
                    >
                      {payStatus}
                      <SyncIcon className="kt-margin-l-10" />
                    </Button>
                  </ShouldItRender>
                ),
                onClick: genericFunction,
              },
            ]}
          />
        </div>
      </>,
    );
  };

  const toogleShowSendReceiptEmailModal = () =>
    setShowSendReceiptEmailModal(!showSendReceiptEmailModal);

  const toogleShowSendInvoiceModal = () =>
    setShowSendInvoiceModal(!showSendInvoiceModal);

  const toogleShowSendRefundEmailModal = () =>
    setShowSendRefundEmailModal(!showSendRefundEmailModal);

  const toogleShowValidatePaymentModal = () =>
    setValidatePaymentModal(!showValidatePaymentModal);

  const toogleShowNoInvoiceModal = () =>
    setShowNoInvoiceModal(!showNoInvoiceModal);

  const toogleShowChangePayment = () =>
    setShowChangePaymentModal(!showChangePaymentModal);

  const handleValidatePayment = (values) => {
    const data = {
      id: props.match.params.paymentID,
      invoiceNumber: values.invoiceNumber,
      type: `${order[orderType]}s`,
    };
    setValidatePaymentModal(false);
    setShowNoInvoiceModal(false);
    dispatch(validatePayment(data));
  };

  const handleSubmitReceipt = (values) => {
    const data = {
      orderType,
      orderID: orderData.id,
      email: values.email,
      paymentID: props.match.params.paymentID,
    };
    dispatch(sendPaymentReceipt(data));
    toogleShowSendReceiptEmailModal();
    dispatch(closeModals());
  };
  const handleSubmitInvoice = (values) => {
    const data = {
      phone: values.phone,
      paymentID: props.match.params.paymentID,
    };
    dispatch(sendInvoiceReceipt(data));
    toogleShowSendInvoiceModal();
    dispatch(closeModals());
  };

  const handleSubmitRefund = (values) => {
    const data = {
      orderType: 'payment',
      email: values.email,
      paymentID: props.match.params.paymentID,
    };
    dispatch(sendPaymentReceipt(data));
    toogleShowSendRefundEmailModal();
    dispatch(closeModals());
  };

  const handleChangeStatus = () => {
    const data = {
      status: paymentDetails.status,
      paymentID: props.match.params.paymentID,
    };
    toogleShowChangePayment();
    dispatch(statusPayment(data));
  };

  const handleCloseModals = () => {
    dispatch(closeModals());
    props.history.push('/payments');
  };

  const handleCloseModalsWithOutRedirect = () => {
    dispatch(closeModals());
  };

  const renderValidatePayment = () => {
    return (
      <Modal
        open={showValidatePaymentModal}
        closeModal={toogleShowValidatePaymentModal}
        dialogTitle="Validar pago"
        dialogText="Ingrese el número de la factura del pago."
        actionButtonText="Confirmar"
        dialogChildren={
          <form
            className={classes.container}
            id="invoice-number-form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(handleValidatePayment)}
          >
            <TextField
              name="invoiceNumber"
              label="Número de factura"
              className={classes.textField}
              error={
                errors.invoiceNumber &&
                formState.touched.invoiceNumber
              }
              inputRef={register({
                required: true,
              })}
              margin="normal"
              variant="outlined"
            />
          </form>
        }
        form={'invoice-number-form'}
      />
    );
  };

  const renderChangeNoInvoice = () => {
    return (
      <Modal
        open={showNoInvoiceModal}
        closeModal={toogleShowNoInvoiceModal}
        dialogTitle="Editar número de factura"
        dialogText="Ingrese el número de la factura del pago."
        actionButtonText="Confirmar"
        dialogChildren={
          <form
            className={classes.container}
            id="invoice-number-form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(handleValidatePayment)}
          >
            <TextField
              name="invoiceNumber"
              label="Número de factura"
              className={classes.textField}
              error={
                errors.invoiceNumber &&
                formState.touched.invoiceNumber
              }
              inputRef={register({
                required: true,
              })}
              margin="normal"
              variant="outlined"
            />
          </form>
        }
        form={'invoice-number-form'}
      />
    );
  };

  const renderSendReceiptDialog = () => {
    return (
      <Modal
        open={showSendReceiptEmailModal}
        closeModal={toogleShowSendReceiptEmailModal}
        dialogTitle="Enviar recibo"
        dialogText="Se enviará el recibo de pago al correo que se ingrese."
        actionButtonText="Enviar"
        dialogChildren={
          <form
            className={classes.container}
            id="email-form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(handleSubmitReceipt)}
          >
            <TextField
              name="email"
              label="Correo"
              className={classes.textField}
              error={errors.email && formState.touched.email}
              inputRef={register({
                required: true,
                pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
              margin="normal"
              variant="outlined"
            />
          </form>
        }
        form={'email-form'}
      />
    );
  };
  const renderSendInvoiceDialog = () => {
    return (
      <Modal
        open={showSendInvoiceModal}
        closeModal={toogleShowSendInvoiceModal}
        dialogTitle="Enviar recibo y factura"
        dialogText="Se enviará la Factura y el recibo de pago al numero que ingrese."
        actionButtonText="Enviar"
        dialogChildren={
          <form
            className={classes.container}
            id="phone-form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(handleSubmitInvoice)}
          >
            <TextField
              name="phone"
              label="Numero"
              className={classes.textField}
              defaultValue={orderData.clientPhoneNumber}
              error={errors.phone && formState.touched.phone}
              inputRef={register({
                required: true,
                pattern: /^[0-9]*$/,
              })}
              type="number"
              margin="normal"
              variant="outlined"
            />
          </form>
        }
        form={'phone-form'}
      />
    );
  };
  const renderSendRefundDialog = () => {
    return (
      <Modal
        open={showSendRefundEmailModal}
        closeModal={toogleShowSendRefundEmailModal}
        dialogTitle="Enviar recibo de devolución"
        dialogText="Se enviará el recibo de devolución al correo que se ingrese."
        actionButtonText="Enviar"
        dialogChildren={
          <form
            className={classes.container}
            id="email-form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(handleSubmitRefund)}
          >
            <TextField
              name="email"
              label="Correo"
              className={classes.textField}
              error={errors.email && formState.touched.email}
              inputRef={register({
                required: true,
                pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
              margin="normal"
              variant="outlined"
            />
          </form>
        }
        form={'email-form'}
      />
    );
  };

  const renderPaymentForm = (months) => {
    if (months) return `${months} meses`;
    else return 'Una sola exhibición';
  };
  const calculateFixedMonetaryCommission = () => {
    if (orderData.monetaryCommission) {
      return `${formatNumber(orderData.monetaryCommission)}`;
    }
  };

  const calculateFixedComission = () => {
    if (orderData.comission && orderData.baseComission) {
      return `${formatNumber(orderData.comission)} | ${(
        orderData.baseComission * 100
      ).toFixed(2)}%`;
    }

    switch (orderData.paymentType) {
      case 'OXXO':
        return `${formatNumber(orderData.orderPrice * 0.039)} | 3.9%`;
      case 'SPEI':
        return `$8.00`;
      case 'CREDIT AMERICAN-EXPRESS':
        if (orderData.baseComission)
          return `${formatNumber(
            orderData.orderPrice * orderData.baseComission,
          )} | ${orderData.baseComission * 100}%`;
        else
          return `${formatNumber(
            orderData.orderPrice * defaultComissions['amex'],
          )} | 3.5%`;

      default:
        if (orderData.baseComission)
          return `${formatNumber(
            orderData.orderPrice * orderData.baseComission,
          )} | ${orderData.baseComission * 100}%`;
        else
          return `${formatNumber(
            orderData.orderPrice *
              defaultComissions['visa/mastercard'],
          )} | 2.7%`;
    }
  };

  const calculateSurtaxFixedComission = () => {
    if (orderData.surcharge && orderData.baseSurcharge) {
      return `${formatNumber(orderData.surcharge)} | ${(
        orderData.baseSurcharge * 100
      ).toFixed(2)}%`;
    }

    const bpID = getNested('-', orderData, 'billPocketID');
    if (bpID in paymentsHardcoded) {
      return `${formatNumber(
        paymentsHardcoded[bpID].surtaxMonths,
      )} | ${paymentsHardcoded[bpID].surtaxComissionPercentage}`;
    } else {
      const initDate = moment(
        'noviembre 10 2021, 00:01',
        'MMMM DD YYYY, h:mm',
      );
      const endDate = moment(
        'noviembre 16 2021, 23:53',
        'MMMM DD YYYY, h:mm',
      );
      const paymentDate = moment(paymentDetails?.date).format(
        'MMMM DD YYYY, h:mm',
      );
      if (paymentDate > initDate._i && paymentDate < endDate._i) {
        return `${formatNumber(
          orderData.orderPrice *
            monthsByComissionsBuenFin[orderData.months].normal,
        )} | ${(
          monthsByComissionsBuenFin[orderData.months].normal * 100
        ).toFixed(2)}%`;
      } else {
        return `${formatNumber(
          orderData.orderPrice *
            monthsByComissions[orderData.months].normal,
        )} | ${(
          monthsByComissions[orderData.months].normal * 100
        ).toFixed(2)}%`;
      }
    }
  };

  const calculateIva = () => {
    if (orderData.ivaComision && orderData.ivaSurcharge) {
      return formatNumber(
        orderData.ivaComision + orderData.ivaSurcharge,
      );
    }

    switch (orderData.paymentType) {
      case 'OXXO':
        return `${formatNumber(orderData.orderPrice * 0.039 * 0.16)}`;
      case 'SPEI':
        return `${formatNumber(8 * 0.16)}`;

      case 'CREDIT AMERICAN-EXPRESS':
        const initDate = moment(
          'noviembre 10 2021, 00:01',
          'MMMM DD YYYY, h:mm',
        );
        const endDate = moment(
          'noviembre 16 2021, 23:53',
          'MMMM DD YYYY, h:mm',
        );
        const paymentDate = moment(paymentDetails?.date).format(
          'MMMM DD YYYY, h:mm',
        );
        if (paymentDate > initDate._i && paymentDate < endDate._i) {
          return `${formatNumber(
            orderData.months
              ? (orderData.orderPrice *
                  monthsByComissionsBuenFin[orderData.months].normal +
                  orderData.orderPrice *
                    getNested(
                      defaultComissions['amex'],
                      orderData,
                      'baseComission',
                    )) *
                  0.16
              : orderData.orderPrice *
                  getNested(
                    defaultComissions['amex'],
                    orderData,
                    'baseComission',
                  ) *
                  0.16,
          )}`;
        } else {
          return `${formatNumber(
            orderData.months
              ? (orderData.orderPrice *
                  monthsByComissions[orderData.months].normal +
                  orderData.orderPrice *
                    getNested(
                      defaultComissions['amex'],
                      orderData,
                      'baseComission',
                    )) *
                  0.16
              : orderData.orderPrice *
                  getNested(
                    defaultComissions['amex'],
                    orderData,
                    'baseComission',
                  ) *
                  0.16,
          )}`;
        }

      default:
        const bpID = getNested('-', orderData, 'billPocketID');
        if (bpID in paymentsHardcoded) {
          return formatNumber(paymentsHardcoded[bpID].iva);
        } else {
          const initDate = moment(
            'noviembre 10 2021, 00:01',
            'MMMM DD YYYY, h:mm',
          );
          const endDate = moment(
            'noviembre 16 2021, 23:53',
            'MMMM DD YYYY, h:mm',
          );
          const paymentDate = moment(paymentDetails?.date).format(
            'MMMM DD YYYY, h:mm',
          );
          if (paymentDate > initDate._i && paymentDate < endDate._i) {
            return `${formatNumber(
              orderData.months
                ? (orderData.orderPrice *
                    monthsByComissionsBuenFin[orderData.months]
                      .normal +
                    orderData.orderPrice *
                      getNested(
                        defaultComissions['visa/mastercard'],
                        orderData,
                        'baseComission',
                      )) *
                    0.16
                : orderData.orderPrice *
                    getNested(
                      defaultComissions['visa/mastercard'],
                      orderData,
                      'baseComission',
                    ) *
                    0.16,
            )}`;
          } else {
            return `${formatNumber(
              orderData.months
                ? (orderData.orderPrice *
                    monthsByComissions[orderData.months].normal +
                    orderData.orderPrice *
                      getNested(
                        defaultComissions['visa/mastercard'],
                        orderData,
                        'baseComission',
                      )) *
                    0.16
                : orderData.orderPrice *
                    getNested(
                      defaultComissions['visa/mastercard'],
                      orderData,
                      'baseComission',
                    ) *
                    0.16,
            )}`;
          }
        }
    }
  };

  const calculateFixedTotalComission = () => {
    if (orderData.totalCommisionToAgency) {
      return formatNumber(orderData.totalCommisionToAgency);
    }

    const bpID = getNested('-', orderData, 'billPocketID');
    if (bpID in paymentsHardcoded) {
      return formatNumber(
        paymentsHardcoded[bpID].comissionWithoutIVA +
          paymentsHardcoded[bpID].iva,
      );
    } else {
      return formatNumber(orderData.totalComission);
    }
  };

  const calculateFixedToDeposit = () => {
    const toogleZeroTaxes = getNested(
      false,
      paymentDetails,
      [order[orderType]],
      'agency',
      'toogleZeroTaxes',
    );

    if (toogleZeroTaxes) {
      return formatNumber(orderData.orderPrice);
    }

    if (orderData.totalToDepositAgency) {
      return formatNumber(orderData.totalToDepositAgency);
    }

    const bpID = getNested(null, orderData, 'billPocketID');

    if (bpID in paymentsHardcoded) {
      return formatNumber(paymentsHardcoded[bpID].toDeposit);
    } else if (bpID && toogleZeroTaxes) {
      return formatNumber(orderData.orderPrice);
    } else {
      return formatNumber(orderData.toDeposit);
    }
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = payments;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message } = payments[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={handleCloseModalsWithOutRedirect}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={handleCloseModalsWithOutRedirect}
          />
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  const renderChangeStatus = () => {
    let title;
    payStatus === 'Cambiar a devuelto'
      ? (title = 'devuelto')
      : (title = 'pagado');
    return (
      <Modal
        open={showChangePaymentModal}
        closeModal={toogleShowChangePayment}
        dialogTitle={`¿Esta seguro de cambiar el estado a ${title}?`}
        dialogText={`El estado del pago cambiara a ${title}, esta acción puede revertirse en cualquier momento.`}
        actionButtonText="Confirmar"
        onClick={handleChangeStatus}
      />
    );
  };

  const renderOrderDetails = () => {
    return (
      <Paper className={classes.paper}>
        <DialogTitle className={classes.title}>
          Resumen de la orden
        </DialogTitle>

        <Table bordered className={classes.tables}>
          <tbody>
            {orderType === 2 ? (
              <tr>
                <td className={classes.tableTitle}>FACTURA ID</td>
                <td>{orderData.invoiceNumberCreditOrder || '-'}</td>
              </tr>
            ) : null}
            <tr>
              <td className={classes.tableTitle}>
                {orderData.type === 'parts'
                  ? 'NO. DE PEDIDO'
                  : 'ID DE LA ORDEN'}
              </td>
              <td>{orderData.orderID}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>MONTO ORIGINAL</td>
              <td>{formatNumber(orderData.originalTotal)}</td>
            </tr>
            {orderData.category && (
              <tr>
                <td className={classes.tableTitle}>CATEGORÍA</td>
                <td>
                  {orderData.category === 'pulled_apart'
                    ? 'Apartado'
                    : intl.formatMessage({
                        id: `PAYMENT_ORDER.TYPE.${orderData.category}`,
                      })}
                </td>
              </tr>
            )}
            {orderData.clientAccount && (
              <tr>
                <td className={classes.tableTitle}>ID de Cliente</td>
                <td>{orderData.clientAccount}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Paper>
    );
  };

  const renderCardInfo = () => {
    return (
      <Paper className={classes.paperTarjeta}>
        <DialogTitle className={classes.title}>
          Información de pago
        </DialogTitle>
        {renderCardDetails()}
      </Paper>
    );
  };

  const renderCardDetails = () => {
    return (
      <Table bordered className={classes.tables}>
        <tbody>
          {orderData.paymentType === 'OXXO' ||
          orderData.paymentType === 'SPEI' ||
          orderData.external ? null : (
            <>
              <tr>
                  <td className={classes.tableTitle}>NOMBRE</td>
                  <td>{getNested('-', orderData, 'cardHolder')}</td>
                </tr>
                {
                  role === 'Super Admin' ||
                    role === 'Super Admin Agencies' ?
                    <>
                      <tr>
                        <td className={classes.tableTitle}>Agencia</td>
                        <td>{paymentDetails?.serviceOrder && paymentDetails?.serviceOrder?.agency
                          && paymentDetails?.serviceOrder?.agency.businessName
                          ? paymentDetails?.serviceOrder?.agency.businessName
                          : ''}</td>
                      </tr>
                    </>
                    : null
                }
                <tr>
                  <td className={classes.tableTitle}>NUM. TARJETA</td>
                  <td>{getNested('-', orderData, 'lasDigits')}</td>
                </tr>
                <tr>
                <td className={classes.tableTitle}>BP ID</td>
                <td>{getNested('-', orderData, 'billPocketID')}</td>
              </tr>
              <tr>
                <td className={classes.tableTitle}>
                  NUM. DE TRANSACCIÓN
                </td>
                <td>{getNested('-', orderData, 'authNumber')}</td>
              </tr>
            </>
          )}
          <tr>
            <td className={classes.tableTitle}>MÉTODO DE PAGO</td>
            <td>
              {intl.formatMessage({
                id: `PAYMENT.TYPE.${orderData.paymentType}`,
              })}
            </td>
          </tr>
          {orderData.bank && (
            <tr>
              <td className={classes.tableTitle}>BANCO</td>
              <td>{orderData.bank}</td>
            </tr>
          )}
          <tr>
            <td className={classes.tableTitle}>FORMA DE PAGO</td>
            <td>{renderPaymentForm(orderData.months)}</td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>
              PROMOCIÓM MESES SIN INTERESES
            </td>
            <td>
              {orderData.promotionWI ? 'Aplicada' : 'No aplica'}
            </td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>FECHA DE PAGO</td>
            <td>{orderData.datePayed}</td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>ORIGEN</td>
            <td>
              {orderData.origin && orderData.origin === 'online'
                ? 'En linea'
                : orderData.origin && orderData.origin === 'kiosko'
                ? 'Kiosko'
                : orderData.origin.toUpperCase()}
            </td>
          </tr>
          {orderType === 2 ? null : (
            <tr>
              <td className={classes.tableTitle}>FACTURA NO.</td>
              <td>{updateInvoice()}</td>
            </tr>
          )}
          {orderData.paymentType === 'SPEI' && (
            <>
              <tr>
                <td className={classes.tableTitle}>
                  NOMBRE DE ORDENANTE
                </td>
                <td>
                  {orderData.nombreOrdenante
                    ? orderData.nombreOrdenante
                    : '-'}
                </td>
              </tr>
              <tr>
                <td className={classes.tableTitle}>NO. CUENTA</td>
                <td>{orderData.cuenta ? orderData.cuenta : '-'}</td>
              </tr>
              <tr>
                <td className={classes.tableTitle}>RFC/CURP</td>
                <td>{orderData.rfc ? orderData.rfc : '-'}</td>
              </tr>
              <tr>
                <td className={classes.tableTitle}>
                  CLAVE DE RASTREO
                </td>
                <td>
                  {orderData.claveRastreo
                    ? orderData.claveRastreo
                    : '-'}
                </td>
              </tr>
            </>
          )}
        </tbody>
      </Table>
    );
  };

  const renderBillingInfo = () => {
    const invoiceDetail = orderData.invoiceDetail;
    if (invoiceDetail)
      return (
        <Paper className={classes.paper}>
          <DialogTitle className={classes.title}>
            Datos de facturación
          </DialogTitle>

          <Table bordered className={classes.tables}>
            <tbody>
              <tr>
                <td className={classes.tableTitle}>RAZÓN SOCIAL</td>
                <td>
                  {getNested('n/a', invoiceDetail, 'socialReason')}
                </td>
              </tr>
              <tr>
                <td className={classes.tableTitle}>RFC</td>
                <td>{getNested('n/a', invoiceDetail, 'rfc')}</td>
              </tr>
              <tr>
                <td className={classes.tableTitle}>CFDI</td>
                <td>{getNested('n/a', invoiceDetail, 'cfdi')}</td>
              </tr>
              <tr>
                <td className={classes.tableTitle}>MÉTODO DE PAGO</td>
                <td>
                  {intl.formatMessage({
                    id: `PAYMENT.TYPE.${orderData.paymentType}`,
                  })}
                </td>
              </tr>
              <tr>
                <td className={classes.tableTitle}>EMAIL</td>
                <td>{getNested('n/a', invoiceDetail, 'email')}</td>
              </tr>
            </tbody>
          </Table>
        </Paper>
      );
    return null;
  };

  const renderAdvisorDetails = () => {
    if (orderType === 0)
      return (
        <Paper className={classes.paper}>
          <DialogTitle className={classes.title}>Asesor</DialogTitle>

          <Table bordered className={classes.tables}>
            <tbody>
              <tr>
                <td className={classes.tableTitle}>NOMBRE</td>
                <td>{orderData.advisorName}</td>
              </tr>
              <tr>
                <td className={classes.tableTitle}>TELÉFONO</td>
                <td>{orderData.advisorPhone}</td>
              </tr>
              <tr>
                <td className={classes.tableTitle}>CORREO</td>
                <td>{orderData.advisorEmail}</td>
              </tr>
            </tbody>
          </Table>
        </Paper>
      );

    return null;
  };

  const renderUserDetails = () => {
    return (
      <Table bordered className={classes.tables}>
        <tbody>
          {orderType === 2 ? (
            <tr>
              <td className={classes.tableTitle}>ID cliente</td>
              <td>{orderData.clientID || '-'}</td>
            </tr>
          ) : null}
          <tr>
            <td className={classes.tableTitle}>
              NOMBRE O RAZÓN SOCIAL
            </td>
            <td>{orderData.clientFullName}</td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>CORREO</td>
            <td>{orderData.clientEmail}</td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>TELÉFONO CELULAR</td>
            <td>{orderData.clientPhoneNumber}</td>
          </tr>
        </tbody>
      </Table>
    );
  };

  const renderTotals = () => {
    return (
      <Paper className={classes.paperCliente}>
        <DialogTitle className={classes.title}>
          Detalle de movimientos
        </DialogTitle>
        <Table bordered className={classes.tables}>
          <tbody>
            <tr>
              <td
                className={classes.tableTitle}
              >{`MONTO ORDEN DE ${orderData.orderName}`}</td>
              <td>{formatNumber(orderData.originalTotal)}</td>
            </tr>
            {orderData.advancesToShow.length > 0 &&
              orderData.advancesToShow.map((advance, idx) => (
                <tr key={idx}>
                  <td className={classes.tableTitle}>
                    ANTICIPO {idx + 1}
                  </td>
                  <td>- {formatNumber(advance.total)}</td>
                </tr>
              ))}
            {Boolean(orderData.months) && (
              <tr>
                <td
                  className={classes.tableTitle}
                >{`COMISIÓN POR DIFERIR PAGO A ${orderData.months} meses`}</td>
                {orderData.surchargeTotal ? (
                  <td>{formatNumber(orderData.surchargeTotal)}</td>
                ) : (
                  <td>
                    {formatNumber(
                      orderData.totalCommissionForDeferringToMonths ===
                        0
                        ? orderData.totalCommissionForDeferringToMonths
                        : orderData.orderPrice - orderData.total,
                    )}
                  </td>
                )}
              </tr>
            )}
            {orderData.totalCommissionForTerminalUse &&
            orderData.totalCommissionForTerminalUse > 0 ? (
              <tr>
                <td
                  className={classes.tableTitle}
                >{`COMISIÓN POR USO DE TERMINAL`}</td>
                <td>
                  {formatNumber(
                    orderData.totalCommissionForTerminalUse,
                  )}
                </td>
              </tr>
            ) : null}
            <tr>
              <td className={classes.tableTitle}>
                <b>TOTAL PAGADO</b>
              </td>
              <td>{formatNumber(orderData.orderPrice)}</td>
            </tr>
          </tbody>
        </Table>
        {paymentDetails?.months > 0 &&
          paymentDetails?.serviceOrder &&
          paymentDetails.detailPromotionByAgency &&
          !paymentDetails?.detailPromotionByAgency[0]?.isThree &&
          !paymentDetails?.detailPromotionByAgency[0]?.isSix &&
          !paymentDetails?.detailPromotionByAgency[0]?.isNine &&
          !paymentDetails?.detailPromotionByAgency[0]?.isTwelve &&
          !paymentDetails?.detailPromotionByAgency[0]?.isEighteen &&
          !paymentDetails?.serviceOrder?.agency?.surTax?.[3] &&
          !paymentDetails?.serviceOrder?.agency?.surTax?.[6] &&
          !paymentDetails?.serviceOrder?.agency?.surTax?.[9] &&
          !paymentDetails?.serviceOrder?.agency?.surTax?.[12] &&
          !paymentDetails?.serviceOrder?.agency?.surTax?.[18] && (
            <Alert className={classes.yellowWarning}>
              <span className="base-style-info owner-name">
                <i
                  className={clsx(
                    'fas fa-exclamation-circle',
                    classes.iconImportant,
                  )}
                />
                <b>&nbsp;IMPORTANTE</b>&nbsp;Este pago se difirio a
                meses por lo que se tiene que hacer una nota de cargo
              </span>
            </Alert>
          )}
        {paymentDetails?.months > 0 &&
          paymentDetails?.serviceOrder &&
          paymentDetails?.total <
            paymentDetails?.serviceOrder?.agency?.surTax?.[3] && (
            <Alert className={classes.yellowWarning}>
              <span className="base-style-info owner-name">
                <i
                  className={clsx(
                    'fas fa-exclamation-circle',
                    classes.iconImportant,
                  )}
                />
                <b>&nbsp;IMPORTANTE</b>&nbsp;Este pago se difirio a
                meses por lo que se tiene que hacer una nota de cargo
              </span>
            </Alert>
          )}
      </Paper>
    );
  };

  const renderCommissionsDetails = () => {
    if (orderData.external) return null;
    return (
      <Paper className={classes.paperCliente}>
        <DialogTitle className={classes.title}>
          Comisiones
        </DialogTitle>
        <Table bordered className={classes.tables}>
          <tbody>
            <tr>
              <td className={classes.tableTitle}>
                MONTO TOTAL PAGADO
              </td>
              <td>{formatNumber(orderData.orderPrice)}</td>
            </tr>
            {Boolean(orderData.months) && (
              <tr>
                <td className={classes.tableTitle}>
                  {`COMISIÓN SOBRETASA ${orderData.months} MESES`}
                </td>
                <td>{calculateSurtaxFixedComission()}</td>
              </tr>
            )}
            {orderData?.paymentType !== 'SPEI' && (
              <>
                <tr>
                  <td className={classes.tableTitle}>
                    COMISIÓN FIJA
                  </td>
                  <td>{calculateFixedComission()}</td>
                </tr>
                {orderData.monetaryCommission > 0 && (
                  <tr>
                    <td className={classes.tableTitle}>
                      COMISIÓN BANCARIA
                    </td>
                    <td>{calculateFixedMonetaryCommission()}</td>
                  </tr>
                )}
                <tr>
                  <td className={classes.tableTitle}>
                    IVA COMISIONES
                  </td>
                  <td>{calculateIva()}</td>
                </tr>
              </>
            )}
            <tr>
              <td className={classes.tableTitle}>COMISIÓN TOTAL</td>
              <td>{calculateFixedTotalComission()}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>
                TOTAL A DEPOSITAR
              </td>
              <td>{calculateFixedToDeposit()}</td>
            </tr>
          </tbody>
        </Table>
        {paymentDetails?.paymentOrder?.agency?.toogleZeroTaxes && (
          <Alert className={classes.yellowWarning}>
            <span className="base-style-info owner-name">
              <i
                className={clsx(
                  'fas fa-exclamation-circle',
                  classes.iconImportant,
                )}
              />
              <b>&nbsp;IMPORTANTE</b>&nbsp;Las comisiones se
              facturaran en el corte de venta mensual
            </span>
          </Alert>
        )}
        {paymentDetails?.serviceOrder?.agency?.toogleZeroTaxes && (
          <Alert className={classes.yellowWarning}>
            <span className="base-style-info owner-name">
              <i
                className={clsx(
                  'fas fa-exclamation-circle',
                  classes.iconImportant,
                )}
              />
              <b>&nbsp;IMPORTANTE</b>&nbsp;Las comisiones se
              facturaran en el corte de venta mensual
            </span>
          </Alert>
        )}
      </Paper>
    );
  };

  return isEmpty(orderData) ? (
    <SplashScreen></SplashScreen>
  ) : (
    <div>
      {renderSendReceiptDialog()}
      {renderSendInvoiceDialog()}
      {renderSendRefundDialog()}
      {renderValidatePayment()}
      {renderFeedBackModal()}
      {renderChangeNoInvoice()}
      {renderChangeStatus()}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          {renderOrderDetails()}
          {renderCardInfo()}
          {renderBillingInfo()}
          {renderAdvisorDetails()}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={8}
          className={classes.rightContainer}
        >
          <Paper className={classes.paperUrl}>
            <DialogTitle className={classes.title}>
              Cliente
            </DialogTitle>
            {renderUserDetails()}
          </Paper>
          {Array.isArray(orderData?.products) &&
            orderData?.products.length > 0 && (
              <Products
                title="Productos"
                products={orderData.products}
              />
            )}
          {renderTotals()}
          <ShouldItRender
            locationPage={`/${props.location.pathname.split('/')[1]}`}
            action="viewPaymentComissions"
          >
            {renderCommissionsDetails()}
          </ShouldItRender>
          {Array.isArray(paymentsLogs) && paymentsLogs.length > 0 && (
            <Grid item>
              <RecentActivities
                logs={paymentsLogs}
              ></RecentActivities>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  role: makeSelectUserRoleFromState(state),
  user: selectAuth(state),
});

// export default withRouter(injectIntl(DetailPayment));

export default injectIntl(connect(mapStateToProps)(DetailPayment));
