import { createSelector } from 'reselect';
import { initialState } from './initialState';

export const selectSetup = (state) => state.setup || initialState;

export const makeSelectSetupIsloading = createSelector(
  selectSetup,
  (setupState) => setupState.isLoading,
);

export const makeSelectSetupAssociate = createSelector(
  selectSetup,
  (setupState) => setupState,
);
