import { createSelector } from 'reselect';
import { initialState } from './initialState';

export const selectServiceReview = (state) =>
  state.serviceReview || initialState;

export const makeSelectServiceReview = createSelector(
  selectServiceReview,
  (serviceReviewState) => {
    const serviceReviewData = serviceReviewState.reviews;
    return serviceReviewData;
  },
);

export const makeSelectServiceReviewFilters = createSelector(
  selectServiceReview,
  (serviceReviewState) => {
    const serviceReviewFiltered = serviceReviewState.reviewsFilters;
    return serviceReviewFiltered;
  },
);
