/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { Paper } from '@material-ui/core';

import { formatNumber, getColumnWidth, getNested, formatSingleDate } from 'app/utils';
import MUIDatePickerFilter from 'app/components/MUIDatePickerFilter';

import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import { debounce } from 'lodash';
import moment from 'moment';

export const DepositsFiservCreatedTable = (props) => {
  const {
    deposits,
    depositsFromdb,
    getAllDepositsfiserv,
    reactTableRef,
    firstDate,
    secondDate,
  } = props;

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sorted, setSorted] = useState([]);
  const [filtered, setFiltered] = useState('');

  useEffect(() => {
    if ((firstDate && secondDate) || (!firstDate && !secondDate)) {
      handleFetchData();
    }
  }, [firstDate, secondDate]);

  const translateDepositor = (row) => {
    if (row.original.origin == 'TPV') {
      switch (row.original.depositor) {
        case 'depositoTPVAMEX':
          return 'TPV-AMEX';
        case 'depositoTPVProsaBbva':
          return 'TPV-Fiserv-BBVA';
        case 'depositoPINPADProsaBbva':
          return 'TPV-AMEX';
        case 'depositoPINPADAMEX':
          return 'TPV-AMEX';
        default:
          break;
      }
      return `/detail-deposit-standalone/${row.original.id}/${row.original.agencyID}`;
    } else {
      return `/detail-depositfiserv/${row.original.id}`;
    }
  }

  const colums = [
    {
      Header: () => <strong>ID</strong>,
      accessor: 'id',
      Cell: (row) => {
        return (
          <Link to={handleTable(row)}>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              {row.value}
            </div>
          </Link>
        );
      },
    },
    {
      Header: () => <strong>Agencia</strong>,
      accessor: 'agencyName',
      Cell: (row) => {
        return (
          <Link to={handleTable(row)}>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              {row.value}
            </div>
          </Link>
        );
      },
    },
    {
      Header: () => <strong>Cuenta destino</strong>,
      accessor: 'finalDestinationAccount',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      width: 250,
    },
    {
      Header: () => <strong>Depositante</strong>,
      accessor: 'depositor',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value.includes('deposito') ? 'Fiserv' : row.value}
          </div>
        </Link>
      ),
      width: 250,
      Filter: (cell) => {
        return (
          // eslint-disable-next-line jsx-a11y/no-onchange
          <select
            onChange={(e) => cell.onChange(e.target.value)}
            value={
              cell.filter && cell.filter.value
                ? cell.filter.value
                : ''
            }
          >
            <option value="">Todos</option>
            <option value="american-express">American-Express</option>
            <option value="fiserv">Fiserv</option>
            <option value="bbva-karlopay">bbva-karlopay</option>
          </select>
        );
      },
    },
    {
      Header: () => <strong>Fecha de depósito</strong>,
      accessor: 'depositDate',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value
              ? formatSingleDate(row.value)
              : '-'}
          </div>
        </Link>
      ),
      Filter: (cell) => {
        return <MUIDatePickerFilter cell={cell} />;
      },
      width: getColumnWidth(
        getNested([], depositsFromdb),
        'date',
        'Fecha de pago     ',
      ),
    },
    {
      Header: () => <strong>Suma de comisiones</strong>,
      accessor: 'totalCommissions',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {formatNumber(row.value)}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Suma de IVA</strong>,
      accessor: 'totalIvaCommissions',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {formatNumber(row.value)}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Total depositado</strong>,
      accessor: 'totalToDeposit',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {formatNumber(row.value)}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Total Pagos</strong>,
      accessor: 'totalPayments',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {formatNumber(row.value)}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      /* eslint-disable react/display-name */
      Header: () => <strong>Origen</strong>,
      accessor: 'origin',
      /* eslint-disable react/display-name */
      Cell: (row) => {
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        );
      },
      /* eslint-disable react/display-name */
      Filter: (cell) => {
        return (
          // eslint-disable-next-line jsx-a11y/no-onchange
          <select
            onChange={(e) => cell.onChange(e.target.value)}
            value={
              cell.filter && cell.filter.value
                ? cell.filter.value
                : ''
            }
          >
            <option value="">Todos</option>
            <option value={'Caja/Kiosko'}>Caja/Kiosko</option>
            <option value={'kiosko'}>Kiosko</option>
            <option value={'online'}>Online</option>
          </select>
        );
      },
    },
  ];

  const handleFetchData = debounce(
    (state, instance) => {
      if (state) {
        const { page, pageSize, sorted, filtered } = state;
        getAllDepositsfiserv({
          page,
          pageSize,
          sorted,
          filtered,
          firstDate,
          secondDate,
        });
        setPage(page);
        setPageSize(pageSize);
        setSorted(sorted);
        setFiltered(filtered);
      } else {
        getAllDepositsfiserv({
          page,
          pageSize,
          sorted,
          filtered,
          firstDate,
          secondDate,
        });
      }
    },
    1000,
    { leading: false, trailing: true },
  );

  const handleTable = (row) => {
    return `/detail-depositfiserv/${row.original.id}`;
  };

  return (
    <Paper>
      <ReactTable
        ref={reactTableRef}
        NoDataComponent={() => (
          <div className="rt-noData">Sin resultados</div>
        )}
        filterable
        manual
        onFetchData={handleFetchData}
        data={getNested([], depositsFromdb)}
        pages={deposits.tablePagesFromdb}
        columns={colums}
        className="-striped -highlight"
        previousText="Anterior"
        nextText="Siguiente"
        loading={deposits.isLoading}
        loadingText="Cargando datos..."
      />
    </Paper>
  );
};
