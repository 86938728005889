import React, { useState, useEffect } from 'react';

import {
  Button,
  TextField,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  submitButton: {
    marginTop: '20px',
  },
  funtions: {
    marginTop: '40px',
    width: '100%',
  },
}));

const UpdatePasswordForm = (props) => {
  const { handleUpdatePassword } = props;
  const classes = useStyles();
  const [accountData, setAccountData] = useState({
    email: '',
    newPassword: '',
    repeatNewPassword: '',
  });

  const onChange = (value, name) => {
    setAccountData({
      ...accountData,
      [name]: value,
    });
  };

  const sendAndClean = () => {
    setAccountData({
      email: '',
      newPassword: '',
      repeatNewPassword: '',
    });
    handleUpdatePassword(accountData);
  };

  return (
    <>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          label="Usuario"
          variant="outlined"
          fullWidth
          name="email"
          value={accountData.email}
          onChange={(event) =>
            onChange(event.target.value, event.target.name)
          }
        />
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          type="password"
          label="Nueva contraseña"
          variant="outlined"
          fullWidth
          name="newPassword"
          value={accountData.newPassword}
          onChange={(event) =>
            onChange(event.target.value, event.target.name)
          }
        />
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          type="password"
          label="Repetir nueva contraseña"
          variant="outlined"
          fullWidth
          name="repeatNewPassword"
          value={accountData.repeatNewPassword}
          onChange={(event) =>
            onChange(event.target.value, event.target.name)
          }
          error={
            accountData.repeatNewPassword !== accountData.newPassword
          }
          helperText={
            accountData.repeatNewPassword !== accountData.newPassword
              ? 'Las contraseñas no coinciden.'
              : ''
          }
        />
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <div className={classes.submitButton}>
          <Button
            variant="contained"
            color="primary"
            disabled={
              accountData.repeatNewPassword !==
                accountData.newPassword ||
              accountData.email === '' ||
              accountData.repeatNewPassword === '' ||
              accountData.newPassword === ''
            }
            onClick={sendAndClean}
          >
            Actualizar
          </Button>
        </div>
      </Grid>
    </>
  );
};

export default UpdatePasswordForm;
