import { closeModals } from '../modals/modalsSlice';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { logout } from '../auth/authSlice';

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    getPaymentsSPEI: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getPaymentsSPEISuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.fromdb = action.payload.payments.rows;
        state.tablePages = action.payload.payments.pages;
      },
    },
    getPaymentsSPEIFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    getTPVPayments: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getTPVPaymentsSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.paymentsTPVfromdb = action.payload.tpvPayments;
        state.tablePages = action.payload.pages;
      },
    },
    getTPVPaymentsFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    getTPVFiservPayments: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getTPVFiservPaymentsSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.paymentsTPVFiservfromdb = action.payload.deposits;
        state.tablePages = action.payload.pages;
      },
    },
    getTPVFiservPaymentsFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    getPayments: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getPaymentsSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.fromdb = action.payload.payments.rows;
        state.tablePages = action.payload.payments.pages;
      },
    },
    getPaymentsFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload,
        };
      },
    },
    getPaymentsOrders: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getPaymentsOrdersSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.paymentsOrdersFromdb = action.payload.payments.rows;
        state.tablePages = action.payload.payments.pages;
      },
    },
    getPaymentsOrdersFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    getPaymentsForDownload: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getPaymentsForDownloadSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.paymentsForDownload = action.payload.payments;
      },
    },
    getPaymentsForDownloadFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    getPaymentsTPVForDownload: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getPaymentsTPVForDownloadSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.paymentsTPVFiservForDownload = action.payload.payments;
      },
    },
    getPaymentsTPVForDownloadFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    getPaymentsCreditOrders: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getPaymentsCreditOrdersSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.creditOrdersFromdb = action.payload.payments;
      },
    },
    getPaymentsCreditOrdersFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    getPaymentDetail: {
      reducer(state) {
        state.isLoading = true;
        state.paymentDetails = null;
      },
    },
    getPaymentDetailSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.paymentDetails = action.payload.payment;
      },
    },
    getPaymentDetailFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    searchInPayments: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    searchInPaymentsSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.paymentsSearched = action.payload.payments;
      },
    },
    searchInPaymentsFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    getPdfData: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    getPdfDataSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.pdfData = action.payload.pdfData;
      },
    },
    getPdfDataFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.pdfData = null;
      },
    },
    sendPaymentReceipt: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    sendInvoiceReceipt: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    sendPaymentReceiptSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    sendPaymentReceiptFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    validatePayment: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    validatePaymentSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.paymentDetails = action.payload.payment;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    validatePaymentFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    statusPayment: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    statusPaymentSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    statusPaymentFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    getPaymentsCompleteForDownload: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getPaymentsCompleteForDownloadSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.paymentsCompleteForDownload = action.payload.payments;
      },
    },
    getPaymentsCompleteForDownloadFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
  },
  extraReducers: {
    [logout]: (state, action) => {
      return initialState;
    },
    [closeModals]: (state, action) => {
      state.isLoading = false;
      state.successModal = initialState.successModal;
      state.errorModal = initialState.errorModal;
    },
  },
});

export const {
  getPdfData,
  getPdfDataSuccess,
  getPdfDataFail,
  getPaymentsSPEI,
  getPaymentsSPEISuccess,
  getPaymentsSPEIFail,
  getPayments,
  getPaymentsSuccess,
  getPaymentsFail,
  getPaymentsOrders,
  getPaymentsOrdersSuccess,
  getPaymentsOrdersFail,
  getPaymentsForDownload,
  getPaymentsForDownloadSuccess,
  getPaymentsForDownloadFail,
  getPaymentsCreditOrders,
  getPaymentsCreditOrdersSuccess,
  getPaymentsCreditOrdersFail,
  getPaymentDetail,
  getPaymentDetailSuccess,
  getPaymentDetailFail,
  searchInPayments,
  searchInPaymentsSuccess,
  searchInPaymentsFail,
  sendPaymentReceipt,
  sendInvoiceReceipt,
  sendPaymentReceiptSuccess,
  sendPaymentReceiptFail,
  validatePayment,
  validatePaymentSuccess,
  validatePaymentFail,
  statusPayment,
  statusPaymentSuccess,
  statusPaymentFail,
  getTPVPayments,
  getTPVPaymentsSuccess,
  getTPVPaymentsFail,
  getTPVFiservPaymentsFail,
  getTPVFiservPaymentsSuccess,
  getTPVFiservPayments,
  getPaymentsTPVForDownload,
  getPaymentsTPVForDownloadSuccess,
  getPaymentsTPVForDownloadFail,
  getPaymentsCompleteForDownload,
  getPaymentsCompleteForDownloadSuccess,
  getPaymentsCompleteForDownloadFail,
} = paymentsSlice.actions;

export default paymentsSlice.reducer;
