import 'react-table/react-table.css';
/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from 'react';
import ReactTable from 'react-table';
import Add from '@material-ui/icons/Add';
import { Badge } from 'react-bootstrap';
import { getBadgeColor } from '../../../../utils';
import { GetApp } from '@material-ui/icons';
import {
  Button,
  Paper,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { debounce } from 'lodash';
import {
  getChargebacksWTF,
  closeModals,
  importChargebacksFromExcelWTF,
} from '../../chargeBacksSlices';
import Modal from '../../../../components/Modal';
import moment from 'moment';
import {
  selectChargebacks,
  makeSelectChargebacks,
} from '../../selectors';
import ButtonImportFile from 'app/components/ButtonImportFile';
import {
  filterCaseInsensitive,
  formatDate,
  formatNumber,
  getColumnWidth,
  getNested,
} from 'app/utils';
import { makeSelectUserRoleFromState } from '../../../auth/selectors';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  paper: {
    widht: '100%',
    marginBottom: theme.spacing(0),
  },
  button: {
    margin: theme.spacing(1),
  },
  textField: {
    margin: theme.spacing(1),
    width: '95%',
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
}));

const Chargebacks = (props) => {
  const {
    getChargebacksWTF,
    chargebacks,
    fromdb,
    closeModals,
    setActionButton,
    importChargebacksFromExcelWTF,
    role,
  } = props;
  const classes = useStyles();
  const reactTable = useRef(null);
  const [
    showImportDespositsModal,
    setShowImportDespositsModal,
  ] = useState(false);
  const [excelFile, setExcelFile] = useState(null);

  useEffect(() => {
    if (role === 'Super Admin')
      setActionButton(
        <Button
          variant="contained"
          className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
          onClick={toggleImportExcel}
        >
          Importar contracargos
          <GetApp className="kt-margin-l-10" />
        </Button>,
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  const toggleImportExcel = () => {
    setShowImportDespositsModal(!showImportDespositsModal);
  };

  const handleImportDepositsFromExcel = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file01', excelFile);
    importChargebacksFromExcelWTF({
      data: formData,
      handleRefreshData,
    });
    setExcelFile(null);
    toggleImportExcel();
  };

  const handleRefreshData = () =>
    reactTable.current && reactTable.current.fireFetchData();

  const renderCreateDeposit = () => {
    return (
      <Modal
        open={showImportDespositsModal}
        closeModal={toggleImportExcel}
        dialogTitle="Crear depósito"
        actionButtonText="Validar"
        form={'import-deposits-from-excel-form'}
        dialogChildren={
          <form
            id="import-deposits-from-excel-form"
            autoComplete="off"
            noValidate
            onSubmit={handleImportDepositsFromExcel}
          >
            <ButtonImportFile
              className={classes.buttonImportExcel}
              buttonName="Seleccionar archivo excel"
              file={excelFile}
              setFile={setExcelFile}
            />
          </form>
        }
      />
    );
  };

  const columns = [
    // {
    //   Header: () => <strong>Factura</strong>,
    //   // accessor: 'orderReservation',
    //   Cell: (row) => {
    //     return (
    //       <Link to={`/reservation-order/${row.original.id}`}>
    //         <div
    //           style={{
    //             textAlign: 'center',
    //           }}
    //         >
    //           {row.value}
    //         </div>
    //       </Link>
    //     );
    //   },
    // },
    {
      Header: () => <strong>Dms ID</strong>,
      accessor: 'dmsId',
      Cell: (row) => {
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        );
      },
    },
    {
      Header: () => <strong>ID Transacción</strong>,
      accessor: 'transactionId',
      Cell: (row) => {
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        );
      },
    },
    {
      Header: () => <strong>Cliente</strong>,
      accessor: 'user',
      Cell: (row) => {
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        );
      },
    },
    {
      Header: () => <strong>Fecha de pago</strong>,
      accessor: 'dateChargeback',
      Cell: (row) => {
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value
              ? moment(row.value).utc().format('DD/MM/YYYY')
              : '-'}
          </div>
        );
      },
    },
    {
      Header: () => <strong>Fecha de contracargo</strong>,
      accessor: 'dateChargeback',
      Cell: (row) => {
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value
              ? moment(row.value).utc().format('DD/MM/YYYY')
              : '-'}
          </div>
        );
      },
    },
    {
      Header: () => <strong>Fecha de depósito</strong>,
      accessor: 'dateDeposit',
      Cell: (row) => {
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value
              ? moment(row.value).utc().format('DD/MM/YYYY')
              : '-'}
          </div>
        );
      },
    },
    {
      Header: () => <strong>Monto de TX</strong>,
      accessor: 'amountTx',
      Cell: (row) => {
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {formatNumber(row.value)}
          </div>
        );
      },
    },
    {
      Header: () => <strong>Última actualización</strong>,
      accessor: 'updatedAt',
      Cell: (row) => {
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value ? formatDate(row.value) : '-'}
          </div>
        );
      },
    },
  ];

  const handleFetchData = debounce(
    (state, instance) => {
      const { page, pageSize, sorted, filtered } = state;
      getChargebacksWTF({
        page,
        pageSize,
        sorted,
        filtered,
      });
    },
    1000,
    { leading: false, trailing: true },
  );

  const renderModal = () => {
    const { modal } = chargebacks;
    if (modal.show === true) {
      const modalType = modal.type;
      return (
        <Modal
          open={modal.show}
          type={modalType}
          closeModal={closeModals}
          dialogTitle={modal.title}
          dialogText={modal.message}
          actionButtonText="Aceptar"
          onClick={closeModals}
        />
      );
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {renderModal()}
        {renderCreateDeposit()}
        <ReactTable
          NoDataComponent={() => (
            <div className="rt-noData">Sin resultados</div>
          )}
          onFetchData={handleFetchData}
          data={fromdb || []}
          columns={columns}
          filterable
          defaultFilterMethod={filterCaseInsensitive}
          className="-striped -highlight"
          previousText="Anterior"
          nextText="Siguiente"
          pages={chargebacks.pages}
          loading={chargebacks.isLoading}
          loadingText="Cargando datos..."
        />
      </Paper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  chargebacks: selectChargebacks(state),
  fromdb: makeSelectChargebacks(state),
  role: makeSelectUserRoleFromState(state),
});

const mapDispatchToProps = {
  getChargebacksWTF,
  closeModals,
  importChargebacksFromExcelWTF,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(Chargebacks),
);
