import {
  applyAdvancePaymentOrderFail,
  applyAdvancePaymentOrderSuccess,
  createOrUpdateSurtaxesForPaymentOrdersFail,
  createOrUpdateSurtaxesForPaymentOrdersSuccess,
  createPaymentOrderFail,
  createPaymentOrderSuccess,
  getPaymentOrderDetailFail,
  getPaymentOrderDetailSuccess,
  getPaymentOrdersFail,
  getPaymentOrdersSuccess,
  getPaymentsForOrderDetailFail,
  getPaymentsForOrderDetailSuccess,
  sendEmailLinkPaymentOrderFail,
  sendEmailLinkPaymentOrderSuccess,
  updatePaymentOrderFail,
  updatePaymentOrderSuccess,
} from './paymentOrdersSlice';
import {
  asesorRoles,
  partsAsesorRoles,
  salesAdvisorRoles,
} from '../../config/roles';
import { call, put, select } from 'redux-saga/effects';
import {
  makeSelectAgencyId,
  makeSelectUserId,
  makeSelectUserRole,
} from '../auth/selectors.js';

import { errors } from './errors.js';
import { formatDatesForFilter } from 'app/utils';
import { getLogs } from 'app/features/logs/logsSlice';
import moment from 'moment';
import { requestHandler } from '../../services/requestHandler';

export function* getPaymentOrdersSaga(action) {
  try {
    const userRole = yield select(makeSelectUserRole());
    const adminID = yield select(makeSelectUserId());
    const agencyId = yield select(makeSelectAgencyId());
    const {
      page,
      pageSize,
      filtered,
      canceled,
      firstDate,
      secondDate,
    } = action.payload;
    let fullOrders = [];
    let data = {
      where: {
        advance: { $or: ['true', null] },
        createdAt: {
          $gt: moment().subtract(4, 'months').startOf('day'),
        },
      },
      agencies: {
        options: {
          attributes: ['id', 'isActiveAgency', 'name'],
        },
      },
      payments: true,
      options: {
        count: true,
        distinct: true,
      },
      pagination: {
        page,
        perPage: pageSize,
        order: [['updatedAt', 'DESC']],
      },
    };
    if (agencyId) data.agencies.where = { id: agencyId };
    if (canceled === 1)
      data.where = { ...data.where, status: 'cancelada' };
    if (canceled === 2)
      data.where = { ...data.where, status: 'pagado' };
    else
      data.where = { ...data.where, status: { $not: 'cancelada' } };
    if (filtered.length) {
      filtered.forEach((filter) => {
        if (filter.id === 'name')
          data.where['nombre'] = {
            $iLike: `%${filter.value}%`,
          };
        else if (filter.id === 'agencyName')
          data.agencies.where = {
            ...data.agencies.where,
            name: { $iLike: `%${filter.value}%` },
          };
        else if (filter.id === 'created')
          data.where['createdAt'] = formatDatesForFilter(
            filter.value,
          );
        else if (filter.id === 'date')
          data.payments = {
            where: {
              date: formatDatesForFilter(filter.value),
            },
          };
        else {
          let translade = '';
          const term = filter.value.toUpperCase();
          if (term.includes('VEN')) translade = 'sale';
          if (term.includes('REF')) translade = 'part';
          if (term.includes('SERVICIO')) translade = 'service';
          translade !== ''
            ? (data.where[filter.id] = { $iLike: `%${translade}%` })
            : (data.where[filter.id] = {
                $iLike: `%${filter.value}%`,
              });
        }
      });
    }
    if (firstDate && secondDate)
      // prettier-ignore
      data.where.createdAt = formatDatesForFilter(firstDate, secondDate);
    const orders = yield call(requestHandler, {
      method: 'POST',
      path: '/paymentOrder/getAll',
      data,
    });
    if (
      (firstDate && secondDate) ||
      (filtered.length > 0 && filtered[0].id === 'agencyName')
    ) {
      data = {
        where: {
          advance: { $or: ['true', null] },
          createdAt: {
            $gt: moment().subtract(4, 'months').startOf('day'),
          },
        },
        agencies: {
          options: {
            attributes: ['id', 'isActiveAgency', 'name'],
          },
        },
        payments: true,
        options: {
          count: true,
          distinct: true,
        },
      };
      if (agencyId) data.agencies.where = { id: agencyId };
      if (canceled)
        data.where = { ...data.where, status: 'cancelada' };
      else
        data.where = { ...data.where, status: { $not: 'cancelada' } };
      if (filtered.length) {
        filtered.forEach((filter) => {
          if (filter.id === 'name')
            data.where['nombre'] = {
              $iLike: `%${filter.value}%`,
            };
          else if (filter.id === 'agencyName')
            data.agencies.where = {
              ...data.agencies.where,
              name: { $iLike: `%${filter.value}%` },
            };
          else if (filter.id === 'created')
            data.where['createdAt'] = formatDatesForFilter(
              filter.value,
            );
          else if (filter.id === 'date')
            data.payments = {
              where: {
                date: formatDatesForFilter(filter.value),
              },
            };
          else
            data.where[filter.id] = { $iLike: `%${filter.value}%` };
        });
      }
      if (
        asesorRoles.includes(userRole) ||
        partsAsesorRoles.includes(userRole) ||
        salesAdvisorRoles.includes(userRole)
      )
        data.where = {
          ...data.where,
          adminID,
        };
      if (firstDate && secondDate)
        // prettier-ignore
        data.where.createdAt = formatDatesForFilter(firstDate, secondDate);
      fullOrders = yield call(requestHandler, {
        method: 'POST',
        path: '/paymentOrder/getAll',
        data,
      });
    }
    orders.fullOrders = fullOrders;
    orders.pages = yield call(Math.ceil, orders.count / pageSize);
    yield put(getPaymentOrdersSuccess({ orders }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba obtener las órdenes de pago.
      Intente de nuevo, si el error persiste intente más tarde.`,
    };
    yield put(getPaymentOrdersFail({ message }));
    console.log('error in login user saga', e);
  }
}

export function* getPaymentOrdersForDashboardSaga(action) {
  try {
    const agencyId = yield select(makeSelectAgencyId());
    const data = {
      payments: true,
      options: {
        attributes: ['status'],
        order: [['updatedAt', 'DESC']],
      },
    };
    data.agencies = agencyId ? { where: { id: agencyId } } : true;
    data.where = { status: { $not: 'cancelada' } };
    const orders = yield call(requestHandler, {
      method: 'POST',
      path: '/paymentOrder/getAll',
      data,
    });
    yield put(getPaymentOrdersSuccess({ orders }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba obtener las órdenes de pago.
      Intente de nuevo, si el error persiste intente más tarde.`,
    };
    yield put(getPaymentOrdersFail({ message }));
    console.log('error in login user saga', e);
  }
}

export function* getPaymentOrderDetailSaga(action) {
  try {
    const { orderID, agencyID } = action.payload;
    const data = {
      where: {
        orderID,
        agencyID,
      },
      surTaxes: {
        options: {
          attributes: [
            'id',
            '3',
            '6',
            '9',
            '12',
            '18',
            'typeOfPromotion',
          ],
        },
      },
      AMEXSurTaxes: {
        options: {
          attributes: [
            'id',
            '3',
            '6',
            '9',
            '12',
            '18',
            'typeOfPromotion',
          ],
        },
      },
      detailPromotionByAgencies: {
        options: {
          attributes: [
            'id',
            'surTaxID',
            'amexSurTaxID',
            'agencyID',
            'serviceOrderID',
            'paymentOrderID',
          ],
        },
      },
      agencies: true,
      payments: true,
    };
    const order = yield call(requestHandler, {
      method: 'POST',
      path: '/paymentOrder/getOne',
      data,
    });

    if (order.type === 'pulled_apart') {
      const orderData = {
        where: {
          id: order.vehicleID,
        },
      };
      const vehicle = yield call(requestHandler, {
        method: 'POST',
        path: '/vehicle/getOne',
        data: orderData,
      });
      order.vehicle = vehicle;
    }
    yield put(getPaymentOrderDetailSuccess({ order }));
    yield put(
      getLogs({
        object: 'paymentOrders',
        objectID: order.id,
      }),
    );
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba obtener información de la orden de pago.
      Intente de nuevo, si el error persiste intente más tarde.`,
    };
    yield put(getPaymentOrderDetailFail({ message }));
    console.log('error in get order detail user saga', e);
  }
}

export function* getPaymentsForOrderDetailSaga(action) {
  const { orderID } = action.payload;
  try {
    const data = {
      where: {
        paymentOrderID: orderID,
        status: 'DONE',
      },
    };
    const order = yield call(requestHandler, {
      method: 'POST',
      path: '/payment/getAll',
      data,
    });
    yield put(getPaymentsForOrderDetailSuccess(order));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba obtener los pagos de la orden ${orderID}.
      Intente de nuevo, si el error persiste intente más tarde.`,
    };
    yield put(getPaymentsForOrderDetailFail({ message }));
    console.log('error in get order detail user saga', e);
  }
}

export function* createPaymentOrderSaga(action) {
  const {
    agencyID,
    vin,
    name,
    email,
    products,
    reference,
    number,
    provisional,
    type,
    clientAccount,
    clientID,
    createdBy,
  } = action.payload;
  const agencyId = yield select(makeSelectAgencyId());
  let tempagencyID = agencyId ? agencyId : agencyID;
  try {
    const data = {
      agencyID: tempagencyID,
      vin,
      nombre: name,
      email,
      products,
      reference,
      phoneNumber: number,
      provisional,
      type,
      clientAccount,
      clientID,
      createdBy,
    };
    const paymentOrder = yield call(requestHandler, {
      method: 'POST',
      path: '/paymentOrder/create',
      data,
    });
    if (paymentOrder?.moreThan1M) {
      const message = {
        title: '¡Error!',
        desc: ` ${paymentOrder.message}`,
      };
      const redirect = '/paymentorders';
      yield put(
        createPaymentOrderFail({
          message,
          redirect,
        }),
      );
    } else {
      const message = {
        title: '¡Éxito!',
        desc: `La orden de pago ${reference} ha sido creada correctamente.`,
      };
      const redirect = '/paymentorders';
      yield put(
        createPaymentOrderSuccess({
          message,
          redirect,
        }),
      );
    }
  } catch (e) {
    let message = '';
    if (e.code === 463)
      message = {
        title: 'Error',
        desc: `El id ${reference} de la orden ya existe, por favor elije otro`,
      };
    else if (e.code in errors)
      message = {
        title: 'Error',
        desc: errors[e.code],
      };
    else
      message = {
        title: 'Error',
        desc: `Ocurrió un error cuando se intentaba crear la orden de pago.
        Intente de nuevo, si el error persiste intente más tarde.`,
      };
    yield put(createPaymentOrderFail({ message }));
    console.log('error in get order detail user saga', e);
  }
}

export function* updatePaymentOrderSaga(action) {
  try {
    const { status, id, reference } = action.payload;
    const data = {
      id,
      status,
    };
    const paymentOrder = yield call(requestHandler, {
      method: 'POST',
      path: '/paymentOrder/update',
      data,
    });
    const message = {
      title: '¡Éxito!',
      desc: `La orden de pago ${reference} ha sido cancelada.`,
    };
    yield put(
      updatePaymentOrderSuccess({
        message,
        paymentOrder,
      }),
    );
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba cancelar la orden de pago.
      Intente de nuevo, si el error persiste intente más tarde.`,
    };
    yield put(updatePaymentOrderFail({ message }));
    console.log('error canceling payment order: ', e);
  }
}

export function* sendEmailLinkPaymentOrderSaga(action) {
  try {
    const { email, paymentOrderID, receipt } = action.payload;
    const data = {
      email: email ? email : undefined,
      paymentOrderID,
      type: 'payment',
      receipt,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/paymentOrder/mail',
      data,
      type: 'payment',
    });
    const message = {
      title: '¡Enviado!',
      desc: `El correo se envió a ${email}.`,
    };
    yield put(
      sendEmailLinkPaymentOrderSuccess({
        message,
      }),
    );
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba enviar el correo.
      Intente de nuevo, si el error persiste intente más tarde.`,
    };
    yield put(sendEmailLinkPaymentOrderFail({ message }));
    console.log('error sending receipt payment order: ', e);
  }
}

export function* applyAdvancePaymentOrderSaga(action) {
  try {
    const { orderID, agencyID, ...rest } = action.payload;
    const data = {
      ...rest,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/paymentOrder/addExternalPayment',
      data,
    });
    const message = {
      title: '¡Aplicado!',
      desc: `Anticipo aplicado correctamente`,
    };
    yield put(
      applyAdvancePaymentOrderSuccess({
        message,
      }),
    );
    yield call(getPaymentOrderDetailSaga, {
      payload: { orderID, agencyID },
    });
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba enviar el correo.
      Intente de nuevo, si el error persiste intente más tarde.`,
    };
    yield put(
      applyAdvancePaymentOrderFail({
        message,
      }),
    );
    console.log('error sending receipt payment order: ', e);
  }
}

export function* createOrUpdateSurtaxesForPaymentOrdersSaga(action) {
  try {
    const {
      amex,
      visa,
      paymentOrderID,
      agencyID,
      orderID,
      detailPromotionId,
    } = action.payload;
    const dataVisa = {
      paymentOrderID,
      detailPromotionId,
      ...visa,
    };
    const dataAmex = {
      paymentOrderID,
      detailPromotionId,
      ...amex,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/surTax/update',
      data: dataVisa,
    });
    yield call(requestHandler, {
      method: 'POST',
      path: '/AMEXSurTax/update',
      data: dataAmex,
    });
    const message = {
      title: 'Promociones actualizadas ',
      desc: `Las promociones se han actualizado correctamente.`,
    };
    yield call(getPaymentOrderDetailSaga, {
      payload: {
        orderID,
        agencyID,
      },
    });
    yield put(
      createOrUpdateSurtaxesForPaymentOrdersSuccess({ message }),
    );
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se trataba de actualizar la promoción.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(
      createOrUpdateSurtaxesForPaymentOrdersFail({ message }),
    );
    console.log('error in upload SurTaxes saga', e);
  }
}
