import { closeModals } from '../modals/modalsSlice';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { logout } from '../auth/authSlice';

const advancesSlice = createSlice({
  name: 'advances',
  initialState,
  reducers: {
    getAdvances: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getCancelAdvances: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getCancelAdavancesSuccess: {
      reducer(state, action) {
        state.loading = false;
        state.cancelAdvances = action.payload.advancesCancel;
        state.tablePages = action.payload.advances?.pages;
        state.toDownload = null;
      },
    },
    getAdvancesSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.fromdb = action.payload.advancesNoCancel;
        state.tablePages = action.payload.advances?.pages;
        state.toDownload = null;
        state.fullOrders = action.payload.advances.fullOrders;
      },
    },
    getAdvancesFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.error = action.payload.error;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    getAdvanceDetail: {
      reducer(state, action) {
        state.isLoading = true;
        state.advanceDetails = null;
      },
    },
    getAdvanceDetailSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.advanceDetails = action.payload.advance;
      },
    },
    getAdvanceDetailFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    createAdvance: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    createAdvanceSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          ...action.payload,
        };
      },
    },
    createAdvanceFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    updateAdvance: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    updateAdvancesuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.advanceDetails = action.payload.advance;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    updateAdvanceFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    sendEmailLinkAdvance: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    sendEmailLinkAdvancesuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    sendEmailLinkAdvanceFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    createExternalAdvance: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    createExternalAdvanceSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          ...action.payload,
        };
      },
    },
    createExternalAdvanceFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    closeModals: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = initialState.successModal;
        state.errorModal = initialState.errorModal;
      },
    },
  },
  extraReducers: {
    [logout]: (state, action) => {
      return initialState;
    },
    [closeModals]: (state, action) => {
      state.isLoading = false;
      state.successModal = initialState.successModal;
      state.errorModal = initialState.errorModal;
    },
  },
});

export const {
  getAdvances,
  getCancelAdvances,
  getCancelAdavancesSuccess,
  getAdvancesSuccess,
  getAdvancesFail,
  getAdvanceDetail,
  getAdvanceDetailSuccess,
  getAdvanceDetailFail,
  createAdvance,
  createAdvanceSuccess,
  createAdvanceFail,
  updateAdvance,
  updateAdvancesuccess,
  updateAdvanceFail,
  sendEmailLinkAdvance,
  sendEmailLinkAdvancesuccess,
  sendEmailLinkAdvanceFail,
  createExternalAdvance,
  createExternalAdvanceSuccess,
  createExternalAdvanceFail,
} = advancesSlice.actions;

export default advancesSlice.reducer;
