import MomentUtils from '@date-io/moment';
import {
  Button,
  Backdrop,
  CircularProgress,
  Grid,
  Checkbox,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import moment from 'moment';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import {
  makeSelectAgenciesFromdb,
  makeSelectAgencyDetailsFromState,
} from 'app/features/agencies/selectors';

import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';

import Input from 'app/components/Input';
import { connect } from 'react-redux';
import { selectKarloPromotions } from 'app/features/karloPromotions/selectors';
import { createStructuredSelector } from 'reselect';
import {
  createPromotion,
  handleClosePromoModals,
} from '../../../karloPromotions/karloPromotionsSlice';
import { injectIntl } from 'react-intl';
import { selectAuth } from 'app/features/auth/selectors';
import { makeSelectUserRoleFromState } from '../../../auth/selectors';
import { selectPaymentOrders } from 'app/features/paymentOrders/selectors';
import { useForm } from 'react-hook-form';
import Modal from '../../../../components/Modal';
import { withRouter } from 'react-router';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(5, 5, 3, 5),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5, 5, 3, 5),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(5, 10, 3, 10),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(5, 10, 3, 10),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.5) !important',
  },
  title: {
    textAlign: 'left',
    color: '#1c1c1c',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  switch: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textFieldAddProducts: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    width: '50%',
  },
  margin: {
    margin: theme.spacing(1),
  },
  client: {
    padding: theme.spacing(0),
    marginTop: theme.spacing(1),
  },
  productsContainer: {
    marginBottom: theme.spacing(3),
  },
  submitButtonWrapper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  formGroupSwitch: {
    alignItems: 'center',
  },
}));

function AddPromotion(props) {
  const classes = useStyles();
  const { createPromotion, users } = props;
  const [thumbnailImage, setThumbnailImage] = useState('');
  const [descriptionImage, setDescriptionImage] = useState('');
  const [hasExpiracy, setExpiracy] = useState(false);
  const [vigencyDate, setVigency] = useState(new Date());
  const [expirationDate, setExpiration] = useState(null);
  const { handleSubmit, register, errors, control, watch } = useForm({
    defaultValues: {
      provisional: false,
    },
  });

  function handleClosePromoModals() {
    props.handleClosePromoModals();
    props.history.push('/karloPromotions');
  }

  const handleThumbnailChangeStatus = ({ meta, file }, status) => {
    if (status == 'done') {
      setThumbnailImage(file);
    }
  };

  const handleDescriptionChangeStatus = ({ meta, file }, status) => {
    if (status == 'done') {
      setDescriptionImage(file);
    }
  };

  const handleExtraValidation = (formData) => {
    const userId = users?.user?.id;
    createPromotion({
      ...formData,
      vigencyDate: vigencyDate,
      expirationDate: expirationDate,
      thumbnailImg: thumbnailImage,
      descriptionImg: descriptionImage,
      createdByUserId: userId,
    });
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = props.promotions;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message } = props.promotions[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={handleClosePromoModals}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={handleClosePromoModals}
          />
        );
      }
      return null;
    } catch (e) {
      handleClosePromoModals();
      return null;
    }
  };

  return (
    <div>
      {renderFeedBackModal()}
      {props.promotions.isLoading && (
        <Backdrop
          className={classes.backdrop}
          open={props.promotions.isLoading}
        >
          <CircularProgress />
        </Backdrop>
      )}
      <Paper className={classes.paper}>
        <form
          onSubmit={handleSubmit(handleExtraValidation)}
          noValidate
          autoComplete="off"
        >
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.client}>
              <Typography variant="h6">
                Información de la promoción
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                required
                ref={register({
                  required: true,
                })}
                name="title"
                label={'Titulo de promoción'}
                className={classes.textField}
                error={Boolean(errors.reference)}
                // helperText="Introduce el número de referencia"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                required
                ref={register({
                  required: true,
                })}
                name="subtitle"
                label={'Subtitulo de promoción'}
                className={classes.textField}
                error={Boolean(errors.reference)}
                // helperText="Introduce el número de referencia"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Input
                required
                ref={register({
                  required: true,
                })}
                name="description"
                label={'Descripción de promoción'}
                className={classes.textField}
                error={Boolean(errors.reference)}
                // helperText="Introduce el número de referencia"
              />
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={6}>
              <Checkbox
                className={classes.checkbox}
                checked={hasExpiracy}
                onChange={() => setExpiracy(!hasExpiracy)}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
              <Typography>Tiene vigencia</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MuiPickersUtilsProvider
                utils={MomentUtils}
                locale={'es'}
              >
                <DatePicker
                  autoOk
                  fullWidth
                  disablePast
                  disableToolbar
                  name="startDate"
                  // error={Boolean(errors.vigencyDate)}
                  className={classes.textField}
                  format="YYYY/MM/DD"
                  label="Fecha de inicio"
                  variant="inline"
                  inputVariant="outlined"
                  value={vigencyDate}
                  onChange={(date) =>
                    setVigency(moment(date).format('YYYY/MM/DD'))
                  }
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MuiPickersUtilsProvider
                utils={MomentUtils}
                locale={'es'}
              >
                <DatePicker
                  autoOk
                  fullWidth
                  disablePast
                  disableToolbar
                  name="expirationDate"
                  disabled={hasExpiracy == false ? true : false}
                  // error={Boolean(errors.vigencyDate)}
                  className={classes.textField}
                  format="YYYY/MM/DD"
                  label="Fecha de vigencia"
                  variant="inline"
                  inputVariant="outlined"
                  value={expirationDate}
                  onChange={(date) =>
                    setExpiration(moment(date).format('YYYY/MM/DD'))
                  }
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.client}>
              <Typography variant="h6" gutterBottom>
                {'Archivos adjuntos'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Input
                ref={register({
                  required: false,
                })}
                name="videoUrl"
                label={'Video'}
                className={classes.textField}
                error={Boolean(errors.reference)}
              />
              <p className="text-align-dialog">
                Solamente se aceptan imágenes JPEG, PNG y SVG de
                máximo 4mb
              </p>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <div className={classes.buttonGroup}>
                  <p className="text-align-dialog">
                    Cargar una imagen para el thumbnail
                  </p>
                  <Dropzone
                    // getUploadParams={getUploadParams}
                    onChangeStatus={handleThumbnailChangeStatus}
                    maxFiles={1}
                    submitButtonContent={null}
                    styles={{
                      dropzone: { minHeight: 200, maxHeight: 250 },
                    }}
                    // onSubmit={handleSubmitDropzone}
                    accept="image/*"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={classes.buttonGroup}>
                  <p className="text-align-dialog">
                    Cargar una imagen para el promoción
                  </p>
                  <Dropzone
                    // getUploadParams={getUploadParams}
                    onChangeStatus={handleDescriptionChangeStatus}
                    maxFiles={1}
                    submitButtonContent={''}
                    styles={{
                      dropzone: { minHeight: 200, maxHeight: 250 },
                    }}
                    // onSubmit={handleSubmitDropzone}
                    accept="image/*"
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.submitButtonWrapper}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={props.promotions.isLoading}
            >
              Guardar promoción
            </Button>
          </div>
        </form>
      </Paper>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  promotions: selectKarloPromotions,
  users: selectAuth,
});

const mapDispatchToProps = {
  // createPaymentOrder: createPaymentOrder,
  handleClosePromoModals,
  createPromotion,
};

export default withRouter(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(AddPromotion),
  ),
);
