export const initialState = {
  fromdb: null,
  modal: {
    title: null,
    show: false,
    message: null,
    type: null,
  },
  isLoading: false,
  error: null,
  pages: 1,
};
