import { call } from 'redux-saga/effects';

export function* closeModalsAndRedirectModalsSaga(action) {
  if (!action.payload) return;
  const { history, redirect } = action.payload;
  try {
    if (redirect && history) yield call(history.push, redirect);
  } catch (e) {
    console.log('error: ', e);
  }
}
