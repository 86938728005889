import {
  Button,
  DialogContent,
  CircularProgress,
  Backdrop,
  CardMedia,
  Grid,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';

import Input from 'app/components/Input';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import { selectAuth } from 'app/features/auth/selectors';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router';
import { selectKarloAdvices } from 'app/features/karloAdvices/selectors';
import Clear from '@material-ui/icons/Clear';

import Modal from '../../../../components/Modal';
import {
  getAdviceDetail,
  deleteTip,
  uploadThumbnail,
  uploadDescription,
  updateTip,
  handleCloseTipsModals,
} from 'app/features/karloAdvices/karloAdvicesSlice';
import { green } from '@material-ui/core/colors';
import { makeSelectAdviceDetails } from 'app/features/karloAdvices/selectors';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(5, 5, 3, 5),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5, 5, 3, 5),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(5, 10, 3, 10),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(5, 10, 3, 10),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.5) !important',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  title: {
    textAlign: 'left',
    color: '#1c1c1c',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  switch: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textFieldAddProducts: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    width: '50%',
  },
  margin: {
    margin: theme.spacing(1),
  },
  client: {
    padding: theme.spacing(0),
    marginTop: theme.spacing(1),
  },
  productsContainer: {
    marginBottom: theme.spacing(3),
  },
  submitButtonWrapper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  formGroupSwitch: {
    alignItems: 'center',
  },
  image: {
    height: 200,
    width: 'auto',
    alignItems: 'center',
    marginTop: 30,
    marginBottom: 30,
  },
  media: {
    width: '100%',
    alignItems: 'center',
  },
}));

function EditAdvice(props) {
  const classes = useStyles();
  const {
    updateTip,
    adviceDetail,
    users,
    deleteTip,
    uploadThumbnail,
    uploadDescription,
    setActionButton,
  } = props;
  const [thumbnailImage, setThumbnailImage] = useState('');
  const [openDelete, setOpenDelete] = React.useState(false);
  const [descriptionImage, setDescriptionImage] = useState('');
  const [tipId, setTipId] = React.useState(0);
  const [openThumbnail, setOpenThumbnail] = React.useState(false);
  const [openDescription, setOpenDescription] = React.useState(false);
  const { handleSubmit, register, errors, control, reset } = useForm({
    defaultValues: {
      provisional: false,
    },
  });

  function handleCloseTipsModals() {
    props.handleCloseTipsModals();
  }

  function handleCloseDeleteModal() {
    props.handleCloseTipsModals();
    props.history.push('/advices');
  }

  useEffect(() => {
    setActionButton(
      <div>
        <Button
          variant="contained"
          className="btn btn-label-danger btn-bold btn-sm btn-icon-h kt-margin-l-10"
          onClick={() => {
            handleOpenDelete();
            setTipId(adviceDetail.id);
          }}
        >
          Eliminar consejo
          <Clear className="kt-margin-l-10" />
        </Button>
      </div>,
    );
    if (adviceDetail == null) {
      console.log('');
    } else {
      reset({
        title: adviceDetail.title,
        subtitle: adviceDetail.subtitle,
        description: adviceDetail.description,
        videoUrl: adviceDetail.videoUrl,
      });
    }
  }, [adviceDetail]);

  useEffect(() => {
    let data = {
      id: props.match.params.id,
    };
    props.getAdviceDetail(data);
  }, []);

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setOpenThumbnail(false);
    setOpenDescription(false);
  };

  const handleThumbnailChangeStatus = ({ meta, file }, status) => {
    if (status == 'done') {
      setThumbnailImage(file);
    }
  };

  const handleThumbnail = () => {
    uploadThumbnail({
      file: thumbnailImage,
      id: tipId,
    });
    handleCloseDelete();
  };

  const handleDescription = () => {
    uploadDescription({
      file: descriptionImage,
      id: tipId,
    });
    handleCloseDelete();
  };

  const handleDescriptionChangeStatus = ({ meta, file }, status) => {
    if (status == 'done') {
      setDescriptionImage(file);
    }
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = props.advices;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message } = props.advices[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={
              message.title == 'Eliminado'
                ? handleCloseDeleteModal
                : handleCloseTipsModals
            }
            onClick={
              message.title == 'Eliminado'
                ? handleCloseDeleteModal
                : handleCloseTipsModals
            }
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
          />
        );
      }
      return null;
    } catch (e) {
      handleCloseTipsModals();
      return null;
    }
  };

  const handleExtraValidation = (formData) => {
    const userId = users?.user?.id;
    updateTip({
      ...formData,
      id: adviceDetail.id,
      createdByUserId: userId,
    });
  };

  const handleDelete = () => {
    deleteTip({ tipId });
    handleCloseDelete();
  };

  const renderConfirmationDelete = () => {
    return (
      <Modal
        open={openDelete}
        type="warning"
        closeModal={handleCloseDelete}
        dialogTitle="¿Seguro que deseas eliminar el consejo?"
        dialogText="Si continúas no habrá manera de recuperar la información
            que haya sido borrada."
        actionButtonText="Eliminar consejo"
        onClick={handleDelete}
      />
    );
  };

  const renderUploadThumbnail = () => {
    return (
      <Modal
        open={openThumbnail}
        closeModal={handleCloseDelete}
        dialogTitle="Actualizar thumbnail"
        actionButtonText="Actualizar thumbnail"
        onClick={handleThumbnail}
        dialogChildren={
          <div className={classes.buttonGroup}>
            <p className="text-align-dialog">
              Cargar una imagen para el thumbnail
            </p>
            <Dropzone
              // getUploadParams={getUploadParams}
              onChangeStatus={handleThumbnailChangeStatus}
              maxFiles={1}
              accept="image/jpeg, image/png"
              submitButtonContent={null}
              styles={{
                dropzone: { minHeight: 200, maxHeight: 250 },
              }}
              // maxSizeBytes={1000000}
              // maxSize={1000000}
              // onSubmit={handleSubmitDropzone}
            />
          </div>
        }
      />
    );
  };

  const renderUploadDescription = () => {
    return (
      <Modal
        open={openDescription}
        closeModal={handleCloseDelete}
        dialogTitle="Actualizar consejo"
        actionButtonText="Actualizar consejo"
        onClick={handleDescription}
        dialogChildren={
          <div className={classes.buttonGroup}>
            <p className="text-align-dialog">
              Cargar una imagen para el consejo
            </p>
            <Dropzone
              // getUploadParams={getUploadParams}
              onChangeStatus={handleDescriptionChangeStatus}
              maxFiles={1}
              submitButtonContent={null}
              styles={{
                dropzone: { minHeight: 200, maxHeight: 250 },
              }}
              // onSubmit={handleSubmitDropzone}
              accept="image/*"
            />
          </div>
        }
      />
    );
  };

  return (
    <div>
      {renderUploadThumbnail()}
      {renderUploadDescription()}
      {renderConfirmationDelete()}
      {renderFeedBackModal()}
      {props.advices.isLoading && (
        <Backdrop
          className={classes.backdrop}
          open={props.advices.isLoading}
        >
          <CircularProgress />
        </Backdrop>
      )}
      <Paper className={classes.paper}>
        <form
          onSubmit={handleSubmit(handleExtraValidation)}
          noValidate
          autoComplete="off"
        >
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.client}>
              <Typography variant="h6">
                Información del consejo
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                required
                ref={register({
                  required: true,
                })}
                name="title"
                label={'Titulo de consejo'}
                className={classes.textField}
                error={Boolean(errors.reference)}
                // helperText="Introduce el número de referencia"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                required
                ref={register({
                  required: true,
                })}
                name="subtitle"
                label={'Subtitulo de consejo'}
                className={classes.textField}
                error={Boolean(errors.reference)}
                // helperText="Introduce el número de referencia"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Input
                required
                ref={register({
                  required: true,
                })}
                maxlength={10}
                name="description"
                label={'Descripción de consejo'}
                className={classes.textField}
                error={Boolean(errors.reference)}
                // helperText="Introduce el número de referencia"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Input
                ref={register({
                  required: false,
                })}
                name="videoUrl"
                label={'Video'}
                className={classes.textField}
                error={Boolean(errors.reference)}
              />
            </Grid>
          </Grid>
          <div className={classes.submitButtonWrapper}>
            <Button
              variant="contained"
              color="primary"
              disabled={props.advices.isLoading}
              type="submit"
            >
              Actualizar consejo
            </Button>
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.client}>
              <Typography variant="h6" gutterBottom>
                {'Archivos adjuntos'}
              </Typography>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <div className={classes.buttonGroup}>
                  <p className="text-align-dialog">
                    Imagen para el thumbnail
                  </p>
                  <DialogContent>
                    {adviceDetail?.thumbnailImage == null ? (
                      <div>
                        <Typography variant="h4" gutterBottom>
                          No hay imagen para el thumbnail
                        </Typography>
                        <div className={classes.submitButtonWrapper}>
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={props.advices.isLoading}
                            onClick={() => {
                              setOpenThumbnail(true);
                              setTipId(adviceDetail.id);
                            }}
                          >
                            Agregar imagen thumbnail
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <CardMedia
                          className={classes.media}
                          title="Imagen Thumbnail"
                        >
                          <img
                            src={adviceDetail.thumbnailImage}
                            alt="logo"
                            className={classes.image}
                          />
                        </CardMedia>
                        <div className={classes.submitButtonWrapper}>
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={props.advices.isLoading}
                            onClick={() => {
                              setOpenThumbnail(true);
                              setTipId(adviceDetail.id);
                            }}
                          >
                            Actualizar imagen thumbnail
                          </Button>
                        </div>
                      </div>
                    )}
                  </DialogContent>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={classes.buttonGroup}>
                  <p className="text-align-dialog">
                    Imagen para el consejo
                  </p>
                  <DialogContent>
                    {adviceDetail?.descriptionImage == null ? (
                      <div>
                        <Typography variant="h4" gutterBottom>
                          No hay imagen para el consejo
                        </Typography>
                        <div className={classes.submitButtonWrapper}>
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={props.advices.isLoading}
                            onClick={() => {
                              setOpenDescription(true);
                              setTipId(adviceDetail.id);
                            }}
                          >
                            Agregar imagen consejo
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <CardMedia
                          className={classes.media}
                          title="Imagen Consejo"
                        >
                          <img
                            src={adviceDetail.descriptionImage}
                            alt="logo"
                            className={classes.image}
                          />
                        </CardMedia>
                        <div className={classes.submitButtonWrapper}>
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={props.advices.isLoading}
                            onClick={() => {
                              setOpenDescription(true);
                              setTipId(adviceDetail.id);
                            }}
                          >
                            Actualizar imagen consejo
                          </Button>
                        </div>
                      </div>
                    )}
                  </DialogContent>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  advices: selectKarloAdvices,
  users: selectAuth,
  adviceDetail: makeSelectAdviceDetails,
});

const mapDispatchToProps = {
  handleCloseTipsModals,
  deleteTip,
  uploadThumbnail,
  uploadDescription,
  getAdviceDetail,
  updateTip,
};

export default withRouter(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(EditAdvice),
  ),
);
