import {
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
} from '@material-ui/core';

import React from 'react';
import { formatNumber } from 'app/utils';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  divider: {
    width: '100%',
  },
  title: {
    textAlign: 'left',
    color: '#1c1c1c',
  },
  content: {
    textAlign: 'left',
  },
  paperOps: {
    width: '100%',
    margin: theme.spacing(2, 0),
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
  },
  item1: {
    textAlign: 'left',
  },
  item2: {
    textAlign: 'right',
  },
}));

const Products = ({
  title,
  products,
  description = 'concepto',
  price = 'total',
}) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paperOps}>
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      <DialogContent className={classes.content}>
        <>
          {Array.isArray(products) &&
            products.map((operation, idx) => {
              return (
                <div key={idx}>
                  <Divider className={classes.divider} />
                  <List
                    component="nav"
                    aria-label="Main mailbox folders"
                    className={classes.itemBlock}
                  >
                    <ListItem>
                      <ListItemText
                        primary={operation[description]}
                        className={classes.item1}
                      />
                      <ListItemText
                        primary={formatNumber(operation[price])}
                        className={classes.item2}
                      />
                    </ListItem>
                  </List>
                  <Divider className={classes.divider} />
                </div>
              );
            })}
        </>
      </DialogContent>
    </Paper>
  );
};

export default Products;
