export const initialState = {
  fromdb: null,
  tablePages: 0,
  paymentsTPVfromdb: [],
  paymentsTPVFiservfromdb: [],
  paymentsOrdersFromdb: null,
  creditOrdersFromdb: null,
  paymentsForDownload: null,
  paymentsCompleteForDownload: null,
  paymentsSearched: null,
  paymentDetails: null,
  successModal: {
    show: false,
    message: null,
  },
  errorModal: {
    show: false,
    message: null,
  },
  isLoading: false,
  error: null,
  pdfData: null,
};
