import { createAction, createSlice } from '@reduxjs/toolkit';

import { initialState } from './initialState';

export const closeModals = createAction('CLOSE_MODALS');

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {},
});

// export const {} = modalsSlice.actions;

export default modalsSlice.reducer;
