import {
  DialogTitle,
  Grid,
  Paper,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
  formatDate,
  formatSingleDate,
  formatNumber,
  getNested,
} from '../../../../../utils';
import {
  makeSelectAgencies,
  selectAgencies,
} from 'app/features/agencies/selectors';
import {
  makeSelectDepositDetailsFromState,
  selectDepositsStandAlone,
} from 'app/features/standAlone/deposits/selectors';

import moment from 'moment';
import ButtonExportExcel from 'app/components/ButtonExportExcel';
import Modal from '../../../../../components/Modal';
import RecentActivities from '../../../../../components/RecentActivities';
import ShouldItRender from 'app/components/ShouldItRender';
import SplashScreen from '../../../../../partials/layout/SplashScreen';
import { Table } from 'react-bootstrap';
import { closeModals } from '../../../../modals/modalsSlice';
import { connect } from 'react-redux';
import { getPaymentsDetailStandAlone } from 'app/features/standAlone/payments/depositsSlice';
import { injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { makeSelectDepositsLogs } from '../../../../logs/selectors';
import { makeSelectUserRoleFromState } from 'app/features/auth/selectors';
import { withRouter } from 'react-router';

const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  title: {
    textAlign: 'left',
    color: '#1c1c1c',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  rightContainer: {
    flexDirection: 'column',
  },
  tableTitle: {
    fontWeight: 401,
  },
  clickeableTR: {
    cursor: 'pointer',
  },
  chargebackRow: {
    backgroundColor: '#ffcccb',
  },
  table: {
    width: '100%',
    overflowX: 'auto',
  },
}));

export const DetailPaymentsStandAlone = (props) => {
  const classes = useStyles();
  const {
    closeModals,
    deposits,
    paymetnDetails,
    getPaymentsDetailStandAlone,
    setLeftSideComponent,
    role,
    match,
    history,
  } = props;
  const [totalAmount, setTotalAmount] = useState(false);
  const [hasSpeiPayments, setHasSpeiPayments] = useState(false);
  const [paymentsData, setPaymentsData] = useState([]);

  useEffect(() => {
    setLeftSideComponent(
      <div className="kt-subheader__main">
        <div>
          <h3 className="kt-subheader__title">
            Detalle del pago TPV Fiserv
          </h3>
        </div>
      </div>,
    );
    let data = {
      id: match.params.id,
      agencyID: match.params.agencyId,
    };
    getPaymentsDetailStandAlone(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (paymetnDetails) {
      const hasSpeiPayments =
        paymetnDetails?.SPEIPayments?.length > 0;
      setHasSpeiPayments(hasSpeiPayments);
      loadPaymentsInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymetnDetails]);

  const loadPaymentsInfo = () => {
    const standAlonePayments =
      paymetnDetails?.depositsDetailsStandAlones
        ? paymetnDetails?.depositsDetailsStandAlones
        : {};
    if (standAlonePayments.length > 0) {
      const exportDataTemp = [];
      let totalAmountTemp = 0;

      standAlonePayments.map((standAlonePayment) => {
        totalAmountTemp += standAlonePayment?.total;
        let dateBpPayment =
          standAlonePayment?.paymentsStandAlone.transactionDate;
        exportDataTemp.push({
          AGENCIA: paymetnDetails?.agency?.name,
          'ID AGENCIA': paymetnDetails?.agency?.id,
          DEPOSITOR: paymetnDetails?.depositor,
          'FECHA DE PAGO': dateBpPayment
            ? formatDate(dateBpPayment)
            : '',
          'MONTO DE TRANSACCIÓN': formatNumber(
            standAlonePayment.paymentsStandAlone.transactionAmount,
          ),
        });
      });
      setPaymentsData(exportDataTemp);
      setTotalAmount(totalAmountTemp);
    }
  };

  const handleCloseModals = () => {
    closeModals();
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = deposits;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message } = deposits[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={handleCloseModals}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={handleCloseModals}
          />
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  const renderCardDetails = () => {
    return (
      <Paper className={classes.paper}>
        <DialogTitle className={classes.title}>
          Resumen del pago
        </DialogTitle>
        <Table bordered className={classes.tables}>
          <tbody>
            {paymetnDetails.paymentType === 'OXXO' ||
            paymetnDetails.paymentType === 'SPEI' ||
            paymetnDetails.external ? null : (
              <>
                <tr>
                  <td className={classes.tableTitle}>NOMBRE DBA</td>
                  <td>{getNested('-', paymetnDetails, 'nameDba')}</td>
                </tr>
                <tr>
                  <td className={classes.tableTitle}>UBICACIÓN ID</td>
                  <td>{paymetnDetails.locationID}</td>
                </tr>
                <tr>
                  <td className={classes.tableTitle}>
                    ID DE TERMINAL
                  </td>
                  <td>{paymetnDetails.terminalID}</td>
                </tr>
                <tr>
                  <td className={classes.tableTitle}>
                    CÓDIGO DE AUTORIZACIÓN
                  </td>
                  <td>{paymetnDetails.authorizationCode}</td>
                </tr>
                <tr>
                  <td className={classes.tableTitle}>
                    DESCRIPCIÓN DE ENTRADA
                  </td>
                  <td>{paymetnDetails.inputDescription}</td>
                </tr>
                <tr>
                  <td className={classes.tableTitle}>
                    DESCRIPCIÓN DE SERVICIO
                  </td>
                  <td>{paymetnDetails.codeService}</td>
                </tr>
                <tr>
                  <td className={classes.tableTitle}>NUM. TARJETA</td>
                  <td>
                    {getNested('-', paymetnDetails, 'lastFour')}
                  </td>
                </tr>
                <tr>
                  <td className={classes.tableTitle}>
                    CÓDIGO DE AUTORIZACIÓN
                  </td>
                  <td>
                    {getNested(
                      '-',
                      paymetnDetails,
                      'transactionAuthorizationCode',
                    )}
                  </td>
                </tr>
              </>
            )}
            {paymetnDetails.bankName && (
              <tr>
                <td className={classes.tableTitle}>BANCO</td>
                <td>{paymetnDetails.bankName}</td>
              </tr>
            )}
            <tr>
              <td className={classes.tableTitle}>
                FECHA DE TRANSACCIÓN
              </td>
              <td>{paymetnDetails.transactionDate}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>MONTO</td>
              <td>
                {formatNumber(paymetnDetails.transactionAmount)}
              </td>
            </tr>
          </tbody>
        </Table>
      </Paper>
    );
  };

  return isEmpty(paymetnDetails) ? (
    <SplashScreen></SplashScreen>
  ) : (
    <div>
      {renderFeedBackModal()}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={8}>
          {renderCardDetails()}
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  deposits: selectDepositsStandAlone(state),
  paymetnDetails: makeSelectDepositDetailsFromState(state),
  depositLogs: makeSelectDepositsLogs(state),
  role: makeSelectUserRoleFromState(state),
  agencies: makeSelectAgencies(state),
});

const mapDispatchToProps = {
  closeModal: closeModals,
  getPaymentsDetailStandAlone: getPaymentsDetailStandAlone,
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(DetailPaymentsStandAlone),
  ),
);
