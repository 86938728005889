import {
  getTokenFirstRequestSuccess,
  logout,
} from 'app/features/auth/authSlice';

export function removeCSSClass(ele, cls) {
  const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
  ele.className = ele.className.replace(reg, ' ');
}

export function addCSSClass(ele, cls) {
  ele.classList.add(cls);
}

export const toAbsoluteUrl = (pathname) =>
  process.env.PUBLIC_URL + pathname;

export function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState();
      const { type } = config.headers;
      if (type) {
        delete config.headers.type;
      } else {
        if (authToken) {
          config.headers.Authorization = authToken;
        }
      }
      return config;
    },
    (err) => Promise.reject(err),
  );

  axios.interceptors.response.use(
    function (response) {
      const {
        auth: { authToken },
      } = store.getState();
      if (
        response.headers.authorization &&
        authToken !== response.headers.authorization
      )
        store.dispatch(
          getTokenFirstRequestSuccess({
            token: response.headers.authorization,
          }),
        );
      return response;
    },
    function (error) {
      console.log(error);
      if (
        error.response.status === 401 ||
        error.response.status === 403
      )
        store.dispatch(logout());
      return Promise.reject(error);
    },
  );
}

export function getDMSName(dms) {
  const formated = {
    magik: 'Magik',
    businesspro: 'Bussiness PRO',
    clearmechanics: 'Clear Mechanics',
    dynamics: 'Dynamics',
    'solomon(camcar)': 'Solomon (Camcar)',
    'solomon(camcar) leon': 'Solomon (Camcar) León',
    GDS: 'GDS',
    ABG: 'ABG',
    'karlo lite': 'Karlo Lite',
    credits: 'Créditos',
    karlo: 'Karlo',
    'ftp-autoline': 'ftp Autoline',
    sia: 'SIA',
    w32: 'W32 PHP',
    sad: 'SAD',
  };
  return formated[dms];
}
