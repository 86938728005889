import React, { useState, useEffect } from 'react';

import {
  Button,
  TextField,
  Grid,
  Typography,
  makeStyles,
  Paper,
  Tooltip,
  ClickAwayListener,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  IconButton,
} from '@material-ui/core';

import {
  Visibility,
  VisibilityOff,
  Replay,
} from '@material-ui/icons';

import MUIAutocompleteAgencies from 'app/components/MUIAutocompleteAgencies';
import Modal from 'app/components/Modal';

import copy from 'copy-to-clipboard';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: theme.spacing(7, 10, 1, 10),
  },
  stepText: {
    marginTop: theme.spacing(3),
  },
  urlBox: {
    padding: theme.spacing(1),
    cursor: 'pointer',
  },
  buttonAgencies: {
    marginBottom: theme.spacing(3),
  },
  submitButton: {
    marginTop: '20px',
  },
}));

const CreateSuperAdmins = (props) => {
  const {
    createUser,
    url,
    id,
    users,
    closeModalsUser,
    handleAssociate,
    supportData,
    resetState,
  } = props;
  const classes = useStyles();
  const [userData, setUserData] = useState({
    name: '',
    email: '',
  });
  const [agencySelected, setAgencySelected] = useState('');
  const [agenciesSelecteds, setAgenciesSelecteds] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);
  const [copyURL, setCopyURL] = useState(false);
  const [createdUser, setCreatedUser] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [password, setPassword] = useState({
    password: '',
    showPassword: false,
  });
  const [repeatPassword, setRepeatPassword] = useState({
    password: '',
    showPassword: false,
  });

  useEffect(() => {
    if (
      supportData?.agencyWithManager &&
      supportData?.agencyWithManager?.length > 1
    ) {
      setShowAlertModal(true);
    }
  }, [supportData.agencyWithManager]);

  const handleClickShowPassword = (typePass) => {
    if (typePass === 'password') {
      setPassword({
        ...password,
        showPassword: !password.showPassword,
      });
    } else {
      setRepeatPassword({
        ...repeatPassword,
        showPassword: !repeatPassword.showPassword,
      });
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangePassword = (typePass, value) => {
    if (typePass === 'password') {
      setPassword({
        ...password,
        password: value,
      });
    } else {
      setRepeatPassword({
        ...repeatPassword,
        password: value,
      });
    }
  };

  const handleHideTooltip = () => {
    setShowTooltip(false);
  };

  const handleCopyText = () => {
    setCopyURL(true);
    setShowTooltip(true);
    copy(url);
  };

  const handleCreateUser = () => {
    setCreatedUser(true);
    const data = {
      agencyID: agencySelected,
      email: userData.email,
      name: userData.name,
      role: 'Super Admin Agencies',
      superadmin: true,
    };
    createUser(data);
  };

  const handleCloseModals = () => {
    closeModalsUser();
  };

  const handleAssociateAgency = () => {
    const data = {
      superAdminID: id,
      password: password.password,
      agencies: agenciesSelecteds,
    };
    handleAssociate(data);
  };

  const handleResetValues = () => {
    setAgencySelected('');
    setPassword({
      password: '',
      showPassword: false,
    });
    setRepeatPassword({
      password: '',
      showPassword: false,
    });
    setCreatedUser(false);
    setUserData({
      name: '',
      email: '',
    });
    resetState();
  };

  const messageAgenciesInUse = () => {
    if (supportData?.agencyWithManager?.length === 1) {
      return `La agencia ${supportData?.agencyWithManager[0]} ya cuentra controlada por uno o varios super administradores, pero se asocio a está cuenta, para poder acceder a ella por favor consute la contraseña de la agencia a los otros super administradores.`;
    } else {
      return `Las agencias ${supportData?.agencyWithManager?.map(
        (agency) => {
          const value = `${agency} `;
          return value;
        },
      )} ya cuentran controladas por uno o varios super administradores, pero fueron asociadas a está cuenta, para poder acceder a ellas por favor consute la contraseña de las agencias a los otros super administradores.`;
    }
  };

  const alertModal = () => {
    return (
      <Modal
        dialogTitle="Agencias en uso"
        dialogText={messageAgenciesInUse()}
        type="warning"
        open={showAlertModal}
        closeModal={() => setShowAlertModal(false)}
        onClick={() => setShowAlertModal(false)}
        actionButtonText="Aceptar"
      />
    );
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = users;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message } = users[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={handleCloseModals}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={handleCloseModals}
          />
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  return (
    <>
      {alertModal()}
      {renderFeedBackModal()}
      <Grid item xs={12}>
        <Typography
          variant="h4"
          gutterBottom
          className={classes.stepText}
        >
          Paso 1: Crear usuario
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          onChange={(e) =>
            setUserData({ ...userData, name: e.target.value })
          }
          fullWidth
          label="Nombre"
          variant="outlined"
          value={userData.name}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          onChange={(e) =>
            setUserData({ ...userData, email: e.target.value })
          }
          fullWidth
          label="Correo"
          variant="outlined"
          value={userData.email}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <MUIAutocompleteAgencies
          textLabel="Agencia default"
          fullWidth={true}
          value={agencySelected}
          onChange={(_, newValue) => {
            setAgencySelected(newValue?.id);
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Button
          className={classes.submitButton}
          disabled={userData.email === '' || userData.name === ''}
          onClick={handleCreateUser}
          variant="contained"
          color="primary"
        >
          Crear super administrador
        </Button>
      </Grid>
      {createdUser && url && (
        <Grid item xs={12}>
          <ClickAwayListener onClickAway={handleHideTooltip}>
            <Tooltip
              className="copied-url-tooltip"
              placement="top"
              onClose={handleHideTooltip}
              open={showTooltip}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title="¡URL copiada al portapapeles!"
            >
              <Paper
                className={classes.urlBox}
                onClick={handleCopyText}
                title="Copea este enlace para activar la cuenta"
              >
                {url}
              </Paper>
            </Tooltip>
          </ClickAwayListener>
        </Grid>
      )}

      {copyURL && (
        <>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              gutterBottom
              className={classes.stepText}
            >
              Paso 2: Añadir agencias
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <MUIAutocompleteAgencies
              textLabel="Agencias"
              multiple={true}
              fullWidth={true}
              onChange={(_, newValue) => {
                setAgenciesSelecteds(newValue);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl
              className={clsx(classes.margin, classes.textField)}
              variant="outlined"
              fullWidth
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Contraseña
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={password.showPassword ? 'text' : 'password'}
                value={password.password}
                onChange={(e) =>
                  handleChangePassword('password', e.target.value)
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        handleClickShowPassword('password')
                      }
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {password.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl
              className={clsx(classes.margin, classes.textField)}
              variant="outlined"
              fullWidth
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Repetir contraseña
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={
                  repeatPassword.showPassword ? 'text' : 'password'
                }
                value={repeatPassword.password}
                onChange={(e) =>
                  handleChangePassword(
                    'repeatPassword',
                    e.target.value,
                  )
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        handleClickShowPassword('repeatPassword')
                      }
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {repeatPassword.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              *Esta contraseña sirve para navegar entre las diferentes
              agencias, puede ser la misma que ya uso en el super
              administrador o puede ser una nueva
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAssociateAgency}
              disabled={
                agenciesSelecteds.length === 0 ||
                (password.password === '' &&
                  repeatPassword.password === '') ||
                password.password !== repeatPassword.password
              }
            >
              Asociar agencias
            </Button>
          </Grid>
          <Grid item xs={12} className={classes.buttonAgencies}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Replay />}
              onClick={handleResetValues}
            >
              Reiniciar valores
            </Button>
          </Grid>
        </>
      )}
    </>
  );
};

export default CreateSuperAdmins;
