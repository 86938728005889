import { call, put } from 'redux-saga/effects';
import {
  createAdvanceServiceOrderFail,
  createAdvanceServiceOrderSuccess,
  createOrUpdateSurtaxesFail,
  createOrUpdateSurtaxesSuccess,
  getOrderDetailFail,
  getOrderDetailSuccess,
  invoiceOrderSuccess,
  invoiceOrderFail,
  getServiceOrdersFail,
  getServiceOrdersSuccess,
  importServiceOrderFail,
  importServiceOrderSuccess,
  sendEmailLinkServiceOrderFail,
  sendEmailLinkServiceOrderSuccess,
  setReadyToPayServiceOrderFail,
  setReadyToPayServiceOrderSuccess,
  updateServiceOrderFail,
  updateServiceOrderSuccess,
  searchServiceOrderSucces,
  searchServiceOrderFail,
  downloadfileXmlFail,
  downloadfileXmlSuccess,
} from 'app/features/serviceOrders/serviceOrdersSlice.js';
import { formatDatesForFilter, getNested } from 'app/utils/index.js';

import { errors } from './errors.js';
import { getLogs } from 'app/features/logs/logsSlice.js';
import moment from 'moment';
import { requestHandler } from '../../services/requestHandler';

export function* invoiceOrdersSaga(action) {
  try {
    const { id } = action.payload;
    const data = { serviceOrderID: id, isManual: true };

    const invoiceOrder = yield call(requestHandler, {
      method: 'POST',
      path: '/serviceOrder/notifyDMS',
      data,
    });
    const message = {
      title: '¡Éxito!',
      desc: `Se facturo correctamente la ODS.`,
    };
    yield put(invoiceOrderSuccess({ message }));
    // yield put(getServiceOrdersSuccess({ orders }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc:
        e?.details?.slice(0, 110) ||
        `Ocurrió un error cuando se intentaba facturar la ODS.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(invoiceOrderFail({ message }));
  }
}

export function* getOrdersSaga(action) {
  try {
    const {
      page,
      pageSize,
      tabSelected,
      filtered,
      firstDate,
      secondDate,
    } = action.payload;
    let fullOrders = [];
    const data = {
      where: {
        updatedAt: {
          $gt: moment().subtract(3, 'months').startOf('day'),
        },
      },
      options: {
        attributes: [
          'id',
          'orderID',
          'agencyID',
          'vin',
          'adviser',
          'createdAt',
          'createdDate',
          'total',
          'status',
          'updatedAt',
        ],
        count: true,
        distinct: true,
      },
      pagination: {
        page,
        perPage: pageSize,
        order: [['updatedAt', 'DESC']],
      },
      vehicles: {
        where: {},
        options: {
          attributes: ['plates', 'brand', 'model'],
        },
      },
      agencies: {
        options: {
          attributes: [
            'id',
            'isActiveAgency',
            'name',
            'nickname',
            'smsNotifications',
            'emailNotifications',
            'whatsNotifications',
          ],
        },
      },
      serviceOrderNotifications: {
        options: {
          attributes: ['type', 'clicked'],
        },
      },
    };
    if (tabSelected === 1)
      data.where = { ...data.where, status: 'payment' };
    if (filtered.length) {
      filtered.forEach((filter) => {
        if (filter.id === 'orderID' && filter.value) {
          data.where[filter.id] = {
            $iLike: `%${filter.value}%`,
          };
        } else if (filter.id === 'vin' && filter.value) {
          data.where[filter.id] = {
            $iLike: `%${filter.value}%`,
          };
        } else if (filter.id === 'total' && filter.value) {
          data.where[filter.id] = filter.value;
        } else if (filter.id === 'plates' && filter.value) {
          data.vehicles.where[filter.id] = {
            $iLike: `%${filter.value}%`,
          };
        } else if (filter.id === 'agencyName' && filter.value) {
          data.agencies.where = {
            name: { $iLike: `%${filter.value}%` },
          };
        } else if (filter.id === 'advisor' && filter.value) {
          data.where['adviser'] = {
            $iLike: `%${filter.value}%`,
          };
        } else if (filter.id === 'updatedAt' && filter.value) {
          data.where[filter.id] = formatDatesForFilter(filter.value);
        } else if (filter.id === 'status' && filter.value) {
          data.where[filter.id] = {
            $iLike: `%${filter.value}%`,
          };
        } else if (filter.id === 'createdDate' && filter.value) {
          data.where[filter.id] = formatDatesForFilter(filter.value);
          delete data.where.updatedAt;
        }
      });
    }
    if (firstDate && secondDate) {
      // prettier-ignore
      data.where.createdAt = formatDatesForFilter(firstDate, secondDate);
    }
    const orders = yield call(requestHandler, {
      method: 'POST',
      path: '/serviceOrder/getAll',
      data,
    });
    if (
      (firstDate && secondDate) ||
      (filtered.length > 0 && filtered[0].id === 'agencyName')
    ) {
      const data = {
        where: {
          updatedAt: {
            $gt: moment().subtract(4, 'months').startOf('day'),
          },
        },
        options: {
          attributes: [
            'id',
            'orderID',
            'agencyID',
            'vin',
            'adviser',
            'createdAt',
            'createdDate',
            'total',
            'status',
            'updatedAt',
          ],
          count: true,
          distinct: true,
        },
        vehicles: {
          where: {},
          options: {
            attributes: ['plates', 'brand', 'model'],
          },
        },
        agencies: {
          options: {
            attributes: [
              'id',
              'name',
              'nickname',
              'isActiveAgency',
              'smsNotifications',
              'emailNotifications',
              'whatsNotifications',
            ],
          },
        },
        serviceOrderNotifications: {
          options: {
            attributes: ['type', 'clicked'],
          },
        },
      };
      if (filtered.length) {
        filtered.forEach((filter) => {
          if (filter.id === 'orderID' && filter.value) {
            data.where[filter.id] = filter.value;
          } else if (filter.id === 'vin' && filter.value) {
            data.where[filter.id] = filter.value;
          } else if (filter.id === 'total' && filter.value) {
            data.where[filter.id] = filter.value;
          } else if (filter.id === 'plates' && filter.value) {
            data.vehicles.where[filter.id] = {
              $iLike: `%${filter.value}%`,
            };
          } else if (filter.id === 'agencyName' && filter.value) {
            data.agencies.where = {
              name: { $iLike: `%${filter.value}%` },
            };
          } else if (filter.id === 'advisor' && filter.value) {
            data.where['adviser'] = {
              $iLike: `%${filter.value}%`,
            };
          } else if (filter.id === 'updatedAt' && filter.value) {
            data.where[filter.id] = formatDatesForFilter(
              filter.value,
            );
          } else if (filter.id === 'status' && filter.value) {
            data.where[filter.id] = {
              $iLike: `%${filter.value}%`,
            };
          } else if (filter.id === 'createdDate' && filter.value) {
            data.where[filter.id] = formatDatesForFilter(
              filter.value,
            );
            delete data.where.updatedAt;
          }
        });
      }
      if (firstDate && secondDate) {
        // prettier-ignore
        data.where.createdAt = formatDatesForFilter(firstDate, secondDate);
      }
      fullOrders = yield call(requestHandler, {
        method: 'POST',
        path: '/serviceOrder/getAll',
        data,
      });
    }
    orders.fullOrders = fullOrders;
    orders.pages = yield call(Math.ceil, orders.count / pageSize);
    yield put(getServiceOrdersSuccess({ orders }));
  } catch (e) {
    yield put(getServiceOrdersFail('Not valid auth'));
    console.log('error in login user saga', e);
  }
}

export function* getOrderDetailSaga(action) {
  try {
    let advances;
    const data = {
      where: {
        id: action.payload.karloID,
      },
      options: {
        attributes: [
          'id',
          'agencyID',
          'userID',
          'orderID',
          'adviser',
          'adviserEmail',
          'adviserPhoneNumber',
          'agencyID',
          'createdAt',
          'total',
          'status',
          'vin',
          'urlFile',
          'urlFileXml',
          'invoiceNumber',
        ],
      },
      surTaxes: {
        options: {
          attributes: ['3', '6', '9', '12', '18'],
        },
      },
      AMEXSurTaxes: {
        options: {
          attributes: ['3', '6', '9', '12', '18'],
        },
      },
      vehicles: {
        options: {
          attributes: ['plates', 'brand', 'model'],
        },
      },
      invoiceProfiles: {
        options: {
          attributes: ['fullName', 'rfc', 'phoneNumbers', 'email'],
        },
      },
      agencies: {
        options: {
          attributes: [
            'id',
            'DMS',
            'nickname',
            'name',
            'smsUnitNotifications',
            'whatsUnitNotifications',
            'emailUnitNotifications',
            'cardComission',
            'amexCardComission',
            'odsServicePromotions',
          ],
        },
      },
      operations: {
        options: {
          attributes: ['id', 'description', 'price'],
        },
      },
      payments: true,
      paymentOrders: {
        options: {
          attributes: [
            'total',
            'status',
            'id',
            'agencyID',
            'orderID',
          ],
        },
      },
      pagination: { order: [['updatedAt', 'DESC']] },
    };
    const order = yield call(requestHandler, {
      method: 'POST',
      path: '/serviceOrder/getOne',
      data,
    });
    /* let payloadInvoicePDF = {
      orderID: order.orderID,
      agencyID: order.agencyID,
    };
    const invoicePDF = yield call(requestHandler, {
      method: 'POST',
      path: '/serviceOrder/downloadOrderInvoice',
      data: payloadInvoicePDF,
    }); */
    const vin = getNested(null, order, 'vin');
    if (vin && order.status === 'remission') {
      const paymentOrderData = {
        where: {
          vin,
          status: {
            $not: ['APPLIED'],
          },
          advance: true,
        },
      };
      advances = yield call(requestHandler, {
        method: 'POST',
        path: '/paymentOrder/getAll',
        data: paymentOrderData,
      });
      order.advances = advances;
    }
    yield put(getOrderDetailSuccess({ order /* invoicePDF */ }));
    yield put(
      getLogs({
        object: 'serviceOrders',
        objectID: action.payload.karloID,
      }),
    );
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba encontrar la informaición de la ordern.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(getOrderDetailFail({ message }));
    console.log('error in get order detail user saga', e);
  }
}

export function* sendEmailLinkServiceOrderSaga(action) {
  try {
    const { email, serviceOrderID } = action.payload;
    const data = {
      email: email ? email : undefined,
      serviceOrderID,
      type: 'payment',
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/serviceOrder/mail',
      data,
    });
    const message = {
      title: '¡Éxito!',
      desc: `El correo se envió a ${email}.`,
    };
    yield put(sendEmailLinkServiceOrderSuccess({ message }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba enviar el correo.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(sendEmailLinkServiceOrderFail({ message }));
    console.log('error sending receipt payment order: ', e);
  }
}

export function* importServiceOrderSaga(action) {
  try {
    const { history, page, pageSize, sorted, filtered, ...rest } =
      action.payload;
    const data = {
      ...rest,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/serviceOrder/search',
      data,
    });
    const message = {
      title: '¡Orden agregada!',
      desc: `La orden ${action.payload.orderID} ha sido agregada correctamente.`,
    };
    yield put(importServiceOrderSuccess({ message }));
    yield call(getOrdersSaga, {
      payload: {
        page,
        pageSize,
        sorted,
        filtered,
      },
    });
  } catch (e) {
    let message;
    const errorDetails = e.details
      .split('\n')[0]
      .replace('Error: ', '');
    if (errorDetails in errors)
      message = {
        title: 'Error',
        desc: errors[errorDetails],
      };
    else {
      message = {
        title: 'Error',
        desc: `Ocurrió un error cuando se intentaba aregar la orden.
        Por favor, inténtelo de nuevo más tarde.`,
      };
    }
    yield put(importServiceOrderFail({ message }));
    console.log('error sending receipt payment order: ', e);
  }
}

export function* searchServiceOrderSaga(action) {
  try {
    const { values, id, handleRefreshData } = action.payload;
    const data = {
      agencyID: id,
      dmsID: values.orderID,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/serviceOrder/getOneODS',
      data,
    });
    const message = `La orden ${action.payload.values.orderID} ha sido agregada correctamente.`;
    yield put(searchServiceOrderSucces({ message }));
    yield call(handleRefreshData);
  } catch (error) {
    console.log('Error in search service order', error);
    const message = 'Hubo un error al crear la order manualmente';
    yield put(searchServiceOrderFail({ message }));
  }
}

export function* updateServiceOrderSaga(action) {
  try {
    const { karloID, ...rest } = action.payload;
    const data = {
      ...rest,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/serviceOrder/search',
      data,
    });
    const message = {
      title: '¡Orden actualizada!',
      desc: `La orden ${action.payload.orderID} ha sido actualizada correctamente.`,
    };
    yield put(updateServiceOrderSuccess({ message }));
    yield call(getOrderDetailSaga, {
      payload: {
        karloID,
        agencyID: action.payload.agencyID,
      },
    });
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba actualizar la orden.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(updateServiceOrderFail({ message }));
    console.log('error sending receipt payment order: ', e);
  }
}

export function* setReadyToPayServiceOrderSaga(action) {
  try {
    const { karloID: id, agencyID, ...rest } = action.payload;
    const data = {
      ...rest,
      id,
      agencyID,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/serviceOrder/readyToPay',
      data,
    });
    const message = {
      title: '¡Orden actualizada!',
      desc: `La orden ${action.payload.orderID} ha sido actualizada para pagar.`,
    };
    yield put(setReadyToPayServiceOrderSuccess({ message }));
    yield call(getOrderDetailSaga, {
      payload: {
        id,
        agencyID,
      },
    });
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba actualizar la orden.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(setReadyToPayServiceOrderFail({ message }));
    console.log('error sending receipt payment order: ', e);
  }
}

export function* createAdvanceServiceOrderSaga(action) {
  try {
    const { id } = action.payload;
    const data = {
      ...action.payload,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/serviceOrder/createAdvance',
      data,
    });
    const message = {
      title: '¡Anticipo creado!',
      desc: `¡El anticipo ha sido creado correctamente!`,
    };
    yield put(createAdvanceServiceOrderSuccess({ message }));
    yield call(getOrderDetailSaga, {
      payload: {
        karloID: id,
      },
    });
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba crear el anticipo para la orden.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(createAdvanceServiceOrderFail({ message }));
    console.log(
      'error sending creating advance for serviceOrder: ',
      e,
    );
  }
}

export function* createExternalPaymentServiceOrderSaga(action) {
  try {
    const { id } = action.payload;
    const data = {
      ...action.payload,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/paymentOrder/addPaidAdvance',
      data,
    });
    const message = {
      title: '¡Pago externo creado!',
      desc: `¡El pago externo ha sido aplicado correctamente!`,
    };
    yield put(createAdvanceServiceOrderSuccess({ message }));
    yield call(getOrderDetailSaga, {
      payload: {
        karloID: id,
      },
    });
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba crear el pago externo.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(createAdvanceServiceOrderFail({ message }));
    console.log(
      'error sending creating advance for serviceOrder: ',
      e,
    );
  }
}

export function* createOrUpdateSurTaxesForServiceOrdersSaga(action) {
  try {
    const { amex, visa, serviceOrderID } = action.payload;
    const dataVisa = {
      serviceOrderID,
      ...visa,
    };
    const dataAmex = {
      serviceOrderID,
      ...amex,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/surTax/update',
      data: dataVisa,
    });
    yield call(requestHandler, {
      method: 'POST',
      path: '/AMEXSurTax/update',
      data: dataAmex,
    });
    const message = {
      title: 'Promociones actualizadas ',
      desc: `Las promociones se han actualizado correctamente.`,
    };
    yield call(getOrderDetailSaga, {
      payload: {
        karloID: serviceOrderID,
      },
    });
    yield put(createOrUpdateSurtaxesSuccess({ message }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se trataba de actualizar la promoción.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(createOrUpdateSurtaxesFail({ message }));
    console.log('error in upload SurTaxes saga', e);
  }
}

export function* downloadfileXmlSaga(action) {
  try {
    const { serviceOrderID } = action.payload;
    const data = {
      orderID: serviceOrderID,
    };
    const url = yield call(requestHandler, {
      method: 'POST',
      path: `/downloadfile/downloadInvoiceXml?orderID=${serviceOrderID}`,
    });
    const blob = new Blob([url], { type: 'application/xml' });
    const link = document.createElement('a');
    const urlResponse = window.URL.createObjectURL(blob);
    link.href = urlResponse;
    link.download = `Factura-${serviceOrderID}.xml`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(urlResponse);
    console.log('url in downloadfileXml saga', url);
    yield put(downloadfileXmlSuccess({ url }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrio un error cuando se trataba de descargar el archivo XML.
      Por favor, reintente de nuevo.`,
    };
    yield put(downloadfileXmlFail({ message }));
    console.log('error in downloadfileXml saga', e);
  }
}
