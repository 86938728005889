import React, { useEffect, useRef } from 'react';

import { Pie } from 'react-chartjs-2';
import { formatNumber, getNested } from '../utils';

export default function QuickStatsChart({
  type,
  value,
  desc,
  data,
  paymentOrders,
}) {
  const chartRef = useRef(null);

  useEffect(() => {
    const el = document.getElementById(`legend-${desc}`);
    if (el)
      el.innerHTML = chartRef.current.chartInstance.generateLegend();
  }, [data, desc]);

  const chartOptions = {
    legend: {
      display: false,
      position: 'right',
    },
    legendCallback: function (chart) {
      let ul = document.createElement('ul');
      ul.style.listStyle = 'none';
      ul.style.padding = '0px';
      ul.style.paddingLeft = '10px';
      chart.data.datasets.forEach(function (dataset, datasetIndex) {
        dataset.labels.forEach(function (label, labelIndex) {
          ul.innerHTML += `
            <li>
                <span style="background-color: ${
                  dataset.backgroundColor[labelIndex]
                }; 
                width: 15px;
                height: 10px;
                display: inline-block;
                margin: 0 5px 8px 0;
                vertical-align: -9.4px;"></span>
                ${label} (${getNested(
            0,
            data,
            'datasets',
            '0',
            'data',
            labelIndex,
          )})
          ${
            labelIndex === 1 && label === 'ODP'
              ? ` 
            <li style="padding-left: 30px;">
              ${getNested(
                0,
                paymentOrders,
                'sales',
              )} ventas: ${formatNumber(
                  getNested(0, paymentOrders, 'salesTotal'),
                )}
            </li>
            <li style="padding-left: 30px;">
              ${getNested(0, paymentOrders, 'service')} servicio: 
              ${formatNumber(
                getNested(0, paymentOrders, 'serviceTotal'),
              )}
            </li>
            <li style="padding-left: 30px;">
              ${getNested(0, paymentOrders, 'fi')} F&I: 
              ${formatNumber(getNested(0, paymentOrders, 'fiTotal'))}
            </li>
            <li style="padding-left: 30px;">
              ${getNested(
                0,
                paymentOrders,
                'parts',
              )} refacciones: ${formatNumber(
                  getNested(0, paymentOrders, 'partsTotal'),
                )}
            </li>
            <li style="padding-left: 30px;">
              ${getNested(
                0,
                paymentOrders,
                'pulled_apart',
              )} apartados: ${formatNumber(
                  getNested(0, paymentOrders, 'pulled_apartTotal'),
                )}
            </li>`
              : ``
          }</li>`;
        });
      });
      return ul.outerHTML;
    },
    tooltips: {
      intersect: true,
      mode: 'nearest',
      xPadding: 10,
      yPadding: 10,
      caretPadding: 10,
    },
    responsive: false,
    maintainAspectRatio: false,
    animation: {
      animateRotate: false,
      animateScale: true,
    },
  };

  const renderEmptyOrCanvas = () => {
    if (value > 0)
      return (
        <>
          <div className="kt-widget26__content">
            <span className="kt-widget26__number">{value}</span>
            <span className="kt-widget26__desc">{desc}</span>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Pie
              width={150}
              height={150}
              data={data}
              options={chartOptions}
              ref={chartRef}
            />
            <div id={`legend-${desc}`}></div>
          </div>
        </>
      );
    else {
      return (
        <div className="kt-widget26__content text-center">
          <span className="kt-widget26__number">{value}</span>
          <span className="kt-widget26__desc">{desc}</span>
        </div>
      );
    }
  };

  return <div className="kt-widget26">{renderEmptyOrCanvas()}</div>;
}
