import { createSelector } from 'reselect';
import { initialState } from './initialState';

export const selectKarloVehicles = (state) =>
  state.karloVehicles || initialState;

export const makeSelectKarloVehicles = createSelector(
  selectKarloVehicles,
  (karloVehiclesState) => karloVehiclesState,
);

export const makeSelectKarloVehiclesDetails = createSelector(
  selectKarloVehicles,
  (karloVehiclesState) => karloVehiclesState.vehicleDetails,
);

export const makeSelectKarloVehiclesIsLoading = createSelector(
  selectKarloVehicles,
  (karloVehiclesState) => karloVehiclesState.isLoading,
);
