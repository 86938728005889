import { createSelector } from 'reselect';
import { initialState } from './initialState';

export const selectDashboard = (state) =>
  state.dashboard || initialState;

export const makeSelectPaymentOrdersFromDashboard = createSelector(
  selectDashboard,
  (dashboardState) => dashboardState.paymentOrders,
);

export const makeSelectServiceOrdersFromDashboard = createSelector(
  selectDashboard,
  (dashboardState) => dashboardState.serviceOrders,
);

export const makeSelectPaymentsFromDashboard = createSelector(
  selectDashboard,
  (dashboardState) => dashboardState.payments,
);

export const makeSelectDashboardIsLoading = createSelector(
  selectDashboard,
  (dashboardState) => dashboardState.isLoading,
);

export const makeSelectDashboardAgencies = createSelector(
  selectDashboard,
  (dashboardState) => dashboardState.agencies,
);
