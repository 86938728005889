import 'react-table/react-table.css';
import 'moment/locale/es';

/* eslint-disable react/display-name */
import {
  Button,
  Badge as MaterialBadge,
  Paper,
  TextField,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import { Badge } from 'react-bootstrap';
import {
  formatSingleDate,
  formatNumber,
  getAppointmentColor,
  getColumnWidth,
  getAppointmentName,
} from '../../../../utils';
import { getServiceAppointments } from 'app/features/serviceAppointments/serviceAppointmentsSlice';

import { Link } from 'react-router-dom';
import MUIDatePickerFilter from 'app/components/MUIDatePickerFilter';
import MUIRHFAutocompleteAgencies from 'app/components/MUIRHFAutocompleteAgencies';
import Modal from '../../../../components/Modal';
import React from 'react';
import ReactTable from 'react-table';
import ShouldItRender from '../../../../components/ShouldItRender';
import TextsmsIcon from '@material-ui/icons/Textsms';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { agenciesMercedes } from '../../../../config/customizing';
import { closeModals } from 'app/features/modals/modalsSlice';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { injectIntl } from 'react-intl';
import { makeSelectServiceAppointmentDetails } from 'app/features/serviceAppointments/selectors';
import { makeSelectUserRoleFromState } from '../../../auth/selectors';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(0),
  },
  notifications: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  lastUpdated: {
    marginLeft: theme.spacing(2),
    color: '#434349',
  },
  datePickerTextfield: {
    padding: '5px 5px 5px !important',
    height: '20px',
  },
}));

function AppointmentsTable(props) {
  const classes = useStyles();
  const { serviceAppointments, role, getServiceAppointments } = props;
  const [appointmentsData, setAppointmentsData] = React.useState([]);
  const [pages, setPages] = React.useState(1);
  const [
    showImportOrderModal,
    setShowImportOrderModal,
  ] = React.useState(false);
  const { handleSubmit, register, errors, control } = useForm();
  const reactTable = React.useRef(null);

  const handleFetchData = debounce(
    (state, instance) => {
      const { page, pageSize, sorted, filtered } = state;
      getServiceAppointments({ page, pageSize, sorted, filtered });
    },
    1000,
    { leading: false, trailing: true },
  );

  React.useEffect(() => {
    if (Array.isArray(serviceAppointments.fromdb)) {
      const cleanOrders = serviceAppointments.fromdb.map((order) => {
        return {
          karloID: order.id,
          createdDate: order.date
            ? formatSingleDate(order.date)
            : 'n/a',
          hour: order.hour ? order.hour : 'n/a',
          type: order.type ? order.type : 'n/a',
          status: order.status,
          packageService: order.packageService
            ? order.packageService.name
            : 'n/a',
          description: order.description ? order.description : 'n/a',
          specialService:
            order.specialService.length != 0 ? 'si' : 'n/a',
        };
      });
      setAppointmentsData(cleanOrders);
    }
  }, [serviceAppointments.fromdb]);

  const columns = [
    {
      Header: () => <strong>ID</strong>,
      accessor: 'karloID',
      Cell: (row) => (
        <Link to={`/detail-appointment/${row.original.karloID}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Tipo</strong>,
      accessor: 'type',
      Cell: (row) => (
        <Link to={`/detail-appointment/${row.original.karloID}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      Filter: (cell) => {
        return (
          // eslint-disable-next-line jsx-a11y/no-onchange
          <select
            onChange={(e) => cell.onChange(e.target.value)}
            value={
              cell.filter && cell.filter.value
                ? cell.filter.value
                : ''
            }
          >
            <option value="">Todos</option>
            <option value="Mantenimiento">Mantenimiento</option>
            <option value="Falla / Garantía">Falla / Garantía</option>
          </select>
        );
      },
    },
    // {
    //   Header: () => <strong>Paquete</strong>,
    //   accessor: 'packageService',
    //   Cell: (row) => (
    //     <Link to={`/detail-appointment/${row.original.karloID}`}>
    //       <div
    //         style={{
    //           textAlign: 'center',
    //         }}
    //       >
    //         {row.value}
    //       </div>
    //     </Link>
    //   ),
    //   filterable: false,
    // },
    // {
    //   Header: () => <strong>Servicios Especiales</strong>,
    //   accessor: 'specialService',
    //   Cell: (row) => (
    //     <Link to={`/detail-appointment/${row.original.karloID}`}>
    //       <div
    //         style={{
    //           textAlign: 'center',
    //         }}
    //       >
    //         {row.value}
    //       </div>
    //     </Link>
    //   ),
    //   filterable: false,
    // },
    {
      Header: () => <strong>Descripción</strong>,
      accessor: 'description',
      Cell: (row) => (
        <Link to={`/detail-appointment/${row.original.karloID}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Fecha de Cita</strong>,
      accessor: 'createdDate',
      Cell: (row) => (
        <Link to={`/detail-appointment/${row.original.karloID}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      width: getColumnWidth(
        appointmentsData,
        'createdDate',
        'Fecha de Creación',
      ),
      filterable: false,
      // Filter: (cell) => {
      //   return <MUIDatePickerFilter cell={cell} />;
      // },
    },
    {
      Header: () => <strong>Hora de Cita</strong>,
      accessor: 'hour',
      Cell: (row) => (
        <Link to={`/detail-appointment/${row.original.karloID}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
      width: getColumnWidth(
        appointmentsData,
        'updatedAt',
        'Última actualización',
      ),
    },
    {
      Header: () => <strong>Estatus</strong>,
      accessor: 'status',
      Cell: (row) => (
        <Link to={`/detail-appointment/${row.original.karloID}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <Badge
              className={classes.badges}
              variant={getAppointmentColor(row.value)}
            >
              {getAppointmentName(row.value)}
            </Badge>
          </div>
        </Link>
      ),
      filterable: false,
    },
  ];

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <ReactTable
          ref={reactTable}
          NoDataComponent={() => (
            <div className="rt-noData">Sin resultados</div>
          )}
          manual
          onFetchData={handleFetchData}
          data={appointmentsData}
          pages={serviceAppointments.tablePages}
          columns={columns}
          filterable
          className="-striped -highlight"
          previousText="Anterior"
          nextText="Siguiente"
          loading={serviceAppointments.isLoading}
          loadingText="Cargando datos..."
        />
      </Paper>
    </div>
  );
}

const mapStateToProps = (state) => ({
  serviceAppointments: makeSelectServiceAppointmentDetails(state),
});

const mapDispatchToProps = {
  getServiceAppointments,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(AppointmentsTable),
);
