import 'react-table/react-table.css';
import 'moment/locale/es';
import React, { useState, useEffect, useRef } from 'react';

/* eslint-disable react/display-name */
import {
  Button,
  IconButton,
  Badge as MaterialBadge,
  Paper,
  TextField,
  Tooltip,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import {
  formatDate,
  formatNumber,
  getBadgeColor,
  getColumnWidth,
  getNested,
  getSOStatusName,
  mappedAssesors,
} from '../../../../utils';
import {
  getServiceOrders,
  importServiceOrder,
  searchServiceOrder,
} from 'app/features/serviceOrders/serviceOrdersSlice';
import MUIAutocompleteAgencies from 'app/components/MUIAutocompleteAgencies';

import Add from '@material-ui/icons/Add';
import { Badge as BootstrapBadge } from 'react-bootstrap';
import EmailIcon from '@material-ui/icons/Email';
import { Link } from 'react-router-dom';
import MUIDatePickerFilter from 'app/components/MUIDatePickerFilter';
import MUIRHFAutocompleteAgencies from 'app/components/MUIRHFAutocompleteAgencies';
import Modal from '../../../../components/Modal';
import ReactTable from 'react-table';
import ShouldItRender from '../../../../components/ShouldItRender';
import TextsmsIcon from '@material-ui/icons/Textsms';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { agenciesMercedes } from '../../../../config/customizing';
import { closeModals } from 'app/features/modals/modalsSlice';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { injectIntl } from 'react-intl';
import { makeSelectUserRoleFromState } from '../../../auth/selectors';
import { useForm } from 'react-hook-form';
import { CSVLink } from 'react-csv';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Close from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import GetApp from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(0),
  },
  notifications: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  lastUpdated: {
    marginLeft: theme.spacing(2),
    color: '#434349',
  },
  datePickerTextfield: {
    padding: '5px 5px 5px !important',
    height: '20px',
  },
  agenciesSelect: {
    [theme.breakpoints.up('lg')]: {
      minWidth: theme.spacing(25),
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: theme.spacing(25),
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '125px',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '125px',
    },
    marginTop: theme.spacing(1),
  },
  range: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: '30px',
  },
  datePicker: {
    width: '90px',
  },
  btnSize: {
    width: 100,
  },
  formControl: {
    marginTop: '8px',
    marginRight: '5px',
    backgroundColor: '#FFF',
  },
}));

const EnhancedTable = (props) => {
  const classes = useStyles();
  const {
    agency,
    serviceOrders,
    role,
    importServiceOrder,
    getServiceOrders,
    closeModal,
    history,
    searchServiceOrder,
    fullOrders,
  } = props;
  const [orders, setOrders] = useState([]);
  const [showImportOrderModal, setShowImportOrderModal] =
    useState(false);
  const [showSearchOrderModal, setShowSearchOrderModal] =
    useState(false);
  const [agencySelect, setagencySelect] = useState(null);
  const { handleSubmit, register, errors, control } = useForm();
  const reactTable = useRef(null);
  const [dataToCSV, setDataToCSV] = useState([]);
  const [firstDate, setFirstDate] = useState(null);
  const [secondDate, setSecondDate] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sorted, setSorted] = useState([]);
  const [filtered, setFiltered] = useState('');
  const [ordersFiltered, setOrdersFiltered] = useState([]);
  const [showDateErrorModal, setShowDateErrorModal] = useState(false);
  const [closeDateErrorModal, setCloseDateErrorModal] =
    useState(false);
  const [disableDownloadCSV, setDisableDownloadCSV] = useState(false);
  const [gridDate, setGridDate] = useState(0);
  const [gridButtons, setGridButtons] = useState(0);
  const [tabSelected, setTabSelected] = useState(0);

  useEffect(() => {
    if (role === 'Super Admin') {
      setGridDate(6);
      setGridButtons(2);
    } else {
      setGridDate(9);
      setGridButtons(1);
    }
  }, [role]);

  const handleFirstDate = (date) => {
    setFirstDate(date);
  };

  const handleSecondDate = (date) => {
    setSecondDate(date);
  };

  useEffect(() => {
    handleFetchData();
  }, [firstDate, secondDate]);

  const handleClearFirstDate = () => {
    setFirstDate(null);
  };

  const handleClearSecondDate = () => {
    setSecondDate(null);
  };

  const handleFetchData = debounce(
    (state, instance) => {
      if (state) {
        const { page, pageSize, sorted, filtered } = state;
        getServiceOrders({
          page,
          pageSize,
          sorted,
          filtered,
          tabSelected,
          firstDate,
          secondDate,
        });
        setPage(page);
        setPageSize(pageSize);
        setSorted(sorted);
        setFiltered(filtered);
      } else {
        getServiceOrders({
          page,
          pageSize,
          sorted,
          filtered,
          tabSelected,
          firstDate,
          secondDate,
        });
      }
    },
    1000,
    { leading: false, trailing: true },
  );

  useEffect(() => {
    props.setLeftSideComponent(
      <div>
        <FormControl
          variant="outlined"
          size="small"
          lassName={classes.formControl}
        >
          <InputLabel htmlFor="name-native-disabled">
            Selecciona tipo de orden
          </InputLabel>
          <Select
            value={tabSelected}
            onChange={handleChangeTab}
            label="orderType"
          >
            <MenuItem value={0}>Abiertas</MenuItem>
            <MenuItem value={1}>Pendientes por facturar</MenuItem>
          </Select>
        </FormControl>
        ,
      </div>,
    );
    reactTable.current && reactTable.current.fireFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabSelected]);

  const handleRefreshData = () =>
    reactTable.current && reactTable.current.fireFetchData();

  // this is used for fill the table in the site delimiter for the row numbers
  useEffect(() => {
    if (Array.isArray(serviceOrders.fromdb)) {
      const cleanOrders = serviceOrders.fromdb.map((order) => {
        return {
          karloID: order.id,
          agencyID: order.agencyID ? order.agencyID : 'n/a',
          isActiveAgency:
            order.agency && order.agency.isActiveAgency
              ? order.agency.isActiveAgency
              : false,
          orderID: order.orderID ? order.orderID : 'n/a',
          orderIDLink: order.orderID ? order.orderID : 'n/a',
          nickname: order.agency ? order.agency.nickname : 'n/a',
          plates:
            order.vehicle && order.vehicle.plates
              ? order.vehicle.plates
              : 'n/a',
          vin: order.vin ? order.vin : 'n/a',
          createdDate: order.createdDate
            ? formatDate(order.createdDate)
            : 'n/a',
          vehicle:
          order.vehicle
            ? `${order.vehicle.brand || ''} ${order.vehicle.model || ''}`.trim() || 'n/a'
            : 'n/a',
          total: order.total ? order.total : 'n/a',
          status: order.status
            ? getSOStatusName(
                order.status,
                order.agency &&
                  agenciesMercedes.includes(order.agency.id),
              )
            : 'n/a',
          updatedAt: order.updatedAt
            ? formatDate(order.updatedAt)
            : 'n/a',
          serviceOrderNotifications:
            order.serviceOrderNotifications || [],
          smsClick: order.smsClick,
          emailClick: order.emailClick,
          smsNotifications: order.agency
            ? order.agency.smsNotifications
            : false,
          emailNotifications: order.agency
            ? order.agency.emailNotifications
            : false,
          whatsNotifications: order.agency
            ? order.agency.whatsNotifications
            : false,
          advisor: getNested('-', order, 'adviser'),
          agencyName: getNested(null, order, 'agency', 'name'),
        };
      });
      setOrders(cleanOrders);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceOrders.fromdb]);

  // this is used for fill the csv file when exist the date filter or agency filter
  useEffect(() => {
    if (!Array.isArray(fullOrders) && fullOrders !== undefined) {
      const cleanOrders = fullOrders.rows.map((order) => {
        return {
          karloID: order.id,
          agencyID: order.agencyID ? order.agencyID : 'n/a',
          isActiveAgency:
            order.agency && order.agency.isActiveAgency
              ? order.agency.isActiveAgency
              : false,
          orderID: order.orderID ? order.orderID : 'n/a',
          orderIDLink: order.orderID ? order.orderID : 'n/a',
          nickname: order.agency ? order.agency.nickname : 'n/a',
          plates:
            order.vehicle && order.vehicle.plates
              ? order.vehicle.plates
              : 'n/a',
          vin: order.vin ? order.vin : 'n/a',
          createdDate: order.createdAt
            ? formatDate(order.createdAt)
            : 'n/a',
          vehicle:
            order.vehicle &&
            order.vehicle.brand &&
            order.vehicle.model
              ? `${order.vehicle.brand} ${order.vehicle.model}`
              : 'n/a',
          total: order.total ? order.total : 'n/a',
          status: order.status
            ? getSOStatusName(
                order.status,
                order.agency &&
                  agenciesMercedes.includes(order.agency.id),
              )
            : 'n/a',
          updatedAt: order.updatedAt
            ? formatDate(order.updatedAt)
            : 'n/a',
          serviceOrderNotifications:
            order.serviceOrderNotifications || [],
          smsClick: order.smsClick,
          emailClick: order.emailClick,
          smsNotifications: order.agency
            ? order.agency.smsNotifications
            : false,
          emailNotifications: order.agency
            ? order.agency.emailNotifications
            : false,
          whatsNotifications: order.agency
            ? order.agency.whatsNotifications
            : false,
          advisor: getNested('-', order, 'adviser'),
          agencyName: getNested(null, order, 'agency', 'name'),
        };
      });
      setOrdersFiltered(cleanOrders);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullOrders]);

  useEffect(() => {
    if (firstDate && secondDate && firstDate > secondDate) {
      setShowDateErrorModal(true);
      setDisableDownloadCSV(true);
      renderErrorDateModal();
    } else {
      setDisableDownloadCSV(false);
    }
  }, [firstDate, secondDate]);

  const toogleImportOrderModal = () => {
    setShowImportOrderModal(!showImportOrderModal);
  };

  const toogleSearchOrderModal = () => {
    setShowSearchOrderModal(!showSearchOrderModal);
    setagencySelect(null);
  };

  const handleImportOrder = (values) => {
    const { page, pageSize, sorted, filtered } =
      reactTable.current.state;
    importServiceOrder({
      ...values,
      page,
      pageSize,
      sorted,
      filtered,
      history,
    });
    setShowImportOrderModal(false);
  };

  const handleCloseModals = () => {
    closeModal();
    setCloseDateErrorModal(false);
    setShowDateErrorModal(false);
  };

  const handleChangeTab = (event, value) => {
    setTabSelected(event.target.value);
  };

  const importOrderDialog = () => {
    return (
      <Modal
        open={showImportOrderModal}
        closeModal={toogleImportOrderModal}
        dialogTitle="Agregar orden de servicio"
        dialogText={`Ingresa el ID de la orden de servicio que deseas agregar`}
        dialogChildren={
          <form
            id="import-order-form"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(handleImportOrder)}
          >
            <ShouldItRender should={role === 'Super Admin'}>
              <MUIRHFAutocompleteAgencies
                name="agencyID"
                control={control}
                fullWidth
                className={classes.textField}
              />
            </ShouldItRender>
            <TextField
              required
              error={Boolean(errors.orderID)}
              label="ID de la orden"
              name="orderID"
              margin="normal"
              variant="outlined"
              inputRef={register({
                required: true,
              })}
            />
          </form>
        }
        actionButtonText="Agregar"
        form={'import-order-form'}
      />
    );
  };

  const handleSearchOrder = (values) => {
    const id = agencySelect ? agencySelect : null;
    searchServiceOrder({ values, id, handleRefreshData });
    setShowSearchOrderModal(false);
    setagencySelect(null);
  };

  const searchOrderDialog = () => {
    return (
      <Modal
        open={showSearchOrderModal || agencySelect}
        closeModal={toogleSearchOrderModal}
        dialogTitle="Agregar orden de servicio"
        dialogText={`Ingresa el ID de la orden de servicio para crearla manualmente en Karlo`}
        dialogChildren={
          <form
            id="import-order-form"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(handleSearchOrder)}
          >
            <TextField
              required
              error={Boolean(errors.orderID)}
              label="ID de la orden"
              name="orderID"
              margin="normal"
              variant="outlined"
              inputRef={register({
                required: true,
              })}
            />
          </form>
        }
        actionButtonText="Agregar"
        form={'import-order-form'}
      />
    );
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = serviceOrders;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message } = serviceOrders[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={handleCloseModals}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={handleCloseModals}
          />
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  const quiterModal = () => {
    try {
      const { quiterModal } = serviceOrders;
      if (quiterModal.show === true) {
        return (
          <Modal
            open={quiterModal.show}
            type={quiterModal.type}
            closeModal={handleCloseModals}
            dialogTitle={'Crear orden de servicio manualmente'}
            dialogText={quiterModal.message}
            actionButtonText="Aceptar"
            onClick={handleCloseModals}
          />
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  const columns = [
    {
      Header: () => <strong>ID</strong>,
      accessor: 'orderID',
      Cell: (row) => (
        <Link
          to={`/detail-order/${row.original.karloID}/${row.original.agencyID}/`}
        >
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>Agencia</strong>,
      accessor: 'agencyName',
      Cell: (row) => (
        <Link
          to={`/detail-order/${row.original.karloID}/${row.original.agencyID}/`}
        >
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      width: getColumnWidth(orders, 'agencyName', 'Agencia'),
      show: role === 'Super Admin',
    },
    {
      Header: () => <strong>Placa</strong>,
      accessor: 'plates',
      Cell: (row) => (
        <Link
          to={`/detail-order/${row.original.karloID}/${row.original.agencyID}/`}
        >
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>Vin</strong>,
      accessor: 'vin',
      Cell: (row) => (
        <Link
          to={`/detail-order/${row.original.karloID}/${row.original.agencyID}/`}
        >
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      width: getColumnWidth(orders, 'vin', 'Vin.....'),
    },
    {
      Header: () => <strong>Asesor</strong>,
      accessor: 'advisor',
      Cell: (row) => (
        <Link
          to={`/detail-order/${row.original.karloID}/${row.original.agencyID}/`}
        >
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {mappedAssesors(row.value)}
          </div>
        </Link>
      ),
      width: getColumnWidth(orders, 'advisor', 'Asesor.....'),
    },
    {
      Header: () => <strong>Última actualización</strong>,
      accessor: 'updatedAt',
      Cell: (row) => (
        <Link
          to={`/detail-order/${row.original.karloID}/${row.original.agencyID}/`}
        >
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      width: getColumnWidth(
        orders,
        'updatedAt',
        'Última actualización',
      ),
      Filter: (cell) => {
        return <MUIDatePickerFilter cell={cell} />;
      },
    },
    {
      Header: () => <strong>Fecha de Creación</strong>,
      accessor: 'createdDate',
      Cell: (row) => (
        <Link
          to={`/detail-order/${row.original.karloID}/${row.original.agencyID}/`}
        >
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      width: getColumnWidth(
        orders,
        'createdDate',
        'Fecha de Creación',
      ),
      Filter: (cell) => {
        return <MUIDatePickerFilter cell={cell} />;
      },
    },
    {
      Header: () => <strong>Vehiculo</strong>,
      accessor: 'vehicle',
      Cell: (row) => (
        <Link
          to={`/detail-order/${row.original.karloID}/${row.original.agencyID}/`}
        >
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Total</strong>,
      accessor: 'total',
      Cell: (row) => (
        <Link
          to={`/detail-order/${row.original.karloID}/${row.original.agencyID}/`}
        >
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {formatNumber(row.value)}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>Estatus</strong>,
      accessor: 'status',
      Cell: (row) => (
        <Link
          to={`/detail-order/${row.original.karloID}/${row.original.agencyID}/`}
        >
          <div
            style={{
              textAlign: 'center',
              alignItems: 'middle',
            }}
          >
            <BootstrapBadge
              className={classes.badges}
              variant={getBadgeColor(row.value)}
            >
              {row.value}
            </BootstrapBadge>
          </div>
        </Link>
      ),
      width: getColumnWidth(
        [
          { status: '  pagada  ' },
          { status: '  por pagar  ' },
          { status: '  facturada  ' },
          { status: '  REFUND  ' },
        ],
        'status',
        'Estatus',
      ),
      Filter: (cell) => {
        return (
          // eslint-disable-next-line jsx-a11y/no-onchange
          <select
            onChange={(e) => cell.onChange(e.target.value)}
            value={
              cell.filter && cell.filter.value
                ? cell.filter.value
                : ''
            }
          >
            <option value="">Todos</option>
            <option value="open">Abierta</option>
            <option value="remission">Por pagar</option>
            <option value="payment">Pagada</option>
            <option value="billed">Facturada</option>
            <option value="canceled">Cancelada</option>
            <option value="REFUND">Devuelto</option>
          </select>
        );
      },
    },
    {
      Header: () => <strong>Notificaciones</strong>,
      accessor: 'serviceOrderNotifications',
      Cell: (row) => {
        let notificationSMS;
        let smsClicked;
        let notificationEmail;
        let emailClicked;
        let notificationWhatsapp;
        let whatsappClicked;
        row.value.forEach((notification) => {
          switch (notification.type) {
            case 'readyToPayEmail':
              notificationEmail = true;
              emailClicked = notification.clicked;
              break;
            case 'readyToPaySMS':
              notificationSMS = true;
              smsClicked = notification.clicked;
              break;
            case 'readyToPayWhats':
              notificationWhatsapp = true;
              whatsappClicked = notification.clicked;
              break;

            default:
              break;
          }
        });
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <ShouldItRender
              should={Boolean(row.original.smsNotifications)}
            >
              <Tooltip
                disableFocusListener={!notificationSMS}
                disableHoverListener={!notificationSMS}
                disableTouchListener={!notificationSMS}
                title={
                  smsClicked
                    ? 'Orden abierta por sms'
                    : 'Mensaje SMS enviado'
                }
              >
                <MaterialBadge
                  className={classes.notifications}
                  color="error"
                  badgeContent={smsClicked ? 1 : null}
                >
                  <TextsmsIcon
                    color={notificationSMS ? 'primary' : 'disabled'}
                  />
                </MaterialBadge>
              </Tooltip>
            </ShouldItRender>
            <ShouldItRender
              should={Boolean(row.original.emailNotifications)}
            >
              <Tooltip
                disableFocusListener={!notificationEmail}
                disableHoverListener={!notificationEmail}
                disableTouchListener={!notificationEmail}
                title={
                  emailClicked
                    ? 'Orden abierta por correo'
                    : 'Correo enviado'
                }
              >
                <MaterialBadge
                  className={classes.notifications}
                  badgeContent={emailClicked ? 1 : null}
                  color="error"
                >
                  <EmailIcon
                    color={notificationEmail ? 'primary' : 'disabled'}
                  />
                </MaterialBadge>
              </Tooltip>
            </ShouldItRender>
            <ShouldItRender
              should={Boolean(row.original.whatsNotifications)}
            >
              <Tooltip
                disableFocusListener={!notificationWhatsapp}
                disableHoverListener={!notificationWhatsapp}
                disableTouchListener={!notificationWhatsapp}
                title={
                  whatsappClicked
                    ? 'Orden abierta por WhatsApp'
                    : 'Whatsapp enviado'
                }
              >
                <MaterialBadge
                  className={classes.notifications}
                  badgeContent={whatsappClicked ? 1 : null}
                  color="error"
                >
                  <WhatsAppIcon
                    color={
                      notificationWhatsapp ? 'primary' : 'disabled'
                    }
                  />
                </MaterialBadge>
              </Tooltip>
            </ShouldItRender>
          </div>
        );
      },
      width: 127,
      filterable: false,
    },
  ];

  useEffect(() => {
    const CSV = [];
    let searchIn;
    !Array.isArray(fullOrders)
      ? (searchIn = ordersFiltered)
      : (searchIn = orders);
    searchIn
      .filter((x) => x['isActiveAgency'] === true)
      .forEach((cursor) => {
        let msNotifications;
        let emailNotifications;
        let whatsNotifications;
        cursor.msNotifications
          ? (msNotifications = 'Si')
          : (msNotifications = 'No');
        cursor.emailNotifications
          ? (emailNotifications = 'Si')
          : (emailNotifications = 'No');
        cursor.whatsNotifications
          ? (whatsNotifications = 'Si')
          : (whatsNotifications = 'No');
        const data = {
          // prettier-ignore
          'ID': cursor.orderID || '-',
          // prettier-ignore
          'Agencia': cursor.agencyName || '-',
          // prettier-ignore
          'Placa': cursor.plates || '-',
          // prettier-ignore
          'Vin': cursor.vin || '-',
          // prettier-ignore
          'Asesor': cursor.advisor || '-',
          // prettier-ignore
          'Última actualización': cursor.updatedAt || '-',
          // prettier-ignore
          'Fecha de creación': cursor.createdDate || '-',
          // prettier-ignore
          'Vehiculo': cursor.vehicle || '-',
          // prettier-ignore
          'Total': cursor.total || '-',
          // prettier-ignore
          'Estatus': cursor.status || '-',
          // prettier-ignore
          'Notif. SMS': msNotifications || '-',
          // prettier-ignore
          'Notif. Correo': emailNotifications || '-',
          // prettier-ignore
          'Notif. WhatsApp': whatsNotifications || '-',
        };
        CSV.push(data);
      });
    setDataToCSV(CSV);
  }, [orders, filtered]);

  useEffect(() => {
    props.setActionButton(
      <>
        <Grid container justify="flex-start">
            <MuiPickersUtilsProvider
              utils={MomentUtils}
              locale={'es'}
            >
              <DatePicker
                id="mui-pickers-firstDate"
                autoOk
                disableToolbar
                disableFuture
                variant="inline"
                label="Inicial"
                format="DD/MM"
                className={classes.datePicker}
                value={firstDate}
                onChange={handleFirstDate}
                InputProps={{
                  endAdornment: firstDate ? (
                    <IconButton
                      aria-label="Select locale"
                      size="small"
                      onClick={handleClearFirstDate}
                    >
                      <Close />
                    </IconButton>
                  ) : null,
                }}
              />
              <span className={classes.range}>&nbsp;A&nbsp;</span>
              <DatePicker
                id="mui-pickers-secondDate"
                autoOk
                disableToolbar
                disableFuture
                variant="inline"
                label="Final"
                format="DD/MM"
                className={classes.datePicker}
                value={secondDate}
                onChange={handleSecondDate}
                InputProps={{
                  endAdornment: secondDate ? (
                    <IconButton
                      aria-label="Select locale"
                      size="small"
                      onClick={handleClearSecondDate}
                    >
                      <Close />
                    </IconButton>
                  ) : null,
                }}
              />
            </MuiPickersUtilsProvider>
            <div style={{marginRight: "20px"}}>
              <CSVLink
                data={dataToCSV}
                filename={ tabSelected === 0 ? 'Ordenes_Servicio.csv' : 'Ordenes_Servicio_Pendientes_Facturar.csv'}
                onClick={() => {
                  if (disableDownloadCSV) return false;
                }}
              >
                <Button
                  variant="contained"
                  className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-t-10 btnSize"
                  disabled={disableDownloadCSV}
                >
                  Exportar
                  <GetApp className="kt-margin-l-10" />
                </Button>
              </CSVLink>
            </div>
            {agency && agency.nickname === 'nissan-obregon' && (
              <Button
                variant="contained"
                className="btn btn-label-success btn-bold"
                onClick={toogleImportOrderModal}
              >
                Agregar orden para pago
                <Add className="kt-margin-l-10" />
              </Button>
            )}
            {agency && agency.DMS === 'quiter' && (
              <Button
                variant="contained"
                className="btn btn-label-success btn-bold kt-margin-t-10"
                onClick={toogleSearchOrderModal}
              >
                Buscar orden de servico
                <Add className="kt-margin-l-10" />
              </Button>
            )}
            {role === 'Super Admin' && (
              <MUIAutocompleteAgencies
                size="small"
                className={classes.agenciesSelect}
                value={agencySelect}
                onChange={(_, newValue) => {
                  setagencySelect(newValue?.id);
                }}
              />
            )}
        </Grid>
      </>,
    );
    // prettier-ignore
  }, [
    dataToCSV,
    firstDate,
    secondDate,
    role,
    agency,
    agencySelect,
    disableDownloadCSV,
  ]);

  const renderErrorDateModal = () => {
    try {
      const title = 'Error en las fechas';
      const desc =
        'Por favor revise que la fecha inicial y la fecha final no estén invertidas ya que de lo contrario la información podría no ser correcta ni podrá descargar los reportes';
      return (
        <Modal
          open={showDateErrorModal}
          type={'error'}
          closeModal={handleCloseModals}
          dialogTitle={title}
          dialogText={desc}
          actionButtonText="Aceptar"
          onClick={handleCloseModals}
        />
      );
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <ReactTable
          ref={reactTable}
          NoDataComponent={() => (
            <div className="rt-noData">Sin resultados</div>
          )}
          manual
          onFetchData={handleFetchData}
          data={orders}
          pages={serviceOrders.tablePages}
          columns={columns}
          filterable
          className="-striped -highlight"
          previousText="Anterior"
          nextText="Siguiente"
          loading={serviceOrders.isLoading}
          loadingText="Cargando datos..."
        />
      </Paper>
      {importOrderDialog()}
      {renderFeedBackModal()}
      {searchOrderDialog()}
      {quiterModal()}
      {renderErrorDateModal()}
    </div>
  );
};

const mapStateToProps = (state) => ({
  serviceOrders: state.serviceOrders,
  fullOrders: state.serviceOrders.fullOrders,
  agency: state.agencies.agencyDetails,
  role: makeSelectUserRoleFromState(state),
});

const mapDispatchToProps = {
  getServiceOrders: getServiceOrders,
  importServiceOrder: importServiceOrder,
  closeModal: closeModals,
  searchServiceOrder,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(EnhancedTable),
);
