export const bankList = [
  '',
  'ABC CAPITAL',
  'ACCIVAL',
  'ACTINVER',
  'AFIRME',
  'AKALA',
  'AMERICAN EXPRESS',
  'ARCUS',
  'ASEA',
  'AUTOFIN',
  'AZTECA',
  'B&B',
  'BAJIO',
  'BAMSA',
  'BANAMEX',
  'BANREGIO',
  'BANCO FAMSA',
  'BANCO DE LA PAZ',
  'BANCOMEXT',
  'BANCOPPEL',
  'BANJERCITO',
  'BAÑO',
  'BANOBRAS',
  'BANORTE',
  'BANREGIO',
  'BANSEFI',
  'BANSI',
  'BARCLAYS',
  'BBASE',
  'BBVA BANCOMER',
  'BMONEX',
  'BMULTIVA',
  'BULLTICK CB',
  'CB ACTINVER',
  'CB INTERCAM',
  'CB JPMORGAN',
  'CBDEUTSCHE',
  'CI BOLSA',
  'CIBANCO',
  'CLS',
  'COMPARTAMOS',
  'CONSUBANCO',
  'CREDIT SUISSE',
  'DEUTSCHE',
  'ESTRUCTURADORES',
  'EVERCORE',
  'FINAMEX',
  'FINCOMUN',
  'GBM',
  'HDI SEGUROS',
  'HIPOTECARIA FEDERAL',
  'HSBC',
  'INBURSA',
  'INDEVAL',
  'ING',
  'INTERACCIONES',
  'INTERBANCO',
  'INTERCAM BANCO',
  'INVEX',
  'IXE',
  'JP MORGAN',
  'KUSPIT',
  'LIBERTAD',
  'MAPFRE',
  'MASARI',
  'MERRILL LYNCH',
  'MERCADO PAGO',
  'MIFEL',
  'MONEXCB',
  'NAFIN',
  'NVIO',
  'OACTIN',
  'OPCIONES EMPRESARIALES DEL NOROESTE',
  'ORDER',
  'PROFUTURO',
  'REFORMA',
  'SANTANDER',
  'SCOTIABANK',
  'SEGMTY',
  'SKANDIA VIDA',
  'SKANDIA OPERADORA DE FONDOS',
  'SOFIEXPRESS',
  'STERLING',
  'STP',
  'SU CASITA',
  'TELECOMM',
  'THE ROYAL BANK',
  'TIBER',
  'TOKYO',
  'UBS BANK',
  'UNAGRA',
  'UNICA',
  'VALMEX',
  'VALUE',
  'VE POR MAS',
  'VECTOR',
  'VOLKSWAGEN',
  'WAL-MART',
  'ZURICH',
  'ZURICHVI',
];
