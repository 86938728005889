import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Chart } from 'chart.js';
import { metronic } from '../../_metronic';
import moment from 'moment';
import { useSelector } from 'react-redux';

export default function OrderStatisticsChart(props) {
  const { payments } = props;
  const ref = useRef();
  const { brandColor, shape2Color, shape3Color } = useSelector(
    (state) => ({
      brandColor: metronic.builder.selectors.getConfig(
        state,
        'colors.state.brand',
      ),
      shape2Color: metronic.builder.selectors.getConfig(
        state,
        'colors.base.shape.2',
      ),
      shape3Color: metronic.builder.selectors.getConfig(
        state,
        'colors.base.shape.3',
      ),
    }),
  );

  const [paymentsCount, setPaymentsCount] = useState([
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ]);

  const today = moment();
  const lastDate = moment().subtract(11, 'days');

  const days = [
    lastDate,
    moment().subtract(10, 'days'),
    moment().subtract(9, 'days'),
    moment().subtract(8, 'days'),
    moment().subtract(7, 'days'),
    moment().subtract(6, 'days'),
    moment().subtract(5, 'days'),
    moment().subtract(4, 'days'),
    moment().subtract(3, 'days'),
    moment().subtract(2, 'days'),
    moment().subtract(1, 'days'),
    today,
  ];

  useEffect(() => {
    getPaymentsByDay();
    return () =>
      setPaymentsCount([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payments]);

  const getPaymentsByDay = () => {
    if (
      payments &&
      Array.isArray(payments.fromdb) &&
      payments.fromdb.length > 0
    ) {
      const tempPaymentsCount = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      payments.fromdb.forEach((payment) => {
        const updatedAt = moment(payment.updatedAt);
        if (updatedAt.isBetween(days[0], days[11]))
          days.forEach((day, idx) => {
            if (updatedAt.isSame(day, 'day')) {
              tempPaymentsCount[idx]++;
            }
          });
      });
      setPaymentsCount(tempPaymentsCount);
    }
  };

  const data = useMemo(
    () => ({
      labels: [
        `${days[0].format('DD MMMM')}`,
        `${days[1].format('DD MMMM')}`,
        `${days[2].format('DD MMMM')}`,
        `${days[3].format('DD MMMM')}`,
        `${days[4].format('DD MMMM')}`,
        `${days[5].format('DD MMMM')}`,
        `${days[6].format('DD MMMM')}`,
        `${days[7].format('DD MMMM')}`,
        `${days[8].format('DD MMMM')}`,
        `${days[9].format('DD MMMM')}`,
        `${days[10].format('DD MMMM')}`,
        `${days[11].format('DD MMMM')}`,
      ],
      datasets: [
        {
          fill: true,
          // borderWidth: 0,
          backgroundColor: Chart.helpers
            .color(brandColor)
            .alpha(0.76)
            .rgbString(),

          borderColor: Chart.helpers
            .color(brandColor)
            .alpha(0)
            .rgbString(),

          pointHoverRadius: 4,
          pointHoverBorderWidth: 12,
          pointBackgroundColor: Chart.helpers
            .color('#000000')
            .alpha(0)
            .rgbString(),
          pointBorderColor: Chart.helpers
            .color('#000000')
            .alpha(0)
            .rgbString(),
          pointHoverBackgroundColor: brandColor,
          pointHoverBorderColor: Chart.helpers
            .color('#000000')
            .alpha(0.1)
            .rgbString(),

          data: paymentsCount,
        },
      ],
    }),
    [brandColor, days, paymentsCount],
  );

  useEffect(() => {
    // For more information about the chartjs, visit this link
    // https://www.chartjs.org/docs/latest/getting-started/usage.html

    const chart = new Chart(ref.current, {
      data,
      type: 'bar',
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: false,
        scales: {
          xAxes: [
            {
              categoryPercentage: 0.35,
              barPercentage: 0.7,
              display: true,
              scaleLabel: {
                display: false,
                labelString: 'Month',
              },
              gridLines: false,
              ticks: {
                display: true,
                beginAtZero: true,
                fontColor: shape3Color,
                fontSize: 13,
                padding: 10,
              },
            },
          ],
          yAxes: [
            {
              categoryPercentage: 0.35,
              barPercentage: 0.7,
              display: true,
              scaleLabel: {
                display: false,
                labelString: 'Value',
              },
              gridLines: {
                color: shape2Color,
                drawBorder: false,
                offsetGridLines: false,
                drawTicks: false,
                borderDash: [3, 4],
                zeroLineWidth: 1,
                zeroLineColor: shape2Color,
                zeroLineBorderDash: [3, 4],
              },
              ticks: {
                max: 15,
                stepSize: 3,
                display: true,
                beginAtZero: true,
                fontColor: shape3Color,
                fontSize: 13,
                padding: 10,
              },
            },
          ],
        },
        title: {
          display: false,
        },
        hover: {
          mode: 'ErrorsPage.js',
        },
        tooltips: {
          enabled: true,
          intersect: false,
          mode: 'nearest',
          bodySpacing: 5,
          yPadding: 10,
          xPadding: 10,
          caretPadding: 0,
          displayColors: false,
          backgroundColor: brandColor,
          titleFontColor: '#ffffff',
          cornerRadius: 4,
          footerSpacing: 0,
          titleSpacing: 0,
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 5,
            bottom: 5,
          },
        },
      },
    });

    return () => {
      chart.destroy();
    };
  }, [data, brandColor, shape2Color, shape3Color]);

  return (
    <div className="kt-widget12">
      <div className="kt-widget12__content">
        {/* <div className="kt-widget12__item">
          <div className="kt-widget12__info">
            <span className="kt-widget12__desc">
              Pagos realizados en Karlo
            </span>
            <span className="kt-widget12__value">
              {getNested(0, payments, 'count')}
            </span>
          </div>
          <div className="kt-widget12__info">
            <span className="kt-widget12__desc">Pagos a meses</span>
            <div className="kt-widget12__progress">
              <div className="progress kt-progress--sm">
                <div
                  role="progressbar"
                  aria-valuemin={0}
                  aria-valuenow={100}
                  aria-valuemax={100}
                  style={{ width: '40%' }}
                  className="progress-bar bg-success"
                />
              </div>
              <span className="kt-widget12__stat">40%</span>
            </div>
          </div>

          <div className="kt-widget12__info">
            <span className="kt-widget12__desc">
              Pagos facturados
            </span>
            <span className="kt-widget12__value">
              {getNested(0, payments, 'invoicedPayments')}
            </span>
          </div>
        </div> */}
        {/* <div className="kt-widget12__item">
          <div className="kt-widget12__info">
            <span className="kt-widget12__desc">
              Total de los pagos
            </span>
            <span className="kt-widget12__value">
              {formatNumber(getNested(0, payments, 'total'))}
            </span>
          </div>
          <div className="kt-widget12__info">
            <span className="kt-widget12__desc">
              Monto de pago promedio
            </span>
            <span className="kt-widget12__value">
              {formatNumber(
                getNested(0, payments, 'total') /
                  getNested(0, payments, 'count'),
              )}
            </span>
            <span className="kt-widget12__desc">
              Pagos de contado
            </span>
            <div className="kt-widget12__progress">
              <div className="progress kt-progress--sm">
                <div
                  role="progressbar"
                  aria-valuemin={0}
                  aria-valuenow={100}
                  aria-valuemax={100}
                  style={{ width: '40%' }}
                  className="progress-bar bg-success"
                />
              </div>
              <span className="kt-widget12__stat">40%</span>
            </div>
          </div>
        </div> */}
      </div>
      <div className="kt-widget12__chart" style={{ height: '250px' }}>
        <canvas
          ref={ref}
          width={683}
          height={312}
          id="kt_chart_order_statistics"
        />
      </div>
    </div>
  );
}
