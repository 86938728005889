import {
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import {
  DatePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import {
  filterCaseInsensitive,
  formatDate,
  formatNumber,
  getColumnWidth,
  getNested,
} from 'app/utils';

import Close from '@material-ui/icons/Close';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import MUIAutocompleteAgencies from 'app/components/MUIAutocompleteAgencies';
import Modal from 'app/components/Modal';
import MomentUtils from '@date-io/moment';
import ReactTable from 'react-table';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

/* eslint-disable react/display-name */

const useStyles = makeStyles((theme) => ({
  paper: {
    widht: '100%',
    marginBottom: theme.spacing(0),
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2, 0, 2, 0),
  },
  checkbox: {
    padding: '0px',
  },
  selectedContainder: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  selectedCheckbox: {
    padding: '2px',
  },
  createDeposit: {
    marginRight: theme.spacing(1),
  },
  textField: {
    margin: theme.spacing(1, 0, 1, 0),
  },
}));

export const SpeiValidatedTable = (props) => {
  const classes = useStyles();
  const {
    speiPayments,
    speiPaymentsFromdb,
    getAllSpeiPayments,
    createDeposit,
    changeToDepositsCreated,
    cleanSpeiPaymentsLoaded,
  } = props;
  const [agencyToSearch, setAgencyToSearch] = useState(null);
  const [
    selectedItemsFromTable,
    setSelectedItemsFromTable,
  ] = useState([]);
  const [
    showCreateDeopsitModal,
    setShowCreateDepositModal,
  ] = useState(false);

  const reactTable = React.useRef(null);

  const {
    handleSubmit,
    register,
    errors,
    control,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      depositTime: moment(),
    },
  });

  const values = watch();

  useEffect(() => {
    cleanSpeiPaymentsLoaded();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchPayments = () => {
    if (agencyToSearch)
      getAllSpeiPayments({
        agencyID: agencyToSearch,
      });
  };

  const getOrderTypeFromPayment = (originalRow) => {
    let orderIdentificator;
    if (originalRow.serviceOrderID)
      orderIdentificator = 'serviceOrder';
    else if (originalRow.paymentOrderID)
      orderIdentificator = 'paymentOrder';
    else if (originalRow.creditOrderID)
      orderIdentificator = 'creditOrder';
    return orderIdentificator;
  };

  const getNamePayment = (cardHolder, originalRow) => {
    if (cardHolder) return cardHolder;
    const orderType = getOrderTypeFromPayment(originalRow);
    if (orderType === 'serviceOrder' && originalRow[orderType]) {
      return getNested(
        '-',
        originalRow[orderType],
        'invoiceProfile',
        'fullName',
      );
    } else if (
      orderType === 'paymentOrder' &&
      originalRow[orderType]
    ) {
      return getNested('-', originalRow[orderType], 'nombre');
    } else if (
      orderType === 'creditOrder' &&
      originalRow[orderType]
    ) {
      return originalRow[orderType].clientName;
    }
    return '-';
  };

  const handleCreateDeposit = (formData) => {
    const data = {
      ...formData,
      agencyID: agencyToSearch.id,
      SPEIPayments: selectedItemsFromTable,
      changeToDepositsCreated,
    };
    createDeposit(data);
    setShowCreateDepositModal(false);
  };

  const renderCreateDeposit = () => {
    return (
      <Modal
        open={showCreateDeopsitModal}
        closeModal={() => setShowCreateDepositModal(false)}
        dialogTitle="Crear depósito"
        dialogText="Ingrese los siguientes datos del depósito."
        actionButtonText="Validar"
        dialogChildren={
          <form
            id="create-deposit-form"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(handleCreateDeposit)}
          >
            <TextField
              name="name"
              label="Concepto"
              error={Boolean(errors.name)}
              className={classes.textField}
              inputRef={register({
                required: true,
              })}
              margin="normal"
              variant="outlined"
            />
            <TextField
              name="bankAuthorization"
              label="Folio de Autorización Bancaria"
              error={Boolean(errors.bankAuthorization)}
              className={classes.textField}
              inputRef={register({
                required: true,
              })}
              margin="normal"
              variant="outlined"
            />
            <Controller
              control={control}
              name="depositTime"
              rules={{ required: true }}
              as={
                <MuiPickersUtilsProvider
                  utils={MomentUtils}
                  locale={'es'}
                >
                  <KeyboardDateTimePicker
                    autoOk
                    fullWidth
                    disableFuture
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    className={classes.textField}
                    label="Fecha de depósito"
                    value={values.depositTime}
                    onChange={(e) =>
                      setValue('depositTime', e, false)
                    }
                    format="DD/MM/yyyy HH:mm"
                    ampm={false}
                    invalidDateMessage="Fecha inválida"
                    invalidLabel="Fecha inválida"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Ingrese la fecha y hora en modo de 24hrs">
                            <HelpOutlineIcon />
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </MuiPickersUtilsProvider>
              }
            />
          </form>
        }
        form={'create-deposit-form'}
      />
    );
  };

  const handleSelectRowFromTable = (id) => {
    const tempSelectedItemsFromTable = [...selectedItemsFromTable];
    const position = tempSelectedItemsFromTable.indexOf(id);
    if (position !== -1)
      tempSelectedItemsFromTable.splice(position, 1);
    else tempSelectedItemsFromTable.push(id);
    setSelectedItemsFromTable(tempSelectedItemsFromTable);
  };

  const colums = [
    {
      Header: () => (
        <div className={classes.selectedContainder}>
          <Checkbox className={classes.checkbox} checked={true} />
          <strong>
            &nbsp;Seleccionados {selectedItemsFromTable.length}
          </strong>
        </div>
      ),
      accessor: 'SPEIPayment.id',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <Checkbox
            className={classes.checkbox}
            checked={selectedItemsFromTable.includes(row.value)}
            onChange={() => handleSelectRowFromTable(row.value)}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </div>
      ),
      filterable: false,
      width: 150,
    },
    {
      Header: () => <strong>Agencia</strong>,
      accessor: 'agencyName',
      Cell: (row) => {
        const orderType = getOrderTypeFromPayment(row.original);
        const agencyName = getNested(
          '-',
          row.original[orderType],
          'agency',
          'name',
        );
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {agencyName}
          </div>
        );
      },
    },
    {
      Header: () => <strong>Cliente</strong>,
      accessor: 'name',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {getNamePayment(row.value, row.original)}
        </div>
      ),
      width: 150,
    },
    {
      Header: () => <strong>Monto</strong>,
      accessor: 'amount',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {formatNumber(row.value)}
        </div>
      ),
      width: getColumnWidth(
        getNested([], speiPaymentsFromdb),
        'total',
        'Total    ',
      ),
    },
    {
      Header: () => <strong>Fecha de pago</strong>,
      accessor: 'SPEIPayment.date',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value ? formatDate(row.value) : '-'}
        </div>
      ),
      Filter: (cell) => {
        return (
          <MuiPickersUtilsProvider utils={MomentUtils} locale={'es'}>
            <DatePicker
              autoOk
              disableToolbar
              disableFuture
              format="DD/MM"
              className={classes.datePicker}
              value={
                cell.filter && cell.filter.value
                  ? cell.filter.value
                  : null
              }
              onChange={(e) => cell.onChange(e)}
              inputProps={{
                className: classes.datePickerTextfield,
              }}
              InputProps={{
                disableUnderline: true,
                endAdornment:
                  cell.filter && cell.filter.value ? (
                    <IconButton
                      aria-label="Select locale"
                      size="small"
                      onClick={() => cell.onChange(null)}
                    >
                      <Close />
                    </IconButton>
                  ) : null,
              }}
            />
          </MuiPickersUtilsProvider>
        );
      },
      width: getColumnWidth(
        getNested([], speiPaymentsFromdb),
        'date',
        'Fecha de pago     ',
      ),
    },
  ];

  return (
    <Paper>
      {renderCreateDeposit()}
      <div className={classes.searchContainer}>
        {selectedItemsFromTable.length > 0 && (
          <Button
            className={clsx(
              'btn btn-label-info btn-bold kt-margin-l-10',
              classes.createDeposit,
            )}
            onClick={() => setShowCreateDepositModal(true)}
          >
            Crear depósito
          </Button>
        )}
        {/* {selectedItemsFromTable.length === 0 && (
          <>
            <MUIAutocompleteAgencies
              size="small"
              style={{ width: 250 }}
              className={clsx(
                classes.textfields,
                classes.referenceTextfield,
              )}
              value={agencyToSearch}
              onChange={(_, newValue) => {
                setAgencyToSearch(newValue.id);
              }}
            />
            <Button
              className="btn btn-label-success btn-bold kt-margin-l-10"
              onClick={handleSearchPayments}
            >
              Buscar pagos
            </Button>
          </>
        )} */}
      </div>
      <ReactTable
        ref={reactTable}
        NoDataComponent={() => (
          <div className="rt-noData">Sin resultados</div>
        )}
        data={getNested([], speiPaymentsFromdb)}
        columns={colums}
        defaultFilterMethod={filterCaseInsensitive}
        // pages={}
        className="-striped -highlight"
        previousText="Anterior"
        nextText="Siguiente"
        loading={speiPayments.isLoading}
        loadingText="Cargando datos..."
      />
    </Paper>
  );
};
