export const rolesByRole = {
  'Super Admin': [
    {
      value: '',
      label: '',
    },
    {
      subheader: 'Super administradores',
    },
    {
      value: 'Super Admin Agencies',
      label: 'Super administrador de agencias',
    },
    {
      value: 'Agency Manager',
      label: 'Manejador de agencia',
    },
    {
      value: 'Super Admin',
      label: 'Administrador Karlo',
    },
    {
      value: 'Super Admin Lite',
      label: 'Administrador Karlo Lite',
    },
    {
      value: 'Super Admin WTF',
      label: 'Administrador WTF',
    },
    {
      value: 'WTF Asesor',
      label: 'Asesor WTF',
    },
    {
      subheader: 'Roles normales',
    },
    {
      value: 'Agency Admin',
      label: 'Administrador de agencia',
    },
    {
      value: 'f&i',
      label: 'f&i y garantías',
    },
    {
      value: 'Service Manager',
      label: 'Gerente de servicio',
    },
    {
      value: 'Service Asesor',
      label: 'Asesor de servicio',
    },
    {
      value: 'Sales Manager',
      label: 'Gerente de ventas',
    },
    {
      value: 'Sales Advisor',
      label: 'Asesor de ventas',
    },
    {
      value: 'Parts Manager',
      label: 'Gerente de refacciones',
    },
    {
      value: 'Parts Asesor',
      label: 'Refacciones',
    },
    {
      value: 'Marketing Agent',
      label: 'Mercadotecnia',
    },
    {
      value: 'Accounting',
      label: 'Contabilidad',
    },
    {
      value: 'Cashier',
      label: 'Caja',
    },
    {
      subheader: 'Créditos',
    },
    {
      value: 'Credit Administrator',
      label: 'Administrador de Crédito',
    },
    {
      value: 'Credit Analyst',
      label: 'Analista',
    },
    {
      subheader: 'Clear',
    },
    {
      value: 'Clear Admin',
      label: 'Clear Admin',
    },
    {
      value: 'Customer Success',
      label: 'Customer Success',
    },
    {
      subheader: 'W32 Roles',
    },
    {
      value: 'W32 Agency Admin',
      label: 'Administrador de agencia (W32)',
    },
    {
      value: 'W32 Asesor',
      label: 'Asesor (W32)',
    },
    {
      value: 'W32 Accounting',
      label: 'Contabilidad (W32)',
    },
    {
      value: 'W32 Parts Asesor',
      label: 'Refacciones (W32)',
    },
    {
      subheader: 'ABG Roles',
    },
    {
      value: 'ABG Agency Admin',
      label: 'Administrador de agencia (ABG)',
    },
    {
      value: 'ABG Service Manager',
      label: 'Gerente de servicio (ABG)',
    },
    {
      value: 'ABG Asesor',
      label: 'Asesor (ABG)',
    },
    {
      value: 'ABG Sales Manager',
      label: 'Gerente de ventas (ABG)',
    },
    {
      value: 'ABG Sales Advisor',
      label: 'Asesor de ventas (ABG)',
    },
    {
      value: 'ABG Asesor PreOwned',
      label: 'Asesor seminuevos (ABG)',
    },
    {
      value: 'ABG Parts Manager',
      label: 'Gerente de Refacciones (ABG)',
    },
    {
      value: 'ABG Parts Asesor',
      label: 'Refacciones (ABG)',
    },
    {
      value: 'ABG Accounting',
      label: 'Contabilidad (ABG)',
    },
    {
      value: 'ABG Cashier',
      label: 'Cajas (ABG)',
    },
    {
      subheader: 'Otros',
    },
    {
      value: 'Payments Searcher',
      label: 'Selene',
    },
  ],
  'Agency Admin': [
    {
      value: '',
      label: '',
    },
    {
      subheader: 'Gerentes',
    },
    {
      value: 'Service Manager',
      label: 'Gerente de servicio',
    },
    {
      value: 'Sales Manager',
      label: 'Gerente de ventas',
    },
    {
      value: 'Parts Manager',
      label: 'Gerente de refacciones',
    },
    {
      subheader: 'Asesores',
    },
    {
      value: 'Service Asesor',
      label: 'Asesor de servicio',
    },
    {
      value: 'f&i',
      label: 'f&i y garantías',
    },
    {
      value: 'Sales Advisor',
      label: 'Asesor de ventas',
    },
    {
      value: 'Parts Asesor',
      label: 'Refacciones',
    },
    {
      subheader: 'Marketing',
    },
    {
      value: 'Marketing Agent',
      label: 'Mercadotecnia',
    },
    {
      subheader: 'Contabilidad',
    },
    {
      value: 'Accounting',
      label: 'Contabilidad',
    },
    {
      value: 'Cashier',
      label: 'Caja',
    },
  ],
  'Super Admin Agencies': [
    {
      value: '',
      label: '',
    },
    {
      subheader: 'Gerentes',
    },
    {
      value: 'Service Manager',
      label: 'Gerente de servicio',
    },
    {
      value: 'Sales Manager',
      label: 'Gerente de ventas',
    },
    {
      value: 'Parts Manager',
      label: 'Gerente de refacciones',
    },
    {
      subheader: 'Asesores',
    },
    {
      value: 'Service Asesor',
      label: 'Asesor de servicio',
    },
    {
      value: 'f&i',
      label: 'f&i y garantías',
    },
    {
      value: 'Sales Advisor',
      label: 'Asesor de ventas',
    },
    {
      value: 'Parts Asesor',
      label: 'Refacciones',
    },
    {
      subheader: 'Marketing',
    },
    {
      value: 'Marketing Agent',
      label: 'Mercadotecnia',
    },
    {
      subheader: 'Contabilidad',
    },
    {
      value: 'Accounting',
      label: 'Contabilidad',
    },
    {
      value: 'Cashier',
      label: 'Caja',
    },
  ],
  'Agency Manager': [
    {
      value: '',
      label: '',
    },
    {
      subheader: 'Gerentes',
    },
    {
      value: 'Service Manager',
      label: 'Gerente de servicio',
    },
    {
      value: 'Sales Manager',
      label: 'Gerente de ventas',
    },
    {
      value: 'Parts Manager',
      label: 'Gerente de refacciones',
    },
    {
      subheader: 'Asesores',
    },
    {
      value: 'Service Asesor',
      label: 'Asesor de servicio',
    },
    {
      value: 'f&i',
      label: 'f&i y garantías',
    },
    {
      value: 'Sales Advisor',
      label: 'Asesor de ventas',
    },
    {
      value: 'Parts Asesor',
      label: 'Refacciones',
    },
    {
      subheader: 'Marketing',
    },
    {
      value: 'Marketing Agent',
      label: 'Mercadotecnia',
    },
    {
      subheader: 'Contabilidad',
    },
    {
      value: 'Accounting',
      label: 'Contabilidad',
    },
    {
      value: 'Cashier',
      label: 'Caja',
    },
  ],
  'Clear Admin': [
    {
      value: '',
      label: '',
    },
    {
      value: 'Customer Success',
      label: 'Customer Success',
    },
  ],
  'Credit Administrator': [
    {
      value: '',
      label: '',
    },
    {
      value: 'Credit Analyst',
      label: 'Analista',
    },
  ],
  'W32 Agency Admin': [
    {
      value: '',
      label: '',
    },
    {
      value: 'W32 Asesor',
      label: 'Asesor',
    },
    {
      value: 'W32 Accounting',
      label: 'Contabilidad',
    },
    {
      value: 'W32 Parts Asesor',
      label: 'Refacciones',
    },
  ],
  'ABG Agency Admin': [
    {
      value: '',
      label: '',
    },
    {
      subheader: 'Gerentes',
    },
    {
      value: 'ABG Service Manager',
      label: 'Gerente de servicio',
    },
    {
      value: 'ABG Sales Manager',
      label: 'Gerente de ventas',
    },
    {
      value: 'ABG Parts Manager',
      label: 'Gerente de Refacciones',
    },
    {
      subheader: 'Asesores',
    },
    {
      value: 'ABG Asesor',
      label: 'Asesor',
    },
    {
      value: 'ABG Asesor PreOwned',
      label: 'Asesor seminuevos',
    },
    {
      value: 'ABG Sales Advisor',
      label: 'Asesor de ventas',
    },
    {
      value: 'ABG Parts Asesor',
      label: 'Refacciones',
    },
    {
      subheader: 'Contabilidad',
    },
    {
      value: 'ABG Accounting',
      label: 'Contabilidad',
    },
    {
      value: 'ABG Cashier',
      label: 'Cajas',
    },
  ],
  default: [
    {
      value: 'Service Asesor',
      label: 'Asesor de servicio',
    },
    {
      value: 'Marketing Agent',
      label: 'Mercadotecnia',
    },
    {
      value: 'Accounting',
      label: 'Contabilidad',
    },
    {
      value: 'Parts Asesor',
      label: 'Refacciones',
    },
  ],
};
