/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { Paper } from '@material-ui/core';

import { formatNumber, getColumnWidth, getNested, formatSingleDate, formatSingleDateBillpocket } from 'app/utils';
import MUIDatePickerFilter from 'app/components/MUIDatePickerFilter';

import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import { debounce } from 'lodash';
import moment from 'moment';

export const DepositsCreatedTable = (props) => {
  const {
    deposits,
    depositsFromdb,
    getAllDeposits,
    reactTableRef,
    firstDate,
    secondDate,
  } = props;

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sorted, setSorted] = useState([]);
  const [filtered, setFiltered] = useState('');

  useEffect(() => {
    if ((firstDate && secondDate) || (!firstDate && !secondDate)) {
      handleFetchData();
    }
  }, [firstDate, secondDate]);

  const colums = [
    {
      Header: () => <strong>ID</strong>,
      accessor: 'id',
      Cell: (row) => {
        return (
          <Link to={handleTable(row)}>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              {row.value}
            </div>
          </Link>
        );
      },
    },
    {
      Header: () => <strong>Agencia</strong>,
      accessor: 'agency.name',
      Cell: (row) => {
        return (
          <Link to={handleTable(row)}>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              {row.value}
            </div>
          </Link>
        );
      },
    },
    {
      Header: () => <strong>Clave rastreo</strong>,
      accessor: 'name',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      width: 250,
    },
    {
      Header: () => <strong>Monto</strong>,
      accessor: 'amount',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {formatNumber(row.value)}
          </div>
        </Link>
      ),
      width: getColumnWidth(
        getNested([], depositsFromdb),
        'total',
        'Total    ',
      ),
    },
    {
      Header: () => <strong>Fecha de depósito</strong>,
      accessor: 'depositTime',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value
              ?  formatSingleDateBillpocket(row.value)
              : '-'}
          </div>
        </Link>
      ),
      Filter: (cell) => {
        return <MUIDatePickerFilter cell={cell} />;
      },
      width: getColumnWidth(
        getNested([], depositsFromdb),
        'date',
        'Fecha de pago     ',
      ),
    },
    {
      Header: () => <strong>Suma total</strong>,
      accessor: 'totalAmount',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>Suma de comisiones</strong>,
      accessor: 'totalCommission',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>Suma de IVA</strong>,
      accessor: 'VATSum',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>Total depositado</strong>,
      accessor: 'totalDeposited',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>Total contracargos</strong>,
      accessor: 'fullChargeback',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
  ];

  const handleFetchData = debounce(
    (state, instance) => {
      if (state) {
        const { page, pageSize, sorted, filtered } = state;
        getAllDeposits({
          page,
          pageSize,
          sorted,
          filtered,
          firstDate,
          secondDate,
        });
        setPage(page);
        setPageSize(pageSize);
        setSorted(sorted);
        setFiltered(filtered);
      } else {
        getAllDeposits({
          page,
          pageSize,
          sorted,
          filtered,
          firstDate,
          secondDate,
        });
      }
    },
    1000,
    { leading: false, trailing: true },
  );

  const handleTable = (row) => {
    return `/detail-deposit/${row.original.id}`;
  };

  return (
    <Paper>
      <ReactTable
        ref={reactTableRef}
        NoDataComponent={() => (
          <div className="rt-noData">Sin resultados</div>
        )}
        filterable
        manual
        onFetchData={handleFetchData}
        data={getNested([], depositsFromdb)}
        pages={deposits.tablePagesFromdb}
        columns={colums}
        className="-striped -highlight"
        previousText="Anterior"
        nextText="Siguiente"
        loading={deposits.isLoading}
        loadingText="Cargando datos..."
      />
    </Paper>
  );
};
