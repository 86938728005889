export const permissions = {
  'Super Admin': {
    '/advances': ['createAdvance'],
    '/paymentorders': ['createOrder'],
    '/creditorders': [
      'exportCreditOrders',
      'importCreditOrders',
      'createCreditOrder',
      'importPayments',
    ],
    '/serviceorders': ['getOrderFromDMS'],
    '/detail-credit-order': [
      'shareLink',
      'extraordinaryPayment',
      'externalPayment',
      'cancelOrder',
      'update',
    ],
    '/add-paymentorder': ['add', 'delete', 'setProvisionalPayment'],
    '/add-advance': ['selectAgency'],
    '/add-external-advance': ['selectAgency'],
    '/add-creditorder': ['selectAgency'],
    '/users': ['add', 'selectAgency'],
    '/detail-payment-order': [
      'addPromotions',
      'addAdvance',
      'shareLink',
      'cancelOrder',
    ],
    '/detail-advance': ['shareLink', 'cancelAdvance'],
    '/detail-payment': [
      'send&DownloadReceipt',
      'validateInvoice',
      'viewPaymentComissions',
    ],
    '/detail-order': [
      'orderToPay',
      'updateOrder',
      'shareLink',
      'selectDepartmentType',
      'addExternalPayment',
      'addAdvance',
      'addPromotions',
    ],
    '/agencies': ['add'],
    '/detail-agency': ['edit'],
    '/deposits': ['importDeposits'],
    '/depositfiserv': ['importDepositsFiserv'],
  },
  'Super Admin Agencies': {
    '/detail-payment-order': ['shareLink', 'cancelOrder', 'addPromotions'],
    '/detail-order': ['shareLink'],
    '/users': ['add'],
    '/deposits': ['importDeposits'],
    '/depositfiserv': ['importDepositsFiserv'],
    '/detail-payment': [
      'send&DownloadReceipt',
      'validateInvoice',
    ],
  },
  'Agency Manager': {
    '/detail-payment-order': ['shareLink',
     'cancelOrder',
     'addPromotions',
    ],
    '/detail-order': [
      'shareLink',
      'addPromotions',
    ],
    '/users': ['add'],
    '/deposits': ['importDeposits'],
    '/depositfiserv': ['importDepositsFiserv'],
    '/detail-payment': [
      'send&DownloadReceipt',
      'validateInvoice',
    ],
  },
  'Super Admin Lite': {},
  'Agency Admin': {
    '/advances': ['createAdvance'],
    '/detail-advance': ['shareLink', 'cancelAdvance'],
    '/paymentorders': ['createOrder'],
    '/add-paymentorder': ['setProvisionalPayment'],
    '/users': ['add', 'view'],
    '/detail-payment-order': [
      'addPromotions',
      'addAdvance',
      'shareLink',
      'cancelOrder',
    ],
    '/detail-payment': [
      'send&DownloadReceipt',
      'validateInvoice',
      'viewPaymentComissions',
    ],
    '/detail-order': [
      'addPromotions',
      'orderToPay',
      'shareLink',
      'selectDepartmentType',
      'addExternalPayment',
      'addAdvance',
    ],
    '/deposits': ['importDeposits'],
    '/depositfiserv': ['importDepositsFiserv'],
  },
  'Clear Admin': {
    '/paymentorders': ['createOrder'],
    '/add-paymentorder': ['setProvisionalPayment'],
    '/users': ['add', 'view'],
    '/detail-payment-order': [
      'addPromotions',
      'addAdvance',
      'shareLink',
      'cancelOrder',
    ],
    '/detail-payment': [
      'send&DownloadReceipt',
      'validateInvoice',
      'viewPaymentComissions',
    ],
  },
  'Credit Administrator': {
    '/creditorders': [
      'exportCreditOrders',
      'importCreditOrders',
      'importPayments',
      'createCreditOrder',
    ],
    '/detail-credit-order': [
      'extraordinaryPayment',
      'externalPayment',
      'cancelOrder',
      'update',
    ],
    '/users': ['add', 'view'],
    '/detail-payment': ['viewPaymentComissions'],
  },
  'Customer Success': {
    '/paymentorders': ['createOrder'],
    '/add-paymentorder': ['setProvisionalPayment'],
    '/detail-payment-order': ['shareLink', 'cancelOrder'],
    '/detail-payment': ['send&DownloadReceipt'],
    '/detail-order': ['shareLink'],
  },
  'Service Manager': {
    '/advances': ['createAdvance'],
    '/detail-advance': ['shareLink', 'cancelAdvance'],
    '/paymentorders': ['createOrder'],
    '/add-paymentorder': ['setProvisionalPayment'],
    '/detail-payment-order': [
      'addPromotions',
      'shareLink',
      'cancelOrder',
    ],
    '/detail-payment': ['send&DownloadReceipt'],
    '/detail-order': [
      'addPromotions',
      'orderToPay',
      'shareLink',
      'selectDepartmentType',
      'addExternalPayment',
      'addAdvance',
    ],
  },
  'ABG Service Manager': {
    '/advances': ['createAdvance'],
    '/detail-advance': ['shareLink', 'cancelAdvance'],
    '/paymentorders': ['createOrder'],
    '/add-paymentorder': ['setProvisionalPayment'],
    '/detail-payment-order': [
      'addPromotions',
      'shareLink',
      'cancelOrder',
    ],
    '/detail-payment': ['send&DownloadReceipt'],
    '/detail-order': [
      'addPromotions',
      'orderToPay',
      'shareLink',
      'selectDepartmentType',
      'addExternalPayment',
      'addAdvance',
    ],
  },
  'Credit Analyst': {
    '/creditorders': ['createCreditOrder'],
    '/detail-credit-order': [
      'extraordinaryPayment',
      'externalPayment',
      'cancelOrder',
      'update',
    ],
    '/detail-payment': ['viewPaymentComissions'],
  },
  'Service Asesor': {
    '/advances': ['createAdvance'],
    '/detail-advance': ['shareLink'],
    '/paymentorders': ['createOrder'],
    '/add-paymentorder': ['setProvisionalPayment'],
    '/detail-payment-order': ['shareLink'],
    '/detail-payment': ['send&DownloadReceipt'],
    '/detail-order': [
      'orderToPay',
      'shareLink',
      'selectDepartmentType',
      'addExternalPayment',
      'addAdvance',
    ],
  },
  'f&i': {
    '/paymentorders': ['createOrder'],
    '/add-paymentorder': ['setProvisionalPayment'],
    '/detail-payment-order': ['shareLink', 'cancelOrder'],
    '/detail-payment': ['send&DownloadReceipt'],
    '/detail-order': [
      'orderToPay',
      'shareLink',
      'selectDepartmentType',
      'addExternalPayment',
      'addAdvance',
    ],
  },
  'Sales Advisor': {
    '/paymentorders': ['createOrder'],
    '/add-paymentorder': ['setProvisionalPayment'],
    '/detail-payment-order': ['shareLink', 'cancelOrder'],
    '/detail-payment': ['send&DownloadReceipt'],
  },
  'Sales Manager': {
    '/paymentorders': ['createOrder'],
    '/add-paymentorder': ['setProvisionalPayment'],
    '/detail-payment-order': [
      'addPromotions',
      'shareLink',
      'cancelOrder',
    ],
    '/detail-payment': ['send&DownloadReceipt'],
  },
  'ABG Sales Manager': {
    '/paymentorders': ['createOrder'],
    '/add-paymentorder': ['setProvisionalPayment'],
    '/detail-payment-order': [
      'addPromotions',
      'shareLink',
      'cancelOrder',
    ],
    '/detail-payment': ['send&DownloadReceipt'],
  },
  Cashier: {
    '/paymentorders': ['createOrder'],
    '/detail-payment': ['send&DownloadReceipt', 'validateInvoice'],
    '/detail-payment-order': ['addAdvance'],
    '/serviceorders': ['getOrderFromDMS'],
    '/detail-order': [
      'orderToPay',
      'updateOrder',
      'shareLink',
      'selectDepartmentType',
      'addExternalPayment',
      'addAdvance',
      'addPromotions',
    ],
    '/deposits': ['importDeposits'],
    '/depositfiserv': ['importDepositsFiserv'],
  },
  'Marketing Agent': {},
  Accounting: {
    '/serviceorders': ['getOrderFromDMS'],
    '/detail-order': [
      'orderToPay',
      'updateOrder',
      'shareLink',
      'selectDepartmentType',
      'addExternalPayment',
      'addAdvance',
      'addPromotions',
    ],
    '/detail-payment-order': ['addAdvance', 'cancelOrder'],
    '/detail-payment': [
      'send&DownloadReceipt',
      'validateInvoice',
      'viewPaymentComissions',
    ],
    '/deposits': ['importDeposits'],
    '/depositfiserv': ['importDepositsFiserv'],
  },
  'Parts Asesor': {
    '/paymentorders': ['createOrder'],
    '/add-paymentorder': ['setProvisionalPayment'],
    '/detail-payment-order': [
      'addAdvance',
      'shareLink',
      'cancelOrder',
    ],
    '/detail-payment': ['send&DownloadReceipt'],
  },
  'Parts Manager': {
    '/paymentorders': ['createOrder'],
    '/add-paymentorder': ['setProvisionalPayment'],
    '/detail-payment-order': [
      'addPromotions',
      'addAdvance',
      'shareLink',
      'cancelOrder',
    ],
    '/detail-payment': ['send&DownloadReceipt'],
  },
  'ABG Parts Manager': {
    '/paymentorders': ['createOrder'],
    '/add-paymentorder': ['setProvisionalPayment'],
    '/detail-payment-order': [
      'addPromotions',
      'addAdvance',
      'shareLink',
      'cancelOrder',
    ],
    '/detail-payment': ['send&DownloadReceipt'],
  },
  'W32 Agency Admin': {
    '/paymentorders': ['createOrder'],
    '/add-paymentorder': ['setProvisionalPayment'],
    '/users': ['add', 'view'],
    '/detail-payment-order': [
      'addAdvance',
      'shareLink',
      'cancelOrder',
    ],
    '/detail-payment': [
      'send&DownloadReceipt',
      'validateInvoice',
      'viewPaymentComissions',
    ],
  },
  'W32 Asesor': {
    '/paymentorders': ['createOrder'],
    '/add-paymentorder': ['setProvisionalPayment'],
    '/detail-payment-order': ['shareLink'],
    '/detail-payment': ['send&DownloadReceipt'],
  },
  'W32 Accounting': {
    '/detail-payment': [
      'send&DownloadReceipt',
      'validateInvoice',
      'viewPaymentComissions',
    ],
    '/detail-payment-order': ['addAdvance', 'cancelOrder'],
    '/deposits': ['importDeposits'],
    '/serviceorders': ['getOrderFromDMS'],
    '/detail-order': [
      'orderToPay',
      'updateOrder',
      'shareLink',
      'selectDepartmentType',
      'addExternalPayment',
      'addAdvance',
      'addPromotions',
    ],
  },
  'W32 Parts Asesor': {
    '/paymentorders': ['createOrder'],
    '/detail-payment-order': ['shareLink', 'cancelOrder'],
    '/add-paymentorder': ['setProvisionalPayment'],
    '/detail-payment': ['send&DownloadReceipt'],
  },
  'ABG Agency Admin': {
    '/paymentorders': ['createOrder'],
    '/add-paymentorder': ['setProvisialPayment'],
    '/users': ['add', 'view'],
    '/detail-payment-order': [
      'addAdvance',
      'shareLink',
      'cancelOrder',
    ],
    '/detail-payment': [
      'send&DownloadReceipt',
      'validateInvoice',
      'viewPaymentComissions',
    ],
  },
  'ABG Asesor': {
    '/paymentorders': ['createOrder'],
    '/add-paymentorder': ['setProvisionalPayment'],
    '/detail-payment-order': ['shareLink'],
    '/detail-payment': ['send&DownloadReceipt'],
  },
  'ABG Asesor PreOwned': {
    '/paymentorders': ['createOrder'],
    '/add-paymentorder': ['setProvisionalPayment'],
    '/detail-payment-order': ['shareLink'],
    '/detail-payment': ['send&DownloadReceipt'],
  },
  'ABG Sales Advisor': {
    '/paymentorders': ['createOrder'],
    '/add-paymentorder': ['setProvisionalPayment'],
    '/detail-payment-order': ['shareLink', 'cancelOrder'],
    '/detail-payment': ['send&DownloadReceipt'],
  },
  'ABG Accounting': {
    '/detail-payment': [
      'send&DownloadReceipt',
      'validateInvoice',
      'viewPaymentComissions',
    ],
    '/detail-payment-order': ['addAdvance', 'cancelOrder'],
    '/deposits': ['importDeposits'],
    '/serviceorders': ['getOrderFromDMS'],
    '/detail-order': [
      'orderToPay',
      'updateOrder',
      'shareLink',
      'selectDepartmentType',
      'addExternalPayment',
      'addAdvance',
      'addPromotions',
    ],
  },
  'ABG Cashier': {
    '/detail-payment': ['send&DownloadReceipt', 'validateInvoice'],
    '/detail-payment-order': ['addAdvance'],
    '/serviceorders': ['getOrderFromDMS'],
    '/detail-order': [
      'orderToPay',
      'updateOrder',
      'shareLink',
      'selectDepartmentType',
      'addExternalPayment',
      'addAdvance',
      'addPromotions',
    ],
    '/deposits': ['importDeposits'],
    '/depositfiserv': ['importDepositsFiserv'],
  },
  'ABG Parts Asesor': {
    '/paymentorders': ['createOrder'],
    '/detail-payment-order': ['shareLink', 'cancelOrder'],
    '/add-paymentorder': ['setProvisionalPayment'],
    '/detail-payment': ['send&DownloadReceipt'],
  },
  'Payments Searcher': {
    '/detail-payment': ['send&DownloadReceipt', 'validateInvoice'],
    '/detail-payment-order': ['addAdvance'],
  },
};
