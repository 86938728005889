import React, { useState, useEffect } from 'react';

import {
  Button,
  TextField,
  Grid,
  Typography,
  makeStyles,
  InputAdornment,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: theme.spacing(7, 10, 1, 10),
  },
  funtions: {
    margin: '40px 0',
    width: '100%',
  },
  textField: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  submitButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
}));

const UpdateAmountODS = (props) => {
  const { handleUpdateAmountODS, orderData } = props;
  const classes = useStyles();

  const [orderDataUpdate, setOrderDataUpdate] = useState();

  useEffect(() => {
    setOrderDataUpdate(orderData);
  }, [orderData]);

  const handleOnChange = (name, value) => {
    const validateAmount = /^([0-9]{1,10}(\.[0-9]{1,2})?)$/;
    let valueTemp = value;
    if (value.includes('.')) {
      let countDecimals = value.split('.');
      countDecimals = countDecimals.pop();
      if (countDecimals.length === 0) {
        valueTemp = `${value}0`;
      }
    }
    if (validateAmount.test(valueTemp) || valueTemp === '') {
      setOrderDataUpdate({
        ...orderDataUpdate,
        [name]: value,
      });
    }
  };

  return (
    <>
      <Grid item xs={12} md={12}>
        <Typography variant="h4" gutterBottom>
          {`Modificar monto ODS ${orderDataUpdate?.orderID || 'N/A'}`}
        </Typography>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          label="Total"
          fullWidth
          name="total"
          value={orderDataUpdate?.total}
          variant="outlined"
          onChange={(event) =>
            handleOnChange(event.target.name, event.target.value)
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">$</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          label="Subtotal"
          fullWidth
          name="subtotal"
          value={orderDataUpdate?.subtotal}
          variant="outlined"
          onChange={(event) =>
            handleOnChange(event.target.name, event.target.value)
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">$</InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12} md={2} className={classes.funtions}>
        <div className={classes.submitButtonWrapper}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleUpdateAmountODS(orderDataUpdate)}
          >
            Actualizar
          </Button>
        </div>
      </Grid>
    </>
  );
};

export default UpdateAmountODS;
