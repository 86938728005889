import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import React, { useEffect } from 'react';

import ShouldItRender from 'app/components/ShouldItRender';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  zIndex: {
    zIndex: 1,
  },
}));

export const MenuOption = ({ options = [], Button }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const prevOpen = React.useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handleClose = (event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target)
    ) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      {React.cloneElement(Button, {
        ref: anchorRef,
        'aria-controls': open ? 'menu-list-grow' : undefined,
        'aria-haspopup': true,
        onClick: handleToggle,
        variant: 'contained',
      })}
      <Popper
        className={classes.zIndex}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom'
                  ? 'center top'
                  : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {options.map((option, idx) => (
                    <ShouldItRender
                      key={idx}
                      should={true}
                      {...option.shouldItRender}
                    >
                      <MenuItem
                        key={idx}
                        onClick={(target) => {
                          handleClose(target);
                          option.onClick();
                        }}
                        {...option.props}
                      >
                        {option.name}
                      </MenuItem>
                    </ShouldItRender>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
