import * as yup from 'yup';
const stringValid = yup.string().required('Este campo es requerido');

export const confirmPasswordSchema = yup.object().shape({
  password: stringValid.min(
    6,
    'La contraseña debe tener por lo menos 6 caracteres',
  ),
  rePassword: yup
    .string()
    .required('Este campo es requerido')
    .when('password', {
      is: (val) => (val && val.length > 0 ? true : false),
      then: yup
        .string()
        .oneOf([yup.ref('password')], 'Las contraseñas no coinciden'),
    }),
});
