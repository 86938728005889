import { closeModals } from '../modals/modalsSlice';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    loginSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.user = action?.payload?.user;
        state.superAdminAgenciesId = action?.payload?.idAdmin;
      },
    },
    loginFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action?.payload?.message,
        };
      },
    },
    logout: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    logoutSuccess: {
      reducer(state, action) {
        return initialState;
      },
    },
    logoutFail: {
      reducer(state, action) {
        return initialState;
      },
    },
    requestPasswordReset: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    requestPasswordResetSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action?.payload?.message,
        };
      },
    },
    requestPasswordResetFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action?.payload?.message,
        };
      },
    },
    passwordReset: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    passwordResetSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action?.payload?.message,
        };
      },
    },
    passwordResetFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action?.payload?.message,
        };
      },
    },
    confirmEmail: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    confirmEmailSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action?.payload?.message,
        };
      },
    },
    confirmEmailFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action?.payload?.message,
        };
      },
    },
    getTokenFirstRequest: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getTokenFirstRequestSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.authToken = action?.payload?.token;
      },
    },
    getTokenFirstRequestFail: {
      reducer(state, action) {
        state.isLoading = false;
      },
    },
    getModalAllAgencies: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    getModalAllAgenciesToClose: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    getModalAllAgenciesToCloseSuccess: {
      reducer(state, action) {
        state.agencyModal = false;
        state.selectAgencyModal = false;
      },
    },
    getModalAllAgenciesSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.agencyModal = true;
        state.agencies = action.payload.registers;
      },
    },
    getModalAllAgenciesFail: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    getSelectOneAgency: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    getSelectOneAgencySuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.agencySelect = action.payload.agency;
      },
    },
    getSelectOneAgencyFail: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    confirmAgencySelectaModal: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    confirmAgencySelectaModalSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.selectAgencyModal = true;
      },
    },
    confirmAgencySelectaModalFail: {
      reducer(state, action) {
        state.isLoading = false;
      },
    },
    confirmClose: {
      reducer(state, action) {
        state.selectAgencyModal = false;
      },
    },
    sendPasswordToLogin: {
      reducer(state, action) {
        state.selectAgencyModal = false;
      },
    },
    sendPasswordToLoginSuccess: {
      reducer(state, action) {
        state.selectAgencyModal = true;
      },
    },
    sendPasswordToLoginFail: {
      reducer(state, action) {
        state.selectAgencyModal = false;
      },
    },
    succesLoginModal: {
      reducer(state, action) {
        state.successLogin = true;
        state.failLogin = false;
      },
    },
    handleCloseModals: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    handleCloseModalsSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successLogin = false;
        state.failLogin = false;
        state.agencyModal = false;
      },
    },
    failLoginModal: {
      reducer(state, action) {
        state.successLogin = false;
        state.failLogin = true;
      },
    },
    selectOneAgencyError: {
      reducer(state, action) {
        state.successLogin = true;
      },
    },
    selectOneAgencyErrorSuccess: {
      reducer(state, action) {
        state.successLogin = false;
        state.selectOneAgencyNotFound = true;
      },
    },
    selectOneAgencyErrorFail: {
      reducer(state, action) {
        state.successLogin = false;
        state.selectOneAgencyNotFound = false;
      },
    },
  },
  extraReducers: {
    [closeModals]: (state, action) => {
      state.isLoading = false;
      state.successModal = initialState.successModal;
      state.errorModal = initialState.errorModal;
    },
  },
});

export const {
  selectOneAgencyError,
  selectOneAgencyErrorSuccess,
  selectOneAgencyErrorFail,
  handleCloseModalsSuccess,
  handleCloseModals,
  succesLoginModal,
  failLoginModal,
  sendPasswordToLogin,
  sendPasswordToLoginSuccess,
  sendPasswordToLoginFail,
  confirmClose,
  confirmAgencySelectaModal,
  confirmAgencySelectaModalSuccess,
  confirmAgencySelectaModalFail,
  getSelectOneAgency,
  getSelectOneAgencySuccess,
  getSelectOneAgencyFail,
  getModalAllAgenciesToClose,
  getModalAllAgenciesToCloseSuccess,
  getModalAllAgencies,
  getModalAllAgenciesSuccess,
  getModalAllAgenciesFail,
  login,
  loginSuccess,
  loginFail,
  logout,
  logoutSuccess,
  logoutFail,
  requestPasswordReset,
  requestPasswordResetSuccess,
  requestPasswordResetFail,
  passwordReset,
  passwordResetSuccess,
  passwordResetFail,
  confirmEmail,
  confirmEmailSuccess,
  confirmEmailFail,
  getTokenFirstRequest,
  getTokenFirstRequestSuccess,
  getTokenFirstRequestFail,
} = authSlice.actions;

export default authSlice.reducer;
