import { createSelector } from 'reselect';
import { initialState } from './initialState';

export const selectKarloUsers = (state) =>
  state.karloUsers || initialState;

export const makeSelectKarloUsers = createSelector(
  selectKarloUsers,
  (karloUsersState) => karloUsersState,
);

export const makeSelectKarloUsersDetails = createSelector(
  selectKarloUsers,
  (karloUsersState) => karloUsersState.userDetail,
);

export const makeSelectKarloUsersIsLoading = createSelector(
  selectKarloUsers,
  (karloUsersState) => karloUsersState.isLoading,
);
