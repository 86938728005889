export const monthsTaxes = {
  netpay: [
    {
      ID: 0,
      title: 'Pago de contado',
      text: '1 pago de',
      checked: true,
      normal: 1,
      amex: 1,
    },
    {
      ID: 3,
      title: 'Pago a 3 meses',
      text: '3 pagos de',
      checked: false,
      normal: 0.0419,
      amex: 0.0605,
    },
    {
      ID: 6,
      title: 'Pago a 6 meses',
      text: '6 pagos de',
      checked: false,
      normal: 0.0699,
      amex: 0.085,
    },
    {
      ID: 9,
      title: 'Pago a 9 meses',
      text: '9 pagos de',
      checked: false,
      normal: 0.0969,
      amex: 0.108,
    },
    {
      ID: 12,
      title: 'Pago a 12 meses',
      text: '12 pagos de',
      checked: false,
      normal: 0.1239,
      amex: 0.1295,
    },
    {
      ID: 18,
      title: 'Pago a 18 meses',
      text: '18 pagos de',
      checked: false,
      normal: 0.1739,
    },
  ],
  billPocket: [
    {
      ID: 0,
      title: 'Pago de contado',
      text: '1 pago de',
      checked: true,
      normal: 1,
      amex: 1,
    },
    {
      ID: 3,
      title: 'Pago a 3 meses',
      text: '3 pagos de',
      checked: false,
      normal: 0.03,
      amex: 0.03,
    },
    {
      ID: 6,
      title: 'Pago a 6 meses',
      text: '6 pagos de',
      checked: false,
      normal: 0.06,
      amex: 0.06,
    },
    {
      ID: 9,
      title: 'Pago a 9 meses',
      text: '9 pagos de',
      checked: false,
      normal: 0.08,
      amex: 0.08,
    },
    {
      ID: 12,
      title: 'Pago a 12 meses',
      text: '12 pagos de',
      checked: false,
      normal: 0.1,
      amex: 0.1,
    },
    // {
    //   ID: 18,
    //   title: 'Pago a 18 meses',
    //   text: '18 pagos de',
    //   checked: false,
    //   normal: 0.1739,
    //   amex: 0.1739,
    // },
    // {
    //   ID: 24,
    //   title: 'Pago a 24 meses',
    //   text: '24 pagos de',
    //   checked: false,
    //   normal: 0.2295,
    //   amex: 0.2295,
    // },
  ],
};

export const monthsByComissions = {
  3: {
    months: 3,
    normal: 0.0395,
    amex: 0.0395,
    toShow: 0.0497,
  },
  6: {
    months: 6,
    normal: 0.0695,
    amex: 0.0695,
    toShow: 0.0908,
  },
  9: {
    months: 9,
    normal: 0.0895,
    amex: 0.0895,
    toShow: 0.12,
  },
  12: {
    months: 12,
    normal: 0.1195,
    amex: 0.1195,
    toShow: 0.167,
  },
};
export const monthsByComissionsByAgregador = {
  billpocket : { 
    3: {
      months: 3,
      normal: 0.0395,
      amex: 0.0395,
      toShow: 0.0497,
    },
    6: {
      months: 6,
      normal: 0.0695,
      amex: 0.0695,
      toShow: 0.0908,
    },
    9: {
      months: 9,
      normal: 0.0895,
      amex: 0.0895,
      toShow: 0.12,
    },
    12: {
      months: 12,
      normal: 0.1195,
      amex: 0.1195,
      toShow: 0.167,
    },
    // 18: {
    //   months: 18,
    //   normal: 0.1739,
    //   amex: 0.1739,
    //   toShow: 0.27381,
    // },
   },
  fiserv: {
    3: {
      months: 3,
      prosa: 0.0330,
      citibanamex: 0.0496,
      BBVA: 0.0493,
      amex: 0.0325,
      toShow: 0.0497,
    },
    6: {
      months: 6,
      prosa: 0.0395,
      citibanamex: 0.0480,
      BBVA: 0.0886,
      amex: 0.0842,
      toShow: 0.0908,
    },
    9: {
      months: 9,
      prosa: 0.0750,
      citibanamex: 0.1147,
      BBVA: 0.1174,
      amex: 0.0825,
      toShow: 0.12,
    },
    12: {
      months: 12,
      prosa: 0.1050,
      citibanamex: 0.1466,
      BBVA: 0.1491,
      amex: 0.1025,
      toShow: 0.167,
    },
   }
};

export const monthsByComissionsBuenFin = {
  3: {
    months: 3,
    normal: 0.0395,
    amex: 0.0395,
    toShow: 0.0497,
  },
  6: {
    months: 6,
    normal: 0.0695,
    amex: 0.0695,
    toShow: 0.0908,
  },
  9: {
    months: 9,
    normal: 0.0895,
    amex: 0.0895,
    toShow: 0.12,
  },
  12: {
    months: 12,
    normal: 0.1195,
    amex: 0.1195,
    toShow: 0.167,
  },
  // 18: {
  //   months: 18,
  //   normal: 0.1739,
  //   amex: 0.1739,
  //   toShow: 0.27381,
  // },
};

export const defaultComissions = {
  'visa/mastercard': 0.027,
  amex: 0.035,
};

export const defaultComissionsByAgregador = {
  billpocket : {
    'visa/mastercard': 0.027,
    amex: 0.035,
  },
  fiserv : {
    'visa/mastercard': 0.018,
    amex: 0.026,
  },
  fiservKiosko : {
    'visa/mastercard': 0.0110,
    amex: 0.026,
  },
};

export const IVA = 1.16;
