import 'react-table/react-table.css';

/* eslint-disable react/display-name */
import { Paper, Tab, Tabs, makeStyles } from '@material-ui/core';
import React, { useEffect, useState, useRef } from 'react';
import {
  formatDate,
  formatNumber,
  getBadgeColor,
  getColumnWidth,
  getNested,
  getPOStatusName,
  getDISPERSIONStatusName,
} from '../../../../utils';

import { Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import moment from 'moment';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { getDispersions } from '../../dispersionsSlice';
import { injectIntl } from 'react-intl';
import { makeSelectAgencyDetailsFromState } from '../../../agencies/selectors';
import { makeSelectPaymentOrders } from '../../selectors';
import MUIDatePickerFilter from 'app/components/MUIDatePickerFilter';
import { makeSelectUserRoleFromState } from '../../../auth/selectors';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(0),
  },
  datePickerTextfield: {
    padding: '5px 5px 5px !important',
    height: '20px',
  },
  range: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(10),
  },
  datePicker: {
    width: 90,
  },
}));

function DispersionTable(props) {
  const classes = useStyles();
  const {
    paymentOrdersFromdb,
    agencyDetail,
    role,
    fullOrders,
  } = props;
  const [orders, setOrders] = useState([]);
  const [ordersFiltered, setOrdersFiltered] = useState([]);
  const [tabSelected, setTabSelected] = useState(0);
  const reactTable = useRef(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sorted, setSorted] = useState([]);
  const [filtered, setFiltered] = useState('');
  const [showDateErrorModal, setShowDateErrorModal] = useState(false);
  const [closeDateErrorModal, setCloseDateErrorModal] = useState(
    false,
  );

  const handleCloseModals = () => {
    setCloseDateErrorModal(!closeDateErrorModal);
    setShowDateErrorModal(false);
  };

  useEffect(() => {
    const CSV = [];
    let searchIn;
    !Array.isArray(fullOrders)
      ? (searchIn = ordersFiltered)
      : (searchIn = orders);
    searchIn.forEach((cursor) => {
      let type;
      switch (cursor.type) {
        case 'service':
          type = 'Servicio';
          break;
        case 'parts':
          type = 'Refacciones';
          break;
        case 'sales':
          type = 'Ventas';
          break;
        case 'fi':
          type = 'F&I y Garantías';
          break;
        case 'reservation':
          type = 'Reservación';
          break;
        case 'pulled_apart':
          type = 'Apartado';
          break;
        default:
          type = '-';
          break;
      }
      const data = {
        // prettier-ignore
        'ID de la dispersión': cursor.reference || '-',
        // prettier-ignore
        'claveRastreo': cursor.clientID || '-',
        // prettier-ignore
        'Agencia': cursor.agencyName || '-',
        // prettier-ignore
        'tsLiquidacion': cursor.name || '-',
        // prettier-ignore
        'Fecha de creación': cursor.created || '-',
        // prettier-ignore
        'fechaOperacion': cursor.date || '-',
        // prettier-ignore
        'Total': cursor.total || '-',
        // prettier-ignore
        'Estatus': cursor.status || '-',
      };
      CSV.push(data);
    });
  }, [orders, tabSelected, filtered]);

  useEffect(() => {
    props.setLeftSideComponent(
      <Tabs
        value={tabSelected}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChangeTab}
      >
        <Tab label="Todas" />
      </Tabs>,
    );
    reactTable.current && reactTable.current.fireFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabSelected]);

  // this is used for fill the table in the site delimiter for the row numbers
  useEffect(() => {
    if (Array.isArray(paymentOrdersFromdb)) {
      const cleanOrders = paymentOrdersFromdb.map((order) => {
        const tsDate = new Date(parseInt(order.tsLiquidacion));
        return {
          id: order.id ? order.id : '-',
          stpId: order.stpId ? order.stpId : '-',
          centroCosto: order.agency.centroCosto
            ? order.agency.centroCosto
            : '-',
          rastreoKey: order.claveRastreo ? order.claveRastreo : '-',
          agencyName: order?.agency?.name || '-',
          tsLiquid: order.tsLiquidacion ? formatDate(tsDate) : '-',
          dateOperation: order.fechaOperacion
            ? formatDate(order.fechaOperacion)
            : '-',
          monto: order.monto
            ? formatNumber(order.monto)
            : order.total
            ? formatNumber(order.total)
            : '-',
          status: order.statusStp
            ? getDISPERSIONStatusName(order.statusStp)
            : '-',
        };
      });
      setOrders(cleanOrders);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabSelected, paymentOrdersFromdb]);

  // this is used for fill the csv file when exist the date filter or agency filter
  useEffect(() => {
    if (!Array.isArray(fullOrders) && fullOrders !== undefined) {
      const cleanOrders = fullOrders.rows.map((order) => {
        return {
          id: order.id ? order.id : 'n/a',
          agencyName: order?.agency?.name || 'n/a',
          reference: order.reference ? order.reference : 'n/a',
          clientID: getNested('-', order, 'clientID'),
          name: order.nombre ? order.nombre : 'n/a',
          orderID: order.orderID ? order.orderID : 'n/a',
          nickname: order.agency ? order.agency.nickname : 'n/a',
          created: order.createdAt
            ? formatDate(order.createdAt)
            : 'n/a',
          date:
            order?.payments?.length > 0 &&
            order?.payments[order.payments.length - 1].status ===
              'DONE'
              ? formatDate(
                  order.payments[order.payments.length - 1]?.date,
                )
              : 'n/a',
          total: order.amount
            ? formatNumber(order.amount)
            : order.total
            ? formatNumber(order.total)
            : 'n/a',
          status: order.status
            ? getPOStatusName(order.status)
            : 'n/a',
        };
      });
      setOrdersFiltered(cleanOrders);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabSelected, fullOrders]);

  const handleFetchData = debounce(
    (state, instance) => {
      if (state) {
        const { page, pageSize, sorted, filtered } = state;
        props.getDispersions({
          page,
          pageSize,
          sorted,
          filtered,
          canceled: tabSelected,
        });
        setPage(page);
        setPageSize(pageSize);
        setSorted(sorted);
        setFiltered(filtered);
      } else {
        props.getDispersions({
          page,
          pageSize,
          sorted,
          filtered,
          canceled: tabSelected,
        });
      }
    },
    1000,
    { leading: false, trailing: true },
  );

  const handleChangeTab = (event, value) => setTabSelected(value);

  const columns = [
    {
      Header: () => <strong>ID de la dispersión</strong>,
      ///Indica la referencia del cleanOrders que se definio arriba///
      ///Este pone los valores del cleanOrders en la tabla
      accessor: 'id',
      Cell: (row) => (
        <Link to={`/detail-dispersions/${row.original.id}/`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>STP ID</strong>,
      ///Indica la referencia del cleanOrders que se definio arriba///
      ///Este pone los valores del cleanOrders en la tabla
      accessor: 'stpId',
      Cell: (row) => (
        <Link to={`/detail-dispersions/${row.original.id}/`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>Agencia</strong>,
      accessor: 'agencyName',
      Cell: (row) => (
        <Link to={`/detail-dispersions/${row.original.id}/`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      show: role === 'Super Admin',
    },
    {
      Header: () => <strong>Clave de Rastreo</strong>,
      accessor: 'rastreoKey',
      Cell: (row) => (
        <Link to={`/detail-dispersions/${row.original.id}/`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      show: role === 'Super Admin',
    },
    {
      Header: () => <strong>Fecha Liquidación</strong>,
      accessor: 'tsLiquid',
      Cell: (row) => (
        <Link to={`/detail-dispersions/${row.original.id}/`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
      width: getColumnWidth(orders, 'name', 'Nombre de cliente'),
    },
    {
      Header: () => <strong>Fecha Operación</strong>,
      accessor: 'dateOperation',
      Cell: (row) => (
        <Link to={`/detail-dispersions/${row.original.id}/`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      Filter: (cell) => {
        return <MUIDatePickerFilter cell={cell} />;
      },
      width: 140,
    },
    {
      Header: () => <strong>Total</strong>,
      accessor: 'monto',
      Cell: (row) => (
        <Link to={`/detail-dispersions/${row.original.id}/`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Estatus</strong>,
      accessor: 'status',
      Cell: (row) => (
        <Link to={`/detail-dispersions/${row.original.id}/`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <Badge
              className={classes.badges}
              variant={getBadgeColor(row.value)}
            >
              {row.value}
            </Badge>
          </div>
        </Link>
      ),
      Filter: (cell) => {
        return (
          // eslint-disable-next-line jsx-a11y/no-onchange
          <select
            disabled={tabSelected === 1 || tabSelected === 2}
            onChange={(e) => cell.onChange(e.target.value)}
            value={
              cell.filter && cell.filter.value
                ? cell.filter.value
                : ''
            }
          >
            <option value="">Todos</option>
              <>
                <option value="FALLO">Fallo</option>
                <option value="LIQUIDADO">Liquidado</option>
                <option value="CANCELADO">Cancelado</option>
              </>
          </select>
        );
      },
    },
  ];

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <ReactTable
          ref={reactTable}
          NoDataComponent={() => (
            <div className="rt-noData">Sin resultados</div>
          )}
          manual
          onFetchData={handleFetchData}
          data={orders}
          pages={props.orders.tablePages}
          columns={columns}
          filterable
          className="-striped -highlight"
          previousText="Anterior"
          nextText="Siguiente"
          loading={props.orders.isLoading}
          loadingText="Cargando datos..."
        />
      </Paper>
    </div>
  );
}

const mapStateToProps = (state) => ({
  orders: state.paymentOrders,
  fullOrders: state.paymentOrders.fullOrders,
  paymentOrdersFromdb: makeSelectPaymentOrders(state),
  agencyDetail: makeSelectAgencyDetailsFromState(state),
  role: makeSelectUserRoleFromState(state),
});

const mapDispatchToProps = {
  getDispersions: getDispersions,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(DispersionTable),
);
