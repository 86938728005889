import 'react-table/react-table.css';

/* eslint-disable react/display-name */
import {
  Button,
  CircularProgress,
  MenuItem,
  Paper,
  FormControl,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Col, Row } from 'react-bootstrap';
import AutocompleteBrands from 'app/components/AutocompleteBrands';
import React, { useEffect, useState } from 'react';
import {
  cleanEmptyKeys,
  dmsValues,
  filterCaseInsensitive,
} from '../../../../utils';
import {
  createAgency,
  getAgencies,
  updloadLogo,
  getAgenciesFilters,
} from 'app/features/agencies/agenciesSlice';
import {
  makeSelectAgenciesFromdb,
  selectAgencies,
  makeSelectAgenciesFilters,
} from 'app/features/agencies/selectors';

import Add from '@material-ui/icons/Add';
import Check from '@material-ui/icons/Check';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Input from 'app/components/Input';
import { Link } from 'react-router-dom';
import Modal from '../../../../components/Modal';
import { RHFMUISelect } from 'app/components/RHF';
import ReactTable from 'react-table';
import ShouldItRender from '../../../../components/ShouldItRender';
import { agencySchema } from './schema';
import { closeModals } from 'app/features/modals/modalsSlice';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { green } from '@material-ui/core/colors';
import { injectIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CSVLink } from 'react-csv';
import GetApp from '@material-ui/icons/GetApp';
import { debounce } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  paper: {
    widht: '100%',
    marginBottom: theme.spacing(0),
  },
  button: {
    margin: theme.spacing(1),
  },
  textField: {
    margin: theme.spacing(1),
    width: '95%',
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function AgenciesTable(props) {
  const classes = useStyles();
  const {
    agencies,
    agenciesFromdb,
    getAgencies,
    getAgenciesFilters,
    createAgency,
    updloadLogo,
    setActionButton,
    location,
    closeModals,
  } = props;
  const [showAddAgencyModal, setShowAddAgencyModal] = React.useState(
    false,
  );
  const buttonClassname = clsx({
    [classes.buttonSuccess]: agencies.logoUploaded,
  });
  const [sizeError, setSizeError] = React.useState(false);
  const [formatBrands, setFormatBrands] = React.useState([]);
  const { handleSubmit, register, errors, control } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(agencySchema),
  });
  const [agencyExport, setAgencyExport] = useState([]);
  const [filteredAgencies, setFilteredAgencies] = useState([]);

  useEffect(() => {
    setFilteredAgencies(agencies.agenciesFilter);
    exportData();
  }, [agencies.agenciesFilter]);

  const exportData = () => {
    const CSV = [];
    const list =
      filteredAgencies && filteredAgencies.length > 0
        ? filteredAgencies
        : agenciesFromdb;
    if (list) {
      list.forEach((cursor) => {
        // eslint-disable-next-line no-unused-vars
        const isActive = cursor.isActiveAgency
          ? 'Activa'
          : 'Inactiva';
        const data = {
          // prettier-ignore
          'ID': cursor.id || '-',
          // prettier-ignore
          'Nombre': cursor.name || '-',
          // prettier-ignore
          'DMS': cursor.DMS || '-',
          // prettier-ignore
          'Teléfono': cursor.phoneNumber || '-',
          // prettier-ignore
          'Nickname': cursor.nickname || '-',
          // prettier-ignore
          'Número de afiliación': cursor.afiliationNumber || '-',
          // prettier-ignore
          '# usuario BP': cursor.bpUser || '-',
          // prettier-ignore
          'Nombre de grupo': cursor.groupName || '-',
          // prettier-ignore
          'Activa': isActive || '-',
        };
        CSV.push(data);
      });
    }
    setAgencyExport(CSV);
  };

  useEffect(() => {
    setActionButton(
      <ShouldItRender
        locationPage={`/${location.pathname.split('/')[1]}`}
        action="add"
      >
        <Button
          variant="contained"
          className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
          onClick={toogleAddAgencyModal}
        >
          Crear agencia
          <Add className="kt-margin-l-10" />
        </Button>
        <CSVLink data={agencyExport} filename={'Agencias.csv'}>
          <Button
            variant="contained"
            className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
            onClick={exportData}
          >
            Exportar
            <GetApp className="kt-margin-l-10" />
          </Button>
        </CSVLink>
      </ShouldItRender>,
    );

    getAgencies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencyExport]);

  let toogleAddAgencyModal = () => {
    setShowAddAgencyModal(!showAddAgencyModal);
  };

  const handleCloseModals = () => {
    closeModals();
  };

  const columns = [
    {
      Header: () => <strong>ID</strong>,
      accessor: 'id',
      Cell: (row) => (
        <Link to={`/detail-agency/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>Nombre</strong>,
      accessor: 'name',
      Cell: (row) => (
        <Link to={`/detail-agency/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>DMS</strong>,
      accessor: 'DMS',
      Cell: (row) => (
        <Link to={`/detail-agency/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>Teléfono</strong>,
      accessor: 'phoneNumber',
      Cell: (row) => (
        <Link to={`/detail-agency/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>Nickname</strong>,
      accessor: 'nickname',
      Cell: (row) => {
        return (
          <Link to={`/detail-agency/${row.original.id}`}>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              {row.value}
            </div>
          </Link>
        );
      },
    },
    {
      Header: () => <strong>Número de afiliación</strong>,
      accessor: 'afiliationNumber',
      Cell: (row) => {
        return (
          <Link to={`/detail-agency/${row.original.id}`}>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              {row.value}
            </div>
          </Link>
        );
      },
    },
    {
      Header: () => <strong># usuario BP</strong>,
      accessor: 'bpUser',
      Cell: (row) => {
        return (
          <Link to={`/detail-agency/${row.original.id}`}>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              {row.value}
            </div>
          </Link>
        );
      },
    },
    {
      Header: () => <strong>Nombre de Grupo</strong>,
      accessor: 'groupName',
      Cell: (row) => {
        return (
          <Link to={`/detail-agency/${row.original.id}`}>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              {row.value}
            </div>
          </Link>
        );
      },
    },
    {
      Header: () => <strong>Estatus</strong>,
      accessor: 'isActiveAgency',
      Cell: (row) => {
        return (
          <Link to={`/detail-agency/${row.original.id}`}>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              {row.value ? 'Activa' : 'Inactiva'}
            </div>
          </Link>
        );
      },
    },
  ];

  const handleFile = (selectedFiles) => {
    setSizeError(false);
    if (!selectedFiles || selectedFiles.length === 0) return;

    if (selectedFiles[0].size > 5000000) {
      setSizeError(true);
      return;
    }

    if (selectedFiles[0] != null) {
      if (selectedFiles[0].size !== 0) {
        const formData = new FormData();
        formData.append(`file0`, selectedFiles[0]);
        console.log('selectedFiles ', selectedFiles[0]);
        return updloadLogo(formData);
      }
    }
    return;
  };

  const renderSizeError = () => {
    return sizeError ? (
      <p className="custom-error">Tu archivo pesa más de 5MB</p>
    ) : null;
  };

  const handleCreateAgency = (formData) => {
    let cleanValues;
    agencies.logoUploaded
      ? (cleanValues = {
          ...formData,
          nickname: formData.nickname.replace(/\s/g, ''),
          logo: agencies.logoURL,
        })
      : (cleanValues = {
          ...formData,
          nickname: formData.nickname.replace(/\s/g, ''),
        });

    cleanValues = {
      ...cleanValues,
      brands: formatBrands,
    };

    cleanValues = cleanEmptyKeys(cleanValues);
    createAgency({ ...cleanValues });
  };

  const handleChangeBrands = (data) => {
    const newFormatBrands = data.map((brand) => brand.id);
    setFormatBrands(newFormatBrands);
  };

  const renderAddAgencyDialog = () => {
    return (
      <Modal
        fullWidth={true}
        maxWidth="md"
        open={showAddAgencyModal}
        closeModal={toogleAddAgencyModal}
        dialogTitle="Crear Nueva Agencia"
        actionButtonText="Crear agencia"
        disableBackdropClick={true}
        form={'create-agency-form'}
        dialogChildren={
          <form
            id="create-agency-form"
            className={classes.container}
            onSubmit={handleSubmit(handleCreateAgency)}
            noValidate
            autoComplete="off"
          >
            <Row>
              <Col>
                <Input
                  required
                  ref={register({
                    required: true,
                  })}
                  name="name"
                  label="Nombre de la agencia"
                  className={classes.textField}
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                />
              </Col>
              <Col>
                <Input
                  required
                  ref={register({
                    required: true,
                  })}
                  name="businessName"
                  label="Razón social de la agencia"
                  className={classes.textField}
                  error={!!errors.businessName}
                  helperText={errors?.businessName?.message}
                />
              </Col>
            </Row>
            <Typography variant="subtitle1">
              Dirección fiscal
            </Typography>
            <Row>
              <Col>
                <Input
                  required
                  ref={register({
                    required: true,
                  })}
                  name="address.street"
                  label="Calle de la agencia"
                  className={classes.textField}
                  error={!!errors?.address?.street}
                  helperText={errors?.address?.street?.message}
                />
              </Col>
              <Col>
                <Input
                  required
                  ref={register({
                    required: true,
                  })}
                  name="address.ext"
                  label="Número exterior de la agencia"
                  className={classes.textField}
                  error={!!errors?.address?.ext}
                  helperText={errors?.address?.ext?.message}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  required
                  ref={register({
                    required: true,
                  })}
                  name="address.colony"
                  label="Colonia de la agencia"
                  className={classes.textField}
                  error={!!errors?.address?.colony}
                  helperText={errors?.address?.colony?.message}
                />
              </Col>
              <Col>
                <Input
                  required
                  ref={register({
                    required: true,
                    pattern: /^[0-9]*$/,
                  })}
                  name="address.postalCode"
                  label="Código postal de la agencia"
                  className={classes.textField}
                  error={!!errors?.address?.postalCode}
                  helperText={errors?.address?.postalCode?.message}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  required
                  ref={register({
                    required: true,
                  })}
                  name="address.city"
                  label="Ciudad de la agencia"
                  className={classes.textField}
                  error={!!errors?.address?.city}
                  helperText={errors?.address?.city?.message}
                />
              </Col>
              <Col>
                <Input
                  required
                  ref={register({
                    required: true,
                  })}
                  name="address.state"
                  label="Estado de la agencia"
                  className={classes.textField}
                  error={!!errors?.address?.state}
                  helperText={errors?.address?.state?.message}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  required
                  ref={register({
                    required: true,
                    pattern: /^[0-9]*$/,
                  })}
                  name="phoneNumber"
                  label="Número telefónico de la agencia"
                  className={classes.textField}
                  error={!!errors.phoneNumber}
                  helperText={errors?.phoneNumber?.message}
                />
              </Col>
              <Col>
                <Input
                  required
                  ref={register({
                    required: true,
                  })}
                  name="rfc"
                  label="RFC de la agencia"
                  className={classes.textField}
                  error={!!errors.rfc}
                  helperText={errors?.rfc?.message}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Input
                  required
                  ref={register({
                    required: true,
                  })}
                  name="email"
                  label="Correo electrónico de la agencia"
                  className={classes.textField}
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                />
              </Col>
              <Col>
                <Input
                  required
                  ref={register({
                    required: true,
                    pattern: /^[a-zA-Z-]*$/,
                  })}
                  name="nickname"
                  label="Nickname de la agencia"
                  className={classes.textField}
                  error={!!errors.nickname}
                  helperText={errors?.nickname?.message}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  ref={register({
                    required: true,
                  })}
                  name="bpAgencyName"
                  label="Nombre BP de la agencia"
                  className={classes.textField}
                  error={!!errors.bpAgencyName}
                  helperText={errors?.bpAgencyName?.message}
                />
              </Col>
              <Col>
                <Input
                  ref={register({
                    required: true,
                  })}
                  name="afiliationNumber"
                  label="Afiliación BP de la agencia"
                  className={classes.textField}
                  error={!!errors.afiliationNumber}
                  helperText={errors?.afiliationNumber?.message}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  ref={register({
                    required: true,
                  })}
                  name="bpocketApiKey"
                  label="BpocketApiKey de la agencia"
                  className={classes.textField}
                  error={!!errors.bpocketApiKey}
                  helperText={errors?.bpocketApiKey?.message}
                />
              </Col>
              <Col>
                <Input
                  ref={register({
                    required: true,
                  })}
                  name="bpUser"
                  label="# de usaurio Billpocket"
                  className={classes.textField}
                  error={!!errors.bpUser}
                  helperText={errors?.bpUser?.message}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <RHFMUISelect
                  required
                  name="DMS"
                  className={classes.textField}
                  label="DMS"
                  control={control}
                  rules={{ required: true }}
                  error={!!errors.DMS}
                  helperText={errors?.DMS?.message}
                >
                  {dmsValues
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                </RHFMUISelect>
              </Col>
              <Col>
                <Input
                  name="whatsPhoneID"
                  label="Whats Phone ID"
                  className={classes.textField}
                  error={!!errors.whatsPhoneID}
                  helperText={errors?.whatsPhoneID?.message}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  name="stpID"
                  label="stp ID"
                  className={classes.textField}
                  error={!!errors.stpID}
                  helperText={errors?.stpID?.message}
                />
              </Col>
              <Col>
                <AutocompleteBrands
                  ref={register({
                    required: true,
                  })}
                  rules={{ required: true }}
                  error={Boolean(errors.brands?.message)}
                  name="brands"
                  control={control}
                  onChange={(data) => handleChangeBrands(data)}
                  fullWidth
                  required
                  className={classes.textField}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  name="groupName"
                  ref={register({
                    required: true,
                  })}
                  label="Nombre de grupo"
                  className={classes.textField}
                  error={!!errors.groupName}
                  helperText={errors?.groupName?.message}
                />
              </Col>
              <Col></Col>
            </Row>
            <p className="text-align-dialog">
              Solamente se aceptan imágenes JPEG, PNG y SVG de máximo
              5mb
            </p>
            <div className={classes.buttonGroup}>
              <input
                accept="image/*"
                hidden
                id="raised-button-file"
                type="file"
                onChange={(e) => handleFile(e.target.files)}
              />
              <label
                htmlFor="raised-button-file"
                className={classes.wrapper}
              >
                <Button
                  variant="contained"
                  component="span"
                  color="primary"
                  className={buttonClassname}
                  disabled={agencies.isLoading}
                >
                  {agencies.logoUploaded
                    ? 'Logo Cargado'
                    : 'Cargar un logo de agencia'}
                  {agencies.logoUploaded ? (
                    <Check className={classes.rightIcon} />
                  ) : (
                    <CloudUploadIcon className={classes.rightIcon} />
                  )}
                </Button>
                {agencies.isLoading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </label>
            </div>
            {renderSizeError()}
          </form>
        }
      />
    );
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = agencies;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message } = agencies[modalType];
        if (successModal.show) {
          if (showAddAgencyModal) {
            toogleAddAgencyModal();
          }
        }
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={handleCloseModals}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={handleCloseModals}
          />
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  const handleFetchData = debounce(
    (state, instance) => {
      const { page, pageSize, sorted, filtered } = state;
      getAgenciesFilters({ filtered });
    },
    1000,
    { leading: false, trailing: true },
  );

  return (
    <div className={classes.root}>
      {renderAddAgencyDialog()}
      {renderFeedBackModal()}
      <Paper className={classes.paper}>
        <ReactTable
          NoDataComponent={() => (
            <div className="rt-noData">Sin resultados</div>
          )}
          data={
            filteredAgencies &&
            Array.isArray(filteredAgencies) &&
            filteredAgencies.length > 0
              ? filteredAgencies
              : agenciesFromdb || []
          }
          columns={columns}
          onFetchData={handleFetchData}
          filterable
          defaultFilterMethod={filterCaseInsensitive}
          className="-striped -highlight"
          previousText="Anterior"
          nextText="Siguiente"
          loading={agencies.isLoading}
          loadingText="Cargando datos..."
        />
      </Paper>
    </div>
  );
}

const mapStateToProps = (state) => ({
  agencies: selectAgencies(state),
  agenciesFromdb: makeSelectAgenciesFromdb(state),
  agenciesFilters: makeSelectAgenciesFilters(state),
});

const mapDispatchToProps = {
  getAgencies,
  createAgency,
  updloadLogo,
  closeModals,
  getAgenciesFilters,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(AgenciesTable),
);
