import { Col, Row } from 'react-bootstrap';
import {
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { ReportDataTable } from '../components/ReportDataTable';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { formatMoney, formatPercent, typeOfPromotionMap } from '../../../../../utils';
import ErrorIcon from '@material-ui/icons/Error';
import NumberFormatCustom from '../../../../../components/NumberFormatCustom';
import { connect } from 'react-redux';
import { getAgency } from 'app/features/agencies/agenciesSlice';
import MUIRHFAutocompleteAgencies from 'app/components/MUIRHFAutocompleteAgencies';
import { makeSelectAgencyDetailsFromState } from '../../../../agencies/selectors';
import {
  makeSelectUserRoleFromState,
  makeSelectAgencyIdFromState,
} from 'app/features/auth/selectors';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCalculatorData,
  getFetchCalculatorData,
  getOrdersType,
} from 'app/features/calculator/containers/Calculator2023/calculator2023Slice';
import Lottie from 'lottie-react';
import calculator from 'app/assets/img/animations/calculator.json';

const useStyles = makeStyles((theme) => ({
  containerInfo: {
    borderRadius: '10px',
    background: '#FFFF',
    padding: theme.spacing(2),
    boxShadow:
      'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px',
    height: 'auto',
  },
  container: {
    flexWrap: 'wrap',
  },
  paper: {
    padding: '12px',
    background: '#FFFFFF',
  },
  paperCalculed: {
    padding: '12px',
    height: '100%',
    background: '#FFFFFF',
  },
  borderInput: {
    marginBottom: 20,
    [`& fieldset`]: {
      borderRadius: 10,
      border: '2px solid #E7E9ED',
    },
  },
  borderInputFilter: {
    width: '300px',
    marginRight: '10px',
    marginBottom: 20,
    [`& fieldset`]: {
      borderRadius: 10,
      border: '2px solid #E7E9ED',
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    margin: theme.spacing(0, 0, 2, 0),
  },

  headerColumns: {
    backgroundColor: '#E7E9ED',
  },
  titleTable: {
    fontWeight: 'bold',
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  subtitleTable: {
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  headerTitle: {
    fontWeight: 'bold',
    fontSize: '18px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: '#434B4D',
  },
  emptyStateTitle: {
    fontWeight: 'bold',
    paddingBottom: '10px',
    fontSize: '18px',
  },
  dividerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    justifyContent: 'center',
  },
  loadingSkeletonRow: {
    borderRadius: '10px',
  },
}));

function Calculator2023(props) {
  const dispatch = useDispatch();
  const { agencyDetails, getAgency, role, agencyId } = props;
  const classes = useStyles();

  const agencySettings = useSelector(
    (state) => state?.calculatorData?.settingAmountsPerAgency,
  );
  const calculatorResponse = useSelector(
    (state) => state?.calculatorData?.calculatorResponse,
  );
  const ordersType = useSelector((state) => state?.calculatorData?.ordersType);
  const [selectedOrderType, setOrderType] = useState('');
  const [agencySelected, setAgencySelected] = useState('');
  const [maxAmount, setMaxAmount] = useState('');

  const [selectedAmount, setAmount] = useState('');
  const [selectedMethod, setMethod] = useState('');
  const [visualMethod, setVisualMethod] = useState('');
  const [selectedEntity, setEntity] = useState('');
  const [selectedOrigin, setOrigin] = useState('');
  const [activePromotions, setActivePromotions] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [aggregator, setAgreggator] = useState('');

  const [reportEntity, seREntity] = useState('none');
  const [reportPay, setRPay] = useState('none');
  const [selectedReport, setReport] = useState({});
  const [filterError, setFilterError] = useState('');
  const [loading, setLoading] = useState(false);
  const [filterValue, setFilterValue] = useState({});
  const [filteredMethod, setFilteredMethod] = useState({});
  const [typeOfOrders, setTypeOfOrders] = useState([]);

  const handleFilterTable = (data) => {
    if (Object.keys(data).length > 0) {
      setFilterValue(data);
      const { filterEntity, type } = data;
      const filteredEntityData = calculatorResponse.filter(
        (obj) => obj[0]?.cardType === filterEntity,
      );
      const filteredSelectedData = filteredEntityData[0].filter(
        (obj) => obj?.etiquetaPago === type,
      );
      setFilteredMethod(filteredSelectedData[0]);
    } else {
      setFilterValue({});
      setFilteredMethod({});
    }
  };

  useEffect(() => {
    if (Array.isArray(calculatorResponse)) {
      if (calculatorResponse.length > 0) {
        setVisualMethod(selectedMethod);
        if (Array.isArray(calculatorResponse[0])) {
          // Fiserv
          const filterIndex = calculatorResponse.filter(
            (obj) => obj[0]?.meses === 0,
          );
          const filterAggregator = filterIndex[0].map(
            (x) => x.agregadorDePago,
          );
          if (filterAggregator[0] === 'fiserv') {
            // Fiserv
            let amexTableData;
            let citiTableData;
            let bbvaTableData;
            let prosaTableData;

            const filteredAMEX = calculatorResponse.filter(
              (obj) => obj[0]?.cardType === 'american-express',
            );
            if (filteredAMEX.length > 0) {
              const filterAMEXMonths = filteredAMEX[0].map((x) => ({
                type: x.etiquetaPago,
                amount: (x.porcentajeSobretasa === 0 && x.meses !== 0 && x.conPromocion === false) ? 0 : x.totalPagar,
                promotions: x.conPromocion,
                entity: 'American Express',
                filterEntity: x.cardType,
              }));
              amexTableData = filterAMEXMonths;
            } else {
              amexTableData = [];
            }
            const filteredCiti = calculatorResponse.filter(
              (obj) => obj[0]?.cardType === 'banamex',
            );
            if (filteredCiti.length > 0) {
              const filterCitiMonths = filteredCiti[0].map((x) => ({
                type: x.etiquetaPago,
                amount: (x.porcentajeSobretasa === 0 && x.meses !== 0 && x.conPromocion === false) ? 0 : x.totalPagar,
                promotions: x.conPromocion,
                entity: 'Citibanamex',
                filterEntity: x.cardType,
              }));
              citiTableData = filterCitiMonths;
            } else {
              citiTableData = [];
            }

            const filteredBBVA = calculatorResponse.filter(
              (obj) => obj[0]?.cardType === 'bbva',
            );

            if (filteredBBVA.length > 0) {
              const filterBBVAMonths = filteredBBVA[0].map((x) => ({
                type: x.etiquetaPago,
                amount: (x.porcentajeSobretasa === 0 && x.meses !== 0 && x.conPromocion === false) ? 0 : x.totalPagar,
                promotions: x.conPromocion,
                entity: 'BBVA',
                filterEntity: x.cardType,
              }));
              bbvaTableData = filterBBVAMonths;
            } else {
              bbvaTableData = [];
            }

            const filteredProsa = calculatorResponse.filter(
              (obj) => obj[0]?.cardType === 'prosa',
            );
            if (filteredProsa.length > 0) {
              const filterProsaMonths = filteredProsa[0].map((x) => ({
                type: x.etiquetaPago,
                amount: (x.porcentajeSobretasa === 0 && x.meses !== 0 && x.conPromocion === false) ? 0 : x.totalPagar,
                promotions: x.conPromocion,
                entity: 'Otros bancos (Prosa)',
                filterEntity: x.cardType,
              }));
              prosaTableData = filterProsaMonths;
            } else {
              prosaTableData = [];
            }

            const tableBanksData = [
              prosaTableData,
              bbvaTableData,
              citiTableData,
              amexTableData,
            ];
            setAgreggator('fiserv');
            setTableData(tableBanksData);
            setLoading(false);
          } else if (filterAggregator[0] === 'billpocket') {
            //Billpocket
            const filteredAMEX = calculatorResponse.filter(
              (obj) => obj[0]?.cardType === 'american-express',
            );
            const filterAMEXMonths = filteredAMEX[0].map((x) => ({
              type: x.etiquetaPago,
              amount: (x.porcentajeSobretasa === 0 && x.meses !== 0 && x.conPromocion === false) ? 0 : x.totalPagar,
              promotions: x.conPromocion,
              entity: 'American Express',
              filterEntity: x.cardType,
            }));
            const amexTableData = filterAMEXMonths;
            const filteredProsa = calculatorResponse.filter(
              (obj) => obj[0]?.cardType === 'visa',
            );
            const filterProsaMonths = filteredProsa[0].map((x) => ({
              type: x.etiquetaPago,
              amount: (x.porcentajeSobretasa === 0 && x.meses !== 0 && x.conPromocion === false) ? 0 : x.totalPagar,
              promotions: x.conPromocion,
              entity: 'Visa',
              filterEntity: x.cardType,
            }));
            const prosaTableData = filterProsaMonths;
            const tableBanksData = [prosaTableData, amexTableData];
            setAgreggator('billpocket');
            setTableData(tableBanksData);
            setLoading(false);
          }
        } else {
          const filterToPay = calculatorResponse
            .filter((x) => x.etiquetaPago === 'Pago de contado')
            .map((x) => x.totalPagar);
          const filterAggregator = calculatorResponse
            .filter((x) => x.etiquetaPago === 'Pago de contado')
            .map((x) => x.agregadorDePago);

          setAgreggator(filterAggregator[0]);
          setTableData(calculatorResponse);
          setLoading(false);
        }
      } else {
        if (loading) {
          setLoading(false);
          setFilterError(
            'No se ha encontrado información de la entidad bancaria seleccionada',
          );
        }
      }
    }
  }, [calculatorResponse]);

  useEffect(() => {
    if(ordersType === null || !ordersType) return;
    const typeOrders = ordersType.output.filter(type => type.isActive).map(type => type.typeOrder);
    setTypeOfOrders(typeOrders);
  }, [ordersType]);

  useEffect(() => {
    if (selectedOrderType && agencySettings.length > 0) {
      const filteredByOrder = agencySettings.filter(
        (x) => x.typeOrder === selectedOrderType,
      );
      const result = filteredByOrder[0]?.maximumAmountCardPayment;
      setMaxAmount(result === null ? 0 : result);
    }
  }, [selectedOrderType]);

  useEffect(() => {
    if (role === 'Super Admin') {
      cleanData();
    } else {
      cleanData();
    }
  }, [role]);

  const cleanData = () => {
    setAgreggator('');
    setTableData([]);
    setAmount('');
    setMethod('');
    setEntity('');
    setAgencySelected('');
    setOrderType('');
    setReport({});
    setFilterError('');
  };

  const cleanPrecalculus = () => {
    setReport({});
    setFilterValue({});
    setFilteredMethod({});
    setTableData([]);
  };

  useEffect(() => {
    if (agencyId) {
      dispatch(getCalculatorData(agencyId));
      dispatch(getOrdersType({agencyID: agencyId}));
    }
  }, [agencyId]);

  useEffect(() => {
    if (agencySelected) {
      dispatch(getCalculatorData(agencySelected?.id));
      dispatch(getOrdersType({agencyID: agencySelected?.id}));
    }
  }, [agencySelected]);

  const { handleSubmit, register, errors, control, watch } = useForm({
    defaultValues: {
      provisional: false,
    },
  });

  const handleFetchSearchData = () => {
    setLoading(true);
    setReport({});
    setFilterError('');
    setTableData([]);
    seREntity('none');
    setRPay('none');
    const searchData = {
      orderId: null,
      origin: 'online',
      agencyID: agencyId || agencySelected?.id,
      amount: selectedAmount,
      type: selectedOrderType === 'service' ? 'ods' : 'odp',
      subType: selectedOrderType,
      cardDC: selectedMethod,
      cardType: 'visa',
      origin: selectedOrigin,
      isInternational:
        selectedEntity === 'international' ? true : false,
      bankRates: 'all',
      location: 'calculator',
      withPromotions: activePromotions,
    };
    dispatch(getFetchCalculatorData(searchData));
  };

  const fetchSelectedItem = () => {
    const reportFilter = calculatorResponse.filter(
      (obj) => obj[0]?.cardType === reportEntity,
    );
    const resultData = reportFilter[0];
    const reportFilterBool = resultData.filter(
      (x) => x.meses == reportPay,
    );
    if (reportFilterBool.length > 0) {
      if((reportFilterBool[0].porcentajeSobretasa !== 0 || reportFilterBool[0].meses === 0)) {
        setReport(reportFilterBool[0]);
        setFilterError('');
      } else {
        setReport({});
        setFilterError(
          'No se ha encontrado información de la entidad bancaria seleccionada',
        );
      }
    } else {
      setReport({});
      setFilterError(
        'No se ha encontrado información de la entidad bancaria seleccionada',
      );
    }
  };

  const renderCalculation = () => {
    return (
      <div>
        <ReportDataTable
          tableData={tableData}
          visualMethod={visualMethod}
          selectedItem={filterValue}
          setSelectedValue={(item) => handleFilterTable(item)}
        />
        {Object.keys(filteredMethod).length > 0 && (
          <Paper
            elevation={3}
            style={{
              padding: 15,
              marginTop: '15px',
              marginBottom: '15px',
            }}
          >
            <Typography className={classes.headerTitle} variant="h5">
              {filteredMethod.etiquetaPago !== 'Pago de contado' ? `Pago diferido - ${filterValue.entity}` : `Pago de contado - ${filterValue.entity}`}
            </Typography>
            <div className={classes.dividerContainer}>
              <Divider style={{ width: '100%' }} />
            </div>

            <Table>
              <TableBody>
                <TableRow>
                  <TableCell size={'small'}>
                    <Typography className={classes.subtitleTable}>
                      {`Comisión base:`}
                    </Typography>
                  </TableCell>
                  <TableCell size={'small'} align={'center'}>
                    <Typography className={classes.subtitleTable}>
                      <strong>
                        {formatMoney(
                          filteredMethod.comisiones?.comissionBase,
                        ) || '-'}
                      </strong>
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size={'small'}>
                    <Typography className={classes.subtitleTable}>
                      {`Pocentaje comisión:`}
                    </Typography>
                  </TableCell>
                  <TableCell size={'small'} align={'center'}>
                    <Typography className={classes.subtitleTable}>
                      <strong>
                        {formatPercent(
                          filteredMethod.porcentajeComision,
                        ) || '-'}
                      </strong>
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size={'small'}>
                    <Typography className={classes.subtitleTable}>
                      {`Tu cliente paga:`}
                    </Typography>
                  </TableCell>
                  <TableCell size={'small'} align={'center'}>
                    <Typography className={classes.subtitleTable}>
                      <strong>
                        {formatMoney(filteredMethod.totalPagar) ||
                          '-'}
                      </strong>
                    </Typography>
                  </TableCell>
                </TableRow>
                {(filteredMethod.porcentajeSobretasa !== 0 && filteredMethod.meses !== 0) ? (
                  <TableRow>
                    <TableCell size={'small'}>
                      <Typography className={classes.subtitleTable}>
                        {filteredMethod?.conPromocion
                          ? `${filteredMethod.meses} MSI de: `
                          : `${filteredMethod.meses} Pagos de: `}
                      </Typography>
                    </TableCell>
                    <TableCell size={'small'} align={'center'}>
                      <Typography className={classes.subtitleTable}>
                        <strong>
                          {formatMoney(
                            filteredMethod.totalPagoPorMes,
                          ) || '-'}
                        </strong>
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </Paper>
        )}
      </div>
    );
  };

  const renderFilters = () => {
    return (
      <div style={{ marginTop: '15px', marginBottom: '15px' }}>
        <Select
          size={'medium'}
          align={'left'}
          className={classes.borderInputFilter}
          variant="outlined"
          onChange={(e) => seREntity(e.target.value)}
          value={reportEntity}
        >
          <MenuItem value="none" disabled>
            Selecciona una entidad bancaria
          </MenuItem>
          <MenuItem value="american-express">
            American Express
          </MenuItem>
          {aggregator === 'fiserv' ? (
            <MenuItem value="banamex">Citibanamex</MenuItem>
          ) : null}
          {aggregator === 'fiserv' ? (
            <MenuItem value="bbva">BBVA</MenuItem>
          ) : (
            <MenuItem value="visa">Visa / Mastercard</MenuItem>
          )}
          {aggregator === 'fiserv' ? (
            <MenuItem value="prosa">Otros bancos</MenuItem>
          ) : null}
        </Select>
        <Select
          size={'medium'}
          align={'left'}
          className={classes.borderInputFilter}
          variant="outlined"
          onChange={(e) => setRPay(e.target.value)}
          value={reportPay}
        >
          <MenuItem value="none" disabled>
            Selecciona un método de pago
          </MenuItem>
          <MenuItem value="0">Contado</MenuItem>
          <MenuItem
            value="3"
            disabled={visualMethod === 'credit' ? false : true}
          >
            3 meses
          </MenuItem>
          <MenuItem
            value="6"
            disabled={visualMethod === 'credit' ? false : true}
          >
            6 meses
          </MenuItem>
          <MenuItem
            value="9"
            disabled={visualMethod === 'credit' ? false : true}
          >
            9 meses
          </MenuItem>
          <MenuItem
            value="12"
            disabled={visualMethod === 'credit' ? false : true}
          >
            12 meses
          </MenuItem>
        </Select>
        <Button
          variant="contained"
          disabled={reportPay === 'none' ? true : false}
          className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
          onClick={fetchSelectedItem}
        >
          Filtrar
        </Button>
      </div>
    );
  };

  const loadingShimmers = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Skeleton
            className={classes.loadingSkeletonRow}
            variant="rounded"
            animation="pulse"
            height={'250px'}
            width={'100%'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Skeleton
            className={classes.loadingSkeletonRow}
            variant="rounded"
            animation="pulse"
            height={'150px'}
            width={'100%'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Skeleton
            className={classes.loadingSkeletonRow}
            variant="rounded"
            animation="pulse"
            height={'150px'}
            width={'100%'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Skeleton
            className={classes.loadingSkeletonRow}
            variant="rounded"
            animation="pulse"
            height={'150px'}
            width={'100%'}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <Paper className={classes.paper}>
          <Typography variant="h6" style={{ marginBottom: '20px' }}>
            Calculadora de pagos
          </Typography>
          {role === 'Super Admin' && (
            <>
              <Typography
                variant="h6"
                style={{ marginBottom: '20px' }}
              >
                Agencia
              </Typography>
              <MUIRHFAutocompleteAgencies
                name="agencyID"
                control={control}
                fullWidth
                className={classes.textField}
                getAllInfo={(newValue) => {
                  cleanPrecalculus();
                  setAgencySelected(newValue);
                }}
              />
            </>
          )}
          <Typography variant="h6" style={{ marginBottom: '20px' }}>
            Monto de venta
          </Typography>
          <TextField
            required
            fullWidth
            type="text"
            label="Monto de venta"
            variant="outlined"
            value={selectedAmount}
            onChange={({ target }) => {
              cleanPrecalculus();
              setAmount(target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              inputComponent: NumberFormatCustom,
              inputProps: { quantity: true },
            }}
            className={classes.textField}
          />
          <FormControlLabel
            control={
              <Switch
                checked={activePromotions}
                onChange={(event) => {
                  cleanPrecalculus();
                  setActivePromotions(event.target.checked);
                }}
                name="activePromotions"
                color="primary"
              />
            }
            label={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  style={{ marginRight: '10px' }}
                  variant="h6"
                >
                  Promociones
                </Typography>
                <Tooltip
                  title={`Al activar esta opción se mostrarán las promociones de MSI que esten activas en la agencia.`}
                >
                  <ErrorIcon />
                </Tooltip>
              </div>
            }
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '10px',
              marginBottom: '10px',
            }}
          >
            <Typography variant="h6">Tipo de orden</Typography>
          </div>

          <Select
            size={'medium'}
            align={'left'}
            className={classes.borderInput}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              cleanPrecalculus();
              setOrderType(e.target.value);
            }}
            value={selectedOrderType}
          >
            <MenuItem value="">Tipo de orden</MenuItem>
            {typeOfOrders.map((typeOrder, index) => (
              <MenuItem key={index} value={typeOrder}>{typeOfPromotionMap(typeOrder)}</MenuItem>
            ))}
          </Select>
          {maxAmount !== '' && maxAmount !== undefined ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: '10px',
              }}
            >
              <Typography>
                Monto máximo para cobro con TDC / TDD* -{' '}
                <strong>{'$' + maxAmount}</strong>
              </Typography>
              <Tooltip
                title={`El cliente podrá pagar hasta ${formatMoney(
                  maxAmount,
                )} con tarjetas bancarias en caso de superar este monto el cliente pagara comisión por uso de terminal del monto excedente`}
              >
                <ErrorIcon />
              </Tooltip>
            </div>
          ) : null}
          <Typography variant="h6" style={{ marginBottom: '10px' }}>
            Origen
          </Typography>

          <Select
            size={'medium'}
            align={'left'}
            className={classes.borderInput}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              cleanPrecalculus();
              setOrigin(e.target.value);
            }}
            value={selectedOrigin}
          >
            <MenuItem value="ecommerce">Pagos Online</MenuItem>
            <MenuItem value="tpv">Pagos TPV</MenuItem>
          </Select>
          <Typography variant="h6" style={{ marginBottom: '10px' }}>
            Método de pago
          </Typography>

          <Select
            size={'medium'}
            align={'left'}
            className={classes.borderInput}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              cleanPrecalculus();
              setMethod(e.target.value);
            }}
            value={selectedMethod}
          >
            <MenuItem value="debit">Débito</MenuItem>
            <MenuItem value="credit">Credito</MenuItem>
          </Select>
          <Button
            variant="contained"
            className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
            onClick={handleFetchSearchData}
          >
            Calcular
          </Button>
        </Paper>
      </Grid>
      <Grid item xs={12} md={9}>
        <Paper className={classes.paperCalculed}>
          {loading ? (
            loadingShimmers()
          ) : tableData.length > 0 ? (
            <>
              {(aggregator === 'fiserv' ||
                aggregator === 'billpocket') && (
                <>
                  {renderCalculation()}
                  {role === 'Super Admin' ||
                  role === 'Accounting' ||
                  role === 'Cashier'
                    ? renderFilters()
                    : null}
                </>
              )}
              {filterError.length > 0 && (
                <Typography className={classes.emptyStateTitle}>
                  {filterError}
                </Typography>
              )}
              {Object.keys(selectedReport).length > 0 && (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <div className={classes.containerInfo}>
                      <Table>
                        <TableBody>
                          <TableRow
                            style={{
                              background: '#d0dcf4',
                            }}
                          >
                            <TableCell size={'small'}>
                              <Typography
                                className={classes.headerTitle}
                              >
                                Tu cliente paga:
                              </Typography>
                            </TableCell>
                            <TableCell
                              size={'small'}
                              align={'center'}
                            >
                              <Typography
                                className={classes.headerTitle}
                              >
                                <strong style={{ color: '#000000' }}>
                                  {formatMoney(
                                    selectedReport.totalPagar,
                                  ) || '-'}
                                </strong>
                              </Typography>
                            </TableCell>
                          </TableRow>

                          {selectedReport.meses !== 0 && (
                            <TableRow>
                              <TableCell size={'small'}>
                                <Typography
                                  className={classes.subtitleTable}
                                >
                                  {`${selectedReport.meses} Pagos de: `}
                                </Typography>
                              </TableCell>
                              <TableCell
                                size={'small'}
                                align={'center'}
                              >
                                <Typography
                                  className={classes.subtitleTable}
                                >
                                  <strong>
                                    {formatMoney(
                                      selectedReport.totalPagoPorMes,
                                    ) || '-'}
                                  </strong>
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )}

                          <TableRow>
                            <TableCell size={'small'}>
                              <Typography
                                className={classes.subtitleTable}
                              >
                                {'Comisión bancaria:'}
                              </Typography>
                            </TableCell>
                            <TableCell
                              size={'small'}
                              align={'center'}
                            >
                              <Typography
                                className={classes.subtitleTable}
                              >
                                <strong>
                                  {formatMoney(
                                    selectedReport.totalComisionAgencia,
                                  ) || '-'}
                                </strong>
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell size={'small'}>
                              <Typography
                                className={classes.subtitleTable}
                              >
                                {'El banco te retiene:'}
                              </Typography>
                            </TableCell>
                            <TableCell
                              size={'small'}
                              align={'center'}
                            >
                              <Typography
                                className={classes.subtitleTable}
                              >
                                <strong>
                                  {formatMoney(
                                    selectedReport.totalComisionCliente,
                                  ) || '-'}
                                </strong>
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow
                            style={{
                              background: '#d0dcf4',
                            }}
                          >
                            <TableCell size={'small'}>
                              <Typography
                                className={classes.headerTitle}
                              >
                                Tu recibes:
                              </Typography>
                            </TableCell>
                            <TableCell
                              size={'small'}
                              align={'center'}
                            >
                              <Typography
                                className={classes.headerTitle}
                              >
                                <strong style={{ color: '#000000' }}>
                                  {formatMoney(
                                    selectedReport.totalDepositoAgencia,
                                  ) || '-'}
                                </strong>
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className={classes.containerInfo}>
                      <Typography
                        className={classes.headerTitle}
                        variant="h5"
                      >
                        {`Detalle de movimientos - ${selectedReport.etiquetaPago}`}
                      </Typography>
                      <div className={classes.dividerContainer}>
                        <Divider style={{ width: '100%' }} />
                      </div>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell size={'small'}>
                              <Typography
                                className={classes.subtitleTable}
                              >
                                {`Monto de la orden de pago:`}
                              </Typography>
                            </TableCell>
                            <TableCell
                              size={'small'}
                              align={'center'}
                            >
                              <Typography
                                className={classes.subtitleTable}
                              >
                                <strong>
                                  {formatMoney(
                                    selectedReport.montoOrden,
                                  ) || '-'}
                                </strong>
                              </Typography>
                            </TableCell>
                          </TableRow>
                          {selectedReport.meses !== 0 && (
                            <TableRow>
                              <TableCell size={'small'}>
                                <Typography
                                  className={classes.subtitleTable}
                                >
                                  {`Comisión cliente por diferir a ${selectedReport.meses} meses:`}
                                </Typography>
                              </TableCell>
                              <TableCell
                                size={'small'}
                                align={'center'}
                              >
                                <Typography
                                  className={classes.subtitleTable}
                                >
                                  <strong>
                                    {formatMoney(
                                      selectedReport.totalComisionPorDiferirAMeses,
                                    ) || '-'}
                                  </strong>
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )}
                          <TableRow>
                            <TableCell size={'small'}>
                              <Typography
                                className={classes.subtitleTable}
                              >
                                {`Comisión por uso terminal:`}
                              </Typography>
                            </TableCell>
                            <TableCell
                              size={'small'}
                              align={'center'}
                            >
                              <Typography
                                className={classes.subtitleTable}
                              >
                                <strong>
                                  {formatMoney(
                                    selectedReport.totalComisionPorUsoTerminal,
                                  ) || '-'}
                                </strong>
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell size={'small'}>
                              <Typography
                                className={classes.subtitleTable}
                              >
                                {`Comisión bancaria por transacción exitosa:`}
                              </Typography>
                            </TableCell>
                            <TableCell
                              size={'small'}
                              align={'center'}
                            >
                              <Typography
                                className={classes.subtitleTable}
                              >
                                <strong>
                                  {formatPercent(
                                    selectedReport?.porcentajeComision,
                                  ) || '-'}
                                  {' + '}
                                  {formatPercent(
                                    selectedReport?.porcentajeSobretasa,
                                  ) || '-'}
                                  {' + '}
                                  {formatMoney(
                                    selectedReport.comisionMonetariaBanco,
                                  ) || '-'}
                                  {' + IVA'}
                                </strong>
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className={classes.containerInfo}>
                      <Typography className={classes.headerTitle}>
                        Desglose de comisión bancaria
                      </Typography>
                      <div className={classes.dividerContainer}>
                        <Divider style={{ width: '100%' }} />
                      </div>
                      <Table>
                        <TableHead>
                          <TableRow className={classes.headerColumns}>
                            <TableCell size={'small'} align={'left'}>
                              <Typography
                                className={classes.titleTable}
                              >
                                Nombre
                              </Typography>
                            </TableCell>
                            <TableCell
                              size={'small'}
                              align={'center'}
                            >
                              <Typography
                                className={classes.titleTable}
                              >
                                Monto
                              </Typography>
                            </TableCell>
                            <TableCell
                              size={'small'}
                              align={'center'}
                            >
                              <Typography
                                className={classes.titleTable}
                              >
                                Porcentaje
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          <TableRow>
                            <TableCell size={'small'}>
                              <Typography>
                                {'Comisión base:'}
                              </Typography>
                            </TableCell>
                            <TableCell
                              size={'small'}
                              align={'center'}
                            >
                              <strong>
                                {formatMoney(
                                  selectedReport?.comisiones
                                    ?.comissionBase,
                                ) || '-'}
                              </strong>
                            </TableCell>
                            <TableCell
                              size={'small'}
                              align={'center'}
                            >
                              <strong>
                                {formatPercent(
                                  selectedReport?.porcentajeComision,
                                ) || '-'}
                              </strong>
                            </TableCell>
                          </TableRow>
                          {selectedReport.meses !== 0 && (
                            <TableRow>
                              <TableCell size={'small'}>
                                <Typography>
                                  {`Sobretasa ${selectedReport.meses} MSI:`}
                                </Typography>
                              </TableCell>
                              <TableCell
                                size={'small'}
                                align={'center'}
                              >
                                <strong>
                                  {formatMoney(
                                    selectedReport?.sobreTasas
                                      ?.sobreTasa,
                                  ) || '-'}
                                </strong>
                              </TableCell>
                              <TableCell
                                size={'small'}
                                align={'center'}
                              >
                                <strong>
                                  {formatPercent(
                                    selectedReport?.porcentajeSobretasa,
                                  ) || '-'}
                                </strong>
                              </TableCell>
                            </TableRow>
                          )}
                          <TableRow>
                            <TableCell size={'small'}>
                              <Typography>
                                {`IVA Comision:`}
                              </Typography>
                            </TableCell>
                            <TableCell
                              size={'small'}
                              align={'center'}
                            >
                              <strong>
                                {formatMoney(
                                  selectedReport?.comisiones
                                    ?.comissionIvaBase,
                                ) || '-'}
                              </strong>
                            </TableCell>
                            <TableCell
                              size={'small'}
                              align={'center'}
                            >
                              {'-'}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell size={'small'}>
                              <Typography>
                                {'IVA sobretasa:'}
                              </Typography>
                            </TableCell>
                            <TableCell
                              size={'small'}
                              align={'center'}
                            >
                              <strong>
                                {formatMoney(
                                  selectedReport?.sobreTasas
                                    ?.sobreTasaIva,
                                ) || '-'}
                              </strong>
                            </TableCell>
                            <TableCell
                              size={'small'}
                              align={'center'}
                            >
                              {'-'}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                  </Grid>
                </Grid>
              )}
            </>
          ) : (
            <div
              style={{
                height: '100%',
              }}
            >
              {filterError !== '' ? (
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Lottie
                    style={{ height: '400px' }}
                    animationData={calculator}
                  />
                  <Typography className={classes.emptyStateTitle}>
                    {filterError}
                  </Typography>
                </div>
              ) : null}
            </div>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  agencyDetails: makeSelectAgencyDetailsFromState(state),
  role: makeSelectUserRoleFromState(state),
  agencyId: makeSelectAgencyIdFromState(state),
});

const mapDispatchToProps = {
  getAgency,
  getCalculatorData,
  getFetchCalculatorData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Calculator2023);
