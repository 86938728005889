import React, { useEffect, useState } from 'react';

import {
  InputAdornment,
  IconButton,
  OutlinedInput,
  FormControl,
  InputLabel,
} from '@material-ui/core';

import { Visibility, VisibilityOff } from '@material-ui/icons';

import Modal from '../../../../components/Modal';

import {
  confirmEmail as confirmEmailAction,
  logout,
} from '../../authSlice';
import { closeModals } from 'app/features/modals/modalsSlice';
import { selectAuth } from '../../selectors';
import { confirmPasswordSchema } from './schema';

import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';

function ConfirmEmail(props) {
  const { history } = props;
  const searchParams = new URLSearchParams(useLocation().search);
  const token = searchParams.get('token');
  const userID = searchParams.get('userID');
  const [showCharactersError, setShowCharactersError] = useState(
    false,
  );
  const [getErrorPass, setErrorPass] = useState(true);
  const [password, setPassword] = useState({
    password: '',
    showPassword: false,
  });
  const [passwordConfirm, setPasswordConfirm] = useState({
    password: '',
    showPassword: false,
  });

  const { handleSubmit, register, errors } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(confirmPasswordSchema),
  });

  const toggleCharactersError = () => {
    setShowCharactersError(!showCharactersError);
  };

  useEffect(() => {
    if (props.auth.user) props.logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.auth.user]);

  const handleCloseModals = () => {
    props.closeModals();
  };

  const dismissAlert = () => {
    window.setTimeout(() => {
      props.closeModals();
    }, 7000);
  };

  const handleConfirmEmail = (formData) => {
    if (!getErrorPass) {
      const data = {
        userID,
        token,
        password: formData.password,
        history,
      };
      props.confirmEmailAction(data);
      dismissAlert();
    } else {
      setShowCharactersError(true);
    }
  };

  const handleChangePassword = (value, type) => {
    const regexPass = /[ ]/;
    const characters = value;
    if (characters) {
      regexPass.test(characters)
        ? setErrorPass(true)
        : setErrorPass(false);
    } else {
      setErrorPass(false);
    }

    if (type === 'password') {
      setPassword({
        ...password,
        password: value,
      });
    } else {
      setPasswordConfirm({
        ...passwordConfirm,
        password: value,
      });
    }
  };

  const handleClickShowPassword = (type) => {
    if (type === 'password') {
      setPassword({
        ...password,
        showPassword: !password.showPassword,
      });
    } else {
      setPasswordConfirm({
        ...passwordConfirm,
        showPassword: !passwordConfirm.showPassword,
      });
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const renderError = () => {
    try {
      const { errorModal } = props.auth;
      if (errorModal && 'show' in errorModal && errorModal.show) {
        const { show, message } = props.auth['errorModal'];
        return (
          <Alert
            show={show}
            variant="danger"
            onClose={handleCloseModals}
            dismissible
          >
            <Alert.Heading>{message.desc}</Alert.Heading>
          </Alert>
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  const renderCharactersError = () => {
    return (
      <Modal
        open={showCharactersError}
        type="warning"
        closeModal={toggleCharactersError}
        dialogTitle="Error en la contraseña"
        dialogText="No se admiten espacios en blanco en la constraseña"
        actionButtonText="Aceptar"
        onClick={toggleCharactersError}
      />
    );
  };

  return (
    <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h4>
              <FormattedMessage id="AUTH.CONFIRM.TITLE" />
            </h4>
            <h6>
              <FormattedMessage id="AUTH.CONFIRM.DESC" />
            </h6>
          </div>
          <form
            onSubmit={handleSubmit(handleConfirmEmail)}
            className="kt-form"
            autoComplete="off"
            noValidate
          >
            {renderError()}
            {renderCharactersError()}
            <div className="form-group">
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-password">
                  Introduzca una nueva contraseña
                </InputLabel>
                <OutlinedInput
                  required="false"
                  inputRef={register({
                    required: false,
                  })}
                  id="outlined-adornment-password"
                  value={password.password}
                  type={password.showPassword ? 'text' : 'password'}
                  label="Introduzca su nueva contraseña"
                  margin="normal"
                  name="password"
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                  onChange={(e) =>
                    handleChangePassword(e.target.value, 'password')
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          handleClickShowPassword('password')
                        }
                        onMouseDown={handleMouseDownPassword}
                      >
                        {password.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  fullWidth
                  {...props}
                />
              </FormControl>

              <FormControl
                variant="outlined"
                fullWidth
                style={{ marginTop: '10px' }}
              >
                <InputLabel htmlFor="outlined-adornment-password-confirm">
                  Confirme su contraseña
                </InputLabel>
                <OutlinedInput
                  required="false"
                  inputRef={register({
                    required: false,
                  })}
                  id="outlined-adornment-password-confirm"
                  value={passwordConfirm.password}
                  type={
                    passwordConfirm.showPassword ? 'text' : 'password'
                  }
                  label="Confirme su contraseña"
                  margin="normal"
                  name="rePassword"
                  error={Boolean(errors.rePassword)}
                  helperText={errors?.rePassword?.message}
                  onChange={(e) =>
                    handleChangePassword(e.target.value, 'rePassword')
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          handleClickShowPassword('rePassword')
                        }
                        onMouseDown={handleMouseDownPassword}
                      >
                        {passwordConfirm.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  fullWidth
                  {...props}
                />
              </FormControl>
            </div>

            <div className="kt-login__actions">
              <Link to="/auth">
                <button
                  type="button"
                  className="btn btn-secondary btn-elevate kt-login__btn-secondary"
                  onClick={handleCloseModals}
                >
                  Volver
                </button>
              </Link>

              <button
                type="submit"
                className="btn btn-primary btn-elevate kt-login__btn-primary"
              >
                Enviar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: selectAuth(state),
});

const mapDispatchToProps = {
  confirmEmailAction,
  closeModals,
  logout,
};

export default withRouter(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail),
  ),
);
