import 'react-table/react-table.css';
import 'moment/locale/es';

/* eslint-disable react/display-name */
import { Paper, makeStyles } from '@material-ui/core';
import { getKarloUsers } from 'app/features/karloUsers/karloUsersSlice';

import { Link } from 'react-router-dom';
import React from 'react';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { injectIntl } from 'react-intl';
import { makeSelectKarloUsers } from 'app/features/karloUsers/selectors';
import { useForm } from 'react-hook-form';
import { formatSingleDate, formatHour } from '../../../../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(0),
  },
  notifications: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  lastUpdated: {
    marginLeft: theme.spacing(2),
    color: '#434349',
  },
  datePickerTextfield: {
    padding: '5px 5px 5px !important',
    height: '20px',
  },
}));

function KarloUsersTable(props) {
  const classes = useStyles();
  const { role, getKarloUsers, karloUsers } = props;
  const [karloUsersData, setKarloUsersData] = React.useState([]);
  const reactTable = React.useRef(null);

  const handleFetchData = debounce(
    (state, instance) => {
      const { page, pageSize, sorted, filtered } = state;
      getKarloUsers({ page, pageSize, sorted, filtered });
    },
    1000,
    { leading: false, trailing: true },
  );

  React.useEffect(() => {
    console.log('karloUsersFROMDB ', karloUsers.fromdb);
    if (Array.isArray(karloUsers.fromdb)) {
      const cleanData = karloUsers.fromdb.map((user) => {
        return {
          id: user.id,
          name: user.name ? user.name : 'n/a',
          phoneNumber: user.phoneNumber ? user.phoneNumber : 'n/a',
          email: user.email ? user.email : 'n/a',
          agency: user.agency.length != 0 ? user.agency.name : 'n/a',
          loginType: user.loginType,
          createdHour: formatHour(user.createdAt),
          createdAt: formatSingleDate(user.createdAt),
        };
      });
      setKarloUsersData(cleanData);
    }
  }, [karloUsers]);

  const columns = [
    {
      Header: () => <strong>ID</strong>,
      accessor: 'id',
      Cell: (row) => (
        <Link to={`/karlouser-detail/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Nombre</strong>,
      accessor: 'name',
      Cell: (row) => (
        <Link to={`/karlouser-detail/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Teléfono</strong>,
      accessor: 'phoneNumber',
      Cell: (row) => (
        <Link to={`/karlouser-detail/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Correo</strong>,
      accessor: 'email',
      Cell: (row) => (
        <Link to={`/karlouser-detail/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Tipo de Login</strong>,
      accessor: 'loginType',
      Cell: (row) => (
        <Link to={`/karlouser-detail/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Fecha de Registro</strong>,
      accessor: 'createdAt',
      Cell: (row) => (
        <Link to={`/karlouser-detail/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Hora de Registro</strong>,
      accessor: 'createdHour',
      Cell: (row) => (
        <Link to={`/karlouser-detail/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
  ];

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <ReactTable
          ref={reactTable}
          NoDataComponent={() => (
            <div className="rt-noData">Sin resultados</div>
          )}
          manual
          onFetchData={handleFetchData}
          data={karloUsersData}
          pages={karloUsers.tablePages}
          columns={columns}
          // filterable
          className="-striped -highlight"
          previousText="Anterior"
          nextText="Siguiente"
          loading={karloUsers.isLoading}
          loadingText="Cargando datos..."
        />
      </Paper>
    </div>
  );
}

const mapStateToProps = (state) => ({
  karloUsers: makeSelectKarloUsers(state),
});

const mapDispatchToProps = {
  getKarloUsers,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(KarloUsersTable),
);
