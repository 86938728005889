import { Col, Row } from 'react-bootstrap';
import {
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import {
  defaultComissions,
  monthsByComissions,
} from '../../../../config/monthsTaxes';
import {
  formatNumber,
  getNested,
  roundValue,
} from '../../../../utils';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import NumberFormatCustom from '../../../../components/NumberFormatCustom';
import ShouldItRender from 'app/components/ShouldItRender';
import { connect } from 'react-redux';
import { getAgency } from 'app/features/agencies/agenciesSlice';
import { makeSelectAgencyDetailsFromState } from '../../../agencies/selectors';
import { makeSelectUserRoleFromState } from 'app/features/auth/selectors';
import { superAdminRoles } from 'app/config/roles';

const IVA = 1.16;
const BASE_EXTRA_WITH_IVA = {
  billPocket: 0,
  netpay: 2.9,
};

const useStyles = makeStyles((theme) => ({
  container: {
    flexWrap: 'wrap',
  },
  paper: {
    padding: theme.spacing(7, 10, 1, 10),
  },
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  client: {
    padding: theme.spacing(0),
    marginBottom: theme.spacing(2),
  },
  textField: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  media: {
    height: 60,
    width: '100%',
    position: 'relative',
  },
  image: {
    height: '100%',
    width: 'auto',
    position: 'absolute',
    top: '10%',
    left: '45%',
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  subtitle: {
    marginBottom: theme.spacing(4.5),
  },
  subTotals: {
    margin: theme.spacing(4, 0),
  },
  totals: {
    margin: theme.spacing(3, 0),
  },
  conditions: {
    margin: theme.spacing(6, 0, 0, 0),
  },
}));

function PromotionsCalculator(props) {
  const { agencyDetails, getAgency, role } = props;
  const classes = useStyles();
  const promotion = 1;
  const [amount, setAmount] = React.useState('');
  const [paymentType, setPaymentType] = React.useState(1);
  const [cardType, setCardType] = React.useState(0);
  const [placement, setPlacement] = React.useState(0);
  const [iva, setIva] = React.useState(0);
  const [
    surchargeWithOutIva,
    setSurchargeWithOutIva,
  ] = React.useState(0);
  const [
    comissionWithOutIva,
    setComissionWithOutIva,
  ] = React.useState(0);
  const [totalToDeposit, setTotalToDeposit] = React.useState(0);
  const [totalToBeCharged, setTotalToBeCharged] = React.useState(0);

  useEffect(() => {
    if (!superAdminRoles.includes(role)) getAgency();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    cleanValues();
    handleCalculateTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, paymentType, cardType, placement, promotion]);

  const cleanValues = () => {
    setIva(0);
    setSurchargeWithOutIva(0);
    setComissionWithOutIva(0);
    setTotalToDeposit(0);
    setTotalToBeCharged(0);
  };

  const getBaseComission = () => {
    switch (cardType) {
      case 2:
        return getNested(
          defaultComissions['amex'],
          agencyDetails,
          'amexCardComission',
        );
      default:
        return getNested(
          defaultComissions['visa/mastercard'],
          agencyDetails,
          'cardComission',
        );
    }
  };

  const getSurchargeByCardType = () => {
    switch (cardType) {
      case 2:
        return monthsByComissions[placement].amex;
      default:
        return monthsByComissions[placement].normal;
    }
  };

  const calculateTotalCards = async () => {
    let baseComission = 0,
      baseComissionWithOutIVA = 0,
      baseComissionWithIVA = 0,
      surchargeByCardType = 0,
      completeSurcharge = 0,
      toDepositToAgency = 0,
      tempIva = 0,
      totalToBeCharged = 0,
      totalAmountToCharge = parseFloat(amount);
    switch (paymentType) {
      case 1:
        while (amount > toDepositToAgency) {
          baseComissionWithOutIVA =
            getBaseComission() * parseFloat(totalAmountToCharge);
          baseComissionWithIVA = getBaseComission() * IVA;
          tempIva = baseComissionWithOutIVA * 0.16;
          toDepositToAgency = roundValue(
            totalAmountToCharge - baseComissionWithOutIVA * 1.16,
          );
          const difference = amount - toDepositToAgency;
          totalAmountToCharge += difference;
        }
        setIva(tempIva);
        setComissionWithOutIva(baseComissionWithOutIVA);
        setSurchargeWithOutIva(0);
        setTotalToDeposit(toDepositToAgency);
        setTotalToBeCharged(totalAmountToCharge);
        break;
      case 2:
        while (amount > toDepositToAgency) {
          baseComissionWithOutIVA = 0;
          baseComissionWithIVA = 0;
          tempIva = 0;
          baseComission = getBaseComission();
          baseComissionWithOutIVA =
            baseComission * parseFloat(totalAmountToCharge);
          baseComissionWithIVA = baseComission * IVA;
          tempIva += baseComissionWithOutIVA * 0.16;
          surchargeByCardType = getSurchargeByCardType();
          tempIva +=
            surchargeByCardType *
            parseFloat(totalAmountToCharge) *
            0.16;
          completeSurcharge =
            surchargeByCardType * parseFloat(totalAmountToCharge);
          const baseComissionCalculated =
            totalAmountToCharge * baseComissionWithIVA +
            BASE_EXTRA_WITH_IVA['billPocket'];
          //MONTHS WITH OUT INTEREST CASE
          toDepositToAgency = roundValue(
            totalAmountToCharge -
              baseComissionCalculated -
              totalAmountToCharge * (getSurchargeByCardType() * IVA),
          );
          totalToBeCharged = totalAmountToCharge;
          const difference = amount - toDepositToAgency;
          totalAmountToCharge += difference;
        }
        setIva(tempIva);
        setComissionWithOutIva(baseComissionWithOutIVA);
        setSurchargeWithOutIva(completeSurcharge);
        setTotalToDeposit(toDepositToAgency);
        setTotalToBeCharged(totalToBeCharged);
        break;
      default:
        console.log('CAse not supported');
        break;
    }
  };

  const handleCalculateTotal = () => {
    if (amount && paymentType) {
      switch (paymentType) {
        case 1:
          if (cardType && amount > 11.99) calculateTotalCards();
          break;
        case 2:
          if (cardType && placement && promotion && amount > 299.99)
            calculateTotalCards();
          break;

        default:
          console.log('calculating...');
          break;
      }
    } else {
      cleanValues();
    }
  };

  const validMonth = (month) => {
    return amount / month >= 100;
  };

  const renderMonthsOptions = () => {
    const options = [];
    Object.keys(monthsByComissions).forEach((month) => {
      if (validMonth(month))
        options.push(
          <MenuItem key={`month-${month}`} value={month}>
            {month} meses
          </MenuItem>,
        );
    });
    return options;
  };

  const renderSelectCard = () => {
    return (
      <Grid item xs={12} sm={6} className={classes.section}>
        <TextField
          required
          select
          fullWidth
          label="Tipo de tarjeta"
          variant="outlined"
          className={classes.textField}
          value={cardType}
          onChange={({ target }) => {
            setCardType(target.value);
          }}
          SelectProps={{
            MenuProps: {
              className: classes.menu,
            },
          }}
        >
          <MenuItem value={0}>Seleccione una opción</MenuItem>
          <MenuItem value={1}>Visa/Mastercard</MenuItem>
          <MenuItem value={2}>Amex</MenuItem>
        </TextField>
      </Grid>
    );
  };

  const handleRenderPlacements = () => {
    switch (paymentType) {
      case 1:
        return renderSelectCard();
      case 2:
        return (
          <>
            <Grid item xs>
              <TextField
                required
                select
                fullWidth
                label="Plazo"
                variant="outlined"
                className={classes.textField}
                value={placement}
                onChange={({ target }) => {
                  setPlacement(target.value);
                }}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title={
                          <>
                            <Typography variant="body2">
                              *El monto mínimo para pagos a 3 meses es
                              de $300.00 pesos.
                            </Typography>
                            <Typography variant="body2">
                              *El monto mínimo para pagos a 6 meses es
                              de $600.00 pesos.
                            </Typography>
                            <Typography variant="body2">
                              *El monto mínimo para pagos a 9 meses es
                              de $900.00 pesos.
                            </Typography>
                            <Typography variant="body2">
                              *El monto mínimo para pagos a 12 meses
                              es de $1200.00 pesos.
                            </Typography>
                          </>
                        }
                      >
                        <HelpOutlineIcon />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              >
                <MenuItem value={0}>Seleccione una opción</MenuItem>
                {renderMonthsOptions()}
              </TextField>
            </Grid>
            {renderSelectCard()}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Row>
      <Col lg="8" md="10">
        <Paper className={classes.paper}>
          <form
            className={classes.container}
            noValidate
            autoComplete="off"
          >
            <div className="input-container">
              <Grid xs={12} item className={classes.subtitle}>
                <Typography variant="h6">
                  Calculadora de venta
                </Typography>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    type="text"
                    label="Monto a recibir"
                    variant="outlined"
                    value={amount}
                    onChange={({ target }) => setAmount(target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          $
                        </InputAdornment>
                      ),
                      inputComponent: NumberFormatCustom,
                      inputProps: { quantity: true },
                    }}
                    className={classes.textField}
                  />
                </Grid>
                <ShouldItRender should={!process.env.REACT_APP_ENV}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className={classes.section}
                  >
                    <TextField
                      required
                      select
                      fullWidth
                      name="paymentType"
                      label="Método de pago"
                      variant="outlined"
                      className={classes.textField}
                      value={paymentType}
                      onChange={({ target }) => {
                        setPaymentType(target.value);
                      }}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                    >
                      <MenuItem value={0}>
                        Seleccione una opción
                      </MenuItem>
                      <MenuItem value={1}>
                        Una sola excibición
                      </MenuItem>
                      <MenuItem value={2}>Pago a meses</MenuItem>
                    </TextField>
                  </Grid>
                </ShouldItRender>
                {handleRenderPlacements()}
              </Grid>
              <Grid
                container
                direction="row"
                justify="space-between"
                className={classes.subTotals}
              >
                <Grid>
                  <Typography variant="body2">Comisión</Typography>
                  <Typography variant="h6">
                    {formatNumber(
                      comissionWithOutIva + surchargeWithOutIva,
                    )}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography variant="body2">IVA</Typography>
                  <Typography variant="h6">
                    {formatNumber(iva)}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography variant="body2">
                    Comisión Total ({getBaseComission() * 100}%)
                  </Typography>
                  <Typography variant="h6">
                    {formatNumber(
                      iva + surchargeWithOutIva + comissionWithOutIva,
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justify="space-between"
                className={classes.totals}
              >
                <Grid>
                  <Typography variant="body2">
                    Total a pagar
                  </Typography>
                  <Typography variant="h4">
                    {formatNumber(totalToBeCharged)}
                  </Typography>
                </Grid>
                {paymentType === 2 && (
                  <Grid>
                    <Typography variant="body2">
                      Pago mensual
                    </Typography>
                    <Typography variant="h4">
                      {formatNumber(totalToBeCharged / placement)}
                    </Typography>
                  </Grid>
                )}
                <Grid>
                  <Typography variant="body2">
                    Depósito total a tu cuenta
                  </Typography>
                  <Typography variant="h4">
                    {formatNumber(totalToDeposit)}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </form>
        </Paper>
      </Col>
    </Row>
  );
}

const mapStateToProps = (state) => ({
  agencyDetails: makeSelectAgencyDetailsFromState(state),
  role: makeSelectUserRoleFromState(state),
});

const mapDispatchToProps = {
  getAgency,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PromotionsCalculator);
