export const superAdminRoles = [
  'Super Admin',
  'Super Admin Lite',
  'Super Admin Agencies',
];

export const adminRoles = [
  'Agency Admin',
  'W32 Agency Admin',
  'ABG Agency Admin',
  'Clear Admin',
  'Credit Administrator',
];

export const asesorRoles = [
  'Service Asesor',
  'ABG Asesor',
  'W32 Asesor',
];

export const salesAdvisorRoles = [
  'f&i',
  'Sales Advisor',
  'ABG Sales Advisor',
  'ABG Asesor PreOwned',
];

export const partsAsesorRoles = [
  'Parts Asesor',
  'ABG Parts Asesor',
  'W32 Parts Asesor',
];

export const partsManagerRoles = ['Parts Manager'];

export const accountingRoles = [
  'Accounting',
  'ABG Accounting',
  'W32 Accounting',
];
