import { createSelector } from 'reselect';
import { initialState } from './initialState';

export const selectChargebacks = (state) =>
  state.chargebacks || initialState;

export const makeSelectChargebacks = createSelector(
  selectChargebacks,
  (chargebacksState) => chargebacksState.fromdb,
);
