import { FormattedMessage, injectIntl } from 'react-intl';

import { Alert } from 'react-bootstrap';
import Input from 'app/components/Input';
import { Link } from 'react-router-dom';
import React from 'react';
import { closeModals } from 'app/features/modals/modalsSlice';
import { connect } from 'react-redux';
import { requestPasswordReset } from '../../authSlice';
import { requestPasswordResetSchema } from './schema';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

function ForgotPassword(props) {
  const { history } = props;

  const { handleSubmit, register, errors } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(requestPasswordResetSchema),
  });

  const handleCloseModals = () => {
    props.closeModals();
  };

  const renderError = () => {
    try {
      const { errorModal } = props.auth;
      if (errorModal && 'show' in errorModal && errorModal.show) {
        const { show, message } = props.auth['errorModal'];
        return (
          <Alert
            show={show}
            variant="danger"
            onClose={handleCloseModals}
            dismissible
          >
            <Alert.Heading>{message.desc}</Alert.Heading>
          </Alert>
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  const dismissAlert = () => {
    window.setTimeout(() => {
      props.closeModals();
    }, 7000);
  };

  const handleRequestPasswordReset = (formData) => {
    const data = {
      email: formData.email,
      history,
    };
    props.requestPasswordReset(data);
    dismissAlert();
  };

  return (
    <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h4>
              <FormattedMessage id="AUTH.FORGOT.TITLE" />
            </h4>
            <h6>
              <FormattedMessage id="AUTH.FORGOT.DESC" />
            </h6>
          </div>
          <form
            onSubmit={handleSubmit(handleRequestPasswordReset)}
            className="kt-form"
            noValidate
          >
            {renderError()}
            <div className="form-group">
              <Input
                required
                ref={register({
                  required: true,
                })}
                type="email"
                label="Correo electrónico"
                margin="normal"
                name="email"
                error={!!errors.email}
                helperText={errors?.email?.message}
              />
            </div>
            <div className="kt-login__actions">
              <Link to="/auth">
                <button
                  type="button"
                  onClick={handleCloseModals}
                  className="btn btn-secondary btn-elevate kt-login__btn-secondary"
                >
                  Volver
                </button>
              </Link>
              <button
                type="submit"
                className="btn btn-primary btn-elevate kt-login__btn-primary"
              >
                Enviar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {
  requestPasswordReset: requestPasswordReset,
  closeModals: closeModals,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPassword),
);
