import { closeModals } from '../modals/modalsSlice';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { logout } from '../auth/authSlice';

const karloVehiclesSlice = createSlice({
  name: 'karloVehicles',
  initialState,
  reducers: {
    getKarloVehicles: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getKarloVehiclesSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.fromdb = action.payload.karloVehicles;
        state.tablePages = action.payload.karloVehicles.pages;
      },
    },
    getKarloVehiclesFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    getVehicleDetail: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getVehicleDetailSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.vehicleDetails = action.payload.vehicleDetails;
      },
    },
    getVehicleDetailFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
  },
  extraReducers: {
    [logout]: (state, action) => {
      return initialState;
    },
  },
});

export const {
  getKarloVehicles,
  getKarloVehiclesSuccess,
  getKarloVehiclesFail,
  getVehicleDetail,
  getVehicleDetailSuccess,
  getVehicleDetailFail,
} = karloVehiclesSlice.actions;

export default karloVehiclesSlice.reducer;
