import { call, put } from 'redux-saga/effects';
import {
  getKarloAdvicesSuccess,
  getKarloAdvicesFail,
  createTipSuccess,
  createTipFail,
  deleteTipSuccess,
  deleteTipFail,
  uploadThumbnailSuccess,
  uploadThumbnailFail,
  uploadDescriptionSuccess,
  uploadDescriptionFail,
  updateTipSuccess,
  updateTipFail,
  getAdviceDetailSuccess,
  getAdviceDetailFail,
  handleCloseTipsModalsSuccess,
} from 'app/features/karloAdvices/karloAdvicesSlice.js';

import { errors } from './errors.js';

import { requestHandler } from '../../services/requestHandler';
import moment from 'moment';

export function* getKarloAdvicesSaga(action) {
  try {
    let page = 0;
    let pageSize = 20;
    let agencyId = '';
    let filtered;

    if (action) {
      page = action.payload.page;
      pageSize = action.payload.pageSize;
      filtered = action.payload.filtered;
    }
    const _renderPage = pageSize * page;
    const data = {
      offset: _renderPage,
      limit: pageSize,
      field: 'id',
      ordering: 'ASC',
    };
    const karloAdvices = yield call(requestHandler, {
      method: 'POST',
      path: '/karlo-app/getTips',
      data,
    });
    karloAdvices.pages = yield call(
      Math.ceil,
      karloAdvices.tips.length / pageSize,
    );
    yield put(getKarloAdvicesSuccess({ karloAdvices }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba encontrar la información.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(getKarloAdvicesFail({ message }));
  }
}

export function* createTipSaga(action) {
  const {
    title,
    subtitle,
    description,
    videoUrl,
    descriptionImg,
    thumbnailImg,
    createdByUserId,
  } = action.payload;
  console.log('thumbnailImg ', thumbnailImg);
  console.log('descriptionImg ', descriptionImg);
  try {
    const data = {
      title: title,
      subtitle: subtitle,
      description: description,
      videoUrl: videoUrl,
      createdByUserId: createdByUserId,
    };
    console.log('data ', data);
    const tip = yield call(requestHandler, {
      method: 'POST',
      path: '/karlo-app/createTip',
      data,
    });
    const tipId = tip.tipId;
    if (tip.tipId) {
      try {
        console.log(tipId);
        const formData = new FormData();
        formData.append(`file0`, thumbnailImg);
        formData.append('tipId', tipId);
        // for (var pair of data.entries()) {
        //   console.log(pair[0] + ', ' + pair[1]);
        // }
        const tipThumbnail = yield call(requestHandler, {
          method: 'POST',
          path: '/karlo-app/uploadTipThumbnailImage',
          contenType: 'multipart/form-data',
          data: formData,
        });
        console.log('tipThumbnail ', tipThumbnail);
        if (tipThumbnail.code == '200') {
          try {
            console.log(tipId);
            const formDataD = new FormData();
            formDataD.append(`file1`, descriptionImg);
            formDataD.append('tipId', tipId);
            // for (var pair of data.entries()) {
            //   console.log(pair[0] + ', ' + pair[1]);
            // }
            const tipDescription = yield call(requestHandler, {
              method: 'POST',
              path: '/karlo-app/uploadTipDescriptionImage',
              contenType: 'multipart/form-data',
              data: formDataD,
            });
            console.log('tipDescription ', tipDescription);
            const redirect = '/advices';
            const message = {
              title: '¡Exito!',
              desc: `El consejo ha sido creado con exito.`,
            };
            yield put(
              createTipSuccess({
                message,
                redirect,
              }),
            );
          } catch (e) {
            console.log('e ', e);
            let message = {
              title: 'Error',
              desc: `Ocurrió un error cuando se intentaba crear el consejo.
              Intente de nuevo, si el error persiste intente más tarde.`,
            };
            yield put(createTipFail({ message }));
          }
        }
      } catch (e) {
        let message = {
          title: 'Error',
          desc: `Ocurrió un error cuando se intentaba crear el consejo.
          Intente de nuevo, si el error persiste intente más tarde.`,
        };
        yield put(createTipFail({ message }));
        console.log('e ', e);
      }
    }
  } catch (e) {
    let message = '';
    if (e.code in errors)
      message = {
        title: 'Error',
        desc: errors[e.code],
      };
    else
      message = {
        title: 'Error',
        desc: `Ocurrió un error cuando se intentaba crear el consejo.
        Intente de nuevo, si el error persiste intente más tarde.`,
      };
    yield put(createTipFail({ message }));
    console.log('error in create tip saga', e);
  }
}

export function* uploadThumbnailSaga(action) {
  const { id, file } = action.payload;
  // console.log(id, ' image ', file);
  try {
    const formData = new FormData();
    formData.append(`file0`, file);
    formData.append(`tipId`, id);
    const url = yield call(requestHandler, {
      method: 'POST',
      path: '/karlo-app/uploadTipThumbnailImage',
      contenType: 'multipart/form-data',
      data: formData,
    });
    // console.log('response in upload thumbnail saga', url);
    const message = {
      title: 'Exito',
      desc: `Se ha subido con exito la imagen del thumbnail.`,
    };
    yield put(uploadThumbnailSuccess({ message }));
    yield call(getAdviceDetailSaga, {
      payload: { id: action.payload.id },
    });
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se trataba subir el thumbnail.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    console.log('error in upload logo saga', e);
    yield put(uploadThumbnailFail({ message }));
  }
}

export function* uploadDescriptionSaga(action) {
  const { id, file } = action.payload;
  console.log(id, ' image ', file);
  try {
    const formData = new FormData();
    formData.append(`file0`, file);
    formData.append(`tipId`, id);
    const url = yield call(requestHandler, {
      method: 'POST',
      path: '/karlo-app/uploadTipDescriptionImage',
      contenType: 'multipart/form-data',
      data: formData,
    });
    const message = {
      title: 'Exito',
      desc: `Se ha subido con exito la imagen de la descripción.`,
    };
    console.log('response in upload description saga', url);
    yield put(uploadDescriptionSuccess({ message }));
    yield call(getAdviceDetailSaga, {
      payload: { id: action.payload.id },
    });
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se trataba subir el logo.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(uploadDescriptionFail({ message }));
    console.log('error in upload logo saga', e);
  }
}

export function* updateTipSaga(action) {
  const {
    title,
    subtitle,
    description,
    videoUrl,
    id,
    createdByUserId,
  } = action.payload;
  try {
    const data = {
      id: id,
      title: title,
      subtitle: subtitle,
      description: description,
      videoUrl: videoUrl.length != 0 ? videoUrl : null,
      createdByUserId: createdByUserId,
    };
    const url = yield call(requestHandler, {
      method: 'POST',
      path: '/karlo-app/updateTip',
      data: data,
    });
    const message = {
      title: 'Exito',
      desc: `El consejo se ha editado exitosamente.`,
    };
    yield put(updateTipSuccess({ message }));
    yield call(getAdviceDetailSaga, {
      payload: { id: action.payload.id },
    });
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se trataba subir el logo.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(updateTipFail({ message }));
  }
}

export function* deleteTipSaga(action) {
  try {
    const { tipId } = action.payload;
    const data = {
      id: tipId,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/karlo-app/deleteTip',
      data,
    });
    const message = {
      title: 'Eliminado',
      desc: `El consejo se eliminó correctamente`,
    };
    yield put(deleteTipSuccess({ message }));
    yield call(getKarloAdvicesSaga);
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Error al tratar de eliminar el consejo, por favor intenta más tarde`,
    };
    yield put(deleteTipFail({ message }));
    console.log('error', e);
  }
}

export function* getAdviceDetailSaga(action) {
  try {
    const data = {
      id: action.payload.id,
    };
    console.log('data id ', data);
    const details = yield call(requestHandler, {
      method: 'POST',
      path: '/karlo-app/getTipById',
      data,
    });
    console.log('details ', details);
    yield put(getAdviceDetailSuccess({ details }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba encontrar la información del consejo.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(getAdviceDetailFail({ message }));
  }
}

export function* closeModalsTipsSaga(action) {
  try {
    yield put(handleCloseTipsModalsSuccess({}));
  } catch (error) {
    console.log('error in close modals correctly in saga', error);
  }
}
