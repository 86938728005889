import { createSelector } from 'reselect';
import { initialState } from './initialState';

export const selectDepositsFiserv = (state) =>
  state.depositsFiserv || initialState;

export const makeSelectDepositsFromdbFromState = createSelector(
  selectDepositsFiserv,
  (depositsFiservState) => depositsFiservState.fromdb,
);

export const makeSelectDepositsIsLoading = createSelector(
  selectDepositsFiserv,
  (depositsFiservState) => depositsFiservState.isLoading,
);

export const makeSelectDepositDetailsFromState = createSelector(
  selectDepositsFiserv,
  (depositsFiservState) => depositsFiservState.depositDetails,
);

export const makeSelectDepositsSearchedFromState = createSelector(
  selectDepositsFiserv,
  (depositsFiservState) => depositsFiservState.paymentsSearchedFromdb,
);

export const makeSelectDepositsPages = createSelector(
  selectDepositsFiserv,
  (depositsFiservState) => depositsFiservState.tablePagesFromdb,
);
