import PerfectScrollbar from 'react-perfect-scrollbar';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  title: {
    margin: 0,
    padding: 0,
    fontSize: '1.2rem',
    fontWeight: 500,
    color: '#1c1c1c',
  },
}));

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export default function ActiviesProgram(props) {
  const { logs } = props;

  const classes = useStyles();
  const handleRenderType = (type) => {
    const types = {
      ods: 'kt-font-success',
      serviceAppt: 'kt-font-info',
    };
    return types[type];
  };

  const handleRenderLogs = () => {
    const verifiedLogs = logs || [];
    return verifiedLogs.map((action, index) => {
      return (
        <div key={`action-${index}`} className="kt-timeline-v2__item">
          <span className="kt-timeline-v2__item-time">
            {moment(action.date).format('DD/MM/YY HH:mm')}
          </span>
          <div className="kt-timeline-v2__item-cricle">
            <i
              className={`fa fa-genderless ${handleRenderType(
                action.type,
              )}`}
            />
          </div>
          <div className="kt-timeline-v2__item-text kt-padding-top-5">
            {action.text}
          </div>
          <div className="kt-list-pics kt-list-pics--sm kt-padding-l-20" />
        </div>
      );
    });
  };

  return (
    <>
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className={classes.title}>Registro de actividad</h3>
          </div>
        </div>
        <div className="kt-portlet__body">
          {/* style="max-height: 50vh;" */}
          <PerfectScrollbar
            options={perfectScrollbarOptions}
            style={{ maxHeight: '40vh', position: 'relative' }}
          >
            <div className="kt-timeline-v2 ps ps--active-y">
              <div className="kt-timeline-v2__items kt-padding-top-25 kt-padding-bottom-30">
                {handleRenderLogs()}
              </div>
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    </>
  );
}
