import {
  formatDate,
  formatNumber,
  getColumnWidth,
  getNested,
} from 'app/utils';

import { Link } from 'react-router-dom';
import MUIDatePickerFilter from 'app/components/MUIDatePickerFilter';
/* eslint-disable react/display-name */
import { Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { debounce } from 'lodash';
import moment from 'moment';

export const DepositsSPEI = (props) => {
  const {
    deposits,
    depositsFromdb,
    getAllDeposits,
    reactTableRef,
    firstDate,
    secondDate,
  } = props;
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sorted, setSorted] = useState([]);
  const [filtered, setFiltered] = useState('');

  useEffect(() => {
    if ((firstDate && secondDate) || (!firstDate && !secondDate)) {
      handleFetchData();
    }
  }, [firstDate, secondDate]);

  const handleFetchData = debounce(
    (state, instance) => {
      if (state) {
        const { page, pageSize, sorted, filtered } = state;
        getAllDeposits({
          page,
          pageSize,
          sorted,
          filtered,
          firstDate,
          secondDate,
        });
        setPage(page);
        setPageSize(pageSize);
        setSorted(sorted);
        setFiltered(filtered);
      } else {
        getAllDeposits({
          page,
          pageSize,
          sorted,
          filtered,
          firstDate,
          secondDate,
        });
      }
    },
    1000,
    { leading: false, trailing: true },
  );

  const handleTable = (row) => {
    return `/detail-deposit-spei/${row.original.id}`;
  };

  const colums = [
    {
      Header: () => <strong>Fecha de depósito</strong>,
      accessor: 'depositTime',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value &&
              moment(row.value).utc().format('DD/MM/YYYY')}
          </div>
        </Link>
      ),
      Filter: (cell) => {
        return <MUIDatePickerFilter cell={cell} />;
      },
      width: getColumnWidth(
        getNested([], depositsFromdb),
        'date',
        'Fecha de pago     ',
      ),
    },
    {
      Header: () => <strong>Total</strong>,
      accessor: 'totalAmount',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value && formatNumber(row.value)}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>Agencia</strong>,
      accessor: 'agencyName',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      width: 250,
    },
  ];

  return (
    <Paper>
      <ReactTable
        ref={reactTableRef}
        NoDataComponent={() => (
          <div className="rt-noData">Sin resultados</div>
        )}
        filterable
        manual
        onFetchData={handleFetchData}
        data={getNested([], depositsFromdb)}
        pages={deposits.tablePagesFromdb}
        columns={colums}
        className="-striped -highlight"
        previousText="Anterior"
        nextText="Siguiente"
        loading={deposits.isLoading}
        loadingText="Cargando datos..."
      />
    </Paper>
  );
};
