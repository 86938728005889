import { createSelector } from 'reselect';
import { initialState } from './initialState';

export const selectKarloAdvices = (state) =>
  state.karloAdvices || initialState;

export const makeSelectKarloAdvices = createSelector(
  selectKarloAdvices,
  (karloAdvicesState) => karloAdvicesState,
);

export const makeSelectAdviceDetails = createSelector(
  selectKarloAdvices,
  (karloAdvicesState) => karloAdvicesState.adviceDetail,
);

export const makeSelectKarloAdvicesIsLoading = createSelector(
  selectKarloAdvices,
  (karloAdvicesState) => karloAdvicesState.isLoading,
);
