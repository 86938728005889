import React from 'react';

export default function OrdersWidget(props) {
  const { data } = props;
  return (
    <div className="kt-widget1">
      {Object.keys(data).map((key) => (
        <div
          key={`${data[key].name}`}
          className="kt-widget1__item ng-star-inserted"
        >
          <div className="kt-widget1__info">
            <h3 className="kt-widget1__title">{data[key].name}</h3>
            <span className="kt-widget1__desc">{data[key].desc}</span>
          </div>

          <span className="kt-widget1__number">
            {data[key].count}
          </span>
        </div>
      ))}
    </div>
  );
}
