import { call, put, select } from 'redux-saga/effects';
import {
  getCalculatorDataSuccess,
  getCalculatorDataFail,
  getFetchCalculatorDataSuccess,
  getFetchCalculatorDataFail,
  getOrdersTypeSuccess,
  getOrdersTypeFail,
} from './calculator2023Slice';
import { requestHandler } from '../../../../services/requestHandler';

export function* getCalculatorSaga(action) {
  try {
    const businessId = action.payload;
    const calculatorData = yield call(requestHandler, {
      method: 'POST',
      path: `/settingAmountsPerAgencies/getAll`,
      data: {
        agencyID: businessId,
      },
    });
    yield put(
      getCalculatorDataSuccess(
        calculatorData?.settingAmountsPerAgency,
      ),
    );
  } catch (e) {
    yield put(getCalculatorDataFail('getPaymentSaga'));
    console.log('error in get payment saga', e);
  }
}

export function* fetchCalculatorResponse(action) {
  try {
    const baseUrl =
      process.env.REACT_APP_ENV === 'development'
        ? 'http://localhost:8000/api/web'
        : process.env.REACT_APP_ENV === 'staging'
        ? 'https://backend.karlo.io/api/web'
        : process.env.REACT_APP_ENV === 'production'
        ? 'https://backend-production.karlo.io/api/web'
        : 'http://localhost:8000/api/web';
    const calculatorData = yield call(requestHandler, {
      method: 'POST',
      path: `/payment/pre-calculated-payment`,
      baseUrl,
      data: action.payload,
    });
    yield put(getFetchCalculatorDataSuccess(calculatorData));
  } catch (e) {
    yield put(getFetchCalculatorDataFail('getPaymentSaga'));
    console.log('error in get payment saga', e);
  }
}

export function* getOrdersTypeByAgencySaga(action){

  try {
    const data = action.payload;
    const calculatorData = yield call(requestHandler, {
      method: 'POST',
      path: '/typeOrdersByAgency/get-by-agency',
      data,
    });
    yield put(getOrdersTypeSuccess(calculatorData));
  } catch (e) {
    yield put(getOrdersTypeFail(e));
    console.log('error in get payment saga', e);
  }
}

export function* resetOrderTypeSaga(action){
  yield put(getOrdersTypeSuccess([]));
}
