import { closeModals } from '../modals/modalsSlice';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { logout } from '../auth/authSlice';

const karloOffersSlice = createSlice({
  name: 'karloOffers',
  initialState,
  reducers: {
    getKarloOffers: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getKarloOffersSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.fromdb = action.payload.karloOffers.carDeals;
        state.tablePages = action.payload.karloOffers.pages;
      },
    },
    getKarloOffersFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    getOfferVehicles: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getOfferVehiclesSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.offerdb = action.payload.offerVehicles;
        state.tableCarsPages = action.payload.offerVehicles.pages;
      },
    },
    getOfferVehiclesFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    createOffer: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    createOfferSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          ...action.payload,
        };
      },
    },
    createOfferFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    getOfferDetail: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getOfferDetailSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.offerDetails = action.payload.offerDetails.carDeal;
      },
    },
    getOfferDetailFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    handleCloseOfferModals: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    handleCloseOfferModalsSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = initialState.successModal;
        state.errorModal = initialState.errorModal;
      },
    },
  },
  extraReducers: {
    [logout]: (state, action) => {
      return initialState;
    },
  },
});

export const {
  getKarloOffers,
  getKarloOffersSuccess,
  getKarloOffersFail,
  getOfferVehicles,
  getOfferVehiclesSuccess,
  getOfferVehiclesFail,
  createOffer,
  createOfferSuccess,
  createOfferFail,
  getOfferDetail,
  getOfferDetailSuccess,
  getOfferDetailFail,
  handleCloseOfferModals,
  handleCloseOfferModalsSuccess,
} = karloOffersSlice.actions;

export default karloOffersSlice.reducer;
