import { Badge, Table, Alert } from 'react-bootstrap';
import {
  Button,
  DialogTitle,
  Grid,
  Paper,
  CircularProgress,
  Backdrop,
  createMuiTheme,
  TextField,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
  getAppointmentDetail,
  confirmAppointment,
  rejectAppointment,
  handleCloseAppointmentModals,
} from 'app/features/serviceAppointments/serviceAppointmentsSlice';
import { makeSelectServiceAppointment } from 'app/features/serviceAppointments/selectors';
import {
  formatNumber,
  formatSingleDate,
  formatHour,
  getAppointmentColor,
  getAppointmentName,
} from '../../../../utils';

import SplashScreen from '../../../../partials/layout/SplashScreen';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import Modal from '../../../../components/Modal';

import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { selectServiceAppointments } from 'app/features/serviceAppointments/selectors';
import clsx from 'clsx';
import { withRouter } from 'react-router';
import { useForm } from 'react-hook-form';

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '1em',
        color: 'lightblue',
        backgroundColor: 'green',
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  paperTarjeta: {
    textAlign: 'left',
    marginTop: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  divider: {
    width: '100%',
  },
  title: {
    textAlign: 'left',
    color: '#1c1c1c',
  },
  content: {
    textAlign: 'left',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    margin: theme.spacing(1),
    width: '95%',
  },
  menu: {
    width: 200,
  },
  margin: {
    margin: theme.spacing(1),
  },
  list: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    // backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  bigContainer: {
    display: 'flex',
  },
  leftContainer: {
    flexDirection: 'column',
  },
  rightContainer: {
    flexDirection: 'column',
  },
  item: {
    textAlign: 'left',
    justifyContent: 'space-between',
  },
  paperOrden: {
    width: '100%',
    textAlign: 'left',
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
  },
  paperCliente: {
    width: '100%',
    textAlign: 'left',
    marginTop: theme.spacing(2),
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
  },
  paperOps: {
    width: '100%',
    margin: theme.spacing(2, 0),
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
  },
  paperUrl: {
    width: '100%',
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
  },
  itemBlock: {
    display: 'flex',
  },
  item1: {
    textAlign: 'left',
  },
  item2: {
    textAlign: 'center',
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  tableTitle: {
    fontWeight: 401,
  },
  tables: {
    width: '100%',
  },
  media: {
    height: 60,
    width: '100%',
    position: 'relative',
  },
  feedBackStatus: {
    width: '50px',
    height: 'auto',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '30px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.5) !important',
  },
  inputs: {
    marginTop: theme.spacing(1),
  },
  select: {
    margin: theme.spacing(1, 0, 1, 0),
  },
  yellowWarning: {
    backgroundColor: '#ffb82270',
    border: '1px solid #ffb82270',
    color: '#111111',
  },
  iconImportant: {
    display: 'inline',
  },
}));

function DetailAppointment(props) {
  const classes = useStyles();
  const {
    serviceAppointmentDetail,
    setActionButton,
    confirmAppointment,
    rejectAppointment,
  } = props;
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');

  const {
    handleSubmit,
    register,
    errors,
    control,
    reset,
  } = useForm();

  function handleCloseModals() {
    props.handleCloseAppointmentModals();
  }

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = props.appointment;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message } = props.appointment[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={handleCloseModals}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={handleCloseModals}
          />
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  useEffect(() => {
    props.setLeftSideComponent(
      <div className="kt-subheader__main">
        <div>
          <h3 className="kt-subheader__title">
            Detalle cita de servicio
          </h3>
        </div>
      </div>,
    );
    let data = {
      karloID: props.match.params.id,
    };
    props.getAppointmentDetail(data);
  }, []);

  React.useEffect(() => {
    serviceAppointmentDetail.status == 'pending'
      ? setActionButton(
          <div>
            <Button
              variant="contained"
              className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
              onClick={() => handleOpenConfirm()}
            >
              Confirmar
              <Check className="kt-margin-l-10" />
            </Button>
            <Button
              variant="contained"
              className="btn btn-label-danger btn-bold btn-sm btn-icon-h kt-margin-l-10"
              onClick={() => handleOpenReject()}
            >
              Cancelar
              <Clear className="kt-margin-l-10" />
            </Button>
          </div>,
        )
      : setActionButton(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceAppointmentDetail]);

  const handleCloseConfirm = () => {
    setShowAcceptModal(false);
  };

  const handleOpenConfirm = () => {
    setShowAcceptModal(true);
  };

  const handleCloseReject = () => {
    setShowRejectModal(false);
  };

  const handleOpenReject = () => {
    setShowRejectModal(true);
  };

  const renderAppointmentDetails = () => {
    return (
      <Paper className={classes.paper}>
        <DialogTitle className={classes.title}>
          Resumen de la cita
        </DialogTitle>

        <Table bordered className={classes.tables}>
          <tbody>
            <tr>
              <td className={classes.tableTitle}>ID</td>
              <td>{serviceAppointmentDetail.id}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>TIPO</td>
              <td>{serviceAppointmentDetail.type}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>DESCRIPCIÓN</td>
              <td>{serviceAppointmentDetail.description}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>ESTATUS</td>
              <td>
                <Badge
                  className={classes.badges}
                  variant={getAppointmentColor(
                    serviceAppointmentDetail.status,
                  )}
                >
                  {getAppointmentName(
                    serviceAppointmentDetail.status,
                  )}
                </Badge>
              </td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>FECHA</td>
              <td>
                {formatSingleDate(serviceAppointmentDetail.date)}
              </td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>HORA</td>
              <td>{serviceAppointmentDetail.hour}</td>
            </tr>
          </tbody>
        </Table>
      </Paper>
    );
  };

  const renderAgencyDetails = () => {
    return (
      <Paper className={classes.paper}>
        <DialogTitle className={classes.title}>Agencia</DialogTitle>

        <Table bordered className={classes.tables}>
          <tbody>
            <tr>
              <td className={classes.tableTitle}>ID</td>
              <td>{serviceAppointmentDetail?.agency?.id}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>NOMBRE</td>
              <td>{serviceAppointmentDetail?.agency?.name}</td>
            </tr>
          </tbody>
        </Table>
      </Paper>
    );
  };

  const renderPackageDetails = () => {
    if (serviceAppointmentDetail.packageService) {
      let PackageData = serviceAppointmentDetail.packageService;
      return (
        <Paper className={classes.paper}>
          <DialogTitle className={classes.title}>Paquete</DialogTitle>
          <Table bordered className={classes.tables}>
            <tbody>
              <tr>
                <td className={classes.tableTitle}>NOMBRE</td>
                <td>{PackageData.name}</td>
              </tr>
              <tr>
                <td className={classes.tableTitle}>DESCRIPCIÓN</td>
                <td>{PackageData.description}</td>
              </tr>
              <tr>
                <td className={classes.tableTitle}>PRECIO</td>
                <td>{formatNumber(PackageData.price)}</td>
              </tr>
            </tbody>
          </Table>
        </Paper>
      );
    } else {
      return null;
    }
  };

  const renderCancelAlert = () => {
    return (
      <Modal
        open={showRejectModal}
        closeModal={handleCloseReject}
        dialogTitle={'Cancelar cita'}
        actionButtonText={'Cancelar cita'}
        form={'create-user-form'}
        dialogChildren={
          <form
            id="create-user-form"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(handleReject)}
          >
            <Alert className={classes.yellowWarning}>
              <span className="base-style-info owner-name">
                <i
                  className={clsx(
                    'fas fa-exclamation-circle',
                    classes.iconImportant,
                  )}
                />
                <b>&nbsp;IMPORTANTE</b>&nbsp; Estas seguro de esta
                acción y cancelar la cita?
              </span>
            </Alert>
          </form>
        }
      />
    );
  };

  const handleExtraValidation = (formData) => {
    confirmAppointment({
      ...formData,
      date: date,
      time: time,
      id: serviceAppointmentDetail.id,
      karloID: serviceAppointmentDetail.id,
    });
    setShowAcceptModal(false);
  };

  const handleReject = () => {
    rejectAppointment({
      id: serviceAppointmentDetail.id,
      karloID: serviceAppointmentDetail.id,
    });
    handleCloseReject();
  };

  const renderAddAcceptDialog = () => {
    return (
      <Modal
        open={showAcceptModal}
        closeModal={handleCloseConfirm}
        dialogTitle={'Confirmar cita'}
        actionButtonText={'Confirmar cita'}
        form={'create-user-form'}
        dialogChildren={
          <form
            id="create-user-form"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(handleExtraValidation)}
          >
            <TextField
              fullWidth
              name="date"
              onChange={(e) => setDate(e.target.value)}
              label="Fecha"
              type="date"
              variant="outlined"
              defaultValue={new Date().getDate()}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              fullWidth
              name="time"
              onChange={(e) => setTime(e.target.value)}
              label="Hora"
              type="time"
              variant="outlined"
              defaultValue={new Date().getDate()}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              required
              name="description"
              label="Descripción"
              type="description"
              variant="outlined"
              // onChange={handleCharactersMail}
              inputRef={register({
                required: true,
              })}
              className={classes.textField}
              error={Boolean(errors.description)}
            />
          </form>
        }
      />
    );
  };

  return props.appointment.isLoading ? (
    <SplashScreen />
  ) : (
    <div>
      {renderFeedBackModal()}
      {renderAddAcceptDialog()}
      {renderCancelAlert()}
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          {renderAppointmentDetails()}
          {renderAgencyDetails()}
        </Grid>
        <Grid item xs={12} md={8} className={classes.rightContainer}>
          {renderPackageDetails()}
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  appointment: selectServiceAppointments(state),
  serviceAppointmentDetail: makeSelectServiceAppointment(state),
});

const mapDispatchToProps = {
  handleCloseAppointmentModals,
  getAppointmentDetail,
  confirmAppointment,
  rejectAppointment,
};

export default withRouter(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(DetailAppointment),
  ),
);
