import React, { Fragment, useState, useEffect } from 'react';

import {
  Button,
  TextField,
  Grid,
  Typography,
  makeStyles,
  InputAdornment,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Checkbox,
  Box,
} from '@material-ui/core';

import { bankList } from './bankList';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: theme.spacing(7, 10, 1, 10),
  },
  funtions: {
    margin: '40px 0',
    width: '100%',
  },
  textField: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  submitButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
  formControl: {
    width: '100%',
    margin: theme.spacing(1),
  },
}));

const FillDataSPEI = (props) => {
  const {
    handlepaymentSPEI,
    orderNumber,
    originalDate,
    originalAmount,
  } = props;
  const classes = useStyles();

  const [dataSPEI, setDataSPEI] = useState({
    id: orderNumber,
    amount: originalAmount,
    status: 'DONE',
    paymentMethod: 'complete',
    type: 'SPEI',
    reference: '',
    invoiceNumber: '',
    total: '',
    authNumber: '',
    date: originalDate,
    bank: '',
    nameOrder: '',
    accountNumber: '',
    rfcCurp: '',
    tracking: '',
  });

  const [wasDispersed, setWasDispersed] = useState(false);
  const lockButton = () => {
    const {
      amount,
      authNumber,
      date,
      bank,
      nameOrder,
      accountNumber,
      rfcCurp,
      tracking,
    } = dataSPEI;
    let validat =
      !amount ||
      !authNumber ||
      !date ||
      !bank ||
      !nameOrder ||
      !accountNumber ||
      !rfcCurp ||
      !tracking;
    return validat;
  };

  const handleChange = (event) => {
    const {
      target: { name: targetName, value: targetValue },
    } = event;
    if (targetName === 'amount') {
      const validation = /^[0-9.]+$/;
      if (validation.test(targetValue) || targetValue === '') {
        setDataSPEI({
          ...dataSPEI,
          [targetName]: targetValue.trim(),
        });
      }
    } else {
      if (targetName === 'nameOrder' || targetName === 'bank') {
        setDataSPEI({
          ...dataSPEI,
          [targetName]: targetValue.toUpperCase(),
        });
      } else {
        setDataSPEI({
          ...dataSPEI,
          [targetName]: targetValue.toUpperCase().trim(),
        });
      }
    }
  };

  const banks = () => {
    return (
      <TextField
        select
        label="Banco"
        fullWidth
        value={dataSPEI.bank}
        onChange={handleChange}
        name="bank"
        variant="outlined"
        SelectProps={{
          native: true,
        }}
      >
        {bankList.map((bank) => (
          <option key={bank} value={bank}>
            {bank}
          </option>
        ))}
      </TextField>
    );
  };

  return (
    <>
      <Grid item xs={12} md={12} className={classes.funtions}>
        <Typography variant="h4" gutterBottom>
          Información del pago
        </Typography>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="$ Cantidad"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">$</InputAdornment>
            ),
          }}
          fullWidth
          onChange={handleChange}
          name="amount"
          value={dataSPEI.amount}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="Referencia numerica"
          fullWidth
          onChange={handleChange}
          name="authNumber"
          value={dataSPEI.authNumber}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="Fecha"
          fullWidth
          onChange={handleChange}
          name="date"
          value={dataSPEI.date}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        {banks()}
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="Nombre de ordenante"
          fullWidth
          onChange={handleChange}
          name="nameOrder"
          value={dataSPEI.nameOrder}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="Número de cuenta"
          fullWidth
          onChange={handleChange}
          name="accountNumber"
          value={dataSPEI.accountNumber}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions} style={{ marginBottom: 2 }}>
        <TextField
          id="standard-select-currency-native"
          label="RFC/CURP"
          fullWidth
          onChange={handleChange}
          name="rfcCurp"
          value={dataSPEI.rfcCurp}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions} style={{ marginBottom: 2 }}>
        <TextField
          id="standard-select-currency-native"
          label="Clave de rastreo"
          fullWidth
          onChange={handleChange}
          name="tracking"
          value={dataSPEI.tracking}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <Box display="flex" alignItems="center">
          <Checkbox
            className={classes.checkbox}
            checked={wasDispersed}
            onChange={() => setWasDispersed(!wasDispersed)}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          <Typography>Ya fue dispersado</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={2} className={classes.funtions}>
        <div className={classes.submitButtonWrapper}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handlepaymentSPEI({
              ...dataSPEI,
              wasDispersed,
            })}
            disabled={lockButton()}
          >
            Generar pago
          </Button>
        </div>
      </Grid>
    </>
  );
};

export default FillDataSPEI;
