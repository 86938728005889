/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Divider from '@material-ui/core/Divider';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles({
  primary: {
    fontSize: '1.1em',
    '&:hover': {
      color: '#066dbf',
    },
  },
});

const mostPopular = (props) => {
  const classes = useStyles();
  const { typeRole } = props;

  return (
    <Container>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Artículos más populares
          </Typography>
          <Divider variant="middle" />
        </Grid>

        <Grid item xs={12}>
          <List dense={true}>
            <ListItem component="a" href="/dashboard">
              <ListItemIcon style={{ minWidth: 30 }}>
                <FiberManualRecordIcon style={{ fontSize: 14 }} />
              </ListItemIcon>
              <ListItemText
                classes={{
                  primary: classes.primary,
                }}
                primary="Lorem ipsum dolor sit amet consectetur adipisicing elit."
              />
            </ListItem>
            <ListItem component="a" href="/dashboard">
              <ListItemIcon style={{ minWidth: 30 }}>
                <FiberManualRecordIcon style={{ fontSize: 14 }} />
              </ListItemIcon>
              <ListItemText
                classes={{
                  primary: classes.primary,
                }}
                primary="Quo facere autem quaerat ducimus expedita maxime pariatur quia."
              />
            </ListItem>
            <ListItem component="a" href="/dashboard">
              <ListItemIcon style={{ minWidth: 30 }}>
                <FiberManualRecordIcon style={{ fontSize: 14 }} />
              </ListItemIcon>
              <ListItemText
                classes={{
                  primary: classes.primary,
                }}
                primary="Dolorum qui at aliquid magnam dignissimos quis molestias fugit corporis ipsum, quas sed."
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Container>
  );
};

export default mostPopular;
