import {
  applyExternalPaymentFail,
  applyExternalPaymentSuccess,
  applyextraordinaryPaymentFail,
  applyextraordinaryPaymentSuccess,
  cancelCreditOrderFail,
  cancelCreditOrderSuccess,
  createCreditOrderFail,
  createCreditOrderSuccess,
  getAllCreditOrdersFail,
  getAllCreditOrdersSuccess,
  getAllCreditOrdersToDownloadFail,
  getAllCreditOrdersToDownloadSuccess,
  getCreditOrderFail,
  getCreditOrderSuccess,
  notificateCreditUserFail,
  notificateCreditUserSuccess,
  updateCreditOrderFail,
  updateCreditOrderSuccess,
  uploadExcelFileFail,
  uploadExcelFileSuccess,
  uploadPaymentsFromExcelFileFail,
  uploadPaymentsFromExcelFileSuccess,
} from './creditOrdersSlice';
import { call, put, select } from 'redux-saga/effects';

import { errors } from './errors';
import { formatDatesForFilter } from 'app/utils';
import { getLogs } from 'app/features/logs/logsSlice';
import { isEmpty } from 'lodash';
import { makeSelectUserRole } from '../auth/selectors';
import { requestHandler } from '../../services/requestHandler';

export function* getAllCreditOrdersSaga(action) {
  // const role = yield select(makeSelectUserRole());
  const { firstDate, secondDate, page, pageSize, filtered } =
    action.payload;
  try {
    const data = {
      where: {},
      payments: true,
      options: {
        count: true,
        distinct: true,
      },
      pagination: {
        page,
        perPage: pageSize,
        order: [['updatedAt', 'DESC']],
      },
      agencies: {
        options: {
          attributes: ['id', 'isActiveAgency', 'name'],
        },
      },
      creditOrderNotifications: {
        options: { order: [['updatedAt', 'DESC']] },
      },
    };
    if (firstDate && secondDate)
      data.where.createdAt = formatDatesForFilter(
        firstDate,
        secondDate,
      );
    if (filtered.length) {
      data.where = {};
      filtered.forEach((filter) => {
        data.where[filter.id] = { $iLike: `%${filter.value}%` };
      });
    }
    const creditOrders = yield call(requestHandler, {
      method: 'POST',
      path: '/creditOrder/getAll',
      data,
    });
    creditOrders.pages = yield call(
      Math.ceil,
      creditOrders.count / pageSize,
    );
    yield put(getAllCreditOrdersSuccess({ creditOrders }));
    if (firstDate && secondDate)
      yield call(getAllCreditOrdersToDownloadSaga, {
        data: { ...data },
      });
  } catch (e) {
    yield put(getAllCreditOrdersFail('getUserBySuperAdmin'));
    console.log('error in get user BySuperAdmin saga', e);
  }
}

export function* getAllCreditOrdersToDownloadSaga(action) {
  try {
    const { data } = action;
    delete data.options;
    delete data.pagination;
    delete data.creditOrderNotifications;
    const creditOrders = yield call(requestHandler, {
      method: 'POST',
      path: '/creditOrder/getAll',
      data,
    });
    yield put(getAllCreditOrdersToDownloadSuccess({ creditOrders }));
  } catch (e) {
    yield put(
      getAllCreditOrdersToDownloadFail('getUserBySuperAdmin'),
    );
    console.log('error in get user BySuperAdmin saga', e);
  }
}

export function* getCreditOrderSaga(action) {
  const { orderID } = action.payload;
  try {
    const data = {
      where: { id: orderID },
      payments: true,
    };
    const creditOrder = yield call(requestHandler, {
      method: 'POST',
      path: '/creditOrder/getOne',
      data,
    });
    yield put(getCreditOrderSuccess({ creditOrder }));
    yield put(
      getLogs({
        object: 'creditOrders',
        objectID: orderID,
      }),
    );
  } catch (e) {
    yield put(getCreditOrderFail('getUserBySuperAdmin'));
    console.log('error in get user BySuperAdmin saga', e);
  }
}

export function* createCreditOrderSaga(action) {
  try {
    const data = {
      ...action.payload,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/creditOrder/create',
      data,
    });
    const message = {
      title: '¡Éxito!',
      desc: `La orden de crédito ha sido creada correctamente.`,
    };
    const redirect = '/creditorders';
    yield put(
      createCreditOrderSuccess({
        message,
        redirect,
      }),
    );
  } catch (e) {
    let message = '';
    if (e.code in errors)
      message = {
        title: 'Error',
        desc: errors[e.code],
      };
    else
      message = {
        title: 'Error',
        desc: `Ocurrió un error cuando se intentaba crear la orden de crédito.
        Intente de nuevo, si el error persiste intente más tarde.`,
      };
    yield put(createCreditOrderFail({ message }));
    console.log('error in get order detail user saga', e);
  }
}

export function* updateCreditOrderSaga(action) {
  try {
    const { id, ...rest } = action.payload;
    const dataIsEmpty = yield call(isEmpty, rest);
    if (!dataIsEmpty) {
      const data = {
        ...action.payload,
      };
      yield call(requestHandler, {
        method: 'POST',
        path: '/creditOrder/update',
        data,
      });
    }
    const message = {
      title: '¡Éxito!',
      desc: `La orden de crédito ha sido actualizada correctamente.`,
    };
    yield put(
      updateCreditOrderSuccess({
        message,
      }),
    );
    yield call(getCreditOrderSaga, {
      payload: { orderID: id },
    });
  } catch (e) {
    let message = '';
    if (e.code in errors)
      message = {
        title: 'Error',
        desc: errors[e.code],
      };
    else
      message = {
        title: 'Error',
        desc: `Ocurrió un error cuando se intentaba crear la orden de crédito.
        Intente de nuevo, si el error persiste intente más tarde.`,
      };
    yield put(updateCreditOrderFail({ message }));
    console.log('error in get order detail user saga', e);
  }
}

export function* applyExternalPaymentCreditOrderSaga(action) {
  try {
    const { creditOrderID, ...rest } = action.payload;
    const data = {
      ...rest,
      creditOrderID,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/creditOrder/addExternalPayment',
      data,
    });
    const message = {
      title: '¡Aplicado!',
      desc: `Pago aplicado correctamente`,
    };
    yield put(
      applyExternalPaymentSuccess({
        message,
      }),
    );
    yield call(getCreditOrderSaga, {
      payload: { orderID: creditOrderID },
    });
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba aplicar el pago.
      Intente de nuevo, si el error persiste intente más tarde.`,
    };
    yield put(
      applyExternalPaymentFail({
        message,
      }),
    );
    console.log('error sending receipt payment order: ', e);
  }
}

export function* applyExtraordinaryPaymentCreditOrderSaga(action) {
  try {
    const { creditOrderID, ...rest } = action.payload;
    const data = {
      ...rest,
      creditOrderID,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/creditOrder/pay/oxxo',
      data,
    });
    const message = {
      title: '¡Ficha de pago creada!',
      desc: `La ficha de pago se ha aplicado correctamente`,
    };
    yield put(
      applyextraordinaryPaymentSuccess({
        message,
      }),
    );
    yield call(getCreditOrderSaga, {
      payload: { orderID: creditOrderID },
    });
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba crear la ficha de pago.
      Intente de nuevo, si el error persiste intente más tarde.`,
    };
    yield put(
      applyextraordinaryPaymentFail({
        message,
      }),
    );
    console.log('error sending receipt payment order: ', e);
  }
}

export function* cancelCreditOrderSaga(action) {
  try {
    const role = yield select(makeSelectUserRole());
    const { id, agencyID } = action.payload;
    const data = {
      id,
    };
    if (role === 'Super Admin') data.agencyID = agencyID || undefined;
    yield call(requestHandler, {
      method: 'POST',
      path: '/creditOrder/cancel',
      data,
    });
    yield call(getCreditOrderSaga, {
      payload: { orderID: id },
    });
    const message = {
      title: '¡Cancelada!',
      desc: `La orden de crédito ha sido cancelada correctamente`,
    };
    const redirect = '/creditorders';
    yield put(
      cancelCreditOrderSuccess({
        message,
        redirect,
      }),
    );
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba cancelar la orden de crédito.
      Intente de nuevo, si el error persiste intente más tarde.`,
    };
    yield put(
      cancelCreditOrderFail({
        message,
      }),
    );
    console.log('error canceling credit order: ', e);
  }
}

export function* notificateCreditUserSaga(action) {
  try {
    const { type, ...rest } = action.payload;
    const data = {
      ...rest,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: `/creditOrder/notificate/${type}`,
      data,
    });
    const message = {
      title: 'Eviada!',
      desc: `La notificación ha sido enviada correctamente`,
    };
    yield put(
      notificateCreditUserSuccess({
        message,
      }),
    );
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba enviar la notificación.
      Intente de nuevo, si el error persiste intente más tarde.`,
    };
    yield put(
      notificateCreditUserFail({
        message,
      }),
    );
    console.log('error sending notification: ', e);
  }
}

export function* uploadExcelFileSaga(action) {
  try {
    const { file, handleRefreshData } = action.payload;
    yield call(requestHandler, {
      method: 'POST',
      path: '/creditOrder/importFromExcel',
      contenType: 'multipart/form-data',
      data: file,
    });
    const message = {
      title: '¡Órdenes importadas!',
      desc: `Las órdenes han sido importadas correctamente.`,
    };
    yield put(uploadExcelFileSuccess({ message }));
    yield call(handleRefreshData);
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se trataba importar las órdenes.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(uploadExcelFileFail({ message }));
    console.log('error in upload excel', e);
  }
}

export function* uploadPaymentsFromExcelFileSaga(action) {
  try {
    let message;
    const { file } = action.payload;
    const response = yield call(requestHandler, {
      method: 'POST',
      path: '/creditOrder/importPayments',
      contenType: 'multipart/form-data',
      data: file,
    });

    if (response.notFound.length > 0) {
      message = {
        title: `${
          Object.keys(response.created).length
        } pagos importados, ${response.notFound.length} errores`,
        desc: `Los siguientes pagos no han sido importados debido a que no existe una orden de crédito con los siguientes clientes ID: \n\n\n\n ${response.notFound}`,
      };
      yield put(uploadPaymentsFromExcelFileFail({ message }));
    } else {
      message = {
        title: `${
          Object.keys(response.created).length
        } pagos importados`,
        desc: `Los pagos han sido importados correctamente.`,
      };
      yield put(uploadPaymentsFromExcelFileSuccess({ message }));
    }
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se trataba importar los pagos.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(uploadPaymentsFromExcelFileFail({ message }));
    console.log('error in upload excel', e);
  }
}

export function* closeModalsAndRedirectCreditOrdersSaga(action) {
  if (!action.payload) return;
  const { history, redirect } = action.payload;
  try {
    if (redirect && history) yield call(history.push, redirect);
  } catch (e) {
    console.log('error: ', e);
  }
}
