import { createSelector } from 'reselect';
import { initialState } from './initialState';

export const selectDepositsStandAlone = (state) =>
  state.depositsStandAlone || initialState;

export const makeSelectDepositsFromdbFromState = createSelector(
  selectDepositsStandAlone,
  (depositsStandAloneSatate) => depositsStandAloneSatate.fromdb,
);

export const makeSelectDepositsIsLoading = createSelector(
  selectDepositsStandAlone,
  (depositsStandAloneSatate) => depositsStandAloneSatate.isLoading,
);

export const makeSelectDepositDetailsFromState = createSelector(
  selectDepositsStandAlone,
  (depositsStandAloneSatate) =>
    depositsStandAloneSatate.depositDetails,
);

export const makeSelectDepositsSearchedFromState = createSelector(
  selectDepositsStandAlone,
  (depositsStandAloneSatate) =>
    depositsStandAloneSatate.paymentsSearchedFromdb,
);

export const makeSelectDepositsPages = createSelector(
  selectDepositsStandAlone,
  (depositsStandAloneSatate) =>
    depositsStandAloneSatate.tablePagesFromdb,
);
