import React, { Fragment, useState, useEffect } from 'react';

import {
  Button,
  TextField,
  Grid,
  Typography,
  makeStyles,
  InputAdornment,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: theme.spacing(7, 10, 1, 10),
  },
  funtions: {
    margin: '40px 0',
    width: '100%',
  },
  textField: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  submitButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
  formControl: {
    width: '100%',
    margin: theme.spacing(1),
  },
}));

const FillDataOXXO = (props) => {
  const {
    handlepaymentOXXO,
    orderNumber,
    originalDate,
    originalAmount,
    hasPaymentOXXO,
    paymentOXXOId,
  } = props;
  const classes = useStyles();

  const [dataSPEI, setDataSPEI] = useState({
    total: originalAmount,
    conektaReferenceNumber: '',
    cardHolder: '',
    conektaOxxoOrderID: '',
    hasPaymentOXXO,
    paymentOXXOId,
  });

  const lockButton = () => {
    const {
      total,
      conektaReferenceNumber,
      cardHolder,
      conektaOxxoOrderID,
    } = dataSPEI;
    let validat =
      !total ||
      !conektaReferenceNumber ||
      !cardHolder ||
      !conektaOxxoOrderID;
    return validat;
  };

  const handleChange = (event) => {
    const {
      target: { name: targetName, value: targetValue },
    } = event;
    if (targetName === 'total') {
      const validation = /^[0-9.]+$/;
      if (validation.test(targetValue) || targetValue === '') {
        setDataSPEI({
          ...dataSPEI,
          [targetName]: targetValue.trim(),
        });
      }
    } else {
      if (targetName === 'cardHolder') {
        setDataSPEI({
          ...dataSPEI,
          [targetName]: targetValue,
        });
      } else {
        setDataSPEI({
          ...dataSPEI,
          [targetName]: targetValue.toUpperCase().trim(),
        });
      }
    }
  };

  return (
    <>
      <Grid item xs={12} md={12} className={classes.funtions}>
        <Typography variant="h4" gutterBottom>
          Información del pago
        </Typography>
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="$ Total"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">$</InputAdornment>
            ),
          }}
          fullWidth
          onChange={handleChange}
          name="total"
          value={dataSPEI.total}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="Referencia numerica"
          fullWidth
          onChange={handleChange}
          name="conektaReferenceNumber"
          value={dataSPEI.conektaReferenceNumber}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="Nombre de ordenante"
          fullWidth
          onChange={handleChange}
          name="cardHolder"
          value={dataSPEI.cardHolder}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={5} className={classes.funtions}>
        <TextField
          id="standard-select-currency-native"
          label="Id de orden en Conekta"
          fullWidth
          onChange={handleChange}
          name="conektaOxxoOrderID"
          value={dataSPEI.conektaOxxoOrderID}
          variant="outlined"
          SelectProps={{
            native: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={2} className={classes.funtions}>
        <div className={classes.submitButtonWrapper}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handlepaymentOXXO(dataSPEI)}
            disabled={lockButton()}
          >
            Generar pago
          </Button>
        </div>
      </Grid>
    </>
  );
};

export default FillDataOXXO;
