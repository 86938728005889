import { createSelector } from 'reselect';
import { initialState } from './initialState';

export const selectLogs = (state) => state.logs || initialState;

export const makeSelectServiceOrdersLogs = createSelector(
  selectLogs,
  (logsState) => logsState.serviceOrders,
);

export const makeSelectPaymentOrdersLogs = createSelector(
  selectLogs,
  (logsState) => logsState.paymentOrders,
);

export const makeSelectCreditOrdersLogs = createSelector(
  selectLogs,
  (logsState) => logsState.creditOrders,
);

export const makeSelectPaymentsLogs = createSelector(
  selectLogs,
  (logsState) => logsState.payments,
);

export const makeSelectDepositsLogs = createSelector(
  selectLogs,
  (logsState) => logsState.deposits,
);

export const makeSelectLogsIsLoading = createSelector(
  selectLogs,
  (logsState) => logsState.isLoading,
);
