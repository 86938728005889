import {
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import {
  createTransform,
  persistReducer,
  persistStore,
} from 'redux-persist';

import createSagaMiddleware from 'redux-saga';
import omit from 'lodash/omit';
import root from '../sagas';
import { rootReducer } from './rootDuck';
import storage from 'redux-persist/lib/storage';

const devMode =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

const sagaMiddleware = createSagaMiddleware();

const middleware = [
  ...getDefaultMiddleware({
    thunk: false,
    serializableCheck: false,
    immutableCheck: false,
  }),
  sagaMiddleware,
];

let blacklistTransform = createTransform((inboundState, key) => {
  switch (key) {
    case 'auth':
    case 'users':
    case 'karloUsers':
    case 'agencies':
    case 'serviceAppointments':
    case 'serviceOrders':
    case 'paymentOrders':
    case 'creditOrders':
    case 'payments':
    case 'speiPayments':
    case 'scores':
    case 'notifications':
    case 'dashboard':
    case 'logs':
      inboundState = omit(inboundState, ['isLoading']);
      inboundState = omit(inboundState, ['error']);
      break;
    default:
      break;
  }
  return inboundState;
});

const persistConfig = {
  key: 'root-admin',
  storage,
  transforms: [blacklistTransform],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: devMode,
  middleware,
});

sagaMiddleware.run(root);

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./rootDuck', () => {
    store.replaceReducer(rootReducer);
  });
}

/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
export const persistor = persistStore(store);
