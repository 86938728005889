import React, { useState, useEffect } from 'react';

import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';

import {
  createAgencyEvents,
  updateAgencyEvents,
} from 'app/features/agencies/agenciesSlice';
import { closeModals } from 'app/features/modals/modalsSlice';

import { Controller, useForm } from 'react-hook-form';
import ChipInput from 'material-ui-chip-input';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(1),
  },
  title: {
    margin: theme.spacing(1),
  },
  cardContent: {
    minHeight: theme.spacing(20),
  },
  cardContentContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  chipInput: {
    marginTop: theme.spacing(3),
  },
  submitButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
}));

const Advertisements = ({
  agencyEvents,
  createAgencyEvents,
  updateAgencyEvents,
  getSetuplogs,
  agencyDetails,
}) => {
  const classes = useStyles();
  const [odsAlreadyCreated, setOdsAlreadyCreated] = useState(false);
  const [odpAlreadyCreated, setOdpAlreadyCreated] = useState(false);
  const [apptAlreadyCreated, setApptAlreadyCreated] = useState(false);
  const [dmsNotificationAlreadyCreated, setDmsNotificationAlreadyCreated] = useState(false);
  const [
    invoiceErrorAlreadyCreated,
    setInvoiceErrorAlreadyCreated,
  ] = useState(false);
  const [
    invoiceSuccessAlreadyCreated,
    setInvoiceSuccessAlreadyCreated,
  ] = useState(false);
  const [millionaireLetterAlreadyCreated, setMillionaireLetterAlreadyCreated] = useState(false);
  const [apartAlreadyCreated, setApartAlreadyCreated] = useState(
    false,
  );
  const [errors, setErrors] = useState({
    emailPaymentsODS: false,
    emailPaymentsODP: false,
    emailPaymentsODPApartados: false,
    emailService: false,
    emailInvoiceError: false,
    emailInvoiceSuccess: false,
    emailDMSNotificationError: false,
    emailMillionaireLetter: false,
  });
  const [errorsText, setErrorsText] = useState({
    emailPaymentsODS: '',
    emailPaymentsODP: '',
    emailPaymentsODPApartados: '',
    emailService: '',
    emailInvoiceError: '',
    emailMillionaireLetter: '',
    emailInvoiceSuccess: '',
    emailDMSNotificationError: '',
  });

  const { handleSubmit, control, watch, setValue, reset } = useForm();

  const values = watch();

  useEffect(() => {
    if (Array.isArray(agencyEvents) && agencyEvents.length > 0) {
      let ods = [];
      let odp = [];
      let apart = [];
      let appt = [];
      let emailInvoiceError = [];
      let emailInvoiceSuccess = [];
      let emailDMSNotificationError = [];
      let millionaireLetter = [];

      agencyEvents.forEach((event) => {
        switch(event.type) {
          case "pay:ods":
            ods = [...event.emails];
            setOdsAlreadyCreated(true);
            break;
          case "pay:odp":
            odp = [...event.emails];
            setOdpAlreadyCreated(true);
            break;
          case "pay:apart":
            apart = [...event.emails];
            setApartAlreadyCreated(true);
            break;
          case "service:appt":
            appt = [...event.emails];
            setApptAlreadyCreated(true);
            break;
          case "error:invoice":
            emailInvoiceError = [...event.emails];
            setInvoiceErrorAlreadyCreated(true);
            break;
          case "success:invoice":
            emailInvoiceSuccess = [...event.emails];
            setInvoiceSuccessAlreadyCreated(true);
            break;
          case "error:dmsNotification":
            emailDMSNotificationError = [...event.emails];
            setDmsNotificationAlreadyCreated(true);
            break;
          case "millionaire:letter":
            millionaireLetter = [...event.emails];
            setMillionaireLetterAlreadyCreated(true);
            break;
          default:
            break;
        }
      });

      reset({
        ods,
        odp,
        apart,
        appt,
        emailInvoiceError,
        emailInvoiceSuccess,
        emailDMSNotificationError,
        millionaireLetter,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencyEvents]);

  const handleSendAdvertisements = (formData, type) => {
    if (type === 'ods') {
      let ods = {};
      if (Array.isArray(formData.ods) && formData.ods.length > 0) {
        ods = {
          type: 'pay:ods',
          activated: true,
          emails: formData.ods,
        };
      } else if (
        Array.isArray(formData.ods) &&
        formData.ods.length === 0
      )
        ods = {
          type: 'pay:ods',
          activated: false,
          emails: [],
        };
      if (!odsAlreadyCreated) createAgencyEvents({ ...ods });
      else updateAgencyEvents({ ...ods });
    }

    if (type === 'odp') {
      let odp = {};
      if (Array.isArray(formData.odp) && formData.odp.length > 0) {
        odp = {
          type: 'pay:odp',
          activated: true,
          emails: formData.odp,
        };
      } else if (
        Array.isArray(formData.odp) &&
        formData.odp.length === 0
      )
        odp = {
          type: 'pay:odp',
          activated: false,
          emails: [],
        };

      if (!odpAlreadyCreated) createAgencyEvents({ ...odp });
      else updateAgencyEvents({ ...odp });
    }

    if (type === 'apart') {
      let apart = {};
      if (
        Array.isArray(formData.apart) &&
        formData.apart.length > 0
      ) {
        apart = {
          type: 'pay:apart',
          activated: true,
          emails: formData.apart,
        };
      } else if (
        Array.isArray(formData.apart) &&
        formData.apart.length === 0
      )
        apart = {
          type: 'pay:apart',
          activated: false,
          emails: [],
        };

      if (!apartAlreadyCreated) createAgencyEvents({ ...apart });
      else updateAgencyEvents({ ...apart });
    }

    if (type === 'appt') {
      let appt = {};
      if (Array.isArray(formData.appt) && formData.appt.length > 0) {
        appt = {
          type: 'service:appt',
          activated: true,
          emails: formData.appt,
        };
      } else if (
        Array.isArray(formData.appt) &&
        formData.appt.length === 0
      )
        appt = {
          type: 'service:appt',
          activated: false,
          emails: [],
        };

      if (!apptAlreadyCreated) createAgencyEvents({ ...appt });
      else updateAgencyEvents({ ...appt });
    }

    if (type === 'emailInvoiceError') {
      let emailInvoiceError = {};
      if (
        Array.isArray(formData.emailInvoiceError) &&
        formData.emailInvoiceError.length > 0
      ) {
        emailInvoiceError = {
          type: 'error:invoice',
          activated: true,
          emails: formData.emailInvoiceError,
        };
      } else if (
        Array.isArray(formData.emailInvoiceError) &&
        formData.emailInvoiceError.length === 0
      )
        emailInvoiceError = {
          type: 'error:invoice',
          activated: false,
          emails: [],
        };

      if (!invoiceErrorAlreadyCreated)
        createAgencyEvents({ ...emailInvoiceError });
      else updateAgencyEvents({ ...emailInvoiceError });
    }

    if (type === 'emailInvoiceSuccess') {
      let emailInvoiceSuccess = {};
      if (
        Array.isArray(formData.emailInvoiceSuccess) &&
        formData.emailInvoiceSuccess.length > 0
      ) {
        emailInvoiceSuccess = {
          type: 'success:invoice',
          activated: true,
          emails: formData.emailInvoiceSuccess,
        };
      } else if (
        Array.isArray(formData.emailInvoiceSuccess) &&
        formData.emailInvoiceSuccess.length === 0
      )
      emailInvoiceSuccess = {
          type: 'success:invoice',
          activated: false,
          emails: [],
        };

      if (!invoiceSuccessAlreadyCreated)
        createAgencyEvents({ ...emailInvoiceSuccess });
      else updateAgencyEvents({ ...emailInvoiceSuccess });
    }
    if(type === 'millionaireLetter'){
      let millionaireLetter = {};
      if (
        Array.isArray(formData.millionaireLetter) &&
        formData.millionaireLetter.length > 0
      ) {
        millionaireLetter = {
          type: 'millionaire:letter',
          activated: true,
          emails: formData.millionaireLetter,
        };
      } else if (
        Array.isArray(formData.millionaireLetter) &&
        formData.millionaireLetter.length === 0
      )
      millionaireLetter = {
          type: 'millionaire:letter',
          activated: false,
          emails: [],
        };

      if (!millionaireLetterAlreadyCreated)
        createAgencyEvents({ ...millionaireLetter });
      else updateAgencyEvents({ ...millionaireLetter });
    }

    if (type === "emailDMSNotificationError") {
      let emailDMSNotificationError = {};
      if (
        Array.isArray(formData.emailDMSNotificationError) &&
        formData.emailDMSNotificationError.length > 0
      ) {
        emailDMSNotificationError = {
          type: 'error:dmsNotification',
          activated: true,
          emails: formData.emailDMSNotificationError,
        }
      } else if (
        Array.isArray(formData.emailDMSNotificationError) &&
        formData.emailDMSNotificationError.length === 0
      ) {
        emailDMSNotificationError = {
          type: 'error:dmsNotification',
          activated: false,
          emails: [],
        }
      }
      if (!dmsNotificationAlreadyCreated)
        createAgencyEvents({ ...emailDMSNotificationError });
      else updateAgencyEvents({ ...emailDMSNotificationError });
    }

    const body = {
      type: 'UPDATEADVERTISEMENTS',
      formData,
    };
    getSetuplogs(body);
  };
  
  const handleAddEmail = (emailsArray, email, type) => {
    const newEmails = [...emailsArray];
      newEmails.push(email);
      errors[type] = false;
      return newEmails;
  };

  const handleDeleteEmail = (emailsArray, index) => {
    const newEmails = [...emailsArray];
    newEmails.splice(index, 1);
    return newEmails;
  };

  const renderCommissionsByCards = () => (
    <>
      <Grid item xs={12} md={6}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography align="center" variant="h5">
              Pago de ODS
            </Typography>
            <form
              onSubmit={handleSubmit((data) =>
                handleSendAdvertisements(data, 'ods'),
              )}
              noValidate
            >
              <Controller
                as={
                  <ChipInput
                    fullWidth
                    className={classes.chipInput}
                    error={errors.emailPaymentsODS}
                    label="Destinatarios"
                    placeholder="adolfo@mail.com, rodrigo@mail.com"
                    value={values.ods}
                    variant="outlined"
                    newChipKeyCodes={[13, 32, 188]}
                    onAdd={(email) => {
                      setValue(
                        'ods',
                        handleAddEmail(
                          values.ods,
                          email,
                          'emailPaymentsODS',
                        ),
                      );
                    }}
                    onDelete={(_, index) => {
                      setValue(
                        'ods',
                        handleDeleteEmail(values.ods, index),
                      );
                    }}
                  />
                }
                control={control}
                name="ods"
              />
              {errors.emailPaymentsODS && (
                <Typography style={{ color: 'red' }}>
                  {'Error el correo *' +
                    errorsText.emailPaymentsODS +
                    '* no es valido'}
                </Typography>
              )}
              <div className={classes.submitButtonContainer}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Actualizar
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography align="center" variant="h5">
              Pago de ODP
            </Typography>
            <form
              onSubmit={handleSubmit((data) =>
                handleSendAdvertisements(data, 'odp'),
              )}
              noValidate
            >
              <Controller
                as={
                  <ChipInput
                    fullWidth
                    className={classes.chipInput}
                    error={errors.emailPaymentsODP}
                    label="Destinatarios"
                    placeholder="adolfo@mail.com, rodrigo@mail.com"
                    value={values.odp}
                    variant="outlined"
                    newChipKeyCodes={[13, 32, 188]}
                    onAdd={(email) => {
                      setValue(
                        'odp',
                        handleAddEmail(
                          values.odp,
                          email,
                          'emailPaymentsODP',
                        ),
                      );
                    }}
                    onDelete={(_, index) => {
                      setValue(
                        'odp',
                        handleDeleteEmail(values.odp, index),
                      );
                    }}
                  />
                }
                control={control}
                name="odp"
              />
              {errors.emailPaymentsODP && (
                <Typography style={{ color: 'red' }}>
                  {'Error el correo *' +
                    errorsText.emailPaymentsODP +
                    '* no es valido'}
                </Typography>
              )}
              <div className={classes.submitButtonContainer}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Actualizar
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography align="center" variant="h5">
              Pago de ODP APARTADOS
            </Typography>
            <form
              onSubmit={handleSubmit((data) =>
                handleSendAdvertisements(data, 'apart'),
              )}
              noValidate
            >
              <Controller
                as={
                  <ChipInput
                    fullWidth
                    className={classes.chipInput}
                    error={errors.emailPaymentsODPApartados}
                    label="Destinatarios"
                    placeholder="adolfo@mail.com, rodrigo@mail.com"
                    value={values.apart}
                    variant="outlined"
                    newChipKeyCodes={[13, 32, 188]}
                    onAdd={(email) => {
                      setValue(
                        'apart',
                        handleAddEmail(
                          values.apart,
                          email,
                          'emailPaymentsODPApartados',
                        ),
                      );
                    }}
                    onDelete={(_, index) => {
                      setValue(
                        'apart',
                        handleDeleteEmail(values.apart, index),
                      );
                    }}
                  />
                }
                control={control}
                name="apart"
              />
              {errors.emailPaymentsODPApartados && (
                <Typography style={{ color: 'red' }}>
                  {'Error el correo *' +
                    errorsText.emailPaymentsODPApartados +
                    '* no es valido'}
                </Typography>
              )}
              <div className={classes.submitButtonContainer}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Actualizar
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography align="center" variant="h5">
              Citas de Servicio
            </Typography>
            <form
              onSubmit={handleSubmit((data) =>
                handleSendAdvertisements(data, 'appt'),
              )}
              noValidate
            >
              <Controller
                as={
                  <ChipInput
                    fullWidth
                    className={classes.chipInput}
                    error={errors.emailService}
                    label="Destinatarios"
                    placeholder="adolfo@mail.com, rodrigo@mail.com"
                    value={values.appt}
                    variant="outlined"
                    newChipKeyCodes={[13, 32, 188]}
                    onAdd={(email) => {
                      setValue(
                        'appt',
                        handleAddEmail(
                          values.appt,
                          email,
                          'emailService',
                        ),
                      );
                    }}
                    onDelete={(_, index) => {
                      setValue(
                        'appt',
                        handleDeleteEmail(values.appt, index),
                      );
                    }}
                  />
                }
                control={control}
                name="appt"
              />
              {errors.emailService && (
                <Typography style={{ color: 'red' }}>
                  {'Error el correo *' +
                    errorsText.emailService +
                    '* no es valido'}
                </Typography>
              )}
              <div className={classes.submitButtonContainer}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Actualizar
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography align="center" variant="h5">
              Carta de pago millonario 
            </Typography>
            <form
              onSubmit={handleSubmit((data) =>
                handleSendAdvertisements(data, 'millionaireLetter'),
              )}
              noValidate
            >
              <Controller
                as={
                  <ChipInput
                    fullWidth
                    className={classes.chipInput}
                    error={errors.emailService}
                    label="Destinatarios"
                    placeholder="adolfo@mail.com, rodrigo@mail.com"
                    value={values.millionaireLetter}
                    variant="outlined"
                    newChipKeyCodes={[13, 32, 188]}
                    onAdd={(email) => {
                      setValue(
                        'millionaireLetter',
                        handleAddEmail(
                          values.millionaireLetter,
                          email,
                          'emailService',
                        ),
                      );
                    }}
                    onDelete={(_, index) => {
                      setValue(
                        'millionaireLetter',
                        handleDeleteEmail(values.millionaireLetter, index),
                      );
                    }}
                  />
                }
                control={control}
                name="millionaireLetter"
              />
              {errors.emailInvoiceError && (
                <Typography style={{ color: 'red' }}>
                  {'Error el correo *' +
                    errorsText.emailInvoiceError +
                    '* no es valido'}
                </Typography>
              )}
              <div className={classes.submitButtonContainer}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Actualizar
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography align="center" variant="h5">
              Notificaciones Fallo Facturación
            </Typography>
            <form
              onSubmit={handleSubmit((data) =>
                handleSendAdvertisements(data, 'emailInvoiceError'),
              )}
              noValidate
            >
              <Controller
                as={
                  <ChipInput
                    fullWidth
                    className={classes.chipInput}
                    error={errors.emailService}
                    label="Destinatarios"
                    placeholder="adolfo@mail.com rodrigo@mail.com"
                    value={values.emailInvoiceError}
                    variant="outlined"
                    newChipKeyCodes={[13, 32, 188]}
                    onAdd={(email) => {
                      setValue(
                        'emailInvoiceError',
                        handleAddEmail(
                          values.emailInvoiceError,
                          email,
                          'emailInvoiceError',
                        ),
                      );
                    }}
                    onDelete={(_, index) => {
                      setValue(
                        'emailInvoiceError',
                        handleDeleteEmail(
                          values.emailInvoiceError,
                          index,
                        ),
                      );
                    }}
                  />
                }
                control={control}
                name="emailInvoiceError"
              />
              {errors.emailInvoiceError && (
                <Typography style={{ color: 'red' }}>
                  {'Error el correo *' +
                    errorsText.emailInvoiceError +
                    '* no es valido'}
                </Typography>
              )}
              <div className={classes.submitButtonContainer}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Actualizar
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={6}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography align="center" variant="h5">
              Fallo en la notificaciones al DMS
            </Typography>
            <form
              onSubmit={handleSubmit((data) =>
                handleSendAdvertisements(data, 'emailDMSNotificationError'),
              )}
              noValidate
            >
              <Controller
                as={
                  <ChipInput
                    fullWidth
                    className={classes.chipInput}
                    error={errors.emailService}
                    label="Destinatarios"
                    placeholder="adolfo@mail.com rodrigo@mail.com"
                    value={values.emailInvoiceError}
                    variant="outlined"
                    newChipKeyCodes={[13, 32, 188]}
                    onAdd={(email) => {
                      setValue(
                        'emailDMSNotificationError',
                        handleAddEmail(
                          values.emailDMSNotificationError,
                          email,
                          'emailDMSNotificationError',
                        ),
                      );
                    }}
                    onDelete={(_, index) => {
                      setValue(
                        'emailDMSNotificationError',
                        handleDeleteEmail(
                          values.emailDMSNotificationError,
                          index,
                        ),
                      );
                    }}
                  />
                }
                control={control}
                name="emailDMSNotificationError"
              />
              {errors.emailDMSNotificationError && (
                <Typography style={{ color: 'red' }}>
                  {'Error el correo *' +
                    errorsText.emailDMSNotificationError +
                    '* no es valido'}
                </Typography>
              )}
              <div className={classes.submitButtonContainer}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Actualizar
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
      </Grid>

      {agencyDetails?.upgradeProcessInvoice && (
      <Grid item xs={12} md={6}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography align="center" variant="h5">
              Notificaciones Facturación Exitosa
            </Typography>
            <form
              onSubmit={handleSubmit((data) =>
                handleSendAdvertisements(data, 'emailInvoiceSuccess'),
              )}
              noValidate
            >
              <Controller
                as={
                  <ChipInput
                    fullWidth
                    className={classes.chipInput}
                    error={errors.emailService}
                    label="Destinatarios"
                    placeholder="adolfo@mail.com rodrigo@mail.com"
                    value={values.emailInvoiceSuccess}
                    variant="outlined"
                    newChipKeyCodes={[13, 32, 188]}
                    onAdd={(email) => {
                      setValue(
                        'emailInvoiceSuccess',
                        handleAddEmail(
                          values.emailInvoiceSuccess,
                          email,
                          'emailInvoiceSuccess',
                        ),
                      );
                    }}
                    onDelete={(_, index) => {
                      setValue(
                        'emailInvoiceSuccess',
                        handleDeleteEmail(
                          values.emailInvoiceSuccess,
                          index,
                        ),
                      );
                    }}
                  />
                }
                control={control}
                name="emailInvoiceSuccess"
              />
              {errors.emailInvoiceSuccess && (
                <Typography style={{ color: 'red' }}>
                  {'Error el correo *' +
                    errorsText.emailInvoiceSuccess +
                    '* no es valido'}
                </Typography>
              )}
              <div className={classes.submitButtonContainer}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Actualizar
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
      </Grid>
      )}
    </>
  );

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Typography variant="h5" className={classes.title}>
            Avisos
          </Typography>
        </Grid>
        {renderCommissionsByCards()}
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  agencies: state.agencies,
});

const mapDispatchToProps = {
  createAgencyEvents,
  updateAgencyEvents,
  closeModals,
};

export default withRouter(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(Advertisements),
  ),
);
