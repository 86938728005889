import React, { useEffect, useState } from 'react';

import {
  InputAdornment,
  IconButton,
  OutlinedInput,
  FormControl,
  InputLabel,
} from '@material-ui/core';

import { Visibility, VisibilityOff } from '@material-ui/icons';

import { Alert } from 'react-bootstrap';
import Input from 'app/components/Input';
import { Link } from 'react-router-dom';
import { closeModals } from 'app/features/modals/modalsSlice';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { login as loginAction } from '../../authSlice';
import { loginSchema } from './schema';
import { makeStyles } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormattedMessage, injectIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  alert: {
    marginLeft: theme.spacing(2),
  },
}));

function Login(props) {
  const { auth, agencies } = props;
  const classes = useStyles();
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: '2.5rem',
  });
  const [password, setPassword] = useState({
    password: '',
    showPassword: false,
  });

  const { handleSubmit, register, errors } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(loginSchema),
  });

  useEffect(() => {
    if (props.auth.isLoading)
      setLoadingButtonStyle({ paddingRight: '3.5rem' });
    else setLoadingButtonStyle({ paddingRight: '2.5rem' });
  }, [props.auth.isLoading]); // Only re-run the effect if count changes

  const handleCloseModals = () => {
    props.closeModals();
  };

  const dismissAlert = () => {
    window.setTimeout(() => {
      props.closeModals();
    }, 7000);
  };

  const handleLogin = (formData) => {
    props.loginAction({
      email: formData.email,
      password: password.password,
    });
    dismissAlert();
  };

  const handleChangePassword = (value) => {
    setPassword({
      ...password,
      password: value,
    });
  };

  const handleClickShowPassword = () => {
    setPassword({
      ...password,
      showPassword: !password.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const renderError = () => {
    try {
      const { errorModal } = props.auth;
      if (errorModal && 'show' in errorModal && errorModal.show) {
        const { show, message } = props.auth['errorModal'];
        return (
          <Alert
            show={show}
            variant="danger"
            onClose={handleCloseModals}
            dismissible
          >
            <Alert.Heading>{message.desc}</Alert.Heading>
          </Alert>
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  const renderSuccess = () => {
    return (
      <Alert
        show={props.auth.successModal.show}
        variant="success"
        onClose={handleCloseModals}
        dismissible
      >
        <Alert.Heading>Éxito</Alert.Heading>
        <p className={classes.alert}>
          {props.auth?.successModal?.message?.desc}
        </p>
      </Alert>
    );
  };

  return (
    <>
      <div className="kt-login__body">
        <div className="kt-login__form">
          <form
            autoComplete="off"
            className="kt-form"
            onSubmit={handleSubmit(handleLogin)}
            noValidate
          >
            {renderError()}
            {renderSuccess()}

            <div className="form-group">
              <Input
                required
                ref={register({
                  required: true,
                })}
                type="email"
                label="Correo electrónico"
                margin="normal"
                name="email"
                error={!!errors.email}
                helperText={errors?.email?.message}
              />
            </div>

            <div className="form-group">
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-password">
                  Contraseña
                </InputLabel>
                <OutlinedInput
                  required="false"
                  inputRef={register({
                    required: false,
                  })}
                  id="outlined-adornment-password"
                  margin="normal"
                  label="Contraseña"
                  name="password"
                  value={password.password}
                  type={password.showPassword ? 'text' : 'password'}
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                  onChange={(e) =>
                    handleChangePassword(e.target.value)
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {password.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  fullWidth
                  {...props}
                />
              </FormControl>
            </div>

            <div className="kt-login__actions">
              <Link
                to="/auth/forgot-password"
                className="kt-link kt-login__link-forgot"
              >
                <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
              </Link>

              <button
                id="kt_login_signin_submit"
                type="submit"
                disabled={props.auth.isLoading || agencies.isLoading}
                className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                  {
                    'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                      auth.isLoading || agencies.isLoading,
                  },
                )}`}
                style={loadingButtonStyle}
              >
                Entrar
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  agencies: state.agencies,
});

const mapDispatchToProps = {
  loginAction,
  closeModals,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(Login),
);
