import 'react-table/react-table.css';
import 'moment/locale/es';

import { Badge } from 'react-bootstrap';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { CSVLink } from 'react-csv';
import Close from '@material-ui/icons/Close';
import GetApp from '@material-ui/icons/GetApp';
/* eslint-disable react/display-name */
import MUIDatePickerFilter from 'app/components/MUIDatePickerFilter';
import {
  Button,
  IconButton,
  Paper,
  Tab,
  Tabs,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { formatNumber } from '../../../../utils';
import {
  makeSelectUserRoleFromState,
  selectAuth,
} from '../../../auth/selectors';
import MomentUtils from '@date-io/moment';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { injectIntl } from 'react-intl';
import { getAllPayments } from '../../reservationsSlice';
import { selectReservations } from '../../selectors';
import moment from 'moment';
import {
  accountingRoles,
  adminRoles,
  superAdminRoles,
} from '../../../../config/roles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  paper: {
    widht: '100%',
    marginBottom: theme.spacing(0),
  },
  button: {
    margin: theme.spacing(1),
  },
  grid: {
    marginBottom: theme.spacing(1),
  },
  range: {
    paddingTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  datePicker: {
    width: 90,
  },
}));

const Payments = (props) => {
  const { getAllPayments, reservations, intl, role } = props;
  const classes = useStyles();
  const reactTable = React.useRef(null);
  const [paymentsRegisters, setPaymentsRegisters] = useState([]);
  const [dataCSVLink, setDataCSVLink] = useState([]);
  const [firstDate, setFirstDate] = useState(null);
  const [secondDate, setSecondDate] = useState(null);

  useEffect(() => {
    if (
      reservations &&
      reservations.fromdbPayments &&
      reservations.fromdbPayments.length > 0
    ) {
      setPaymentsRegisters(reservations?.fromdbPayments);
    } else {
      setPaymentsRegisters([]);
    }
  }, [reservations]);

  useEffect(() => {
    if (paymentsRegisters && paymentsRegisters.length > 0) {
      const formatData = paymentsRegisters.map((item) => {
        const {
          paymentOrder,
          billPocketID,
          cardHolder,
          last4,
          paymentMethod,
          date,
          type,
          months,
          total,
          comission,
          promotionWI,
          amount,
          surcharge,
        } = item;
        const {
          orderID,
          numberReservation,
          nombre,
          phoneNumber,
          reference,
          adviser: adviserName,
          total: totalPaymentOrder,
        } = paymentOrder;

        let toDeposit = paymentMethod
          ? promotionWI
            ? amount - surcharge
            : amount - comission
          : 0;

        return {
          billPocketID,
          cardHolder,
          last4,
          paymentMethod,
          date,
          type,
          months: months
            ? `A ${months} meses`
            : 'Una sola exhibicion',
          total,
          comission,
          orderID,
          numberReservation,
          nombre,
          phoneNumber,
          reference,
          toDeposit,
          amountForDiffer: total - totalPaymentOrder,
          adviserName,
        };
      });
      setDataCSVLink(formatData);
    }
  }, [paymentsRegisters]);
  const handleFetchData = debounce(
    (state, instance) => {
      const { page, pageSize, sorted, filtered } = state;
      getAllPayments({
        firstDate,
        secondDate,
        page,
        pageSize,
        sorted,
        filtered,
      });
    },
    1000,
    { leading: false, trailing: true },
  );

  const handleTable = (row) => {
    return `/detail-payment/${row.original.id}`;
  };

  const getDataField = (field, row) => {
    let value;
    if (field === 'toDeposit' || field === 'iva') {
      if (row.paymentMethod) {
        if (row.promotionWI) value = row.amount - row.surcharge;
        else value = row.amount - row.comission;
      } else {
        value = 0;
      }
    }

    if (field === 'iva') {
      let toDeposit = value;
      let comissionWithOutIva = row.paymentMethod
        ? (row.total - toDeposit) / 1.16
        : 0;

      value = row.paymentMethod
        ? comissionWithOutIva
          ? (comissionWithOutIva * 0.16).toFixed(2)
          : '-'
        : 0;
    }

    if (value) return formatNumber(value);
    return '-';
  };

  const handleFirstDate = (date) => {
    setFirstDate(date);
  };

  const handleSecondDate = (date) => {
    setSecondDate(date);
  };

  const handleClearFirstDate = () => {
    setFirstDate(null);
  };

  const handleClearSecondDate = () => {
    setSecondDate(null);
  };

  useEffect(() => {
    if (firstDate && secondDate)
      reactTable.current && reactTable.current.fireFetchData();
    else if (!firstDate && !secondDate)
      reactTable.current && reactTable.current.fireFetchData();

    props.setActionButton(
      <>
        <MuiPickersUtilsProvider utils={MomentUtils} locale={'es'}>
          <DatePicker
            autoOk
            disableToolbar
            disableFuture
            variant="inline"
            label="Inicial"
            format="DD/MM"
            className={classes.datePicker}
            value={firstDate}
            onChange={handleFirstDate}
            InputProps={{
              endAdornment: firstDate ? (
                <IconButton
                  aria-label="Select locale"
                  size="small"
                  onClick={handleClearFirstDate}
                >
                  <Close />
                </IconButton>
              ) : null,
            }}
          />
          <span className={classes.range}>&nbsp;A&nbsp;</span>
          <DatePicker
            autoOk
            disableToolbar
            disableFuture
            variant="inline"
            label="Final"
            format="DD/MM"
            className={classes.datePicker}
            value={secondDate}
            onChange={handleSecondDate}
            InputProps={{
              endAdornment: secondDate ? (
                <IconButton
                  aria-label="Select locale"
                  size="small"
                  onClick={handleClearSecondDate}
                >
                  <Close />
                </IconButton>
              ) : null,
            }}
          />
        </MuiPickersUtilsProvider>
        <CSVLink
          data={dataCSVLink}
          filename="tablaPagosReservaciones.csv"
          headers={[
            { label: 'Order ID', key: 'orderID' },
            {
              label: 'No. Reservacion',
              key: 'numberReservation',
            },
            { label: 'BP ID', key: 'billPocketID' },
            { label: 'Nombre Tarjetahabiente', key: 'cardHolder' },
            { label: 'Nombre Cliente', key: 'nombre' },
            {
              label: 'Teléfono Cliente',
              key: 'phoneNumber',
            },
            { label: 'Nombre Asesor', key: 'adviserName' },
            {
              label: 'No. Transacción',
              key: 'reference',
            },
            { label: 'No. Tarjeta', key: 'last4' },
            { label: 'Forma de pago', key: 'paymentMethod' },
            { label: 'Fecha de pago', key: 'date' },
            { label: 'Metodo Pago', key: 'type' },
            { label: 'Meses', key: 'months' },
            { label: 'Total', key: 'total' },
            { label: 'Comision', key: 'comission' },
            {
              label: 'Cargo por diferir a meses',
              key: 'amountForDiffer',
            },
            { label: 'Total a depositar', key: 'toDeposit' },
          ]}
          onClick={(event) => {
            return dataCSVLink.length > 0;
          }}
        >
          <Button
            variant="contained"
            className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
            disabled={Boolean(dataCSVLink.length <= 0)}
          >
            Exportar
            <GetApp className="kt-margin-l-10" />
          </Button>
        </CSVLink>
      </>,
    );
  }, [firstDate, secondDate]);

  const colums = [
    {
      Header: () => <strong>ID Orden</strong>,
      accessor: 'paymentOrder.orderID',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>BP ID</strong>,
      accessor: 'billPocketID',
      Cell: (row) => {
        return (
          <Link to={handleTable(row)}>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              {row.value}
            </div>
          </Link>
        );
      },
    },
    {
      Header: () => <strong>Cliente</strong>,
      accessor: 'cardHolder',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>Método de pago</strong>,
      accessor: 'type',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {intl.formatMessage({
              id: `PAYMENT.TYPE.${row.value}`,
            })}
          </div>
        </Link>
      ),
      Filter: (cell) => {
        return (
          // eslint-disable-next-line jsx-a11y/no-onchange
          <select
            onChange={(e) => cell.onChange(e.target.value)}
            value={
              cell.filter && cell.filter.value
                ? cell.filter.value
                : ''
            }
          >
            <option value="">Todos</option>
            <option value="DEBIT VISA">DÉBITO VISA</option>
            <option value="DEBIT MASTERCARD">
              DÉBITO MASTERCARD
            </option>
            <option value="CREDIT AMERICAN-EXPRESS">
              CRÉDITO AMEX
            </option>
            <option value="CREDIT VISA">CRÉDITO VISA</option>
            <option value="CREDIT MASTERCARD">
              CRÉDITO MASTERCARD
            </option>
            <option value="OXXO">OXXO</option>
            <option value="SPEI">SPEI</option>
          </select>
        );
      },
    },
    {
      Header: () => <strong>Meses</strong>,
      accessor: 'months',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value ? (
              <Badge className={classes.badges} variant="info">
                {`${row.value} meses`}
              </Badge>
            ) : (
              '-'
            )}
          </div>
        </Link>
      ),
      Filter: (cell) => {
        return (
          // eslint-disable-next-line jsx-a11y/no-onchange
          <select
            onChange={(e) => cell.onChange(e.target.value)}
            value={
              cell.filter && cell.filter.value
                ? cell.filter.value
                : ''
            }
          >
            <option value="">Todos</option>
            <option value="3">3 meses</option>
            <option value="6">6 meses</option>
            <option value="9">9 meses</option>
            <option value="12">12 meses</option>
          </select>
        );
      },
    },
    {
      Header: () => <strong>Fecha de pago</strong>,
      accessor: 'date',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value
              ? moment(row.value).utc().format('DD/MM/YYYY')
              : '-'}
          </div>
        </Link>
      ),
      Filter: (cell) => {
        return <MUIDatePickerFilter cell={cell} />;
      },
    },
    {
      Header: () => <strong>Monto</strong>,
      accessor: 'total',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {formatNumber(row.value)}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Comisión</strong>,
      accessor: 'comission',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {formatNumber(row.value)}
          </div>
        </Link>
      ),
      filterable: false,
      show:
        superAdminRoles.includes(role) ||
        adminRoles.includes(role) ||
        accountingRoles.includes(role),
    },
    {
      Header: () => <strong>IVA</strong>,
      accessor: 'iva',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {getDataField('iva', row.original)}
          </div>
        </Link>
      ),
      filterable: false,
      show:
        superAdminRoles.includes(role) ||
        adminRoles.includes(role) ||
        accountingRoles.includes(role),
    },
    {
      Header: () => <strong>A depositar</strong>,
      accessor: 'toDeposit',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {getDataField('toDeposit', row.original)}
          </div>
        </Link>
      ),
      filterable: false,
      show:
        superAdminRoles.includes(role) ||
        adminRoles.includes(role) ||
        accountingRoles.includes(role),
    },
  ];

  return (
    <div className={classes.root}>
      <Paper>
        <ReactTable
          ref={reactTable}
          NoDataComponent={() => (
            <div className="rt-noData">Sin resultados</div>
          )}
          manual
          onFetchData={handleFetchData}
          data={paymentsRegisters || []}
          pages={reservations?.pagesPayments}
          columns={colums}
          filterable
          className="-striped -highlight"
          previousText="Anterior"
          nextText="Siguiente"
          loading={reservations?.isLoading}
          loadingText="Cargando datos..."
        />
      </Paper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  reservations: selectReservations(state),
  role: makeSelectUserRoleFromState(state),
});

const mapDispatchToProps = {
  getAllPayments,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(Payments),
);
