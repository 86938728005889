import { closeModals } from '../modals/modalsSlice';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { logout } from '../auth/authSlice';

const karloUsersSlice = createSlice({
  name: 'karloUsers',
  initialState,
  reducers: {
    getKarloUsers: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getKarloUsersSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.fromdb = action.payload.karloUsers;
        state.tablePages = action.payload.karloUsers.pages;
      },
    },
    getKarloUsersFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    getUserDetail: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getUserDetailSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.userDetail = action.payload.userDetail;
      },
    },
    getUserDetailFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
  },
  extraReducers: {
    [logout]: (state, action) => {
      return initialState;
    },
  },
});

export const {
  getKarloUsers,
  getKarloUsersSuccess,
  getKarloUsersFail,
  getUserDetail,
  getUserDetailSuccess,
  getUserDetailFail,
} = karloUsersSlice.actions;

export default karloUsersSlice.reducer;
