import React, { useEffect, useState } from 'react';

import { TextField, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { getAgencies } from 'app/features/agencies/agenciesSlice';
import { makeSelectAgenciesFromdb } from 'app/features/agencies/selectors';

import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  agenciesSelectFull: {
    width: '100%',
  },
  agenciesSelect: {
    marginTop: '8px',
    [theme.breakpoints.down('lg')]: {
      width: '130px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '200px',
    },
    marginRight: '5px',
  },
}));

const MUIAutocompleteAgencies = (props) => {
  const classes = useStyles();
  const {
    agenciesFromdb,
    getAgencies,
    bp,
    quiter,
    dashboarAgency,
    textLabel,
    multiple,
    fullWidth,
    ...rest
  } = props;

  const [agenciesOptions, setAgenciesOptions] = useState([]);

  useEffect(() => {
    getAgencies();
  }, []);

  useEffect(() => {
    if (bp) {
      if (Array.isArray(agenciesFromdb)) {
        let cleanAgencies = agenciesFromdb.map((agency) => {
          return agency;
        });
        const agencies = cleanAgencies.filter(
          (el) => el.DMS === 'businesspro',
        );
        cleanAgencies = agencies.map((agency) => {
          return {
            id: agency.id,
            label: agency.name,
          };
        });
        cleanAgencies.push({
          id: 1000000,
          label: 'Todas las agencias',
        });
        cleanAgencies.reverse();
        setAgenciesOptions(cleanAgencies);
      }
    } else if (quiter) {
      if (Array.isArray(agenciesFromdb)) {
        let cleanAgencies = agenciesFromdb.map((agency) => {
          return agency;
        });
        let agencies = cleanAgencies
          .filter((el) => el.DMS === 'quiter')
          .map((agency) => {
            return {
              id: agency.id,
              label: agency.name,
            };
          });
        agencies.push({
          id: 1000000,
          label: 'Todas las agencias',
        });
        agencies.reverse();
        setAgenciesOptions(agencies);
      }
    } else if (dashboarAgency) {
      if (Array.isArray(agenciesFromdb)) {
        let cleanAgencies = agenciesFromdb.map((agency) => {
          return agency;
        });
        let agencies = cleanAgencies
          .filter((el) => el.id === dashboarAgency)
          .map((agency) => {
            return {
              id: agency.id,
              label: agency.name,
            };
          });
        agencies.reverse();
        setAgenciesOptions(agencies);
      }
    } else {
      if (Array.isArray(agenciesFromdb)) {
        let cleanAgencies = agenciesFromdb.map((agency) => {
          return {
            id: agency.id,
            label: agency.name,
          };
        });
        cleanAgencies.push({
          id: 1000000,
          label: 'Todas las agencias',
        });
        cleanAgencies.reverse();
        setAgenciesOptions(cleanAgencies);
      }
    }
  }, [agenciesFromdb, bp, quiter, dashboarAgency]);

  const getOpObj = (option) => {
    if (!option.id)
      option = agenciesOptions.find((op) => op.id === option);
    return option;
  };

  const getOpLabel = (option) => {
    const optionSelected = getOpObj(option);
    if (optionSelected) return optionSelected.label;
    return '';
  };

  return (
    <Autocomplete
      getOptionLabel={getOpLabel}
      multiple={multiple}
      getOptionSelected={(option, value) => option.id === value.id}
      options={agenciesOptions}
      {...rest}
      className={
        fullWidth
          ? classes.agenciesSelectFull
          : classes.agenciesSelect
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={textLabel ? textLabel : 'Agencia'}
          variant="outlined"
        />
      )}
    />
  );
};

const mapStateToProps = (state) => ({
  agenciesFromdb: makeSelectAgenciesFromdb(state),
});

const mapDispatchToProps = {
  getAgencies: getAgencies,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MUIAutocompleteAgencies);
