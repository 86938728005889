import React, { useEffect, useState, Fragment } from 'react';

import { Badge, Table } from 'react-bootstrap';
import {
  Button,
  ClickAwayListener,
  DialogTitle,
  Grid,
  InputAdornment,
  MenuItem,
  MuiThemeProvider,
  Paper,
  TextField,
  Tooltip,
  createMuiTheme,
  makeStyles,
  Select,
  InputLabel,
  FormControl,
} from '@material-ui/core';

import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import {
  Cancel,
  LocalOffer,
  Mail,
  WhatsApp,
  Sms,
} from '@material-ui/icons';

import {
  formatNumber,
  getBadgeColor,
  getNested,
  getPOStatusName,
} from '../../../../utils';

import Modal from '../../../../components/Modal';
import NumberFormatCustom from '../../../../components/NumberFormatCustom';
import Products from 'app/components/Products';
import { PromotionMonthsCard } from 'app/components/PromotionMonthsCard';
import { RHFMUISelect } from 'app/components/RHF';
import RecentActivities from '../../../../components/RecentActivities';
import ShouldItRender from '../../../../components/ShouldItRender';

import {
  applyAdvancePaymentOrder,
  createOrUpdateSurtaxesForPaymentOrders,
  getPaymentOrderDetail,
  getPaymentsForOrderDetail,
  sendEmailLinkPaymentOrder,
  updatePaymentOrder,
} from '../../paymentOrdersSlice';
import { sendNotification } from 'app/features/notifications/notificationsSlice';
import { tokenizePaymentOrder } from 'app/features/tokenize/tokenizeSlice';
import { closeModals } from '../../../modals/modalsSlice';

import { makeSelectPaymentOrderDetailsFromState } from 'app/features/paymentOrders/selectors';
import { makeSelectPaymentOrderTokenFromState } from 'app/features/tokenize/selectors';
import { makeSelectPaymentOrdersLogs } from '../../../logs/selectors';
import { makeSelectUserRoleFromState } from 'app/features/auth/selectors';

import { handleUrlLanding } from '../../../../config/landing';
import SplashScreen from '../../../../partials/layout/SplashScreen';

import { Link } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import { connect } from 'react-redux';
import copy from 'copy-to-clipboard';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import { withRouter } from 'react-router';
import { Controller, useForm } from 'react-hook-form';
import { get, isEmpty } from 'lodash';

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '1em',
        color: 'lightblue',
        backgroundColor: 'green',
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  paperTarjeta: {
    textAlign: 'left',
    marginTop: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  divider: {
    width: '100%',
  },
  title: {
    textAlign: 'left',
    color: '#1c1c1c',
  },
  content: {
    textAlign: 'left',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  inputs: {
    marginTop: theme.spacing(1),
  },
  rightContainer: {
    flexDirection: 'column',
  },
  item: {
    textAlign: 'left',
    justifyContent: 'space-between',
  },
  paperOrden: {
    width: '100%',
    textAlign: 'left',
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
  },
  paperCliente: {
    width: '100%',
    textAlign: 'left',
    marginTop: theme.spacing(2),
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
  },
  paperUrl: {
    width: '100%',
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
  },
  item1: {
    textAlign: 'left',
  },
  item2: {
    textAlign: 'center',
  },
  tableTitle: {
    fontWeight: 401,
  },
  tables: {
    width: '100%',
  },
}));

const DetailPaymentOrder = (props) => {
  const classes = useStyles();
  const {
    closeModals,
    sendEmailLinkPaymentOrder,
    notifications,
    paymentOrders,
    paymentOrderdetails,
    paymentOrdersLogs,
    applyAdvancePaymentOrder,
    getPaymentOrderDetail,
    updatePaymentOrder,
    SendNotification,
    createOrUpdateSurtaxesForPaymentOrders,
    paymentOrderToken,
    tokenizePaymentOrder,
    setLeftSideComponent,
    setActionButton,
    role,
    match,
    location,
    intl,
  } = props;
  const [orderData, setOrder] = useState({});
  const [showTooltip, setShowTooltip] = useState(false);
  const [showSMSModal, setShowSMSModal] = useState(false);
  const [showModalCancelingOrder, setShowModalCancelingOrder] =
    useState(false);
  const [showEmailPaymentLink, setShowEmailPaymentLink] =
    useState(false);
  const [showWhatsModal, setShowWhatsModal] = useState(false);
  const [showAdvanceModal, setShowAdvanceModal] = useState(false);
  const [paymentsMiniCard, setPaymentsMiniCard] = useState([]);
  const [paymentsToRender, setPaymentsToRender] = useState([]);
  const [balance, setBalance] = useState(0);
  const [amount, setAmount] = useState('');
  const [showPromotionsModal, setShowPromotionsModal] =
    useState(false);
  const [monthsChecked, setMonthsChecked] = useState({
    amex: {
      id: 0,
      3: 'false',
      6: 'false',
      9: 'false',
      12: 'false',
      18: 'false',
      typeOfPromotion: '',
    },
    visa: {
      id: 0,
      3: 'false',
      6: 'false',
      9: 'false',
      12: 'false',
      18: 'false',
      typeOfPromotion: '',
    },
  });
  const [lada, setLada] = useState('+52');

  const {
    handleSubmit,
    register,
    errors,
    formState,
    control,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      type: '',
      date: null,
      amount: '',
      reference: '',
    },
  });

  const date = watch('date');

  useEffect(() => {
    setLeftSideComponent(
      <div className="kt-subheader__main">
        <div>
          <h3 className="kt-subheader__title">
            Detalle orden de pago
          </h3>
        </div>
      </div>,
    );
    let data = {
      orderID: match.params.id,
      agencyID: match.params.agencyId,
    };
    getPaymentOrderDetail(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { orderDetails } = paymentOrders;
    handleDisplayRightButtons(orderDetails);
    handlerenderPaymentsDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentOrders, paymentOrderdetails]);

  useEffect(() => {
    if (paymentOrderdetails) {
      const orderData = paymentOrderdetails || {};
      const agencyData = orderData.agency || {};
      const operationsData = orderData.products || {};
      const cleanOrder = {
        id: orderData.id || 'n/a',
        status: getPOStatusName(orderData.status) || 'n/a',
        total: orderData.total ? orderData.total : 'n/a',
        orderID: orderData.orderID || 'n/a',
        reference: orderData.reference || 'n/a',
        operations: operationsData || 'n/a',
        createdDate: orderData.createdAt || 'n/a',
        nickname: agencyData.nickname || 'n/a',
        clientFullName: orderData.nombre || 'n/a',
        clientEmail: orderData.email || 'n/a',
        clientPhoneNumber: orderData.phoneNumber || 'n/a',
        vin: orderData.vin || 'n/a',
        urlPayment: orderData.urlPayment,
        clientRFC: orderData.rfc || 'n/a',
        type: getNested(null, orderData, 'type'),
        clientAccount: getNested(null, orderData, 'clientAccount'),
      };
      setOrder(cleanOrder);
      tokenizePaymentOrder({
        reference: getNested(null, orderData, 'reference'),
        agencyID: getNested(null, orderData, 'agencyID'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentOrderdetails]);

  const handleDisplayRightButtons = (orderDetails) => {
    if (
      orderDetails &&
      orderDetails.status &&
      (orderDetails.status === 'por pagar' ||
        orderDetails.status === 'pago provisional')
    ) {
      return setActionButton(
        <>
          <ShouldItRender
            should={
              orderDetails.agency.odsServicePromotions ||
              orderDetails.agency.odpSalesPromotions ||
              orderDetails.agency.odpPartsPromotions ||
              orderDetails.agency.odpServicePromotions ||
              role === 'Agency Admin' ||
              role === 'Service Manager' ||
              role === 'Super Admin' ||
              role === 'Super Admin Agencies' ||
              role === 'W32 Agency Admin' ||
              role === 'Parts Manager' ||
              role === 'Agency Manager' ||
              role === 'Super Admin WTF'
            }
            action="addPromotions"
            locationPage={`/${props.location.pathname.split('/')[1]}`}
            compounded
          >
            <Button
              variant="contained"
              className="btn btn-label-info btn-bold btn-sm btn-icon-h kt-margin-l-10"
              onClick={() => setShowPromotionsModal(true)}
            >
              Promociones
              <LocalOffer className="kt-margin-l-10" />
            </Button>
          </ShouldItRender>
          <ShouldItRender
            should={Boolean(
              orderDetails.agency.provisionalPayments &&
                orderDetails.provisional,
            )}
            locationPage={`/${location.pathname.split('/')[1]}`}
            action="addAdvance"
            compounded
          >
            <Button
              variant="contained"
              className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
              onClick={toogleAdvanceModal}
            >
              Anticipo
              <Sms className="kt-margin-l-10" />
            </Button>
          </ShouldItRender>
          <ShouldItRender
            should={
              Boolean(orderDetails.agency.smsUnitNotifications) &&
              role !== 'Super Admin Agencies'
            }
            locationPage={`/${location.pathname.split('/')[1]}`}
            action="shareLink"
            compounded
          >
            <Button
              variant="contained"
              className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
              onClick={toogleSMSModal}
            >
              SMS
              <Sms className="kt-margin-l-10" />
            </Button>
          </ShouldItRender>
          <ShouldItRender
            should={Boolean(
              orderDetails.agency.whatsUnitNotifications,
            )}
            locationPage={`/${location.pathname.split('/')[1]}`}
            action="shareLink"
            compounded
          >
            <Button
              variant="contained"
              className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
              onClick={toogleWhatsModal}
            >
              WhatsApp
              <WhatsApp className="kt-margin-l-10" />
            </Button>
          </ShouldItRender>
          <ShouldItRender
            should={Boolean(
              orderDetails.agency.emailUnitNotifications,
            )}
            locationPage={`/${location.pathname.split('/')[1]}`}
            action="shareLink"
            compounded
          >
            <Button
              variant="contained"
              className="btn btn-label-info btn-bold btn-sm btn-icon-h kt-margin-l-10"
              onClick={() => setShowEmailPaymentLink(true)}
            >
              Correo
              <Mail className="kt-margin-l-10" />
            </Button>
          </ShouldItRender>
          {cancelButtonValidate(orderDetails.payments) ? (
            <Button
              variant="contained"
              className="btn btn-label-warning btn-bold btn-sm btn-icon-h kt-margin-l-10"
              onClick={toogleCancelOrderModal}
            >
              Cancelar orden
              <Cancel className="kt-margin-l-10" />
            </Button>
          ) : null}
        </>,
      );
    } else {
      setActionButton(null);
    }
  };

  const cancelButtonValidate = (data) => {
    if (data.length === 0) {
      return true;
    } else {
      let result = true;
      const filter = data.filter((pay) => pay.status === 'DONE');
      if (filter.length > 0) result = false;
      return result;
    }
  };

  const toogleAdvanceModal = () =>
    setShowAdvanceModal(!showAdvanceModal);

  const toogleSMSModal = () => setShowSMSModal(!showSMSModal);

  const closeModalSMS = () => setShowSMSModal(false);

  const toogleWhatsModal = () => setShowWhatsModal(!showWhatsModal);

  const closeModalWhats = () => setShowWhatsModal(false);

  const toogleCancelOrderModal = () =>
    setShowModalCancelingOrder(!showModalCancelingOrder);

  const closeModalCancelingOrder = () =>
    setShowModalCancelingOrder(false);

  const handleToogleEmailLinkModal = () => {
    setShowEmailPaymentLink(!showEmailPaymentLink);
  };

  const getOrderCreator = () => {
    let creator = '';
    const tempPaymentOrderLogs = getNested([], paymentOrdersLogs);
    for (let i = 0; i < tempPaymentOrderLogs.length; i++) {
      if (tempPaymentOrderLogs[i].type === 'CREATE') {
        creator = tempPaymentOrderLogs[i].text.slice(
          tempPaymentOrderLogs[i].text.indexOf('usuario') +
            'usuario'.length +
            1,
          tempPaymentOrderLogs[i].text.length,
        );
        break;
      }
    }
    return creator;
  };

  const handleCancelOrder = () => {
    const id = get(paymentOrderdetails, 'id', null);
    const reference = get(paymentOrderdetails, 'reference', null);
    const data = {
      id,
      status: 'cancelada',
      reference,
    };
    updatePaymentOrder(data);
  };

  const handleSendAdvance = (formData) => {
    const data = {
      ...formData,
      amount: parseFloat(amount.replace(/,/g, ''), 10),
      paymentOrderID: orderData.id,
      orderID: match.params.id,
      agencyID: match.params.agencyId,
    };
    applyAdvancePaymentOrder(data);
    setShowAdvanceModal(false);
    closeModals();
  };

  const onSubmit = (type, formData) => {
    const { id } = match.params;
    const data = {
      isOdp: true,
      phoneNumber: formData.cellphone,
      id,
      type,
      lada,
    };
    SendNotification(data);
    closeModals();
  };

  const handleSendPaymentLink = (formData) => {
    const data = {
      paymentOrderID: orderData.id,
      email: formData.email,
      paymentID: match.params.paymentID,
    };
    sendEmailLinkPaymentOrder(data, handleToogleEmailLinkModal);
    handleToogleEmailLinkModal();
  };

  const handleCloseModals = () => {
    closeModalCancelingOrder();
    closeModalWhats();
    closeModalSMS();
    closeModals();
  };

  const handleHideTooltip = () => {
    setShowTooltip(false);
  };

  const handleCopyText = () => {
    const landingUrl = handleUrlLanding();
    setShowTooltip(true);
    if (orderData.urlPayment) {
      copy(orderData.urlPayment);
    } else {
      copy(`${landingUrl}odp/${paymentOrderToken}`);
    }
  };

  const handleValidateTotal = () => {
    return amount > orderData.total;
  };
  const renderOrderType = (type) => {
    switch (type) {
      case 'insurance':
        return 'Seguro';
      case 'service':
        return 'Servicio';
      case 'parts':
        return 'Refacciones';
      case 'sales':
        return 'Ventas';
      case 'fi':
        return 'F&I y Garantías';
      case 'hp':
        return 'H&P';
      case 'reservation':
        return 'Reservación';
      case 'pulled_apart':
        return 'Apartado';
      case 'maintenance':
        return 'Mantenimiento';
      default:
        return '';
    }
  };

  const renderAdvanceModal = () => {
    return (
      <Modal
        open={showAdvanceModal}
        closeModal={toogleAdvanceModal}
        dialogTitle="Aplicar anticipo"
        dialogText="Se aplicará un pago externo a la orden de pago."
        actionButtonText="Aplicar anticipo"
        dialogChildren={
          <form
            id="advance-form"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(handleSendAdvance)}
          >
            <TextField
              required
              error={Boolean(errors.amount) || handleValidateTotal()}
              label="Cantidad"
              name="amount"
              value={amount}
              onChange={({ target }) => setAmount(target.value)}
              margin="normal"
              variant="outlined"
              helperText={
                handleValidateTotal()
                  ? 'El valor no puede ser mayor al total de la orden.'
                  : null
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                inputComponent: NumberFormatCustom,
                inputProps: { quantity: true },
              }}
            />
            <RHFMUISelect
              name="type"
              className={classes.inputs}
              label="Método de pago"
              control={control}
              rules={{ required: true }}
              error={!!errors.type}
            >
              <MenuItem disabled value="">
                Selecciona una opción
              </MenuItem>
              <MenuItem value={'CASH'}>Efectivo</MenuItem>
              <MenuItem value={'TRANSFER'}>Transferencia</MenuItem>
              <MenuItem value={'CARD'}>
                Tarjeta de débito ó crédito
              </MenuItem>
              <MenuItem value={'CREDIT AMEX'}>Tarjeta AMEX</MenuItem>
            </RHFMUISelect>
            <TextField
              required
              name="reference"
              error={Boolean(errors.reference)}
              inputRef={register({
                required: true,
              })}
              label="Número de referencia"
              margin="normal"
              variant="outlined"
            />
            <Controller
              control={control}
              name="date"
              rules={{ required: true }}
              as={
                <MuiPickersUtilsProvider
                  utils={MomentUtils}
                  locale={'es'}
                >
                  <DatePicker
                    autoOk
                    fullWidth
                    disableFuture
                    disableToolbar
                    error={Boolean(errors.date)}
                    className={classes.inputs}
                    label="Fecha de pago"
                    variant="inline"
                    inputVariant="outlined"
                    value={date}
                    onChange={(e) => setValue('date', e, false)}
                  />
                </MuiPickersUtilsProvider>
              }
            ></Controller>
          </form>
        }
        form={'advance-form'}
      />
    );
  };

  const renderSendSmsDialog = () => {
    return (
      <Modal
        open={showSMSModal}
        closeModal={toogleSMSModal}
        dialogTitle="Enviar SMS"
        dialogText="Se enviará un SMS al número registrado previamente. En
        caso de ser incorrecto puede cambirlo en seguida."
        actionButtonText="Enviar"
        dialogChildren={
          <form
            id="sms-form"
            className={classes.container}
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit((data) => onSubmit('sms', data))}
          >
            <Grid container>
              <Grid item xs={3}>
                <FormControl variant="outlined">
                  <InputLabel htmlFor="outlined-lada">
                    Lada
                  </InputLabel>
                  <Select
                    native
                    label="Lada"
                    onChange={(event) => setLada(event.target.value)}
                    inputProps={{
                      id: 'outlined-lada',
                    }}
                  >
                    <option value={'+52'}>+52</option>
                    <option value={'+1'}>+1</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  required
                  name="cellphone"
                  error={Boolean(errors.cellphone)}
                  inputRef={register({
                    required: true,
                  })}
                  label="Teléfono celular"
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    type: 'number',
                  }}
                />
              </Grid>
            </Grid>
          </form>
        }
        form={'sms-form'}
      />
    );
  };

  const renderSendWhatsDialog = () => {
    return (
      <Modal
        open={showWhatsModal}
        closeModal={toogleWhatsModal}
        dialogTitle="Enviar mensaje por Whatsapp"
        dialogText="Se enviará un mensaje por medio de Whatsapp al número que ingrese."
        actionButtonText="Enviar"
        dialogChildren={
          <form
            id="whatsapp-form"
            className={classes.container}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit((data) =>
              onSubmit('whatsapp', data),
            )}
          >
            <Grid container>
              <Grid item xs={3}>
                <FormControl variant="outlined">
                  <InputLabel htmlFor="outlined-lada">
                    Lada
                  </InputLabel>
                  <Select
                    native
                    label="Lada"
                    onChange={(event) => setLada(event.target.value)}
                    inputProps={{
                      id: 'outlined-lada',
                    }}
                  >
                    <option value={'+52'}>+52</option>
                    <option value={'+1'}>+1</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  name="cellphone"
                  label="Teléfono"
                  fullWidth
                  error={Boolean(errors.cellphone)}
                  inputRef={register({
                    required: true,
                  })}
                  variant="outlined"
                  inputProps={{
                    type: 'number',
                  }}
                />
              </Grid>
            </Grid>
          </form>
        }
        form={'whatsapp-form'}
      />
    );
  };

  const renderSendMailDialog = () => {
    return (
      <Modal
        open={showEmailPaymentLink}
        closeModal={handleToogleEmailLinkModal}
        dialogTitle="Enviar correo"
        dialogText="Escriba el correo al cual le desea enviar el link de pago."
        actionButtonText="Enviar"
        dialogChildren={
          <form
            className={classes.container}
            id="email-form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(handleSendPaymentLink)}
          >
            <TextField
              name="email"
              label="Correo"
              className={classes.textField}
              error={errors.email && formState.touched.email}
              inputRef={register({
                required: true,
                pattern:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
              margin="normal"
              variant="outlined"
            />
          </form>
        }
        form={'email-form'}
      />
    );
  };

  const renderCancelOrderDialog = () => {
    return (
      <Modal
        open={showModalCancelingOrder}
        closeModal={toogleCancelOrderModal}
        dialogTitle="Cancelar orden de pago"
        dialogText="Estás a punto de cancelar esta orden de pago, una vez
        cancelada no se puede deshacer la acción, ¿Deseas
        continuar?"
        actionButtonText="Cancelar orden"
        onClick={handleCancelOrder}
      />
    );
  };

  const showCalculateAmount = () => {
    return getNested(0, orderData, 'total');
  };

  const showCardComission = () => {
    return getNested(null, paymentOrderdetails, 'cardComission');
  };

  const showAmexCardComission = () => {
    return getNested(null, paymentOrderdetails, 'amexCardComission');
  };

  const handleUpdateSurTaxes = () => {
    const detailPromotionId =
      paymentOrderdetails.detailPromotionByAgency[0] === undefined
        ? 0
        : paymentOrderdetails.detailPromotionByAgency[0].id;

    const data = {
      visa: monthsChecked.visa,
      amex: monthsChecked.amex,
      paymentOrderID: getNested(null, paymentOrderdetails, 'id'),
      orderID: match.params.id,
      agencyID: match.params.agencyId,
      detailPromotionId,
    };
    createOrUpdateSurtaxesForPaymentOrders(data);
    setShowPromotionsModal(false);
  };

  const renderPromotionsDialog = () => {
    return (
      <Modal
        open={showPromotionsModal}
        closeModal={() => setShowPromotionsModal(false)}
        maxWidth="md"
        fullWidth
        dialogTitle="Administrar promociones"
        actionButtonText="Actualizar promociones"
        onClick={handleUpdateSurTaxes}
        dialogChildren={
          <PromotionMonthsCard
            payments={paymentOrderdetails}
            amount={showCalculateAmount()}
            monthsChecked={monthsChecked}
            setMonthsChecked={setMonthsChecked}
            cardComission={showCardComission()}
            amexCardComission={showAmexCardComission()}
          />
        }
        form={'advance-form'}
      />
    );
  };

  const renderPaymentForm = (months) => {
    if (months) return `${months} meses`;
    else return 'Una sola exhibición';
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = paymentOrders;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message } = paymentOrders[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={handleCloseModals}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={handleCloseModals}
          />
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  const renderFeedBackModalnotifications = () => {
    try {
      const { successModal, errorModal } = notifications;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message } = notifications[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={handleCloseModals}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={handleCloseModals}
          />
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  const renderOrderDetails = () => {
    return (
      <Table bordered className={classes.tables}>
        <tbody>
          <tr>
            <td className={classes.tableTitle}>
              {orderData.type === 'parts'
                ? 'NO. DE PEDIDO'
                : orderData.nickname === 'WORLDTRAVELANDFUN'
                ? 'NÚMERO DE ORDEN WTF'
                : 'ID DE LA ORDEN'}
            </td>
            <td>{orderData.reference}</td>
          </tr>
          {role === 'Super Admin' ||
          role === 'Super Admin Agencies' ? (
            <>
              <tr>
                <td className={classes.tableTitle}>Agencia</td>
                <td>
                  {paymentOrderdetails &&
                  paymentOrderdetails.agency &&
                  paymentOrderdetails.agency.businessName
                    ? paymentOrderdetails.agency.businessName
                    : ''}
                </td>
              </tr>
            </>
          ) : null}
          {orderData.clientAccount && (
            <tr>
              <td className={classes.tableTitle}>
                {orderData.nickname === 'WORLDTRAVELANDFUN'
                  ? 'NÚMERO DE RESERVACIÓN'
                  : 'ID DE CLIENTE'}
              </td>
              <td>{orderData.clientAccount}</td>
            </tr>
          )}
          <tr>
            <td className={classes.tableTitle}>ESTADO</td>
            <td>
              <Badge
                className={classes.badges}
                variant={getBadgeColor(orderData.status)}
              >
                {orderData.status}
              </Badge>
            </td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>
              {orderData.nickname === 'WORLDTRAVELANDFUN'
                ? 'AGENTE'
                : 'CREADA POR'}
            </td>
            <td>{getOrderCreator()}</td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>MONTO ORIGINAL</td>
            <td>{formatNumber(orderData.total)}</td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>FECHA DE CREACIÓN</td>
            <td>
              {moment(orderData.createdDate).format(
                'DD/MM/YYYY hh:mm a',
              )}
            </td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>ID KARLO</td>
            <td>{orderData.id}</td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>Tipo</td>
            <td>{renderOrderType(orderData.type)}</td>
          </tr>
        </tbody>
      </Table>
    );
  };

  const renderCardOrBalanceDetails = () => {
    if (
      getNested(
        null,
        props,
        'paymentOrders',
        'orderDetails',
        'provisional',
      )
    )
      return (
        <Paper className={classes.paperTarjeta}>
          <DialogTitle className={classes.title}>Balance</DialogTitle>
          {renderBalanceDetails()}
        </Paper>
      );
  };

  const renderBalanceDetails = () => {
    return (
      <Table bordered className={classes.tables}>
        <tbody>
          <tr>
            <td className={classes.tableTitle}>Total</td>
            <td>{formatNumber(orderData.total)}</td>
          </tr>
          {paymentsMiniCard}
          <tr>
            <td className={classes.tableTitle}>Saldo</td>
            <td>{formatNumber(balance)}</td>
          </tr>
        </tbody>
      </Table>
    );
  };

  const renderUserDetails = () => {
    return (
      <Table bordered className={classes.tables}>
        <tbody>
          <tr>
            <td className={classes.tableTitle}>
              NOMBRE O RAZÓN SOCIAL
            </td>
            <td>{orderData.clientFullName}</td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>CORREO</td>
            <td>{orderData.clientEmail}</td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>TELÉFONO CELULAR</td>
            <td>{orderData.clientPhoneNumber}</td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>
              {orderData.nickname === 'WORLDTRAVELANDFUN'
                ? 'TIPO DE UNIDAD'
                : 'VIN'}
            </td>
            <td>{orderData.vin}</td>
          </tr>
          {paymentOrderdetails.type === 'pulled_apart' && (
            <Fragment>
              <tr>
                <td className={classes.tableTitle}>ESTADO</td>
                <td>{paymentOrderdetails.stateCustomer || ''}</td>
              </tr>
              <tr>
                <td className={classes.tableTitle}>CIUDAD</td>
                <td>{paymentOrderdetails.cityCustomer || ''}</td>
              </tr>
              <tr>
                <td className={classes.tableTitle}>
                  MODELO DEL AUTO
                </td>
                <td>{paymentOrderdetails?.vehicle?.model || ''}</td>
              </tr>
              <tr>
                <td className={classes.tableTitle}>COLOR DEL AUTO</td>
                <td>{paymentOrderdetails?.vehicle?.color || ''}</td>
              </tr>
            </Fragment>
          )}
        </tbody>
      </Table>
    );
  };

  const handlerenderPaymentsDetails = () => {
    let paymentsToRenderTemp = [];
    let paymentsMiniCardTemp = [];
    let balanceTemp = getNested(
      0,
      paymentOrders,
      'orderDetails',
      'total',
    );
    const tempPayments = getNested(
      [],
      paymentOrders,
      'orderDetails',
      'payments',
    );
    if (
      tempPayments.length > 0 &&
      getNested(false, paymentOrders, 'orderDetails', 'provisional')
    ) {
      paymentOrderdetails.payments
        .filter((payment) => payment.status === 'DONE')
        .forEach((payment, index) => {
          balanceTemp -= payment.amount;
          paymentsMiniCardTemp.push(
            <tr key={`minicard-${index}`}>
              <td className={classes.tableTitle}>Pago {index + 1}</td>
              <td>{formatNumber(payment.amount)}</td>
            </tr>,
          );
          paymentsToRenderTemp.push(
            <Link
              key={`payment-detail-link${payment.id}`}
              to={`/detail-payment/${payment.id}`}
            >
              <Paper
                key={`payment-${index}`}
                className={classes.paperCliente}
              >
                <DialogTitle className={classes.title}>
                  Pago {index + 1}
                </DialogTitle>
                {payment.paymentMethod ? (
                  <Table bordered className={classes.tables}>
                    <thead>
                      <tr>
                        {payment.type === 'OXXO' ||
                        payment.type === 'SPEI' ? null : (
                          <>
                            <th>NOMBRE</th>
                            <th>NUM. TARJETA</th>
                            <th>NUM. DE TRANSACCIÓN</th>
                          </>
                        )}
                        <th>MÉTODO DE PAGO</th>
                        <th>FORMA DE PAGO</th>
                        <th>PROMOCIÓM MESES SIN INTERESES</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {payment.type === 'OXXO' ||
                        payment.type === 'SPEI' ? null : (
                          <>
                            <td>{payment.cardHolder}</td>
                            <td>{payment.last4}</td>
                            <td>{payment.authNumber}</td>
                          </>
                        )}
                        <td>
                          {intl.formatMessage({
                            id: `PAYMENT.TYPE.${payment.type}`,
                          })}
                        </td>
                        <td>{renderPaymentForm(payment.months)}</td>
                        <td>
                          {payment.promotionWI
                            ? 'Aplicada'
                            : 'No aplica'}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                ) : (
                  <Table bordered className={classes.tables}>
                    <thead>
                      <tr>
                        <th>MÉTODO DE PAGO</th>
                        <th>REFERENCIA</th>
                        <th>MONTO PAGADO</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {intl.formatMessage({
                            id: `PAYMENT.TYPE.${payment.type}`,
                          })}
                        </td>
                        <td>{payment.reference}</td>
                        <td>{formatNumber(payment.amount)}</td>
                      </tr>
                    </tbody>
                  </Table>
                )}
              </Paper>
            </Link>,
          );
        });
    }
    setBalance(balanceTemp);
    setPaymentsMiniCard(paymentsMiniCardTemp);
    setPaymentsToRender(paymentsToRenderTemp);
  };

  const renderUrlDetails = () => {
    const landingUrl = handleUrlLanding();
    return (
      <>
        <ClickAwayListener onClickAway={handleHideTooltip}>
          <div>
            <MuiThemeProvider theme={theme}>
              <Tooltip
                className="copied-url-tooltip"
                placement="top"
                onClose={handleHideTooltip}
                open={showTooltip}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="¡URL copiada al portapapeles!"
              >
                <Table
                  bordered
                  className={classes.tables}
                  onClick={handleCopyText}
                >
                  <tbody>
                    <tr>
                      <td className={classes.tableTitle}>URL</td>
                      <td>
                        {orderData.urlPayment
                          ? `${orderData.urlPayment.substring(
                              0,
                              75,
                            )}...`
                          : `${landingUrl}odp/${paymentOrderToken.substring(
                              0,
                              30,
                            )}...`}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Tooltip>
            </MuiThemeProvider>
          </div>
        </ClickAwayListener>
      </>
    );
  };

  return isEmpty(orderData) ? (
    <SplashScreen></SplashScreen>
  ) : (
    <div>
      {renderAdvanceModal()}
      {renderSendSmsDialog()}
      {renderSendWhatsDialog()}
      {renderSendMailDialog()}
      {renderCancelOrderDialog()}
      {renderPromotionsDialog()}
      {renderFeedBackModal()}
      {renderFeedBackModalnotifications()}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          <Paper className={classes.paper}>
            <DialogTitle className={classes.title}>
              Resumen de la orden
            </DialogTitle>
            {renderOrderDetails()}
          </Paper>
          {renderCardOrBalanceDetails()}
        </Grid>
        <Grid item xs={12} md={8} className={classes.rightContainer}>
          <>
            {(orderData.status === 'Por pagar' ||
              orderData.status === 'Pago provisional') && (
              <Paper className={classes.paperUrl}>
                <DialogTitle className={classes.title}>
                  URL DE PAGO
                </DialogTitle>
                {renderUrlDetails()}
              </Paper>
            )}
          </>
          <Paper className={classes.paperCliente}>
            <DialogTitle className={classes.title}>
              Cliente
            </DialogTitle>
            {renderUserDetails()}
          </Paper>
          <Products
            title={
              orderData.nickname === 'WORLDTRAVELANDFUN'
                ? 'Resort / Destino'
                : 'Productos'
            }
            products={orderData.operations}
          />
          {paymentsToRender}
          <Grid item>
            <RecentActivities logs={paymentOrdersLogs} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  paymentOrders: state.paymentOrders,
  notifications: state.notifications,
  paymentOrdersLogs: makeSelectPaymentOrdersLogs(state),
  agency: state.agencies.agencyDetails,
  paymentOrderdetails: makeSelectPaymentOrderDetailsFromState(state),
  role: makeSelectUserRoleFromState(state),
  paymentOrderToken: makeSelectPaymentOrderTokenFromState(state),
});

const mapDispatchToProps = {
  closeModals: closeModals,
  SendNotification: sendNotification,
  getPaymentOrderDetail: getPaymentOrderDetail,
  getPaymentsForOrderDetail: getPaymentsForOrderDetail,
  updatePaymentOrder: updatePaymentOrder,
  sendEmailLinkPaymentOrder: sendEmailLinkPaymentOrder,
  applyAdvancePaymentOrder: applyAdvancePaymentOrder,
  createOrUpdateSurtaxesForPaymentOrders,
  tokenizePaymentOrder,
};

export default withRouter(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(DetailPaymentOrder),
  ),
);
