import {
  getTerminalsSuccess,
  getTerminalsFail,
  getTerminalDetailSuccess,
  getTerminalDetailFail,
  createTerminalSuccess,
  createTerminalFail,
  deleteTerminalSuccess,
  deleteTerminalFail,
  updateTerminalSuccess,
  updateTerminalFail,
} from 'app/features/terminals/terminalsSlice.js';

import { requestHandler } from '../../services/requestHandler';
import { call, put, select } from 'redux-saga/effects';
import { makeSelectAgencyId } from '../auth/selectors.js';

import { errors } from './errors.js';

export function* getTerminalsSaga(action) {
  try {
    let page = 0;
    let pageSize = 20;
    let agencyId = '';
    let filtered;
    if (action) {
      page = action.payload.page;
      pageSize = action.payload.pageSize;
      filtered = action.payload.filtered;
      if (action && action.payload && action.payload.agencyId)
        agencyId = action.payload.agencyId;
      else agencyId = yield select(makeSelectAgencyId());
    }
    const _renderPage = pageSize * page;
    const data = {
      where: {},
      agencies: {
        options: {
          attributes: ['id', 'name'],
        },
      },
      offset: _renderPage,
      limit: pageSize,
      field: 'createdAt',
      ordering: 'DESC',
      search: [],
      filter: [],
    };
    if (agencyId) data.agencies.where = { id: agencyId };
    const terminals = yield call(requestHandler, {
      method: 'POST',
      path: '/terminal/getAll',
      data,
    });
    terminals.pages = yield call(
      Math.ceil,
      terminals.count / pageSize,
    );
    // console.log('success in terminals saga ', terminals);
    yield put(getTerminalsSuccess({ terminals }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba encontrar la información.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(getTerminalsFail({ message }));
    console.log('error in terminals saga', e);
  }
}

export function* createTerminalSaga(action) {
  const { agencyID, name, model, serialNumber } = action.payload;
  const agencyId = yield select(makeSelectAgencyId());
  let tempagencyID = agencyId ? agencyId : agencyID;
  console.log(tempagencyID);
  try {
    const data = {
      agencyID: tempagencyID,
      name,
      model,
      serialNumber,
    };
    const terminal = yield call(requestHandler, {
      method: 'POST',
      path: '/terminal/create',
      data,
    });
    const message = {
      title: '¡Éxito!',
      desc: `La terminal ha sido creada correctamente.`,
    };
    const redirect = '/terminals';
    yield put(
      createTerminalSuccess({
        message,
        redirect,
      }),
    );
    yield call(getTerminalsSaga);
  } catch (e) {
    let message = '';
    if (e.code === 463)
      message = {
        title: 'Error',
        desc: `La terminal ya existe, por favor elije otro`,
      };
    else if (e.code in errors)
      message = {
        title: 'Error',
        desc: errors[e.code],
      };
    else
      message = {
        title: 'Error',
        desc: `Ocurrió un error cuando se intentaba crear la terminal.
        Intente de nuevo, si el error persiste intente más tarde.`,
      };
    yield put(createTerminalFail({ message }));
    console.log('error in get order detail user saga', e);
  }
}

export function* deleteTerminalSaga(action) {
  try {
    const { terminalID, handleRefreshData } = action.payload;
    const data = {
      id: terminalID,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/terminal/remove',
      data,
    });
    const message = {
      title: 'Elimiado',
      desc: `La terminal se eliminó correctamente`,
    };
    yield put(deleteTerminalSuccess({ message }));
    yield call(getTerminalsSaga);
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Error al tratar de eliminar el usuario, por favor intenta más tarde`,
    };
    yield put(deleteTerminalFail({ message }));
    console.log('error', e);
  }
}

export function* updateTerminalSaga(action) {
  try {
    const { ...rest } = action.payload;
    console.log(action);
    const data = {
      ...rest,
    };
    console.log('edit data: ', data);
    yield call(requestHandler, {
      method: 'POST',
      path: '/terminal/updated',
      data,
    });
    const message = {
      title: 'Terminal actualizado',
      desc: `La terminal ha sido actualizada correctamente`,
    };
    yield put(updateTerminalSuccess({ message }));
    yield call(getTerminalsSaga);
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ha sucedido un error al tratar de actualizar la terminal, por favor intenta nuevamente`,
    };
    yield put(updateTerminalFail(message));
    console.log('error in update terminal saga', e);
  }
}

// export function* getTerminalDetailSaga(action) {
//   try {
//     const data = {
//       userId: action.payload.userID,
//     };
//     console.log(data);
//     const userDetail = yield call(requestHandler, {
//       method: 'POST',
//       path: '/karlo-app/getTerminalDetail',
//       data,
//     });
//     yield put(getTerminalDetailSuccess({ userDetail }));
//     console.log('success in users saga ', userDetail);
//   } catch (e) {
//     const message = {
//       title: 'Error',
//       desc: `Ocurrió un error cuando se intentaba encontrar la información del usuario.
//       Por favor, inténtelo de nuevo más tarde.`,
//     };
//     yield put(getTerminalDetailFail({ message }));
//     console.log('error in userDetail saga', e);
//   }
// }
