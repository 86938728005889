import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';

const calculatorSlice = createSlice({
  name: 'calculatorData',
  initialState,
  reducers: {
    getCalculatorData(state) {
      state.isLoading = true;
      state.fromdb = null;
    },
    getCalculatorDataSuccess(state, action) {
      state.isLoading = false;
      state.fromdb = action.payload;
    },
    getCalculatorDataFail(state, action) {
      state.isLoading = false;
      state.fromdb = null;
      state.errorModal = {
        show: true,
        message: action.payload.message,
      };
    },
  },
});

export const {
  getCalculatorData,
  getCalculatorDataSuccess,
  getCalculatorDataFail,
} = calculatorSlice.actions;

export default calculatorSlice.reducer;
