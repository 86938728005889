import { Badge, Table, Alert } from 'react-bootstrap';
import {
  DialogTitle,
  Grid,
  Paper,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
  getOfferDetail,
  handleCloseOfferModals,
} from 'app/features/karloOffers/karloOffersSlice';
import { makeSelectOfferDetails } from 'app/features/karloOffers/selectors';

import {
  formatDate,
  formatExpiricyDate,
  formatNumber,
  getBadgeColor,
  getOffersStatusName,
} from '../../../../utils';
import Modal from '../../../../components/Modal';
import SplashScreen from '../../../../partials/layout/SplashScreen';
import { selectAuth } from 'app/features/auth/selectors';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { selectKarloOffers } from 'app/features/karloOffers/selectors';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  paperTarjeta: {
    textAlign: 'left',
    marginTop: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  divider: {
    width: '100%',
  },
  title: {
    textAlign: 'left',
    color: '#1c1c1c',
  },
  content: {
    textAlign: 'left',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    margin: theme.spacing(1, 1, 1, 1),
  },
  menu: {
    width: 200,
  },
  margin: {
    margin: theme.spacing(1),
  },
  list: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    // backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  bigContainer: {
    display: 'flex',
  },
  leftContainer: {
    flexDirection: 'column',
  },
  rightContainer: {
    flexDirection: 'column',
  },
  item: {
    textAlign: 'left',
    justifyContent: 'space-between',
  },
  paperOrden: {
    width: '100%',
    textAlign: 'left',
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
  },
  paperCliente: {
    width: '100%',
    textAlign: 'left',
    marginTop: theme.spacing(2),
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
  },
  paperOps: {
    width: '100%',
    margin: theme.spacing(2, 0),
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
  },
  paperUrl: {
    width: '100%',
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
  },
  itemBlock: {
    display: 'flex',
  },
  item1: {
    textAlign: 'left',
  },
  item2: {
    textAlign: 'center',
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  tableTitle: {
    fontWeight: 401,
  },
  tables: {
    width: '100%',
  },
  media: {
    height: 60,
    width: '100%',
    position: 'relative',
  },
  feedBackStatus: {
    width: '50px',
    height: 'auto',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '30px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  inputs: {
    marginTop: theme.spacing(1),
  },
  select: {
    margin: theme.spacing(1, 0, 1, 0),
  },
}));

function OfferDetail(props) {
  const classes = useStyles();
  const { offerDetail, users } = props;
  const [vehicleData, setVehicleData] = useState([]);
  const [agenciesData, setAgenciesData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [dealData, setDealData] = useState([]);

  useEffect(() => {
    if (offerDetail == null) {
      console.log('es nill');
    } else {
      setDealData(offerDetail);
      setVehicleData(offerDetail?.car ? offerDetail.car : []);
      setAgenciesData(
        offerDetail?.agency?.dataKarpayAgency
          ? offerDetail.agency?.dataKarpayAgency
          : [],
      );
      setUserData(offerDetail?.user ? offerDetail.user : []);
    }
  }, [offerDetail]);

  useEffect(() => {
    props.setLeftSideComponent(
      <div className="kt-subheader__main">
        <div>
          <h3 className="kt-subheader__title">Detalle Oferta</h3>
        </div>
      </div>,
    );
    let data = {
      id: props.match.params.id,
    };
    props.getOfferDetail(data);
  }, []);

  function handleCloseModals() {
    props.handleCloseOfferModals();
  }

  const renderVehicleDetails = () => {
    return (
      <Paper className={classes.paper}>
        <DialogTitle className={classes.title}>
          Resumen del Vehículo
        </DialogTitle>

        <Table bordered className={classes.tables}>
          <tbody>
            <tr>
              <td className={classes.tableTitle}>ID</td>
              <td>{vehicleData.id ?? '-'}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>PLACAS</td>
              <td>{vehicleData.plates ?? '-'}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>VIN</td>
              <td>{vehicleData.vin ?? '-'}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>MARCA</td>
              <td>{vehicleData.brand ?? '-'}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>MODELO</td>
              <td>{vehicleData.model ?? '-'}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>AÑO</td>
              <td>{vehicleData.year ?? '-'}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>VERSIÓN</td>
              <td>{vehicleData.version ?? '-'}</td>
            </tr>
          </tbody>
        </Table>
      </Paper>
    );
  };

  const renderDealDetails = () => {
    return (
      <Paper className={classes.paper}>
        <DialogTitle className={classes.title}>Oferta</DialogTitle>
        <Table bordered className={classes.tables}>
          <tbody>
            <tr>
              <td className={classes.tableTitle}>ID</td>
              <td>{dealData.id ?? '-'}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>ESTATUS</td>
              <td>
                <Badge
                  className={classes.badges}
                  variant={getBadgeColor(dealData.status)}
                >
                  {getOffersStatusName(dealData.status) ?? '-'}
                </Badge>
              </td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>OFERTA</td>
              <td>{formatNumber(dealData.total) ?? '-'}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>
                FECHA DE VIGENCIA
              </td>
              <td>{formatDate(dealData.expiredAt) ?? '-'}</td>
            </tr>
          </tbody>
        </Table>
      </Paper>
    );
  };

  const renderAgencyDetails = () => {
    return (
      <Paper className={classes.paper}>
        <DialogTitle className={classes.title}>Agencia</DialogTitle>
        <Table bordered className={classes.tables}>
          <tbody>
            <tr>
              <td className={classes.tableTitle}>ID</td>
              <td>{agenciesData.id ?? '-'}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>NOMBRE</td>
              <td>{agenciesData.name ?? '-'}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>TELÉFONO</td>
              <td>{agenciesData.phoneNumber ?? '-'}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>EMAIL</td>
              <td>{agenciesData.email ?? '-'}</td>
            </tr>
          </tbody>
        </Table>
      </Paper>
    );
  };

  const renderUserDetails = () => {
    return (
      <Paper className={classes.paper}>
        <DialogTitle className={classes.title}>Cliente</DialogTitle>

        <Table bordered className={classes.tables}>
          <tbody>
            <tr>
              <td className={classes.tableTitle}>ID</td>
              <td>{userData.id ?? '-'}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>NOMBRE</td>
              <td>{userData.name ?? '-'}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>TELÉFONO CELULAR</td>
              <td>{userData.phoneNumber ?? '-'}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>EMAIL</td>
              <td>{userData.email ?? '-'}</td>
            </tr>
          </tbody>
        </Table>
      </Paper>
    );
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = props.offers;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message } = props.offers[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={handleCloseModals}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={handleCloseModals}
          />
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  return props.offers.isLoading ? (
    <SplashScreen />
  ) : (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          {renderVehicleDetails()}
          {renderAgencyDetails()}
          {renderFeedBackModal()}
        </Grid>
        <Grid item xs={12} md={8} className={classes.rightContainer}>
          {renderDealDetails()}
          {users?.user?.role == 'Super Admin' && renderUserDetails()}
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  offers: selectKarloOffers(state),
  offerDetail: makeSelectOfferDetails(state),
  users: selectAuth(state),
});

const mapDispatchToProps = {
  getOfferDetail,
  handleCloseOfferModals,
};

export default withRouter(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(OfferDetail),
  ),
);
