import { call, put, select } from 'redux-saga/effects';
import {
  getKarloOffersSuccess,
  getKarloOffersFail,
  getOfferVehiclesSuccess,
  getOfferVehiclesFail,
  createOfferSuccess,
  createOfferFail,
  getOfferDetailSuccess,
  getOfferDetailFail,
  handleCloseOfferModalsSuccess,
} from 'app/features/karloOffers/karloOffersSlice.js';

import { requestHandler } from '../../services/requestHandler';
import { makeSelectAgencyId } from '../auth/selectors.js';
import { errors } from './errors.js';
import moment from 'moment';

export function* getKarloOffersSaga(action) {
  try {
    let page = 0;
    let pageSize = 20;
    let filtered;
    if (action) {
      page = action.payload.page;
      pageSize = action.payload.pageSize;
      filtered = action.payload.filtered;
    }
    const _renderPage = pageSize * page;
    const data = {
      offset: _renderPage,
      limit: pageSize,
      field: 'createdAt',
      ordering: 'ASC',
    };
    const karloOffers = yield call(requestHandler, {
      method: 'POST',
      path: '/karlo-app/getOffers',
      data,
    });
    karloOffers.pages = yield call(
      Math.ceil,
      karloOffers.carDeals.length / pageSize,
    );
    console.log('success in karloOffers saga ', karloOffers);
    yield put(getKarloOffersSuccess({ karloOffers }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba encontrar la información.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(getKarloOffersFail({ message }));
    console.log('error in karloOffers saga', e);
  }
}

export function* getOfferVehiclesSaga(action) {
  try {
    let page = 0;
    let pageSize = 20;
    let filtered;
    if (action) {
      page = action.payload.page;
      pageSize = action.payload.pageSize;
      filtered = action.payload.filtered;
    }
    const _renderPage = pageSize * page;
    const data = {
      offset: _renderPage,
      limit: pageSize,
      field: 'id',
      ordering: 'ASC',
    };
    const offerVehicles = yield call(requestHandler, {
      method: 'POST',
      path: '/karlo-app/getCarsReceiveOffers',
      data,
    });
    offerVehicles.pages = yield call(
      Math.ceil,
      offerVehicles.cars.length / pageSize,
    );
    console.log('success in offerVehicles saga ', offerVehicles);
    yield put(getOfferVehiclesSuccess({ offerVehicles }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba encontrar la información.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(getOfferVehiclesFail({ message }));
  }
}

export function* createOfferSaga(action) {
  try {
    const offer = yield call(requestHandler, {
      method: 'POST',
      path: '/karlo-app/createCarDeal',
      data: action.payload,
    });
    const message = {
      title: '¡Éxito!',
      desc: `La oferta ha sido creada correctamente.`,
    };
    const redirect = '/offers';
    yield put(
      createOfferSuccess({
        message,
        redirect,
      }),
    );
    console.log('offer ', offer);
    yield call(getKarloOffersSaga);
  } catch (e) {
    let message = '';
    if (e.code === 463)
      message = {
        title: 'Error',
        desc: `La oferta ya existe, por favor elije otro`,
      };
    else if (e.code in errors)
      message = {
        title: 'Error',
        desc: errors[e.code],
      };
    else
      message = {
        title: 'Error',
        desc: `Ocurrió un error cuando se intentaba crear la oferta.
        Intente de nuevo, si el error persiste intente más tarde.`,
      };
    yield put(createOfferFail({ message }));
    console.log('error in get order detail user saga', e);
  }
}

export function* getOfferDetailSaga(action) {
  try {
    const data = {
      id: action.payload.id,
    };
    console.log('dya ', data);
    console.log(data);
    const offerDetails = yield call(requestHandler, {
      method: 'POST',
      path: '/karlo-app/getOfferById',
      data,
    });
    yield put(getOfferDetailSuccess({ offerDetails }));
    console.log('success in vehicles saga ', offerDetails);
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba encontrar la información de la oferta.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(getOfferDetailFail({ message }));
    console.log('error in vehicleDetail saga', e);
  }
}

export function* closeModalsOfferSaga(action) {
  try {
    yield put(handleCloseOfferModalsSuccess({}));
  } catch (error) {
    console.log('error in close modals correctly in saga', error);
  }
}
