const api = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'development':
      return 'http://localhost:8000/api/admin';
    case 'staging':
      return 'https://backend.karlo.io/api/admin';
    case 'production':
      return 'https://backend-production.karlo.io/api/admin';
    default:
      return 'http://localhost:8000/api/admin';
  }
};

export default api;
