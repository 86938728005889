import { createSelector } from 'reselect';
import { initialState } from './initialState';

export const selectPayments = (state) =>
  state.payments || initialState;

export const makeSelectPaymentsIsLoading = createSelector(
  selectPayments,
  (paymentsState) => paymentsState.isLoading,
);

export const makeSelectPaymentDetailsFromState = createSelector(
  selectPayments,
  (paymentsState) => paymentsState.paymentDetails,
);

export const makeSelectPaymentsSearched = createSelector(
  selectPayments,
  (paymentsState) => paymentsState.paymentsSearched,
);

export const makeSelectPaymentPdfData = createSelector(
  selectPayments,
  (paymentsState) => paymentsState.pdfData,
);
