/* eslint-disable react/display-name */
import { Badge, Table } from 'react-bootstrap';
import {
  DialogTitle,
  Grid,
  Paper,
  createMuiTheme,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { getDispersionsDetails } from '../../dispersionsSlice';
import {
  formatNumber,
  formatDate,
  getBadgeColor,
  getDispersionStatus,
  getNested,
  getDISPERSIONStatusName,
} from '../../../../utils';
import { isEmpty } from 'lodash';

import SplashScreen from '../../../../partials/layout/SplashScreen';
import { closeModals } from '../../../modals/modalsSlice';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { makeSelectPaymentOrderDetailsFromState } from 'app/features/dispersions/selectors';
import { makeSelectPaymentOrderTokenFromState } from 'app/features/tokenize/selectors';
import { makeSelectUserRoleFromState } from 'app/features/auth/selectors';
import moment from 'moment';
import { tokenizePaymentOrder } from 'app/features/tokenize/tokenizeSlice';
import { withRouter } from 'react-router';

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '1em',
        color: 'lightblue',
        backgroundColor: 'green',
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  paperTarjeta: {
    textAlign: 'left',
    marginTop: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  divider: {
    width: '100%',
  },
  title: {
    textAlign: 'left',
    color: '#1c1c1c',
  },
  content: {
    textAlign: 'left',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  inputs: {
    marginTop: theme.spacing(1),
  },
  rightContainer: {
    flexDirection: 'column',
  },
  item: {
    textAlign: 'left',
    justifyContent: 'space-between',
  },
  paperOrden: {
    width: '100%',
    textAlign: 'left',
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
  },
  paperCliente: {
    width: '100%',
    textAlign: 'left',
    marginTop: theme.spacing(2),
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
  },
  paperUrl: {
    width: '100%',
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
  },
  item1: {
    textAlign: 'left',
  },
  item2: {
    textAlign: 'center',
  },
  tableTitle: {
    fontWeight: 401,
  },
  tables: {
    width: '100%',
  },
}));

function DetailDispersion(props) {
  const classes = useStyles();
  const {
    paymentOrderdetails,
    getDispersionsDetails,
    tokenizePaymentOrder,
    setLeftSideComponent,
    match,
  } = props;
  const [orderData, setOrderData] = useState({});

  useEffect(() => {
    setLeftSideComponent(
      <div className="kt-subheader__main">
        <div>
          <h3 className="kt-subheader__title">Detalle Dispersión</h3>
        </div>
      </div>,
    );
    let data = {
      dispersionID: match.params.id,
    };
    getDispersionsDetails(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (paymentOrderdetails) {
      const orderData = paymentOrderdetails.findDispersion || {};
      const agencyData = orderData.agency || {};

      const cleanOrder = {
        id: orderData.id || 'n/a',
        status: getDISPERSIONStatusName(orderData.statusStp) || 'n/a',
        stpId: orderData.stpId || 'n/a',
        statusKP:
          getDISPERSIONStatusName(orderData.statusKarpay) || 'n/a',
        monto: orderData.monto ? orderData.monto : 'n/a',
        orderID: orderData.id || 'n/a',
        referenciaNumerica: orderData.referenciaNumerica || 'n/a',
        createdDate: orderData.createdAt || 'n/a',
        nickname: agencyData.name || 'n/a',
        claveRastreo: orderData.claveRastreo || 'n/a',
        centroCosto: orderData.centroCosto || 'n/a',
        conceptoPago: orderData.conceptoPago || 'n/a',
        cuentaBeneficiario: orderData.cuentaBeneficiario || 'n/a',
        cuentaOrdenante: orderData.cuentaOrdenante || 'n/a',
        folioOrigen: orderData.folioOrigen || 'n/a',
        institucionContraparte:
          orderData.institucionContraparte || 'n/a',
        institucionOperante: orderData.institucionOperante || 'n/a',
        nombreOrdenante: orderData.nombreOrdenante || 'n/a',
        rfcCurpBeneficiario: orderData.rfcCurpBeneficiario || 'n/a',
        nombreBeneficiario: orderData.nombreBeneficiario || 'n/a',
        rfcCurpOrdenante: orderData.rfcCurpOrdenante || 'n/a',
        tipoCuentaBeneficiario:
          orderData.tipoCuentaBeneficiario || 'n/a',
        tipoCuentaOrdenante: orderData.tipoCuentaOrdenante || 'n/a',
        clientPhoneNumber: orderData.phoneNumber || 'n/a',
        vin: orderData.vin || 'n/a',
        clientRFC: orderData.rfc || 'n/a',
        type: getNested(null, orderData, 'type'),
        payments: orderData.dispersionDetails || {},
        clientAccount: getNested(null, orderData, 'clientAccount'),
      };
      setOrderData(cleanOrder);
      tokenizePaymentOrder({
        reference: getNested(null, orderData, 'reference'),
        agencyID: getNested(null, orderData, 'agencyID'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentOrderdetails]);

  const renderDispersionDetails = () => {
    return (
      <Table bordered className={classes.tables}>
        <tbody>
          <tr>
            <td className={classes.tableTitle}>STP ID</td>
            <td>{orderData.stpId}</td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>
              ID DE LA DISPERSIÓN
            </td>
            <td>{orderData.id}</td>
          </tr>
          {orderData.clientAccount && (
            <tr>
              <td className={classes.tableTitle}>ID DE CLIENTE</td>
              <td>{orderData.clientAccount}</td>
            </tr>
          )}
          <tr>
            <td className={classes.tableTitle}>CLIENTE</td>
            <td>{orderData.nickname}</td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>ESTADO STP</td>
            <td>
              <Badge
                className={classes.badges}
                variant={getBadgeColor(orderData.status)}
              >
                {orderData.status}
              </Badge>
            </td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>ESTADO Karpay</td>
            <td>
              <Badge
                className={classes.badges}
                variant={getBadgeColor(orderData.statusKP)}
              >
                {orderData.statusKP}
              </Badge>
            </td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>
              REFERENCIA NUMERICA
            </td>
            <td>{orderData.referenciaNumerica}</td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>FOLIO ORIGEN</td>
            <td>{orderData.folioOrigen}</td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>FECHA DE CREACIÓN</td>
            <td>
              {moment(orderData.createdDate).format(
                'DD/MM/YYYY hh:mm a',
              )}
            </td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>
              FECHA DE LIQUIDACIÓN
            </td>
            <td>
              {moment(orderData.tsLiquidacion).format(
                'DD/MM/YYYY hh:mm a',
              )}
            </td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>CONCEPTO PAGO</td>
            <td>{orderData.conceptoPago}</td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>MONTO TOTAL</td>
            <td>{formatNumber(orderData.monto)}</td>
          </tr>
        </tbody>
      </Table>
    );
  };

  const renderUserDetails = () => {
    return (
      <Table bordered className={classes.tables}>
        <tbody>
          <tr>
            <td className={classes.tableTitle}>
              NOMBRE O RAZÓN SOCIAL
            </td>
            <td>{orderData.nombreBeneficiario}</td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>RFC</td>
            <td>{orderData.rfcCurpBeneficiario}</td>
          </tr>
        </tbody>
      </Table>
    );
  };

  const renderOrdenanteDetails = () => {
    return (
      <Table bordered className={classes.tables}>
        <tbody>
          <tr>
            <td className={classes.tableTitle}>
              NOMBRE O RAZÓN SOCIAL
            </td>
            <td>{orderData.nombreOrdenante}</td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>RFC</td>
            <td>{orderData.rfcCurpOrdenante}</td>
          </tr>
        </tbody>
      </Table>
    );
  };

  const renderPaymentsDetails = () => {
    return (
      <>
        <Table bordered className={classes.tables}>
          <tr key={'header'}>
            <td>
              <strong>Id de Pago</strong>
            </td>
            <td>
              <strong>Banco</strong>
            </td>
            <td>
              <strong>Total</strong>
            </td>
            <td>
              <strong>Fecha de pago</strong>
            </td>
            <td>
              <strong>Status</strong>
            </td>
          </tr>
          {orderData?.payments.length > 0 ? (
            orderData.payments.map((payment) => (
              // eslint-disable-next-line react/jsx-key
              <tbody>
                <tr>
                  <td>{payment.paymentId}</td>
                  <td>{payment.payment.bank}</td>
                  <td>{formatNumber(payment.payment.total)}</td>
                  <td>{formatDate(payment.payment.date)}</td>
                  <td>
                    <Badge
                      className={classes.badges}
                      variant={getBadgeColor(
                        getDispersionStatus(
                          payment.payment.wasDispersed,
                        ),
                      )}
                    >
                      {getDispersionStatus(
                        payment.payment.wasDispersed,
                      )}
                    </Badge>
                  </td>
                </tr>
              </tbody>
            ))
          ) : (
            <Typography>Sin resultados</Typography>
          )}
        </Table>
      </>
    );
  };

  return isEmpty(orderData) ? (
    <SplashScreen></SplashScreen>
  ) : (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          <Paper className={classes.paper}>
            <DialogTitle className={classes.title}>
              Resumen de la dispersión
            </DialogTitle>
            {renderDispersionDetails()}
          </Paper>
        </Grid>
        <Grid item xs={12} md={8} className={classes.rightContainer}>
          <Paper className={classes.paperCliente}>
            <DialogTitle className={classes.title}>
              Beneficiario
            </DialogTitle>
            {renderUserDetails()}
          </Paper>
          <Paper className={classes.paperCliente}>
            <DialogTitle className={classes.title}>
              Ordenante
            </DialogTitle>
            {renderOrdenanteDetails()}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Paper className={classes.paper}>
            <DialogTitle className={classes.title}>Pagos</DialogTitle>
            {renderPaymentsDetails()}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  paymentOrders: state.paymentOrders,
  notifications: state.notifications,
  agency: state.agencies.agencyDetails,
  paymentOrderdetails: makeSelectPaymentOrderDetailsFromState(state),
  role: makeSelectUserRoleFromState(state),
  paymentOrderToken: makeSelectPaymentOrderTokenFromState(state),
});

const mapDispatchToProps = {
  closeModals: closeModals,
  getDispersionsDetails: getDispersionsDetails,
  tokenizePaymentOrder,
};

export default withRouter(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(DetailDispersion),
  ),
);
