import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Divider,
  makeStyles,
} from '@material-ui/core';
import RecentActivities from '../../../../components/RecentActivities';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  commission: {
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: 'black',
  },
  tables: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  leyendsContainer: {
    padding: theme.spacing(1, 3, 4, 5),
  },
  card: {
    minWidth: 275,
    marginBottom: theme.spacing(2),
  },
  CardContent: {
    padding: theme.spacing(0, 3),
    '&:last-child': {
      paddingBottom: '0px',
    },
  },
  cardInnerContent: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  flex: {
    flex: '1',
  },
  flexToogle: {
    alignItems: 'flex-start',
  },
  items: {
    padding: theme.spacing(0),
  },
  textField: {
    width: '250px',
    marginBottom: '0px',
    marginTop: '0px',
    marginRight: theme.spacing(1),
  },
  imageBrands: {
    margin: theme.spacing(0, 0.2),
  },
  updateButton: {},
  intputButtonContainer: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
  },
  conditions: {
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
}));

const Activities = (props) => {
  const { agencies } = props;
  const classes = useStyles();
  const [promotions, setPromotions] = useState(null);
  const [agency, setAgency] = useState(null);
  const [adversiments, setAdversiments] = useState(null);

  useEffect(() => {
    const agenciesLogs = agencies.agencyDetails.logsSetup?.filter(
      (el) => el.type === 'UPDATEAGENCY',
    );
    const adeversimentsLogs = agencies.agencyDetails.logsSetup?.filter(
      (el) => el.type === 'UPDATEADVERTISEMENTS',
    );
    setLogsAgency(agenciesLogs);
    setLogsAdversiments(adeversimentsLogs);
    setLogsPromotions(agencies.agencyDetails.logs);
  }, []);

  const setLogsAgency = (logs) => {
    const newLogs = logs?.map((log) => {
      let { updatedBy, mail } = log.data;
      let date = log.updatedAt;
      let type = log.type;
      let text;
      text =
        `Se han actualizado las configuraciones de la agencia.` +
        ` El cambio se producio a las ${date} por el usuario ${updatedBy}, con correo ${mail}.`;
      return {
        date,
        text,
        type,
      };
    });
    setAgency(newLogs);
  };

  const setLogsAdversiments = (logs) => {
    const newLogs = logs?.map((log) => {
      let { updatedBy, mail } = log.data;
      let date = log.updatedAt;
      let type = log.type;
      let text;
      text =
        `Se han actualizado las configuraciones de los avisos.` +
        ` El cambio se producio a las ${date} por el usuario ${updatedBy}, con correo ${mail}.`;
      return {
        date,
        text,
        type,
      };
    });
    setAdversiments(newLogs);
  };

  const setLogsPromotions = (logs) => {
    const newLogs = logs?.map((log) => {
      let { date, typeOfPromotion, user } = log.data;
      let type = log.type;
      let text;

      if (type === 'CREATEPROMOTION') {
        text =
          `Se han agregado las configuraciones de las promociones para ${typeOfPromotion} ` +
          `de manera automatica. El cambio se producio a las ${date} por el usuario ${user}.`;
      } else {
        text =
          `Se han actualizado las configuraciones de las promociones para ${typeOfPromotion}, ` +
          `el cambio se producio a las ${date} por el usuario ${user}.`;
      }

      return {
        date,
        text,
        type,
      };
    });
    setPromotions(newLogs);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={12} className={classes.tables}>
          <Grid item xs={12} md={12}>
            <Typography variant="h6">DATOS DE AGENCIA</Typography>
            <RecentActivities logs={agency} />
          </Grid>
          <br />
          <Grid item xs={12} md={12}>
            <Typography variant="h6">PROMOCIONES</Typography>
            <RecentActivities logs={promotions} />
          </Grid>
          <br />
          <Grid item xs={12} md={12}>
            <Typography variant="h6">AVISOS</Typography>
            <RecentActivities logs={adversiments} />
          </Grid>
          <br />
          <Grid item xs={12} md={12}>
            <Typography variant="h6">COMISIONES</Typography>
            <RecentActivities />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  agencies: state.agencies,
});

const mapDispatchToProps = {};

export default withRouter(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(Activities),
  ),
);
