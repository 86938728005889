export const initialState = {
  user: null,
  authToken: null,
  successModal: {
    show: false,
    message: null,
  },
  errorModal: {
    show: false,
    message: null,
  },
  isLoading: false,
  error: null,
  agencyModal: false,
  agencies: null,
  selectAgencyModal: false,
  agencySelect: null,
  successLogin: false,
  failLogin: false,
  selectOneAgencyNotFound: false,
  superAdminAgenciesId: null,
};
