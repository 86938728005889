import 'react-table/react-table.css';

/* eslint-disable react/display-name */
import {
  Button,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect } from 'react'; //, { useEffect }

import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
// import { paymentsActions } from '../../actions';
// import moment from 'moment';
// import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  paper: {
    widht: '100%',
    marginBottom: theme.spacing(0),
  },
  button: {
    margin: theme.spacing(1),
  },
  media: {
    height: 60,
    width: '100%',
    position: 'relative',
  },
  image: {
    height: '100%',
    width: 'auto',
    position: 'absolute',
    top: '10%',
    left: '45%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function ReportsTable(props) {
  const classes = useStyles();
  const [showModal, setShowModal] = React.useState(false);
  // const [payments, setPayments] = React.useState([]);

  useEffect(() => {
    setShowModal(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (Array.isArray(props.payments.fromdb)) {
  //     const cleanPayments = props.payments.fromdb.map(payment => {
  //       return {
  //         id: payment.id,
  //         odsId: payment.orderID,
  //         date: moment(payment.createdAt).format(
  //           'DD/MM/YYYY hh:mm a',
  //         ),
  //         orderPrice: payment.total,
  //         comission: payment.comission,
  //         iva: payment.serviceOrder.iva,
  //         toDeposit: payment.serviceOrder.total,
  //         refNumber: payment.serviceOrderID,
  //       };
  //     });
  //     setPayments(cleanPayments);
  //   }
  // }, [props.payments.fromdb]);

  const handleCloseModals = () => {
    setShowModal(false);
    props.history.push('/dashboard');
  };

  const colums = [
    {
      Header: () => <strong>Id orden de servicio</strong>,
      // accessor: 'odsId',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value}
        </div>
      ),
    },
    {
      Header: () => <strong>Fecha de pago</strong>,
      // accessor: 'date',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value}
        </div>
      ),
    },
    {
      Header: () => <strong>Monto</strong>,
      // accessor: 'orderPrice',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value}
        </div>
      ),
    },
    {
      Header: () => <strong>Comisión</strong>,
      // accessor: 'comission',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value}
        </div>
      ),
    },
    {
      Header: () => <strong>IVA</strong>,
      // accessor: 'iva',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value}
        </div>
      ),
    },
    {
      Header: () => <strong>A depositar</strong>,
      // accessor: 'toDeposit',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value}
        </div>
      ),
    },
    {
      Header: () => <strong>Cod. de autorización</strong>,
      // accessor: 'refNumber',
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value}
        </div>
      ),
    },
    // {
    //   Header: '',
    //   Cell: row => (
    //     <div
    //       style={{
    //         textAlign: 'center',
    //       }}
    //     >
    //       <Link to={`/detail-payment/${row.original.id}`}>
    //         <Tooltip title={'DETALLES'}>
    //           <IconButton className={classes.button} size="small">
    //             <i className="far fa-eye"></i>
    //           </IconButton>
    //         </Tooltip>
    //       </Link>
    //     </div>
    //   ),
    //   filterable: false,
    // },
  ];

  const renderNotAllowed = () => {
    return (
      <div>
        <Dialog
          open={showModal}
          onClose={handleCloseModals}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            ¡No autorizado!
          </DialogTitle>
          <DialogContent>
            <CardMedia
              className={classes.media}
              title="Logo de agencia"
            >
              <img
                src={
                  'https://s3.us-east-2.amazonaws.com/karlov2/agencyLogos/15747187130717055402719803032.jpeg'
                }
                alt="logo"
                className={classes.image}
              />
            </CardMedia>
            <DialogContentText id="alert-dialog-description">
              Reportes no está disponible para la versión demo
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModals} color="primary">
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <Paper>
        <ReactTable
          NoDataComponent={() => (
            <div className="rt-noData">Sin resultados</div>
          )}
          columns={colums}
          filterable
          previousText="Anterior"
          nextText="Siguiente"
        />
      </Paper>
      {renderNotAllowed()}
    </div>
  );
}

// const mapStateToProps = state => ({
//   payments: state.payments,
// });

// const mapDispatchToProps = dispatch => ({
//   getPayments: () => dispatch(paymentsActions.getPayments()),
// });

export default withRouter(
  injectIntl(
    connect()(ReportsTable),
    // mapStateToProps,
    // mapDispatchToProps,
  ),
);
