import React from 'react';
import {
  ButtonBase,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Label from 'app/components/Label';
import { formatMoney } from 'app/utils';

const useStyles = makeStyles((theme) => ({
  headerColumns: {
    backgroundColor: '#D9DDDC',
  },
  titleTable: {
    fontWeight: 'bold',
    paddingTop: '5px',
    paddingBottom: '5px',
  },
}));

export const ReportDataTable = (props) => {
  const { selectedItem, setSelectedValue, tableData, visualMethod } =
    props;
  const classes = useStyles();

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow className={classes.headerColumns}>
            <TableCell size={'small'} align={'left'}>
              <Typography className={classes.titleTable}>
                Nombre
              </Typography>
            </TableCell>
            <TableCell size={'small'} align={'center'}>
              <Typography className={classes.titleTable}>
                Contado
              </Typography>
            </TableCell>
            {visualMethod === 'credit' && (
              <>
                <TableCell size={'small'} align={'center'}>
                  <Typography className={classes.titleTable}>
                    3 meses
                  </Typography>
                </TableCell>
                <TableCell size={'small'} align={'center'}>
                  <Typography className={classes.titleTable}>
                    6 meses
                  </Typography>
                </TableCell>
                <TableCell size={'small'} align={'center'}>
                  <Typography className={classes.titleTable}>
                    9 meses
                  </Typography>
                </TableCell>
                <TableCell size={'small'} align={'center'}>
                  <Typography className={classes.titleTable}>
                    12 meses
                  </Typography>
                </TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(tableData).map((key) => {
            return (
              <TableRow key={key}>
                <TableCell
                  style={{ background: '#E7E9ED' }}
                  size={'small'}
                  align={'left'}
                >
                  <Typography>
                    {tableData[key]
                      .filter((preCalculo) => preCalculo.type === 'Pago de contado')
                      .map((preCalculo) => (preCalculo.entity === 'Visa' ? 'Visa / Mastercard' : preCalculo.entity))}
                  </Typography>
                </TableCell>
                {tableData[key].map((dataItem) => {
                  return (
                    <TableCell
                      style={{
                        background: Object.is(selectedItem, dataItem)
                          ? '#e8f4fd'
                          : 'white',
                      }}
                      size={'small'}
                      align={'center'}
                    >
                      <ButtonBase
                        onClick={() => {
                          setSelectedValue(
                            Object.is(selectedItem, dataItem)
                              ? {}
                              : dataItem,
                          );
                        }}
                        focusRipple
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            style={{
                              marginRight: '5px',
                              color: Object.is(selectedItem, dataItem)
                                ? '#3d9ff5'
                                : 'black',
                              fontWeight: Object.is(
                                selectedItem,
                                dataItem,
                              )
                                ? 'bold'
                                : '',
                            }}
                          >
                            {dataItem.amount ? formatMoney(dataItem.amount) : 'N/A'}
                          </Typography>
                          {dataItem?.promotions && (
                            <Label
                              variant="contained"
                              color={{
                                background: '#108f30',
                                text: '#FFF',
                              }}
                            >
                              MSI
                            </Label>
                          )}
                        </div>
                      </ButtonBase>
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Alert severity="info">
        Da click en el monto de tu preferencia para ver la información
        desglosada.
      </Alert>
    </div>
  );
};
