import { closeModals } from '../modals/modalsSlice';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { logout } from '../auth/authSlice';

const terminalsSlice = createSlice({
  name: 'terminals',
  initialState,
  reducers: {
    getTerminals: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getTerminalsSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.fromdb = action.payload.terminals.terminals;
        state.tablePages = action.payload.terminals.pages;
      },
    },
    getTerminalsFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    createTerminal: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    createTerminalSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          ...action.payload,
        };
      },
    },
    createTerminalFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    deleteTerminal: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    deleteTerminalSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    deleteTerminalFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    getTerminalDetail: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getTerminalDetailSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.terminalDetail = action.payload.terminalDetail;
      },
    },
    getTerminalDetailFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    updateTerminal: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    updateTerminalSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    updateTerminalFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
  },
  extraReducers: {
    [logout]: (state, action) => {
      return initialState;
    },
  },
});

export const {
  getTerminals,
  getTerminalsSuccess,
  getTerminalsFail,
  getTerminalDetail,
  getTerminalDetailSuccess,
  getTerminalDetailFail,
  createTerminal,
  createTerminalSuccess,
  createTerminalFail,
  deleteTerminal,
  deleteTerminalSuccess,
  deleteTerminalFail,
  updateTerminal,
  updateTerminalSuccess,
  updateTerminalFail,
} = terminalsSlice.actions;

export default terminalsSlice.reducer;
