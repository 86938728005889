import { createSelector } from 'reselect';
import { getNested } from '../../utils';
import { initialState } from './initialState';

export const selectPaymentOrders = (state) =>
  state.dispersions || initialState;

export const makeSelectPaymentOrdersIsLoading = createSelector(
  selectPaymentOrders,
  (dispersionsState) => dispersionsState.isLoading,
);

export const makeSelectPaymentOrders = createSelector(
  selectPaymentOrders,
  (dispersionsState) => getNested([], dispersionsState, 'fromdb'),
);

export const makeSelectPaymentOrderDetailsFromState = createSelector(
  selectPaymentOrders,
  (dispersionsState) =>
    getNested([], dispersionsState, 'orderDetails'),
);
