export const initialState = {
  fromdb: null,
  tablePages: 0,
  advanceDetails: null,
  cancelAdvances: null,
  paymentsDetails: null,
  successModal: {
    show: false,
    message: null,
  },
  errorModal: {
    show: false,
    message: null,
  },
  isLoading: false,
  error: null,
};
