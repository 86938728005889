import { call, put, select } from 'redux-saga/effects';
import {
  createAgencyEventsFail,
  createAgencyEventsSuccess,
  createAgencyFail,
  createAgencySuccess,
  getAgenciesFail,
  getAgenciesSuccess,
  getAgencyFail,
  getAgencySuccess,
  getOrUpdateSurTaxesFail,
  getOrUpdateSurTaxesSuccess,
  updateAgencyEventsFail,
  updateAgencyEventsSuccess,
  updateAgencyFail,
  updateAgencySuccess,
  updateOptionFail,
  updateOptionSuccess,
  updloadLogoFail,
  upLoadAmountsSuccess,
  upLoadAmountsFail,
  updloadLogoSuccess,
  getAgenciesFiltersSuccess,
  getAgenciesFiltersFail,
  updateLineConfigSuccess,
  updateLineConfigFail,
  updateTemplateSuccess,
  updateTemplateFail,
  getOneAgencySuccess,
  getOneAgencyFail,
  createOrUpdateTypeOrdersSuccess,
  createOrUpdateTypeOrdersFail,
} from './agenciesSlice';
import {
  makeSelectAgencyId,
  makeSelectUserRole,
} from '../auth/selectors.js';

import { requestHandler } from '../../services/requestHandler';

export function* getAgenciesSaga(action) {
  try {
    const data = {
      pagination: { order: [['updatedAt', 'DESC']] },
    };
    const agencies = yield call(requestHandler, {
      method: 'POST',
      path: '/agency/getAll',
      data,
    });
    yield put(getAgenciesSuccess({ agencies }));
  } catch (e) {
    yield put(getAgenciesFail('getAgenciesSaga'));
    console.log('error in get agencies saga');
  }
}

export function* getAgenciesFiltersSaga(action) {
  try {
    const { filtered } = action.payload;
    let where = {};
    filtered.forEach((filter) => {
      where = {
        ...where,
        [`${filter.id}`]: { $iLike: `%${filter.value}%` },
      };
    });
    const data = {
      pagination: { order: [['updatedAt', 'DESC']] },
      where,
    };
    const agengiesFilter = yield call(requestHandler, {
      method: 'POST',
      path: '/agency/getAll',
      data,
    });
    yield put(getAgenciesFiltersSuccess({ agengiesFilter }));
  } catch (error) {
    yield put(getAgenciesFiltersFail('getAgenciesFiltersSaga'));
    console.log('error in get agencies filters saga');
  }
}

export function* createAgencySaga(action) {
  try {
    const data = {
      ...action.payload,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/agency/create',
      data,
    });
    const message = {
      title: '¡Éxito!',
      desc: `La agencia se creó correctamente`,
    };
    yield put(createAgencySuccess({ message }));
    yield call(getAgenciesSaga);
  } catch (e) {
    let message = '';
    if (e.code === 463)
      message = {
        title: 'Error',
        desc: `Ya existe una agencia con ese nickname o nombre o email.
        Intenta de nuevo cambiando alguno de esos datos.`,
      };
    else
      message = {
        title: 'Error',
        desc: `Ocurrió un error cuando se trataba de crear la agencia.
        Por favor, inténtelo de nuevo más tarde.`,
      };
    yield put(createAgencyFail({ message }));
    console.log('error in create agency saga', e);
  }
}

export function* updateAgencyCommissionsSaga(action) {
  const agencyId = yield select(makeSelectAgencyId());
  const { id, ...payload } = action.payload;
  try {
    const recordsToUpdate = action.payload;
    const agency = yield call(requestHandler, {
      method: 'POST',
      path: '/agencyCommissions/multi-update',
      data: recordsToUpdate,
    });

    const message = {
      title: '¡Éxito!',
      desc: `Los registros se actualizaron correctamente`,
    };

    yield put(updateAgencySuccess({ agency, message }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error al tratar de actualizar los registros.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(updateAgencyFail({ message }));
    console.log('error in update multiple records saga', e);
  }
}

export function* updateAgencySaga(action) {
  const agencyId = yield select(makeSelectAgencyId());
  let tempagencyID = agencyId ? agencyId : action.payload.id;
  const { id, ...payload } = action.payload;
  try {
    let data = {
      agencyId: tempagencyID,
    };
    if (action.payload.brands) {
      const currentBrands = yield call(requestHandler, {
        method: 'POST',
        path: '/brands/getAll',
        data,
      });
      let currentBrandsClean = [];
      currentBrands.result.map((cursor) => {
        currentBrandsClean.push(cursor.name);
      });
      const newBrands = action.payload.brands;
      let setNewBrands = [];
      let setDeleteBrands = [];
      newBrands.map((cursor) => {
        if (!currentBrandsClean.includes(cursor))
          setNewBrands.push(cursor);
      });
      currentBrandsClean.map((cursor) => {
        if (!newBrands.includes(cursor)) setDeleteBrands.push(cursor);
      });
      let brands = [];
      newBrands.map((cursor) => {
        brands.push(cursor.name);
      });
      let noEquals = false;
      if (currentBrandsClean.length === brands.length) {
        for (let i = 0; i < currentBrandsClean.length; ++i) {
          if (currentBrandsClean[i] === brands[i]) {
            noEquals = false;
          } else {
            noEquals = true;
            i = currentBrandsClean.length;
          }
        }
      } else {
        noEquals = true;
      }
      if (noEquals) {
        if (setNewBrands.length > 0) {
          data = {
            agencyId: tempagencyID,
            name: setNewBrands,
          };
          yield call(requestHandler, {
            method: 'POST',
            path: '/brands/create',
            data,
          });
        }
        if (setDeleteBrands.length > 0) {
          data = {
            agencyId: tempagencyID,
            name: setDeleteBrands,
          };
          yield call(requestHandler, {
            method: 'POST',
            path: '/brands/deletes',
            data,
          });
        }
      }
      payload.brands = [];
    }
    data = {
      ...payload,
      id: tempagencyID,
    };
    if (data?.isConsolidatedPayment === 'null') {
      data.isConsolidatedPayment = null;
    }
    const agency = yield call(requestHandler, {
      method: 'POST',
      path: '/agency/update',
      data,
    });
    const message = {
      title: '¡Éxito!',
      desc: `La información de agencia se actualizó correctamente`,
    };
    yield put(updateAgencySuccess({ agency, message }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba actualizar la
      agencia. Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(updateAgencyFail({ message }));
    console.log('error in update agency saga', e);
  }
}

export function* updateOptionAgencySaga(action) {
  try {
    const data = {
      ...action.payload,
    };
    if (data.whatsNotifications) {
      const activeLine = yield call(requestHandler, {
        method: 'POST',
        path: '/gupshupLines/createOrUpdate',
        data: { agencyID: data.id },
      });
    }
    const agency = yield call(requestHandler, {
      method: 'POST',
      path: '/agency/update',
      data,
    });
    const message = {
      title: '¡Éxito!',
      desc: `La opación de la agencia se actualizó correctamente`,
    };
    yield put(updateOptionSuccess({ agency, message }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se trataba de actualizar la opción de la agencia.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(updateOptionFail({ message }));
    console.log('error in update agency saga', e);
  }
}

export function* getAgencySaga(action) {
  try {
    const agencyId = yield select(makeSelectAgencyId());
    const tempagencyID = agencyId
      ? agencyId
      : action.payload.agencyID;
    const role = yield select(makeSelectUserRole());
    const data = {
      where: { id: tempagencyID },
      wsUsers: true,
      agencyEvents: true,
      addresses: {
        options: {
          attributes: [
            'street',
            'colony',
            'ext',
            'postalCode',
            'city',
            'state',
          ],
        },
      },
      agencyGeneralAddresses: {
        options: {
          attributes: [
            'street',
            'colony',
            'ext',
            'postalCode',
            'city',
            'state',
          ],
        },
      },
      surTaxes: {
        options: {
          attributes: [
            'id',
            '3',
            '6',
            '9',
            '12',
            '18',
            'typeOfPromotion',
          ],
        },
      },
      AMEXSurTaxes: {
        options: {
          attributes: [
            'id',
            '3',
            '6',
            '9',
            '12',
            '18',
            'typeOfPromotion',
          ],
        },
      },
    };
    const path =
      role === 'Super Admin'
        ? '/agency/getOne'
        : '/agency/getMyAgency';
    const agency = yield call(requestHandler, {
      method: 'POST',
      path,
      data,
    });
    const settingAmounts = yield call(requestHandler, {
      method: 'POST',
      path: '/settingAmountsPerAgencies/getAll',
      data: { agencyID: tempagencyID },
    });
    const { output } = yield call(requestHandler, {
      method: 'POST',
      path: '/typeOrdersByAgency/get-by-agency',
      data: { agencyID: tempagencyID },
    });
    agency.settingAmounts = settingAmounts?.settingAmountsPerAgency;
    agency.typeOrders = output;
    yield put(getAgencySuccess({ agency }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se trataba obtener la información de la agencia.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(getAgencyFail({ message }));
    console.log('error in get detail agency saga', e);
  }
}

export function* uploadLogoSaga(action) {
  try {
    const url = yield call(requestHandler, {
      method: 'POST',
      path: '/agencyLogo/upload',
      contenType: 'multipart/form-data',
      data: action.payload,
    });
    yield put(updloadLogoSuccess({ url }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se trataba subir el logo.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(updloadLogoFail({ message }));
    console.log('error in upload logo saga', e);
  }
}

export function* updateLineConfigSaga(action) {
  try {
    const update = yield call(requestHandler, {
      method: 'POST',
      path: '/gupshupLines/update',
      data: action.payload,
    });
    const message = {
      title: 'Se actualizaron las configuraciones',
      desc: `Se actualizaron las configuraciones de la linea con exito.`,
    };
    yield put(updateLineConfigSuccess({ message }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: e.message,
    };
    yield put(updateLineConfigFail({ message }));
    console.log('error in updateLineConfigSaga', e);
  }
}

export function* updateTemplateSaga(action) {
  try {
    const update = yield call(requestHandler, {
      method: 'POST',
      path: '/gupshupTemplates/update',
      data: action.payload,
    });
    const message = {
      title: 'Se actualizaron las configuraciones',
      desc: `Se actualizaron las configuraciones de la linea con exito.`,
    };
    yield put(updateTemplateSuccess({ message }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: e.message,
    };
    yield put(updateTemplateFail({ message }));
    console.log('error in updateTemplateSaga', e);
  }
}

export function* updateAmounstSaga(action) {
  try {
    let message = '';
    if (action.payload.all && action.payload.all.applyAll) {
      yield call(requestHandler, {
        method: 'POST',
        path: '/settingAmountsPerAgencies/update',
        data: action.payload.all,
      });
      message = {
        title: 'Se actalizaron todos los montos',
        desc: `Todos los montos fueron actualizados a $${action.payload.all.maximumAmountCardPayment} de manera exitosa.`,
      };
    } else {
      const newAmounts = action.payload;
      yield* newAmounts.map(function* (amount) {
        try {
          let newAmount;
          for (const [key, value] of Object.entries(amount)) {
            newAmount = value;
          }
          yield call(requestHandler, {
            method: 'POST',
            path: '/settingAmountsPerAgencies/update',
            data: newAmount,
          });
        } catch (error) {
          const message = {
            title: 'Error',
            desc: `Ocurrió un error cuando se trataba de actualizar los mosntos.
            Por favor, inténtelo de nuevo más tarde.`,
          };
          yield put(upLoadAmountsFail({ message }));
          console.log('error in upload amounts saga', error);
        }
      });
    }
    message = {
      title: 'Se actalizaron todos los montos',
      desc: `Los montos fueron actualizados de manera exitosa.`,
    };
    yield put(upLoadAmountsSuccess({ message }));
  } catch (error) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se trataba de actualizar los mosntos.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(upLoadAmountsFail({ message }));
    console.log('error in upload amounts saga', error);
  }
}

export function* getOrUpdateSurTaxesSaga(action) {
  try {
    const { amex, visa } = action.payload;
    yield call(requestHandler, {
      method: 'POST',
      path: '/surTax/update',
      data: visa,
    });
    yield call(requestHandler, {
      method: 'POST',
      path: '/AMEXSurTax/update',
      data: amex,
    });
    const message = {
      title: 'Promocion actualizada',
      desc: `La promoción ha sido actualizada correctamente`,
    };
    yield put(getOrUpdateSurTaxesSuccess({ message }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se trataba de actualizar la promoción.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(getOrUpdateSurTaxesFail({ message }));
    console.log('error in upload SurTaxes saga', e);
  }
}

export function* createAgencyEventsSaga(action) {
  try {
    const data = {
      ...action.payload,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/agencyEvents/create',
      data,
    });
    const message = {
      title: 'Aviso actualizado',
      desc: `El aviso ha sido actualizada correctamente`,
    };
    yield put(createAgencyEventsSuccess({ message }));
    yield call(getAgencySaga, {});
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se trataba de actualizar el aviso.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(createAgencyEventsFail({ message }));
    console.log('error in upload agencyEvents', e);
  }
}

export function* updateAgencyEventsSaga(action) {
  try {
    const data = {
      ...action.payload,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/agencyEvents/update',
      data,
    });
    const message = {
      title: 'Aviso actualizado',
      desc: `El aviso ha sido actualizada correctamente`,
    };
    yield put(updateAgencyEventsSuccess({ message }));
  } catch (error) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se trataba de actualizar el aviso.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    console.log('ERROR IN UPLOAD AGENCY EVENTS ->', error);
    yield put(updateAgencyEventsFail({ message }));
  }
}

export function* getOneAgencySaga(action) {
  try {
    const data = action.payload;
    const agency = yield call(requestHandler, {
      method: 'POST',
      path: '/agency/getById',
      data,
    });
    yield put(getOneAgencySuccess({ agency }));
  } catch (error) {
    console.log('ERROR IN GET ONE AGENCY ->', error);
    yield put(getOneAgencyFail({ message: error }));
  }
}

export function* updateAgencyMembershipSaga(action) {
  try {
    const data = {
      ...action.payload,
    };
    const response = yield call(requestHandler, {
      method: 'POST',
      path: '/agencyByMemberships/create-or-update',
      data,
    });
    const message = {
      title: 'Agencia actualizada',
      desc: `Se ha actualizado la sobretasa`,
    };
    yield put(updateAgencySuccess({ message }));
  } catch (error) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se trataba de actualizar la sobretasa.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    console.log('ERROR IN UPLOAD AGENCY EVENTS ->', error);
    yield put(updateAgencyEventsFail({ message }));
  }
}

export function* updateTemplateByIdSaga(action) {
  try {
    const data = {
      ...action.payload,
    };
    const response = yield call(requestHandler, {
      method: 'POST',
      path: '/gupshupTemplates/updateById',
      data,
    });
    const message = {
      title: 'Plantilla actualizada',
      desc: `Se ha actualizado la plantilla`,
    };
    yield put(updateTemplateSuccess({ message }));
  } catch (error) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se trataba de actualizar la plantilla.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    console.log('ERROR IN UPLOAD AGENCY EVENTS ->', error);
    yield put(updateTemplateFail({ message }));
  }
}

export function* createOrUpdateTypeOrdersSaga(action) {
  try {
    const data = {
      ...action.payload,
    };

    yield call(requestHandler, {
      method: 'POST',
      path: '/typeOrdersByAgency/create-or-update',
      data,
    });
    yield put(createOrUpdateTypeOrdersSuccess());
  } catch (error) {
    console.log('ERROR IN GET TYPE ORDER BY AGENCY ID ->', error);
    yield put(createOrUpdateTypeOrdersFail({ message: error }));
  }
}
