import {
  Button,
  CircularProgress,
  Backdrop,
  Grid,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { selectKarloAdvices } from 'app/features/karloAdvices/selectors';
import Modal from '../../../../components/Modal';

import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';

import Input from 'app/components/Input';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  createTip,
  handleCloseTipsModals,
} from '../../../karloAdvices/karloAdvicesSlice';
import { injectIntl } from 'react-intl';
import { selectAuth } from 'app/features/auth/selectors';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(5, 5, 3, 5),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5, 5, 3, 5),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(5, 10, 3, 10),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(5, 10, 3, 10),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.5) !important',
  },
  title: {
    textAlign: 'left',
    color: '#1c1c1c',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  switch: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textFieldAddProducts: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    width: '50%',
  },
  margin: {
    margin: theme.spacing(1),
  },
  client: {
    padding: theme.spacing(0),
    marginTop: theme.spacing(1),
  },
  productsContainer: {
    marginBottom: theme.spacing(3),
  },
  submitButtonWrapper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  formGroupSwitch: {
    alignItems: 'center',
  },
}));

function AddAdvice(props) {
  const classes = useStyles();
  const { createTip, users } = props;
  const [thumbnailImage, setThumbnailImage] = useState('');
  const [descriptionImage, setDescriptionImage] = useState('');
  const { handleSubmit, register, errors, control, watch } = useForm({
    defaultValues: {
      provisional: false,
    },
  });

  function handleCloseTipsModals() {
    props.handleCloseTipsModals();
    props.history.push('/advices');
  }

  const handleThumbnailChangeStatus = ({ meta, file }, status) => {
    if (status == 'done') {
      setThumbnailImage(file);
    }
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = props.advices;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message } = props.advices[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={handleCloseTipsModals}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={handleCloseTipsModals}
          />
        );
      }
      return null;
    } catch (e) {
      handleCloseTipsModals();
      return null;
    }
  };

  const handleDescriptionChangeStatus = ({ meta, file }, status) => {
    if (status == 'done') {
      setDescriptionImage(file);
    }
  };

  const handleExtraValidation = (formData) => {
    const userId = users?.user?.id;
    createTip({
      ...formData,
      createdByUserId: userId,
      thumbnailImg: thumbnailImage,
      descriptionImg: descriptionImage,
    });
  };

  return (
    <div>
      {renderFeedBackModal()}
      {props.advices.isLoading && (
        <Backdrop
          className={classes.backdrop}
          open={props.advices.isLoading}
        >
          <CircularProgress />
        </Backdrop>
      )}
      <Paper className={classes.paper}>
        <form
          onSubmit={handleSubmit(handleExtraValidation)}
          noValidate
          autoComplete="off"
        >
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.client}>
              <Typography variant="h6">
                Información del consejo
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                required
                ref={register({
                  required: true,
                })}
                name="title"
                label={'Titulo de consejo'}
                className={classes.textField}
                error={Boolean(errors.reference)}
                // helperText="Introduce el número de referencia"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                required
                ref={register({
                  required: true,
                })}
                name="subtitle"
                label={'Subtitulo de consejo'}
                className={classes.textField}
                error={Boolean(errors.reference)}
                // helperText="Introduce el número de referencia"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Input
                required
                ref={register({
                  required: true,
                })}
                name="description"
                label={'Descripción de consejo'}
                className={classes.textField}
                error={Boolean(errors.reference)}
                // helperText="Introduce el número de referencia"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.client}>
              <Typography variant="h6" gutterBottom>
                {'Archivos adjuntos'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Input
                ref={register({
                  required: false,
                })}
                name="videoUrl"
                label={'Video'}
                className={classes.textField}
                error={Boolean(errors.reference)}
              />
              <p className="text-align-dialog">
                Solamente se aceptan imágenes JPEG, PNG y SVG de
                máximo 5mb
              </p>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <div className={classes.buttonGroup}>
                  <p className="text-align-dialog">
                    Cargar una imagen para el thumbnail
                  </p>
                  <Dropzone
                    // getUploadParams={getUploadParams}
                    onChangeStatus={handleThumbnailChangeStatus}
                    maxFiles={1}
                    submitButtonContent={null}
                    styles={{
                      dropzone: { minHeight: 200, maxHeight: 250 },
                    }}
                    // onSubmit={handleSubmitDropzone}
                    accept="image/*"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={classes.buttonGroup}>
                  <p className="text-align-dialog">
                    Cargar una imagen para el consejo
                  </p>
                  <Dropzone
                    // getUploadParams={getUploadParams}
                    onChangeStatus={handleDescriptionChangeStatus}
                    maxFiles={1}
                    submitButtonContent={''}
                    styles={{
                      dropzone: { minHeight: 200, maxHeight: 250 },
                    }}
                    // onSubmit={handleSubmitDropzone}
                    accept="image/*"
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.submitButtonWrapper}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={props.advices.isLoading}
            >
              Guardar consejo
            </Button>
          </div>
        </form>
      </Paper>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  advices: selectKarloAdvices,
  users: selectAuth,
});

const mapDispatchToProps = {
  // createPaymentOrder: createPaymentOrder,
  handleCloseTipsModals,
  createTip,
};

export default withRouter(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(AddAdvice)),
);
