export const initialState = {
  fromdb: null,
  tablePages: 0,
  orderDetails: null,
  invoicePDF: null,
  isLoading: false,
  successModal: {
    show: false,
    message: null,
  },
  errorModal: {
    show: false,
    message: null,
  },
  error: null,
  quiterModal: {
    type: null,
    show: false,
    message: null,
  },
};
