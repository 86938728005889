import React from 'react';
import { toAbsoluteUrl } from '../../../_metronic';

import '../../../_metronic/_assets/sass/pages/error/error-1.scss';
import { useStyles } from './styles';

import {
  Typography,
  useTheme,
  useMediaQuery,
  Paper,
} from '@material-ui/core';

import karloLogo from 'app/assets/img/logos/Logo_white_back.png';
import brokeLink from 'app/assets/img/animations/brokeLink.json';

import Lottie from 'lottie-react';

const ExpiredLinks = () => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <div className={classes.root}>
        <div
          style={{
            height: '100%',
            backgroundImage: `url(${toAbsoluteUrl(
              '/media/error/bg1.jpg',
            )})`,
          }}
        >
          <img
            src={karloLogo}
            alt="logo"
            className={classes.karloLogo}
          />
          <Paper
            className={classes.cardContainer}
            style={{
              width: mobileDevice ? '90%' : '40%',
            }}
          >
            <div className={classes.imageContainer}>
              <Lottie animationData={brokeLink} />
            </div>
            <Typography
              className={classes.errorTitle}
              align="center"
              variant="h5"
            >
              Estimado cliente, la liga de pago que esta intentando
              abrir ha expirado
            </Typography>
            <Typography
              className={classes.errorText}
              align="center"
              variant="subtitle2"
            >
              Favor de comunicarse con su asesor para que este le haga
              llegar una nueva y pueda realizar su pago, gracias.
            </Typography>
          </Paper>
        </div>
      </div>
    </>
  );
};

export default ExpiredLinks;
