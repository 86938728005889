import { createSelector } from 'reselect';
import { initialState } from './initialState';

export const selectAuth = (state) => state.auth || initialState;

export const makeSelectUserRole = () =>
  createSelector(selectAuth, (authState) => authState.user.role);

export const makeSelectModalToGetAllAgencies = createSelector(
  selectAuth,
  (authState) => authState.agencyModal,
);

export const makeSelectModalToGetOneAgency = createSelector(
  selectAuth,
  (authState) => authState.selectAgencyModal,
);

export const makeSelectModalToSuccessLogin = createSelector(
  selectAuth,
  (authState) => authState.successLogin,
);

export const makeSelectModalToGetOneAgencyFail = createSelector(
  selectAuth,
  (authState) => authState.selectOneAgencyNotFound,
);

export const makeSelectModalToFailLogin = createSelector(
  selectAuth,
  (authState) => authState.failLogin,
);

export const makeSelectModalToGetOneAgencyInfo = createSelector(
  selectAuth,
  (authState) => authState.agencySelect,
);

export const makeSelecAllAgencies = createSelector(
  selectAuth,
  (authState) => authState.agencies,
);

export const makeSelectUserId = () =>
  createSelector(selectAuth, (authState) => authState.user.id);

export const makeSelectUserRoleFromState = createSelector(
  selectAuth,
  (authState) => authState.user.role,
);

export const makeCreationByAgency = createSelector(
  selectAuth,
  (authState) => authState.user.createManuallyOdp,
);

export const makeSelectAgencyId = () =>
  createSelector(selectAuth, (authState) => authState.user.agencyID);

export const makeSelectAgencyIdFromState = createSelector(
  selectAuth,
  (authState) => authState.user.agencyID,
);

export const makeSelectSuperAdminAgenciesId = createSelector(
  selectAuth,
  (authState) => authState?.superAdminAgenciesId,
);
