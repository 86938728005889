import 'react-table/react-table.css';

/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';

import {
  Paper,
  Tab,
  Tabs,
  makeStyles,
  Button,
  IconButton,
  Typography,
} from '@material-ui/core';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import PaymentIcon from '@material-ui/icons/Payment';
import Close from '@material-ui/icons/Close';
import GetApp from '@material-ui/icons/GetApp';

import {
  formatDate,
  formatNumber,
  getBadgeColor,
  getColumnWidth,
  getNested,
  getPOStatusName,
} from '../../../../utils';
import MUIDatePickerFilter from 'app/components/MUIDatePickerFilter';
import { MenuOption } from 'app/components/MenuOption';
import ShouldItRender from '../../../../components/ShouldItRender';
import Modal from '../../../../components/Modal';

import { getAgency } from 'app/features/agencies/agenciesSlice';
import {
  getAdvances,
  getCancelAdavancesSuccess,
  getCancelAdvances,
} from '../../advancesSlice';
import {
  makeSelectAdvances,
  makeSelectCancelAdvances,
  selectAdvances,
} from '../../selectors';
import { makeSelectAgencyDetailsFromState } from '../../../agencies/selectors';
import { makeSelectUserRoleFromState } from '../../../auth/selectors';

import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { injectIntl } from 'react-intl';
import { CSVLink } from 'react-csv';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(0),
  },
  datePickerTextfield: {
    padding: '5px 5px 5px !important',
    height: '20px',
  },
  range: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: '-30px',
  },
  datePicker: {
    ['@media screen and (max-width: 1496px)']: {
      // eslint-disable-line no-useless-computed-key
      width: '70px',
    },
    ['@media screen and (max-width: 1024px)']: {
      // eslint-disable-line no-useless-computed-key
      width: '90px',
    },
    width: '90px',
  },
  tapStyle: {
    width: '120px',
  },

  icons: {
    color: '#0abb87',
    marginRight: '10px',
  },
  colorPrimary: {
    color: '#0abb87',
  },
  menuDesktop: {
    ['@media screen and (max-width: 1452px)']: {
      // eslint-disable-line no-useless-computed-key
      display: 'none',
    },
  },
  menuMobile: {
    ['@media screen and (min-width: 1452px)']: {
      // eslint-disable-line no-useless-computed-key
      display: 'none',
    },
  },
}));

function AdvancesTable(props) {
  const classes = useStyles();
  const {
    advances,
    cancelAdvances,
    advancesFromdb,
    agencyDetail,
    role,
    getAgency,
    setActionButton,
    location,
    history,
    setLeftSideComponent,
    getAdvances,
    getCancelAdvances,
    getCancelAdavancesSuccess,
    fullOrders,
  } = props;
  const reactTable = React.useRef(null);
  const [tabSelected, setTabSelected] = useState(0);
  const advancesToBring = [getAdvances, getCancelAdavancesSuccess];
  const advancesSelector = [advancesFromdb, cancelAdvances];
  const orders = advancesSelector[tabSelected];
  const [dataToCSV, setDataToCSV] = React.useState([]);
  const [firstDate, setFirstDate] = useState(null);
  const [secondDate, setSecondDate] = useState(null);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(20);
  const [sorted, setSorted] = React.useState([]);
  const [filtered, setFiltered] = React.useState('');
  const [nameFile, setNameFile] = React.useState(
    'Anticipos-Refacciones.csv',
  );
  const [showDateErrorModal, setShowDateErrorModal] = useState(false);
  const [closeDateErrorModal, setCloseDateErrorModal] = useState(
    false,
  );
  const [disableDownloadCSV, setDisableDownloadCSV] = useState(false);

  useEffect(() => {
    handleFetchData();
  }, [firstDate, secondDate]);

  useEffect(() => {
    if (role !== 'Super Admin') getAgency();
  }, [getAgency, role]);

  useEffect(() => {
    const CSV = [];
    let searchIn;
    !Array.isArray(fullOrders)
      ? (searchIn = fullOrders.rows)
      : (searchIn = orders);
    if (searchIn) {
      searchIn.forEach((cursor) => {
        let payDate;
        cursor.payments.length > 0
          ? (payDate = moment(cursor.payments[0].createdAt).format(
              'DD/MM/YYYY HH:MM',
            ))
          : (payDate = '-');
        const data = {
          // prettier-ignore
          'ID de Cliente': cursor.clientAccount || '-',
          // prettier-ignore
          'VIN': cursor.vin || '-',
          // prettier-ignore
          'Nombre de Cliente': cursor.nombre || '-',
          // prettier-ignore
          'Fecha de creación': moment(cursor.createdAt).format('DD/MM/YYYY HH:MM') || '-',
          // prettier-ignore
          'Fecha pago': payDate || '-',
          // prettier-ignore
          'Total': formatNumber(cursor.total) || '-',
          // prettier-ignore
          'Estatus': cursor.status || '-',
        };
        CSV.push(data);
      });
      setDataToCSV(CSV);
    }
    tabSelected === 0
      ? setNameFile('Anticipos-Refacciones.csv')
      : setNameFile('Anticipos-Refacciones-Canceladas.csv');
  }, [orders, tabSelected, filtered]);

  useEffect(() => {
    if (firstDate && secondDate && firstDate > secondDate) {
      setShowDateErrorModal(true);
      setDisableDownloadCSV(true);
      renderFeedBackModal();
    } else {
      setDisableDownloadCSV(false);
    }
  }, [firstDate, secondDate]);

  useEffect(() => {
    setLeftSideComponent(
      <Tabs
        value={tabSelected}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChangeTab}
      >
        <Tab label="Anticipos Refacciones" />
        <Tab label="Anticipos Refacciones Cancelados" />
      </Tabs>,
    );
    setActionButton(
      <>
        <MuiPickersUtilsProvider utils={MomentUtils} locale={'es'}>
          <DatePicker
            id="mui-pickers-date"
            autoOk
            disableToolbar
            disableFuture
            variant="inline"
            label="Inicial"
            format="DD/MM"
            className={classes.datePicker}
            value={firstDate}
            onChange={handleFirstDate}
            InputProps={{
              endAdornment: firstDate ? (
                <IconButton
                  aria-label="Select locale"
                  size="small"
                  onClick={handleClearFirstDate}
                >
                  <Close />
                </IconButton>
              ) : null,
            }}
          />
          <span className={classes.range}>&nbsp;A&nbsp;</span>
          <DatePicker
            id="mui-pickers-date"
            autoOk
            disableToolbar
            disableFuture
            variant="inline"
            label="Final"
            format="DD/MM"
            className={classes.datePicker}
            value={secondDate}
            onChange={handleSecondDate}
            InputProps={{
              endAdornment: secondDate ? (
                <IconButton
                  aria-label="Select locale"
                  size="small"
                  onClick={handleClearSecondDate}
                >
                  <Close />
                </IconButton>
              ) : null,
            }}
          />
        </MuiPickersUtilsProvider>
        <div className={classes.menuDesktop}>
          <CSVLink
            data={dataToCSV}
            filename={nameFile}
            onClick={() => {
              if (disableDownloadCSV) return false;
            }}
          >
            <Button
              variant="contained"
              className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-t-10"
              disabled={disableDownloadCSV}
            >
              Exportar
              <GetApp className="kt-margin-l-10" />
            </Button>
          </CSVLink>
          <ShouldItRender
            should={
              !(
                getNested(null, agencyDetail, 'DMS') ===
                  'ftp-autoline' &&
                (role === 'Service Asesor' ||
                  role === 'Service Manager')
              )
            }
            locationPage={location.pathname}
            action="createAdvance"
            compounded
          >
            <MenuOption
              Button={
                <Button
                  variant="contained"
                  className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-t-10"
                >
                  Agregar anticipo
                  <ArrowDropDownIcon className="kt-margin-l-10" />
                </Button>
              }
              options={[
                {
                  name: (
                    <>
                      <PaymentIcon />
                      &nbsp;&nbsp;Generar orden de pago para pago en
                      línea
                    </>
                  ),
                  onClick: handleGoToAddAdvance,
                },
                {
                  name: (
                    <>
                      <LocalAtmIcon />
                      &nbsp;&nbsp;Registrar anticipo realizado en la
                      agencia
                    </>
                  ),
                  onClick: handleGoToAddExternalAdvance,
                },
              ]}
            />
          </ShouldItRender>
        </div>

        <div className={classes.menuMobile}>
          <MenuOption
            Button={
              <Button
                variant="contained"
                className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-t-10"
              >
                Ver opciones
                <ArrowDropDownIcon className="kt-margin-l-10" />
              </Button>
            }
            options={[
              {
                name: (
                  <CSVLink
                    data={dataToCSV}
                    filename={nameFile}
                    onClick={() => {
                      if (disableDownloadCSV) return false;
                    }}
                  >
                    <Typography className={classes.colorPrimary}>
                      <GetApp className={classes.icons} />
                      Exportar
                    </Typography>
                  </CSVLink>
                ),
                onClick: genericFuntion,
              },
              {
                name: (
                  <>
                    <ShouldItRender
                      should={
                        !(
                          getNested(null, agencyDetail, 'DMS') ===
                            'ftp-autoline' &&
                          (role === 'Service Asesor' ||
                            role === 'Service Manager')
                        )
                      }
                      locationPage={location.pathname}
                      action="createAdvance"
                      compounded
                    >
                      <Typography className={classes.colorPrimary}>
                        <PaymentIcon className={classes.icons} />
                        pago en línea
                      </Typography>
                    </ShouldItRender>
                  </>
                ),
                onClick: handleGoToAddAdvance,
              },
              {
                name: (
                  <>
                    <ShouldItRender
                      should={
                        !(
                          getNested(null, agencyDetail, 'DMS') ===
                            'ftp-autoline' &&
                          (role === 'Service Asesor' ||
                            role === 'Service Manager')
                        )
                      }
                      locationPage={location.pathname}
                      action="createAdvance"
                      compounded
                    >
                      <Typography className={classes.colorPrimary}>
                        <LocalAtmIcon className={classes.icons} />
                        Registrar anticipo realizado en la agencia
                      </Typography>
                    </ShouldItRender>
                  </>
                ),
                onClick: handleGoToAddExternalAdvance,
              },
            ]}
          />
        </div>
      </>,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    agencyDetail,
    tabSelected,
    orders,
    nameFile,
    dataToCSV,
    disableDownloadCSV,
  ]);

  const genericFuntion = () => {
    // Don't delete this funtion, is called by the top bar menu
    // buttons that do not require the component's onClick function
  };

  const handleCloseModals = () => {
    setCloseDateErrorModal(!closeDateErrorModal);
    setShowDateErrorModal(false);
  };

  const handleFirstDate = (date) => {
    setFirstDate(date);
  };

  const handleSecondDate = (date) => {
    setSecondDate(date);
  };

  const handleClearFirstDate = () => {
    setFirstDate(null);
  };

  const handleClearSecondDate = () => {
    setSecondDate(null);
  };

  const handleChangeTab = (event, value) => {
    setTabSelected(value);
  };

  const handleFetchData = debounce(
    (state, instance) => {
      if (state) {
        const { page, pageSize, sorted, filtered } = state;
        advancesToBring[0]({
          page,
          pageSize,
          sorted,
          filtered,
          firstDate,
          secondDate,
        });
        setPage(page);
        setPageSize(pageSize);
        setSorted(sorted);
        setFiltered(filtered);
      } else {
        advancesToBring[0]({
          page,
          pageSize,
          sorted,
          filtered,
          firstDate,
          secondDate,
        });
      }
    },
    1000,
    { leading: false, trailing: true },
  );

  const handleGoToAddAdvance = () => {
    history.push('/add-advance');
  };

  const handleGoToAddExternalAdvance = () => {
    history.push('/add-external-advance');
  };

  const columns = [
    {
      Header: () => <strong>ID de Cliente</strong>,
      accessor: 'clientAccount',
      Cell: (row) => (
        <Link
          to={`/detail-advance/${row.original.orderID}/${row.original.agencyID}/`}
        >
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value || '-'}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>VIN</strong>,
      accessor: 'vin',
      Cell: (row) => (
        <Link
          to={`/detail-advance/${row.original.orderID}/${row.original.agencyID}/`}
        >
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>Nombre de cliente</strong>,
      accessor: 'nombre',
      Cell: (row) => (
        <Link
          to={`/detail-advance/${row.original.orderID}/${row.original.agencyID}/`}
        >
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      width: getColumnWidth(
        getNested([], advancesFromdb),
        'nombre',
        'Nombre de cliente',
      ),
    },
    {
      Header: () => <strong>Fecha creación</strong>,
      accessor: 'createdAt',
      Cell: (row) => (
        <Link
          to={`/detail-advance/${row.original.orderID}/${row.original.agencyID}/`}
        >
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {formatDate(row.value)}
          </div>
        </Link>
      ),
      Filter: (cell) => {
        return <MUIDatePickerFilter cell={cell} />;
      },
      width: getColumnWidth(
        getNested([], advancesFromdb),
        'createdAt',
        'Fecha creación',
      ),
    },
    {
      Header: () => <strong>Fecha pago</strong>,
      accessor: 'date',
      Cell: (row) => (
        <Link
          to={`/detail-advance/${row.original.orderID}/${row.original.agencyID}/`}
        >
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.original?.payments?.length > 0 &&
            row.original.payments[row.original.payments.length - 1]
              .status === 'DONE'
              ? formatDate(
                  row.original.payments[
                    row.original.payments.length - 1
                  ]?.date,
                )
              : 'n/a'}
          </div>
        </Link>
      ),
      Filter: (cell) => {
        return <MUIDatePickerFilter cell={cell} />;
      },
      width: getColumnWidth(
        getNested([], advancesFromdb),
        'date',
        'Fecha pago',
      ),
    },
    {
      Header: () => <strong>Total</strong>,
      accessor: 'total',
      Cell: (row) => (
        <Link
          to={`/detail-advance/${row.original.orderID}/${row.original.agencyID}/`}
        >
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {formatNumber(row.value)}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Estatus</strong>,
      accessor: 'status',
      Cell: (row) => (
        <Link
          to={`/detail-advance/${row.original.orderID}/${row.original.agencyID}/`}
        >
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <Badge
              className={classes.badges}
              variant={getBadgeColor(getPOStatusName(row.value))}
            >
              {getPOStatusName(row.value)}
            </Badge>
          </div>
        </Link>
      ),
      Filter: (cell) => {
        return (
          // eslint-disable-next-line jsx-a11y/no-onchange
          <select
            onChange={(e) => cell.onChange(e.target.value)}
            value={
              cell.filter && cell.filter.value
                ? cell.filter.value
                : ''
            }
          >
            <option value="">Todos</option>
            <option value="APPLIED">Aplicados</option>
            <option value="por pagar">Por pagar</option>
            <option value="pagado">Pagados</option>
          </select>
        );
      },
    },
  ];

  const renderFeedBackModal = () => {
    try {
      const title = 'Error en las fechas';
      const desc =
        'Por favor revise que la fecha inicial y la fecha final no estén invertidas ya que de lo contrario la información podría no ser correcta ni podrá descargar los reportes';
      return (
        <Modal
          open={showDateErrorModal}
          type={'error'}
          closeModal={handleCloseModals}
          dialogTitle={title}
          dialogText={desc}
          actionButtonText="Aceptar"
          onClick={handleCloseModals}
        />
      );
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  return (
    <div className={classes.root}>
      {renderFeedBackModal()}
      <Paper className={classes.paper}>
        <ReactTable
          ref={reactTable}
          NoDataComponent={() => (
            <div className="rt-noData">Sin resultados</div>
          )}
          manual
          onFetchData={handleFetchData}
          data={orders}
          pages={advances.tablePages}
          columns={columns}
          filterable
          className="-striped -highlight"
          previousText="Anterior"
          nextText="Siguiente"
          loading={advances.isLoading}
          loadingText="Cargando datos..."
        />
      </Paper>
    </div>
  );
}

const mapStateToProps = (state) => ({
  advances: selectAdvances(state),
  fullOrders: state.advances.fullOrders,
  advancesFromdb: makeSelectAdvances(state),
  agencyDetail: makeSelectAgencyDetailsFromState(state),
  role: makeSelectUserRoleFromState(state),
  cancelAdvances: makeSelectCancelAdvances(state),
});

const mapDispatchToProps = {
  getAdvances: getAdvances,
  getCancelAdvances: getCancelAdvances,
  getAgency: getAgency,
  getCancelAdavancesSuccess: getCancelAdavancesSuccess,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(AdvancesTable),
);
