import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';

const serviceReviewSlice = createSlice({
  name: 'serviceReview',
  initialState,
  reducers: {
    getServiceReviews: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getServiceReviewsSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.reviews = action.payload.reviews;
      },
    },
    getServiceReviewsFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    getServiceReviewsFilters: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getServiceReviewsFiltersSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.reviewsFilters = action.payload.outPutFilter;
      },
    },
    getServiceReviewsFiltersFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    closeModals: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = initialState.successModal;
        state.errorModal = initialState.errorModal;
      },
    },
  },
});

export const {
  getServiceReviews,
  getServiceReviewsSuccess,
  getServiceReviewsFail,
  getServiceReviewsFilters,
  getServiceReviewsFiltersSuccess,
  getServiceReviewsFiltersFail,
  closeModals,
} = serviceReviewSlice.actions;

export default serviceReviewSlice.reducer;
