import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  ListItem,
  ListItemText,
  Switch,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { getNested, isNumeric, typeOfPromotionMap } from 'app/utils';
import { useDispatch, useSelector } from 'react-redux';

import NumberFormatCustom from '../../../../components/NumberFormatCustom';
import React, { Fragment, useEffect, useState } from 'react';
import amex from '../../../../assets/img/logos/amex.svg';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { getOrUpdateSurTaxes } from '../../../agencies/agenciesSlice';
import { injectIntl } from 'react-intl';
import mastercard from '../../../../assets/img/logos/mastercard.svg';
import visa from '../../../../assets/img/logos/visa.svg';
import { withRouter } from 'react-router-dom';
import { identity } from 'lodash-es';
import RecentActivities from '../../../../components/RecentActivities';
import {
  makeSelectUserRoleFromState,
} from '../../../auth/selectors';

import { updatePromotions, closeModals } from '../../setupSlice';
import { selectSetup} from '../../selector';
import Modal from 'app/components/Modal';


const useStyles = makeStyles((theme) => ({
  commission: {
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: 'black',
  },
  tables: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  leyendsContainer: {
    padding: theme.spacing(1, 3, 4, 5),
  },
  card: {
    minWidth: 275,
    marginBottom: theme.spacing(2),
  },
  CardContent: {
    padding: theme.spacing(0, 3),
    '&:last-child': {
      paddingBottom: '0px',
    },
  },
  cardInnerContent: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  flex: {
    flex: '1',
  },
  flexToogle: {
    alignItems: 'flex-start',
  },
  items: {
    padding: theme.spacing(0),
  },
  textField: {
    width: '250px',
    marginBottom: '0px',
    marginTop: '0px',
    marginRight: theme.spacing(1),
  },
  intputButtonContainer: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
  },
  conditions: {
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
}));

const NewPromotions = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { agencies, getOrUpdateSurTaxes, role, setup, closeModals } = props;
  const [selectedMSI, setSelectedMSI] = useState({});
  const [
    showConfirmMSIModal,
    setShowConfirmMSIModal,
  ] = useState(false);
  const [promotion, setPromotions] = useState(null);
  const [editedPromotions, setEditedPromotions] = useState({});
  const [typeOrdersByAgencies, setTypeOrdersByAgencies] = useState();
  useEffect(() => {
    if (agencies.agencyDetails && agencies.agencyDetails.typeOrdersByAgencies)
      setTypeOrdersByAgencies(agencies.agencyDetails.typeOrdersByAgencies);
  }, [agencies.agencyDetails.typeOrdersByAgencies]);
  
  const handleUpdatePromotion = () => {
    dispatch(updatePromotions(editedPromotions));
  }

  const handlePromotionSwitchChange = (promotionId, newValue, meses) => {
    setShowConfirmMSIModal(false);
    let propToChange = `${meses}IsActive`;
    const updatedData = typeOrdersByAgencies.map(typeOrder => {
      if (typeOrder.promotionsByOrderType.id === promotionId) {
        setEditedPromotions(prevState => ({
          ...prevState,
          [promotionId]: {
            ...prevState[promotionId],
            id: promotionId,
            [`${propToChange}`]: newValue
          }
        }))
        return {
          ...typeOrder,
          promotionsByOrderType: {
            ...typeOrder.promotionsByOrderType,
            [propToChange]: newValue
          }
        };
      }
      return typeOrder;
    });

    setTypeOrdersByAgencies(updatedData);
  };

  const handleMinimumPaymentChange = (promotionId, newValue, meses) => {
    let propToChange = `${meses}Promotion`;
    const updatedData = typeOrdersByAgencies.map(typeOrder => {
      if (typeOrder.promotionsByOrderType.id === promotionId) {
        setEditedPromotions(prevState => ({
          ...prevState,
          [promotionId]: {
            ...prevState[promotionId],
            id: promotionId,
            [`${propToChange}`]: newValue
          }
        }))
        return {
          ...typeOrder,
          promotionsByOrderType: {
            ...typeOrder.promotionsByOrderType,
            [propToChange]: newValue
          }
        };
      }
      return typeOrder;
    });

    setTypeOrdersByAgencies(updatedData);
  };

  const renderDynamicPromotions = (promotion) => {
    let count = 0;
    if (promotion.promotionsByOrderType) {
      const promotionsByOrderType = promotion.promotionsByOrderType;
      const newObj = {
        "3": {
          value: promotionsByOrderType['3IsActive'] ? promotionsByOrderType['3Promotion'] : false,
          months: 3,
          text: '3 Meses',
          cards: '3.95%',
        },
        "6": {
          value: promotionsByOrderType['6IsActive'] ? promotionsByOrderType['6Promotion'] : false,
          months: 6,
          text: '6 Meses',
          cards: '6.95%',
        },
        "9": {
          value: promotionsByOrderType['9IsActive'] ? promotionsByOrderType['9Promotion'] : false,
          months: 9,
          text: '9 Meses',
          cards: '8.95%',
        },
        "12": {
          value: promotionsByOrderType['12IsActive'] ? promotionsByOrderType['12Promotion'] : false,
          months: 12,
          text: '12 Meses',
          cards: '11.95%',
        },
        "id": promotionsByOrderType['id'],
        "typeOfPromotion": promotion.typeOrder,
        "position": count,
      };
      return (
        <Grid item xs={12} className={classes.tables}>
          <h6>Promociones para {typeOfPromotionMap(promotion.typeOrder)}</h6>
          {renderPromotionsByCards(newObj)}
        </Grid>
      );
    }
    count++;
  }
  const handleShowConfirmMSI = (promotionId, newValue, meses) => {
    setSelectedMSI({promotionId, newValue, meses});
    setShowConfirmMSIModal(true);
  };

  const handleModalSuccess = () => {
    const { successModal } = setup;
    return (
      <Modal
        open={successModal.show}
        closeModal={closeModals}
        type="successModal"
        dialogTitle="Exito"
        dialogText={successModal.message}
        actionButtonText="Cerrar"
        onClick={closeModals}
      />
    );
  };

  const handleModalError = () => {
    const { errorModal } = setup;
    return (
      <Modal
        open={errorModal.show}
        closeModal={closeModals}
        type="errorModal"
        dialogTitle="Error"
        dialogText={errorModal.message}
        actionButtonText="Cerrar"
        onClick={closeModals}
      />
    );
  }
  const renderPromotionsByCards = (promotion) => {
    return (
      <Fragment>
        {Object.keys(promotion).map((onePromotion) => (
          onePromotion !== 'id' && onePromotion !== 'typeOfPromotion' && onePromotion !== 'position' && (
            <Grid item xs={12} key={`grid-${onePromotion}`} className={classes.tables}>
              <Card key={`comission-${onePromotion}`} className={classes.card}>
                <CardContent className={classes.CardContent}>
                  <div className={classes.cardInnerContent}>
                    <ListItem className={classes.items}>
                      <ListItemText
                        className={classes.flex}
                        primary={`${promotion[onePromotion].text}`}
                        secondary={promotion[onePromotion].cards}
                      />
                      <FormControlLabel
                        className={clsx(
                          classes.flex,
                          classes.flexToogle,
                        )}
                        labelPlacement="top"
                        control={
                          <Switch
                            edge="end"
                            onChange={(event) => 
                              event.target.checked === false ?
                              handlePromotionSwitchChange(promotion.id, event.target.checked, onePromotion)
                              :
                              handleShowConfirmMSI(promotion.id, event.target.checked, onePromotion)
                            }
                            checked={promotion[onePromotion].value !== false}
                          />
                        }
                      />
                      <div className={clsx(classes.flex, classes.intputButtonContainer)}>
                        <TextField
                          label="Cantidad de pago mínima"
                          className={classes.textField}
                          value={promotion[onePromotion].value === false ? 0 : promotion[onePromotion].value}
                          onChange={(event) => handleMinimumPaymentChange(promotion.id, event.target.value, onePromotion)}
                          margin="normal"
                          variant="outlined"
                          disabled={promotion[onePromotion].value === false}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">$</InputAdornment>
                            ),
                            inputComponent: NumberFormatCustom,
                            inputProps: { quantity: true },
                          }}
                        />
                      </div>
                    </ListItem>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          )
        ))}
      </Fragment>
    )
  }
  //Modal used to confirm that you want to activate the promotion.
  const renderMSIConfirmation = () => {
    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth="xs"
          open={showConfirmMSIModal}
          onClose={() => setShowConfirmMSIModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Absorber comisión
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Al activar esta opción la empresa absorberá la comisión
              de pago a {selectedMSI.value} meses de las tarjetas
              Visa/Mastercard/AMEX. Podrá desactivar esta opción en
              cualquier momento.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setShowConfirmMSIModal(false)}
              color="primary"
            >
              Cancelar
            </Button>
            <Button onClick={() => handlePromotionSwitchChange(selectedMSI?.promotionId, selectedMSI?.newValue, selectedMSI?.meses)} color="primary">
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };
  return (
    <>
      <Grid container justifyContent="space-between">
      {handleModalSuccess()}
      {handleModalError()}
        <Grid item xs={10}>
          <Typography variant="h5" className={classes.commission}>
            Promociones meses sin intereses
          </Typography>
        </Grid>
        <Grid item xs={2}>
        <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={Object.keys(editedPromotions).length > 0 ? false : true}
            onClick={() => handleUpdatePromotion()}
          >
            Actualizar
          </Button>
        </Grid>
        {console.log('typeOrdersByAgencies', typeOrdersByAgencies)}
        {
          typeOrdersByAgencies && typeOrdersByAgencies.length > 0 &&
          typeOrdersByAgencies.map((promotion) => {
            if(promotion.isActive === false){
              return
            }
            return (renderDynamicPromotions(promotion))
          })
        }
        <Grid item xs={12}>
          <FormGroup className={classes.leyendsContainer}>
            <FormControlLabel
              control={<Switch checked={true} disabled />}
              label="La empresa pagará la comisión fija más la comisión a
              meses de la opción seleccionada. El usuario recibe
              beneficio de meses sin intereses."
            />
            <FormControlLabel
              control={<Switch checked={false} disabled />}
              label="La comisión a meses la paga el cliente final. Ésta
              comisión se calcula e incrementa el monto total a pagar
              de la orden de servicio o orden de pago según sea el
              caso de manera que no afecte el ingreso de la empresa."
            />
          </FormGroup>
        </Grid>
      </Grid>
      {renderMSIConfirmation()}
    </>
  );
}

const mapStateToProps = (state) => ({
  agencies: state.agencies,
  role: makeSelectUserRoleFromState(state),
  setup: selectSetup(state),
});

const mapDispatchToProps = {
  closeModals,
  getOrUpdateSurTaxes,
};

export default withRouter(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(NewPromotions),
  ),
);
