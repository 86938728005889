import { closeModals } from '../modals/modalsSlice';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { logout } from '../auth/authSlice';

const creditOrdersSlice = createSlice({
  name: 'creditOrders',
  initialState,
  reducers: {
    getAllCreditOrders: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getAllCreditOrdersSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.fromdb = action.payload.creditOrders.rows;
        state.tablePages = action.payload.creditOrders.pages;
        state.toDownload = null;
      },
    },
    getAllCreditOrdersFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.error = action.payload.error;
      },
    },
    getAllCreditOrdersToDownload: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getAllCreditOrdersToDownloadSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.toDownload = action.payload.creditOrders;
      },
    },
    getAllCreditOrdersToDownloadFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.error = action.payload.error;
      },
    },
    getCreditOrder: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getCreditOrderSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.creditOrderDetails = action.payload.creditOrder;
      },
    },
    getCreditOrderFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.error = action.payload.error;
      },
    },
    createCreditOrder: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    createCreditOrderSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    createCreditOrderFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    updateCreditOrder: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    updateCreditOrderSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    updateCreditOrderFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    cancelCreditOrder: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    cancelCreditOrderSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    cancelCreditOrderFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    applyExternalPayment: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    applyExternalPaymentSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    applyExternalPaymentFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    applyextraordinaryPayment: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    applyextraordinaryPaymentSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    applyextraordinaryPaymentFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    uploadExcelFile: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    uploadExcelFileSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    uploadExcelFileFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    uploadPaymentsFromExcelFile: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    uploadPaymentsFromExcelFileSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    uploadPaymentsFromExcelFileFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    notificateCreditUser: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    notificateCreditUserSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    notificateCreditUserFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
          redirect: action.payload.redirect,
        };
      },
    },
    closeModals: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = initialState.successModal;
        state.errorModal = initialState.errorModal;
      },
    },
  },
  extraReducers: {
    [logout]: (state, action) => {
      return initialState;
    },
    [closeModals]: (state, action) => {
      state.isLoading = false;
      state.successModal = initialState.successModal;
      state.errorModal = initialState.errorModal;
    },
  },
});

export const {
  getAllCreditOrders,
  getAllCreditOrdersSuccess,
  getAllCreditOrdersFail,
  getAllCreditOrdersToDownload,
  getAllCreditOrdersToDownloadSuccess,
  getAllCreditOrdersToDownloadFail,
  getCreditOrder,
  getCreditOrderSuccess,
  getCreditOrderFail,
  createCreditOrder,
  createCreditOrderSuccess,
  createCreditOrderFail,
  updateCreditOrder,
  updateCreditOrderSuccess,
  updateCreditOrderFail,
  cancelCreditOrder,
  cancelCreditOrderSuccess,
  cancelCreditOrderFail,
  applyExternalPayment,
  applyExternalPaymentSuccess,
  applyExternalPaymentFail,
  applyextraordinaryPayment,
  applyextraordinaryPaymentSuccess,
  applyextraordinaryPaymentFail,
  uploadExcelFile,
  uploadExcelFileSuccess,
  uploadExcelFileFail,
  uploadPaymentsFromExcelFile,
  uploadPaymentsFromExcelFileSuccess,
  uploadPaymentsFromExcelFileFail,
  notificateCreditUser,
  notificateCreditUserSuccess,
  notificateCreditUserFail,
} = creditOrdersSlice.actions;

export default creditOrdersSlice.reducer;
