import { connect } from 'react-redux';
import { goToKiosko } from '../../kioskoSlice';
import { withRouter } from 'react-router';

function RedirecToLanding({ goToKiosko, history }) {
  const handleRedirect = () => {
    goToKiosko({ history });
    return null;
  };

  return handleRedirect();
}

const mapDispatchToProps = {
  goToKiosko,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  agencies: state.agencies,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RedirecToLanding),
);
