import React, { useEffect, useState } from 'react';
import { Badge, Table } from 'react-bootstrap';
import {
  Button,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  makeStyles,
  ClickAwayListener,
  createMuiTheme,
  Tooltip,
  MuiThemeProvider,
} from '@material-ui/core';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import MailIcon from '@material-ui/icons/Mail';
import CancelIcon from '@material-ui/icons/Cancel';
import { getResrvationDeatil } from '../../reservationsSlice';
import { makeSelectReservationDeatil } from '../../selectors';
import { getBadgeColor } from '../../../../utils';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import copy from 'copy-to-clipboard';
import { sendEmailLinkPaymentOrder } from '../../../paymentOrders/paymentOrdersSlice';
import Modal from '../../../../components/Modal';
import { Controller, useForm } from 'react-hook-form';
import { updatePaymentOrderReservation } from '../../reservationsSlice';
import { sendNotification } from 'app/features/notifications/notificationsSlice';
import { closeModals } from '../../../modals/modalsSlice';

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '1em',
        color: 'lightblue',
        backgroundColor: 'green',
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  paperTarjeta: {
    textAlign: 'left',
    marginTop: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  divider: {
    width: '100%',
  },
  title: {
    textAlign: 'left',
    color: '#1c1c1c',
  },
  content: {
    textAlign: 'left',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  rightContainer: {
    flexDirection: 'column',
  },
  item: {
    textAlign: 'left',
    justifyContent: 'space-between',
  },
  paperOrden: {
    width: '100%',
    textAlign: 'left',
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
  },
  paperCliente: {
    width: '100%',
    textAlign: 'left',
    marginTop: theme.spacing(2),
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
  },
  paperOps: {
    width: '100%',
    marginTop: theme.spacing(2),
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
  },
  paperUrl: {
    width: '100%',
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
  },
  item1: {
    textAlign: 'left',
  },
  item2: {
    textAlign: 'center',
  },
  tableTitle: {
    fontWeight: 401,
  },
  tables: {
    width: '100%',
  },
  iconImportant: {
    display: 'inline',
  },
  yellowWarning: {
    backgroundColor: '#ffb82270',
    border: '1px solid #ffb82270',
    color: '#111111',
  },
}));

const ReservationDetail = (props) => {
  const {
    getResrvationDeatil,
    reservationDetail,
    setActionButton,
    setLeftSideComponent,
    updatePaymentOrderReservation,
    sendEmailLinkPaymentOrder,
    sendNotification,
    notifications,
    closeModal,
  } = props;
  const classes = useStyles();
  const [showTooltip, setShowTooltip] = useState(false);
  const [
    showModalCancelingOrder,
    setShowModalCancelingOrder,
  ] = useState(false);
  const [showEmailPaymentLink, setShowEmailPaymentLink] = useState(
    false,
  );
  const [showWhatsModal, setShowWhatsModal] = useState(false);
  const [whatsModalSuccess, setWhatsModalSuccess] = useState(false);
  const {
    handleSubmit,
    register,
    errors,
    formState,
    control,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      type: '',
      date: null,
      amount: '',
      reference: '',
    },
  });

  useEffect(() => {
    setLeftSideComponent(
      <div className="kt-subheader__main">
        <div>
          <h3 className="kt-subheader__title">
            Detalle de reservación
          </h3>
        </div>
      </div>,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reservationDetail?.status === 'por pagar') {
      setActionButton(
        <>
          <Button
            variant="contained"
            className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
            onClick={toogleWhatsModal}
          >
            WhatsApp
            <WhatsAppIcon className="kt-margin-l-10" />
          </Button>
          <Button
            variant="contained"
            className="btn btn-label-info btn-bold btn-sm btn-icon-h kt-margin-l-10"
            onClick={() => setShowEmailPaymentLink(true)}
          >
            Correo
            <MailIcon className="kt-margin-l-10" />
          </Button>
          <Button
            variant="contained"
            className="btn btn-label-warning btn-bold btn-sm btn-icon-h kt-margin-l-10"
            onClick={toogleCancelOrderModal}
          >
            Cancelar orden
            <CancelIcon className="kt-margin-l-10" />
          </Button>
        </>,
      );
    } else {
      setActionButton(null);
    }
  }, [reservationDetail]);

  const toogleCancelOrderModal = () =>
    setShowModalCancelingOrder(!showModalCancelingOrder);

  const handleCloseModalsWhats = () => setWhatsModalSuccess(false);

  // const handleCloseModals = () => {
  //   closeModalCancelingOrder();
  //   closeModalWhats();
  //   closeModals();
  // };

  const renderFeedBackModalnotifications = () => {
    const modalType = 'successModal';
    return (
      <Modal
        open={whatsModalSuccess}
        type={modalType}
        closeModal={handleCloseModalsWhats}
        dialogTitle="Exito!"
        dialogText="El whatsapp se envió correctamente."
        actionButtonText="Aceptar"
        onClick={handleCloseModalsWhats}
      />
    );
  };

  const handleCancelOrder = () => {
    const id = props.match.params.id;
    const reference = reservationDetail.reference;
    const data = {
      id,
      status: 'cancelada',
      reference,
    };
    updatePaymentOrderReservation(data);
    toogleCancelOrderModal();
  };

  const handleToogleEmailLinkModal = () => {
    setShowEmailPaymentLink(!showEmailPaymentLink);
  };

  const handleSendPaymentLink = (formData) => {
    const data = {
      paymentOrderID: props.match.params.id,
      email: formData.email,
      paymentID: reservationDetail.orderID,
      receipt: true,
    };
    sendEmailLinkPaymentOrder(data, handleToogleEmailLinkModal);
    handleToogleEmailLinkModal();
  };

  const renderSendMailDialog = () => {
    return (
      <Modal
        open={showEmailPaymentLink}
        closeModal={handleToogleEmailLinkModal}
        dialogTitle="Enviar correo"
        dialogText="Escriba el correo al cual le desea enviar el link de pago."
        actionButtonText="Enviar"
        dialogChildren={
          <form
            className={classes.container}
            id="email-form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(handleSendPaymentLink)}
          >
            <TextField
              name="email"
              label="Correo"
              className={classes.textField}
              error={errors.email && formState.touched.email}
              inputRef={register({
                required: true,
                pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
              margin="normal"
              variant="outlined"
            />
          </form>
        }
        form={'email-form'}
      />
    );
  };

  const renderCancelOrderDialog = () => {
    return (
      <Modal
        open={showModalCancelingOrder}
        closeModal={toogleCancelOrderModal}
        dialogTitle="Cancelar orden de pago"
        dialogText="Estás a punto de cancelar esta orden de pago, una vez
        cancelada no se puede deshacer la acción, ¿Deseas
        continuar?"
        actionButtonText="Cancelar orden"
        onClick={handleCancelOrder}
      />
    );
  };

  const toogleWhatsModal = () => setShowWhatsModal(!showWhatsModal);

  const closeModalWhats = () => setShowWhatsModal(false);

  const renderSendWhatsDialog = () => {
    return (
      <Modal
        open={showWhatsModal}
        closeModal={toogleWhatsModal}
        dialogTitle="Enviar mensaje por Whatsapp"
        dialogText="Se enviará un mensaje por medio de Whatsapp al número que ingrese."
        actionButtonText="Enviar"
        dialogChildren={
          <form
            id="whatsapp-form"
            className={classes.container}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit((data) =>
              onSubmit('whatsapp', data),
            )}
          >
            <TextField
              name="cellphone"
              label="Teléfono"
              error={Boolean(errors.cellphone)}
              className={classes.textField}
              inputRef={register({
                required: true,
              })}
              margin="normal"
              variant="outlined"
              inputProps={{
                type: 'number',
              }}
            />
          </form>
        }
        form={'whatsapp-form'}
      />
    );
  };

  const onSubmit = (type, formData) => {
    const data = {
      isReservation: true,
      phoneNumber: formData.cellphone,
      id: reservationDetail.orderID,
      type,
    };
    sendNotification(data);
    closeModalWhats();
    setWhatsModalSuccess(true);
  };

  useEffect(() => {
    const id = props.match.params.id;
    getResrvationDeatil({ id });
  }, []);

  const handleHideTooltip = () => {
    setShowTooltip(false);
  };

  const handleCopyText = () => {
    setShowTooltip(true);
    copy(`${reservationDetail.urlPayment}`);
  };

  const urlPayment = () => {
    return (
      <Paper className={classes.paper}>
        <DialogTitle className={classes.title}>
          URL DE PAGO
        </DialogTitle>
        <ClickAwayListener onClickAway={handleHideTooltip}>
          <div>
            <MuiThemeProvider theme={theme}>
              <Tooltip
                className="copied-url-tooltip"
                placement="top"
                onClose={handleHideTooltip}
                open={showTooltip}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="¡URL copiada al portapapeles!"
              >
                <Table
                  bordered
                  className={classes.tables}
                  onClick={handleCopyText}
                >
                  <tbody>
                    <tr>
                      <td className={classes.tableTitle}>URL</td>
                      <td>
                        {`${
                          reservationDetail?.urlPayment?.substring(
                            0,
                            60,
                          ) || '-'
                        }...`}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Tooltip>
            </MuiThemeProvider>
          </div>
        </ClickAwayListener>
      </Paper>
    );
  };

  const reservationsDetails = () => {
    return (
      <Paper className={classes.paper}>
        <DialogTitle className={classes.title}>
          Información de la orden
        </DialogTitle>
        <Table bordered className={classes.tables}>
          <tbody>
            <tr>
              <td className={classes.tableTitle}>NÚM. DE ORDEN</td>
              <td>{reservationDetail?.orderReservation || '-'}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>MONTO TOTAL</td>
              <td>$ {reservationDetail?.total || '-'}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>
                NÚM. DE RESERVACIÓN
              </td>
              <td>{reservationDetail?.numberReservation || '-'}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>ESTAUS</td>
              <td>
                <Badge
                  className={classes.badges}
                  variant={getBadgeColor(reservationDetail?.status)}
                >
                  {reservationDetail?.status
                    ? reservationDetail?.status
                    : '-'}
                </Badge>
              </td>
            </tr>
          </tbody>
        </Table>
      </Paper>
    );
  };

  const clientDetail = () => {
    return (
      <Paper className={classes.paperURL}>
        <DialogTitle className={classes.title}>Cliente</DialogTitle>
        <Table bordered className={classes.tables}>
          <tbody>
            <tr>
              <td className={classes.tableTitle}>
                NOMBRE DEL CLIENTE
              </td>
              <td>{reservationDetail?.nombre || '-'}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>TELÉFONO CELULAR</td>
              <td>{reservationDetail?.phoneNumber || '-'}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>CORREO</td>
              <td>{reservationDetail?.email || '-'}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>DIRECCIÓN</td>
              <td>{reservationDetail?.address || '-'}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>TIPO DE UNIDAD</td>
              <td>{reservationDetail?.typeOfUnit || '-'}</td>
            </tr>
          </tbody>
        </Table>
      </Paper>
    );
  };

  const resortDetail = () => {
    return (
      <Paper className={classes.paperCliente}>
        <DialogTitle className={classes.title}>
          Resort/Destino
        </DialogTitle>
        <Table bordered className={classes.tables}>
          <tbody>
            <tr>
              <td className={classes.tableTitle}>CHECK IN</td>
              <td>{reservationDetail?.checkIn || '-'}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>CHECK OUT</td>
              <td>{reservationDetail?.checkOut || '-'}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>RESORT/DESTINO</td>
              <td>{reservationDetail?.resort}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>
                COMENTARIOS ADICIONALES
              </td>
              <td>{reservationDetail?.comments || '-'}</td>
            </tr>
          </tbody>
        </Table>
      </Paper>
    );
  };
  const MSCDetail = () => {
    return (
      <Paper className={classes.paperCliente}>
        <DialogTitle className={classes.title}>
          Información Embarcación
        </DialogTitle>
        <Table bordered className={classes.tables}>
          <tbody>
            <tr>
              <td className={classes.tableTitle}>CHECK IN</td>
              <td>{reservationDetail?.checkIn || '-'}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>PUERTO DE EMBARQUE</td>
              <td>{reservationDetail?.resort}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>
                COMENTARIOS ADICIONALES
              </td>
              <td>{reservationDetail?.comments || '-'}</td>
            </tr>
          </tbody>
        </Table>
      </Paper>
    );
  };

  return (
    <>
      {renderCancelOrderDialog()}
      {renderSendMailDialog()}
      {renderSendWhatsDialog()}
      {renderFeedBackModalnotifications()}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          {reservationsDetails()}
        </Grid>
        <Grid item xs={12} md={8} className={classes.rightContainer}>
          {(reservationDetail?.status === 'por pagar' ||
            reservationDetail?.status === 'remission') &&
            urlPayment()}
          {clientDetail()}
          {reservationDetail?.typeOfUnit !== 'MSC' ? resortDetail() : MSCDetail()}
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  notifications: state.notifications,
  reservationDetail: makeSelectReservationDeatil(state),
});

const mapDispatchToProps = {
  getResrvationDeatil,
  updatePaymentOrderReservation,
  sendEmailLinkPaymentOrder,
  sendNotification,
  closeModals,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(ReservationDetail),
);
