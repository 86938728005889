import {
  asesorRoles,
  partsAsesorRoles,
  salesAdvisorRoles,
} from 'app/config/roles';
import { call, put, select } from 'redux-saga/effects';
import {
  createAdvanceFail,
  createAdvanceSuccess,
  createExternalAdvanceFail,
  createExternalAdvanceSuccess,
  getAdvanceDetailFail,
  getAdvanceDetailSuccess,
  getAdvancesFail,
  getAdvancesSuccess,
  getCancelAdavancesSuccess,
  sendEmailLinkAdvanceFail,
  sendEmailLinkAdvancesuccess,
  updateAdvanceFail,
  updateAdvancesuccess,
} from './advancesSlice';
import {
  makeSelectAgencyId,
  makeSelectUserId,
  makeSelectUserRole,
} from '../auth/selectors.js';

import { errors } from './errors.js';
import { formatDatesForFilter } from 'app/utils';
import { getLogs } from 'app/features/logs/logsSlice';
import { requestHandler } from '../../services/requestHandler';

export function* getAdvancesSaga(action) {
  try {
    const userRole = yield select(makeSelectUserRole());
    const adminID = yield select(makeSelectUserId());
    const agencyId = yield select(makeSelectAgencyId());
    const {
      page,
      pageSize,
      filtered,
      firstDate,
      secondDate,
    } = action.payload;
    let fullOrders = [];
    const data = {
      where: {
        advance: true,
      },
      payments: true,
      options: {
        count: true,
        distinct: true,
      },
      pagination: {
        page,
        perPage: pageSize,
        order: [['updatedAt', 'DESC']],
      },
    };

    data.agencies = agencyId ? { where: { id: agencyId } } : true;
    if (filtered.length) {
      filtered.forEach((filter) => {
        if (filter.id === 'name')
          data.where['nombre'] = {
            $iLike: `%${filter.value}%`,
          };
        else if (filter.id === 'createdAt')
          data.where['createdAt'] = formatDatesForFilter(
            filter.value,
          );
        else if (filter.id === 'date') {
          data.where['date'] = formatDatesForFilter(filter.value);
        } else
          data.where[filter.id] = { $iLike: `%${filter.value}%` };
      });
    }
    switch (userRole) {
      case 'Service Manager':
        data.where = { ...data.where, type: 'service' };
        break;
      case 'Sales Manager':
        data.where = { ...data.where, type: 'sales' };
        break;
      case 'Parts Manager':
        data.where = { ...data.where, type: 'parts' };
        break;
      default:
        break;
    }
    if (
      asesorRoles.includes(userRole) ||
      partsAsesorRoles.includes(userRole) ||
      salesAdvisorRoles.includes(userRole)
    )
      data.where = {
        ...data.where,
        adminID,
      };
    if (firstDate && secondDate)
      // prettier-ignore
      data.where.createdAt = formatDatesForFilter(firstDate, secondDate);
    const advances = yield call(requestHandler, {
      method: 'POST',
      path: '/paymentOrder/getAll',
      data,
    });
    if (firstDate && secondDate) {
      const data = {
        where: {
          advance: true,
        },
        payments: true,
        options: {
          count: true,
          distinct: true,
        },
      };
      data.agencies = agencyId ? { where: { id: agencyId } } : true;
      if (filtered.length) {
        filtered.forEach((filter) => {
          if (filter.id === 'name')
            data.where['nombre'] = {
              $iLike: `%${filter.value}%`,
            };
          else if (filter.id === 'createdAt')
            data.where['createdAt'] = formatDatesForFilter(
              filter.value,
            );
          else if (filter.id === 'date') {
            data.where['date'] = formatDatesForFilter(filter.value);
          } else
            data.where[filter.id] = { $iLike: `%${filter.value}%` };
        });
      }
      switch (userRole) {
        case 'Service Manager':
          data.where = { ...data.where, type: 'service' };
          break;
        case 'Sales Manager':
          data.where = { ...data.where, type: 'sales' };
          break;
        case 'Parts Manager':
          data.where = { ...data.where, type: 'parts' };
          break;
        default:
          break;
      }
      if (
        asesorRoles.includes(userRole) ||
        partsAsesorRoles.includes(userRole) ||
        salesAdvisorRoles.includes(userRole)
      )
        data.where = {
          ...data.where,
          adminID,
        };
      if (firstDate && secondDate)
        // prettier-ignore
        data.where.createdAt = formatDatesForFilter(firstDate, secondDate);
      fullOrders = yield call(requestHandler, {
        method: 'POST',
        path: '/paymentOrder/getAll',
        data,
      });
    }
    const advancesNoCancel = advances?.rows?.filter(
      (el) => el.status !== 'cancelado',
    );
    const advancesCancel = advances?.rows?.filter(
      (el) => el.status === 'cancelado',
    );
    advances.pages = yield call(Math.ceil, advances.count / pageSize);
    advances.fullOrders = fullOrders;
    yield put(getAdvancesSuccess({ advancesNoCancel, advances }));
    yield put(
      getCancelAdavancesSuccess({
        advancesCancel,
        advances,
      }),
    );
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba obtener las órdenes de pago.
      Intente de nuevo, si el error persiste intente más tarde.`,
    };
    yield put(getAdvancesFail({ message }));
    console.log('error in login user saga', e);
  }
}

export function* getAdvanceDetailSaga(action) {
  try {
    const { orderID, agencyID } = action.payload;
    const data = {
      where: {
        orderID,
        agencyID,
      },
      agencies: true,
      payments: true,
    };
    const advance = yield call(requestHandler, {
      method: 'POST',
      path: '/paymentOrder/getOne',
      data,
    });
    yield put(getAdvanceDetailSuccess({ advance }));
    yield put(
      getLogs({
        object: 'paymentOrders',
        objectID: advance.id,
      }),
    );
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba obtener información de la orden de pago.
      Intente de nuevo, si el error persiste intente más tarde.`,
    };
    yield put(getAdvanceDetailFail({ message }));
    console.log('error in get order detail user saga', e);
  }
}

export function* createAdvanceSaga(action) {
  try {
    const { agencyID } = action.payload;
    const agencyId = yield select(makeSelectAgencyId());
    let tempagencyID = agencyId ? agencyId : agencyID;
    const data = {
      ...action.payload,
      agencyID: tempagencyID,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/paymentOrder/create',
      data,
    });
    const message = {
      title: '¡Éxito!',
      desc: `El anticipo ha sido creado correctamente.`,
    };
    const redirect = '/advances';
    yield put(
      createAdvanceSuccess({
        message,
        redirect,
      }),
    );
  } catch (e) {
    let message = '';
    if (e.code === 463)
      message = {
        title: 'Error',
        desc: `El id de la orden ya existe, por favor elije otro`,
      };
    else if (e.code in errors)
      message = {
        title: 'Error',
        desc: errors[e.code],
      };
    else
      message = {
        title: 'Error',
        desc: `Ocurrió un error cuando se intentaba crear la orden de pago.
        Intente de nuevo, si el error persiste intente más tarde.`,
      };
    yield put(createAdvanceFail({ message }));
    console.log('error in get order detail user saga', e);
  }
}

export function* updateAdvanceSaga(action) {
  try {
    const { status, id } = action.payload;
    const data = {
      id,
      status,
    };
    const advance = yield call(requestHandler, {
      method: 'POST',
      path: '/paymentOrder/update',
      data,
    });
    const message = {
      title: '¡Anticipo cancelado!',
      desc: `El anticipo ha sido cancelado.`,
    };
    yield put(
      updateAdvancesuccess({
        message,
        advance,
      }),
    );
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba cancelar el anticipo.
      Intente de nuevo, si el error persiste intente más tarde.`,
    };
    yield put(updateAdvanceFail({ message }));
    console.log('error canceling payment order: ', e);
  }
}

export function* sendEmailLinkAdvanceSaga(action) {
  try {
    const { email, paymentOrderID } = action.payload;
    const data = {
      email: email ? email : undefined,
      paymentOrderID,
      type: 'payment',
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/paymentOrder/mail',
      data,
      type: 'payment',
    });
    const message = {
      title: '¡Enviado!',
      desc: `El correo se envió a ${email}.`,
    };
    yield put(
      sendEmailLinkAdvancesuccess({
        message,
      }),
    );
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba enviar el correo.
      Intente de nuevo, si el error persiste intente más tarde.`,
    };
    yield put(sendEmailLinkAdvanceFail({ message }));
    console.log('error sending receipt payment order: ', e);
  }
}

export function* createExternalAdvanceSaga(action) {
  try {
    const data = {
      ...action.payload,
    };
    yield call(requestHandler, {
      method: 'POST',
      path: '/paymentOrder/addPaidAdvance',
      data,
    });
    const redirect = '/advances';
    const message = {
      title: '¡Pago externo creado!',
      desc: `¡El pago externo ha sido creado correctamente!`,
    };
    yield put(createExternalAdvanceSuccess({ message, redirect }));
  } catch (e) {
    const message = {
      title: 'Error',
      desc: `Ocurrió un error cuando se intentaba crear el pago externo.
      Por favor, inténtelo de nuevo más tarde.`,
    };
    yield put(createExternalAdvanceFail({ message }));
    console.log(
      'error sending creating advance for serviceOrder: ',
      e,
    );
  }
}
