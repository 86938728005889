import { all, takeLatest } from 'redux-saga/effects';
import {
  getServiceAppointments,
  getAppointmentDetail,
  confirmAppointment,
  rejectAppointment,
  handleCloseAppointmentModals,
} from '../features/serviceAppointments/serviceAppointmentsSlice';
import {
  getAppointmentsSaga,
  getAppointmentDetailSaga,
  updateConfirmedAppointmentSaga,
  updateRejectedAppointmentSaga,
  closeModalsAppointmentSaga,
} from '../features/serviceAppointments/sagas';
import {
  getKarloVehicles,
  getVehicleDetail,
} from '../features/karloVehicles/karloVehiclesSlice';
import {
  getKarloVehiclesSaga,
  getVehicleDetailSaga,
} from '../features/karloVehicles/sagas';
import {
  getKarloUsers,
  getUserDetail,
} from '../features/karloUsers/karloUsersSlice';
import {
  getKarloUsersSaga,
  getKarloUserDetailSaga,
} from '../features/karloUsers/sagas';
import {
  getKarloAdvices,
  createTip,
  deleteTip,
  getAdviceDetail,
  uploadThumbnail,
  uploadDescription,
  updateTip,
  handleCloseTipsModals,
} from '../features/karloAdvices/karloAdvicesSlice';
import {
  getKarloAdvicesSaga,
  createTipSaga,
  deleteTipSaga,
  uploadThumbnailSaga,
  uploadDescriptionSaga,
  updateTipSaga,
  getAdviceDetailSaga,
  closeModalsTipsSaga,
} from '../features/karloAdvices/sagas';
import {
  getKarloPromotions,
  deletePromotion,
  createPromotion,
  updatePromotion,
  getPromotionDetail,
  uploadPromoThumbnail,
  uploadPromoDescription,
  handleClosePromoModals,
} from '../features/karloPromotions/karloPromotionsSlice';
import {
  getKarloPromotionsSaga,
  deletePromotionSaga,
  createPromotionSaga,
  updatePromotionDataSaga,
  uploadThumbnailPromoSaga,
  uploadDescriptionPromoSaga,
  getPromotionDetailSaga,
  closeModalsPromoSaga,
} from '../features/karloPromotions/sagas';
import { getKarloQuotes } from '../features/karloQuotes/karloQuotesSlice';
import { getKarloQuotesSaga } from '../features/karloQuotes/sagas';
import {
  getKarloOffers,
  getOfferVehicles,
  createOffer,
  getOfferDetail,
  handleCloseOfferModals,
} from '../features/karloOffers/karloOffersSlice';
import {
  getKarloOffersSaga,
  getOfferVehiclesSaga,
  createOfferSaga,
  getOfferDetailSaga,
  closeModalsOfferSaga,
} from '../features/karloOffers/sagas';
import {
  applyAdvancePaymentOrder,
  createOrUpdateSurtaxesForPaymentOrders,
  createPaymentOrder,
  getPaymentOrderDetail,
  getPaymentOrders,
  getPaymentsForOrderDetail,
  sendEmailLinkPaymentOrder,
  updatePaymentOrder,
} from '../features/paymentOrders/paymentOrdersSlice';
import {
  applyAdvancePaymentOrderSaga,
  createOrUpdateSurtaxesForPaymentOrdersSaga,
  createPaymentOrderSaga,
  getPaymentOrderDetailSaga,
  getPaymentOrdersSaga,
  getPaymentsForOrderDetailSaga,
  sendEmailLinkPaymentOrderSaga,
  updatePaymentOrderSaga,
} from '../features/paymentOrders/sagas';
import {
  applyExternalPayment,
  applyextraordinaryPayment,
  cancelCreditOrder,
  createCreditOrder,
  getAllCreditOrders,
  getCreditOrder,
  notificateCreditUser,
  updateCreditOrder,
  uploadExcelFile,
  uploadPaymentsFromExcelFile,
} from '../features/creditOrders/creditOrdersSlice';
import {
  applyExternalPaymentCreditOrderSaga,
  applyExtraordinaryPaymentCreditOrderSaga,
  cancelCreditOrderSaga,
  createCreditOrderSaga,
  getAllCreditOrdersSaga,
  getCreditOrderSaga,
  notificateCreditUserSaga,
  updateCreditOrderSaga,
  uploadExcelFileSaga,
  uploadPaymentsFromExcelFileSaga,
} from '../features/creditOrders/sagas';
import {
  confirmEmail,
  login,
  logout,
  passwordReset,
  requestPasswordReset,
  getModalAllAgencies,
  getModalAllAgenciesToClose,
  getSelectOneAgency,
  confirmAgencySelectaModal,
  confirmAgencySelectaModalFail,
  sendPasswordToLogin,
  handleCloseModals,
  selectOneAgencyError,
} from 'app/features/auth/authSlice';
import {
  confirmEmailSaga,
  loginUser,
  logoutUserSaga,
  passwordResetSaga,
  requestPasswordResetSaga,
  allAgenciesModalSaga,
  allAgenciesModalToCloseSaga,
  selectOneAgencySaga,
  confirmAgencySelectSaga,
  confirmAgencySelectToCloseSaga,
  sendPasswordSaga,
  closeModalsSaga,
  closeSelectOneAgencyFailSaga,
} from '../features/auth/sagas';
import {
  createAdvance,
  createExternalAdvance,
  getAdvanceDetail,
  getAdvances,
  sendEmailLinkAdvance,
  updateAdvance,
} from 'app/features/advances/advancesSlice';
import {
  createAdvanceSaga,
  createExternalAdvanceSaga,
  getAdvanceDetailSaga,
  getAdvancesSaga,
  sendEmailLinkAdvanceSaga,
  updateAdvanceSaga,
} from 'app/features/advances/sagas';
import {
  createAdvanceServiceOrder,
  createExternalPaymentServiceOrder,
  createOrUpdateSurtaxes,
  getOrderDetail,
  getServiceOrders,
  invoiceOrder,
  importServiceOrder,
  sendEmailLinkServiceOrder,
  setReadyToPayServiceOrder,
  updateServiceOrder,
  searchServiceOrder,
  downloadfileXml,
} from 'app/features/serviceOrders/serviceOrdersSlice';
import {
  createAdvanceServiceOrderSaga,
  createExternalPaymentServiceOrderSaga,
  createOrUpdateSurTaxesForServiceOrdersSaga,
  getOrderDetailSaga,
  getOrdersSaga,
  invoiceOrdersSaga,
  importServiceOrderSaga,
  sendEmailLinkServiceOrderSaga,
  setReadyToPayServiceOrderSaga,
  updateServiceOrderSaga,
  searchServiceOrderSaga,
  downloadfileXmlSaga,
} from '../features/serviceOrders/sagas';
import {
  createAgency,
  createAgencyEvents,
  getAgencies,
  getAgency,
  getOrUpdateSurTaxes,
  updateAgency,
  updateCommissions,
  updateAgencyEvents,
  updateOption,
  updloadLogo,
  upLoadAmounts,
  getAgenciesFilters,
  updateLineConfig,
  updateAgencyMembership,
  updateTemplate,
  updateTemplateById,
  getOneAgency,
  createOrUpdateTypeOrders,
} from 'app/features/agencies/agenciesSlice';
import {
  createAgencyEventsSaga,
  createAgencySaga,
  getAgenciesSaga,
  getAgencySaga,
  getOrUpdateSurTaxesSaga,
  updateAgencyEventsSaga,
  updateAgencySaga,
  updateAgencyCommissionsSaga,
  updateOptionAgencySaga,
  uploadLogoSaga,
  updateAmounstSaga,
  getAgenciesFiltersSaga,
  updateLineConfigSaga,
  updateAgencyMembershipSaga,
  updateTemplateSaga,
  updateTemplateByIdSaga,
  getOneAgencySaga,
  createOrUpdateTypeOrdersSaga,
} from '../features/agencies/sagas';
import {
  createDeposit,
  getAllDeposits,
  getDepositDetail,
  importDepositsFromExcel,
  getAllDepositsSPEI,
  getDepositSPEIDetail,
  getAllDepositsfiserv,
  countAllDeposits,
} from 'app/features/deposits/depositsSlice';
import {
  createDepositSaga,
  getAllDepositsSaga,
  getDepositDetailSaga,
  importDepositsFromExcelSaga,
  depositsSPEISaga,
  getDepositSPEIDetailSaga,
  getAllDepositsFiservSaga,
  countDepositFiservDetailSaga,
} from 'app/features/deposits/sagas';
import {
  createNotification,
  sendNotification,
} from 'app/features/notifications/notificationsSlice';
import {
  createNotificationSaga,
  sendNotificationSaga,
} from '../features/notifications/sagas';
import {
  createUser,
  deleteUser,
  getAllUsers,
  getUser,
  resendInvitation,
  updateUser,
} from 'app/features/users/usersSlice';
import {
  createUserSaga,
  deleteUserSaga,
  getAllUsersSaga,
  getUserDetailSaga,
  resendInvitationSaga,
  updateUserSaga,
} from '../features/users/sagas';
import {
  getAllSpeiPayments,
  searchInSpeiPayments,
  validateSpeiPayment,
} from 'app/features/speiPayments/speiPaymentsSlice';
import {
  getAllSpeiPaymentsSaga,
  searchInSpeiPaymentsSaga,
  validateSpeiPaymentSaga,
} from 'app/features/speiPayments/sagas';
import {
  getPaymentDetail,
  getPayments,
  getPaymentsCreditOrders,
  getPaymentsOrders,
  getPaymentsSPEI,
  searchInPayments,
  sendPaymentReceipt,
  sendInvoiceReceipt,
  validatePayment,
  getPdfData,
  statusPayment,
  getTPVPayments,
  getPaymentsForDownload,
  getPaymentsTPVForDownload,
  getPaymentsCompleteForDownload,
  getTPVFiservPayments,
} from 'app/features/payments/paymentsSlice';
import {
  getPaymentDetailSaga,
  getPaymentsCreditOrdersSaga,
  getPaymentsOrdersSaga,
  getPaymentsSPEISaga,
  getPaymentsSaga,
  searchInPaymentsSaga,
  sendPaymentReceiptSaga,
  sendInvoiceReceiptSaga,
  validatePaymentSaga,
  pdfDataSaga,
  updateStatusPaymentSaga,
  getTPVPaymentsSaga,
  getPaymentsForDownloadSaga,
  getPaymentsTPVForDownloadSaga,
  getPaymentsCompleteForDownloadSaga,
  getAllPaymentsStandAloneSagaP,
} from '../features/payments/sagas';
import {
  tokenizePaymentOrder,
  tokenizeServiceOrder,
} from 'app/features/tokenize/tokenizeSlice';
import {
  tokenizePaymentOrderSaga,
  tokenizeServiceOrderSaga,
} from 'app/features/tokenize/sagas';

import { closeModals } from '../features/modals/modalsSlice';
import { closeModalsAndRedirectModalsSaga } from '../features/modals/sagas';
import {
  getData,
  getPDFReport,
  getAgenciesForDashboard,
} from '../features/dashboard/dashboardSlice';
import {
  getDataForDashboardSaga,
  getPDFReportSaga,
  getAgenciesForDashboardSaga,
} from '../features/dashboard/sagas';
import { getLogs } from 'app/features/logs/logsSlice';
import { getLogsSaga } from '../features/logs/sagas';
import { goToKiosko } from 'app/features/kiosko/kioskoSlice';
import { goToKioskoSaga } from 'app/features/kiosko/sagas';
import {
  getTerminals,
  createTerminal,
  deleteTerminal,
  updateTerminal,
} from '../features/terminals/terminalsSlice';
import {
  getTerminalsSaga,
  createTerminalSaga,
  deleteTerminalSaga,
  updateTerminalSaga,
} from '../features/terminals/sagas';
import {
  getReservationsSaga,
  getDeatilReservationSaga,
  createReservationSaga,
  getAllPaymentsSaga,
  updatePaymentOrderReservationSaga,
} from 'app/features/reservations/sagas';
import {
  getResrvations,
  getResrvationDeatil,
  createReservation,
  getAllPayments,
  updatePaymentOrderReservation,
} from 'app/features/reservations/reservationsSlice';
import {
  getChargebacks,
  importChargebacksFromExcel,
  getChargebacksWTF,
  importChargebacksFromExcelWTF,
  createChargebacks,
} from 'app/features/chargebacks/chargeBacksSlices';
import {
  getChargebacksSaga,
  importChargebacksFromExcelSaga,
  getChargebacksWTFSaga,
  importChargebacksFromExcelWTFSaga,
  createChargebackSaga,
} from 'app/features/chargebacks/sagas';
import { getSetuplogs } from 'app/features/setup/setupLogsSlice';
import { getSetupLogsSaga } from 'app/features/setup/sagas';
import {updatePromotionsSaga} from 'app/features/setup/sagas';
import {updatePromotions} from 'app/features/setup/setupSlice';
import {
  updateOrder,
  updateStatusOrder,
  billedDMS,
  findSPEIorder,
  findOXXOorder,
  findInvoiceOrder,
  paymentSPEI,
  paymentOXXO,
  invoiceData,
  invoiceDataUpdate,
  paymentSPEIUpdate,
  BPOrder,
  getQuiterOrder,
  refundODS,
  refund,
  removeCommission,
  updatePassword,
  associateAgency,
  getODSByOrderID,
  updateODSByOrderID,
  geOrdersQuiterForDownload,
  importDepositsFromExcelSupport,
  downloadUsability,
  testAffiliation,
  searchSPEI,
} from 'app/features/support/supportSlice';
import {
  updateOrderSaga,
  updateStatusOrderSaga,
  billedDMSSaga,
  findSPEIorderSaga,
  findOXXOorderSaga,
  findInvoiceOrderSaga,
  paymentSPEISaga,
  paymentOXXOSaga,
  updateInvoiceData,
  paymentSPEIUpdateSaga,
  getBPOrderSaga,
  getQuiterOrderSaga,
  refundODSSaga,
  refundSaga,
  removeCommissionSaga,
  updatePasswordSaga,
  associateAgencySaga,
  getODSByOrderIDSaga,
  updateODSByOrderIDSaga,
  getOrdersQuiterToRepost,
  importDepositsFromExcelSupportSaga,
  downloadUsabilitySaga,
  testAffiliationSaga,
  searchSpeiSaga,
} from 'app/features/support/sagas';
import { getServiceReviewsSaga } from 'app/features/serviceReview/sagas';
import { getServiceReviews } from 'app/features/serviceReview/serviceReviewSlice';
import {
  getDispersions,
  getDispersionsDetails,
} from 'app/features/dispersions/dispersionsSlice';
import {
  getDispersionsSaga,
  getDispersionDetailSaga,
} from 'app/features/dispersions/sagas';
import {
  getCalculatorSaga,
  fetchCalculatorResponse,
  getOrdersTypeByAgencySaga,
  resetOrderTypeSaga,
} from '../features/calculator/containers/Calculator2023/sagas';
import {
  getCalculatorData,
  getFetchCalculatorData,
  getOrdersType,
  resetOrdersType,
} from '../features/calculator/containers/Calculator2023/calculator2023Slice';
import { getDepositDetailfiserv } from 'app/features/depositsfiserv/depositsSlice';
import { getDepositFiservDetailSaga } from 'app/features/depositsfiserv/sagas';
import {
  getAllDepositsStandAlone,
  getDepositDetailStandAlone,
} from 'app/features/standAlone/deposits/depositsSlice';
import {
  getAllDepositsStandAloneSaga,
  getDepositStandAloneDetailSaga,
} from 'app/features/standAlone/deposits/sagas';
import {
  getAllPaymentsStandAloneSaga,
  getPaymentsStandAloneDetailSaga,
} from 'app/features/standAlone/payments/sagas';
import {
  getAllPaymentsStandAlone,
  getPaymentsDetailStandAlone,
} from 'app/features/standAlone/payments/depositsSlice';

export default function* root() {
  yield all([
    takeLatest(getServiceReviews, getServiceReviewsSaga),
    takeLatest(getAgenciesForDashboard, getAgenciesForDashboardSaga),
    takeLatest(getPdfData, pdfDataSaga),
    takeLatest(statusPayment, updateStatusPaymentSaga),
    takeLatest(login, loginUser),
    takeLatest(logout, logoutUserSaga),
    takeLatest(requestPasswordReset, requestPasswordResetSaga),
    takeLatest(getModalAllAgencies, allAgenciesModalSaga),
    takeLatest(sendPasswordToLogin, sendPasswordSaga),
    takeLatest(handleCloseModals, closeModalsSaga),
    takeLatest(selectOneAgencyError, closeSelectOneAgencyFailSaga),
    takeLatest(
      getModalAllAgenciesToClose,
      allAgenciesModalToCloseSaga,
    ),
    takeLatest(updateOrder, updateOrderSaga),
    takeLatest(updateStatusOrder, updateStatusOrderSaga),
    takeLatest(billedDMS, billedDMSSaga),
    takeLatest(findSPEIorder, findSPEIorderSaga),
    takeLatest(findOXXOorder, findOXXOorderSaga),
    takeLatest(downloadUsability, downloadUsabilitySaga),
    takeLatest(findInvoiceOrder, findInvoiceOrderSaga),
    takeLatest(getQuiterOrder, getQuiterOrderSaga),
    takeLatest(paymentSPEI, paymentSPEISaga),
    takeLatest(paymentOXXO, paymentOXXOSaga),
    takeLatest(invoiceData, updateInvoiceData),
    takeLatest(paymentSPEIUpdate, paymentSPEIUpdateSaga),
    takeLatest(refundODS, refundODSSaga),
    takeLatest(refund, refundSaga),
    takeLatest(removeCommission, removeCommissionSaga),
    takeLatest(BPOrder, getBPOrderSaga),
    takeLatest(getSetuplogs, getSetupLogsSaga),
    takeLatest(updatePromotions, updatePromotionsSaga),
    takeLatest(getResrvations, getReservationsSaga),
    takeLatest(getResrvationDeatil, getDeatilReservationSaga),
    takeLatest(createReservation, createReservationSaga),
    takeLatest(getAllPayments, getAllPaymentsSaga),
    takeLatest(
      updatePaymentOrderReservation,
      updatePaymentOrderReservationSaga,
    ),
    takeLatest(getChargebacks, getChargebacksSaga),
    takeLatest(
      importChargebacksFromExcel,
      importChargebacksFromExcelSaga,
    ),
    takeLatest(getChargebacksWTF, getChargebacksWTFSaga),
    takeLatest(
      importChargebacksFromExcelWTF,
      importChargebacksFromExcelWTFSaga,
    ),
    takeLatest(createChargebacks, createChargebackSaga),
    takeLatest(confirmAgencySelectaModal, confirmAgencySelectSaga),
    takeLatest(
      confirmAgencySelectaModalFail,
      confirmAgencySelectToCloseSaga,
    ),
    takeLatest(getSelectOneAgency, selectOneAgencySaga),
    takeLatest(passwordReset, passwordResetSaga),
    takeLatest(confirmEmail, confirmEmailSaga),
    takeLatest(getServiceOrders, getOrdersSaga),
    takeLatest(invoiceOrder, invoiceOrdersSaga),
    takeLatest(getOrderDetail, getOrderDetailSaga),
    takeLatest(
      sendEmailLinkServiceOrder,
      sendEmailLinkServiceOrderSaga,
    ),
    takeLatest(importServiceOrder, importServiceOrderSaga),
    takeLatest(updateServiceOrder, updateServiceOrderSaga),
    takeLatest(searchServiceOrder, searchServiceOrderSaga),
    takeLatest(
      setReadyToPayServiceOrder,
      setReadyToPayServiceOrderSaga,
    ),
    takeLatest(
      createAdvanceServiceOrder,
      createAdvanceServiceOrderSaga,
    ),
    takeLatest(
      createExternalPaymentServiceOrder,
      createExternalPaymentServiceOrderSaga,
    ),
    takeLatest(
      downloadfileXml,
      downloadfileXmlSaga,
    ),
    takeLatest(
      createOrUpdateSurtaxes,
      createOrUpdateSurTaxesForServiceOrdersSaga,
    ),
    takeLatest(getAllUsers, getAllUsersSaga),
    takeLatest(deleteUser, deleteUserSaga),
    takeLatest(updatePassword, updatePasswordSaga),
    takeLatest(associateAgency, associateAgencySaga),
    takeLatest(getODSByOrderID, getODSByOrderIDSaga),
    takeLatest(updateODSByOrderID, updateODSByOrderIDSaga),
    takeLatest(resendInvitation, resendInvitationSaga),
    takeLatest(getUser, getUserDetailSaga),
    takeLatest(updateUser, updateUserSaga),
    takeLatest(createUser, createUserSaga),
    takeLatest(getAgencies, getAgenciesSaga),
    takeLatest(getAgenciesFilters, getAgenciesFiltersSaga),
    takeLatest(createAgency, createAgencySaga),
    takeLatest(updateAgency, updateAgencySaga),
    takeLatest(updateCommissions, updateAgencyCommissionsSaga),
    takeLatest(updateOption, updateOptionAgencySaga),
    takeLatest(getAgency, getAgencySaga),
    takeLatest(updloadLogo, uploadLogoSaga),
    takeLatest(upLoadAmounts, updateAmounstSaga),
    takeLatest(createAgencyEvents, createAgencyEventsSaga),
    takeLatest(updateAgencyEvents, updateAgencyEventsSaga),
    takeLatest(getPayments, getPaymentsSaga),
    takeLatest(getPaymentsOrders, getPaymentsOrdersSaga),
    takeLatest(getPaymentsForDownload, getPaymentsForDownloadSaga),
    takeLatest(
      getPaymentsTPVForDownload,
      getPaymentsTPVForDownloadSaga,
    ),
    takeLatest(
      getPaymentsCompleteForDownload,
      getPaymentsCompleteForDownloadSaga,
    ),
    takeLatest(getPaymentsSPEI, getPaymentsSPEISaga),
    takeLatest(getPaymentsCreditOrders, getPaymentsCreditOrdersSaga),
    takeLatest(getPaymentDetail, getPaymentDetailSaga),
    takeLatest(sendPaymentReceipt, sendPaymentReceiptSaga),
    takeLatest(sendInvoiceReceipt, sendInvoiceReceiptSaga),
    takeLatest(validatePayment, validatePaymentSaga),
    takeLatest(getAllSpeiPayments, getAllSpeiPaymentsSaga),
    takeLatest(searchInSpeiPayments, searchInSpeiPaymentsSaga),
    takeLatest(validateSpeiPayment, validateSpeiPaymentSaga),
    takeLatest(getAllDeposits, getAllDepositsSaga),
    takeLatest(getDepositDetail, getDepositDetailSaga),
    takeLatest(createDeposit, createDepositSaga),
    takeLatest(importDepositsFromExcel, importDepositsFromExcelSaga),
    takeLatest(
      importDepositsFromExcelSupport,
      importDepositsFromExcelSupportSaga,
    ),
    takeLatest(
      testAffiliation,
      testAffiliationSaga,
    ),
    takeLatest(searchSPEI, searchSpeiSaga),
    takeLatest(getAllDepositsSPEI, depositsSPEISaga),
    takeLatest(getDepositSPEIDetail, getDepositSPEIDetailSaga),
    takeLatest(createNotification, createNotificationSaga),
    takeLatest(sendNotification, sendNotificationSaga),
    takeLatest(getPaymentOrders, getPaymentOrdersSaga),
    takeLatest(getPaymentOrderDetail, getPaymentOrderDetailSaga),
    takeLatest(
      getPaymentsForOrderDetail,
      getPaymentsForOrderDetailSaga,
    ),
    takeLatest(searchInPayments, searchInPaymentsSaga),
    takeLatest(createPaymentOrder, createPaymentOrderSaga),
    takeLatest(updatePaymentOrder, updatePaymentOrderSaga),
    takeLatest(
      sendEmailLinkPaymentOrder,
      sendEmailLinkPaymentOrderSaga,
    ),
    takeLatest(
      applyAdvancePaymentOrder,
      applyAdvancePaymentOrderSaga,
    ),
    takeLatest(
      createOrUpdateSurtaxesForPaymentOrders,
      createOrUpdateSurtaxesForPaymentOrdersSaga,
    ),
    takeLatest(getAdvances, getAdvancesSaga),
    takeLatest(getAdvanceDetail, getAdvanceDetailSaga),
    takeLatest(createAdvance, createAdvanceSaga),
    takeLatest(createExternalAdvance, createExternalAdvanceSaga),
    takeLatest(updateAdvance, updateAdvanceSaga),
    takeLatest(sendEmailLinkAdvance, sendEmailLinkAdvanceSaga),
    takeLatest(getOrUpdateSurTaxes, getOrUpdateSurTaxesSaga),
    takeLatest(getLogs, getLogsSaga),
    takeLatest(getAllCreditOrders, getAllCreditOrdersSaga),
    takeLatest(getCreditOrder, getCreditOrderSaga),
    takeLatest(createCreditOrder, createCreditOrderSaga),
    takeLatest(updateCreditOrder, updateCreditOrderSaga),
    takeLatest(
      applyExternalPayment,
      applyExternalPaymentCreditOrderSaga,
    ),
    takeLatest(notificateCreditUser, notificateCreditUserSaga),
    takeLatest(cancelCreditOrder, cancelCreditOrderSaga),
    takeLatest(uploadExcelFile, uploadExcelFileSaga),
    takeLatest(
      uploadPaymentsFromExcelFile,
      uploadPaymentsFromExcelFileSaga,
    ),
    takeLatest(
      applyextraordinaryPayment,
      applyExtraordinaryPaymentCreditOrderSaga,
    ),
    takeLatest(closeModals, closeModalsAndRedirectModalsSaga),
    takeLatest(getData, getDataForDashboardSaga),
    takeLatest(getPDFReport, getPDFReportSaga),
    takeLatest(tokenizeServiceOrder, tokenizeServiceOrderSaga),
    takeLatest(tokenizePaymentOrder, tokenizePaymentOrderSaga),
    takeLatest(goToKiosko, goToKioskoSaga),
    takeLatest(getServiceAppointments, getAppointmentsSaga),
    takeLatest(confirmAppointment, updateConfirmedAppointmentSaga),
    takeLatest(getAppointmentDetail, getAppointmentDetailSaga),
    takeLatest(getKarloUsers, getKarloUsersSaga),
    takeLatest(getUserDetail, getKarloUserDetailSaga),
    takeLatest(getKarloVehicles, getKarloVehiclesSaga),
    takeLatest(getVehicleDetail, getVehicleDetailSaga),
    takeLatest(getKarloAdvices, getKarloAdvicesSaga),
    takeLatest(getKarloPromotions, getKarloPromotionsSaga),
    takeLatest(getKarloQuotes, getKarloQuotesSaga),
    takeLatest(getKarloOffers, getKarloOffersSaga),
    takeLatest(getOfferVehicles, getOfferVehiclesSaga),
    takeLatest(getTerminals, getTerminalsSaga),
    takeLatest(createTerminal, createTerminalSaga),
    takeLatest(deleteTerminal, deleteTerminalSaga),
    takeLatest(updateTerminal, updateTerminalSaga),
    takeLatest(getTPVPayments, getTPVPaymentsSaga),
    takeLatest(getTPVFiservPayments, getAllPaymentsStandAloneSagaP),
    takeLatest(rejectAppointment, updateRejectedAppointmentSaga),
    takeLatest(createOffer, createOfferSaga),
    takeLatest(createTip, createTipSaga),
    takeLatest(deleteTip, deleteTipSaga),
    takeLatest(deletePromotion, deletePromotionSaga),
    takeLatest(createPromotion, createPromotionSaga),
    takeLatest(uploadThumbnail, uploadThumbnailSaga),
    takeLatest(uploadDescription, uploadDescriptionSaga),
    takeLatest(updateTip, updateTipSaga),
    takeLatest(updatePromotion, updatePromotionDataSaga),
    takeLatest(getOfferDetail, getOfferDetailSaga),
    takeLatest(getAdviceDetail, getAdviceDetailSaga),

    takeLatest(getPromotionDetail, getPromotionDetailSaga),
    takeLatest(uploadPromoThumbnail, uploadThumbnailPromoSaga),
    takeLatest(uploadPromoDescription, uploadDescriptionPromoSaga),

    takeLatest(handleCloseTipsModals, closeModalsTipsSaga),
    takeLatest(handleClosePromoModals, closeModalsPromoSaga),
    takeLatest(handleCloseOfferModals, closeModalsOfferSaga),
    takeLatest(
      handleCloseAppointmentModals,
      closeModalsAppointmentSaga,
    ),
    takeLatest(getDispersions, getDispersionsSaga),
    takeLatest(getDispersionsDetails, getDispersionDetailSaga),
    takeLatest(getCalculatorData, getCalculatorSaga),
    takeLatest(getFetchCalculatorData, fetchCalculatorResponse),
    takeLatest(getOrdersType, getOrdersTypeByAgencySaga),
    takeLatest(resetOrdersType, resetOrderTypeSaga),
    takeLatest(getAllDepositsfiserv, getAllDepositsFiservSaga),
    takeLatest(countAllDeposits, countDepositFiservDetailSaga),
    takeLatest(getDepositDetailfiserv, getDepositFiservDetailSaga),
    takeLatest(updateLineConfig, updateLineConfigSaga),
    takeLatest(updateAgencyMembership, updateAgencyMembershipSaga),
    takeLatest(updateTemplate, updateTemplateSaga),
    takeLatest(updateTemplateById, updateTemplateByIdSaga),
    takeLatest(getOneAgency, getOneAgencySaga),
    takeLatest(createOrUpdateTypeOrders, createOrUpdateTypeOrdersSaga),
    takeLatest(geOrdersQuiterForDownload, getOrdersQuiterToRepost),
    takeLatest(
      getDepositDetailStandAlone,
      getDepositStandAloneDetailSaga,
    ),
    takeLatest(
      getAllDepositsStandAlone,
      getAllDepositsStandAloneSaga,
    ),
    takeLatest(
      getAllPaymentsStandAlone,
      getAllPaymentsStandAloneSaga,
    ),
    takeLatest(
      getPaymentsDetailStandAlone,
      getPaymentsStandAloneDetailSaga,
    ),
  ]);
}
