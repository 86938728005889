export const statusODS = [
  {
    value: 'none',
    label: 'Estatus de la orden',
  },
  {
    value: 'open',
    label: 'Abierta',
  },
  {
    value: 'payment',
    label: 'Pagada',
  },
  {
    value: 'remission',
    label: 'Por pagar',
  },
  {
    value: 'billed',
    label: 'Facturada',
  },
];

export const statusODP = [
  {
    value: 'none',
    label: 'Selecciona una opción',
  },
  {
    value: 'cancelada',
    label: 'Cancelada',
  },
  {
    value: 'pagado',
    label: 'Pagada',
  },
  {
    value: 'por pagar',
    label: 'Por pagar',
  },
];
