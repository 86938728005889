import 'react-table/react-table.css';
/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import Add from '@material-ui/icons/Add';
import { Badge } from 'react-bootstrap';
import { getBadgeColor, formatSingleDate } from '../../../../utils';
import {
  Button,
  Paper,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import { filterCaseInsensitive } from '../../../../utils';
import { getResrvations } from '../../reservationsSlice';
import { connect } from 'react-redux';
import {
  makeSelectReservations,
  selectReservations,
  makeSelectPages,
} from '../../selectors';
import { selectAuth } from '../../../auth/selectors';
import { selectAgencies } from '../../../agencies/selectors';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { debounce } from 'lodash';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  paper: {
    widht: '100%',
    marginBottom: theme.spacing(0),
  },
  button: {
    margin: theme.spacing(1),
  },
  textField: {
    margin: theme.spacing(1),
    width: '95%',
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
}));

const ReservationsCruise = (props) => {
  const {
    getResrvations,
    resrvationsFromdb,
    reservations,
    pages,
    setActionButton,
    user,
    agencies,
  } = props;
  const classes = useStyles();
  const [tabSelected, setTabSelected] = useState(0);

  useEffect(() => {
    if (
      user.user.role === 'Super Admin' ||
      agencies?.agencyDetails?.nickname == 'WorldTravelFun' ||
      agencies?.agencyDetails?.nickname == 'WORLDTRAVELANDFUN'
    ) {
      setActionButton(
        <>
          <Link to={'/create-reservation-cruise'}>
            <Button
              variant="contained"
              className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
            >
              Crear reservación
              <Add className="kt-margin-l-10" />
            </Button>
          </Link>
        </>,
      );
    }
  }, []);

  const columns = [
    {
      Header: () => <strong>Número de orden</strong>,
      accessor: 'orderReservation',
      Cell: (row) => {
        return (
          <Link to={`/reservation-order/${row.original.id}`}>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              {row.value}
            </div>
          </Link>
        );
      },
    },
    {
      Header: () => <strong>Número de reservación</strong>,
      accessor: 'numberReservation',
      Cell: (row) => {
        return (
          <Link to={`/reservation-order/${row.original.id}`}>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              {row.value}
            </div>
          </Link>
        );
      },
    },
    {
      Header: () => <strong>Nombre</strong>,
      accessor: 'nombre',
      Cell: (row) => {
        return (
          <Link to={`/reservation-order/${row.original.id}`}>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              {row.value}
            </div>
          </Link>
        );
      },
    },
    {
      Header: () => <strong>Correo del cliente</strong>,
      accessor: 'email',
      Cell: (row) => {
        return (
          <Link to={`/reservation-order/${row.original.id}`}>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              {row.value}
            </div>
          </Link>
        );
      },
    },
    {
      Header: () => <strong>Celular del cliente</strong>,
      accessor: 'phoneNumber',
      Cell: (row) => {
        return (
          <Link to={`/reservation-order/${row.original.id}`}>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              {row.value}
            </div>
          </Link>
        );
      },
    },
    {
      Header: () => <strong>Check In</strong>,
      accessor: 'checkIn',
      Cell: (row) => {
        return (
          <Link to={`/reservation-order/${row.original.id}`}>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              {row.value
                ? moment(row.value).utc().format('DD/MM/YYYY')
                : '-'}
            </div>
          </Link>
        );
      },
    },
    {
      Header: () => <strong>Check out</strong>,
      accessor: 'checkOut',
      Cell: (row) => {
        return (
          <Link to={`/reservation-order/${row.original.id}`}>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              {row.value
                ? moment(row.value).utc().format('DD/MM/YYYY')
                : '-'}
            </div>
          </Link>
        );
      },
    },
    {
      Header: () => <strong>Destino</strong>,
      accessor: 'resort',
      Cell: (row) => {
        return (
          <Link to={`/reservation-order/${row.original.id}`}>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              {row.value}
            </div>
          </Link>
        );
      },
    },
    {
      Header: () => <strong>Fecha de creación</strong>,
      accessor: 'createdAt',
      Cell: (row) => {
        return (
          <Link to={`/reservation-order/${row.original.id}`}>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              {row.value
                ? moment(row.value).utc().format('DD/MM/YYYY')
                : '-'}
            </div>
          </Link>
        );
      },
    },
    {
      Header: () => <strong>Asesor</strong>,
      accessor: 'advisorName',
      Cell: (row) => {
        return (
          <Link to={`/reservation-order/${row.original.id}`}>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              {row.value ? row.value : '-'}
            </div>
          </Link>
        );
      },
    },
    {
      Header: () => <strong>Estatus</strong>,
      accessor: 'status',
      Cell: (row) => {
        return (
          <Badge
            className={classes.badges}
            variant={getBadgeColor(row.value)}
          >
            {row.value}
          </Badge>
        );
      },
      Filter: (cell) => {
        return (
          // eslint-disable-next-line jsx-a11y/no-onchange
          <select
            disabled={tabSelected === 1}
            onChange={(e) => cell.onChange(e.target.value)}
            value={
              cell.filter && cell.filter.value
                ? cell.filter.value
                : ''
            }
          >
            <option value="">Todos</option>
            {tabSelected === 0 ? (
              <>
                <option value="por pagar">Por pagar</option>
                <option value="pagado">Pagada</option>
              </>
            ) : null}
          </select>
        );
      },
    },
  ];

  const handleFetchData = debounce(
    (state, instance) => {
      const { page, pageSize, sorted, filtered } = state;
      getResrvations({
        page,
        pageSize,
        isMSC : true,
        sorted,
        filtered,
        agencies,
        user,
      });
    },
    1000,
    { leading: false, trailing: true },
  );

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <ReactTable
          NoDataComponent={() => (
            <div className="rt-noData">Sin resultados</div>
          )}
          onFetchData={handleFetchData}
          data={resrvationsFromdb || []}
          columns={columns}
          filterable
          defaultFilterMethod={filterCaseInsensitive}
          className="-striped -highlight"
          previousText="Anterior"
          nextText="Siguiente"
          pages={pages}
          loading={reservations.isLoading}
          loadingText="Cargando datos..."
        />
      </Paper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  resrvationsFromdb: makeSelectReservations(state),
  reservations: selectReservations(state),
  pages: makeSelectPages(state),
  user: selectAuth(state),
  agencies: selectAgencies(state),
});

const mapDispatchToProps = {
  getResrvations,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(ReservationsCruise),
);
