import React from 'react';
import { TextField } from '@material-ui/core';

import { statusODS, statusODP } from './orderStatus';

const ChangeStatus = (props) => {
  const { type, setNewStatus } = props;

  return (
    <TextField
      fullWidth
      select
      variant="outlined"
      SelectProps={{
        native: true,
      }}
      label="Estatus"
      onChange={(event) => setNewStatus(event.target.value)}
    >
      {type === 'updateStatusODS'
        ? statusODS.map((option) => (
            <option key={option.label} value={option.value}>
              {option.label}
            </option>
          ))
        : statusODP.map((option) => (
            <option key={option.label} value={option.value}>
              {option.label}
            </option>
          ))}
    </TextField>
  );
};

export default ChangeStatus;
