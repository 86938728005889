import React from 'react';

export default function QuickStatsTextWidget({ value, desc }) {
  return (
    <div className="kt-widget26">
      <div className="kt-widget26__content text-center">
        <span className="kt-widget26__number">{value}</span>
        <span className="kt-widget26__desc">{desc}</span>
      </div>
    </div>
  );
}
