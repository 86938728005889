import LayoutConfig from '../layout/LayoutConfig';
import MenuConfig from '../layout/MenuConfig';
import { logout } from 'app/features/auth/authSlice';
import objectPath from 'object-path';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export const actionTypes = {
  SetMenuConfig: 'builder/SET_MENU_CONFIG',
  SetLayoutConfigs: 'builder/SET_LAYOUT_CONFIGS',
  SetLayoutConfigsWithPageRefresh:
    'builder/SET_LAYOUT_CONFIGS_WITH_PAGE_REFRESH',
  SetHtmlClassService: 'builder/SET_HTML_CLASS_SERVICE',
  EditMenuconfig: 'EDIT_MENU_CONFIG',
};

export const selectors = {
  getClasses: (store, params) => {
    const { htmlClassServiceObjects } = store.builder;

    return htmlClassServiceObjects
      ? htmlClassServiceObjects.getClasses(
          params.path,
          params.toString,
        )
      : '';
  },

  getConfig: (state, path) => {
    const { layoutConfig } = state.builder;

    if (path) {
      // if path is specified, get the value within object
      return objectPath.get(layoutConfig, path);
    }

    return '';
  },

  getLogo: ({ builder: { layoutConfig } }) => {
    const menuAsideLeftSkin = objectPath.get(
      layoutConfig,
      'brand.self.skin',
    );
    // set brand logo
    const logoObject = objectPath.get(layoutConfig, 'self.logo');
    let logo;
    if (typeof logoObject === 'string') {
      logo = logoObject;
    }

    if (typeof logoObject === 'object') {
      logo = objectPath.get(logoObject, menuAsideLeftSkin + '');
    }

    if (typeof logo === 'undefined') {
      try {
        const logos = objectPath.get(this.layoutConfig, 'self.logo');
        logo = logos[Object.keys(logos)[0]];
      } catch (e) {}
    }
    return logo;
  },

  getStickyLogo: (store) => {
    const { layoutConfig } = store.builder;
    let logo = objectPath.get(layoutConfig, 'self.logo.sticky');
    if (typeof logo === 'undefined') {
      logo = selectors.getLogo(store);
    }
    return logo + '';
  },
};

const initialState = {
  menuConfig: MenuConfig,
  layoutConfig: LayoutConfig,
  htmlClassServiceObjects: null,
};

const getRoutes = (source) => {
  if (Array.isArray(source?.routes) && source?.routes?.length > 0)
    return source.routes.map((section, index) => {
      if (index === source.main)
        return {
          ...MenuConfig.sections[section],
          main: true,
        };
      else return MenuConfig.sections[section];
    });
  return [];
};

export const reducer = persistReducer(
  {
    storage,
    key: 'build',
    blacklist: ['htmlClassServiceObjects'],
  },
  (state = initialState, { type, payload }) => {
    switch (type) {
      case actionTypes.SetMenuConfig:
        const role = payload.role;
        const DMS = payload.dms;
        const arrModulesDisabled = payload.arrModulesDisabled;
        let newItems = [];
        if (
          DMS in MenuConfig.aside &&
          role in MenuConfig.aside[DMS]
        ) {
          newItems = getRoutes(MenuConfig.aside[DMS][role]);
        } else {
          newItems = getRoutes(MenuConfig.aside[role]);
        }

        if (arrModulesDisabled && arrModulesDisabled.length > 0) {
          arrModulesDisabled.forEach(titulo => {
            let index = newItems.findIndex(objeto => objeto.title === titulo);
            while (index !== -1) {
              newItems.splice(index, 1);
              index = newItems.findIndex(objeto => objeto.title === titulo);
            }
          });
        }
        if (Array.isArray(newItems)) {
          MenuConfig.aside.items = [...newItems];
        }
        return { ...state, menuConfig: MenuConfig };

      case actionTypes.SetLayoutConfigs:
        return { ...state, layoutConfig: payload };

      case actionTypes.SetLayoutConfigsWithPageRefresh: {
        return { ...state, layoutConfig: payload };
      }
      case actionTypes.SetHtmlClassService:
        return { ...state, htmlClassServiceObjects: payload };

      case logout: {
        MenuConfig.aside.items = [];
        return { ...state, menuConfig: MenuConfig };
      }

      default:
        return state;
    }
  },
);

export const actions = {
  setMenuConfig: (role, dms, arrModulesDisabled = null) => ({
    payload: { role, dms, arrModulesDisabled },
    type: actionTypes.SetMenuConfig,
  }),

  setLayoutConfigs: (payload) => ({
    payload,
    type: actionTypes.SetLayoutConfigs,
  }),

  setLayoutConfigsWithPageRefresh: (payload) => ({
    payload,
    type: actionTypes.SetLayoutConfigsWithPageRefresh,
  }),

  setHtmlClassService: (payload) => ({
    payload,
    type: actionTypes.SetHtmlClassService,
  }),
};
