export const initialState = {
  countDeposits: null,
  fromdb: null,
  fromdbFiserv: null,
  fromdbFiservToReport: null,
  tablePagesFromdb: 0,
  depositsForDownload: null,
  depositDetails: null,
  successModal: {
    show: false,
    message: null,
  },
  errorModal: {
    show: false,
    message: null,
  },
  isLoading: false,
  error: null,
};
