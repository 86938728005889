import { closeModals } from '../modals/modalsSlice';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { logout } from '../auth/authSlice';

const karloAdvicesSlice = createSlice({
  name: 'karloAdvices',
  initialState,
  reducers: {
    getKarloAdvices: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getKarloAdvicesSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.fromdb = action.payload.karloAdvices.tips;
        state.tablePages = action.payload.karloAdvices.pages;
      },
    },
    getKarloAdvicesFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    createTip: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    createTipSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          ...action.payload,
        };
      },
    },
    createTipFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    deleteTip: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    deleteTipSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          ...action.payload,
        };
      },
    },
    deleteTipFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    uploadThumbnail: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    uploadThumbnailSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          ...action.payload,
        };
      },
    },
    uploadThumbnailFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    uploadDescription: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    uploadDescriptionSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          ...action.payload,
        };
      },
    },
    uploadDescriptionFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    updateTip: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    updateTipSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          ...action.payload,
        };
      },
    },
    updateTipFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    getAdviceDetail: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getAdviceDetailSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.adviceDetail = action.payload.details.tip;
      },
    },
    getAdviceDetailFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    handleCloseTipsModals: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    handleCloseTipsModalsSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = initialState.successModal;
        state.errorModal = initialState.errorModal;
      },
    },
  },
  extraReducers: {
    [logout]: (state, action) => {
      return initialState;
    },
  },
});

export const {
  getKarloAdvices,
  getKarloAdvicesSuccess,
  getKarloAdvicesFail,
  createTip,
  createTipSuccess,
  createTipFail,
  deleteTip,
  deleteTipSuccess,
  deleteTipFail,
  uploadThumbnail,
  uploadThumbnailSuccess,
  uploadThumbnailFail,
  uploadDescription,
  uploadDescriptionSuccess,
  uploadDescriptionFail,
  updateTip,
  updateTipSuccess,
  updateTipFail,
  getAdviceDetail,
  getAdviceDetailSuccess,
  getAdviceDetailFail,
  handleCloseTipsModals,
  handleCloseTipsModalsSuccess,
} = karloAdvicesSlice.actions;

export default karloAdvicesSlice.reducer;
