import { connect } from 'react-redux';
import { getNested } from '../../utils';
import { permissions } from '../../utils/permissions';
import { withRouter } from 'react-router';

function ShouldItRender(props) {
  const { auth, compounded, should, locationPage, children } = props;

  const renderChildren = () => {
    const permissionsByRole = getNested(
      [],
      permissions,
      auth.user.role,
      locationPage,
    );
    if (
      compounded &&
      should &&
      permissionsByRole.find((action) => props.action === action)
    )
      return children;
    else if (should && !compounded) return children;
    else if (
      !compounded &&
      permissionsByRole.find((action) => props.action === action)
    ) {
      return children;
    }
    return null;
  };

  return renderChildren();
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default withRouter(
  connect(mapStateToProps, null)(ShouldItRender),
);
