import React, { useState, useEffect, useRef } from 'react';

import 'react-table/react-table.css';
import { Paper, Grid, Button, IconButton, Tab, Tabs } from '@material-ui/core';
import { GetApp, Close } from '@material-ui/icons';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import { filterCaseInsensitive } from 'app/utils';
import Modal from 'app/components/Modal';
import MUIDatePickerFilter from 'app/components/MUIDatePickerFilter';

import { makeStyles } from '@material-ui/styles';

import {
  selectServiceReview,
  makeSelectServiceReview,
  makeSelectServiceReviewFilters,
} from '../../selectors';
import { makeSelectUserRoleFromState } from '../../../auth/selectors';

import {
  getServiceReviews,
  closeModals,
} from 'app/features/serviceReview/serviceReviewSlice';

import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import ReactTable from 'react-table';
import { CSVLink } from 'react-csv';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  paper: {
    widht: '100%',
    marginBottom: theme.spacing(0),
  },
  datePicker: {
    width: '90px',
  },
  range: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: '30px',
  },
}));

const ServiceReviewsTable = (props) => {
  const {
    reviews,
    reviewsFromdb,
    reviewsFilter,
    getServiceReviews,
    setActionButton,
    closeModals,
    role,
    setLeftSideComponent,
  } = props;
  const classes = useStyles();

  const [reviewList, setReviewList] = useState([]);
  const [reviewListFilter, setReviewListFilter] = useState([]);
  const [dataToCSV, setDataToCSV] = useState([]);
  const [firstDate, setFirstDate] = useState(null);
  const [secondDate, setSecondDate] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sorted, setSorted] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [disableDownloadCSV, setDisableDownloadCSV] = useState(false);
  const [showDateErrorModal, setShowDateErrorModal] = useState(false);
  const [tabSelected, setTabSelected] = useState('service');
  const reactTable = useRef(null);

  useEffect(() => {
    let list = [];
    if (reviewsFromdb && reviewsFromdb.outPut.length > 0) {
      const { outPut } = reviewsFromdb;
      list = clearData(outPut);
    }
    setReviewList(list);
  }, [reviewsFromdb]);

  useEffect(() => {
    setLeftSideComponent(
      <Tabs
        value={tabSelected}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChangeTab}
      >
        <Tab className={classes.tapStyle} value={'service'} label="Servicio" />
        <Tab className={classes.tapStyle} value={'payment experience'} label="Experiencia de pago" />
      </Tabs>,
    );
    reactTable.current && reactTable.current.fireFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabSelected]);

  const handleChangeTab = (event, value) => setTabSelected(value);

  useEffect(() => {
    let list = [];
    if (reviewsFilter && reviewsFilter.length > 0) {
      list = clearData(reviewsFilter);
    }
    setReviewListFilter(list);
  }, [reviewsFilter]);

  useEffect(() => {
    const list =
      reviewListFilter.length > 0 ? reviewListFilter : reviewList;

    const CSV = list.map((item) => {
      return {
        // prettier-ignore
        'ID': item.id || '-',
        // prettier-ignore
        'Agencia': item.agency || '-',
        // prettier-ignore
        'Tipo': item.type || '-',
        // prettier-ignore
        'ID de la orden': item.orderID || '-',
        // prettier-ignore
        'Cliente': item.customerName || '-',
        // prettier-ignore
        'Teléfono de cliente': item.customerPhone || '-',
        // prettier-ignore
        'Correo de cliente': item.customerMail || '-',
        // prettier-ignore
        'Asesor': item.adviserName || '-',
        // prettier-ignore
        'Origen': item.origin || '-',
        // prettier-ignore
        'Calificación': item.evaluation || '-',
        // prettier-ignore
        'Fecha': item.createdAt || '-',
      };
    });
    setDataToCSV(CSV);
  }, [reviewListFilter, reviewList]);

  useEffect(() => {
    if (
      (firstDate && secondDate && firstDate < secondDate) ||
      (!firstDate && !secondDate)
    ) {
      handleFetchData();
    }
  }, [firstDate, secondDate, tabSelected]);

  useEffect(() => {
    if (firstDate && secondDate && firstDate > secondDate) {
      setShowDateErrorModal(true);
      setDisableDownloadCSV(true);
      renderErrorDatesModal();
    } else {
      setDisableDownloadCSV(false);
    }
  }, [firstDate, secondDate]);

  const clearData = (data) => {
    const list = data.map((item) => {
      return {
        id: item.id,
        agency: item.agency.name,
        orderType: item.paymentOrderID
          ? 'Orden de pago'
          : 'Orden de servicio',
        orderID: item.paymentOrderID
          ? item.paymentOrderID
          : item.serviceOrderID,
        customerName: item.customerName,
        customerPhone: item.customerPhone,
        customerMail: item.customerMail,
        adviserName: item.adviserName,
        origin: item.origin,
        evaluation: item.evaluation,
        type: item.type === 'service' ? 'Servicio' : item.type === 'payment experience' ?  'Experiencia de pago' : '-',
        createdAt: moment(item.createdAt).format('DD/MM/YYYY'),
      };
    });
    return list;
  };

  const handleFirstDate = (date) => {
    setFirstDate(date);
  };

  const handleSecondDate = (date) => {
    setSecondDate(date);
  };

  const handleClearFirstDate = () => {
    setFirstDate(null);
  };

  const handleClearSecondDate = () => {
    setSecondDate(null);
  };

  const handleCloseModals = () => {
    setShowDateErrorModal(false);
  };

  const handleFetchData = debounce(
    (state, instance) => {
      if (state) {
        const { page, pageSize, sorted, filtered } = state;
        getServiceReviews({
          page,
          pageSize,
          sorted,
          filtered,
          firstDate,
          secondDate,
          tabSelected,
        });
        setPage(page);
        setPageSize(pageSize);
        setSorted(sorted);
        setFiltered(filtered);
      } else {
        getServiceReviews({
          page,
          pageSize,
          sorted,
          filtered,
          firstDate,
          secondDate,
          tabSelected,
        });
      }
    },
    1000,
    { leading: false, trailing: true },
  );

  const columns = [
    {
      /* eslint-disable react/display-name */
      Header: () => <strong>ID</strong>,
      accessor: 'id',
      /* eslint-disable react/display-name */
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value}
        </div>
      ),
    },
    {
      /* eslint-disable react/display-name */
      Header: () => <strong>Agencia</strong>,
      accessor: 'agency',
      /* eslint-disable react/display-name */
      Cell: (row) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {row.value}
        </div>
      ),
      show: role === 'Super Admin' || role === 'Super Admin Agencies',
    },
    {
      /* eslint-disable react/display-name */
      Header: () => <strong>Tipo</strong>,
      accessor: 'orderType',
      /* eslint-disable react/display-name */
      Cell: (row) => {
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        );
      },
      /* eslint-disable react/display-name */
      Filter: (cell) => {
        return (
          // eslint-disable-next-line jsx-a11y/no-onchange
          <select
            onChange={(e) => cell.onChange(e.target.value)}
            value={
              cell.filter && cell.filter.value
                ? cell.filter.value
                : ''
            }
          >
            <option value="">Todos</option>
            <option value="serviceOrder">Orden de servicio</option>
            <option value="paymentOrder">Orden de pago</option>
          </select>
        );
      },
    },
    {
      /* eslint-disable react/display-name */
      Header: () => <strong>ID de la orden</strong>,
      accessor: 'orderID',
      /* eslint-disable react/display-name */
      Cell: (row) => {
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        );
      },
    },
    {
      /* eslint-disable react/display-name */
      Header: () => <strong>Cliente</strong>,
      accessor: 'customerName',
      /* eslint-disable react/display-name */
      Cell: (row) => {
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        );
      },
    },
    {
      /* eslint-disable react/display-name */
      Header: () => <strong>Teléfono de cliente</strong>,
      accessor: 'customerPhone',
      /* eslint-disable react/display-name */
      Cell: (row) => {
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        );
      },
    },
    {
      /* eslint-disable react/display-name */
      Header: () => <strong>Correo de cliente</strong>,
      accessor: 'customerMail',
      /* eslint-disable react/display-name */
      Cell: (row) => {
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        );
      },
    },
    {
      /* eslint-disable react/display-name */
      Header: () => <strong>Asesor</strong>,
      accessor: 'adviserName',
      /* eslint-disable react/display-name */
      Cell: (row) => {
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        );
      },
    },
    {
      /* eslint-disable react/display-name */
      Header: () => <strong>Origen</strong>,
      accessor: 'origin',
      /* eslint-disable react/display-name */
      Cell: (row) => {
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        );
      },
      /* eslint-disable react/display-name */
      Filter: (cell) => {
        return (
          // eslint-disable-next-line jsx-a11y/no-onchange
          <select
            onChange={(e) => cell.onChange(e.target.value)}
            value={
              cell.filter && cell.filter.value
                ? cell.filter.value
                : ''
            }
          >
            <option value="">Todos</option>
            <option value={'online'}>Online</option>
            <option value={'kiosko'}>Kiosko</option>
          </select>
        );
      },
    },
    {
      /* eslint-disable react/display-name */
      Header: () => <strong>Calificación</strong>,
      accessor: 'evaluation',
      /* eslint-disable react/display-name */
      Cell: (row) => {
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        );
      },
      /* eslint-disable react/display-name */
      Filter: (cell) => {
        return (
          // eslint-disable-next-line jsx-a11y/no-onchange
          <select
            onChange={(e) => cell.onChange(e.target.value)}
            value={
              cell.filter && cell.filter.value
                ? cell.filter.value
                : ''
            }
          >
            <option value="">Todos</option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
          </select>
        );
      },
    },
    {
      /* eslint-disable react/display-name */
      Header: () => <strong>Fecha</strong>,
      accessor: 'createdAt',
      /* eslint-disable react/display-name */
      Cell: (row) => {
        return (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        );
      },
      Filter: (cell) => {
        return <MUIDatePickerFilter cell={cell} />;
      },
    },
  ];

  useEffect(() => {
    setActionButton(
      <Grid container justify="flex-start">
        <MuiPickersUtilsProvider utils={MomentUtils} locale={'es'}>
          <DatePicker
            id="mui-pickers-firstDate"
            autoOk
            disableToolbar
            disableFuture
            variant="inline"
            label="Inicial"
            format="DD/MM"
            className={classes.datePicker}
            value={firstDate}
            onChange={handleFirstDate}
            InputProps={{
              endAdornment: firstDate ? (
                <IconButton
                  aria-label="Select locale"
                  size="small"
                  onClick={handleClearFirstDate}
                >
                  <Close />
                </IconButton>
              ) : null,
            }}
          />
          <span className={classes.range}>&nbsp;A&nbsp;</span>
          <DatePicker
            id="mui-pickers-secondDate"
            autoOk
            disableToolbar
            disableFuture
            variant="inline"
            label="Final"
            format="DD/MM"
            className={classes.datePicker}
            value={secondDate}
            onChange={handleSecondDate}
            InputProps={{
              endAdornment: secondDate ? (
                <IconButton
                  aria-label="Select locale"
                  size="small"
                  onClick={handleClearSecondDate}
                >
                  <Close />
                </IconButton>
              ) : null,
            }}
          />
        </MuiPickersUtilsProvider>
        <CSVLink
          data={dataToCSV}
          filename="Evaluaciones.csv"
          onClick={() => {
            if (disableDownloadCSV) return false;
          }}
        >
          <Button
            variant="contained"
            className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-t-10"
            disabled={disableDownloadCSV}
          >
            Exportar
            <GetApp className="kt-margin-l-10" />
          </Button>
        </CSVLink>
      </Grid>,
    );
  }, [dataToCSV, disableDownloadCSV, firstDate, secondDate]);

  const renderErrorDatesModal = () => {
    return (
      <Modal
        open={showDateErrorModal}
        closeModal={handleCloseModals}
        type="error"
        dialogTitle="Error en las fechas"
        dialogText="Por favor revise que la fecha inicial y la fecha final no estén invertidas ya que de lo contrario la información podría no ser correcta ni podrá descargar los reportes."
        actionButtonText="Aceptar"
        onClick={handleCloseModals}
      />
    );
  };

  const handleModalFail = () => {
    const { errorModal } = reviews;
    return (
      <Modal
        open={errorModal.show}
        closeModal={closeModals}
        type="errorModal"
        dialogTitle="Error en la orden"
        dialogText={errorModal.message}
        actionButtonText="Cerrar"
        onClick={closeModals}
      />
    );
  };

  return (
    <div className={classes.root}>
      {renderErrorDatesModal()}
      {handleModalFail()}
      <Paper className={classes.paper}>
        <ReactTable
          NoDataComponent={() => (
            <div className="rt-noData">Sin resultados</div>
          )}
          onFetchData={handleFetchData}
          data={reviewList || []}
          columns={columns}
          filterable
          manual
          defaultFilterMethod={filterCaseInsensitive}
          className="-striped -highlight"
          previousText="Anterior"
          nextText="Siguiente"
          pages={reviews?.reviews?.pages}
          loading={reviews.isLoading}
          loadingText="Cargando datos..."
        />
      </Paper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  reviews: selectServiceReview(state),
  reviewsFromdb: makeSelectServiceReview(state),
  reviewsFilter: makeSelectServiceReviewFilters(state),
  role: makeSelectUserRoleFromState(state),
});

const mapDispatchToProps = {
  getServiceReviews,
  closeModals,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(ServiceReviewsTable),
);
