import { createSelector } from 'reselect';
import { initialState } from './initialState';

export const selectKarloOffers = (state) =>
  state.karloOffers || initialState;

export const makeSelectKarloOffers = createSelector(
  selectKarloOffers,
  (karloOffersState) => karloOffersState,
);

export const makeSelectKarloVehiclesOffers = createSelector(
  selectKarloOffers,
  (karloOffersState) => karloOffersState.offerdb,
);

export const makeSelectOfferDetails = createSelector(
  selectKarloOffers,
  (karloOffersState) => karloOffersState.offerDetails,
);

export const makeSelectKarloOffersIsLoading = createSelector(
  selectKarloOffers,
  (karloOffersState) => karloOffersState.isLoading,
);
