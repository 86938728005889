import {
  Button,
  CircularProgress,
  Typography,
} from '@material-ui/core';

import { Check } from '@material-ui/icons';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Controller } from 'react-hook-form';
import Input from 'app/components/Input';
import Modal from 'app/components/Modal';
import { MuiCurrencyFormat } from 'app/components/MUICurrencyFormat';
import React from 'react';
import clsx from 'clsx';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import RecentActivities from '../../../../components/RecentActivities';

const useStyles = makeStyles((theme) => ({
  textField: {
    margin: theme.spacing(1),
    width: '97%',
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'left',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1.5),
  },
  wrapper: {
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  agency: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    margin: theme.spacing(0),
  },
  logo: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  imgDesc: {
    paddingLeft: theme.spacing(1),
  },
  image: {
    height: '50px',
    width: 'auto',
  },
  submitButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2, 2, 0, 2),
  },
}));

const GeneralConfig = ({
  agencyDetails,
  data,
  agencies,
  setData,
  handleClose,
  updateAgency,
  open,
  handleClickOpen,
  updloadLogo,
  control,
  errors,
  handleSubmit,
  getSetuplogs,
}) => {
  const classes = useStyles();
  const [sizeError, setSizeError] = React.useState(false);

  const buttonClassname = clsx({
    [classes.buttonSuccess]: agencies.logoUploaded,
  });

  const renderSizeError = () => {
    return sizeError ? (
      <p className="custom-error">Tu archivo pesa más de 5MB</p>
    ) : null;
  };

  const handleFile = (selectedFiles) => {
    setSizeError(false);
    if (!selectedFiles || selectedFiles.length === 0) return;

    if (selectedFiles[0].size > 5000000) {
      setSizeError(true);
      return;
    }

    if (selectedFiles[0] != null) {
      if (selectedFiles[0].size !== 0) {
        const formData = new FormData();
        formData.append(`file0`, selectedFiles[0]);
        return updloadLogo(formData);
      }
    }
    return;
  };

  const handleUpdate = () => {
    const body = {
      type: 'UPDATEAGENCY',
      data,
    };
    handleClose();
    updateAgency(data);
    getSetuplogs(body);
  };

  const renderChangeAgencyLogo = () => {
    return (
      <>
        <div className={classes.buttonGroup}>
          <input
            accept="image/*"
            hidden
            id="raised-button-file"
            type="file"
            onChange={(e) => handleFile(e.target.files)}
          />
          <label
            htmlFor="raised-button-file"
            className={classes.wrapper}
          >
            <Button
              variant="contained"
              component="span"
              color="secondary"
              className={buttonClassname}
              disabled={agencies.isLoading}
            >
              {agencies.logoUploaded
                ? 'Logo Cargado'
                : 'Cargar un logo de agencia'}
              {agencies.logoUploaded ? (
                <Check className={classes.rightIcon} />
              ) : (
                <CloudUploadIcon className={classes.rightIcon} />
              )}
            </Button>
            {agencies.isLoading && (
              <CircularProgress
                size={24}
                className={classes.buttonProgress}
              />
            )}
          </label>
        </div>
        <Typography
          variant="h6"
          gutterBottom
          className={classes.imgDesc}
        >
          Solamente se aceptan imágenes JPEG, PNG y SVG de máximo 5mb
        </Typography>
        {renderSizeError()}
      </>
    );
  };

  const handleUpdateAgency = (formData) => {
    let cleanValues = agencies.logoUploaded
      ? {
          ...formData,
          logo: agencies.logoURL,
        }
      : {
          ...formData,
        };
    setData(cleanValues);
    handleClickOpen();
  };

  const renderConfirmation = () => {
    return (
      <Modal
        type="warning"
        open={open}
        closeModal={handleClose}
        dialogTitle=" ¿Seguro que deseas editar la agencia?"
        dialogText="Se actualizará la agencia con la nueva información que
        proporciones"
        actionButtonText="Aceptar"
        onClick={handleUpdate}
      />
    );
  };

  return (
    <>
      {renderConfirmation()}
      <form
        className={classes.container}
        onSubmit={handleSubmit(handleUpdateAgency)}
        noValidate
        autoComplete="off"
      >
        <Input
          disabled
          required
          value={agencyDetails?.name}
          label="Nombre de la agencia"
          className={classes.textField}
          error={Boolean(errors.name)}
        />
        <Input
          disabled
          required
          value={agencyDetails?.phoneNumber}
          label="Número telefónico de la agencia"
          className={classes.textField}
          error={Boolean(errors.phoneNumber)}
        />
        <Input
          disabled
          required
          value={agencyDetails?.email}
          label="Correo electrónico de la agencia"
          className={classes.textField}
          error={Boolean(errors.email)}
        />
        <Input
          disabled
          required
          value={agencyDetails?.rfc}
          label="RFC de la agencia"
          className={classes.textField}
          error={Boolean(errors.rfc)}
        />
        <Input
          disabled
          required
          value={agencyDetails?.nickname}
          label="Nickname de la agencia"
          className={classes.textField}
          error={Boolean(errors.nickname)}
        />
        <Controller
          name="amountLetter"
          control={control}
          render={(props) => (
            <MuiCurrencyFormat
              className={classes.textField}
              label="Monto para envío de confirmación de carta a cliente"
              helperText="0 = desactivado"
              error={Boolean(errors.amountLetter)}
              {...props}
            />
          )}
        />
        {renderChangeAgencyLogo()}
        <div className={classes.submitButtonContainer}>
          <Button
            disabled={agencies.isLoading}
            type="submit"
            variant="contained"
            color="primary"
          >
            Actualizar
          </Button>
        </div>
      </form>
      {/* <RecentActivities /> */}
    </>
  );
};

export default GeneralConfig;
