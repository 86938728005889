import React, { useCallback, useEffect, useState } from 'react';

import {
  Button,
  IconButton,
  makeStyles,
  TextField,
  Box,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {
  GetApp,
  Close,
  ArrowDropDown,
  ExpandMore,
} from '@material-ui/icons';

import {
  formatNumber,
  paymentsInfoBase,
  paymentsTypeInfoBase,
} from '../../../../utils';
import { MenuOption } from 'app/components/MenuOption';
import GridContainer from 'app/components/Grid/GridContainer';
import GridItem from 'app/components/Grid/GridItem';
import MUIAutocompleteMultipleAgencies from 'app/components/MUIAutocompleteMultipleAgencies';
import Modal from '../../../../components/Modal';
import api from 'app/config/api';

import OrderStatisticsChart from '../../../../widgets/OrderStatisticsChart';
import OrdersWidget from '../../../../widgets/OrdersWidget';
import QuickStatsChart from '../../../../widgets/QuickStatsChart';
import QuickStatsTextWidget from '../../../../widgets/QuickStatsTextWidget';

import { metronic } from '../../../../../_metronic';

import {
  Portlet,
  PortletBody,
  PortletHeader,
} from '../../../../partials/content/Portlet';

import { getPDFReportSaga } from 'app/features/dashboard/sagas';
import { closeModals } from 'app/features/modals/modalsSlice';
import {
  cleanDashboardInfo,
  getData,
  getPDFReport,
  getAgenciesForDashboard,
  getAgenciesForDashboardFail,
  getAgenciesForDashboardSuccess,
} from 'app/features/dashboard/dashboardSlice';
import { getAgencies } from 'app/features/agencies/agenciesSlice';
import {
  makeSelectUserRoleFromState,
  selectAuth,
} from '../../../../features/auth/selectors';
import {
  makeSelectPaymentOrdersFromDashboard,
  makeSelectPaymentsFromDashboard,
  makeSelectServiceOrdersFromDashboard,
  makeSelectDashboardAgencies,
} from '../../../../features/dashboard/selectors';

import { connect, useSelector } from 'react-redux';
import MomentUtils from '@date-io/moment';
import clsx from 'clsx';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  agenciesSelect: {
    minWidth: theme.spacing(25),
    marginTop: theme.spacing(1),
  },
  datePicker: {
    width: '90px',
  },
  datePickerRight: {
    marginRight: theme.spacing(3),
  },
  range: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: '30px',
  },
  icons: {
    color: '#0abb87',
    marginRight: '10px',
  },
  colorPrimary: {
    color: '#0abb87',
  },
  menuDesktop: {
    ['@media screen and (max-width: 1496px)']: {
      // eslint-disable-line no-useless-computed-key
      display: 'none',
    },
  },
  menuMobile: {
    ['@media screen and (min-width: 1495px)']: {
      // eslint-disable-line no-useless-computed-key
      display: 'none',
    },
  },
  multiselect: {
    minWidth: '200px',
    width: 'auto',
    maxWidth: '420px',
  },
  agencySelectedTitle: {
    fontSize: '1.2rem',
    fontWeight: '500',
    color: '#48465b',
    marginBottom: '20px',
  },
  agencySelected: {
    fontSize: '1.1rem',
    fontWeight: '400',
    color: '#74788d',
  },
}));

const Dashboard = (props) => {
  const API_URL = api();
  const classes = useStyles();
  const {
    serviceOrders,
    paymentOrders,
    payments,
    cleanDashboardInfo,
    getData,
    setActionButton,
    role,
    getAgenciesForDashboard,
    agencies,
    userName,
  } = props;

  const today = new Date();

  const { handleSubmit, register, errors, formState } = useForm();

  const { brandColor } = useSelector((state) => ({
    brandColor: metronic.builder.selectors.getConfig(
      state,
      'colors.state.brand',
    ),
  }));

  let percentageKarlo = '';

  const [endDate, setEndDate] = useState(moment());
  const [resumeToCSV, setResumeToCSV] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAddAgencyModal, setShowAddAgencyModal] = useState(false);
  const [odsPay, setOdsPay] = useState(0);
  const [odpPay, setOdpPay] = useState(0);
  const [noOdsPay, setNoOdsPay] = useState(0);
  const [noOdpPay, setNoOdpPay] = useState(0);
  const [downloadLock, setDownloadLock] = useState(false);
  const [showSendEmailModal, setShowSendEmailModal] = useState(false);
  const [downloadButton, setDownloadButton] = useState(false);
  const [agency, setAgency] = useState('');
  const [agenciesList, setAgenciesList] = useState([]);
  const [agenciesName, setAgenciesName] = useState([]);
  const [firstDate, setFirstDate] = useState(
    moment().subtract(2, 'weeks')
  );
  const [paymentsInfo, setPaymentsInfo] = useState(paymentsInfoBase);
  const [paymentsTypeInfo, setPaymentsTypeInfo] = useState(
    paymentsTypeInfoBase,
  );

  const userInfo = {
    role,
    userName,
  };

  const urlReport = `/dashboard/dashboardReport?firstDate=${firstDate}&endDate=${endDate}&agencyID=${agenciesList[0]}&totalOrdenes=${serviceOrders.count + paymentOrders.count
    }&totalODS=${serviceOrders.count}&totalODP=${paymentOrders.count
    }&facturacionODS=${serviceOrders.globalTotal}&facturacionODP=${paymentOrders.globalTotal
    }&totalPagos=${payments.count
    }&noOdsPay=${noOdsPay}&noOdpPay=${noOdpPay}&karloTotal=${payments.total
    }&karloODS=${odsPay}&karloODP=${odpPay}&tresMeses=${paymentsInfo[3].count
    }&seisMeses=${paymentsInfo[6].count}&nueveMeses=${paymentsInfo[9].count
    }&doceMeses=${paymentsInfo[12].count}&contado=${paymentsInfo.cash.count
    }&userName=${userName?.user?.name}`;

  useEffect(() => {
    setShowAddAgencyModal(true);
  }, [agencies]);

  useEffect(() => {
    if (firstDate > endDate || agency === '') {
      setDownloadLock(true);
    } else {
      setDownloadLock(false);
    }
  }, [firstDate, endDate, agency]);

  useEffect(() => {
    if (
      userName?.user?.agencyID &&
      firstDate &&
      endDate &&
      role !== 'Super Admin' &&
      role !== 'Super Admin Agencies'
    ) {
      getData({
        agencyId: agenciesList,
        firstDate: firstDate.startOf('day'),
        endDate: endDate.endOf('day'),
      });
    }
  }, [firstDate, endDate, agenciesList]);

  useEffect(() => {
    if (
      userName?.user?.agencyID &&
      role !== 'Super Admin' &&
      role !== 'Super Admin Agencies'
    ) {
      setAgenciesList([userName?.user?.agencyID]);
    }
  }, [userName?.user?.agencyID]);

  useEffect(() => {
    if (role === 'Super Admin') {
      getAgencies();
    }
  }, []);

  useEffect(() => {
    if (agency) {
      setDownloadButton(true);
    }
  }, [agency]);

  useEffect(() => {
    handleMonthsInfo();
  }, [payments, serviceOrders, paymentOrders]);

  useEffect(() => {
    if (role === 'Super Admin') {
      cleanDashboardInfo();
    }
  }, []);

  useEffect(() => {
    if (agencies) {
      const ToCSV = agencies.map((agencyData) => {
        const name = agencyData.name;
        const noOdpOdsR = agencyData.noOdpOdsR;
        const facOdpOdsR = agencyData.facOdpOdsR;
        const noOdpOdsPay = agencyData.noOdpOdsPay;
        const facOdpOdsPay = agencyData.facOdpOdsPay;
        const noODSR = agencyData.noOdsR;
        const facODSR = agencyData.facOdsR;
        const noODPR = agencyData.noODPR;
        const facODPR = agencyData.facODPR;
        const noODPServiceR = agencyData.noODPServiceR;
        const noODPSaleR = agencyData.noODPSaleR;
        const noODPPartsR = agencyData.noODPPartsR;
        const noODPFiR = agencyData.noODPFiR;
        const facODPServiceR = agencyData.facODPServiceR;
        const facODPSaleR = agencyData.facODPSaleR;
        const facODPPartsR = agencyData.facODPPartsR;
        const facODPFiR = agencyData.facODPFiR;
        const noODSPay = agencyData.noODSPay;
        const noODPPays = agencyData.noODPPays;
        const noODPServicePay = agencyData.noODPServicePay;
        const noODPSalePay = agencyData.noODPSalePay;
        const noODPPartsPay = agencyData.noODPPartsPay;
        const noODPFiPay = agencyData.noODPFiPay;
        const facODSPay = agencyData.facODSPay;
        const facODPPay = agencyData.facODPPay;
        const facODPServicePay = agencyData.facODPServicePay;
        const facODPSalesPay = agencyData.facODPSalesPay;
        const facODPFiPay = agencyData.facODPFiPay;
        const facODPPartsPay = agencyData.facODPPartsPay;
        const noSOPayInAgency = agencyData.noSOPayInAgency;
        const facSOPayInAgency = agencyData.facSOPayInAgency;
        //data filling
        const dataToCSV = {
          'Nombre de Agencia': name || '-',
          //
          //Total
          //Registradas
          'No. ODS/ODP Registradas': noOdpOdsR || '0',
          'Facturación ODS/ODP Registradas': facOdpOdsR || '0',
          //pagadas
          'No. ODS/ODP pagadas': noOdpOdsPay || '0',
          'Facturación ODS/ODP pagadas': facOdpOdsPay || '0',
          //ODS
          //registrado
          'No. ODS registradas': noODSR || '0',
          'Facturación ODS registradas': facODSR || '0',
          //pagado
          'No. ODS pagadas': noODSPay || '0',
          'Facturación ODS pagadas': facODSPay || '0',
          //Pagadas en agencia
          'No. ODS pagadas en la agencia': noSOPayInAgency || '0',
          'Facturación ODS pagadas en la agencia':
            facSOPayInAgency || '0',
          //ODP
          //agencia
          //total registrado
          'No. ODP Registradas': noODPR || '0',
          'Facturacion ODP Registrado': facODPR || '0',
          //servicio
          'No. ODP Servicio Registradas': noODPServiceR || '0',
          'Facturación ODP Servicio Registrado':
            facODPServiceR || '0',
          //ventas
          'No. ODP Ventas Registradas': noODPSaleR || '0',
          'Facturación ODP Ventas Registrado': facODPSaleR || '0',
          //refacciones
          'No. ODP Refacciones Registradas': noODPPartsR || '0',
          'Facturación ODP Refacciones Registrado':
            facODPPartsR || '0',
          //F&I
          'No. ODP F&I Registradas': noODPFiR || '0',
          'Facturación ODP F&I Registrado': facODPFiR || '0',
          //Karlo
          //Total registrado
          'No. ODP Pagadas': noODPPays || '0',
          'Facturacion ODP Pagadas': facODPPay || '0',
          //servicio
          'No. ODP Servicio Pagadas': noODPServicePay || '0',
          'Facturación ODP Servicio Pagadas': facODPServicePay || '0',
          //ventas
          'No. ODP Ventas Pagadas': noODPSalePay || '0',
          'Facturación ODP Ventas Pagadas': facODPSalesPay || '0',
          //refacciones
          'No. ODP Refacciones Pagadas': noODPPartsPay || '0',
          'Facturación ODP Refacciones Pagadas':
            facODPPartsPay || '0',
          //F&I
          'No. ODP F&I Pagadas': noODPFiPay || '0',
          'Facturación ODP F&I Pagadas': facODPFiPay || '0',
        };
        return dataToCSV;
      });
      setResumeToCSV(ToCSV);
      setLoading(false);
    }
  }, [agencies]);

  useEffect(() => {
    calculateOdpOdsPay();
  }, [payments, agencies, firstDate, endDate]);

  useEffect(() => {
    setActionButton(
      <Grid container justify="flex-start">
        <MuiPickersUtilsProvider utils={MomentUtils} locale={'es'}>
          <DatePicker
            autoOk
            disableToolbar
            disableFuture
            size="small"
            variant="inline"
            label="Inicial"
            format="DD/MM"
            className={classes.datePicker}
            value={firstDate}
            onChange={handleFirstDate}
            InputProps={{
              endAdornment: firstDate ? (
                <IconButton
                  aria-label="Select locale"
                  size="small"
                  onClick={handleClearFirstDate}
                >
                  <Close />
                </IconButton>
              ) : null,
            }}
          />
          <span className={classes.range}>&nbsp;A&nbsp;</span>
          <DatePicker
            autoOk
            disableToolbar
            disableFuture
            size="small"
            variant="inline"
            label="Final"
            format="DD/MM"
            className={clsx(
              classes.datePicker,
              classes.datePickerRight,
            )}
            value={endDate}
            onChange={handleendDate}
            InputProps={{
              endAdornment: endDate ? (
                <IconButton
                  aria-label="Select locale"
                  size="small"
                  onClick={handleClearendDate}
                >
                  <Close />
                </IconButton>
              ) : null,
            }}
          />
        </MuiPickersUtilsProvider>
        {role === 'Super Admin' || role === 'Super Admin Agencies' ? (
          <div className={classes.multiselect}>
            <MUIAutocompleteMultipleAgencies
              // prettier-ignore
              newAgency={(agency) => setAgency(agency)}
              agenciesList={(agencies) => setAgenciesList(agencies)}
              agenciesNames={(agencies) => setAgenciesName(agencies)}
              dashboarAgency={userName?.user?.agencyID}
              userInfo={userInfo}
            />
          </div>
        ) : null}
        <div className={classes.menuDesktop}>
          {role === 'Super Admin' ||
            role === 'Super Admin Agencies' ? (
            <Button
              variant="contained"
              className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-r-5 kt-margin-t-10"
              onClick={handlerFindAgencies}
              disabled={agenciesList.length === 0}
            >
              Obtener datos
            </Button>
          ) : null}
          <Button
            variant="contained"
            className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-r-5 kt-margin-t-10"
            onClick={agenciesList.length > 0 ? getDataForCSV : ''}
            disabled={agenciesList.length <= 0 ? getDataForCSV : ''}
          >
            Excel
            <GetApp className="kt-margin-l-10" />
          </Button>
          {(role === 'Super Admin' || role === 'Super Admin Agencies') && agenciesList.length !== 1 ? null :
            <a
              href={`${API_URL}${urlReport}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                variant="contained"
                className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-r-5 kt-margin-t-10"
                disabled={agenciesList.length === 1 ? false : true}
              >
                Reporte PDF
                <GetApp className="kt-margin-l-10" />
              </Button>
            </a>
          }
        </div>
        <div className={classes.menuMobile}>
          <MenuOption
            Button={
              <Button
                variant="contained"
                className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-t-10"
              >
                Ver opciones
                <ArrowDropDown className="kt-margin-l-10" />
              </Button>
            }
            options={optionByRole()}
          />
        </div>
      </Grid>,
    );
    return () => {
      setActionButton(null);
    };
  }, [
    agency,
    firstDate,
    endDate,
    setActionButton,
    resumeToCSV,
    downloadLock,
    paymentOrders,
    serviceOrders,
    payments,
    noOdsPay,
    noOdpPay,
    odpPay,
    odsPay,
    paymentsInfo,
    userName,
    agenciesList,
  ]);

  const handlerFindAgencies = () => {
    if (
      (agenciesList.length > 0 || userName?.user?.agencyID) &&
      firstDate &&
      endDate
    ) {
      getData({
        agencyId:
          agenciesList.length > 0
            ? agenciesList
            : [userName?.user?.agencyID],
        firstDate: firstDate.startOf('day'),
        endDate: endDate.endOf('day'),
      });
    }
  };

  const optionByRole = () => {
    const optionsMobile = [
      {
        name: (
          <Typography
            onClick={
              agenciesList.length > 0 ? handlerFindAgencies : ''
            }
            className={classes.colorPrimary}
          >
            Obtener datos
          </Typography>
        ),
        onClick: genericFuntion,
      },
      {
        name: (
          <Typography
            onClick={agenciesList.length > 0 ? getDataForCSV : ''}
            className={classes.colorPrimary}
          >
            <GetApp className={classes.icons} />
            Excel
          </Typography>
        ),
        onClick: genericFuntion,
      },
      {
        name: (
          <Typography className={classes.colorPrimary}>
            <GetApp className={classes.icons} />
            <a
              className={classes.colorPrimary}
              style={{
                pointerEvents: downloadButton ? '' : 'none',
              }}
              href={`${API_URL}${urlReport}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Reporte PDF
            </a>
          </Typography>
        ),
        onClick: genericFuntion,
      },
    ];

    if (role !== 'Super Admin' && role !== 'Super Admin Agencies') {
      optionsMobile.shift();
    }

    return optionsMobile;
  };

  const handleButtonClick = () => {
    if (!loading) {
      const resetCSV = [];
      setResumeToCSV(resetCSV);
      setLoading(true);
    }
  };

  const genericFuntion = () => {
    // Don't delete this funtion, is called by the top bar menu
    // buttons that do not require the component's onClick function
  };

  const handleCloseModals = () => {
    setShowAddAgencyModal(!showAddAgencyModal);
  };

  const getDataForCSV = () => {
    getAgenciesForDashboard({
      agency: agenciesList,
      firstDate,
      endDate,
    });
  };

  const handleFirstDate = (date) => {
    setFirstDate(date);
    handleButtonClick();
  };

  const handleendDate = (date) => {
    setEndDate(date);
    handleButtonClick();
  };

  const handleClearFirstDate = () => {
    setFirstDate(null);
  };

  const handleClearendDate = () => {
    setEndDate(null);
  };

  const toogleShowSendEmailModal = () =>
    setShowSendEmailModal(!showSendEmailModal);

  const handleSubmitEmail = (values) => {
    const email = values.email;
    getAgenciesForDashboard({
      agency: '',
      firstDate,
      endDate,
      email,
    });
    toogleShowSendEmailModal();
  };

  const calculatePercentage = () => {
    percentageKarlo =
      (payments?.fromdb?.length || 0 * 100) /
      (serviceOrders?.count + paymentOrders?.count);
    if (isNaN(percentageKarlo) || !isFinite(percentageKarlo)) {
      percentageKarlo = '-';
    } else {
      if (percentageKarlo > 100) percentageKarlo = 100;
      percentageKarlo = parseFloat(percentageKarlo).toFixed(2);
    }
  };

  const calculateOdpOdsPay = () => {
    let tempOdsPay = 0;
    let tempOdpPay = 0;
    let tempNoOdsPay = 0;
    let tempNoOdpPay = 0;

    if (payments.fromdb) {
      payments.fromdb.forEach((cursor) => {
        if (cursor.paymentOrderID) {
          if (
            cursor.status === 'pagado' ||
            cursor.status === 'facturada'
          ) {
            tempOdpPay += cursor.total;
            tempNoOdpPay++;
          }
        } else if (cursor.serviceOrderID) {
          if (
            cursor.status === 'payment' ||
            cursor.status === 'billed'
          ) {
            tempOdsPay += cursor.total;
            tempNoOdsPay++;
          }
        }
      });
      tempOdpPay = formatNumber(tempOdpPay);
      tempOdsPay = formatNumber(tempOdsPay);
      setOdsPay(tempOdsPay);
      setOdpPay(tempOdpPay);
      setNoOdsPay(tempNoOdsPay);
      setNoOdpPay(tempNoOdpPay);
    }
  };

  const calculateOdpPay = () => {
    let sales = 0;
    let service = 0;
    let parts = 0;
    let fi = 0;
    let pulled_apart = 0;
    let salesTotal = 0;
    let fiTotal = 0;
    let serviceTotal = 0;
    let partsTotal = 0;
    let pulled_apartTotal = 0;

    if (payments.fromdb) {
      payments.fromdb.forEach((cursor) => {
        if (cursor.paymentOrderID) {
          switch (cursor.paymentOrderType) {
            case 'sales':
              salesTotal += cursor.total;
              sales++;
              break;
            case 'service':
              serviceTotal += cursor.total;
              service++;
              break;
            case 'parts':
              partsTotal += cursor.total;
              parts++;
              break;
            case 'fi':
              fiTotal += cursor.total;
              fi++;
              break;
            case 'pulled_apart':
              pulled_apartTotal += cursor.total;
              pulled_apart++;
              break;
          }
        }
      });
    }
    const paymentOrders = {
      sales,
      service,
      parts,
      fi,
      salesTotal,
      fiTotal,
      serviceTotal,
      partsTotal,
      pulled_apart,
      pulled_apartTotal,
    };
    return paymentOrders;
  };

  const handleMonthsInfo = () => {
    const tempPaymentsInfo = paymentsInfo;
    const tempPaymentsTypeInfo = paymentsTypeInfo;
    //SETTING PAYMENTS IN CASH AND MONTHS
    tempPaymentsInfo.cash = {
      ...tempPaymentsInfo.cash,
      count: payments.cash,
    };
    tempPaymentsInfo['3'] = {
      ...tempPaymentsInfo['3'],
      count: payments.months['3'] || 0,
    };
    tempPaymentsInfo['6'] = {
      ...tempPaymentsInfo['6'],
      count: payments.months['6'] || 0,
    };
    tempPaymentsInfo['9'] = {
      ...tempPaymentsInfo['9'],
      count: payments.months['9'] || 0,
    };
    tempPaymentsInfo['12'] = {
      ...tempPaymentsInfo['12'],
      count: payments.months['12'] || 0,
    };
    //SETTING PAYMENTS TYPE
    tempPaymentsTypeInfo.visa = {
      ...tempPaymentsTypeInfo.visa,
      count: payments['DEBIT VISA'] + payments['CREDIT VISA'],
    };
    tempPaymentsTypeInfo.master = {
      ...tempPaymentsTypeInfo.master,
      count:
        payments['CREDIT MASTERCARD'] + payments['DEBIT MASTERCARD'],
    };
    tempPaymentsTypeInfo.amex = {
      ...tempPaymentsTypeInfo.amex,
      count: payments['CREDIT AMERICAN-EXPRESS'],
    };
    tempPaymentsTypeInfo.oxxo = {
      ...tempPaymentsTypeInfo.oxxo,
      count: payments['OXXO'],
    };
    tempPaymentsTypeInfo.spei = {
      ...tempPaymentsTypeInfo.spei,
      count: payments['SPEI'],
    };
    setPaymentsInfo(tempPaymentsInfo);
    setPaymentsTypeInfo(tempPaymentsTypeInfo);
  };

  const getDataForPaymentsTypeInfo = useCallback(
    (value) => {
      return Object.keys(paymentsTypeInfo).map(
        (key) => paymentsTypeInfo[key][value],
      );
    },
    [paymentsTypeInfo],
  );

  const chartOptions = {
    chart1: {
      name: 'Total órdenes',
      count: serviceOrders?.count + paymentOrders?.count || null,
      data: {
        labels: ['ODS', 'ODP'],
        datasets: [
          {
            labels: ['ODS', 'ODP'],
            data: [serviceOrders?.count, paymentOrders?.count],
            backgroundColor: ['#FFC233', '#22CECE'],
          },
        ],
      },
      color: brandColor,
      border: 3,
    },
    chart2: {
      name: 'Pagos realizados',
      count: payments?.count,
      data: {
        datasets: [
          {
            labels: ['ODS', 'ODP'],
            data: [noOdsPay, noOdpPay],
            backgroundColor: ['#FFC233', '#22CECE'],
          },
        ],
      },
      color: brandColor,
      border: 3,
    },
    chart3: {
      name: 'Pagos con tarjeta',
      count: payments?.count,
      data: {
        labels: getDataForPaymentsTypeInfo('name'),
        datasets: [
          {
            labels: getDataForPaymentsTypeInfo('name'),
            data: getDataForPaymentsTypeInfo('count'),
            backgroundColor: [
              '#FFC233',
              '#22CECE',
              '#f79c1d',
              '#9c2c2c',
              '#f86385',
            ],
          },
        ],
      },
      color: brandColor,
      border: 3,
    },
  };

  const renderFeedBackModal = () => {
    try {
      if (agencies) {
        const { successModal, errorModal } = agencies;
        if (
          (successModal &&
            'show' in successModal &&
            successModal.show) ||
          (errorModal && 'show' in errorModal && errorModal.show)
        ) {
          if (
            (successModal &&
              'show' in successModal &&
              successModal.show) ||
            (errorModal && 'show' in errorModal && errorModal.show)
          ) {
            let modalType = successModal.show
              ? 'successModal'
              : errorModal.show
                ? 'errorModal'
                : null;
            const { message } = agencies[modalType];
            let buttonText;
            if (message.title === 'Información lista') {
              const dateRange =
                // prettier-ignore
                moment(firstDate).format('DD-MM') +
                '_' +
                // prettier-ignore
                moment(endDate).format('DD-MM');
              buttonText = (
                <>
                  <CSVLink
                    data={resumeToCSV}
                    filename={'Agencias_' + dateRange + '.csv'}
                    onClick={() => {
                      return resumeToCSV.length > 0;
                    }}
                  >
                    Aceptar
                  </CSVLink>
                </>
              );
            } else {
              buttonText = 'Aceptar';
            }
            if (message.title === 'Error') {
              modalType = 'warning';
            }
            return (
              <Modal
                open={showAddAgencyModal}
                type={modalType}
                closeModal={handleCloseModals}
                dialogTitle={message.title}
                dialogText={message.desc}
                onClick={handleCloseModals}
                actionButtonText={buttonText}
              />
            );
          }
        }
      }
    } catch (error) {
      handleCloseModals();
      return null;
    }
  };

  const renderSendReceiptDialog = () => {
    return (
      <Modal
        open={showSendEmailModal}
        closeModal={toogleShowSendEmailModal}
        dialogTitle="Enviar reporte"
        dialogText="Se enviará el reporte de todas las agencias al correo que ingrese."
        actionButtonText="Enviar"
        dialogChildren={
          <form
            className={classes.container}
            id="email-form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(handleSubmitEmail)}
          >
            <TextField
              name="email"
              label="Correo"
              className={classes.textField}
              error={errors.email && formState.touched.email}
              inputRef={register({
                required: true,
                pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
              margin="normal"
              variant="outlined"
            />
          </form>
        }
        form={'email-form'}
      />
    );
  };

  return (
    <>
      <GridContainer>
        {agenciesName.length > 0 && (
          <GridItem xs={12}>
            <Accordion style={{ marginBottom: '20px' }}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography className={classes.agencySelectedTitle}>
                  Agencias Seleccionadas
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  container
                  spacing={1}
                  style={{ marginBottom: '15px' }}
                >
                  {agenciesName.map((agencyName) => {
                    return (
                      <Grid item xs={6} sm={4} lg={3}>
                        <Typography
                          className={classes.agencySelected}
                        >
                          {agencyName.length > 23
                            ? `${agencyName.substring(0, 22)}...`
                            : agencyName}
                        </Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </GridItem>
        )}
        <GridItem xs={12} sm={6} lg={6}>
          <Portlet className="kt-portlet--border-bottom-brand">
            <PortletBody fluid={true}>
              <QuickStatsChart
                value={chartOptions.chart1.count}
                desc={chartOptions.chart1.name}
                data={chartOptions.chart1.data}
                color={chartOptions.chart1.color}
                border={chartOptions.chart1.border}
                paymentOrders={paymentOrders}
              />
            </PortletBody>
          </Portlet>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={6}>
          <Portlet className="kt-portlet--border-bottom-brand">
            <PortletBody>
              <QuickStatsChart
                value={chartOptions.chart2.count}
                desc={chartOptions.chart2.name}
                data={chartOptions.chart2.data}
                color={chartOptions.chart2.color}
                border={chartOptions.chart2.border}
                paymentOrders={calculateOdpPay()}
              />
            </PortletBody>
          </Portlet>
        </GridItem>
      </GridContainer>
      {calculatePercentage()}
      {renderFeedBackModal()}
      {renderSendReceiptDialog()}
      <GridContainer>
        <GridItem xs={12} md={6} lg={6}>
          <Portlet className="kt-portlet--border-bottom-brand">
            <PortletBody fluid={true}>
              <QuickStatsTextWidget
                value={formatNumber(
                  serviceOrders?.globalTotal +
                  paymentOrders?.globalTotal,
                )}
                desc="$ total de órdenes"
              />
              <Box ml={1}>
                {formatNumber(paymentOrders?.globalTotal)}
                {' de Ordenes de Pago'}
              </Box>
              <Box ml={1}>
                {formatNumber(serviceOrders?.globalTotal)}
                {' de Ordenes de Servicio'}
              </Box>
            </PortletBody>
          </Portlet>
        </GridItem>
        <GridItem xs={12} md={6} lg={6} style={{ width: 'auto' }}>
          <Portlet className="kt-portlet--border-bottom-brand">
            <PortletBody fluid={true}>
              <QuickStatsTextWidget
                value={formatNumber(payments?.total)}
                desc="$ desde Karlo"
              />
              <Box ml={1}>
                {odsPay}
                <br></br> Pagado de ODS
              </Box>
              <Box ml={1}>{odpPay}<br></br>De Pagado de ODP</Box>
              <Box ml={1}>{percentageKarlo}% <br></br> De ordenes pagadas</Box>
            </PortletBody>
          </Portlet>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Portlet fluidHeight={true}>
            <PortletHeader title="Pagos en los últimos 12 días" />

            <PortletBody>
              <OrderStatisticsChart payments={payments} />
            </PortletBody>
          </Portlet>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={6}>
          <Portlet className="kt-portlet--border-bottom-brand">
            <PortletBody fluid={true}>
              <div className="col-xl-12">
                <OrdersWidget data={paymentsInfo} />
              </div>
            </PortletBody>
          </Portlet>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={6}>
          <Portlet className="kt-portlet--border-bottom-brand kt-portlet--height-fluid-fourth">
            <PortletBody fluid={true}>
              <QuickStatsTextWidget
                value={payments?.kioskoPayments}
                desc="Pagos desde el kiosko"
              />
            </PortletBody>
          </Portlet>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={6}>
          <Portlet className="kt-portlet--border-bottom-brand">
            <PortletBody>
              <QuickStatsChart
                value={chartOptions.chart3.count}
                desc={chartOptions.chart3.name}
                data={chartOptions.chart3.data}
                color={chartOptions.chart3.color}
                border={chartOptions.chart3.border}
              />
            </PortletBody>
          </Portlet>
        </GridItem>
      </GridContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  paymentOrders: makeSelectPaymentOrdersFromDashboard(state),
  serviceOrders: makeSelectServiceOrdersFromDashboard(state),
  payments: makeSelectPaymentsFromDashboard(state),
  role: makeSelectUserRoleFromState(state),
  userName: selectAuth(state),
  agencies: makeSelectDashboardAgencies(state),
});

const mapDispatchToProps = {
  getData,
  cleanDashboardInfo,
  getAgenciesForDashboard,
  getAgenciesForDashboardFail,
  getAgenciesForDashboardSuccess,
  closeModals,
  getPDFReportSaga,
  getPDFReport,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard);
