import {
  DialogTitle,
  Grid,
  Paper,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
  formatDate,
  formatSingleDate,
  formatNumber,
  getNested,
} from '../../../../../utils';
import {
  makeSelectAgencies,
  selectAgencies,
} from 'app/features/agencies/selectors';
import {
  makeSelectDepositDetailsFromState,
  selectDepositsStandAlone,
} from 'app/features/standAlone/deposits/selectors';

import moment from 'moment';
import ButtonExportExcel from 'app/components/ButtonExportExcel';
import Modal from '../../../../../components/Modal';
import RecentActivities from '../../../../../components/RecentActivities';
import ShouldItRender from 'app/components/ShouldItRender';
import SplashScreen from '../../../../../partials/layout/SplashScreen';
import { Table } from 'react-bootstrap';
import { closeModals } from '../../../../modals/modalsSlice';
import { connect } from 'react-redux';
import { getDepositDetailStandAlone } from 'app/features/standAlone/deposits/depositsSlice';
import { injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { makeSelectDepositsLogs } from '../../../../logs/selectors';
import { makeSelectUserRoleFromState } from 'app/features/auth/selectors';
import { withRouter } from 'react-router';

const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  title: {
    textAlign: 'left',
    color: '#1c1c1c',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  rightContainer: {
    flexDirection: 'column',
  },
  tableTitle: {
    fontWeight: 401,
  },
  clickeableTR: {
    cursor: 'pointer',
  },
  chargebackRow: {
    backgroundColor: '#ffcccb',
  },
  table: {
    width: '100%',
    overflowX: 'auto',
  },
}));

export const DetailDepositStandAlone = (props) => {
  const classes = useStyles();
  const {
    closeModals,
    deposits,
    depositDetails,
    depositLogs,
    getDepositDetailStandAlone,
    setLeftSideComponent,
    setActionButton,
    role,
    match,
    history,
    intl,
    agencies,
  } = props;
  const [totalAmount, setTotalAmount] = useState(false);
  const [totalComissions, setTotalComissions] = useState(false);
  const [totalIVA, setTotalIVA] = useState(false);
  const [totalChargebacks, settTtalChargebacks] = useState(false);
  const [hasSpeiPayments, setHasSpeiPayments] = useState(false);
  const [paymentsData, setPaymentsData] = useState([]);

  useEffect(() => {
    setLeftSideComponent(
      <div className="kt-subheader__main">
        <div>
          <h3 className="kt-subheader__title">
            Detalle del depósito standAlone
          </h3>
        </div>
      </div>,
    );
    let data = {
      id: match.params.id,
      agencyID: match.params.agencyId,
    };
    getDepositDetailStandAlone(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (depositDetails) {
      const hasSpeiPayments =
        depositDetails?.SPEIPayments?.length > 0;
      setHasSpeiPayments(hasSpeiPayments);
      loadPaymentsInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depositDetails]);

  useEffect(() => {
    if (!hasSpeiPayments)
      setActionButton(
        <ButtonExportExcel
          data={paymentsData}
          buttonText="Exportar movimientos"
        />,
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentsData, hasSpeiPayments]);

  const loadPaymentsInfo = () => {
    const standAlonePayments =
      depositDetails?.depositsDetailsStandAlones
        ? depositDetails?.depositsDetailsStandAlones
        : {};
    if (standAlonePayments.length > 0) {
      const exportDataTemp = [];
      let totalAmountTemp = 0;

      standAlonePayments.map((standAlonePayment) => {
        totalAmountTemp += standAlonePayment?.total;
        let dateBpPayment =
          standAlonePayment?.paymentsStandAlone.transactionDate;
        exportDataTemp.push({
          AGENCIA: depositDetails?.agency?.name,
          'ID AGENCIA': depositDetails?.agency?.id,
          DEPOSITOR: depositDetails?.depositor,
          'FECHA DE PAGO': dateBpPayment
            ? formatDate(dateBpPayment)
            : '',
          'MONTO DE TRANSACCIÓN': formatNumber(
            standAlonePayment.paymentsStandAlone.transactionAmount,
          ),
        });
      });
      setPaymentsData(exportDataTemp);
      setTotalAmount(totalAmountTemp);
    }
  };

  const handleCloseModals = () => {
    closeModals();
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = deposits;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
          ? 'errorModal'
          : null;
        const { show, message } = deposits[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={handleCloseModals}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={handleCloseModals}
          />
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  const renderDepostiDetails = () => {
    return (
      <Paper className={classes.paper}>
        <DialogTitle className={classes.title}>
          Resumen del depósito
        </DialogTitle>
        <Table bordered>
          <tbody>
            <tr>
              <td className={classes.tableTitle}>ID DEL DEPÓSITO</td>
              <td>{getNested('-', depositDetails, 'id')}</td>
            </tr>
            <ShouldItRender should={role === 'Super Admin'}>
              <tr>
                <td className={classes.tableTitle}>AGENCIA</td>
                <td>
                  {getNested('-', depositDetails, 'agency', 'name')}
                </td>
              </tr>
            </ShouldItRender>
            <ShouldItRender should={hasSpeiPayments}>
              <tr>
                <td className={classes.tableTitle}>
                  FOLIO DE AUTORIZACIÓN BANCARIA
                </td>
                <td>
                  {getNested(
                    '-',
                    depositDetails,
                    'bankAuthorization',
                  )}
                </td>
              </tr>
            </ShouldItRender>
            <tr>
              <td className={classes.tableTitle}>MONTO</td>
              <td>
                {formatNumber(
                  getNested('-', depositDetails, 'totalToDeposit'),
                )}
              </td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>
                FECHA DE DEPÓSITO
              </td>
              <td>
                {depositDetails?.depositDate
                  ? moment(depositDetails?.depositDate)
                      .utc()
                      .format('DD/MM/YYYY')
                  : '-'}
              </td>
              {/* <td>{formatDate(depositDetails?.depositTime)}</td> */}
            </tr>
          </tbody>
        </Table>
      </Paper>
    );
  };
  const handleRedirectToPayments = (paymentID) => {
    history.push(
      `/detail-payments-standalone/${paymentID}/${depositDetails?.agency?.id}`,
    );
  };

  const renderFiservPaymentsDetails = () => {
    const standAlonePayments =
      depositDetails?.depositsDetailsStandAlones
        ? depositDetails?.depositsDetailsStandAlones
        : {};
    if (standAlonePayments.length > 0) {
      let totalDeposit = 0;
      const payments = standAlonePayments.map((standAlonePayment) => {
        const paymentID = standAlonePayment?.paymentsStandAlone?.id;
        if (depositDetails?.agency?.toogleZeroTaxes) {
          totalDeposit = standAlonePayment.total;
        } else {
          totalDeposit =
            standAlonePayment.paymentsStandAlone.transactionAmount;
        }

        let dateBpPayment =
          standAlonePayment?.paymentsStandAlone.transactionDate;
        return (
          <tr
            key={`spei-payment-${standAlonePayment.id}`}
            className={
              standAlonePayment?.chargeback
                ? classes.chargebackRow
                : null
            }
          >
            <td
              className={classes.clickeableTR}
              role="presentation"
              onClick={() => handleRedirectToPayments(paymentID)}
            >
              {depositDetails?.agency?.name}
            </td>
            <td
              className={classes.clickeableTR}
              role="presentation"
              onClick={() => handleRedirectToPayments(paymentID)}
            >
              {depositDetails?.agency?.id}
            </td>
            <td
              className={classes.clickeableTR}
              role="presentation"
              onClick={() => handleRedirectToPayments(paymentID)}
            >
              {depositDetails?.depositor
                ? depositDetails?.depositor
                : '-'}
            </td>
            <td
              className={classes.clickeableTR}
              role="presentation"
              onClick={() => handleRedirectToPayments(paymentID)}
            >
              {dateBpPayment
                ? formatDate(dateBpPayment)
                : moment(standAlonePayment.date)
                    .utc()
                    .format('DD/MM/YYYY h:mma')}
            </td>
            <td
              className={classes.clickeableTR}
              role="presentation"
              onClick={() => handleRedirectToPayments(paymentID)}
            >
              {formatNumber(
                standAlonePayment.paymentsStandAlone
                  .transactionAmount,
              )}
            </td>
          </tr>
        );
      });
      return (
        <Paper className={classes.table}>
          <DialogTitle className={classes.title}>
            Movimientos
          </DialogTitle>
          <Table bordered size="sm">
            <thead>
              <tr>
                <th>AGENCIA</th>
                <th>ID AGENCIA</th>
                <th>NOMBRE DEL BANCO</th>
                <th>FECHA DE PAGO</th>
                <th>TOTAL A DEPOSITAR</th>
              </tr>
            </thead>
            <tbody>{payments}</tbody>
          </Table>
        </Paper>
      );
    }
  };

  return isEmpty(depositDetails) ? (
    <SplashScreen></SplashScreen>
  ) : (
    <div>
      {renderFeedBackModal()}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          {renderDepostiDetails()}
        </Grid>
        <Grid item xs={12} md={8} className={classes.rightContainer}>
          {renderFiservPaymentsDetails()}
          {Array.isArray(depositLogs) && depositLogs.length > 0 && (
            <Grid item>
              <RecentActivities logs={depositLogs} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  deposits: selectDepositsStandAlone(state),
  depositDetails: makeSelectDepositDetailsFromState(state),
  depositLogs: makeSelectDepositsLogs(state),
  role: makeSelectUserRoleFromState(state),
  agencies: makeSelectAgencies(state),
});

const mapDispatchToProps = {
  closeModal: closeModals,
  getDepositDetailStandAlone: getDepositDetailStandAlone,
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(DetailDepositStandAlone),
  ),
);
