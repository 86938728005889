import 'react-table/react-table.css';
import 'moment/locale/es';

/* eslint-disable react/display-name */
import {
  Button,
  IconButton,
  Paper,
  makeStyles,
} from '@material-ui/core';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React, { useEffect, useState, useRef } from 'react';
import {
  accountingRoles,
  adminRoles,
  superAdminRoles,
} from '../../../../config/roles';
import { formatNumber, getColumnWidth } from '../../../../utils';

import {
  makeSelectUserRoleFromState,
  selectAuth,
} from '../../../auth/selectors';

import { makeSelectGetOneAgency } from 'app/features/agencies/selectors';

import {
  getPayments,
  getPaymentsOrders,
  getPaymentsForDownload,
} from 'app/features/payments/paymentsSlice';

import { getOneAgency } from 'app/features/agencies/agenciesSlice';

import { Badge } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import Close from '@material-ui/icons/Close';
import GetApp from '@material-ui/icons/GetApp';
import { Link } from 'react-router-dom';
import MUIDatePickerFilter from 'app/components/MUIDatePickerFilter';
import MomentUtils from '@date-io/moment';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import { paymentsHardcoded } from 'app/config/paymentsHardcoded';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  paper: {
    widht: '100%',
    marginBottom: theme.spacing(0),
  },
  button: {
    margin: theme.spacing(1),
  },
  grid: {
    width: '45%',
    marginBottom: theme.spacing(1),
  },
  range: {
    paddingTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  datePicker: {
    width: 90,
  },
}));

function PaymentsTableClear(props) {
  const classes = useStyles();
  const {
    getPaymentsForDownload,
    user,
    getOneAgency,
    userAgency,
  } = props;
  const { role, intl } = props;
  const [payments, setPayments] = useState([]);
  const [firstDate, setFirstDate] = useState(null);
  const [secondDate, setSecondDate] = useState(null);
  const [csvReport, setCsvReport] = useState([]);
  const reactTable = useRef(null);
  const [filtered, setFiltered] = useState('');
  const [userAgencyData, setUserAgencyData] = useState('');

  useEffect(() => {
    setUserAgencyData(userAgency);
  }, [userAgency]);

  useEffect(() => {
    if (user?.user?.agencyID) {
      const data = user?.user?.agencyID;
      getOneAgency({ data });
    }
  }, [user]);

  useEffect(() => {
    if (firstDate && secondDate)
      reactTable.current && reactTable.current.fireFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps

    if (firstDate && secondDate && secondDate > firstDate) {
      const data = {
        fromDate: moment(firstDate).format('YYYY-MM-DD'),
        toDate: moment(secondDate).format('YYYY-MM-DD'),
        agencyID: user?.user?.agencyID,
      };

      const info = {
        data,
        type: 'odp',
        wheres: filtered,
      };
      getPaymentsForDownload(info);
    }
  }, [firstDate, secondDate, filtered]);

  useEffect(() => {
    setCsvReport(props.payments.paymentsForDownload);
  }, [props.payments.paymentsForDownload]);

  useEffect(
    () => {
      let paymentsToCSV = [];
      if (
        Array.isArray(props.payments.paymentsOrdersFromdb) &&
        props.payments.paymentsOrdersFromdb.length > 0
      ) {
        const cleanPayments = props.payments.paymentsOrdersFromdb
          .filter((payment) => payment && payment.paymentOrder)
          .map((payment) => {
            let toDeposit;
            let comissionWithOutIva;
            let iva;

            if (payment.billPocketID in paymentsHardcoded) {
              toDeposit =
                paymentsHardcoded[payment.billPocketID].toDeposit;
              comissionWithOutIva =
                paymentsHardcoded[payment.billPocketID]
                  .comissionWithoutIVA;
              iva = paymentsHardcoded[payment.billPocketID].iva;
            } else {
              if (payment.totalToDepositAgency) {
                toDeposit = payment.totalToDepositAgency;
              } else {
                toDeposit = payment.paymentMethod
                  ? payment.promotionWI
                    ? payment.amount - payment.surcharge
                    : payment.amount - payment.comission
                  : 0;
              }
              comissionWithOutIva = payment.paymentMethod
                ? (payment.total - toDeposit) / 1.16
                : 0;

              iva = payment.paymentMethod
                ? comissionWithOutIva
                  ? (comissionWithOutIva * 0.16).toFixed(2)
                  : '-'
                : 0;
            }
            const clientFullName = payment.paymentOrder.nombre
              ? payment.paymentOrder.nombre
              : '-';
            const orderID = payment.paymentOrder.reference;
            const total = payment ? formatNumber(payment.total) : '-';
            const date = moment(payment.date).format(
              'DD/MM/YYYY hh:mm a',
            );
            const paymentMethod = payment.type || '-';

            const billPocketID = payment.billPocketID || '-';
            const authNumber = payment.authNumber || '-';

            const transactionId =
              payment.paymentOrder.agency.paymentAggregators ===
              'fiserv'
                ? payment.authNumber
                : payment.billPocketID
                ? payment.billPocketID
                : '-';

            paymentsToCSV.push({
              'Id de orden': orderID || '-',
              'ID Transacción': transactionId || '-',
              Cliente: clientFullName || '-',
              'Nombre tarjeta habiente': payment.cardHolder || '-',
              'No. Transacción':
                payment.authNumber || payment.reference || '-',
              'Num. Tarjeta': payment.last4 || '-',
              'Metodo de Pago': payment.type || '-',
              'Forma de pago': payment.months
                ? 'A meses'
                : 'Una sola exhibicion',
              'Fecha de pago': date || '-',
              Monto: total || '-',
              'Comision sin IVA':
                formatNumber(comissionWithOutIva) || '-',
              IVA: formatNumber(iva) || '-',
              'A depositar': formatNumber(toDeposit) || '-',
              Origen: formatNumber(payment.origin) || '-',
            });
            return {
              karloID: payment.id,
              billPocketID,
              authNumber,
              transactionId,
              agencyID: payment.paymentOrder.agencyID,
              orderID:
                payment.paymentOrder.reference ||
                payment.paymentOrder.orderID,
              odsId: payment.paymentOrder.orderID,
              date,
              total: payment ? formatNumber(payment.total) : '-',
              comission: comissionWithOutIva,
              iva,
              toDeposit: formatNumber(toDeposit),
              refNumber: payment
                ? payment.transactionNetPayToken
                : '-',
              clientFullName,
              paymentMethod,
              months: payment.months,
              origin: payment.origin,
            };
          });
        setPayments(cleanPayments);
      } else {
        setPayments([]);
      }
      props.setActionButton(
        <>
          <MuiPickersUtilsProvider utils={MomentUtils} locale={'es'}>
            <DatePicker
              autoOk
              disableToolbar
              disableFuture
              variant="inline"
              label="Inicial"
              format="DD/MM"
              className={classes.datePicker}
              value={firstDate}
              onChange={handleFirstDate}
              InputProps={{
                endAdornment: firstDate ? (
                  <IconButton
                    aria-label="Select locale"
                    size="small"
                    onClick={handleClearFirstDate}
                  >
                    <Close />
                  </IconButton>
                ) : null,
              }}
            />
            <span className={classes.range}>&nbsp;A&nbsp;</span>
            <DatePicker
              autoOk
              disableToolbar
              disableFuture
              variant="inline"
              label="Final"
              format="DD/MM"
              className={classes.datePicker}
              value={secondDate}
              onChange={handleSecondDate}
              InputProps={{
                endAdornment: secondDate ? (
                  <IconButton
                    aria-label="Select locale"
                    size="small"
                    onClick={handleClearSecondDate}
                  >
                    <Close />
                  </IconButton>
                ) : null,
              }}
            />
          </MuiPickersUtilsProvider>
          <CSVLink
            data={csvReport || []}
            filename="tablaDePagos.csv"
            onClick={() => {
              return !firstDate ||
                !secondDate ||
                secondDate < firstDate
                ? false
                : true;
            }}
          >
            <Button
              variant="contained"
              className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
              disabled={
                !firstDate || !secondDate || secondDate < firstDate
              }
            >
              Exportar
              <GetApp className="kt-margin-l-10" />
            </Button>
          </CSVLink>
        </>,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.payments.paymentsOrdersFromdb, firstDate, secondDate],
  );

  const handleTable = (row) => {
    return `/detail-payment/${row.original.karloID}`;
  };

  const handleFirstDate = (date) => {
    setFirstDate(date);
  };

  const handleSecondDate = (date) => {
    setSecondDate(date);
  };

  const handleClearFirstDate = () => {
    setFirstDate(null);
  };

  const handleClearSecondDate = () => {
    setSecondDate(null);
  };

  const handleFetchData = debounce(
    (state, instance) => {
      let init = firstDate ? firstDate : undefined;
      let end = secondDate ? secondDate : undefined;
      const { page, pageSize, sorted, filtered } = state;
      props.getPaymentOrders({
        firstDate: init,
        secondDate: end,
        page,
        pageSize,
        sorted,
        filtered,
        userAgencyData,
      });
      setFiltered(filtered);
    },
    1000,
    { leading: false, trailing: true },
  );

  const colums = [
    {
      Header: () => <strong>Id de orden</strong>,
      accessor: 'orderID',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>Cliente</strong>,
      accessor: 'clientFullName',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      width: getColumnWidth(payments, 'clientFullName', 'Cliente'),
    },
    {
      Header: () => <strong>ID Transacción</strong>,
      accessor: 'transactionId',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      show: user?.user?.role !== 'Super Admin',
    },
    {
      Header: () => <strong>billPocket ID</strong>,
      accessor: 'billPocketID',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      show: user?.user?.role === 'Super Admin',
    },
    {
      Header: () => <strong>Fiserv ID</strong>,
      accessor: 'authNumber',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      show: user?.user?.role === 'Super Admin',
    },
    {
      Header: () => <strong>Método de pago</strong>,
      accessor: 'paymentMethod',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {intl.formatMessage({
              id: `PAYMENT.TYPE.${row.value}`,
            })}
          </div>
        </Link>
      ),
      Filter: (cell) => {
        return (
          <select
            onBlur={(e) => cell.onChange(e.target.value)}
            value={
              cell.filter && cell.filter.value
                ? cell.filter.value
                : ''
            }
          >
            <option value="">Todos</option>
            <option value="DEBIT VISA">DÉBITO VISA</option>
            <option value="DEBIT MASTERCARD">
              DÉBITO MASTERCARD
            </option>
            <option value="CREDIT AMERICAN-EXPRESS">
              CRÉDITO AMEX
            </option>
            <option value="CREDIT VISA">CRÉDITO VISA</option>
            <option value="CREDIT MASTERCARD">
              CRÉDITO MASTERCARD
            </option>
            <option value="OXXO">OXXO</option>
            <option value="SPEI">SPEI</option>
          </select>
        );
      },
      width: getColumnWidth(
        payments,
        'paymentMethod',
        'Método de pago',
      ),
    },
    {
      Header: () => <strong>Meses</strong>,
      accessor: 'months',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value ? (
              <Badge className={classes.badges} variant="info">
                {`${row.value} meses`}
              </Badge>
            ) : (
              '-'
            )}
          </div>
        </Link>
      ),
      Filter: (cell) => {
        return (
          <select
            onBlur={(e) => cell.onChange(e.target.value)}
            value={
              cell.filter && cell.filter.value
                ? cell.filter.value
                : ''
            }
          >
            <option value="">Todos</option>
            <option value="3">3 meses</option>
            <option value="6">6 meses</option>
            <option value="9">9 meses</option>
            <option value="12">12 meses</option>
          </select>
        );
      },
      width: getColumnWidth(payments, 'date', 'Meses sin intereses'),
    },
    {
      Header: () => <strong>Fecha de pago</strong>,
      accessor: 'date',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      Filter: (cell) => {
        return <MUIDatePickerFilter cell={cell} />;
      },
      width: getColumnWidth(payments, 'date', 'Fecha de pago'),
    },
    {
      Header: () => <strong>Monto</strong>,
      accessor: 'total',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      width: getColumnWidth(payments, 'total', 'Total'),
      filterable: false,
      show:
        superAdminRoles.includes(role) ||
        adminRoles.includes(role) ||
        accountingRoles.includes(role),
    },
    {
      Header: () => <strong>Comisión</strong>,
      accessor: 'comission',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {formatNumber(row.value)}
          </div>
        </Link>
      ),
      filterable: false,
      show:
        superAdminRoles.includes(role) ||
        adminRoles.includes(role) ||
        accountingRoles.includes(role),
    },
    {
      Header: () => <strong>IVA</strong>,
      accessor: 'iva',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {formatNumber(row.value)}
          </div>
        </Link>
      ),
      width: getColumnWidth(payments, 'iva', 'IVA'),
      filterable: false,
      show:
        superAdminRoles.includes(role) ||
        adminRoles.includes(role) ||
        accountingRoles.includes(role),
    },
    {
      Header: () => <strong>A depositar</strong>,
      accessor: 'toDeposit',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
      show:
        superAdminRoles.includes(role) ||
        adminRoles.includes(role) ||
        accountingRoles.includes(role),
    },
    {
      Header: () => <strong>Origen</strong>,
      accessor: 'origin',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value && row.value === 'online'
              ? 'En linea'
              : row.value && row.value === 'kiosko'
              ? 'Kiosko'
              : row.value.toUpperCase()}
          </div>
        </Link>
      ),
      filterable: false,
    },
  ];

  return (
    <div className={classes.root}>
      <Paper>
        <ReactTable
          ref={reactTable}
          NoDataComponent={() => (
            <div className="rt-noData">Sin resultados</div>
          )}
          manual
          onFetchData={handleFetchData}
          data={payments}
          columns={colums}
          pages={props.payments.tablePages}
          filterable
          className="-striped -highlight"
          previousText="Anterior"
          nextText="Siguiente"
          loading={props.payments.isLoading}
          loadingText="Cargando datos..."
        />
      </Paper>
    </div>
  );
}

const mapStateToProps = (state) => ({
  payments: state.payments,
  role: makeSelectUserRoleFromState(state),
  user: selectAuth(state),
  userAgency: makeSelectGetOneAgency(state),
});

const mapDispatchToProps = {
  getPayments: getPayments,
  getPaymentOrders: getPaymentsOrders,
  getPaymentsForDownload: getPaymentsForDownload,
  getOneAgency: getOneAgency,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(PaymentsTableClear),
);
