import { closeModals } from '../modals/modalsSlice';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { logout } from '../auth/authSlice';

const serviceAppointmentsSlice = createSlice({
  name: 'serviceAppointments',
  initialState,
  reducers: {
    getServiceAppointments: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getServiceAppointmentsSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.fromdb = action.payload.appointments;
        state.tablePages = action.payload.appointments.pages;
      },
    },
    getServiceAppointmentsFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    getAppointmentDetail: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    getAppointmentDetailSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.appointmentDetails = action.payload.appointmentDetails;
      },
    },
    getAppointmentDetailFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    confirmAppointment: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    confirmAppointmentSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    confirmAppointmentFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    rejectAppointment: {
      reducer(state) {
        state.isLoading = true;
      },
    },
    rejectAppointmentSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    rejectAppointmentFail: {
      reducer(state, action) {
        state.isLoading = false;
        state.errorModal = {
          show: true,
          message: action.payload.message,
        };
      },
    },
    handleCloseAppointmentModals: {
      reducer(state, action) {
        state.isLoading = true;
      },
    },
    handleCloseAppointmentModalsSuccess: {
      reducer(state, action) {
        state.isLoading = false;
        state.successModal = initialState.successModal;
        state.errorModal = initialState.errorModal;
      },
    },
  },
  extraReducers: {
    [logout]: (state, action) => {
      return initialState;
    },
  },
});

export const {
  getServiceAppointments,
  getServiceAppointmentsSuccess,
  getServiceAppointmentsFail,
  getAppointmentDetail,
  getAppointmentDetailSuccess,
  getAppointmentDetailFail,
  confirmAppointment,
  confirmAppointmentSuccess,
  confirmAppointmentFail,
  rejectAppointment,
  rejectAppointmentSuccess,
  rejectAppointmentFail,
  handleCloseAppointmentModals,
  handleCloseAppointmentModalsSuccess,
} = serviceAppointmentsSlice.actions;

export default serviceAppointmentsSlice.reducer;
