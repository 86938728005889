import * as builder from '../ducks/builder';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LayoutSplashScreen } from './LayoutContext';
import { getNested } from '../../app/utils';

function loadCss(href, anchor) {
  return new Promise((resolve, reject) => {
    const link = document.createElement('link');

    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('type', 'text/css');

    link.onload = resolve;
    link.onerror = reject;

    link.setAttribute('href', href);

    anchor.parentNode.insertBefore(link, anchor.nextSibling);

    return link;
  });
}

/**
 * Used to synchronize current layout `menuConfig`, `layoutConfig` and
 * `htmlClassService` with redux store.
 */
export default function LayoutInitializer({
  styles,
  children,
  layoutConfig,
  htmlClassService,
  user,
  agencies,
}) {
  const dispatch = useDispatch();
  const [loadedStyles, setLoadedStyles] = useState();
  const builderState = useSelector(({ builder }) => builder);

  useEffect(() => {
    let arrModulesDisabled = [];
    const role = user && 'role' in user ? user.role : '';
    const agency = agencies && 'agencyDetails' in agencies ? agencies.agencyDetails : null;
    const isActiveOnlineKiosko = agency && 'isActiveOnlineKiosko' in agency ? agency.isActiveOnlineKiosko : false;
    if(!isActiveOnlineKiosko) arrModulesDisabled = ['Kiosko'];
    const dms = getNested(null, agencies, 'agencyDetails', 'DMS');
    const agencyID = getNested(null, user, 'agencyID');
    if (!agencyID) dispatch(builder.actions.setMenuConfig(role, dms, arrModulesDisabled));
    else if (agencyID && role && dms)
      dispatch(builder.actions.setMenuConfig(role, dms, arrModulesDisabled));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencies, user]);

  useEffect(() => {
    if (layoutConfig.demo !== builderState.layoutConfig.demo) {
      dispatch(builder.actions.setLayoutConfigs(layoutConfig));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [builderState, layoutConfig]);

  useEffect(() => {
    dispatch(builder.actions.setHtmlClassService(htmlClassService));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [htmlClassService]);

  useEffect(() => {
    if (!Array.isArray(styles)) {
      setLoadedStyles(styles);

      return;
    }

    const anchor = document.getElementById('layout-styles-anchor');

    Promise.all(
      [...styles].reverse().map((href) => loadCss(href, anchor)),
    ).then(() => {
      setLoadedStyles(styles);
    });

    return () => {
      styles.forEach((href) => {
        const link = document.querySelector(`link[href='${href}']`);

        if (link && link.parentNode) {
          link.parentNode.removeChild(link);
        }
      });
    };
  }, [styles]);

  return loadedStyles === styles &&
    htmlClassService === builderState.htmlClassServiceObjects ? (
    // Render content when `htmlClassService` synchronized with redux store.
    <>{children}</>
  ) : (
    // Otherwise sow loading splash screen.
    <LayoutSplashScreen />
  );
}
