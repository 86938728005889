import { Button } from '@material-ui/core';
import { CSVLink } from 'react-csv';
import GetAppIcon from '@material-ui/icons/GetApp';
import React from 'react';

export default function ButtonExportExcel({
  data = [],
  fileName = 'tabla_de_pagos.csv',
  buttonText = 'Exportar',
}) {
  return (
    <CSVLink
      data={data}
      filename={fileName}
      onClick={(event) => {
        return data?.length > 0;
      }}
    >
      <Button
        variant="contained"
        className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
        disabled={Boolean(data?.length <= 0)}
      >
        {buttonText}
        <GetAppIcon className="kt-margin-l-10" />
      </Button>
    </CSVLink>
  );
}
