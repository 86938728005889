import { createSelector } from 'reselect';
import { initialState } from './initialState';

export const selectTokenize = (state) =>
  state.tokenize || initialState;

export const makeSelectServiceOrderTokenFromState = createSelector(
  selectTokenize,
  (tokenizeState) => tokenizeState.serviceOrderToken,
);

export const makeSelectPaymentOrderTokenFromState = createSelector(
  selectTokenize,
  (tokenizeState) => tokenizeState.paymentOrderToken,
);
