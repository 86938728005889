import { Col, Row } from 'react-bootstrap';
import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import {
  defaultComissions,
  monthsByComissions,
} from '../../../../config/monthsTaxes';
import { formatNumber, getNested } from '../../../../utils';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import NumberFormatCustom from '../../../../components/NumberFormatCustom';
import { connect } from 'react-redux';
import { getAgency } from 'app/features/agencies/agenciesSlice';
import { makeSelectAgencyDetailsFromState } from '../../../agencies/selectors';
import { makeSelectUserRoleFromState } from 'app/features/auth/selectors';
import { superAdminRoles } from 'app/config/roles';

const IVA = 1.16;
const BASE_EXTRA_WITH_IVA = {
  billPocket: 0,
  netpay: 2.9,
};

const useStyles = makeStyles((theme) => ({
  container: {
    flexWrap: 'wrap',
  },
  paper: {
    padding: theme.spacing(7, 10, 1, 10),
  },
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  client: {
    padding: theme.spacing(0),
    marginBottom: theme.spacing(2),
  },
  textField: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  media: {
    height: 60,
    width: '100%',
    position: 'relative',
  },
  image: {
    height: '100%',
    width: 'auto',
    position: 'absolute',
    top: '10%',
    left: '45%',
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  subtitle: {
    marginBottom: theme.spacing(4.5),
  },
  subTotals: {
    margin: theme.spacing(4, 0),
  },
  totals: {
    margin: theme.spacing(3, 0),
  },
  conditions: {
    margin: theme.spacing(6, 0, 0, 0),
  },
}));

function Calculator(props) {
  const { agencyDetails, getAgency, role } = props;
  const classes = useStyles();
  const paymentTypeLabel = React.useRef(null);
  const placementLabel = React.useRef(null);
  const cardTypeLabel = React.useRef(null);
  const promotionLabel = React.useRef(null);
  const [paymentTypeWidth, setPaymentTypeWidth] = React.useState(0);
  const [placementWidth, setPlacementWidth] = React.useState(0);
  const [cardTypeWidth, setCardTypeWidth] = React.useState(0);
  const [promotionWidth, setPromotionWidth] = React.useState(0);
  const [amount, setAmount] = React.useState('');
  const [paymentType, setPaymentType] = React.useState(0);
  const [cardType, setCardType] = React.useState(0);
  const [placement, setPlacement] = React.useState(0);
  const [promotion, setPromotion] = React.useState(0);
  const [iva, setIva] = React.useState(0);
  const [
    surchargeWithOutIva,
    setSurchargeWithOutIva,
  ] = React.useState(0);
  const [
    comissionWithOutIva,
    setComissionWithOutIva,
  ] = React.useState(0);
  const [totalToDeposit, setTotalToDeposit] = React.useState(0);
  const [totalToBeCharged, setTotalToBeCharged] = React.useState(0);

  useEffect(() => {
    if (!superAdminRoles.includes(role)) getAgency();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setPaymentTypeWidth(paymentTypeLabel.current.offsetWidth);
  }, []);

  React.useEffect(() => {
    switch (paymentType) {
      case 1:
        setCardTypeWidth(cardTypeLabel.current.offsetWidth);
        break;
      case 2:
        setPlacementWidth(placementLabel.current.offsetWidth);
        setCardTypeWidth(cardTypeLabel.current.offsetWidth);
        setPromotionWidth(promotionLabel.current.offsetWidth);
        break;

      default:
        break;
    }
  }, [paymentType]);

  React.useEffect(() => {
    cleanValues();
    handleCalculateTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, paymentType, cardType, placement, promotion]);

  const cleanValues = () => {
    setIva(0);
    setSurchargeWithOutIva(0);
    setComissionWithOutIva(0);
    setTotalToDeposit(0);
    setTotalToBeCharged(0);
  };

  const getBaseComission = () => {
    switch (cardType) {
      case 2:
        return getNested(
          defaultComissions['amex'],
          agencyDetails,
          'amexCardComission',
        );
      default:
        return getNested(
          defaultComissions['visa/mastercard'],
          agencyDetails,
          'cardComission',
        );
    }
  };

  const getSurchargeByCardType = () => {
    switch (cardType) {
      case 2:
        return monthsByComissions[placement].amex;
      default:
        return monthsByComissions[placement].normal;
    }
  };

  const calculateTotalCards = () => {
    let baseComission = 0,
      baseComissionWithOutIVA = 0,
      baseComissionWithIVA = 0,
      surchargeByCardType = 0,
      completeSurcharge = 0,
      toDepositToAgency = 0,
      tempIva = 0,
      totalToBeCharged = 0;
    switch (paymentType) {
      case 1:
        baseComissionWithOutIVA =
          getBaseComission() * parseFloat(amount);
        baseComissionWithIVA = getBaseComission() * IVA;
        tempIva = baseComissionWithOutIVA * 0.16;
        setIva(tempIva);
        setComissionWithOutIva(baseComissionWithOutIVA);
        setSurchargeWithOutIva(0);
        setTotalToDeposit(amount - baseComissionWithOutIVA * 1.16);
        setTotalToBeCharged(amount);
        break;
      default:
        //CALCULATING COMISSIONS
        baseComission = getBaseComission();
        baseComissionWithOutIVA = baseComission * parseFloat(amount);
        baseComissionWithIVA = baseComission * IVA;
        tempIva += baseComissionWithOutIVA * 0.16;
        surchargeByCardType = getSurchargeByCardType();
        tempIva += surchargeByCardType * parseFloat(amount) * 0.16;
        completeSurcharge = surchargeByCardType * parseFloat(amount);
        const comissionMWI = surchargeByCardType * IVA;
        const baseComissionCalculated =
          amount * baseComissionWithIVA +
          BASE_EXTRA_WITH_IVA['billPocket'];
        if (promotion === 1) {
          //MONTHS WITH OUT INTEREST CASE
          toDepositToAgency =
            amount -
            baseComissionCalculated -
            amount * (getSurchargeByCardType() * IVA);
          totalToBeCharged = amount;
        } else if (promotion === 2) {
          //BASE CASE NO PROMOTION
          //Recalculating comissions by the totalToBeCharged
          tempIva = 0;
          toDepositToAgency = amount - baseComissionCalculated;
          totalToBeCharged = (
            (toDepositToAgency + BASE_EXTRA_WITH_IVA['billPocket']) /
            (1 - (baseComissionWithIVA + comissionMWI))
          ).toFixed(2);
          baseComissionWithOutIVA =
            baseComission * parseFloat(totalToBeCharged);
          tempIva += baseComissionWithOutIVA * 0.16;
          tempIva +=
            surchargeByCardType * parseFloat(totalToBeCharged) * 0.16;
          completeSurcharge =
            surchargeByCardType * parseFloat(totalToBeCharged);
        }
        setIva(tempIva);
        setComissionWithOutIva(baseComissionWithOutIVA);
        setSurchargeWithOutIva(completeSurcharge);
        setTotalToDeposit(toDepositToAgency);
        setTotalToBeCharged(totalToBeCharged);
        break;
    }
  };

  const handleCalculateComissionConekta = (totalComission) => {
    setComissionWithOutIva(totalComission);
    setIva(totalComission * 0.16);
    setTotalToDeposit(amount - totalComission * 1.16);
    setTotalToBeCharged(amount);
  };

  const handleCalculateTotal = () => {
    if (amount && paymentType) {
      switch (paymentType) {
        case 1:
          if (cardType) calculateTotalCards();
          break;
        case 2:
          if (cardType && placement && promotion)
            calculateTotalCards();
          break;
        case 3:
          const comissionSpei = 8;
          handleCalculateComissionConekta(comissionSpei);
          break;
        case 4:
          const comissionOxxo = amount * 0.039;
          handleCalculateComissionConekta(comissionOxxo);
          break;

        default:
          console.log('calculating...');
          break;
      }
    } else {
      cleanValues();
    }
  };

  const validMonth = (month) => {
    return amount / month >= 100;
  };

  const renderMonthsOptions = () => {
    const options = [];
    Object.keys(monthsByComissions).forEach((month) => {
      if (validMonth(month))
        options.push(
          <MenuItem key={`month-${month}`} value={month}>
            {month} meses
          </MenuItem>,
        );
    });
    return options;
  };

  const renderSelectCard = () => {
    return (
      <Grid item xs>
        <FormControl
          variant="outlined"
          fullWidth
          className={classes.formControl}
        >
          <InputLabel ref={cardTypeLabel} htmlFor="card-type">
            Tipo de tarjeta
          </InputLabel>
          <Select
            value={cardType}
            onChange={({ target }) => {
              setCardType(target.value);
            }}
            input={
              <OutlinedInput
                labelWidth={cardTypeWidth}
                id="card-type"
              />
            }
          >
            <MenuItem value={0}>Seleccione una opción</MenuItem>
            <MenuItem value={1}>Visa/Mastercard</MenuItem>
            <MenuItem value={2}>Amex</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const handleRenderPlacements = () => {
    switch (paymentType) {
      case 1:
        return renderSelectCard();
      case 2:
        return (
          <>
            <Grid container spacing={2}>
              <Grid item xs>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                >
                  <InputLabel
                    ref={placementLabel}
                    htmlFor="placement"
                  >
                    Plazo
                  </InputLabel>
                  <Select
                    value={placement}
                    onChange={({ target }) => {
                      setPlacement(target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <Tooltip
                          title={
                            <>
                              <Typography variant="body2">
                                *El monto mínimo para pagos a 3 meses
                                es de $300.00 pesos.
                              </Typography>
                              <Typography variant="body2">
                                *El monto mínimo para pagos a 6 meses
                                es de $600.00 pesos.
                              </Typography>
                              <Typography variant="body2">
                                *El monto mínimo para pagos a 9 meses
                                es de $900.00 pesos.
                              </Typography>
                              <Typography variant="body2">
                                *El monto mínimo para pagos a 12 meses
                                es de $1200.00 pesos.
                              </Typography>
                            </>
                          }
                        >
                          <HelpOutlineIcon />
                        </Tooltip>
                      </InputAdornment>
                    }
                    input={
                      <OutlinedInput
                        labelWidth={placementWidth}
                        name="Plazo"
                        id="placement"
                      />
                    }
                  >
                    <MenuItem value={0}>
                      Seleccione una opción
                    </MenuItem>
                    {renderMonthsOptions()}
                  </Select>
                </FormControl>
              </Grid>
              {renderSelectCard()}
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                >
                  <InputLabel
                    ref={promotionLabel}
                    htmlFor="promotion"
                  >
                    Con promoción
                  </InputLabel>
                  <Select
                    value={promotion}
                    onChange={({ target }) => {
                      setPromotion(target.value);
                    }}
                    input={
                      <OutlinedInput
                        labelWidth={promotionWidth}
                        name="Plazo"
                        id="promotion"
                      />
                    }
                  >
                    <MenuItem value={0}>
                      Seleccione una opción
                    </MenuItem>
                    <MenuItem value={1}>Con promoción</MenuItem>
                    <MenuItem value={2}>Sin promoción</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs></Grid>
            </Grid>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Row>
      <Col lg="8" md="10">
        <Paper className={classes.paper}>
          <form
            className={classes.container}
            noValidate
            autoComplete="off"
          >
            <div className="input-container">
              <Grid xs={12} item className={classes.subtitle}>
                <Typography variant="h6">
                  Calculadora de pagos
                </Typography>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs>
                  <TextField
                    required
                    fullWidth
                    type="text"
                    label="Cantidad total"
                    variant="outlined"
                    value={amount}
                    onChange={({ target }) => setAmount(target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          $
                        </InputAdornment>
                      ),
                      inputComponent: NumberFormatCustom,
                      inputProps: { quantity: true },
                    }}
                    className={classes.textField}
                  />
                </Grid>
                <Grid item xs>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <InputLabel
                      ref={paymentTypeLabel}
                      htmlFor="paymentType"
                    >
                      Tipo de pago
                    </InputLabel>
                    <Select
                      value={paymentType}
                      onChange={({ target }) => {
                        setPaymentType(target.value);
                      }}
                      input={
                        <OutlinedInput
                          labelWidth={paymentTypeWidth}
                          name="Tipo de pago"
                          id="paymentType"
                        />
                      }
                    >
                      <MenuItem value={0}>
                        Seleccione una opción
                      </MenuItem>
                      <MenuItem value={1}>
                        Una sola excibición
                      </MenuItem>
                      <MenuItem value={2}>Pago a meses</MenuItem>
                      <MenuItem value={3}>Pago con SPEI</MenuItem>
                      <MenuItem value={4}>Pagos en Oxxo</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              {handleRenderPlacements()}
              <Grid
                container
                direction="row"
                justify="space-between"
                className={classes.subTotals}
              >
                <Grid>
                  <Typography variant="body2">Comisión</Typography>
                  <Typography variant="h6">
                    {formatNumber(
                      comissionWithOutIva + surchargeWithOutIva,
                    )}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography variant="body2">IVA</Typography>
                  <Typography variant="h6">
                    {formatNumber(iva)}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography variant="body2">
                    Comisión Total
                  </Typography>
                  <Typography variant="h6">
                    {formatNumber(
                      iva + surchargeWithOutIva + comissionWithOutIva,
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justify="space-between"
                className={classes.totals}
              >
                <Grid>
                  <Typography variant="body2">
                    Total a pagar
                  </Typography>
                  <Typography variant="h4">
                    {formatNumber(totalToBeCharged)}
                  </Typography>
                </Grid>
                {paymentType === 2 && (
                  <Grid>
                    <Typography variant="body2">
                      Pago mensual
                    </Typography>
                    <Typography variant="h4">
                      {formatNumber(totalToBeCharged / placement)}
                    </Typography>
                  </Grid>
                )}
                <Grid>
                  <Typography variant="body2">
                    Depósito total a tu cuenta
                  </Typography>
                  <Typography variant="h4">
                    {formatNumber(totalToDeposit)}
                  </Typography>
                </Grid>
              </Grid>
              {paymentType === 2 && (
                <Typography variant="body2">{`El cargo por diferir tu pago será de $${Math.max(
                  totalToBeCharged - amount,
                  0,
                ).toFixed(2)}`}</Typography>
              )}
            </div>
          </form>
        </Paper>
      </Col>
    </Row>
  );
}

const mapStateToProps = (state) => ({
  agencyDetails: makeSelectAgencyDetailsFromState(state),
  role: makeSelectUserRoleFromState(state),
});

const mapDispatchToProps = {
  getAgency,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Calculator);
